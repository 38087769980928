import { InterUIButton, InterUIContainer } from '@interco/inter-ui-react-lib';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import PortabilityAnalyticsService from '../../analytics/portabilityAnalyticsService';
import { InvestmentFundCard } from '../../components';
import FontSizeREM from '../../enums/fontSizesRem';
import { LocalStorageKeys } from '../../enums/localStorageKeys';
import { PageRoutes } from '../../enums/pageRoutes';
import PageTitles from '../../enums/pageTitles';
import LocalStorageService from '../../services/localStorageService';
import { LoadingActions } from '../../store/loading/actions';
import NavbarActions from '../../store/navbar/actions';
import { ProposalDetailActions } from '../../store/proposal/actions';
import SelectableFundsActions from '../../store/selectableFunds/actions';
import { ThemeInter } from '../../styles';
import { P } from '../../styles/commons';

const ProposalByPortabilityPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme() as ThemeInter;
  const currentFund = useSelector(ProposalDetailActions.getInvestmentFund);
  const [selectedFund, setSelectedFund] = useState(currentFund);

  const loading = useSelector(LoadingActions.get);

  const text = {
    title: 'Fundo de Previdência',
    message: 'Selecionamos um fundo de investimento recomendado para o seu perfil:',
    btnLabel: 'Continuar',
  };

  const onSubmit = () => {
    PortabilityAnalyticsService.proposalContinueButton(selectedFund.name);
    history.push(PageRoutes.CONTRIBUTION_TIME);
  };

  const stickyFooter: React.ReactElement = (
    <InterUIButton onClick={onSubmit}>{text.btnLabel}</InterUIButton>
  );

  const goToSelectableFundsPage = () => {
    if (!LocalStorageService.hasExpired(LocalStorageKeys.AVAILABLE_FUNDS)) {
      history.push(PageRoutes.SELECTABLE_FUNDS);
      return;
    }

    if (loading) return;

    dispatch(
      SelectableFundsActions.requestAvailableFunds({
        history,
        pathname: PageRoutes.SELECTABLE_FUNDS,
      }),
    );
  };

  useEffect(() => {
    dispatch(NavbarActions.setTitle(PageTitles.PROPOSAL_BY_PORTABILITY));
  }, [dispatch]);
  return (
    <InterUIContainer stickyFooter={stickyFooter}>
      <P
        fontFamily="Sora"
        fontWeight={600}
        fontSize={FontSizeREM.PX24}
        lineHeight={FontSizeREM.PX30}
        margin="2px 0 8px"
        color={theme.colors.grayscale.A500}
      >
        {text.title}
      </P>
      <P
        fontWeight={400}
        fontSize={FontSizeREM.PX14}
        lineHeight={FontSizeREM.PX17}
        margin="0 0 24px"
        color={theme.colors.grayscale.A400}
      >
        {text.message}
      </P>
      <InvestmentFundCard
        selected
        investmentFund={selectedFund}
        marginBottom="16px"
        onSelect={(fund) => setSelectedFund(fund)}
        hideShadow
      />

      <InterUIButton
        small
        variant="secondary"
        onClick={() => goToSelectableFundsPage()}
        loading={loading}
      >
        Escolher outro fundo
      </InterUIButton>
    </InterUIContainer>
  );
};

export default ProposalByPortabilityPage;
