import { AnyAction, Reducer } from 'redux';
import { UserInfoState, UserInfoTypes } from './types';

const INITIAL_STATE: UserInfoState = {
  info: {
    name: '',
    email: '',
    account: '',
    birthDate: '',
    cpf: '',
    cellPhoneNumber: '',
    phoneNumber: '',
    addressComplement: '',
    addressName: '',
    addressNumber: '',
    cep: '',
    city: '',
    neighborhood: '',
    state: '',
    monthlyIncome: 0,
  },
  signedTerms: {
    investorProfile: '',
    terms: [],
    isAcceptable: undefined,
    whichIsMissing: {
      isSuitabilityMissing: false,
      isInvestorProfileMissing: false,
    },
  },
  hasPrivatePension: false,
};

const reducer: Reducer<UserInfoState> = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case UserInfoTypes.SET_USER_INFO: {
      return { ...state, info: action.payload };
    }
    case UserInfoTypes.SET_USER_BALANCE_INFO: {
      return { ...state, balanceInfo: action.payload };
    }
    case UserInfoTypes.SET_USER_HAS_PRIVATE_PENSION: {
      return { ...state, hasPrivatePension: action.payload };
    }
    case UserInfoTypes.SET_USER_SIGNED_TERMS: {
      return { ...state, signedTerms: action.payload };
    }
    case UserInfoTypes.RESET: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
