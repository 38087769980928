import { formatCurrency, InterUIButton } from '@interco/inter-ui-react-lib';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ProposalAnalyticsService from '../../analytics/proposalAnalyticsService';
import { InvestmentFundCard } from '../../components';
import { LocalStorageKeys } from '../../enums/localStorageKeys';
import { PageRoutes } from '../../enums/pageRoutes';
import PageTitles from '../../enums/pageTitles';
import { PrivatePensions } from '../../enums/privatePensions';
import LocalStorageService from '../../services/localStorageService';
import ContributionValuesAction from '../../store/contributionValues/actions';
import ErrorActions from '../../store/error/actions';
import { LoadingActions } from '../../store/loading/actions';
import NavbarActions from '../../store/navbar/actions';
import { ProposalDetailActions } from '../../store/proposal/actions';
import { ProposalTypes } from '../../store/proposal/types';
import SelectableFundsActions from '../../store/selectableFunds/actions';
import TermsAndConditionsActions from '../../store/termAndCondition/actions';
import Colors from '../../styles/colors';
import { Div, Separator, SeparatorDashed } from '../../styles/commons';
import { InterContainerNoFooter } from '../../styles/inter-ui-customizations';
import { DebitInfo, DeclarationTable, Insurance, PlanTypes } from './components';
import { TableLabel } from './components/DeclarationTable/DeclarationTable';
import RegressiveTaxWarning from './components/RegressiveTaxWarning/RegressiveTaxWarning';
import Simulation from './components/Simulation/Simulation';

export interface IBestOption {
  tableLabel: TableLabel;
  privatePension: PrivatePensions;
}

const ProposalPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const type = useSelector(ProposalDetailActions.getType);
  const investmentFund = useSelector(ProposalDetailActions.getInvestmentFund);
  const insurenceCoverage = useSelector(ProposalDetailActions.getInsuranceCoverage);
  const contributionTime = useSelector(ProposalDetailActions.getNumberMonthsInvesting);
  const loading = useSelector(LoadingActions.get);
  const initialContribution = useSelector(ProposalDetailActions.getInitialContribution);
  const monthlyContribution = useSelector(ProposalDetailActions.getMonthlyContribution);
  const hasInsuranceCoverage = useSelector(ProposalDetailActions.getHasInsuranceCoverage);
  const currentPaymentDay = useSelector(ProposalDetailActions.getPaymentDay);
  const currentPrivatePension = useSelector(ProposalDetailActions.getPrivatePensionType);
  const currentTaxType = useSelector(ProposalDetailActions.getTaxType);
  const incomeTaxComplete = useSelector(ProposalDetailActions.getIncomeTaxComplete);
  const incomeTaxSimplified = useSelector(ProposalDetailActions.getIncomeTaxSimplified);

  const goToSelectableFundsPage = () => {
    if (!LocalStorageService.hasExpired(LocalStorageKeys.AVAILABLE_FUNDS)) {
      history.push(PageRoutes.SELECTABLE_FUNDS);
      return;
    }

    if (loading) return;

    ProposalAnalyticsService.editFundButton(investmentFund.name, type);

    dispatch(
      SelectableFundsActions.requestAvailableFunds({
        history,
        pathname: PageRoutes.SELECTABLE_FUNDS,
      }),
    );
  };

  const goToRevisionPage = () => {
    ProposalAnalyticsService.contractProposal({
      hasInsurance:
        monthlyContribution > 0 && insurenceCoverage !== undefined ? hasInsuranceCoverage : false,
      content_fundo: investmentFund.name,
      content_tempo: String(contributionTime),
      price_inicial: formatCurrency(initialContribution),
      price_mensal: formatCurrency(monthlyContribution),
      content_data: String(currentPaymentDay),
      content_mod: currentPrivatePension,
      content_trib: currentTaxType,
    });
    history.push(PageRoutes.REVISION);
  };

  const disableButton = (): boolean => {
    if (monthlyContribution === 0) return false;
    if (insurenceCoverage !== undefined && hasInsuranceCoverage === undefined) return true;

    return false;
  };

  const shouldExibeTable = () =>
    type === ProposalTypes.INCOME_TAX && incomeTaxComplete && incomeTaxComplete.taxableBase > 0;

  const bestOption = useMemo<IBestOption | undefined>(() => {
    if (type !== ProposalTypes.INCOME_TAX) {
      return undefined;
    }
    return incomeTaxComplete &&
      incomeTaxSimplified &&
      incomeTaxComplete.restitutionValue - incomeTaxSimplified.restitutionValue > 0
      ? { tableLabel: 'Completa', privatePension: PrivatePensions.PGBL }
      : { tableLabel: 'Simplificada', privatePension: PrivatePensions.VGBL };
  }, [incomeTaxComplete, incomeTaxSimplified, type]);

  useEffect(() => {
    ProposalAnalyticsService.proposal(
      {
        content_fundo: investmentFund.name,
        content_tempo: String(contributionTime),
        price_inicial: formatCurrency(initialContribution),
        price_mensal: formatCurrency(monthlyContribution),
        content_data: String(currentPaymentDay),
        content_mod: currentPrivatePension,
        content_trib: currentTaxType,
      },
      type,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Stryker disable all
  useEffect(() => {
    dispatch(NavbarActions.setTitle(PageTitles.PROPOSAL));
    dispatch(ContributionValuesAction.resetState());
    dispatch(TermsAndConditionsActions.resetState());
    dispatch(ErrorActions.resetState());
  }, [dispatch]);
  // Stryker restore all

  return (
    <InterContainerNoFooter margin="0 24px 24px">
      <InvestmentFundCard investmentFund={investmentFund} marginBottom="16px" />
      <InterUIButton
        small
        variant="secondary"
        onClick={() => goToSelectableFundsPage()}
        loading={loading}
        data-testid="select-funds-button"
      >
        Escolher outro fundo
      </InterUIButton>
      <SeparatorDashed marginTop="24px" marginBottom="24px" color={Colors.shuttleGrayDisabled} />
      <DebitInfo />
      <Div width="100vw" margin="24px 0px 24px -24px">
        <Separator marginTop="32px" marginBottom="32px" color={Colors.ghostWhite} height="8px" />
      </Div>
      <PlanTypes bestOption={bestOption} />
      {shouldExibeTable() && bestOption && (
        <>
          <Div width="100vw" margin="24px 0px 24px -24px">
            <Separator
              marginTop="32px"
              marginBottom="32px"
              color={Colors.ghostWhite}
              height="8px"
            />
          </Div>
          <DeclarationTable bestOption={bestOption} />
        </>
      )}
      {contributionTime > 2 && (
        <>
          <Div width="100vw" margin="24px 0px 24px -24px">
            <Separator
              marginTop="49px"
              marginBottom="32px"
              color={Colors.ghostWhite}
              height="8px"
            />
          </Div>
          <Simulation />
        </>
      )}
      {insurenceCoverage && monthlyContribution > 0 && (
        <>
          <Div width="100vw" margin="24px 0px 24px -24px">
            <Separator
              marginTop="16px"
              marginBottom="32px"
              color={Colors.ghostWhite}
              height="8px"
            />
          </Div>
          <Insurance />
        </>
      )}
      <RegressiveTaxWarning type={type} currentTaxType={currentTaxType} />
      <InterUIButton
        margin="48px 0 18px 0"
        disabled={disableButton()}
        onClick={goToRevisionPage}
        data-testid="proposal-button"
      >
        Contratar previdência privada
      </InterUIButton>
    </InterContainerNoFooter>
  );
};

export default ProposalPage;
