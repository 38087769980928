/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { InterUIContainer } from '@interco/inter-ui-react-lib';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import FlexWithHook from '../FlexWithHook/FlexWithHook';

interface FormWithHookConfig {
  header?: JSX.Element;
  stickyFooter?: JSX.Element;
  methods: UseFormReturn<any>;
  margin?: string;
  formId?: string;
  onSubmit: (data: any) => void;
}
/**
 * @param header Jsx Element that will come above the form;
 * @param stickyFooter Jsx Element that will come at the end of the form, usually a button;
 * @param methods Custom useForm definition;
 * @param defaultValues Preenche os campos gerenciáveis do formulário com valores defaults;
 * Essa propriedade NÃO deve ser provida caso o parametro methods seja provido.
 * Nesse caso o defaultValues deve ser informado na definição da origem do useForm;
 * @param margin Margin to add to form @default '24px'
 * @function onSubmit Function to be called if the form is valid to be submitted;
 * @returns Form component that uses the react-hook-form lib
 * in managing your fields
 */
export const FormWithHook: React.FC<FormWithHookConfig> = ({
  margin = '24px',
  header,
  children,
  stickyFooter,
  methods,
  formId,
  onSubmit,
}) => {
  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} id={formId} data-testid={formId}>
      <InterUIContainer margin="0" stickyFooter={stickyFooter}>
        {header}

        <InterUIContainer margin={margin}>
          {React.Children.map(children, (child: any) => {
            let formStateProp = {};
            const isHookComponent = child.type.name === FlexWithHook.name;
            if (isHookComponent) {
              formStateProp = { formState: methods.formState };
            }
            const childElement = React.createElement(child.type, {
              ...{
                ...child.props,
                ...formStateProp,
                register: methods.register,
                setValue: methods.setValue,
                getValues: methods.getValues,
                setFocus: methods.setFocus,
                control: methods.control,
                errors: methods.formState?.errors[child.props.name],
                key: child.props.name,
              },
            });
            return child?.props.name || isHookComponent ? childElement : child;
          })}
        </InterUIContainer>
      </InterUIContainer>
    </form>
  );
};
