/**
 * {*} - Should to be replaced by the param using @function getMessages
 */

export enum MessagesValidation {
  MINIMUM_VALUE_VALIDATION = 'O valor mínimo é {*}',
  INITIAL_CONTRIBUTION_REQUIRED = 'O valor inicial é obrigatório',
  MONTHLY_CONTRIBUTION_REQUIRED = 'O valor mensal é obrigatório',
  ANNUAL_CONTRIBUTION_UNDER_MAXIMUM_DEDUCTIBLE_AMOUNT_DIFF = 'Você pode investir mais {*} para aproveitar o valor máximo dedutível na declaração do seu IR em {*}.',
  ANNUAL_CONTRIBUTION_UNDER_MAXIMUM_DEDUCTIBLE_AMOUNT_FOR_INITIAL = 'Você pode investir {*} por ano para aproveitar o valor máximo dedutível na declaração do seu IR em {*}.',
  ANNUAL_CONTRIBUTION_UNDER_MAXIMUM_DEDUCTIBLE_AMOUNT_FOR_MONTHLY = 'Você pode investir {*} por mês para aproveitar o valor máximo dedutível na declaração do seu IR em {*}.',
  MAXIMUM_DEDUCTIBLE_AMOUNT_EXCEEDS = 'Com esse valor, {*} serão dedutíveis na declaração do seu IR em {*}, e {*} não serão dedutíveis.',
  ANNUAL_CONTRIBUTION_UNDER_MAXIMUM_DEDUCTIBLE_AMOUNT_ADDITIONAL_CONTRIBUTION = 'Você ainda pode investir mais {*} por ano para aproveitar o valor máximo dedutível no seu IR.',
  MAXIMUM_DEDUCTIBLE_AMOUNT_EXCEEDS_ADDITIONAL_CONTRIBUTION = 'Com esse valor, {*} serão dedutíveis no IR, e {*} não serão dedutíveis, a cada ano.',
  MAXIMUM_DEDUCTIBLE_AMOUNT_EXCEEDS_FOR_CONTRIBUTION_TIME = 'Com esse novo prazo de aposentadoria, {*} serão dedutíveis na declaração do seu IR em {*}, e {*} não serão dedutíveis.',
  MAXIMUM_DEDUCTIBLE_AMOUNT_EXCEEDS_FOR_PAYMENT_DAY = 'Com os novos dados informados, {*} serão dedutíveis na declaração do seu IR em {*}, e {*} não serão dedutíveis.',
  EMPTY = '',
}

/**
 * Function to return the messages from enum
 * @param message to format and return
 * @param params to format the messages
 * @returns formattedMessage with {*} replaced by params,
 * if no params was informed @returns message from enum.
 */
export function getMessages(message: MessagesValidation, params?: string[]): string {
  if (!params) {
    return message;
  }

  let formattedMessage = message.toString();

  params.forEach((param) => {
    formattedMessage = formattedMessage.replace(/{\*}/, `${param}`);
  });

  return formattedMessage;
}
