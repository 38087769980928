import { Reducer } from 'redux';
import { PrivatePensions, TaxTypes } from '../../enums/privatePensions';
import { DeepLinkProposalState, DeepLinkProposalTypes } from './types';

const INITIAL_STATE: DeepLinkProposalState = {
  initialContribution: 0,
  monthlyContribution: 0,
  privatePensionType: PrivatePensions.EMPTY,
  taxType: TaxTypes.EMPTY,
  investmentFundcode: '',
  numberMonthsInvesting: 0,
  paymentDay: 0,
  error: false,
};

export const reducer: Reducer<DeepLinkProposalState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DeepLinkProposalTypes.SET_DEEP_LINK_PROPOSAL: {
      return { ...state, ...action.payload };
    }
    case DeepLinkProposalTypes.SET_DEEP_LINK_PROPOSAL_FAILURE: {
      return { ...state, error: action.payload };
    }
    case DeepLinkProposalTypes.RESET: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
