/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
import {
  InterUIAlert,
  InterUIBottomSheet,
  InterUIBox,
  InterUIButton,
  InterUIIcon,
} from '@interco/inter-ui-react-lib';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import IncomeExpenseAnalyticsService from '../../analytics/incomeExpenseAnalyticsService';
import { FormWithHook } from '../../components/FormWithHook/FormWithHook';
import InputPricingWithHook from '../../components/InputPricingWithHook/InputPricingWithHook';
import { RadioWithHook } from '../../components/RadioWithHook/RadioWithHook';
import SelectWithHook from '../../components/SelectWithHook/SelectWithHook';
import FontSizeREM from '../../enums/fontSizesRem';
import { LocalStorageKeys } from '../../enums/localStorageKeys';
import { getMessages, MessagesValidation } from '../../enums/messages';
import { PageRoutes } from '../../enums/pageRoutes';
import PageTitles from '../../enums/pageTitles';
import LocalStorageService from '../../services/localStorageService';
import { IncomeExpenseActions } from '../../store/incomeExpenses/actions';
import NavbarActions from '../../store/navbar/actions';
import UserInfoActions from '../../store/user/actions';
import { ThemeInter } from '../../styles';
import { Div, Flex, H1, P, PSmallGray, SeparatorDashed } from '../../styles/commons';
import { InterUIAlertCustomized } from '../../styles/inter-ui-customizations';
import { convertValueMaskedToNumber } from '../../utils/inputPricingUtils';
import { MINIMUM_INCOME_ACCEPTED, MINIMUM_INCOME_TO_DECLARE } from '../../utils/minValues';
import { formatToBRLCurrency } from '../../utils/numberFormatUtils';

interface IIncomeExpenseForm {
  annualIncome: number;
  monthlyEducationExpenditure: number;
  numberOfDependents: number;
  monthlyEducationExpenditureDependents: number;
  monthlyHealthExpenditure: number;
  socialSecurityContribution: boolean | string;
}

interface IBottomSheetState {
  show: boolean;
  title: string;
  description: string[];
}

type BottomSheetInfoType =
  | 'DEPENDENTS'
  | 'HEALTH'
  | 'ANNUAL_INCOME'
  | 'CALENDAR_YEAR'
  | 'YEAR_OF_EXERCISE';

const DEPENDENTS_INFO = {
  title: 'Dependentes',
  description: [
    'A Receita Federal permite que você inclua algumas pessoas como dependentes em sua declaração de IR.',
    'Alguns exemplos são cônjuge ou companheiro de união estável, filhos e enteados de até 21 anos, filhos de qualquer idade se forem incapacitados para trabalhar e filhos de até 24 anos se estiverem cursando o ensino superior.',
    'Você pode consultar a lista completa no site da Receita Federal.',
  ],
};

const HEALTH_INFO = {
  title: 'Gastos com saúde',
  description: [
    'A Receita Federal permite que você deduza diversos gastos com saúde em sua declaração de IR.',
    'Alguns exemplos são plano de saúde, consultas a profissionais de saúde, implantes dentários, exames laboratoriais, cirurgias e internações.',
    'Alguns dos gastos não abatidos são aqueles com prótese de silicone, tratamentos de beleza, exame de DNA e vacinas.',
    'Você pode consultar a lista completa, incluindo as especificações, no site da Receita Federal.',
  ],
};

const ANNUAL_INCOME_INFO = {
  title: 'Renda anual tributável',
  description: [
    'Para saber sua renda anual tributável, basta somar seus recebimentos de salário no ano, suas férias e 1/3 das férias.',
    'Podem entrar no cálculo também, os aluguéis e pensões recebidas, se houverem.',
    'Se quiser conferir a lista completa de rendimentos tributáveis, acesse o manual da Receita Federal.',
  ],
};

const CALENDAR_YEAR_INFO = {
  title: 'Ano das Receitas e Despesas',
  description: [
    'O ano das receitas e despesas, ou ano-calendário, é o período de 12 meses que vai de 01/Jan a 31/Dez, em que são registradas as movimentações financeiras (receitas e despesas) do contribuínte.',
    'A prestação de contas das receitas e despesas desse período sempre ocorrerão no ano seguinte, ano da declaração do IR.',
  ],
};

const YEAR_OF_EXERCISE_INFO = {
  title: 'Ano da Declaração do IR',
  description: [
    'O ano da declaração do IR, ou ano-exercício, é o ano em que a declaração de Imposto de Renda deve ser entregue, levando em consideração as movimentações financeiras (receitas e despesas) que ocorreram no ano anterior.',
  ],
};

const IncomeExpensePage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme() as ThemeInter;
  const userInfo = useSelector(UserInfoActions.get);

  const currentYear = new Date().getFullYear();
  const yearOfExercise = currentYear + 1;

  const dataFromLocalStorage = LocalStorageService.getItem<IIncomeExpenseForm>(
    LocalStorageKeys.INCOME_EXPENSE_FIELDS,
    userInfo.account,
  );
  const incomeExpenseDefaultValues = dataFromLocalStorage ? dataFromLocalStorage.data : {};

  const [bottomSheet, setBottomSheet] = useState<IBottomSheetState>({
    show: false,
    title: '',
    description: [''],
  });

  const hasValidLocalStorageItem = (): boolean =>
    !LocalStorageService.hasExpired(LocalStorageKeys.INCOME_EXPENSE_FIELDS, userInfo.account);

  const getLocalStorageItem = () =>
    LocalStorageService.getItem<any>(LocalStorageKeys.INCOME_EXPENSE_FIELDS, userInfo.account);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setLocalStorageItem = (item: any) => {
    const expiresAt = new Date();
    expiresAt.setMonth(expiresAt.getMonth() + 1);
    expiresAt.setHours(0, 0, 0, 0);
    LocalStorageService.setItem(
      LocalStorageKeys.INCOME_EXPENSE_FIELDS,
      {
        requestDate: new Date(),
        expiresAt,
        data: item,
      },
      userInfo.account,
    );
  };

  useEffect(() => {
    const request = getLocalStorageItem();

    if (request) {
      const isValidItems = hasValidLocalStorageItem();
      if (!isValidItems) {
        setLocalStorageItem({});
      }
    } else {
      setLocalStorageItem({});
    }
  }, []);

  const handlerBottomSheetCloseButton = (
    bottomSheetParams: IBottomSheetState,
    show: boolean,
  ): void => {
    setBottomSheet({ ...bottomSheetParams, show });
    IncomeExpenseAnalyticsService.bottomSheetClickButton(bottomSheetParams.title);
  };

  const methods = useForm<IIncomeExpenseForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      ...incomeExpenseDefaultValues,
    },
  });

  const {
    getValues,
    setValue,
    formState: { isValid, isDirty },
  } = methods;

  const callBottomSheetInfo = (type: BottomSheetInfoType) => {
    switch (type) {
      case 'DEPENDENTS': {
        setBottomSheet({ show: true, ...DEPENDENTS_INFO });
        break;
      }
      case 'HEALTH': {
        setBottomSheet({ show: true, ...HEALTH_INFO });
        break;
      }
      case 'ANNUAL_INCOME': {
        setBottomSheet({ show: true, ...ANNUAL_INCOME_INFO });
        break;
      }
      case 'CALENDAR_YEAR': {
        setBottomSheet({ show: true, ...CALENDAR_YEAR_INFO });
        break;
      }
      case 'YEAR_OF_EXERCISE': {
        setBottomSheet({ show: true, ...YEAR_OF_EXERCISE_INFO });
        break;
      }
      default: {
        break;
      }
    }
  };

  const isTheInformationalNecessary = useMemo<boolean>(() => {
    const annualIncome = getValues('annualIncome') ? getValues('annualIncome') : 0;

    if (annualIncome >= MINIMUM_INCOME_ACCEPTED && annualIncome < MINIMUM_INCOME_TO_DECLARE) {
      return true;
    }
    return false;
  }, [getValues('annualIncome')]);

  const isDependentsEducationExpenditureDisabled: boolean = useMemo(
    () => !getValues('numberOfDependents'),
    [getValues('numberOfDependents')],
  );

  const isDisableButton = useMemo(
    () => !isValid || getValues('socialSecurityContribution') === null,
    [isValid, isDirty, getValues('socialSecurityContribution')],
  );

  const onSubmit = (data: IIncomeExpenseForm) => {
    const result = { ...data };
    result.numberOfDependents = Number.isNaN(data.numberOfDependents) ? 0 : data.numberOfDependents;
    dispatch(IncomeExpenseActions.set({ ...result }));

    IncomeExpenseAnalyticsService.continueClickButton(
      'Continuar',
      formatToBRLCurrency(result.annualIncome),
      formatToBRLCurrency(result.monthlyEducationExpenditure),
      String(result.numberOfDependents),
      formatToBRLCurrency(result.monthlyEducationExpenditureDependents),
      formatToBRLCurrency(result.monthlyHealthExpenditure),
      result.socialSecurityContribution === 'TRUE' ? 'SIM' : 'NAO',
    );
    history.push(PageRoutes.INCOME_TAX);
  };

  useEffect(() => {
    dispatch(NavbarActions.setTitle(PageTitles.INCOME_EXPENSE));
  }, [dispatch]);

  const stickyFooter = (
    <InterUIButton margin="0 24px 24px" disabled={isDisableButton}>
      Continuar
    </InterUIButton>
  );

  useEffect(() => {
    if (+getValues('numberOfDependents') === 0) {
      setValue('monthlyEducationExpenditureDependents', 0);
    }
  }, [getValues('numberOfDependents')]);

  const persistIRData = (name: string, value: string | number) => {
    const request = getLocalStorageItem();
    if (request) {
      const item = request.data;
      item[name] = value;
      setLocalStorageItem(item);
    }
  };

  return (
    <FormWithHook stickyFooter={stickyFooter} onSubmit={onSubmit} methods={methods}>
      <H1 marginBottom="8px">Seu cálculo</H1>
      <PSmallGray marginBottom="20px">
        Para esse objetivo, informe a sua renda anual total, aquela que você informa no momento da
        declaração de IR
      </PSmallGray>
      <InterUIAlert type="attention" margin="0 0 16px 0">
        <P
          fontWeight={700}
          fontSize={FontSizeREM.PX12}
          lineHeight={FontSizeREM.PX15}
          marginBottom="4px"
        >
          Suas informações serão salvas
        </P>
        <P fontWeight={400} fontSize={FontSizeREM.PX12} lineHeight={FontSizeREM.PX15}>
          Iremos salvar os dados abaixo por 30 dias, após esse prazo, será preciso informá-los
          novamente.
        </P>
      </InterUIAlert>
      <Div>
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          margin="0 0 8px"
        >
          <P fontFamily="Sora" margin="0">
            Ano das Receitas e Despesas
          </P>
          <button
            type="button"
            onClick={() => callBottomSheetInfo('CALENDAR_YEAR')}
            aria-label="Ano das Receitas e Despesas"
          >
            <InterUIIcon name="help" color={theme.colors.primary.A500} size="16px" />
          </button>
        </Flex>
        <P
          fontWeight={700}
          fontSize={FontSizeREM.PX22}
          lineHeight={FontSizeREM.PX22}
          color={theme.colors.primary.A500}
          margin="0 0 8px"
        >
          {currentYear}
        </P>
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          margin="0 0 8px"
        >
          <P fontFamily="Sora" margin="0">
            Ano da Declaração do IR
          </P>
          <button
            type="button"
            onClick={() => callBottomSheetInfo('YEAR_OF_EXERCISE')}
            aria-label="Ano da Declaração do IR"
          >
            <InterUIIcon name="help" color={theme.colors.primary.A500} size="16px" />
          </button>
        </Flex>
        <P
          fontWeight={700}
          fontSize={FontSizeREM.PX22}
          lineHeight={FontSizeREM.PX22}
          color={theme.colors.primary.A500}
          margin="0 0 8px"
        >
          {yearOfExercise}
        </P>
      </Div>
      <SeparatorDashed color={theme.colors.grayscale.A400} marginBottom="24px" marginTop="24px" />
      <Div position="relative">
        <Div position="absolute" top="0" right="0" zIndex={3}>
          <button
            type="button"
            onClick={() => callBottomSheetInfo('ANNUAL_INCOME')}
            aria-label="Sua renda bruta anual tributável"
          >
            <InterUIIcon name="help" color={theme.colors.primary.A500} size="16px" />
          </button>
        </Div>
      </Div>
      <InputPricingWithHook
        name="annualIncome"
        label="Sua renda bruta anual tributável"
        placeholder="0,00"
        prefix="R$"
        maxLength={14}
        margin="0"
        validation={{
          min: {
            value: MINIMUM_INCOME_ACCEPTED,
            message: getMessages(MessagesValidation.MINIMUM_VALUE_VALIDATION, [
              formatToBRLCurrency(MINIMUM_INCOME_ACCEPTED),
            ]),
          },
        }}
        onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
          persistIRData('annualIncome', convertValueMaskedToNumber(e.target.value))
        }
      />
      <Div>
        {isTheInformationalNecessary && (
          <InterUIBox padding="0px" margin="0 0 16px">
            <InterUIAlertCustomized
              color={theme.colors.white}
              icon={<InterUIIcon name="contextual-info" color={theme.colors.alert.A400} />}
              alignItems="center"
            >
              <PSmallGray marginBottom="0">
                Lembre-se que para economizar no IR com a previdência privada, você precisa fazer a
                sua declaração do tipo completa.
              </PSmallGray>
            </InterUIAlertCustomized>
          </InterUIBox>
        )}
      </Div>
      <SeparatorDashed color={theme.colors.grayscale.A400} marginBottom="24px" marginTop="8px" />
      <P fontFamily="Sora">Gastos com sua educação</P>
      <PSmallGray>
        Informe pra gente um valor total aproximado do quanto você gasta{' '}
        <strong>mensalmente</strong> com sua educação.
      </PSmallGray>
      <InputPricingWithHook
        name="monthlyEducationExpenditure"
        placeholder="0,00"
        prefix="R$"
        maxLength={14}
        margin="0"
        onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
          persistIRData('monthlyEducationExpenditure', convertValueMaskedToNumber(e.target.value))
        }
      />
      <SeparatorDashed color={theme.colors.grayscale.A400} marginBottom="24px" />
      <Flex>
        <Div position="relative">
          <P fontFamily="Sora">Dependentes</P>
          <Div position="absolute" top="0" right="0">
            <button
              type="button"
              onClick={() => callBottomSheetInfo('DEPENDENTS')}
              aria-label="Quem são os dependentes?"
            >
              <InterUIIcon name="help" color={theme.colors.primary.A500} size="16px" />
            </button>
          </Div>
        </Div>
        <PSmallGray marginBottom="16px">
          Selecione o número de dependentes que você insere na sua declaração de IR.
        </PSmallGray>
      </Flex>
      <SelectWithHook
        name="numberOfDependents"
        type="number"
        placeholder="Número de dependentes"
        onBlur={(e: React.ChangeEvent<HTMLSelectElement>) =>
          persistIRData('numberOfDependents', parseInt(e.target.value, 10))
        }
      >
        {Array.from(Array(11).keys()).map((nDependents) => {
          const key = `dependents-${nDependents}`;
          return (
            <option key={key} value={nDependents}>
              {nDependents}
            </option>
          );
        })}
      </SelectWithHook>
      <SeparatorDashed color={theme.colors.grayscale.A400} marginBottom="24px" />
      <P
        fontFamily="Sora"
        lineHeight={FontSizeREM.PX19}
        disabled={isDependentsEducationExpenditureDisabled}
      >
        Gastos com educação de dependentes
      </P>
      <PSmallGray disabled={isDependentsEducationExpenditureDisabled}>
        Aqui, preencha com o valor total aproximado do quanto você gasta{' '}
        <strong>mensalmente</strong> com educação do(s) dependente(s).
      </PSmallGray>
      <InputPricingWithHook
        name="monthlyEducationExpenditureDependents"
        placeholder="0,00"
        prefix="R$"
        maxLength={14}
        margin="0"
        disabled={isDependentsEducationExpenditureDisabled}
        onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
          persistIRData(
            'monthlyEducationExpenditureDependents',
            convertValueMaskedToNumber(e.target.value),
          )
        }
      />
      <SeparatorDashed color={theme.colors.grayscale.A400} marginBottom="24px" />
      <Flex>
        <Div position="relative">
          <P fontFamily="Sora">Gastos com saúde</P>
          <Div position="absolute" top="0" right="0">
            <button
              type="button"
              onClick={() => callBottomSheetInfo('HEALTH')}
              aria-label="Quais gastos com saúde são deduziveis?"
            >
              <InterUIIcon name="help" color={theme.colors.primary.A500} size="16px" />
            </button>
          </Div>
        </Div>
        <PSmallGray marginBottom="16px">
          Agora, informe o valor total aproximado do quanto você gasta <strong>mensalmente</strong>{' '}
          com saúde, incluindo os dependentes, se houverem.
        </PSmallGray>
      </Flex>
      <InputPricingWithHook
        name="monthlyHealthExpenditure"
        placeholder="0,00"
        prefix="R$"
        maxLength={14}
        margin="0"
        onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
          persistIRData('monthlyHealthExpenditure', convertValueMaskedToNumber(e.target.value))
        }
      />
      <SeparatorDashed color={theme.colors.grayscale.A400} marginBottom="24px" />
      <P fontFamily="Sora" lineHeight={FontSizeREM.PX19}>
        Contribuição para o INSS
      </P>
      <PSmallGray>Indique se você contribui para o INSS ou não.</PSmallGray>
      <RadioWithHook
        variant="choose-item"
        type="boolean"
        label="Sim, contribuo"
        id="yes"
        name="socialSecurityContribution"
        value="TRUE"
        onClick={() => persistIRData('socialSecurityContribution', 'TRUE')}
      />
      <RadioWithHook
        variant="choose-item"
        type="boolean"
        label="Não contribuo"
        id="no"
        name="socialSecurityContribution"
        value="FALSE"
        onClick={() => persistIRData('socialSecurityContribution', 'FALSE')}
      />
      <InterUIBottomSheet
        title={bottomSheet.title}
        toggle={bottomSheet.show}
        onHide={() => handlerBottomSheetCloseButton({ ...bottomSheet }, false)}
      >
        {bottomSheet.description.map((paragraph, index) => (
          <PSmallGray key={`paragraph-${index}`}>{paragraph}</PSmallGray>
        ))}
        <InterUIButton
          type="button"
          onClick={() => handlerBottomSheetCloseButton({ ...bottomSheet }, false)}
          margin="32px 0px 0px 0px"
        >
          Entendi
        </InterUIButton>
      </InterUIBottomSheet>
    </FormWithHook>
  );
};

export default IncomeExpensePage;
