/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-prototype-builtins */
import { formatCurrency, InterUILoading } from '@interco/inter-ui-react-lib';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DeepLinkProposalAnalytics from '../../analytics/deepLinkProposalAnalytics';
import { PageRoutes } from '../../enums/pageRoutes';
import { MonitoringService } from '../../services/monitoringService';
import DeepLinkProposalActions from '../../store/deeplinkProposal/actions';
import GlobalActions from '../../store/global/actions';
import { LoadingActions } from '../../store/loading/actions';
import NavbarActions from '../../store/navbar/actions';
import { ProposalDetailActions } from '../../store/proposal/actions';
import { Flex } from '../../styles/commons';
import { isDeepLinkParamsValid } from '../../utils/isDeepLinkParamsValid';

const DeepLinkSplash: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const proposalParams = useSelector(GlobalActions.getQueryParams);
  const loading = useSelector(LoadingActions.get);
  const { isParamsValid, paramsError } = isDeepLinkParamsValid(proposalParams);
  const { error } = useSelector(DeepLinkProposalActions.getDeepLinkProposal);

  const {
    codigoFundo,
    diaDebito,
    modalidade,
    tabela,
    valorInicial,
    valorMensal,
    tempoContribuicaoMeses,
  } = proposalParams;

  useEffect(() => {
    if (isParamsValid) {
      DeepLinkProposalAnalytics.success({
        fund_code: codigoFundo,
        debt_day: diaDebito,
        modality: modalidade,
        table: tabela,
        monthly_contribution_time: tempoContribuicaoMeses,
        initial_value: formatCurrency(+valorInicial!),
        monthly_value: formatCurrency(+valorMensal!),
      });
      dispatch(
        DeepLinkProposalActions.setDeepLinkProposal({
          initialContribution: +valorInicial!,
          monthlyContribution: +valorMensal!,
          privatePensionType: modalidade!,
          taxType: tabela!,
          investmentFundcode: codigoFundo!,
          numberMonthsInvesting: parseInt(tempoContribuicaoMeses!),
          paymentDay: parseInt(diaDebito!),
        }),
      );
      dispatch(
        ProposalDetailActions.requestDeepLinkProposal({ history, pathname: PageRoutes.PROPOSAL }),
      );
      dispatch(NavbarActions.setCanGoBack(false));
    } else {
      DeepLinkProposalAnalytics.error({
        fund_code: codigoFundo,
        debt_day: diaDebito,
        modality: modalidade,
        table: tabela,
        monthly_contribution_time: tempoContribuicaoMeses,
        initial_value: valorInicial,
        monthly_value: valorMensal,
      });
      MonitoringService.noticeError(new Error(paramsError), {
        errorCodeRef: 'DeepLinkSplash.invalidParams',
        deepLinkParams: JSON.stringify(proposalParams),
      });

      history.push({ pathname: PageRoutes.HOME });
    }
  }, []);

  useEffect(() => {
    if (error) {
      history.push({ pathname: PageRoutes.HOME });
    }
  }, [error, history]);

  return (
    <Flex height="100vh" justifyContent="center" alignItems="center">
      {loading && <InterUILoading size="ld" data-testid="loading" />}
    </Flex>
  );
};

export default DeepLinkSplash;
