/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { InterUIButton } from '@interco/inter-ui-react-lib';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import * as yup from 'yup';
import { InfoInitialMonthlyContribution } from '../../components';
import { FormWithHook } from '../../components/FormWithHook/FormWithHook';
import InputPricingWithHook from '../../components/InputPricingWithHook/InputPricingWithHook';
import { RadioWithHook } from '../../components/RadioWithHook/RadioWithHook';
import FontSizeREM from '../../enums/fontSizesRem';
import { getMessages, MessagesValidation } from '../../enums/messages';
import { PageRoutes } from '../../enums/pageRoutes';
import PageTitles from '../../enums/pageTitles';
import { LoadingActions } from '../../store/loading/actions';
import NavbarActions from '../../store/navbar/actions';
import { ProposalDetailActions } from '../../store/proposal/actions';
import { ProposalByFundActions } from '../../store/proposalByFund/actions';
import { ContributionStrategyType } from '../../store/proposalByFund/types';
import { ThemeInter } from '../../styles';
import { H1, P, PSmallGray, SeparatorDashed } from '../../styles/commons';
import { formatToBRLCurrency } from '../../utils/numberFormatUtils';

interface IProposalByFundForm {
  strategyContribution: ContributionStrategyType;
  initialValue: number | string;
  monthlyValue: number | string;
}

interface IContributionStrategy {
  type: ContributionStrategyType;
  title: string;
  subtitle: string;
}

const PROPOSAL_BY_FUND_CONTRIBUTION_PAGE_INFO = {
  title: 'Seu jeito de investir',
  text: 'Escolha como você quer investir no seu plano de aposentadoria na previdência privada.',
};

const VALUES_SECTION = {
  title: 'Defina os valores',
  text: 'Sabendo o quanto você quer investir, indicaremos o plano que mais faz sentido.',
  initialInputLabel: 'Valor inicial',
  monthlyInputLabel: 'Valor a cada mês',
};

const initianlContribution: IContributionStrategy = {
  type: ContributionStrategyType.INITIAL_CONTRIBUTION,
  title: 'Um valor único',
  subtitle: 'Você define um valor que será investido uma única vez, logo no início',
};

const monthlyContribution: IContributionStrategy = {
  type: ContributionStrategyType.MONTHLY_CONTRIBUTION,
  title: 'A cada mês',
  subtitle: 'Você define o valor que será investido mensalmente',
};

const initialMonthlyContribution: IContributionStrategy = {
  type: ContributionStrategyType.INITIAL_MONTHLY_CONTRIBUTION,
  title: 'Um valor inicial + a cada mês',
  subtitle: 'Um valor único no início + valores mensais',
};

function getSchemaByProps(initialMinimum: number, monthlyMinimum: number) {
  let initialValue;
  let monthlyValue;

  if (initialMinimum === 0) {
    initialValue = yup.string().nullable();
  } else {
    initialValue = yup
      .string()
      .test(
        'min',
        getMessages(MessagesValidation.MINIMUM_VALUE_VALIDATION, [
          formatToBRLCurrency(initialMinimum),
        ]),
        (value) => {
          let parsedValue = null;
          if (value) {
            parsedValue = +value;
          }
          return !!parsedValue && parsedValue >= initialMinimum;
        },
      )
      .required(MessagesValidation.INITIAL_CONTRIBUTION_REQUIRED);
    monthlyValue = yup.string().nullable();
  }

  if (monthlyMinimum === 0) {
    monthlyValue = yup.string().nullable();
  } else {
    monthlyValue = yup
      .string()
      .test(
        'min',
        getMessages(MessagesValidation.MINIMUM_VALUE_VALIDATION, [
          formatToBRLCurrency(monthlyMinimum),
        ]),
        (value) => {
          let parsedValue = null;
          if (value) {
            parsedValue = +value;
          }
          return !!parsedValue && parsedValue >= monthlyMinimum;
        },
      )
      .required();
  }

  return yup.object().shape({
    initialValue,
    monthlyValue,
  });
}

const ProposalByFundContributionValuesPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme() as ThemeInter;
  const currentFund = useSelector(ProposalDetailActions.getInvestmentFund);
  const loading = useSelector(LoadingActions.get);

  const [monthlyMinimum, setMonthlyMinimum] = useState(currentFund.minimumMonthlyContribution);
  const [initialMinimum, setInitialMinimum] = useState(currentFund.minimumInitialContribution);

  const proposalByFundDefaultValues = useSelector(ProposalByFundActions.get);
  const [choosedRadio, setChoosedRadio] = useState<ContributionStrategyType>(
    proposalByFundDefaultValues.strategyContribution,
  );
  useEffect(() => {
    dispatch(NavbarActions.setTitle(PageTitles.RETIREMENT));
  }, [dispatch]);

  const methods = useForm<IProposalByFundForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      ...proposalByFundDefaultValues,
    },
    resolver: yupResolver(getSchemaByProps(initialMinimum, monthlyMinimum)),
  });

  const {
    trigger,
    setValue,
    formState: { isValid, dirtyFields },
    getValues,
  } = methods;

  const isDisabledButton = useMemo(() => !isValid, [isValid]);

  useEffect(() => {
    switch (choosedRadio) {
      case ContributionStrategyType.INITIAL_CONTRIBUTION: {
        const initialInput = document.getElementById('initialValue') as HTMLInputElement;
        setInitialMinimum(currentFund.minimumInitialContribution);
        setMonthlyMinimum(0);
        setValue('monthlyValue', '0,00');
        initialInput.scrollIntoView({ block: 'start', behavior: 'smooth' });
        initialInput.focus({ preventScroll: true });
        break;
      }
      case ContributionStrategyType.MONTHLY_CONTRIBUTION: {
        const monthlyInput = document.getElementById('monthlyValue') as HTMLInputElement;
        setInitialMinimum(0);
        setMonthlyMinimum(currentFund.minimumMonthlyContribution);
        setValue('initialValue', '0,00');
        monthlyInput.scrollIntoView({ block: 'start', behavior: 'smooth' });
        monthlyInput.focus({ preventScroll: true });
        break;
      }
      case ContributionStrategyType.INITIAL_MONTHLY_CONTRIBUTION: {
        const initialInput = document.getElementById('initialValue') as HTMLInputElement;
        setInitialMinimum(currentFund.minimumInitialContribution);
        setMonthlyMinimum(currentFund.minimumMonthlyContribution);
        initialInput.scrollIntoView({ block: 'start', behavior: 'smooth' });
        initialInput.focus({ preventScroll: true });
        break;
      }
      default:
        break;
    }
  }, [choosedRadio]);

  useEffect(() => {
    if (dirtyFields.initialValue) {
      trigger('initialValue');
    }
  }, [initialMinimum, dirtyFields.initialValue, trigger]);

  useEffect(() => {
    if (dirtyFields.monthlyValue) {
      trigger('monthlyValue');
    }
  }, [monthlyMinimum, dirtyFields.monthlyValue, trigger]);

  useEffect(() => {
    if (choosedRadio) {
      trigger('strategyContribution');
    }
  }, [trigger]);

  const stickyFooter: React.ReactElement = (
    <InterUIButton
      margin="0 24px 24px"
      type="submit"
      data-testid="retirement-by-fund-form-btn"
      disabled={isDisabledButton}
      loading={loading}
    >
      Mostrar plano indicado
    </InterUIButton>
  );

  const onSubmit = (data: IProposalByFundForm) => {
    dispatch(
      ProposalByFundActions.set({
        ...data,
        initialValue: +data.initialValue,
        monthlyValue: +data.monthlyValue,
        strategyContribution: data.strategyContribution,
      }),
    );
    dispatch(
      ProposalDetailActions.requestProposalByFund({
        history,
        pathname: PageRoutes.PROPOSAL,
      }),
    );
  };

  return (
    <FormWithHook stickyFooter={stickyFooter} onSubmit={onSubmit} methods={methods}>
      <H1 marginBottom="8px">{PROPOSAL_BY_FUND_CONTRIBUTION_PAGE_INFO.title}</H1>
      <PSmallGray marginBottom="24px">{PROPOSAL_BY_FUND_CONTRIBUTION_PAGE_INFO.text}</PSmallGray>
      <RadioWithHook
        variant="choose-item"
        type="text"
        label={{
          value: initianlContribution.title,
          options: { margin: '0 0 4px 0', maxWidth: '115px' },
        }}
        subtitle={initianlContribution.subtitle}
        name="strategyContribution"
        value={initianlContribution.type}
        checked={choosedRadio === initianlContribution.type}
        onClick={() => setChoosedRadio(initianlContribution.type)}
      />
      <RadioWithHook
        variant="choose-item"
        type="text"
        label={{
          value: monthlyContribution.title,
          options: { margin: '0 0 4px 0', maxWidth: '115px' },
        }}
        subtitle={monthlyContribution.subtitle}
        name="strategyContribution"
        value={monthlyContribution.type}
        checked={choosedRadio === monthlyContribution.type}
        onClick={() => setChoosedRadio(monthlyContribution.type)}
      />
      <RadioWithHook
        variant="choose-item"
        type="text"
        label={{
          value: initialMonthlyContribution.title,
          options: { margin: '0 0 4px 0', maxWidth: '115px' },
        }}
        subtitle={initialMonthlyContribution.subtitle}
        name="strategyContribution"
        value={initialMonthlyContribution.type}
        checked={choosedRadio === initialMonthlyContribution.type}
        onClick={() => setChoosedRadio(initialMonthlyContribution.type)}
      />
      <SeparatorDashed marginTop="22px" marginBottom="24px" color={theme.colors.grayscale.A200} />
      <P
        fontSize={FontSizeREM.PX16}
        fontWeight={600}
        lineHeight={FontSizeREM.PX20}
        marginBottom="8px"
        disabled={choosedRadio === ''}
      >
        {VALUES_SECTION.title}
      </P>
      <PSmallGray marginBottom="16px" disabled={choosedRadio === ''}>
        {VALUES_SECTION.text}
      </PSmallGray>

      <InputPricingWithHook
        name="initialValue"
        label={VALUES_SECTION.initialInputLabel}
        placeholder="0,00"
        prefix="R$"
        maxLength={14}
        margin="0 0 12px"
        onBlur={() => {}}
        disabled={
          choosedRadio !== initianlContribution.type &&
          choosedRadio !== initialMonthlyContribution.type
        }
        shouldDirty
      />
      <InputPricingWithHook
        name="monthlyValue"
        label={VALUES_SECTION.monthlyInputLabel}
        placeholder="0,00"
        prefix="R$"
        maxLength={14}
        margin="0 0 12px"
        onBlur={() => {}}
        disabled={
          choosedRadio !== monthlyContribution.type &&
          choosedRadio !== initialMonthlyContribution.type
        }
        shouldDirty
      />
      <InfoInitialMonthlyContribution
        strategy={choosedRadio}
        initialContribution={Number(getValues('initialValue'))}
        monthlyContribution={Number(getValues('monthlyValue'))}
      />
    </FormWithHook>
  );
};

export default ProposalByFundContributionValuesPage;
