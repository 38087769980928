/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { InterUIButton, InterUIIcon } from '@interco/inter-ui-react-lib';
import React, { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import ErrorFallbackAnalyticsService from '../../../analytics/errorFallbackAnalyticsService';
import Error from '../../../assets/error/Error.png';
import Warning from '../../../assets/warning/Warning.png';
import FontSizeREM from '../../../enums/fontSizesRem';
import PageTitles from '../../../enums/pageTitles';
import { TrackableError } from '../../../exceptions';
import BridgeService from '../../../services/bridgeService';
import { MonitoringService } from '../../../services/monitoringService';
import ErrorActions from '../../../store/error/actions';
import NavbarActions from '../../../store/navbar/actions';
import { ThemeInter } from '../../../styles';
import { Div, Flex, H1, PSmallGray } from '../../../styles/commons';
import { ErrorContainer } from './ErrorFallback.styles';

const SETTING_INVESTMENT_DEEPLINK = 'bancointer://investimentos/configuracoes';

const SUITABILITY_NOT_FOUND_CODE = 'customer_suitability_not_found';
const FUND_NOT_ALLOWED_FOR_NOT_QUALIFIED_INVESTOR = 'fund_not_allowed_for_not_qualified_investor';

const ErrorFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const dispatch = useDispatch();
  const theme = useTheme() as ThemeInter;
  const { errors } = useSelector(ErrorActions.getErrorResponse);
  const isDetailedError = useSelector(ErrorActions.getIsDetailedError);

  let frontendLogRef = '';

  if (typeof error === 'object') {
    frontendLogRef = `${Date.now()}`;
    const trackableError: TrackableError = error;
    trackableError.errorLogRef = frontendLogRef;
  }

  const callSettingInvestment = async () => {
    try {
      ErrorFallbackAnalyticsService.clickInvestmentDeepLink();
      await BridgeService.openDeepLink(SETTING_INVESTMENT_DEEPLINK);
      resetErrorBoundary();
    } catch (e: any) {
      MonitoringService.noticeError(e, {
        errorCodeRef: 'ErrorFallback.callSettingInvestiment',
      });
    }
  };

  useEffect(() => {
    dispatch(NavbarActions.setTitle(PageTitles.EMPTY));
  }, [dispatch]);

  return (
    <ErrorContainer>
      <Div height="calc(100% - 48px)" position="relative" margin="24px">
        <Div height="56px">
          <button type="button" onClick={resetErrorBoundary}>
            <InterUIIcon name="arrow-left" color={theme.colors.primary.A500} size="24px" />
          </button>
        </Div>
        <Flex flexDirection="row" width="100%" justifyContent="center" margin="150px 0 24px">
          <img
            src={isDetailedError ? Warning : Error}
            alt={isDetailedError ? 'Símbolo de atenção' : 'Símbolo de erro'}
          />
        </Flex>
        <H1 style={{ textAlign: 'center' }} marginBottom="8px">
          {isDetailedError ? 'Algo inesperado ocorreu por aqui' : 'Houve um erro por aqui'}
        </H1>
        <PSmallGray
          style={{ textAlign: 'center' }}
          lineHeight={FontSizeREM.PX17}
          marginBottom="16px"
        >
          {errors[0].message}
        </PSmallGray>
        {frontendLogRef && (
          // This element is NOT the exact design of Figma.
          // The reason is to make the information easier for our support team to see as it was previously too small.
          <PSmallGray style={{ textAlign: 'center' }} lineHeight={FontSizeREM.PX17}>
            Código: {frontendLogRef}
          </PSmallGray>
        )}
        <Div
          position="absolute"
          left="0px"
          top="100%"
          transform="translateY(-100%)"
          width="100%"
          backgroundColor={theme.colors.white}
        >
          <InterUIButton type="button" onClick={resetErrorBoundary} margin="0 0 12px">
            Voltar para o passo anterior
          </InterUIButton>
          {(errors[0].code === SUITABILITY_NOT_FOUND_CODE ||
            errors[0].code === FUND_NOT_ALLOWED_FOR_NOT_QUALIFIED_INVESTOR) && (
            <InterUIButton
              type="button"
              variant="secondary"
              onClick={() => callSettingInvestment()}
            >
              Ir para Investimentos
            </InterUIButton>
          )}
        </Div>
      </Div>
    </ErrorContainer>
  );
};

export default ErrorFallback;
