import { AnyAction, Reducer } from 'redux';
import { PrivatePensions, TaxTypes } from '../../enums/privatePensions';
import {
  PrivatePensionPlanState,
  PrivatePensionPlanStatus,
  PrivatePensionPlanTypes,
} from './types';

const INITIAL_STATE: PrivatePensionPlanState = {
  privatePensionPlan: {
    certificateNumber: '',
    accumulatedValue: 0,
    initialContributionDate: null,
    endContributionDate: null,
    numberMonthsInvesting: null,
    taxType: TaxTypes.EMPTY,
    privatePensionType: PrivatePensions.EMPTY,
    status: PrivatePensionPlanStatus.EMPTY,
    monthlyContribution: 0,
    isValidToContribute: true,
    privatePensionStatusTitle: '',
    privatePensionStatusMessage: '',
    investmentFund: {
      minimumInitialContribution: 0,
      minimumMonthlyContribution: 0,
      minimumAdditionalContribution: 0,
      monthlyProfitability: 0,
      yearlyProfitability: 0,
      lastTwelveMonthsProfitability: 0,
      maximumAdministrationFee: 0,
      minimumAdministrationFee: 0,
      interestRate: 0,
      name: '',
      code: '',
      riskLevel: 0,
      interFund: true,
      qualified: true,
    },
  },
  additionalContributionId: 0,
};

const reducer: Reducer<PrivatePensionPlanState> = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case PrivatePensionPlanTypes.SET_PRIVATE_PENSION_PLAN:
      return { ...state, privatePensionPlan: action.payload };

    case PrivatePensionPlanTypes.SET_ADDITIONAL_CONTRIBUTION_PGBL_METHOD:
      return { ...state, additionalContributionPGBLMethod: action.payload };

    case PrivatePensionPlanTypes.SET_ANNUAL_INCOME_SIMULATION_VALUE:
      return { ...state, annualIncomeSimulationValue: action.payload };

    case PrivatePensionPlanTypes.SET_ADDITIONAL_CONTRIBUTION:
      return { ...state, additionalContribution: action.payload };

    case PrivatePensionPlanTypes.SET_FAILED_PRIVATE_PENSION_PLANS:
      return { ...state, failedPrivatePensionPlans: action.payload };

    case PrivatePensionPlanTypes.SET_ADDITIONAL_CONTRIBUTION_ID:
      return { ...state, additionalContributionId: action.payload };

    case PrivatePensionPlanTypes.RESET: {
      return INITIAL_STATE;
    }

    default:
      return state;
  }
};

export default reducer;
