/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  InterUIBox,
  InterUIButton,
  InterUIContainer,
  InterUIIcon,
} from '@interco/inter-ui-react-lib';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import AdditionalContributionAnalyticsService from '../../analytics/additionalContributionAnalyticsService';
import { InvestmentFundCard } from '../../components';
import FontSizeREM from '../../enums/fontSizesRem';
import { PageRoutes } from '../../enums/pageRoutes';
import PageTitles from '../../enums/pageTitles';
import ErrorActions from '../../store/error/actions';
import { LoadingActions } from '../../store/loading/actions';
import NavbarActions from '../../store/navbar/actions';
import PrivatePensionPlanActions from '../../store/privatePensionPlan/actions';
import UserInfoActions from '../../store/user/actions';
import { ThemeInter } from '../../styles';
import {
  Div,
  EditButton,
  Flex,
  P,
  PSmallBold,
  PSmallGray,
  Separator,
  SeparatorDashed,
  Span,
} from '../../styles/commons';
import { InterUIAlertCustomized } from '../../styles/inter-ui-customizations';
import { getFirstDebitDate } from '../../utils/dateTimeUtils';
import { formatToBRLCurrency } from '../../utils/numberFormatUtils';

const AdditionalContributionProposalPage: React.FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme() as ThemeInter;
  const history = useHistory();
  const [showBalance, setShowBalance] = useState(false);
  const { privatePensionPlan, additionalContribution } = useSelector(PrivatePensionPlanActions.get);

  const userBalance = useSelector(UserInfoActions.getUserBalanceInfo);
  const loading = useSelector(LoadingActions.get);

  useEffect(() => {
    dispatch(NavbarActions.setTitle(PageTitles.ADDITIONAL_CONTRIBUTION));
    dispatch(ErrorActions.resetState());
  }, [dispatch]);

  useEffect(() => {
    AdditionalContributionAnalyticsService.revisionPage(
      privatePensionPlan.investmentFund?.name || '',
      privatePensionPlan.privatePensionType || '',
    );
  }, [privatePensionPlan.investmentFund, privatePensionPlan.privatePensionType]);

  const editInvestmentValueButton = () => {
    AdditionalContributionAnalyticsService.editInvestmentValueButton(
      privatePensionPlan.investmentFund?.name || '',
      privatePensionPlan.privatePensionType || '',
    );
    history.goBack();
  };

  const goToReceiptPage = () => {
    AdditionalContributionAnalyticsService.investButton(
      privatePensionPlan.investmentFund?.name || '',
      privatePensionPlan.privatePensionType || '',
    );
    dispatch(
      PrivatePensionPlanActions.requestAdditionalContribution({
        history,
        pathname: PageRoutes.ADDITIONAL_CONTRIBUTION_RECEIPT,
      }),
    );
  };

  const shouldShowInsufficientBalanceMsg = useMemo(() => {
    if (userBalance?.avaliable) {
      if (userBalance?.avaliable < additionalContribution!) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }, [additionalContribution, userBalance]);

  const stickyFooter: React.ReactElement = (
    <InterUIButton onClick={goToReceiptPage} loading={loading}>
      Investir
    </InterUIButton>
  );

  return (
    <InterUIContainer stickyFooter={stickyFooter} margin="24px">
      {privatePensionPlan.investmentFund && (
        <InvestmentFundCard
          investmentFund={privatePensionPlan.investmentFund}
          marginBottom="24px"
        />
      )}
      {!!privatePensionPlan.accumulatedValue && (
        <>
          <P
            fontSize={FontSizeREM.PX14}
            fontWeight={400}
            lineHeight={FontSizeREM.PX17}
            marginBottom="8px"
            color={theme.colors.grayscale.A400}
          >
            Valor acumulado
          </P>
          <P
            fontSize={FontSizeREM.PX14}
            fontWeight={700}
            lineHeight={FontSizeREM.PX17}
            marginBottom="16px"
          >
            {formatToBRLCurrency(privatePensionPlan.accumulatedValue)}
          </P>
        </>
      )}
      {!!privatePensionPlan.monthlyContribution && (
        <>
          <P
            fontSize={FontSizeREM.PX14}
            fontWeight={400}
            lineHeight={FontSizeREM.PX17}
            marginBottom="8px"
            color={theme.colors.grayscale.A400}
          >
            Contribuição mensal
          </P>
          <P
            fontSize={FontSizeREM.PX14}
            fontWeight={700}
            lineHeight={FontSizeREM.PX17}
            marginBottom="0px"
          >
            {formatToBRLCurrency(privatePensionPlan.monthlyContribution)}
          </P>
        </>
      )}
      <SeparatorDashed color={theme.colors.grayscale.A200} marginTop="24px" marginBottom="24px" />
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={400}
        lineHeight={FontSizeREM.PX17}
        marginBottom="8px"
        color={theme.colors.grayscale.A400}
      >
        Dia do débito (Próximo dia útil)
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={700}
        lineHeight={FontSizeREM.PX17}
        marginBottom="20px"
      >
        Dia {String(getFirstDebitDate().getDate())}
      </P>
      <Div position="relative">
        <P
          fontSize={FontSizeREM.PX14}
          fontWeight={400}
          lineHeight={FontSizeREM.PX17}
          marginBottom="8px"
          color={theme.colors.grayscale.A400}
        >
          Valor do investimento único
        </P>
        <P
          fontSize={FontSizeREM.PX14}
          fontWeight={700}
          lineHeight={FontSizeREM.PX17}
          marginBottom="20px"
        >
          {formatToBRLCurrency(additionalContribution!)}
        </P>
        <EditButton
          position="absolute"
          top="0"
          right="0"
          fontSize={FontSizeREM.PX14}
          lineHeight={FontSizeREM.PX17}
          onClick={() => editInvestmentValueButton()}
          data-testid="edit-contribution-btn"
        >
          Editar
        </EditButton>
      </Div>
      <Div width="100vw" margin="24px 0px 24px -24px">
        <Separator
          marginTop="32px"
          marginBottom="32px"
          color={theme.colors.grayscale.A200}
          height="8px"
        />
      </Div>

      <P fontSize={FontSizeREM.PX16} lineHeight={FontSizeREM.PX20} marginBottom="24px">
        Forma de pagamento
      </P>
      <InterUIBox
        padding="15px 0px 15px 30px"
        justify="flex-start"
        minHeight="80px"
        margin="0 0 24px 0"
      >
        <Div>
          <PSmallBold lineHeight={FontSizeREM.PX17}>Débito em conta</PSmallBold>
          <Flex flexDirection="row" alignItems="center" justifyContent="center">
            <Span
              color={theme.colors.grayscale.A400}
              fontWeight={400}
              lineHeight={FontSizeREM.PX15}
              fontSize={FontSizeREM.PX12}
            >
              Saldo em conta corrente:
            </Span>
            <Div
              backgroundColor={showBalance ? theme.colors.white : theme.colors.grayscale.A200}
              margin="0 10px 0 5px"
            >
              <Span
                color={showBalance ? theme.colors.grayscale.A400 : theme.colors.grayscale.A200}
                fontWeight={400}
                lineHeight={FontSizeREM.PX15}
                fontSize={FontSizeREM.PX12}
              >
                {userBalance?.avaliable && formatToBRLCurrency(+userBalance?.avaliable!)}
              </Span>
            </Div>
            <Div height="17px">
              <button
                type="button"
                data-testid="icon-button"
                onClick={() => setShowBalance((oldState) => !oldState)}
              >
                <InterUIIcon
                  name={showBalance ? 'show' : 'hide'}
                  color={theme.colors.primary.A500}
                  size="17px"
                />
              </button>
            </Div>
          </Flex>
        </Div>
      </InterUIBox>

      <Div>
        {shouldShowInsufficientBalanceMsg && (
          <InterUIBox padding="0px" margin="0 0 16px">
            <InterUIAlertCustomized
              color={theme.colors.white}
              icon={<InterUIIcon name="contextual-info" color={theme.colors.alert.A400} />}
              alignItems="center"
            >
              <PSmallGray lineHeight={FontSizeREM.PX18} fontWeight={600}>
                Seu débito será feito no dia {String(getFirstDebitDate().getDate())}
              </PSmallGray>

              <PSmallGray marginBottom="0">
                Para garantir o pagamento, mantenha em conta o saldo necessário para quitar seu
                investimento.
              </PSmallGray>
            </InterUIAlertCustomized>
          </InterUIBox>
        )}
      </Div>
    </InterUIContainer>
  );
};

export default AdditionalContributionProposalPage;
