/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  IInterUIDropdownOption,
  InterUIButton,
  InterUIContainer,
  InterUIDropdown,
} from '@interco/inter-ui-react-lib';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FontSizeREM from '../../enums/fontSizesRem';
import { LocalStorageKeys } from '../../enums/localStorageKeys';
import { PageRoutes } from '../../enums/pageRoutes';
import PageTitles from '../../enums/pageTitles';
import LocalStorageService from '../../services/localStorageService';
import ErrorActions from '../../store/error/actions';
import { LoadingActions } from '../../store/loading/actions';
import NavbarActions from '../../store/navbar/actions';
import PortabilityActions from '../../store/portability/actions';
import { FinancialInstitution } from '../../store/portability/types';
import UserInfoActions from '../../store/user/actions';
import Colors from '../../styles/colors';
import { H1, P } from '../../styles/commons';
import { InterDropdownContainer } from '../../styles/inter-ui-customizations';

const PortabilitySelectFinancialInstitutionPage: React.FC = () => {
  const history = useHistory();

  const dispatch = useDispatch();
  const loading = useSelector(LoadingActions.get);
  const selectedInstitution = useSelector(PortabilityActions.getFinancialInstitution);
  const { account } = useSelector(UserInfoActions.get);

  /**
   * <pre>
   *  Renato Oliveira - 2022-07-04
   *  This whitelist must be removed @link <https://jira.bancointer.com.br/browse/SEG-9273>
   *  it was setted up just for test reasons.
   * </pre>
   */
  const whiteListToIntegrateWithBelvo = [
    {
      account: '174125143',
      institutions: ['Banco do Brasil'],
    },
    {
      account: '53375050',
      institutions: ['Itaú'],
    },
  ];

  const handleSelectedFinancialInstitution = () => {
    if (
      selectedInstitution!.isSupportedByBelvo ||
      whiteListToIntegrateWithBelvo.some(
        (item) => item.account === account && item.institutions.includes(selectedInstitution!.name),
      )
    ) {
      history.push(PageRoutes.PORTABILITY_ONBOARDING_BELVO);
    } else {
      history.push(PageRoutes.PORTABILITY_ACCUMULATED_VALUE);
    }
  };

  const availableInstitutions = useMemo(
    () =>
      LocalStorageService.getItem<Array<FinancialInstitution>>(
        LocalStorageKeys.FINANCIAL_INSTITUTIONS,
      )?.data,
    [],
  );

  const selectFinancialInstitution = (id: number | string | undefined) => {
    if (id) {
      const institution =
        availableInstitutions?.find((financialInstitution) => financialInstitution.id === +id) ||
        null;
      dispatch(PortabilityActions.setFinancialInstitution(institution));
    }
  };

  const stikyFooter: React.ReactElement = (
    <InterUIButton
      type="submit"
      disabled={!selectedInstitution}
      loading={loading}
      onClick={() => handleSelectedFinancialInstitution()}
    >
      Continuar
    </InterUIButton>
  );

  useEffect(() => {
    dispatch(NavbarActions.setTitle(PageTitles.PORTABILITY_SELECT_FINANCIAL_INSTITUTION));
    dispatch(ErrorActions.resetState());
  }, [dispatch]);

  return (
    <InterUIContainer stickyFooter={stikyFooter}>
      <H1 marginBottom="8px">De onde é a sua Previdência atual?</H1>
      <P
        fontSize={FontSizeREM.PX14}
        lineHeight={FontSizeREM.PX17}
        fontWeight={400}
        color={Colors.shuttleGray}
        marginBottom="16px"
      >
        Selecione a instituição onde sua Previdência privada está hoje.
      </P>
      <InterDropdownContainer>
        <InterUIDropdown
          contentHeight="60vh"
          name="dropdown"
          label=""
          labelOptions="Onde sua previdência está hoje"
          placeholder="Selecione a instituição"
          options={
            availableInstitutions
              ? availableInstitutions.map(
                  (option) =>
                    ({
                      label: option.name,
                      value: option.id,
                    } as IInterUIDropdownOption),
                )
              : []
          }
          value={selectedInstitution?.id}
          onChange={selectFinancialInstitution}
        />
      </InterDropdownContainer>
    </InterUIContainer>
  );
};

export default PortabilitySelectFinancialInstitutionPage;
