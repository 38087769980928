/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  InterUIButton,
  InterUICheckBox,
  InterUIContainer,
  InterUIIcon,
} from '@interco/inter-ui-react-lib';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import RevisionAnalyticsService from '../../analytics/revisionAnalyticsService';
import { InvestmentFundCard } from '../../components';
import FontSizeREM from '../../enums/fontSizesRem';
import { PageRoutes } from '../../enums/pageRoutes';
import PageTitles from '../../enums/pageTitles';
import { TaxTypes } from '../../enums/privatePensions';
import ErrorActions from '../../store/error/actions';
import { HireProposalActions } from '../../store/hire/actions';
import { LoadingActions } from '../../store/loading/actions';
import NavbarActions from '../../store/navbar/actions';
import { PreviewContractActions } from '../../store/previewContract/actions';
import { ProposalDetailActions } from '../../store/proposal/actions';
import TermsAndConditionsActions from '../../store/termAndCondition/actions';
import Colors from '../../styles/colors';
import { Flex, P, Separator, SeparatorDashed, Span } from '../../styles/commons';
import {
  formatMonthsToYearsAndMonths,
  getMonthsInvestingText,
  getSecondDebitDate,
} from '../../utils/dateTimeUtils';
import { formatToBRLCurrency } from '../../utils/numberFormatUtils';

const RevisionPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    hasInsuranceCoverage,
    firstDebitDate,
    insuranceCoverage,
    initialContribution,
    monthlyContribution,
    numberMonthsInvesting,
    paymentDay,
    privatePensionType,
    taxType,
    suggestedProposalId,
    investmentFund,
  } = useSelector(ProposalDetailActions.get);
  const loading = useSelector(LoadingActions.get);
  const isTermsAndConditionsAccepted = useSelector(
    TermsAndConditionsActions.getIsTermsAndConditionsAccepted,
  );
  const type = useSelector(ProposalDetailActions.getType);

  /**
   * The T00:00:00 is used to make new Date return the date stored on currentFirstDebitDate.
   * Without T00:00:00, new Date will return a date with the day before the wanted.
   */
  const firstDebitDayAsDate = new Date(`${firstDebitDate}T00:00:00`);

  const goToPreviewContractPage = () => {
    RevisionAnalyticsService.contractPreviewButton(
      {
        content_data: String(paymentDay),
        content_fundo: investmentFund.name,
        hasInsurance:
          monthlyContribution > 0 && insuranceCoverage !== undefined ? hasInsuranceCoverage : false,
        price_inicial: formatToBRLCurrency(initialContribution),
        price_mensal: formatToBRLCurrency(monthlyContribution),
        content_mod: privatePensionType,
        content_trib: taxType,
        content_tempo: String(numberMonthsInvesting),
      },
      type,
    );
    history.push(PageRoutes.PREVIEW_CONTRACT);
  };

  useEffect(() => {
    dispatch(NavbarActions.setTitle(PageTitles.REVISION));
    dispatch(PreviewContractActions.resetState());
    dispatch(ErrorActions.resetState());
  }, [dispatch]);

  const goToContractionReceiptPage = () => {
    RevisionAnalyticsService.hireProposalButton(
      {
        content_data: String(paymentDay),
        content_fundo: investmentFund.name,
        hasInsurance:
          monthlyContribution > 0 && insuranceCoverage !== undefined ? hasInsuranceCoverage : false,
        price_inicial: formatToBRLCurrency(initialContribution),
        price_mensal: formatToBRLCurrency(monthlyContribution),
        content_mod: privatePensionType,
        content_trib: taxType,
        content_tempo: String(numberMonthsInvesting),
      },
      type,
    );
    dispatch(HireProposalActions.setSuggestedProposalId(suggestedProposalId));
    dispatch(HireProposalActions.setPrivatePensionType(privatePensionType));
    dispatch(HireProposalActions.setTaxType(taxType));
    dispatch(HireProposalActions.setInitialContribution(initialContribution));
    dispatch(HireProposalActions.setMonthlyContribution(monthlyContribution));
    dispatch(HireProposalActions.setNumberMonthsInvesting(numberMonthsInvesting));
    dispatch(HireProposalActions.setPaymentDay(paymentDay));
    dispatch(
      HireProposalActions.setHasInsuranceCoverage(
        monthlyContribution > 0 && insuranceCoverage !== undefined ? !!hasInsuranceCoverage : false,
      ),
    );
    dispatch(HireProposalActions.setInvestimentFundCode(investmentFund.code));
    dispatch(
      HireProposalActions.requestHireProposal({
        history,
        pathname: PageRoutes.CONTRACTION_RECEIPT,
      }),
    );
  };

  const stickyFooter: React.ReactElement = (
    <InterUIButton
      disabled={!isTermsAndConditionsAccepted}
      onClick={goToContractionReceiptPage}
      loading={loading}
      data-testid="go-to-contraction-receipt-button"
    >
      Contratar previdência privada
    </InterUIButton>
  );

  return (
    <InterUIContainer stickyFooter={stickyFooter}>
      <InvestmentFundCard investmentFund={investmentFund} />
      <SeparatorDashed marginTop="24px" marginBottom="24px" color={Colors.shuttleGrayDisabled} />
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={400}
        lineHeight={FontSizeREM.PX17}
        marginBottom="4px"
        color={Colors.shuttleGray}
      >
        Valor inicial
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={700}
        lineHeight={FontSizeREM.PX17}
        marginBottom="16px"
      >
        {formatToBRLCurrency(initialContribution)}
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={400}
        lineHeight={FontSizeREM.PX17}
        marginBottom="4px"
        color={Colors.shuttleGray}
      >
        Valor a cada mês
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={700}
        lineHeight={FontSizeREM.PX17}
        marginBottom="16px"
      >
        {formatToBRLCurrency(monthlyContribution)}
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={400}
        lineHeight={FontSizeREM.PX17}
        marginBottom="4px"
        color={Colors.shuttleGray}
      >
        Prazo de aposentadoria
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={700}
        lineHeight={FontSizeREM.PX17}
        marginBottom="16px"
      >
        {getMonthsInvestingText(formatMonthsToYearsAndMonths(numberMonthsInvesting))}
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={400}
        lineHeight={FontSizeREM.PX17}
        marginBottom="4px"
        color={Colors.shuttleGray}
      >
        Modalidade do plano
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={700}
        lineHeight={FontSizeREM.PX17}
        marginBottom="16px"
      >
        {privatePensionType}
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={400}
        lineHeight={FontSizeREM.PX17}
        marginBottom="4px"
        color={Colors.shuttleGray}
      >
        Tabela de tributação
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={700}
        lineHeight={FontSizeREM.PX17}
        marginBottom="16px"
      >
        Tabela {taxType === TaxTypes.PROGRESSIVE ? 'Progressiva' : 'Regressiva'}
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={400}
        lineHeight={FontSizeREM.PX17}
        marginBottom="4px"
        color={Colors.shuttleGray}
      >
        Primeiro débito
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={700}
        lineHeight={FontSizeREM.PX17}
        marginBottom="16px"
      >
        {firstDebitDayAsDate.toLocaleString('pt-BR', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })}
      </P>
      {monthlyContribution > 0 && (
        <>
          <P
            fontSize={FontSizeREM.PX14}
            fontWeight={400}
            lineHeight={FontSizeREM.PX17}
            marginBottom="4px"
            color={Colors.shuttleGray}
          >
            Data do débito mensal
          </P>
          <P
            fontSize={FontSizeREM.PX14}
            fontWeight={700}
            lineHeight={FontSizeREM.PX17}
            marginBottom="16px"
          >
            {getSecondDebitDate(firstDebitDate, paymentDay).toLocaleString('pt-BR', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })}
          </P>
        </>
      )}
      {monthlyContribution > 0 && insuranceCoverage && hasInsuranceCoverage && (
        <>
          <P
            fontSize={FontSizeREM.PX14}
            fontWeight={400}
            lineHeight={FontSizeREM.PX17}
            marginBottom="4px"
            color={Colors.shuttleGray}
          >
            Seguro
          </P>
          <P
            fontSize={FontSizeREM.PX14}
            fontWeight={700}
            lineHeight={FontSizeREM.PX17}
            marginBottom="16px"
          >
            {formatToBRLCurrency(insuranceCoverage.monthlyContributionValue)} por mês
          </P>
        </>
      )}
      <SeparatorDashed marginTop="24px" marginBottom="24px" color={Colors.shuttleGrayDisabled} />
      <Flex
        flexDirection="row"
        height="8.25vh"
        alignItems="center"
        marginBottom="24px"
        onClick={() => goToPreviewContractPage()}
        data-testid="preview-contract-button"
      >
        <InterUIIcon
          name="pdf-file"
          size="22px"
          color={Colors.interBlack}
          style={{ marginRight: '18px' }}
        />
        <Flex flexDirection="column" style={{ margin: '0 auto 0 0' }}>
          <P fontSize={FontSizeREM.PX12} lineHeight={FontSizeREM.PX15} marginBottom="2px">
            Prévia do contrato
          </P>
          <P
            fontSize={FontSizeREM.PX12}
            lineHeight={FontSizeREM.PX15}
            fontWeight={400}
            marginBottom="0px"
            color={Colors.shuttleGray}
          >
            Contrato do Fundo - Via Inter Seguros
          </P>
        </Flex>
        <InterUIIcon name="arrow-chevron-right" size="14px" color={Colors.interOrange} />
      </Flex>
      <Separator color={Colors.ghostWhite} height="1px" style={{ margin: '0 -24px' }} />
      <Flex flexDirection="row" alignItems="center" justifyContent="space-between">
        <P
          fontSize={FontSizeREM.PX12}
          lineHeight={FontSizeREM.PX18}
          marginBottom="0px"
          fontWeight={400}
        >
          Li e concordo com os{' '}
          <button type="button" onClick={goToPreviewContractPage}>
            <Span
              fontSize={FontSizeREM.PX12}
              lineHeight={FontSizeREM.PX15}
              fontWeight={700}
              color={Colors.interOrange}
            >
              Termos e Condições.
            </Span>
          </button>
        </P>
        <InterUICheckBox
          variant="default"
          defaultChecked={isTermsAndConditionsAccepted}
          height="22px"
          width="22px"
          onClick={() =>
            dispatch(
              TermsAndConditionsActions.setIsTermsAndConditionsAccepted(
                !isTermsAndConditionsAccepted,
              ),
            )
          }
        />
      </Flex>
    </InterUIContainer>
  );
};

export default RevisionPage;
