import { Reducer } from 'redux';
import { SnackbarState, SnackbarTypes } from './types';

const INITIAL_STATE: SnackbarState = {
  toggle: false,
  message: '',
  duration: 2000,
};

export const reducer: Reducer<SnackbarState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SnackbarTypes.SHOW:
      return { ...state, toggle: true };
    case SnackbarTypes.HIDE:
      return { ...state, toggle: false };
    case SnackbarTypes.SET_MESSAGE:
      return { ...state, message: action.payload };
    case SnackbarTypes.SET_DURATION:
      return { ...state, duration: action.payload };
    default:
      return state;
  }
};

export default reducer;
