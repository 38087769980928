/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { InvestmentFund } from '../components/InvestmentFundCard/InvestmentFundCard';
import { BaseService, ServiceResponse } from './baseService';

const { REACT_APP_API_PENSION_PROPOSAL_V1, REACT_APP_API_BRIDGE_PROPOSAL_V1 } = process.env;

class AvailableFundsService {
  static async getAvailableFunds(
    qualifiedCustomer: boolean,
  ): Promise<ServiceResponse<Array<InvestmentFund>>> {
    return BaseService.doExecute({
      endpoint: {
        browser: `${REACT_APP_API_PENSION_PROPOSAL_V1}/fundo-investimento?enableQualified=${qualifiedCustomer}`,
        bridge: `${REACT_APP_API_BRIDGE_PROPOSAL_V1}/fundo-investimento?enableQualified=${qualifiedCustomer}`,
      },
      method: 'GET',
    });
  }
}
export default AvailableFundsService;
