import { InterUIBabi } from '@interco/inter-ui-react-lib';
import React from 'react';
import defaultBabiImg from '../../assets/babi/babi-image.png';
import BridgeService from '../../services/bridgeService';
import { MonitoringService } from '../../services/monitoringService';

interface IBabiProps {
  title: string;
  subTitle: string;
  errorCodeRef: string;
  image?: string;
  analyticCallBack?: () => void;
}

const Babi: React.FC<IBabiProps> = ({ title, subTitle, errorCodeRef, image, analyticCallBack }) => {
  const babiImage = image || defaultBabiImg;
  const callBabiAssistant = async () => {
    try {
      if (analyticCallBack) {
        analyticCallBack();
      }
      await BridgeService.openDeepLink('bancointer://interchat');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      MonitoringService.noticeError(e, {
        errorCodeRef: `${errorCodeRef}.callInterChat`,
      });
    }
  };

  return (
    <InterUIBabi
      onClick={() => callBabiAssistant()}
      title={title}
      subTitle={subTitle}
      image={babiImage}
    />
  );
};

export default Babi;
