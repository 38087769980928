import AnalyticsBaseEventName from '../enums/analyticsBaseEventNames';
import BridgeService from '../services/bridgeService';

class IncomeExpenseAnalyticsService {
  private static className = 'IncomeExepenseAnalyticsService';

  static bottomSheetClickButton(content_name: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.bottomSheetClickButton`,
      `C_IR_BSDepSau_T_Entendi`,
      { content_name },
    );
  }

  static continueClickButton(
    content_id: string,
    annual_income: string,
    monthly_education: string,
    number_dependents: string,
    monthly_education_dependents: string,
    monthly_health: string,
    social_security: string,
  ): void {
    BridgeService.requestAnalytics(
      `${this.className}.continueClickButton`,
      `${AnalyticsBaseEventName.IR_INCOME_EXPENSE}T_Continu`,
      {
        content_id,
        annual_income,
        monthly_education,
        number_dependents,
        monthly_education_dependents,
        monthly_health,
        social_security,
      },
    );
  }
}

export default IncomeExpenseAnalyticsService;
