import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ProposalDetailActions } from '../store/proposal/actions';
import { IRRFTable } from '../store/proposal/types';

/**
 * @param taxableBase
 * @returns An array with two values @name aliquot @name deduction
 * @returns empty array if no table is provided in @param proposalDetails.incomeTaxIRRFTableResponse
 */
export function useIRRFTable(taxableBase: number): number[] {
  const irrfTable = useSelector(ProposalDetailActions.getIncomeTaxIRRFTable);

  if (!irrfTable || irrfTable.length === 0) {
    // Table not available
    return [];
  }

  const taxableBaseRange = useMemo(() => {
    // Sorting asc before find the correct values of aliquot and deduction
    const tableRange = irrfTable
      .sort((range1: IRRFTable, range2: IRRFTable) => range1.rangeNumber - range2.rangeNumber)
      .find((range) => range.value > taxableBase || range.rangeNumber === 5);
    if (tableRange) {
      return [tableRange.aliquot * 0.01, tableRange.deduction];
    }
    // Range not found
    return [];
  }, [irrfTable, taxableBase]);

  return taxableBaseRange;
}
