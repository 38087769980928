/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { InterUIBox, InterUIButton, InterUIContainer } from '@interco/inter-ui-react-lib';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import AdditionalContributionAnalyticsService from '../../analytics/additionalContributionAnalyticsService';
import { InvestmentFundCard } from '../../components';
import FontSizeREM from '../../enums/fontSizesRem';
import PageTitles from '../../enums/pageTitles';
import { PrivatePensions } from '../../enums/privatePensions';
import ErrorActions from '../../store/error/actions';
import { LoadingActions } from '../../store/loading/actions';
import NavbarActions from '../../store/navbar/actions';
import PrivatePensionPlanActions from '../../store/privatePensionPlan/actions';
import { ThemeInter } from '../../styles';
import { Div, H3, P, PSmallBold, Separator, Span } from '../../styles/commons';
import { ANNUAL_INCOME_PERCENTAGE } from '../../utils/constantsValues';
import { formatToBRLCurrency } from '../../utils/numberFormatUtils';
import InvestmentForm from './components/InvestmentForm';

const AdditionalContributionInvestmentFormPage: React.FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme() as ThemeInter;
  const { investmentFund, monthlyContribution, accumulatedValue, privatePensionType } = useSelector(
    PrivatePensionPlanActions.getPrivatePensionPlan,
  );
  const { additionalContributionPGBLMethod } = useSelector(PrivatePensionPlanActions.get);
  const annualIncome = useSelector(PrivatePensionPlanActions.getAnnualIncomeSimulationValue);
  const defaultAdditionalContribution = useSelector(
    PrivatePensionPlanActions.getAdditionalContribution,
  );
  const [disabledButton, setDisabledButton] = useState<boolean>(true);
  const formId = 'additional-contribution-investment-form';
  const loading = useSelector(LoadingActions.get);

  const maximumDeductibleAmount = annualIncome
    ? +(annualIncome * ANNUAL_INCOME_PERCENTAGE).toFixed(2)
    : 0;

  useEffect(() => {
    dispatch(NavbarActions.setTitle(PageTitles.ADDITIONAL_CONTRIBUTION));
    dispatch(ErrorActions.resetState());
  }, [dispatch]);

  useEffect(() => {
    if (additionalContributionPGBLMethod === 'SIMULATE_VALUE') {
      AdditionalContributionAnalyticsService.IRInvestmentPage(
        investmentFund?.name || '',
        privatePensionType || '',
      );
    }
  }, [additionalContributionPGBLMethod, investmentFund, privatePensionType]);

  useEffect(() => {
    AdditionalContributionAnalyticsService.investmentPage(
      investmentFund?.name || '',
      privatePensionType || '',
    );
  }, [investmentFund, privatePensionType]);

  const stickyFooter: React.ReactElement = (
    <InterUIButton
      type="submit"
      data-testid="investment-form-btn"
      form={formId}
      disabled={disabledButton}
      loading={loading}
    >
      Continuar
    </InterUIButton>
  );

  return (
    <InterUIContainer stickyFooter={stickyFooter}>
      {investmentFund && <InvestmentFundCard investmentFund={investmentFund} marginBottom="24px" />}
      {accumulatedValue != null && (
        <>
          <P
            fontSize={FontSizeREM.PX14}
            fontWeight={400}
            lineHeight={FontSizeREM.PX17}
            marginBottom="4px"
            color={theme.colors.grayscale.A400}
          >
            Valor acumulado
          </P>
          <P
            fontSize={FontSizeREM.PX14}
            fontWeight={700}
            lineHeight={FontSizeREM.PX17}
            marginBottom="20px"
          >
            {formatToBRLCurrency(accumulatedValue)}
          </P>
        </>
      )}

      {!!monthlyContribution && (
        <>
          <P
            fontSize={FontSizeREM.PX14}
            fontWeight={400}
            lineHeight={FontSizeREM.PX17}
            marginBottom="4px"
            color={theme.colors.grayscale.A400}
          >
            Contribuição mensal
          </P>
          <P
            fontSize={FontSizeREM.PX14}
            fontWeight={700}
            lineHeight={FontSizeREM.PX17}
            marginBottom="0px"
          >
            {formatToBRLCurrency(monthlyContribution)}
          </P>
        </>
      )}

      <Div width="100vw" margin="24px 0px 24px -24px">
        <Separator
          marginTop="32px"
          marginBottom="32px"
          color={theme.colors.grayscale.A200}
          height="8px"
        />
      </Div>
      <P fontSize={FontSizeREM.PX20} lineHeight={FontSizeREM.PX25} marginBottom="24px">
        Tipo de contribuição
      </P>
      <InterUIBox
        padding="15px 0px 15px 30px"
        justify="flex-start"
        margin="0 0 24px"
        minHeight="113px"
      >
        <Div width="200px">
          <PSmallBold lineHeight={FontSizeREM.PX16}>Investimento único</PSmallBold>
          <Span
            color={theme.colors.grayscale.A400}
            fontWeight={400}
            lineHeight={FontSizeREM.PX14}
            fontSize={FontSizeREM.PX12}
          >
            Faça um investimento de valor único a ser debitado no próximo dia útil da sua conta
            Inter, direto para sua previdência.
          </Span>
        </Div>
      </InterUIBox>

      {!!annualIncome && privatePensionType === PrivatePensions.PGBL && (
        <>
          <H3 marginBottom="8px">Defina os valores</H3>
          <P
            fontSize={FontSizeREM.PX14}
            lineHeight={FontSizeREM.PX17}
            fontWeight={400}
            color={theme.colors.grayscale.A400}
            marginBottom="24px"
          >
            Até 12% da sua renda anual tributável pode ser deduzido do IR, se esse valor for
            investido na previdência privada. Pelo que você informou, na sua realidade isso equivale
            a {formatToBRLCurrency(annualIncome * 0.12)}
          </P>
        </>
      )}

      {investmentFund && (
        <InvestmentForm
          setDisabled={setDisabledButton}
          maximumDeductibleAmount={
            privatePensionType === PrivatePensions.PGBL ? maximumDeductibleAmount : 0
          }
          minimumAdditionalContribution={investmentFund.minimumAdditionalContribution}
          defaultAdditionalContribution={defaultAdditionalContribution}
          privatePensionType={privatePensionType}
          investmentFundName={investmentFund.name}
          additionalContributionPGBLMethod={additionalContributionPGBLMethod}
        />
      )}
    </InterUIContainer>
  );
};

export default AdditionalContributionInvestmentFormPage;
