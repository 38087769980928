/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  IInterUIDropdownOption,
  InterUIAlert,
  InterUIBox,
  InterUIButton,
  InterUIContainer,
  InterUIIcon,
} from '@interco/inter-ui-react-lib';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import PortabilityAnalyticsService from '../../analytics/portabilityAnalyticsService';
import ProposalAnalyticsService from '../../analytics/proposalAnalyticsService';
import Select from '../../components/Select/Select';
import { EnvVariableKeys } from '../../enums/evironmentVariableKeys';
import FontSizeREM from '../../enums/fontSizesRem';
import { getMessages, MessagesValidation } from '../../enums/messages';
import { PageRoutes } from '../../enums/pageRoutes';
import PageTitles from '../../enums/pageTitles';
import { TaxTypes } from '../../enums/privatePensions';
import { EnvVariableService } from '../../services/environmentVariableService';
import ErrorActions from '../../store/error/actions';
import { IncomeExpenseActions } from '../../store/incomeExpenses/actions';
import NavbarActions from '../../store/navbar/actions';
import { ProposalDetailActions } from '../../store/proposal/actions';
import { ProposalTypes } from '../../store/proposal/types';
import UserInfoActions from '../../store/user/actions';
import { ThemeInter } from '../../styles';
import Colors from '../../styles/colors';
import { Flex, H1, P, PSmallGray } from '../../styles/commons';
import { InterUIAlertCustomized } from '../../styles/inter-ui-customizations';
import { ANNUAL_INCOME_PERCENTAGE } from '../../utils/constantsValues';
import {
  calcAge,
  formatMonthsToYearsAndMonths,
  getDateFromNumberOfMonths,
  getMonthsToCompleteAnYear,
  getRetirementAge,
  newDateHandler,
} from '../../utils/dateTimeUtils';
import { formatToBRLCurrency } from '../../utils/numberFormatUtils';

const monthsOptions: IInterUIDropdownOption[] = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
];

const DEFAULT_MAX_CONTRIBUTION_TIME_YEARS = 50;
const DEFAULT_USER_AGE = 61;
const SUBTRACTED_NUMBER_IF_AGE_LESS_THAN_DEFAULT_AGE = 100;
const MIN_CONTRIBUTION_TIME_FOR_TAX_TYPE_REGRESSIVE = 10;
const MAX_CONTRIBUTION_TIME_FOR_TAX_TYPE_PROGRESSIVE = 10;

const ContributionTime: React.FC = () => {
  const history = useHistory();
  const theme = useTheme() as ThemeInter;
  const dispatch = useDispatch();
  const currentMonthsInvesting = useSelector(ProposalDetailActions.getNumberMonthsInvesting);
  const { years: currentYears, months: currentMonths } = formatMonthsToYearsAndMonths(
    currentMonthsInvesting,
  );
  const [selectedYears, setSelectedYears] = useState<number | string>(currentYears);
  const [selectedMonths, setSelectedMonths] = useState<number | string>(currentMonths);
  const userInfo = useSelector(UserInfoActions.get);

  const BIGGER_ACCEPTABLE_CONTRIBUTION_TIME_TO_SHOW_ALERT = parseInt(
    EnvVariableService.getVariable(
      EnvVariableKeys.BIGGER_ACCEPTABLE_CONTRIBUTION_TIME_TO_SHOW_ALERT,
    ) || '6',
  );

  const currentAnnualContribution = useSelector(ProposalDetailActions.getAnnualContribution);
  const currentInitialContribution = useSelector(ProposalDetailActions.getInitialContribution);
  const currentMonthlyContribution = useSelector(ProposalDetailActions.getMonthlyContribution);
  const paymentDay = useSelector(ProposalDetailActions.getPaymentDay);
  const [annualContribution, setAnnualContribution] = useState(currentAnnualContribution);
  /**
   * newMaximumDeductible is the maximumDeductibleAmount that is considering the rounded idealMonthlyContribution
   * In cases when the idealMonthlyContribution is exact ex: 500, 250, 100...
   * The newMaximumDeductible will be equal to the maximumDeductibleAmount;
   * In cases when the idealMonthlyContribution is a periodic tithe ex 133.33, 666.67...
   * The newMaximumDeductible will be considering the rounded monthly values
   * So we need to use as reference the newMaximumDeductible to calcular the deductibleIncomeExpense,
   * This is needed to hidden the card for when is suggested a rounded monthly contribution and the user input the suggestion;
   */
  const [newMaximumDeductible, setNewMaximumDeductible] = useState(0);

  const proposalType = useSelector(ProposalDetailActions.getType);
  const monthlyContribution = useSelector(ProposalDetailActions.getMonthlyContribution);
  const { annualIncome } = useSelector(IncomeExpenseActions.get);
  const maximumDeductibleAmount = annualIncome * ANNUAL_INCOME_PERCENTAGE;

  const taxType = useSelector(ProposalDetailActions.getTaxType);
  const yearsAsNumber = parseInt(selectedYears as string, 10);
  const monthsAsNumber = parseInt(selectedMonths as string, 10);
  const yearsAndMonthsToMonths = yearsAsNumber * 12 + monthsAsNumber;
  const yearOfExercise = String(new Date().getFullYear() + 1);

  const investmentFund = useSelector(ProposalDetailActions.getInvestmentFund);
  const gracePeriod = investmentFund.qualified ? '180 dias para fundos qualificados' : '60 dias';

  const yearsOptions = useMemo<IInterUIDropdownOption[]>(() => {
    const yearsCount = [];
    let yearLength = DEFAULT_MAX_CONTRIBUTION_TIME_YEARS;
    let userAge = DEFAULT_USER_AGE;

    if (userInfo.birthDate !== undefined) {
      userAge = calcAge(new Date(userInfo.birthDate));
    }

    if (userAge < DEFAULT_USER_AGE) {
      yearLength = SUBTRACTED_NUMBER_IF_AGE_LESS_THAN_DEFAULT_AGE - userAge;
    }

    for (let i = 0; i <= yearLength; i += 1) {
      yearsCount.push({ label: `${i}`, value: i });
    }

    return yearsCount;
  }, [userInfo]);

  useEffect(() => {
    let numberOfContributions;
    const monthsToCompleteAnYear = getMonthsToCompleteAnYear(paymentDay);

    const diffBetweenMaximumDeductibleAndIniticalContribution =
      maximumDeductibleAmount - currentInitialContribution;

    if (yearsAndMonthsToMonths < monthsToCompleteAnYear) {
      numberOfContributions = yearsAndMonthsToMonths;
    } else {
      numberOfContributions = monthsToCompleteAnYear;
    }

    const idealMonthlyContribution = +(
      diffBetweenMaximumDeductibleAndIniticalContribution / numberOfContributions
    ).toFixed(2);

    const idealAmountMonthlyContribution = +(
      idealMonthlyContribution * numberOfContributions
    ).toFixed(2);

    const currenctAmountMonthlyContribution = +(
      currentMonthlyContribution * numberOfContributions
    ).toFixed(2);

    setNewMaximumDeductible(idealAmountMonthlyContribution + currentInitialContribution);

    setAnnualContribution(currenctAmountMonthlyContribution + currentInitialContribution);
  }, [paymentDay, selectedYears, selectedMonths]);

  const recalcDeclarationTableInfo = useMemo(() => {
    if (
      proposalType !== ProposalTypes.INCOME_TAX ||
      currentAnnualContribution === annualContribution
    ) {
      return undefined;
    }

    const deductibleIncomeDiff = newMaximumDeductible - annualContribution;

    if (deductibleIncomeDiff > 0) {
      return {
        message: getMessages(
          MessagesValidation.ANNUAL_CONTRIBUTION_UNDER_MAXIMUM_DEDUCTIBLE_AMOUNT_DIFF,
          [formatToBRLCurrency(deductibleIncomeDiff), yearOfExercise],
        ),
      };
    }

    if (deductibleIncomeDiff < 0) {
      /*
       * In this scenarious we need show the diff between the correct maximumDeductibleAmount and the annualContribution inputed
       */
      const rightDeductibleIncomeDiff = maximumDeductibleAmount - annualContribution;
      return {
        message: getMessages(
          MessagesValidation.MAXIMUM_DEDUCTIBLE_AMOUNT_EXCEEDS_FOR_CONTRIBUTION_TIME,
          [
            formatToBRLCurrency(maximumDeductibleAmount),
            yearOfExercise,
            formatToBRLCurrency(rightDeductibleIncomeDiff * -1),
          ],
        ),
      };
    }

    return undefined;
  }, [paymentDay, proposalType, annualContribution, newMaximumDeductible]);

  const calculateUserRetirementAge = (): string => {
    const { birthDate } = userInfo;
    const birthDateAsDate = newDateHandler(birthDate);

    if (birthDateAsDate) {
      const numberOfMonthToRetirement = getDateFromNumberOfMonths(yearsAndMonthsToMonths);
      return String(getRetirementAge(birthDateAsDate, numberOfMonthToRetirement));
    }
    return '';
  };

  const onSubmit = () => {
    if (proposalType === ProposalTypes.PORTABILITY) {
      const userRetirementAge = calculateUserRetirementAge();
      PortabilityAnalyticsService.contributionTimeDefineButton(userRetirementAge);
      dispatch(ProposalDetailActions.setNumberMonthsInvesting(yearsAndMonthsToMonths));
      dispatch(ProposalDetailActions.setAnnualContribution(annualContribution));
      history.push(PageRoutes.PORTABILITY_REVISION);
      return;
    }
    ProposalAnalyticsService.editInfoConfirmation(
      {
        content_area: 'dia do débito por mês',
        content_new_info: String(yearsAndMonthsToMonths),
        content_info: String(currentMonthsInvesting),
      },
      proposalType,
    );

    if (
      taxType === TaxTypes.REGRESSIVE &&
      yearsAndMonthsToMonths < currentMonthsInvesting &&
      yearsAsNumber < MIN_CONTRIBUTION_TIME_FOR_TAX_TYPE_REGRESSIVE
    ) {
      history.push({
        pathname: PageRoutes.TAX_TYPE,
        state: {
          annualContribution,
          months: yearsAndMonthsToMonths,
          suggestedTaxType: 'Progressiva',
        },
      });
    } else if (
      taxType === TaxTypes.PROGRESSIVE &&
      yearsAsNumber >= MAX_CONTRIBUTION_TIME_FOR_TAX_TYPE_PROGRESSIVE
    ) {
      history.push({
        pathname: PageRoutes.TAX_TYPE,
        state: {
          annualContribution,
          months: yearsAndMonthsToMonths,
          suggestedTaxType: 'Regressiva',
        },
      });
    } else {
      dispatch(ProposalDetailActions.setNumberMonthsInvesting(yearsAndMonthsToMonths));
      dispatch(ProposalDetailActions.setAnnualContribution(annualContribution));
      history.goBack();
    }
  };

  const disableButton = () => {
    if (proposalType === ProposalTypes.PORTABILITY && yearsAndMonthsToMonths >= 1) {
      return false;
    }
    if (
      (yearsAsNumber === currentYears && monthsAsNumber === currentMonths) ||
      yearsAndMonthsToMonths <= 0
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    dispatch(NavbarActions.setTitle(PageTitles.CONTRIBUTION_TIME));

    dispatch(ErrorActions.resetState());
  }, [dispatch]);

  const stickyFooter: React.ReactElement = (
    <>
      {proposalType === ProposalTypes.INCOME_TAX && monthlyContribution > 0 && (
        <InterUIAlert margin="16px 0 16px 0">
          <P
            fontWeight={700}
            fontSize={FontSizeREM.PX12}
            lineHeight={FontSizeREM.PX15}
            marginBottom="4px"
          >
            Alteração do Prazo de aposentadoria
          </P>
          <P fontWeight={400} fontSize={FontSizeREM.PX12} lineHeight={FontSizeREM.PX15}>
            A alteração do Prazo de aposentadoria pode implicar no recalculo dos valores ideais para
            investimento na Previdência para Economizar no Imposto de Renda.
          </P>
        </InterUIAlert>
      )}
      <InterUIButton disabled={disableButton()} onClick={onSubmit}>
        Definir
      </InterUIButton>
    </>
  );

  const longTermIsBetterAlert: React.ReactElement = (
    <InterUIAlertCustomized
      color={theme.colors.alert.A100}
      margin="16px 0 16px 0"
      icon={<InterUIIcon name="contextual-info" color={theme.colors.alert.A400} />}
    >
      <P
        fontWeight={700}
        fontSize={FontSizeREM.PX12}
        lineHeight={FontSizeREM.PX14}
        color={theme.colors.grayscale.A500}
      >
        Quanto mais tempo, melhor pro seu investimento
      </P>
      <P
        fontWeight={400}
        fontSize={FontSizeREM.PX12}
        lineHeight={FontSizeREM.PX14}
        color={theme.colors.grayscale.A500}
      >
        Quando seu plano completar o período programado, sua previdência não poderá receber novas
        contribuições. Para continuar acumulando mais, escolha um período maior do que o pretendido,
        pois você pode resgatar antes, respeitando a carência mínima de {gracePeriod}.
      </P>
    </InterUIAlertCustomized>
  );
  return (
    <InterUIContainer stickyFooter={stickyFooter}>
      <H1 marginBottom="8px">Em quanto tempo você quer receber?</H1>
      <P
        fontWeight={400}
        fontSize={FontSizeREM.PX14}
        lineHeight={FontSizeREM.PX17}
        color={Colors.shuttleGray}
        marginBottom="16px"
      >
        Você pode informar um prazo estimado. Quanto maior o tempo, mais o seu dinheiro vai render.
      </P>
      <Flex flexDirection="row" justifyContent="space-between" marginBottom="16px">
        <Select
          label="Anos"
          dataTestId="years-select"
          width="40vw"
          onChange={setSelectedYears}
          defaultValue={selectedYears}
          selectValueColor={disableButton() ? Colors.interBlackDisabled : Colors.interBlack}
        >
          {yearsOptions.map((y) => {
            const key = `year-${y.value}`;
            return (
              <option key={key} data-testid={key} value={y.value}>
                {y.label}
              </option>
            );
          })}
        </Select>
        <Select
          label="Meses"
          dataTestId="months-select"
          width="40vw"
          onChange={setSelectedMonths}
          defaultValue={selectedMonths}
          selectValueColor={disableButton() ? Colors.interBlackDisabled : Colors.interBlack}
        >
          {monthsOptions.map((m) => {
            const key = `month-${m.value}`;
            return (
              <option key={key} value={m.value}>
                {m.label}
              </option>
            );
          })}
        </Select>
      </Flex>
      {recalcDeclarationTableInfo && (
        <InterUIBox padding="0px" margin="0 0 16px">
          <InterUIAlertCustomized
            color={theme.colors.white}
            icon={<InterUIIcon name="contextual-info" color={theme.colors.alert.A400} />}
            alignItems="center"
          >
            <PSmallGray marginBottom="0">{recalcDeclarationTableInfo.message}</PSmallGray>
          </InterUIAlertCustomized>
        </InterUIBox>
      )}

      {yearsAndMonthsToMonths <= BIGGER_ACCEPTABLE_CONTRIBUTION_TIME_TO_SHOW_ALERT &&
        longTermIsBetterAlert}
    </InterUIContainer>
  );
};

export default ContributionTime;
