import { InvestmentFund } from '../../components/InvestmentFundCard/InvestmentFundCard';
import { HireProposalStatus, PrivatePensions, TaxTypes } from '../../enums/privatePensions';
import { InsuranceCoverage } from '../proposal/types';

export enum HireProposalTypes {
  SET_SUGGESTED_PROPOSAL_ID = '@hire/GET_SUGGESTED_PROPOSAL_ID',
  SET_PENDING_HIRE_PROPOSAL = '@hire/SET_PENDING_HIRE_PROPOSAL',
  SET_PRIVATE_PENSION_TYPE = '@hire/SET_PRIVATE_PENSION_TYPE',
  SET_TAX_TYPE = '@hire/SET_TAX_TYPE',
  SET_INITIAL_CONTRIBUTION = '@hire/SET_INITIAL_CONTRIBUTION',
  SET_MONTHLY_CONTRIBUTION = '@hire/SET_MONTHLY_CONTRIBUTION',
  SET_NUMBER_MONTHS_INVESTING = '@hire/SET_NUMBER_MONTHS_INVESTING',
  SET_PAYMENT_DAY = '@hire/SET_PAYMENT_DAY',
  SET_HAS_INSURANCE_COVERAGE = '@hire/SET_HAS_INSURANCE_COVERAGE',
  SET_INVESTMENT_FUND_CODE = '@hire/SET_INVESTIMENT_FUND_CODE',
  SET_CHOOSEN_PROPOSAL_ID = '@hire/SET_CHOOSEN_PROPOSAL_ID',
  REQUEST_HIRE_PROPOSAL = `@hire/REQUEST_HIRE_PROPOSAL`,
  REQUEST_HIRE_PORTABILITY_PROPOSAL = `@hire/REQUEST_HIRE_PORTABILITY_PROPOSAL`,
  HAS_PENDING_HIRE_PROPOSAL = '@hire/HAS_PENDING_HIRE_PROPOSAL',
  GET_HIRE_PROPOSAL = '@hire/GET_HIRE_PROPOSAL',
  CANCEL_PENDING_HIRE_PROPOSAL = '@hire/CANCEL_PENDING_HIRE_PROPOSAL',
  SIGN_PENDING_HIRE_PROPOSAL = '@hire/SIGN_PENDING_HIRE_PROPOSAL',
  RESET = `@hire/RESET`,
}

export interface HireProposalStatePage {
  content: [HireProposalState];
  totalSize: number;
}

export interface HireProposalState {
  suggestedProposalId: number;
  privatePensionType: PrivatePensions;
  taxType: TaxTypes;
  initialContribution: number;
  monthlyContribution: number;
  numberMonthsInvesting: number;
  paymentDay: number;
  hasInsuranceCoverage: boolean;
  investmentFundCode: string;
  chosenProposalId: number;
  pendingHireProposal: PendingHireProposal;
}

export interface PendingHireProposal {
  readonly suggestedProposalId: number;
  readonly privatePensionType: PrivatePensions;
  readonly taxType: TaxTypes;
  readonly initialContribution: number;
  readonly monthlyContribution: number;
  readonly numberMonthsInvesting: number;
  readonly paymentDay: number;
  readonly chosenProposalId: number;
  readonly status: HireProposalStatus;
  readonly firstDebitDate: string;
  readonly investmentFund: InvestmentFund;
  readonly insuranceCoverage?: InsuranceCoverage;
}
