import { AnyAction, Reducer } from 'redux';
import { PortabilityState, PortabilityTypes } from './types';

const INITIAL_STATE: PortabilityState = {
  financialInstitution: null,
  accumulatedValue: null,
  portabilityId: null,
  externalPensionPlan: null,
  requestCount: 0,
  linkId: null,
  isPrivacyPolicyAccepted: false,
  externalPortabilityRequest: null,
};

const reducer: Reducer<PortabilityState> = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case PortabilityTypes.SET_EXTERNAL_PORTABILITY_REQUEST: {
      return { ...state, externalPortabilityRequest: action.payload };
    }
    case PortabilityTypes.SET_ACCUMULATED_VALUE: {
      return { ...state, accumulatedValue: action.payload };
    }
    case PortabilityTypes.SET_FINANCIAL_INSTITUTION: {
      return { ...state, financialInstitution: action.payload };
    }
    case PortabilityTypes.SET_PORTABILITY_ID: {
      return { ...state, portabilityId: action.payload };
    }
    case PortabilityTypes.SET_EXTERNAL_PENSION_PLAN: {
      return { ...state, externalPensionPlan: action.payload };
    }
    case PortabilityTypes.SET_REQUEST_COUNT: {
      return { ...state, requestCount: action.payload };
    }
    case PortabilityTypes.SET_LINK_ID: {
      return { ...state, linkId: action.payload };
    }
    case PortabilityTypes.SET_PRIVACY_POLICY_ACCEPTED: {
      return { ...state, isPrivacyPolicyAccepted: action.payload };
    }
    case PortabilityTypes.RESET: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
