import { InterUIBottomSheet, InterUIButton } from '@interco/inter-ui-react-lib';
import React from 'react';
import { useTheme } from 'styled-components';
import Error from '../../../../assets/error/Error.png';
import FontSizeREM from '../../../../enums/fontSizesRem';
import { ThemeInter } from '../../../../styles';
import { Flex, H3, P } from '../../../../styles/commons';

interface IErrorBottomSheetProps {
  openBottomSheet: boolean;
  setOpenBottomSheet: (setIsOpen: boolean) => void;
  handleClickTryAgain: () => void;
}

const ErrorBottomSheet: React.FC<IErrorBottomSheetProps> = ({
  openBottomSheet,
  setOpenBottomSheet,
  handleClickTryAgain,
}) => {
  const theme = useTheme() as ThemeInter;

  /**
   * passar a função do botão tente novamente via props
   * receber via props um arrray de numeros dos certificados das prevs que estão com o status erro,
   * enviar para requestPrivatePensionPlans, e la no saga fazer um filtro após a requisição.
   */

  return (
    <InterUIBottomSheet toggle={openBottomSheet} onHide={() => setOpenBottomSheet(false)}>
      <Flex
        flexDirection="column"
        width="100%"
        justifyContent="center"
        alignItems="center"
        margin="41px 0px 40px 0px"
      >
        <img src={Error} alt="Símbolo de erro" style={{ width: '64px' }} />
      </Flex>
      <H3 marginBottom="4px">Não foi possível exibir alguns fundos</H3>
      <P
        marginBottom="0"
        fontWeight={400}
        fontSize={FontSizeREM.PX14}
        lineHeight={FontSizeREM.PX16}
        color={theme.colors.grayscale.A400}
      >
        No momento não foi possível carregar todos os fundos de previdência. Você pode visualizar os
        que estão disponíveis ou voltar mais tarde e tentar novamente.
      </P>
      <InterUIButton type="button" onClick={handleClickTryAgain} margin="16px 0px 0px 0px">
        Tentar novamente
      </InterUIButton>
      <InterUIButton
        type="button"
        onClick={() => setOpenBottomSheet(false)}
        margin="16px 0px 0px 0px"
        variant="secondary"
      >
        Visualizar fundos disponíveis
      </InterUIButton>
    </InterUIBottomSheet>
  );
};

export default ErrorBottomSheet;
