export enum PreviewContractTypes {
  GET_CONTRACT = '@previewContract/GET_PREVIEW_CONTRACT',
  SET_CONTRACT = '@previewContract/SET_PREVIEW_CONTRACT',
  REQUEST_CONTRACT = '@previewContract/REQUEST_CONTRACT',
  RESET = `@previewContract/RESET`,
}

export interface ContractPath {
  path: string;
  name: string;
}

export interface PreviewContractState {
  contract: string | null;
}
