export enum IncomeExpensePageTypes {
  SET_INCOME_EXPENSES_STATE = `@incomeExpense/SET_INCOME_EXPENSES_STATE`,
  RESET = `@incomeExpense/RESET`,
}

export interface IncomeExpensePageState {
  annualIncome: number;
  monthlyEducationExpenditure: number;
  numberOfDependents?: number;
  monthlyEducationExpenditureDependents?: number;
  monthlyHealthExpenditure: number;
  socialSecurityContribution?: boolean | string;
}
