/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components';

const fontColorInputPricing = (props: any) => {
  const { colors } = props.theme;
  if (props.disabled) {
    return colors.grayscale.A200;
  }
  if (props.invalid) {
    return colors.error.A400;
  }
  return colors.primary.A500;
};

export const ErrorMessage = styled.div`
  min-height: 1rem;
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${(props) => props.theme.colors.error.A400};
`;

export const PrefixPricing = styled.span<{
  disabled?: boolean;
  invalid?: boolean;
}>`
  padding: 16px 0px 4px 0px;
  font-family: 'Sora';
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-weight: 600;
  color: ${fontColorInputPricing};
  border-bottom: 1px solid ${(props) => props.theme.colors.grayscale.A300};
`;

export const InputPricingContainer = styled.div<{ color?: string }>`
  input {
    border-radius: 0;
  }
  span,
  input {
    ${(props) => props.color && `color: ${props.color};`}
  }
`;
