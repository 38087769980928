import styled, { css } from 'styled-components';
import FontSizeREM from '../../enums/fontSizesRem';
import Colors from '../../styles/colors';

const Label = css`
  label {
    display: block;
    margin-bottom: '4px';
    font-size: ${FontSizeREM.PX14};
    line-height: ${FontSizeREM.PX17};
    font-weight: ${400};
    color: ${Colors.shuttleGray};
  }
`;

const Select = css`
  select {
    z-index: 2;
    width: 100%;
    padding: 12px 24px;
    background-color: ${Colors.ghostWhite};
    color: ${Colors.interBlack};
    border-radius: 8px;
    appearance: none;
    font-family: 'Inter';
    font-weight: 700;
    font-size: ${FontSizeREM.PX14};
    line-height: ${FontSizeREM.PX17};
  }
`;

export const SelectContainer = styled.div<{ width?: string; margin?: string }>`
  ${Label}
  ${Select}
  width: ${(props) => props.width || `100%`};
  ${(props) => props.margin && `margin: ${props.margin};`}
`;
