/* eslint-disable @typescript-eslint/no-explicit-any */

import { InvestmentFund } from '../components/InvestmentFundCard/InvestmentFundCard';

function isNullOrUndefined(value: any): boolean {
  return value === null || value === undefined;
}

export function sortByRiskLevel(fund1: InvestmentFund, fund2: InvestmentFund): number {
  let value1: number | null = fund1.riskLevel;
  let value2: number | null = fund2.riskLevel;

  // Asc direction
  if (value1 - value2 !== 0) {
    return value1 - value2;
  }

  value1 = fund1.lastTwelveMonthsProfitability;
  value2 = fund2.lastTwelveMonthsProfitability;
  // Desc direction
  if (isNullOrUndefined(value1) && !isNullOrUndefined(value2)) {
    return 1;
  }

  if (isNullOrUndefined(value2) && !isNullOrUndefined(value1)) {
    return -1;
  }

  if (
    value1 !== null &&
    value1 !== undefined &&
    value2 !== null &&
    value2 !== undefined &&
    value2 - value1 !== 0
  ) {
    return value2 - value1;
  }

  value1 = fund1.maximumAdministrationFee;
  value2 = fund2.maximumAdministrationFee;
  // Asc direction
  if (value1 - value2 !== 0) {
    return value1 - value2;
  }

  value1 = fund1.yearlyProfitability;
  value2 = fund2.yearlyProfitability;
  // Desc direction
  if (isNullOrUndefined(value1) && !isNullOrUndefined(value2)) {
    return 1;
  }

  if (isNullOrUndefined(value2) && !isNullOrUndefined(value1)) {
    return -1;
  }

  if (
    value1 !== null &&
    value1 !== undefined &&
    value2 !== null &&
    value2 !== undefined &&
    value2 - value1 !== 0
  ) {
    return value2 - value1;
  }

  value1 = fund1.monthlyProfitability;
  value2 = fund2.monthlyProfitability;
  if (value1 === null || value1 === undefined) {
    return 1;
  }

  if (value2 === null || value2 === undefined) {
    return -1;
  }

  return value2 - value1;
}

export function sortByPriority(fund1: InvestmentFund, fund2: InvestmentFund): number {
  const value1 = fund1.priority;
  const value2 = fund2.priority;

  if (value1 !== undefined && value2 !== undefined) {
    return value1 - value2;
  }

  if (value1 === undefined) {
    return 1;
  }

  if (value2 === undefined) {
    return -1;
  }

  return value1 < value2 ? -1 : 1;
}

export function defaultSorting(fund1: InvestmentFund, fund2: InvestmentFund): number {
  if (fund1.priority === fund2.priority) {
    return sortByRiskLevel(fund1, fund2);
  }
  return sortByPriority(fund1, fund2);
}

export function sortByProfitability(fund1: InvestmentFund, fund2: InvestmentFund): number {
  let value1 = fund1.lastTwelveMonthsProfitability;
  let value2 = fund2.lastTwelveMonthsProfitability;
  // Desc direction
  if (isNullOrUndefined(value1) && !isNullOrUndefined(value2)) {
    return 1;
  }

  if (isNullOrUndefined(value2) && !isNullOrUndefined(value1)) {
    return -1;
  }

  if (
    value1 !== null &&
    value1 !== undefined &&
    value2 !== null &&
    value2 !== undefined &&
    value2 - value1 !== 0
  ) {
    return value2 - value1;
  }

  value1 = fund1.maximumAdministrationFee;
  value2 = fund2.maximumAdministrationFee;
  // Asc direction
  if (value1 - value2 !== 0) {
    return value1 - value2;
  }

  value1 = fund1.yearlyProfitability;
  value2 = fund2.yearlyProfitability;
  // Desc direction
  if (isNullOrUndefined(value1) && !isNullOrUndefined(value2)) {
    return 1;
  }

  if (isNullOrUndefined(value2) && !isNullOrUndefined(value1)) {
    return -1;
  }

  if (
    value1 !== null &&
    value1 !== undefined &&
    value2 !== null &&
    value2 !== undefined &&
    value2 - value1 !== 0
  ) {
    return value2 - value1;
  }

  value1 = fund1.riskLevel;
  value2 = fund2.riskLevel;
  // Asc direction
  if (value1 - value2 !== 0) {
    return value1 - value2;
  }

  value1 = fund1.monthlyProfitability;
  value2 = fund2.monthlyProfitability;
  // Desc direction
  if (value1 === null || value1 === undefined) {
    return 1;
  }

  if (value2 === null || value2 === undefined) {
    return -1;
  }
  return value2 - value1;
}
