import { InterUIButton, InterUIContainer } from '@interco/inter-ui-react-lib';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageTitles from '../../enums/pageTitles';
import ErrorActions from '../../store/error/actions';
import { IncomeExpenseActions } from '../../store/incomeExpenses/actions';
import { LoadingActions } from '../../store/loading/actions';
import NavbarActions from '../../store/navbar/actions';
import { ProposalDetailActions } from '../../store/proposal/actions';
import { ProposalTypes } from '../../store/proposal/types';
import { H1, PSmallGray } from '../../styles/commons';
import { ANNUAL_INCOME_PERCENTAGE } from '../../utils/constantsValues';
import { formatToBRLCurrency } from '../../utils/numberFormatUtils';
import ContributionValuesForm from './components/ContributionValuesForm';

const ContributionValues: React.FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(LoadingActions.get);
  const proposalType = useSelector(ProposalDetailActions.getType);
  const [disabledButton, setDisabledButton] = useState<boolean>(true);
  const [disableButtonIfEqual, setDisableButtonIfEqual] = useState<boolean>(true);

  const incomeExpense = useSelector(IncomeExpenseActions.get);
  const maximumDeductibleAmount = +(incomeExpense.annualIncome * ANNUAL_INCOME_PERCENTAGE).toFixed(
    2,
  );

  useEffect(() => {
    dispatch(NavbarActions.setTitle(PageTitles.CONTRIBUTION_VALUES));
    dispatch(ErrorActions.resetState());
  }, [dispatch]);

  const stickyFooter = (
    <InterUIButton
      type="submit"
      data-testid="contribution-values-form-btn"
      disabled={disabledButton || disableButtonIfEqual}
      loading={loading}
      form="contribution-values-form"
    >
      Redefinir
    </InterUIButton>
  );

  return (
    <InterUIContainer stickyFooter={stickyFooter}>
      <H1 marginBottom="8px">Seus valores</H1>
      {proposalType === ProposalTypes.INCOME_TAX ? (
        <PSmallGray marginBottom="16px">
          <strong>Até 12% da sua renda anual tributável pode ser deduzida</strong> da base de
          cálculo IR, se investida na previdência privada. Pela renda que você nos informou,{' '}
          <strong>isso equivale investir {formatToBRLCurrency(maximumDeductibleAmount)}</strong> ao
          longo do ano fiscal.
        </PSmallGray>
      ) : (
        <PSmallGray marginBottom="16px">
          Escolha como você quer investir no seu plano de aposentadoria na previdência privada.
        </PSmallGray>
      )}

      <ContributionValuesForm
        setDisabledButton={setDisabledButton}
        setDisableButtonIfEqual={setDisableButtonIfEqual}
      />
    </InterUIContainer>
  );
};

export default ContributionValues;
