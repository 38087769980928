/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from 'react';
import { useVirtual, VirtualItem } from 'react-virtual';
import { VirtuaLizedContainer, VirtuaLizedItem, VirtuaLizedList } from './VirtualizedList.styles';

interface IVirtualizedList<T> {
  list: Array<T>;
  element: (item: T) => React.ReactNode;
}

/**
 * @param List A list of elements to virtualizer
 * @param element A component to be render that receives the item of list.
 */
const VirtualizedList: React.FC<IVirtualizedList<any>> = ({ list, element, children }) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const virtualized = useVirtual({
    size: list.length,
    parentRef,
    overscan: 10,
  });

  return (
    <VirtuaLizedContainer ref={parentRef}>
      {children}
      <VirtuaLizedList height={`${virtualized.totalSize}px`}>
        {virtualized.virtualItems.map((item: VirtualItem) => (
          <VirtuaLizedItem key={item.index} ref={item.measureRef} transform={`${item.start}px`}>
            {element(list[item.index])}
          </VirtuaLizedItem>
        ))}
      </VirtuaLizedList>
    </VirtuaLizedContainer>
  );
};

export default VirtualizedList;
