import { AnyAction, Reducer } from 'redux';
import { SelectableFundsState, SelectableFundsTypes } from './types';

const INITIAL_STATE: SelectableFundsState = {
  sortter: 'Padrão',
};

const reducer: Reducer<SelectableFundsState> = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case SelectableFundsTypes.SET_SORTTER: {
      return { ...state, sortter: action.payload };
    }
    case SelectableFundsTypes.RESET: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
