/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { InterUIInputGroup } from '@interco/inter-ui-react-lib';
import React from 'react';
import {
  Control,
  RegisterOptions,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetFocus,
  UseFormSetValue,
} from 'react-hook-form';

export interface IInputFormFieldWithHookProps {
  name: string;
  placeholder?: string;
  label?: string;
  type?: string;
  inputMode?: string;
  disabled?: boolean;
  readOnly?: boolean;
  dataTestId?: string;
  maxLength?: number;
  nextField?: string;
  shouldDirty?: boolean;
}

type InputFormFieldProps = React.InputHTMLAttributes<HTMLInputElement> &
  IInputFormFieldWithHookProps & {
    register?: UseFormRegister<any>;
    getValues?: UseFormGetValues<any>;
    setValue?: UseFormSetValue<any>;
    setFocus?: UseFormSetFocus<any>;
    validation?: RegisterOptions;
    margin?: string;
    control?: Control;
  };

const InputFormFieldWithHook: React.FC<InputFormFieldProps> = ({
  name,
  nextField,
  placeholder,
  label,
  type,
  inputMode,
  disabled = false,
  readOnly = false,
  shouldDirty = false,
  dataTestId,
  maxLength,
  margin = '0px',
  register = (data: unknown) => {},
  getValues = (data: any) => null,
  setValue = (data: unknown) => {},
  setFocus,
  validation,
  control,
  ...props
}) => (
  <InterUIInputGroup disabled={disabled} readOnly={readOnly}>
    <label htmlFor={name}>{label}</label>
    <input
      {...register(name, {
        ...validation,
      })}
      {...props}
      data-testid={dataTestId || name}
      readOnly={disabled}
      maxLength={maxLength}
      name={name}
      id={name}
      placeholder={placeholder}
      autoComplete="off"
      type={type}
      inputMode={inputMode}
      onChange={(e) => {
        setValue(name, e.target.value, { shouldValidate: true, shouldDirty });
      }}
      onKeyDown={(event) => {
        if ((event.isTrusted && event.key === 'Enter') || event.code === '13') {
          if (nextField && setFocus) {
            setFocus(nextField);
          }
        }
      }}
    />
  </InterUIInputGroup>
);

export default InputFormFieldWithHook;
