/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/unbound-method */
import { History } from 'history';
import { AnyAction } from 'redux';
import { call, put, SagaGenerator, select, takeLeading } from 'typed-redux-saga';
import PortabilityAnalyticsService from '../../analytics/portabilityAnalyticsService';
import {
  BelvoEventName,
  BelvoEventResponse,
  BelvoExitResponse,
  BelvoSupportedInstitution,
  BelvoWidgetConfig,
} from '../../belvo';
import { EnvVariableKeys } from '../../enums/evironmentVariableKeys';
import { LocalStorageKeys } from '../../enums/localStorageKeys';
import { PageRoutes } from '../../enums/pageRoutes';
import { LoadingIds } from '../../pages/ExternalPortabilityAcceptance/ExternalPortabilityAcceptance.type';
import { HomeButtonIds } from '../../pages/Home/Home';
import { LoadingIds as PortabilityHomeLoadingIds } from '../../pages/PortabilityHome/PortabilityHome';
import { AppInfoService } from '../../services/appInfoService';
import BridgeService from '../../services/bridgeService';
import { HttpStatus } from '../../services/constants';
import { EnvVariableService } from '../../services/environmentVariableService';
import ExternalPortabilityService from '../../services/externalPortabilityService';
import LocalStorageService, { LocalStorageRequest } from '../../services/localStorageService';
import { MonitoringService } from '../../services/monitoringService';
import PortabilityService from '../../services/portabilityService';
import { errorHandlingSaga, redirectThroughSaga } from '../../utils/providerSaga';
import GlobalActions from '../global/actions';
import { LoadingActions } from '../loading/actions';
import PostabilitiesStatusActions from '../portabilityStatus/action';
import { iSafeRequest } from '../storeConstants';
import UserInfoActions from '../user/actions';
import PortabilityActions from './actions';
import {
  ExternalPensionPlan,
  ExternalPortabilityStatusEnum,
  FinancialInstitution,
  PortabilityTypes,
  SupportedInstitutesId,
} from './types';

const { REACT_APP_INTER_ENV } = process.env;
const IS_NOT_RUNNING_PROD =
  REACT_APP_INTER_ENV && REACT_APP_INTER_ENV.toLowerCase() !== 'production';

function* acceptExternalPortabilityRequest({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show(LoadingIds.confirmButton));

    const iSafeResponse = yield* call(BridgeService.getISafeToken, iSafeRequest);

    yield* call(
      ExternalPortabilityService.acceptExternalPortabilityRequest,
      iSafeResponse,
      payload,
    );

    const response = yield* call(ExternalPortabilityService.getExternalPortabilityRequest, payload);
    yield* put(PortabilityActions.setExternalPortabilityRequest(response.data));
    yield* put(LoadingActions.hide());
  } catch (error: any) {
    yield* errorHandlingSaga(error, 'requestPortabilitySaga');
  }
}

function* getCustomerLastPendingPortability() {
  try {
    yield* put(LoadingActions.show(PortabilityHomeLoadingIds.page));

    const response = yield* call(ExternalPortabilityService.getCustomerLastPendingPortability);

    if (response.status === HttpStatus.OK && response.data[0]) {
      yield* put(PortabilityActions.setExternalPortabilityRequest(response.data[0]));
    }

    yield* put(LoadingActions.hide());
  } catch (error: any) {
    yield* errorHandlingSaga(error, 'requestPortabilitySaga');
  }
}

function* fetchExternalPortabilityRequest({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show(LoadingIds.page));

    const response = yield* call(ExternalPortabilityService.getExternalPortabilityRequest, payload);
    yield* put(PortabilityActions.setExternalPortabilityRequest(response.data));
    yield* put(LoadingActions.hide());
  } catch (error: any) {
    yield* errorHandlingSaga(error, 'requestPortabilitySaga');
  }
}

function* requestPortability({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show());

    const accumulatedValue = yield* select(PortabilityActions.getAccumulatedValue);
    const financialInstitution = yield* select(PortabilityActions.getFinancialInstitution);

    yield* call(
      PortabilityService.requestPortability,
      accumulatedValue,
      financialInstitution && financialInstitution.id,
    );

    yield* put(LoadingActions.hide());

    redirectThroughSaga(payload);
  } catch (error: any) {
    yield* errorHandlingSaga(error, 'requestPortabilitySaga');
  }
}

function* requestFinancialInstitutions({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show());

    const response = yield* call(PortabilityService.getFinancialInstitutions);

    /**
     * <pre>
     *  Renato Oliveira - 2022-07-04
     *  This localStorage item was setted to stay always expired,
     *  so the request for the financial institution is called always as well.
     *  This approach has been setted up just for tests reasons and should be undone.
     *  @link <https://jira.bancointer.com.br/browse/SEG-9263>
     * </pre>
     */
    const expiresAt = new Date();
    expiresAt.setDate(expiresAt.getDate());
    expiresAt.setHours(0, 0, 0, 0);

    const storeInstitutions = {
      data: response.data,
      requestDate: new Date(),
      expiresAt,
    } as LocalStorageRequest<FinancialInstitution[]>;
    LocalStorageService.setItem(LocalStorageKeys.FINANCIAL_INSTITUTIONS, storeInstitutions);

    yield* put(LoadingActions.hide());

    redirectThroughSaga(payload);
  } catch (error: any) {
    yield* errorHandlingSaga(error, 'requestFinancialInstitutionsSaga');
  }
}

function getSupportedInstitution(id: number): BelvoSupportedInstitution {
  let result = BelvoSupportedInstitution.EMPTY;

  if (id === SupportedInstitutesId.ITAU) {
    result = BelvoSupportedInstitution.ITAU;
  } else if (id === SupportedInstitutesId.BANCO_DO_BRASIL) {
    result = BelvoSupportedInstitution.BANCO_DO_BRASIL;
  }
  return result;
}

function* requestAccessToken({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show());

    const selectedInstitution = yield* select(PortabilityActions.getFinancialInstitution);
    const { useBelvoApiMock } = yield* select(GlobalActions.getQueryParams);
    const { data } = yield* call(PortabilityService.requestAccessToken);

    if (selectedInstitution) {
      let belvoSupportedInstitution = getSupportedInstitution(selectedInstitution.id);

      if (useBelvoApiMock && IS_NOT_RUNNING_PROD) {
        belvoSupportedInstitution = BelvoSupportedInstitution.FICTIONAL_INSTITUTION;
      }

      const belvoWidgetConfig: BelvoWidgetConfig = {
        exteral_id: '',
        access_mode: 'single',
        country_codes: ['BR'],
        institutions: [belvoSupportedInstitution],
        show_intro: false,
        resources: ['ACCOUNTS', 'OWNERS'],
        locale: 'pt',
        callback: (link: string) => {
          if (payload && payload.history && payload.pathname) {
            const { history, pathname } = payload;
            history.push({ pathname, state: { linkId: link } });
          }
        },
        onExit: (exitResponse: BelvoExitResponse) => {
          MonitoringService.log('requestAccessToken.onExit.exitResponse', exitResponse);
        },
        onEvent: (eventResponse: BelvoEventResponse) => {
          if (eventResponse.eventName === BelvoEventName.ERROR) {
            MonitoringService.noticeError(new Error(eventResponse.meta_data.error_message), {
              belvoEventResponse: JSON.stringify(eventResponse),
              errorCodeRef: 'requestAccessToken.BelvoConfig.onEvent',
            });
          } else {
            MonitoringService.log('requestAccessToken.onEvent.eventResponse', eventResponse);
          }
        },
      };

      if (window.belvoSDK && window.belvoSDK.createWidget) {
        window.belvoSDK.createWidget(data.access, belvoWidgetConfig).build();
      } else {
        throw new Error('Belvo SDK not found');
      }
    }

    yield* put(LoadingActions.hide());
  } catch (error: any) {
    yield* errorHandlingSaga(error, 'requestAccessToken');
  }
}

function* requestDataCollection({ payload }: AnyAction) {
  const { history } = payload;
  try {
    yield* put(LoadingActions.show());
    const financialInstitution = yield* select(PortabilityActions.getFinancialInstitution);
    const link = yield* select(PortabilityActions.getLinkId);

    const response = yield* call(
      PortabilityService.requestDataCollection,
      link,
      financialInstitution && financialInstitution.id,
    );

    yield* put(PortabilityActions.setPortabilityId(response.data.portabilityId));

    yield* put(LoadingActions.hide());
  } catch (error: any) {
    if (error.status === 400) {
      history.go(-2);
    }
    yield* errorHandlingSaga(error, 'requestDataCollection');
  }
}

function* requestPensionPlans({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show());
    let requestCount = yield* select(PortabilityActions.getRequestCount);
    const portabilityId = yield* select(PortabilityActions.getPortabilityId);
    const { account } = yield* select(UserInfoActions.get);

    const expiresAt = new Date();
    expiresAt.setDate(expiresAt.getDate() + 1);
    expiresAt.setHours(0, 0, 0, 0);

    const STATUS_CODE_OK = HttpStatus.OK;
    const STATUS_CODE_NO_CONTENT = HttpStatus.NO_CONTENT;
    const { cpf } = yield* select(UserInfoActions.get);

    const response = yield* call(PortabilityService.requestPensionPlans, portabilityId, cpf);

    if (response.status === STATUS_CODE_OK) {
      const externalPensionPlans: LocalStorageRequest<ExternalPensionPlan[]> = {
        data: response?.data,
        requestDate: new Date(),
        expiresAt,
      };
      LocalStorageService.setItem(
        LocalStorageKeys.EXTERNAL_PENSION_PLANS,
        externalPensionPlans,
        account,
      );

      redirectThroughSaga(payload);
      yield* put(LoadingActions.hide());
    }

    if (response.status === STATUS_CODE_NO_CONTENT) {
      yield* put(PortabilityActions.setRequestCount((requestCount += 1)));
    }
    yield* put(LoadingActions.hide());
  } catch (error: any) {
    yield* errorHandlingSaga(error, 'requestPensionPlans');
  }
}

function* checkIfTheUserTermsAreValid(paramsRoute: string, history: History) {
  const { account } = yield* select(UserInfoActions.get);
  const signedTermsFromLocalStorageIsValid = !LocalStorageService.hasExpired(
    LocalStorageKeys.USER_SIGNED_TERMS,
    account,
  );

  // The minimal app version to use the qualified investor term and suitability deeplinks.
  const startingVersionToValidadeCustomerSuitability = AppInfoService.convertToNumber(
    EnvVariableService.getVariable(
      EnvVariableKeys.STARTING_VERSION_TO_VALIDATE_CUSTOMER_SUITABILITY,
    )!,
  );

  const appVersionInfo = AppInfoService.convertToNumber(AppInfoService.superAppVersion);

  // We compare versions because only in the v11.2 of the app, we have the Suitability and Investor Profile deeplink
  // Under the v11.2, the user will follow the old flow.
  const isAppVersionAllowed = appVersionInfo >= startingVersionToValidadeCustomerSuitability;
  const userSignedTerms = yield* select(UserInfoActions.getUserSignedTerms);

  if (
    !signedTermsFromLocalStorageIsValid &&
    userSignedTerms.isAcceptable !== true &&
    isAppVersionAllowed
  ) {
    yield* put(UserInfoActions.requestUserSignedTerms({ pathname: paramsRoute, history }));
  } else {
    history.push(paramsRoute);
  }
}

function* requestPortabilities({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show(HomeButtonIds.portability));

    const response = yield* call(PortabilityService.requestPortabilities);

    yield* put(PostabilitiesStatusActions.setPortabilitiesStatus(response.data));

    yield* put(LoadingActions.hide());

    const { history } = payload;

    PortabilityAnalyticsService.homePortabilityButton(
      String(response.data.length),
      'Portabilidade de Previdência',
    );
    if (response.data && response.data.length > 0) {
      PortabilityAnalyticsService.portabilityStart('Portabilidade solicitada');
      redirectThroughSaga(payload);
    } else {
      PortabilityAnalyticsService.portabilityStart('Portabilidade não solicitada');
      yield* checkIfTheUserTermsAreValid(PageRoutes.PORTABILITY_HOME, history);
    }
  } catch (error: any) {
    yield* errorHandlingSaga(error, 'requestPortabilities');
  }
}

function* cancelPendingPortabilityRequest({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show());

    const iSafeResponse = yield* call(BridgeService.getISafeToken, iSafeRequest);

    const bodyRequest = { status: ExternalPortabilityStatusEnum.CANCELADA };

    yield* call(
      ExternalPortabilityService.cancelPendingHireProposal,
      payload.id,
      iSafeResponse,
      bodyRequest,
    );

    yield* put(PortabilityActions.resetState());

    yield* put(LoadingActions.hide());

    redirectThroughSaga(payload.data, payload.receiptProps);
  } catch (error: any) {
    yield* errorHandlingSaga(error, 'cancelPortabilitySaga');
  }
}

export default function* watchPortabilityRequest(): SagaGenerator<void> {
  yield* takeLeading(
    PortabilityTypes.GET_CUSTOMER_LAST_PENDING_PORTABILITY,
    getCustomerLastPendingPortability,
  );
  yield* takeLeading(
    PortabilityTypes.ACCEPT_EXTERNAL_PORTABILITY_REQUEST,
    acceptExternalPortabilityRequest,
  );
  yield* takeLeading(
    PortabilityTypes.FETCH_EXTERNAL_PORTABILITY_REQUEST,
    fetchExternalPortabilityRequest,
  );
  yield* takeLeading(PortabilityTypes.REQUEST, requestPortability);
  yield* takeLeading(PortabilityTypes.REQUEST_FINANCIAL_INSTITUTIONS, requestFinancialInstitutions);
  yield* takeLeading(PortabilityTypes.REQUEST_DATA_COLLECTION, requestDataCollection);
  yield* takeLeading(PortabilityTypes.REQUEST_PENSION_PLANS, requestPensionPlans);
  yield* takeLeading(PortabilityTypes.REQUEST_ACCESS_TOKEN, requestAccessToken);
  yield* takeLeading(PortabilityTypes.REQUEST_PORTABILITIES, requestPortabilities);
  yield* takeLeading(PortabilityTypes.CANCEL_PENDING_REQUEST, cancelPendingPortabilityRequest);
}
