/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { IUserBalanceInfo } from '../store/user/types';
import { BaseService, ServiceResponse } from './baseService';
import { MockService } from './mockService';

const {
  REACT_APP_INTER_ENV,
  REACT_APP_API_USER_INFO_V3,
  REACT_APP_API_BRIDGE_BALANCE_V3,
} = process.env;

class UserBalanceInfoService {
  static async requestUserBalanceInfo(
    userAccount: string | number,
    userCpf: string,
  ): Promise<ServiceResponse<IUserBalanceInfo>> {
    return BaseService.doExecute({
      endpoint: {
        browser: `${REACT_APP_API_USER_INFO_V3}/interno/saldo`,
        bridge: `${REACT_APP_API_BRIDGE_BALANCE_V3}/saldo`,
      },
      method: 'GET',
      headers: {
        browser: {
          'x-inter-cpf': userCpf,
          'x-inter-conta-corrente': userAccount as string,
          ...MockService.matchHeadersByUserAccount,
        },
        /**
         * This conditions is being fulfilled because:
         * At this date (18/05/2021) the Bridge is returning a CPF that is not the same of the CPF registered on the UAT backend (inter-consulta-cliente-service).
         * To avoid any problems, we are not sending the CPF received from the Bridge to the development and staging environments.
         * From the previdência backend view, there will be no error. But, don´t send the CPF creates one more processing for the backend to request the CPF.
         */
        bridge:
          REACT_APP_INTER_ENV === 'production'
            ? {
                'x-inter-cpf': userCpf,
                'x-inter-conta-corrente': userAccount as string,
              }
            : undefined,
      },
    });
  }
}
export default UserBalanceInfoService;
