export enum NavbarTypes {
  SET_TITLE = `@navbar/SET_TITLE`,
  SET_DIRECTION = `@navbar/SET_DIRECTION`,
  SET_CAN_GO_BACK = `@navbar/SET_CAN_GO_BACK`,
  SET_GO_INDEX = `@navbar/SET_GO_INDEX`,
}

export interface NavbarState {
  readonly title: string;
  readonly direction: string;
  readonly canGoBack: boolean;
  readonly goIndex: number;
}
