import {
  InterUIBottomSheet,
  InterUIButton,
  InterUICheckBox,
  InterUIContainer,
  InterUIIcon,
} from '@interco/inter-ui-react-lib';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import PortabilityAnalyticsService from '../../analytics/portabilityAnalyticsService';
import { EnvVariableKeys } from '../../enums/evironmentVariableKeys';
import FontSizeREM from '../../enums/fontSizesRem';
import { PageRoutes } from '../../enums/pageRoutes';
import PageTitles from '../../enums/pageTitles';
import { useAppendScript } from '../../hooks/useAppendScript';
import { EnvVariableService } from '../../services/environmentVariableService';
import { LoadingActions } from '../../store/loading/actions';
import NavbarActions from '../../store/navbar/actions';
import PortabilityActions from '../../store/portability/actions';
import { ThemeInter } from '../../styles';
import { Div, Flex, P, PSmall, Span } from '../../styles/commons';
import InstitutionIcons from './components/InstitutionsIcons/InstitutionIcons';
import { Point } from './PortabilityOnBoardingBelvo.styled';

const PortabilityOnboardingBelvoPage: React.FC = () => {
  const BELVO_SCRIPT = EnvVariableService.getVariable(EnvVariableKeys.BELVO_SCRIPT);
  const dispatch = useDispatch();
  const theme = useTheme() as ThemeInter;
  const history = useHistory();
  const appendScript = useAppendScript();
  const loading = useSelector(LoadingActions.get);
  const selectedInstitution = useSelector(PortabilityActions.getFinancialInstitution);
  const isPrivacyPolicyAccepted = useSelector(PortabilityActions.getIsPrivacyPolicyAccepted);
  const [bottomSheet, setBottomSheet] = useState<boolean>(false);
  const text = {
    title: 'Acelere sua portabilidade',
    messageBelvo:
      'Com a Belvo, nossa parceira, sua portabilidade acontece por volta de 15 dias úteis. Isso é 4 vezes mais rápido que o usual!',
    securityMessage:
      'É 100% seguro. A Belvo conecta na sua conta e coleta os dados da sua previdência.',
    dataMessage:
      'O Inter não terá acesso a sua senha e jamais poderá fazer qualquer transação em seu nome. A conexão é somente para leitura de dados.',
    belvoPrivacyPolicy: 'Política de privacidade da Belvo',
    withBelvoBtnLabel: 'Conectar na sua conta',
    withoutBtnLabel: 'Continuar manualmente',
  };

  const goToQueryPensionPlanPage = () => {
    if (isPrivacyPolicyAccepted) {
      PortabilityAnalyticsService.onboardingOptionButton(text.withBelvoBtnLabel);
      dispatch(
        PortabilityActions.requestAccessToken({
          history,
          pathname: PageRoutes.PORTABILITY_QUERY_PENSION_PLAN,
        }),
      );
    } else {
      setBottomSheet(true);
    }
  };

  const goToPortabilityDataPage = () => {
    PortabilityAnalyticsService.onboardingOptionButton(text.withoutBtnLabel);
    history.push(PageRoutes.PORTABILITY_ACCUMULATED_VALUE);
  };

  const goToPortabilityPreviewPrivacyPolicy = () => {
    history.push(PageRoutes.PORTABILITY_PREVIEW_PRIVACY_POLICY);
  };

  const ItemText: React.FC<{ textColor?: string }> = ({ children, textColor }) => (
    <P
      style={{ width: 'fit-content' }}
      fontSize={FontSizeREM.PX12}
      lineHeight={FontSizeREM.PX15}
      fontWeight={400}
      color={textColor || theme.colors.grayscale.A400}
      margin="0"
    >
      {children}
    </P>
  );

  const belvoPrivacyPolicy: React.ReactElement = (
    <Span
      fontSize={FontSizeREM.PX12}
      lineHeight={FontSizeREM.PX15}
      fontWeight={400}
      color={theme.colors.primary.A500}
      onClick={goToPortabilityPreviewPrivacyPolicy}
    >
      {text.belvoPrivacyPolicy}
    </Span>
  );

  const privacyPolicyAcceptance: React.ReactElement = (
    <Flex flexDirection="row" alignItems="center" justifyContent="space-between" margin="0 0 7px">
      <P
        fontSize={FontSizeREM.PX12}
        lineHeight={FontSizeREM.PX14}
        fontWeight={400}
        color={theme.colors.grayscale.A500}
        margin="0"
      >
        Li e concordo em permitir que a Belvo colete os dados para a portabilidade da previdência.
      </P>
      <InterUICheckBox
        variant="default"
        defaultChecked={isPrivacyPolicyAccepted}
        height="22px"
        width="22px"
        onClick={() => {
          dispatch(PortabilityActions.setIsPrivacyPolicyAccepted(!isPrivacyPolicyAccepted));
        }}
      />
    </Flex>
  );

  const stickyFooter: React.ReactElement = (
    <>
      {privacyPolicyAcceptance}
      <InterUIButton margin="0 0 16px" onClick={goToQueryPensionPlanPage} loading={loading}>
        <Flex flexDirection="row" alignItems="center" justifyContent="space-between">
          {text.withBelvoBtnLabel}{' '}
          <InterUIIcon name="arrow-chevron-right" color={theme.colors.white} />
        </Flex>
      </InterUIButton>

      <InterUIButton onClick={goToPortabilityDataPage} variant="secondary">
        <Flex flexDirection="row" alignItems="center" justifyContent="space-between">
          {text.withoutBtnLabel}{' '}
          <InterUIIcon name="arrow-chevron-right" color={theme.colors.primary.A500} />
        </Flex>
      </InterUIButton>
    </>
  );

  useEffect(
    () => {
      if (BELVO_SCRIPT) {
        appendScript(BELVO_SCRIPT);
      }
      dispatch(NavbarActions.setTitle(PageTitles.EMPTY));
    }, // Stryker disable next-line all
    [BELVO_SCRIPT, appendScript, dispatch],
  );

  return (
    <>
      <InterUIContainer margin="24px 24px 18px 24px" stickyFooter={stickyFooter}>
        <Div id="belvo" />
        <InstitutionIcons margin="20px 12px 15px 12px" institution={selectedInstitution} />
        <P
          fontSize={FontSizeREM.PX24}
          lineHeight={FontSizeREM.PX30}
          fontWeight={600}
          fontFamily="Sora"
          margin="0 0 10px 10px"
          color={theme.colors.grayscale.A500}
        >
          {text.title}
        </P>

        <Flex flexDirection="row" gap="14px" alignItems="center" margin="0 0 16px 14px">
          <Point color={theme.colors.grayscale.A500} />
          <ItemText>{text.messageBelvo}</ItemText>
        </Flex>

        <Flex flexDirection="row" gap="14px" alignItems="center" margin="0 0 16px 14px">
          <Point color={theme.colors.grayscale.A500} />
          <ItemText>{text.securityMessage}</ItemText>
        </Flex>

        <Flex flexDirection="row" gap="14px" alignItems="center" margin="0 0 16px 14px">
          <Point color={theme.colors.grayscale.A500} />
          <ItemText>{text.dataMessage}</ItemText>
        </Flex>

        <Flex flexDirection="row" gap="14px" alignItems="center" margin="0 0 0 14px">
          <Point color={theme.colors.grayscale.A500} />
          <ItemText>Conheça a {belvoPrivacyPolicy}</ItemText>
        </Flex>
      </InterUIContainer>
      <InterUIBottomSheet
        toggle={bottomSheet}
        onHide={() => {
          setBottomSheet(false);
        }}
        title="Aceite as condições para continuar"
      >
        <PSmall>Para continuar a política de privacidade precisa ser aceita.</PSmall>
        <InterUIButton
          margin="24px 0 0 0"
          onClick={() => {
            setBottomSheet(false);
          }}
        >
          Entendi
        </InterUIButton>
      </InterUIBottomSheet>
    </>
  );
};

export default PortabilityOnboardingBelvoPage;
