enum PageTitles {
  HOME = 'Previdência privada',
  ADDITIONAL_CONTRIBUTION = 'Investimento esporádico',
  OBJECTIVES_HOME = 'Previdência privada',
  RETIREMENT = 'Aposentadoria',
  PROPOSAL = 'Detalhes do plano indicado',
  SELECTABLE_FUNDS = 'Fundos disponíveis',
  TAX_TYPE = 'Tabela de tributação',
  PRIVATE_PENSION_TYPE = 'Modalidade do plano',
  CONTRIBUTION_TIME = 'Prazo de aposentadoria',
  PAYMENT_DAY = 'Débito Mensal',
  CONTRIBUTION_VALUES = 'Valor do investimento',
  PREVIEW_CONTRACT = 'Contrato Previdência Privada',
  REVISION = 'Revisão',
  PORTABILITY_HOME = 'Portabilidade de Previdência',
  PORTABILITY_SELECT_FINANCIAL_INSTITUTION = 'Previdência atual',
  PORTABILITY_CONFIRMATION = 'Portabilidade de Previdência',
  PORTABILITY_ONBOARDING_BELVO = 'Redirecionamento',
  PREVIEW_PRIVACY_POLICY = 'Política de Privacidade',
  PROPOSAL_BY_PORTABILITY = 'Dados para Portabilidade',
  PORTABILITY_REVISION = `Revisão`,
  PORTABILITY_EXTERNAL_PENSION_PLAN = 'Portabilidade de Previdência',
  PORTABILITY_QUERY_PENSION_PLAN = 'Portabilidade de Previdência',
  PORTABILITY_STATUS = 'Portabilidade de Previdência',
  PORTABILITY_CONTRACTION_RECEIPT = 'Portabilidade de Previdência',
  CONTRACTION_RECEIPT = 'Comprovante',
  INCOME_EXPENSE = 'Economizar no Imposto de Renda',
  BUY_GOOD = 'Conquistar uma Meta',
  INCOME_TAX = 'Economizar no Imposto de Renda',
  PROPOSAL_BY_FUND_CHOOSE_FUND = 'Fundos disponíveis',
  EXTERNAL_PORTABILITY = 'Autorização de Portabilidade',
  HIRE_PROPOSAL_ACCEPTANCE = 'Autorização de Contratação',
  HIRE_PROPOSAL_CANCEL_RECEIPT = 'Cancelamento de Contratação',
  PORTABILITY_CANCEL_RECEIPT = 'Cancelamento de Portabilidade',
  EMPTY = '',
}
export default PageTitles;
