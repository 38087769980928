import { AnyAction } from 'redux';
import { action } from 'typesafe-actions';
import { RootState } from '..';
import { IReceiptPageProps } from '../../pages/CancelReceipt/CancelReceiptPage';
import { SagaWithHistory } from '../../utils/providerSaga';
import {
  ExternalPensionPlan,
  ExternalPortabilityRequest,
  FinancialInstitution,
  PortabilityState,
  PortabilityTypes,
} from './types';

const getCustomerLastPendingPortability = (): AnyAction =>
  action(PortabilityTypes.GET_CUSTOMER_LAST_PENDING_PORTABILITY);

const setExternalPortabilityRequest = (request: ExternalPortabilityRequest | null): AnyAction =>
  action(PortabilityTypes.SET_EXTERNAL_PORTABILITY_REQUEST, request);

const fetchExternalPortabilityRequest = (id: number): AnyAction =>
  action(PortabilityTypes.FETCH_EXTERNAL_PORTABILITY_REQUEST, id);

const getExternalPortabilityRequest = (state: RootState): ExternalPortabilityRequest | null =>
  state.portability.externalPortabilityRequest;

const acceptExternalPortabilityRequest = (id: number): AnyAction =>
  action(PortabilityTypes.ACCEPT_EXTERNAL_PORTABILITY_REQUEST, id);

const get = (state: RootState): PortabilityState => state.portability;

const getFinancialInstitution = (state: RootState): FinancialInstitution | null =>
  state.portability.financialInstitution;

const setFinancialInstitution = (financialInstitution: FinancialInstitution | null): AnyAction =>
  action(PortabilityTypes.SET_FINANCIAL_INSTITUTION, financialInstitution);

const getAccumulatedValue = (state: RootState): number | null => state.portability.accumulatedValue;

const setAccumulatedValue = (accumulatedValue: number | null): AnyAction =>
  action(PortabilityTypes.SET_ACCUMULATED_VALUE, accumulatedValue);

const request = (data?: SagaWithHistory): AnyAction => action(PortabilityTypes.REQUEST, data);

const requestFinancialInstitutions = (data?: SagaWithHistory): AnyAction =>
  action(PortabilityTypes.REQUEST_FINANCIAL_INSTITUTIONS, data);

const requestAccessToken = (data?: SagaWithHistory): AnyAction =>
  action(PortabilityTypes.REQUEST_ACCESS_TOKEN, data);

const requestDataCollection = (data?: SagaWithHistory): AnyAction =>
  action(PortabilityTypes.REQUEST_DATA_COLLECTION, data);

const requestPensionPlans = (data?: SagaWithHistory): AnyAction =>
  action(PortabilityTypes.REQUEST_PENSION_PLANS, data);

const setPortabilityId = (portabilityId: number | null): AnyAction =>
  action(PortabilityTypes.SET_PORTABILITY_ID, portabilityId);

const getPortabilityId = (state: RootState): number | null => state.portability.portabilityId;

const setExternalPensionPlan = (externalPensionPlan: ExternalPensionPlan | null): AnyAction =>
  action(PortabilityTypes.SET_EXTERNAL_PENSION_PLAN, externalPensionPlan);

const getExternalPensionPlan = (state: RootState): ExternalPensionPlan | null =>
  state.portability.externalPensionPlan;

const setRequestCount = (requestCount: number): AnyAction =>
  action(PortabilityTypes.SET_REQUEST_COUNT, requestCount);

const getRequestCount = (state: RootState): number => state.portability.requestCount;

const setLinkId = (linkId: string): AnyAction => action(PortabilityTypes.SET_LINK_ID, linkId);

const getLinkId = (state: RootState): string | null => state.portability.linkId;

const requestPortabilities = (data?: SagaWithHistory): AnyAction =>
  action(PortabilityTypes.REQUEST_PORTABILITIES, data);

const getIsPrivacyPolicyAccepted = (state: RootState): boolean =>
  state.portability.isPrivacyPolicyAccepted;

const setIsPrivacyPolicyAccepted = (isPrivacyPolicyAccepted: boolean): AnyAction =>
  action(PortabilityTypes.SET_PRIVACY_POLICY_ACCEPTED, isPrivacyPolicyAccepted);

const cancelExternalPortabilityRequest = (
  id: number,
  receiptProps: IReceiptPageProps,
  data?: SagaWithHistory,
): AnyAction => action(PortabilityTypes.CANCEL_PENDING_REQUEST, { id, receiptProps, data });

const resetState = (): AnyAction => action(PortabilityTypes.RESET);

const PortabilityActions = {
  getCustomerLastPendingPortability,
  acceptExternalPortabilityRequest,
  fetchExternalPortabilityRequest,
  getExternalPortabilityRequest,
  setExternalPortabilityRequest,
  get,
  getFinancialInstitution,
  setFinancialInstitution,
  getAccumulatedValue,
  setAccumulatedValue,
  request,
  requestFinancialInstitutions,
  requestAccessToken,
  setExternalPensionPlan,
  getExternalPensionPlan,
  requestDataCollection,
  requestPensionPlans,
  setPortabilityId,
  getPortabilityId,
  setRequestCount,
  getRequestCount,
  setLinkId,
  getLinkId,
  requestPortabilities,
  getIsPrivacyPolicyAccepted,
  setIsPrivacyPolicyAccepted,
  cancelExternalPortabilityRequest,
  resetState,
};

export default PortabilityActions;
