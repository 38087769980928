/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/unbound-method */
import { History } from 'history';
import { AnyAction } from 'redux';
import { call, put, SagaGenerator, select, takeLeading } from 'typed-redux-saga';
import { LocalStorageKeys } from '../../enums/localStorageKeys';
import { PageRoutes } from '../../enums/pageRoutes';
import { PrivatePensions } from '../../enums/privatePensions';
import { HomeButtonIds } from '../../pages/Home/Home';
import AdditionalContributionService from '../../services/additionalContributionService';
import BridgeService from '../../services/bridgeService';
import LocalStorageService, { LocalStorageRequest } from '../../services/localStorageService';
import PrivatePensionPlanService from '../../services/privatePensionPlanService';
import { formatCertificateNumber } from '../../utils/numberFormatUtils';
import { errorHandlingSaga, redirectThroughSaga } from '../../utils/providerSaga';
import GlobalActions from '../global/actions';
import { LoadingActions } from '../loading/actions';
import UserInfoActions from '../user/actions';
import PrivatePensionPlanActions from './actions';
import { PrivatePensionPlan, PrivatePensionPlanStatus, PrivatePensionPlanTypes } from './types';

function* handleRedirect(history: History, plans: PrivatePensionPlan[]) {
  if (plans.length === 1) {
    yield* put(PrivatePensionPlanActions.setPrivatePensionPlan(plans[0]));
    if (!plans[0].isValidToContribute) {
      history.push(PageRoutes.ADDITIONAL_CONTRIBUTION_EXPIRED);
    } else if (plans[0].privatePensionType === PrivatePensions.PGBL) {
      history.push(PageRoutes.ADDITIONAL_CONTRIBUTION_PGBL_HELPER);
    } else if (plans[0].privatePensionType === PrivatePensions.VGBL) {
      history.push(PageRoutes.ADDITIONAL_CONTRIBUTION_INVESTMENT_FORM);
    } else {
      history.push(PageRoutes.OBJECTIVES_HOME);
    }
  } else if (plans.length > 1) {
    history.push(PageRoutes.PRIVATE_PENSION_PLANS);
  } else {
    history.push(PageRoutes.OBJECTIVES_HOME);
  }
}

function* requestPrivatePensionPlan({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show(HomeButtonIds.investMore));
    const response = yield* call(PrivatePensionPlanService.getPrivatePensionPlans);
    const { history } = payload;

    const { account } = yield* select(UserInfoActions.get);

    // Array of certificate number with error
    const failedPrivatePensionPlans = yield* select(
      PrivatePensionPlanActions.getFailedPrivatePensionPlans,
    );

    const cachedResponse = LocalStorageService.getItem<PrivatePensionPlan[]>(
      LocalStorageKeys.PRIVATE_PENSION_PLANS,
      account,
    );

    if (cachedResponse && failedPrivatePensionPlans) {
      failedPrivatePensionPlans?.forEach((certificateNumber: string) => {
        const currentPlan = response.data.find(
          (plan: PrivatePensionPlan) => plan.certificateNumber === certificateNumber,
        );
        if (currentPlan && currentPlan?.status !== PrivatePensionPlanStatus.ERROR) {
          // REMOVING THE PLAN WITH ERROR THAT WAS RESOLVED FROM THE CACHE
          (cachedResponse?.data).splice(
            (cachedResponse?.data).findIndex(
              (plan: PrivatePensionPlan) => plan.certificateNumber === certificateNumber,
            ),
            1,
          );

          // Adding the plan with error that was resolved in to cahe
          (cachedResponse?.data).push(currentPlan);
        }
      });
    }

    const expiresAt = new Date();
    expiresAt.setDate(expiresAt.getDate() + 1);
    expiresAt.setHours(0, 0, 0, 0);

    const privatePensionPlans: LocalStorageRequest<PrivatePensionPlan[]> = {
      data: cachedResponse?.data || response?.data,
      requestDate: new Date(),
      expiresAt,
    };

    LocalStorageService.setItem(
      LocalStorageKeys.PRIVATE_PENSION_PLANS,
      privatePensionPlans,
      account,
    );

    yield* handleRedirect(history, response.data);
    yield* put(LoadingActions.hide());
  } catch (error: any) {
    yield* errorHandlingSaga(error, 'requestPrivatePensionPlanSaga');
  }
}

function* requestPrivatePensionPlanDeeplink({ payload }: AnyAction) {
  const { history } = payload;

  try {
    yield* put(LoadingActions.show());

    const { numeroCertificado } = yield* select(GlobalActions.getQueryParams);
    const response = yield* call(PrivatePensionPlanService.getPrivatePensionPlans);
    const { account } = yield* select(UserInfoActions.get);

    const expiresAt = new Date();
    expiresAt.setDate(expiresAt.getDate() + 1);
    expiresAt.setHours(0, 0, 0, 0);

    const privatePensionPlans: LocalStorageRequest<PrivatePensionPlan[]> = {
      data: response.data,
      requestDate: new Date(),
      expiresAt,
    };

    LocalStorageService.setItem(
      LocalStorageKeys.PRIVATE_PENSION_PLANS,
      privatePensionPlans,
      account,
    );

    let privatePensionPlan = response.data;

    if (numeroCertificado) {
      const deeplinkResponse = response.data?.filter(
        (element: any) =>
          formatCertificateNumber(element?.certificateNumber) ===
          formatCertificateNumber(numeroCertificado),
      );
      privatePensionPlan = deeplinkResponse.length < 1 ? response.data : deeplinkResponse;
    }

    yield* handleRedirect(history, privatePensionPlan);
    yield* put(LoadingActions.hide());
  } catch (error: any) {
    history.push(PageRoutes.HOME);
    yield* put(LoadingActions.hide());
  }
}

function* requestAdditionalContribution({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show());

    const { additionalContribution, privatePensionPlan } = yield* select(
      PrivatePensionPlanActions.get,
    );
    const { certificateNumber } = privatePensionPlan;

    const bodyRequest = {
      additionalContribution: additionalContribution!,
      investmentFundCode: privatePensionPlan.investmentFund?.code || '',
      privatePensionType: privatePensionPlan.privatePensionType!,
      taxType: privatePensionPlan.taxType!,
    };

    // category: '16',
    // checkType: '5',
    // feature: 'AUTORIZACAO_SEGURO',

    // '56',
    // '5',
    // 'AUTORIZACAO_RECUPERAR_DADOS_OCULTOS_CARTAO_VIRTUAL',
    const iSafeRequest = {
      category: '56',
      checkType: '5',
      feature: 'AUTORIZACAO_RECUPERAR_DADOS_OCULTOS_CARTAO_VIRTUAL',
      value: 0,
    };

    const iSafeResponse = yield* call(BridgeService.getISafeToken, iSafeRequest);

    const { data } = yield* call(
      AdditionalContributionService.getContributionReceipt,
      certificateNumber,
      iSafeResponse,
      bodyRequest,
    );

    yield* put(
      PrivatePensionPlanActions.setAdditionalContributionId(data.additionalContributionId),
    );

    yield* put(LoadingActions.hide());
    redirectThroughSaga(payload);
  } catch (error: any) {
    yield* errorHandlingSaga(error, 'requestAdditionalContributionSaga');
  }
}

export default function* watchPrivatePensionPlans(): SagaGenerator<void> {
  yield* takeLeading(
    PrivatePensionPlanTypes.REQUEST_PRIVATE_PENSION_PLANS,
    requestPrivatePensionPlan,
  );
  yield* takeLeading(
    PrivatePensionPlanTypes.REQUEST_PRIVATE_PENSION_PLANS_DEEP_LINK,
    requestPrivatePensionPlanDeeplink,
  );
  yield* takeLeading(
    PrivatePensionPlanTypes.REQUEST_ADDITIONAL_CONTRIBUTION,
    requestAdditionalContribution,
  );
}
