export enum ErrorTypes {
  SET_ERROR_RESPONSE = `@error/SET_ERROR_RESPONSE`,
  SET_ERROR_INSTANCE = `@error/SET_ERROR_INSTANCE`,
  SET_IS_DETAILED_ERROR = `@error/SET_IS_DETAILED_ERROR`,
  GET = `@error/GET`,
  SHOW = `@error/SHOW`,
  HIDE = `@error/HIDE`,
  RESET = `@error/RESET`,
}

export const ERROR_MESSAGE_DEFAULT =
  'No momento, essa funcionalidade está indisponível. Por favor, tente novamente em alguns minutos.';

export interface ErrorState {
  hasError: boolean;
  status?: string | number;
  errorResponse: ErrorResponse;
  errorInstance: Error;
  isDetailedError: boolean;
}

export interface ErrorResponse {
  totalErrors: number;
  errors: Array<IError>;
  logref?: number;
}

export interface IError {
  readonly code: string;
  readonly message: string;
}
