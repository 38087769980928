import React from 'react';
import { useHistory } from 'react-router-dom';
import ProposalAnalyticsService from '../../../../analytics/proposalAnalyticsService';
import FontSizeREM from '../../../../enums/fontSizesRem';
import { PageRoutes } from '../../../../enums/pageRoutes';
import { TaxTypes } from '../../../../enums/privatePensions';
import { ProposalTypes } from '../../../../store/proposal/types';
import Colors from '../../../../styles/colors';
import { P } from '../../../../styles/commons';
import { InterUIAlertCustomized } from '../../../../styles/inter-ui-customizations';

export interface RegressiveTaxWarningProps {
  type: ProposalTypes;
  currentTaxType: TaxTypes;
}

const RegressiveTaxWarning: React.FC<RegressiveTaxWarningProps> = ({ type, currentTaxType }) => {
  const history = useHistory();

  const goToTaxTypePage = () => {
    ProposalAnalyticsService.editInfoButton('tabela', type);
    history.push(PageRoutes.TAX_TYPE);
  };

  if (currentTaxType === TaxTypes.REGRESSIVE) {
    return (
      <InterUIAlertCustomized
        margin="16px 0 16px 0"
        type="attention"
        data-testid="alert-message-regressive-tax"
      >
        <P
          fontWeight={700}
          fontSize={FontSizeREM.PX12}
          lineHeight={FontSizeREM.PX15}
          marginBottom="4px"
        >
          Tabela de Tributação Regressiva
        </P>
        <P fontWeight={400} fontSize={FontSizeREM.PX12} lineHeight={FontSizeREM.PX15}>
          Atenção! A alíquota diminui ao longo do tempo nesse regime e não é possível alterar para o
          Progressivo posteriormente.{' '}
          <span
            role="button"
            onClick={goToTaxTypePage}
            aria-hidden
            style={{ fontWeight: 'bold', color: Colors.interOrange }}
            data-testid="btn-message-regressive"
          >
            Clique aqui{' '}
          </span>{' '}
          para alterar ou saber mais.
        </P>
      </InterUIAlertCustomized>
    );
  }

  return <></>;
};

export default RegressiveTaxWarning;
