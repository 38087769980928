import { AnyAction, Reducer } from 'redux';
import { PortabilitiesStatusState, PortabilityStatusTypes } from './types';

const INITIAL_STATE: PortabilitiesStatusState = {
  portabilitiesStatus: [],
};

const reducer: Reducer<PortabilitiesStatusState> = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case PortabilityStatusTypes.SET_PORTABILITIES:
      return { ...state, portabilitiesStatus: action.payload };
    case PortabilityStatusTypes.RESET:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default reducer;
