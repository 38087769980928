/* eslint-disable no-void */
import { InterUIButton, InterUIContainer, InterUIIcon } from '@interco/inter-ui-react-lib';
import React, { useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PortabilityAnalyticsService from '../../analytics/portabilityAnalyticsService';
import Success from '../../assets/success/Success.png';
import { EnvVariableKeys } from '../../enums/evironmentVariableKeys';
import FontSizeREM from '../../enums/fontSizesRem';
import { PageRoutes } from '../../enums/pageRoutes';
import PageTitles from '../../enums/pageTitles';
import { useRemoveScript } from '../../hooks';
import BridgeService from '../../services/bridgeService';
import { EnvVariableService } from '../../services/environmentVariableService';
import ErrorActions from '../../store/error/actions';
import NavbarActions from '../../store/navbar/actions';
import PortabilityActions from '../../store/portability/actions';
import Colors from '../../styles/colors';
import { Flex, H1, P, Span } from '../../styles/commons';
import { _try } from '../../utils/errorUtils';

const PortabilityConfirmationPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const removeScript = useRemoveScript();
  const selectedInstitution = useSelector(PortabilityActions.getFinancialInstitution);
  const accumulatedValue = useSelector(PortabilityActions.getAccumulatedValue);
  const BELVO_SCRIPT = EnvVariableService.getVariable(EnvVariableKeys.BELVO_SCRIPT);
  const handleError = useErrorHandler();

  const goToAppHome = () =>
    _try(
      async () => {
        await BridgeService.goToAppHomeOrBackToNative(history, PageRoutes.HOME);
      },
      handleError,
      'PortabilityConfirmationPage.goToAppHome',
    );

  const stikyFooter: React.ReactElement = (
    <InterUIButton type="button" onClick={goToAppHome}>
      <Flex flexDirection="row" alignItems="center" justifyContent="space-between">
        <Span fontWeight={600} fontSize={FontSizeREM.PX14} lineHeight={FontSizeREM.PX24}>
          Ir para a home
        </Span>
        <InterUIIcon name="arrow-right" size="24px" color={Colors.white} />
      </Flex>
    </InterUIButton>
  );

  useEffect(
    () => {
      PortabilityAnalyticsService.contractionReceipt(
        selectedInstitution?.name as string,
        String(accumulatedValue),
      );
      dispatch(NavbarActions.setTitle(PageTitles.PORTABILITY_CONFIRMATION));
      dispatch(ErrorActions.resetState());
      if (BELVO_SCRIPT) {
        removeScript(BELVO_SCRIPT);
      }
    },
    // Stryker disable next-line all
    [BELVO_SCRIPT, accumulatedValue, dispatch, removeScript, selectedInstitution],
  );

  return (
    <InterUIContainer stickyFooter={stikyFooter}>
      <Flex flexDirection="row" width="100%" justifyContent="center" margin="49px 0 28px">
        <img src={Success} alt="Símbolo de sucesso" />
      </Flex>
      <H1 marginBottom="8px">Solicitação recebida!</H1>
      <P
        fontSize={FontSizeREM.PX14}
        lineHeight={FontSizeREM.PX17}
        fontWeight={400}
        color={Colors.shuttleGray}
      >
        Tudo certo até aqui! Agora, você receberá um e-mail com os próximos passos para trazer sua
        previdência para o Inter. Pode contar com nossa ajuda sempre que precisar!
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        lineHeight={FontSizeREM.PX17}
        fontWeight={400}
        color={Colors.shuttleGray}
      >
        Em breve, você vai aproveitar todos os benefícios de ter sua previdência com a gente!
      </P>
    </InterUIContainer>
  );
};

export default PortabilityConfirmationPage;
