import { AnyAction } from 'redux';
import { action } from 'typesafe-actions';
import { RootState } from '..';
import { BuyGoodPageState, BuyGoodPageType } from './types';

const get = (state: RootState): BuyGoodPageState => state.buyGood;

const set = (buyGood: BuyGoodPageState): AnyAction =>
  action(BuyGoodPageType.SET_BUY_GOOD_STATE, buyGood);

const resetState = (): AnyAction => action(BuyGoodPageType.RESET);

export const BuyGoodActions = {
  get,
  set,
  resetState,
};
