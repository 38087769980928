import { InterUIButton } from '@interco/inter-ui-react-lib';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { InvestmentFund } from '../../components/InvestmentFundCard/InvestmentFundCard';
import SelectableFundsList from '../../components/SelectableFundsList/SelectableFundsList';
import { LocalStorageKeys } from '../../enums/localStorageKeys';
import { PageRoutes } from '../../enums/pageRoutes';
import PageTitles from '../../enums/pageTitles';
import LocalStorageService from '../../services/localStorageService';
import NavbarActions from '../../store/navbar/actions';
import { ProposalDetailActions } from '../../store/proposal/actions';
import { ProposalTypes } from '../../store/proposal/types';
import { ThemeInter } from '../../styles';
import { Div, H3 } from '../../styles/commons';
import { InterContainerNoFooter } from '../../styles/inter-ui-customizations';

const CHOOSE_FUND_PAGE_INFO = {
  title: 'Escolha entre os outros fundos disponíveis pra você',
};
const EMPTY_FUND_CODE = '';

const ProposalByFundChooseFundPage: React.FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme() as ThemeInter;
  const history = useHistory();
  const currentFund = useSelector(ProposalDetailActions.getInvestmentFund);
  const [selectedFund, setSelectedFund] = useState(currentFund);

  useEffect(() => {
    dispatch(NavbarActions.setTitle(PageTitles.PROPOSAL_BY_FUND_CHOOSE_FUND));
    dispatch(ProposalDetailActions.setType(ProposalTypes.BY_FUND));
  }, [dispatch]);

  const funds = useMemo(() => {
    const fundsRequest = LocalStorageService.getItem<InvestmentFund[]>(
      LocalStorageKeys.AVAILABLE_FUNDS,
    );
    if (fundsRequest) {
      return fundsRequest.data;
    }
    return [];
  }, []);

  const onSubmit = () => {
    dispatch(ProposalDetailActions.setInvestimentFund(selectedFund));
    history.push(PageRoutes.PROPOSAL_BY_FUND_CONTRIBUTION_VALUES);
  };

  return (
    <InterContainerNoFooter margin="0 24px 24px">
      <SelectableFundsList
        onSelect={(fund) => setSelectedFund(fund)}
        selected={selectedFund}
        funds={funds}
      >
        <H3 marginBottom="8px">{CHOOSE_FUND_PAGE_INFO.title}</H3>
      </SelectableFundsList>
      <Div
        position="fixed"
        left="0px"
        bottom="0px"
        width="100%"
        backgroundColor={theme.colors.white}
      >
        <Div position="relative" margin="24px">
          <InterUIButton
            type="button"
            data-testid="choose-fund-btn"
            disabled={selectedFund.code === EMPTY_FUND_CODE}
            onClick={onSubmit}
          >
            Escolher fundo
          </InterUIButton>
        </Div>
      </Div>
      <Div height="72px" />
    </InterContainerNoFooter>
  );
};

export default ProposalByFundChooseFundPage;
