const ELEMENT_TAG_NAME = 'script';
const ELEMENT_TYPE = 'text/javascript';

const createElement = (src: string): void => {
  const element: HTMLScriptElement = document.createElement(ELEMENT_TAG_NAME);
  element.src = src;
  element.type = ELEMENT_TYPE;
  element.async = true;
  document.body.appendChild(element);
};

const appendScript = (src: string): void => {
  const elements: HTMLCollectionOf<HTMLScriptElement> = document.body.getElementsByTagName(
    ELEMENT_TAG_NAME,
  );

  const shoulNotCreate = Array.from(elements).some((element) => element.src === src);

  if (!shoulNotCreate) {
    createElement(src);
  }
};

/**
 * A hook that returns a function to append a third party script.
 *
 * @tutorial
 * const appendScript = useAppendScript();
 * appendScript('https:...');
 *
 * @returns a function to append the script
 */
export const useAppendScript = (): ((src: string) => void) => appendScript;
