import AnalyticsBaseEventName from '../enums/analyticsBaseEventNames';
import BridgeService from '../services/bridgeService';
import { ProposalTypes } from '../store/proposal/types';
import { handlerProposalType } from '../utils/analyticsUtils';
import { IContributionValues, IEditInfoConfirmation, IProposalDetailsState } from './types';

class ProposalAnalyticsService {
  private static className = 'ProposalAnalyticsService';

  static editFundButton(currentFund: string, proposalType: ProposalTypes): void {
    BridgeService.requestAnalytics(
      `${this.className}.editFundButton`,
      `${AnalyticsBaseEventName.PROPOSAL}T_OutroFundo`,
      {
        content_name: currentFund,
        content_id: handlerProposalType(proposalType),
      },
    );
  }

  static editInfoButton(buttonName: string, proposalType: ProposalTypes): void {
    BridgeService.requestAnalytics(
      `${this.className}.editInfoButton`,
      `${AnalyticsBaseEventName.PROPOSAL}T_Editar`,
      {
        content_name: buttonName,
        content_id: handlerProposalType(proposalType),
      },
    );
  }

  static contractProposal({
    hasInsurance,
    content_data,
    content_fundo,
    content_mod,
    content_tempo,
    content_trib,
    price_inicial,
    price_mensal,
  }: IProposalDetailsState): void {
    BridgeService.requestAnalytics(
      `${this.className}.contractProposal`,
      `${AnalyticsBaseEventName.PROPOSAL}T_Contratar`,
      {
        content_data: price_mensal === '0,00' ? '0' : content_data,
        content_fundo,
        content_mod,
        content_tempo,
        content_trib,
        price_inicial,
        price_mensal,
        currency: 'BRL',
        content_protecao: hasInsurance ? 'Com proteção' : 'Sem proteção',
        content_id: 'Aposentadoria',
      },
    );
  }

  static editInfoConfirmation(
    { content_area, content_new_info, content_info }: IEditInfoConfirmation,
    proposalType: ProposalTypes,
  ): void {
    BridgeService.requestAnalytics(
      `${this.className}.editInfoConfirmation`,
      'C_PrevidPrivada_Editar_T_Definir',
      {
        content_area,
        content_new_info,
        content_info,
        content_id: handlerProposalType(proposalType),
      },
    );
  }

  static editContributionValues({
    content_inicial_new,
    content_inicial_old,
    content_mensal_new,
    content_mensal_old,
  }: IContributionValues): void {
    BridgeService.requestAnalytics(
      `${this.className}.editContributionValues`,
      'C_PrevidPrivada_Editar_T_Definir',
      {
        content_area: 'valores de contribuição',
        content_inicial_new,
        content_inicial_old,
        content_mensal_new,
        content_mensal_old,
        content_id: 'Aposentadoria',
      },
    );
  }

  static proposal(
    {
      content_data,
      content_fundo,
      content_mod,
      content_tempo,
      content_trib,
      price_inicial,
      price_mensal,
    }: IProposalDetailsState,
    proposalType: ProposalTypes,
  ): void {
    BridgeService.requestAnalytics(
      `${this.className}.proposal`,
      `${AnalyticsBaseEventName.PROPOSAL}DC`,
      {
        content_data,
        content_fundo,
        content_mod,
        content_tempo,
        content_trib,
        price_inicial,
        price_mensal,
        currency: 'BRL',
        content_id: handlerProposalType(proposalType),
      },
    );
  }

  static selectNewFund(newfund: string, proposalType: ProposalTypes): void {
    BridgeService.requestAnalytics(
      `${this.className}.selectNewFund`,
      `C_PrevidPrivada_Fundo_T_Escolher`,
      {
        content_name: newfund,
        content_id: handlerProposalType(proposalType),
      },
    );
  }

  static debitBottomSheetClick(content_name: string, proposalType: ProposalTypes): void {
    BridgeService.requestAnalytics(
      `${this.className}.debitBottomSheetClick`,
      `${AnalyticsBaseEventName.PROPOSAL}BSDiaDeb_T_Entendi`,
      {
        content_name,
        content_id: handlerProposalType(proposalType),
      },
    );
  }

  static contributionTimeBottomSheetClick(proposalType: ProposalTypes): void {
    BridgeService.requestAnalytics(
      `${this.className}.contributionTimeBottomSheetClick`,
      `${AnalyticsBaseEventName.PROPOSAL}BSTempContrib_T_Entendi`,
      {
        content_id: handlerProposalType(proposalType),
      },
    );
  }

  static inssBottomSheetClick(): void {
    BridgeService.requestAnalytics(
      `${this.className}.declarationTableBottomSheetClick`,
      `${AnalyticsBaseEventName.PROPOSAL}C_IR_BSINSS_T_Entendi`,
    );
  }

  static irrfBottomSheetClick(): void {
    BridgeService.requestAnalytics(
      `${this.className}.declarationTableBottomSheetClick`,
      `${AnalyticsBaseEventName.PROPOSAL}C_IR_BSIRRF_T_Entendi`,
    );
  }
}
export default ProposalAnalyticsService;
