import {
  ActiveDataPoint,
  CategoryScale,
  Chart,
  ChartData,
  ChartEvent,
  ChartItem,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import React, { RefObject, useEffect, useMemo, useState } from 'react';
import { useTheme } from 'styled-components';
import { ThemeInter } from '../../../../styles';
import { formatToBRLCurrency } from '../../../../utils/numberFormatUtils';
import { ILineChartDataset } from '../../../../utils/simulationLineChartUtils';
import { LegendChart, Point } from './SimulationLineChart.styles';

interface ISimulationLineChartProps {
  cdiDataset: Array<ILineChartDataset>;
  fundDataset: Array<ILineChartDataset>;
  labelType: 'month' | 'year';
  showCDI: boolean;
}

// Stryker disable all
const createChart = (context: ChartItem, data: ChartData) =>
  new Chart(context, {
    type: 'line',
    data,
    options: {
      animation: {
        duration: 0,
        onComplete: (event) => {
          const elementsToActive: ActiveDataPoint[] = [];
          const lastPointIndex = event.chart.data.datasets[0].data.length - 1;
          event.chart.data.datasets.forEach((val, datasetIndex) => {
            event.chart.getDatasetMeta(datasetIndex).data.forEach((el, index) => {
              if (index === lastPointIndex) {
                elementsToActive.push({ index, datasetIndex });
              }
            });
          });
          event.chart.setActiveElements(elementsToActive);
        },
      },
      plugins: {
        legend: {
          display: false,
          position: 'bottom',
          align: 'start',
          fullSize: true,
          labels: {
            usePointStyle: true,
            color: '#000000',
            font: {
              size: 10,
              weight: '700',
              family: 'Inter',
            },
          },
          onClick: (e: ChartEvent) => e.native?.stopPropagation(),
        },
        tooltip: {
          enabled: true,
          mode: 'x',
          position: 'nearest',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          displayColors: false,
          callbacks: {
            title: () => '',
            label: (item) => formatToBRLCurrency(item.parsed.y),
            labelTextColor: (item) => item.dataset.backgroundColor?.toString() || '#000000',
          },
        },
      },
      responsive: true,
      interaction: {
        intersect: true,
        mode: 'point',
      },
      layout: {
        padding: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        },
      },
      scales: {
        x: {
          grid: {
            display: true,
            borderColor: '#DEDFE4',
            borderDash: [5, 10],
          },
          ticks: {
            color: '#000000',
            font: {
              size: 8,
              family: 'Inter',
            },
            autoSkip: false,
            maxRotation: 45,
            padding: 5,
          },
        },
        y: {
          grid: {
            borderWidth: 0.5,
            borderColor: '#F5F6FA',
          },
          ticks: {
            padding: 5,
            color: '#000000',
            font: {
              size: 8,
              family: 'Inter',
            },
            callback(label) {
              return formatToBRLCurrency(Number(label));
            },
          },
        },
      },
      elements: {
        point: {
          radius: 0,
        },
        line: {
          tension: 0.3,
        },
      },
    },
  });

const SimulationLineChart: React.FC<ISimulationLineChartProps> = ({
  cdiDataset,
  fundDataset,
  labelType,
  showCDI,
}) => {
  const theme = useTheme() as ThemeInter;
  const chartRef: RefObject<HTMLCanvasElement> = React.createRef();
  const [chartInstance, setChartInstance] = useState<Chart>();
  const data = useMemo(
    () =>
      ({
        labels: fundDataset.map((dataset) =>
          labelType === 'month' ? dataset.label.substring(3) : dataset.label.substring(6),
        ),
        datasets: [
          {
            data: fundDataset.map((dataset) => dataset.data),
            borderColor: theme.colors.primary.A400,
            backgroundColor: theme.colors.primary.A400,
            pointRadius: 1,
            pointHitRadius: 10,
          },
          {
            data: showCDI && cdiDataset?.map((dataset) => dataset.data),
            borderColor: theme.colors.grayscale.A300,
            backgroundColor: theme.colors.grayscale.A300,
            pointRadius: 1,
            pointHitRadius: 10,
          },
        ],
      } as ChartData),
    [
      cdiDataset,
      fundDataset,
      labelType,
      showCDI,
      theme.colors.grayscale.A300,
      theme.colors.primary.A400,
    ],
  );

  Chart.register(
    LineController,
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale,
    Title,
    Legend,
    Tooltip,
  );

  useEffect(() => {
    const ctx = chartRef.current?.getContext('2d') as ChartItem;
    if (!chartInstance) {
      setChartInstance(createChart(ctx, data));
    }
  }, [chartRef, chartInstance, data]);

  useEffect(() => {
    if (chartInstance) {
      chartInstance.data = data;
      chartInstance.update();
    }
  }, [chartInstance, data]);

  return (
    <>
      <canvas data-testid="simulation-chart" width="312" height="251" ref={chartRef} />
      <LegendChart>
        <Point color={theme.colors.primary.A400} />
        Simulação: {formatToBRLCurrency(fundDataset[fundDataset.length - 1].data)}
      </LegendChart>
      {showCDI && (
        <LegendChart>
          <Point color={theme.colors.grayscale.A300} />
          CDI: {formatToBRLCurrency(cdiDataset[cdiDataset.length - 1].data)}
        </LegendChart>
      )}
    </>
  );
};

export default SimulationLineChart;
