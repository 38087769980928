import { InterUIAlert } from '@interco/inter-ui-react-lib';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import FontSizeREM from '../../../../enums/fontSizesRem';
import { ProposalDetailActions } from '../../../../store/proposal/actions';
import Colors from '../../../../styles/colors';
import { P } from '../../../../styles/commons';
import { convertDateToStringPtBR } from '../../../../utils/dateTimeUtils';
import {
  choosePoints,
  getCDIDatasets,
  getFundDatasets,
  ILineChartDataset,
} from '../../../../utils/simulationLineChartUtils';
import SimulationLineChart from '../SimulationLineChart/SimulationLineChart';

// Stryker disable all
const Simulation: React.FC = () => {
  const fundSelected = useSelector(ProposalDetailActions.getInvestmentFund);
  const initialContribution = useSelector(ProposalDetailActions.getInitialContribution);
  const monthlyContribution = useSelector(ProposalDetailActions.getMonthlyContribution);
  const monthsInvesting = useSelector(ProposalDetailActions.getNumberMonthsInvesting);

  const contributionTime = useMemo(() => {
    if (initialContribution > 0) {
      return monthsInvesting;
    }
    return monthsInvesting - 1;
  }, [initialContribution, monthsInvesting]);

  const cdiDataset = useMemo(() => {
    let datasets: Array<ILineChartDataset> = [];
    const birthDate = new Date();

    datasets.push({
      label: convertDateToStringPtBR(birthDate),
      data: initialContribution || monthlyContribution,
    });

    datasets = datasets.concat(
      getCDIDatasets(
        contributionTime,
        initialContribution || monthlyContribution,
        monthlyContribution,
      ),
    );
    if (contributionTime < 4) {
      return datasets;
    }
    return choosePoints(datasets, contributionTime);
  }, [contributionTime, initialContribution, monthlyContribution]);

  const fundDataset = useMemo(() => {
    let datasets: Array<ILineChartDataset> = [];

    datasets.push({
      label: convertDateToStringPtBR(new Date()),
      data: initialContribution || monthlyContribution,
    });

    datasets = datasets.concat(
      getFundDatasets(
        fundSelected.interestRate,
        contributionTime,
        initialContribution || monthlyContribution,
        monthlyContribution,
      ),
    );

    if (contributionTime < 4) {
      return datasets;
    }

    return choosePoints(datasets, contributionTime);
  }, [contributionTime, fundSelected.interestRate, initialContribution, monthlyContribution]);

  const showCDI = useMemo(
    () => fundDataset[fundDataset.length - 1].data > cdiDataset[cdiDataset.length - 1].data,
    [cdiDataset, fundDataset],
  );

  return (
    <>
      <P lineHeight={FontSizeREM.PX20} marginBottom="9px" fontFamily="Sora">
        Uma simulação da sua previdência
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        color={Colors.shuttleGray}
        fontWeight={400}
        marginBottom="21px"
      >
        {showCDI
          ? 'Essa simulação é feita com base nas rentabilidades passadas do fundo escolhido, e em comparação com o CDI'
          : 'Essa simulação é feita com base nas rentabilidades passadas do fundo escolhido.'}
      </P>
      <SimulationLineChart
        cdiDataset={cdiDataset}
        fundDataset={fundDataset}
        labelType={contributionTime >= 48 ? 'year' : 'month'}
        showCDI={showCDI}
      />
      <InterUIAlert margin="16px 0 16px 0">
        <P
          fontWeight={700}
          fontSize={FontSizeREM.PX12}
          lineHeight={FontSizeREM.PX15}
          marginBottom="4px"
        >
          Essa é uma simulação
        </P>
        <P fontWeight={400} fontSize={FontSizeREM.PX12} lineHeight={FontSizeREM.PX15}>
          Os valores reais podem variar de acordo com o tempo de permanência, valores aplicados,
          rentabilidade do fundo e tributações.
        </P>
      </InterUIAlert>
    </>
  );
};

export default Simulation;
