import { InterUIIcon, InterUIInputGroup, InterUITag } from '@interco/inter-ui-react-lib';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import FontSizeREM from '../../enums/fontSizesRem';
import SelectableFundsActions from '../../store/selectableFunds/actions';
import { ThemeInter } from '../../styles';
import { Flex, Span } from '../../styles/commons';
import SelectableFundsSortter from '../SelectableFundsSortter/SelectableFundsSortter';

interface ISelectableFundsManagerProps {
  onSearching: (searchingText: string) => void;
}

const SelectableFundsManager: React.FC<ISelectableFundsManagerProps> = ({ onSearching }) => {
  const theme = useTheme() as ThemeInter;

  const [isSorting, setIsSorting] = useState(false);
  const sortter = useSelector(SelectableFundsActions.getSortter);

  const [isSearching, setIsSearching] = useState(false);
  const [searchingTerm, setSearchingTerm] = useState('');

  const toggleSortingOn = () => {
    setIsSorting(true);
  };

  const toggleSortingOff = () => {
    setIsSorting(false);
  };

  const searchTag = {
    label: 'Buscar',
    iconName: 'search',
  };

  const sortTag = {
    label: 'Ordenar',
    iconName: 'arrow-chevron-down',
  };

  const resetSearchField = useCallback(() => {
    setSearchingTerm('');
    onSearching('');
    // Stryker disable next-line all
  }, [onSearching]);

  useEffect(() => {
    if (!isSearching) {
      resetSearchField();
    }
    // Stryker disable next-line all
  }, [isSearching, resetSearchField]);

  return (
    <>
      <InterUITag
        variant="action-button"
        onClick={() => setIsSearching(!isSearching)}
        backgroundColor={isSearching ? theme.colors.grayscale.A500 : theme.colors.white}
        color={isSearching ? theme.colors.white : theme.colors.grayscale.A500}
        margin="0 8px 0 0"
      >
        <Flex flexDirection="row" alignItems="center">
          <Span fontSize={FontSizeREM.PX12} margin="0 8px 0 0">
            {searchTag.label}
          </Span>
          <InterUIIcon
            name={searchTag.iconName}
            size="16px"
            color={isSearching ? theme.colors.white : theme.colors.grayscale.A500}
          />
        </Flex>
      </InterUITag>
      <InterUITag
        variant="action-button"
        onClick={toggleSortingOn}
        backgroundColor={sortter ? theme.colors.grayscale.A500 : theme.colors.white}
        color={sortter ? theme.colors.white : theme.colors.grayscale.A500}
      >
        <Flex flexDirection="row" alignItems="center">
          <Span fontSize={FontSizeREM.PX12} margin="0 8px 0 0">
            {sortTag.label}
          </Span>
          <InterUIIcon
            name={sortTag.iconName}
            size="16px"
            color={sortter ? theme.colors.white : theme.colors.grayscale.A500}
          />
        </Flex>
      </InterUITag>
      {isSearching && (
        <InterUIInputGroup margin="24px 0">
          <input
            value={searchingTerm}
            type="search"
            name="search"
            placeholder="Pesquisar"
            autoComplete="off"
            onChange={(e) => {
              onSearching(e.currentTarget.value);
              setSearchingTerm(e.currentTarget.value);
            }}
          />

          <InterUIIcon
            style={{ position: 'absolute', top: '10px', right: '16px' }}
            data-testid="deleteSearchValue"
            onClick={resetSearchField}
            name="exit"
            size="24px"
            color={theme.colors.primary.A500}
          />
        </InterUIInputGroup>
      )}
      <SelectableFundsSortter toggle={isSorting} toggleOff={toggleSortingOff} marginBottom="16px" />
    </>
  );
};

export default SelectableFundsManager;
