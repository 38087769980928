/* eslint-disable @typescript-eslint/no-explicit-any */
import { History } from 'history';
import InterWebviewBridge, {
  interWbAuth,
  interWbNavigate,
  interWbSession,
  IWbISafeResponse,
  IWbUserInfo,
  WbEnvironments,
} from 'inter-webview-bridge';
import { PageRoutes } from '../enums/pageRoutes';
import {
  logISafeRequest,
  logISafeResponse,
  logUserInfoResponse,
} from '../loggers/bridgeServiceLogger';
import AppInfoMockService from './appInfoMockService';
import ISafeTokenMockService from './iSafeTokenMockService';
import { MockService } from './mockService';
import { MonitoringService } from './monitoringService';
import UserInfoMockService from './userInfoMockService';

const { REACT_APP_INTER_ENV } = process.env;
const IS_NOT_RUNNING_PROD =
  REACT_APP_INTER_ENV && REACT_APP_INTER_ENV.toLowerCase() !== 'production';

export interface IRequestISafe {
  category: string;
  checkType: string;
  feature: string;
  value: number;
}

export interface IWbAppInfo {
  nomeAparelho?: string;
  nomeTipo?: string;
  tema?: string;
  versao?: string;
  deviceName?: string;
  version?: string;
  theme?: string;
  deviceType?: string;
}

class BridgeService {
  private static interWb = InterWebviewBridge.getInstance();

  private static handleBackButton: (() => void) | undefined;

  static getEnvironment(): WbEnvironments {
    return this.interWb.getEnvironment();
  }

  static isBrowser(): boolean {
    return this.interWb.getEnvironment() === WbEnvironments.BROWSER;
  }

  static enableRemoteBridgeDebugLog(): void {
    this.interWb.setDebug(true);
    (this.interWb as any).info = (action: string, evitId: string, message: string) => {
      // eslint-disable-next-line no-console
      console?.info(`InterWebviewBridge - ${action}(${evitId}): ${message}`);

      MonitoringService.log(
        'BridgeService.enableRemoteBridgeDebugLog',
        `InterWebviewBridge - ${action}(${evitId}): ${message}`,
      );
    };
  }

  static async getUserInfo(): Promise<IWbUserInfo> {
    let userInfo;

    if (BridgeService.isBrowser() && IS_NOT_RUNNING_PROD) {
      userInfo = await UserInfoMockService.requestUserInfo(MockService.account);
    } else {
      userInfo = await interWbSession.getUserInfo();
    }
    logUserInfoResponse(userInfo);
    return userInfo;
  }

  static async getAppInfo(): Promise<IWbAppInfo> {
    let appInfo;

    if (BridgeService.isBrowser() && IS_NOT_RUNNING_PROD) {
      appInfo = await AppInfoMockService.requestAppInfo();
    } else {
      appInfo = await interWbSession.getAppInfo();
    }
    return appInfo;
  }

  static async getISafeToken(requestData: IRequestISafe): Promise<IWbISafeResponse> {
    logISafeRequest(requestData);

    let iSafeToken;
    if (BridgeService.isBrowser() && IS_NOT_RUNNING_PROD) {
      iSafeToken = await ISafeTokenMockService.requestISafe(
        requestData.category,
        requestData.checkType,
        requestData.feature,
        requestData.value,
      );
    } else {
      iSafeToken = await interWbAuth.requestISafe(
        requestData.category,
        requestData.checkType,
        requestData.feature,
        requestData.value,
      );
    }

    logISafeResponse(iSafeToken);

    return iSafeToken;
  }

  static async backToNative(): Promise<void> {
    await interWbNavigate.requestGoBack();
  }

  static async openDeepLink(url: string): Promise<void> {
    await interWbNavigate.openDeepLink(url);
  }

  static async goToAppHomeOrBackToNative(
    history: History,
    routeIfBrowser: PageRoutes,
  ): Promise<void> {
    if (this.isBrowser()) {
      history.push(routeIfBrowser);
      return;
    }

    if (this.interWb.getEnvironment() === WbEnvironments.ANDROID) {
      try {
        await this.interWb.execute({ action: 'navigateToHome' });
      } catch (e: any) {
        MonitoringService.noticeError(e, {
          errorCodeRef: 'BridgeService.goToAppHomeOrBackToNative.navigateToHome',
        });
        await BridgeService.backToNative();
      }
    }

    if (this.interWb.getEnvironment() === WbEnvironments.IOS) {
      try {
        await this.interWb.execute({ action: 'navigateHome' });
      } catch (e: any) {
        MonitoringService.noticeError(e, {
          errorCodeRef: 'BridgeService.goToAppHomeOrBackToNative.navigateHome',
        });
        await BridgeService.backToNative();
      }
    }
  }

  static requestAnalytics(
    errorCodeRef: string,
    name: string,
    params?: Record<string, string>,
  ): void {
    if (!this.isBrowser()) {
      interWbSession.requestAnalytics(name, params).catch((e) => {
        MonitoringService.noticeError(e, {
          errorCodeRef,
          requestAnalyticsName: name,
          requestAnalyticsParams: params ? JSON.stringify(params) : 'No params',
        });
      });
    }
  }

  static addBackButtonEvent(callback: () => void): void {
    if (this.handleBackButton) {
      this.interWb.removeBackListener(this.handleBackButton);
    }

    this.handleBackButton = callback;
    // eslint-disable-next-line no-void
    void this.interWb.addBackListener(this.handleBackButton);
  }
}

export default BridgeService;
