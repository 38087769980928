import { AnyAction, Reducer } from 'redux';
import { TermsAndConditionsPageStates, TermsAndConditionsTypes } from './types';

const INITIAL_STATE: TermsAndConditionsPageStates = {
  isTermsAndConditionsAccepted: false,
};

const reducer: Reducer<TermsAndConditionsPageStates> = (
  state = INITIAL_STATE,
  action: AnyAction,
) => {
  switch (action.type) {
    case TermsAndConditionsTypes.SET_TERMS_CONDITIONS_ACCEPTED: {
      return { ...state, isTermsAndConditionsAccepted: action.payload };
    }
    case TermsAndConditionsTypes.RESET: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
