import AnalyticsBaseEventName from '../enums/analyticsBaseEventNames';
import BridgeService from '../services/bridgeService';

class ErrorFallbackAnalyticsService {
  private static className = 'ErrorFallbackAnalyticsService';

  static clickInvestmentDeepLink(): void {
    BridgeService.requestAnalytics(
      `${this.className}.clickInvestmentDeepLink`,
      `${AnalyticsBaseEventName.PRIVATE_PENSION}Erro_T_ConfiguracoesInvestimentos`,
    );
  }
}

export default ErrorFallbackAnalyticsService;
