import { InterUIButton, InterUIContainer } from '@interco/inter-ui-react-lib';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { InfoInitialMonthlyContribution } from '../../components';
import PageTitles from '../../enums/pageTitles';
import ErrorActions from '../../store/error/actions';
import { LoadingActions } from '../../store/loading/actions';
import NavbarActions from '../../store/navbar/actions';
import RetirementPageActions from '../../store/retirement/actions';
import { ThemeInter } from '../../styles';
import { H1, PBlack, PSmallGray, SeparatorDashed } from '../../styles/commons';
import { ContributionStrategyOptions, RetirementForm } from './components';

const RetirementPage: React.FC = () => {
  const theme = useTheme() as ThemeInter;
  const choosedContributionStrategy = useSelector(RetirementPageActions.getContributionStrategy);
  const initialContribution = useSelector(RetirementPageActions.getInitialContribution);
  const monthlyContribution = useSelector(RetirementPageActions.getMonthlyContribution);
  const loading = useSelector(LoadingActions.get);
  const dispatch = useDispatch();
  const [disabledButton, setDisabledButton] = useState<boolean>(true);
  const formId = 'retirement-form';

  useEffect(() => {
    dispatch(NavbarActions.setTitle(PageTitles.RETIREMENT));
    dispatch(ErrorActions.resetState());
  }, [dispatch]);

  const stickyFooter: React.ReactElement = (
    <InterUIButton
      type="submit"
      data-testid="retirement-form-btn"
      form={formId}
      disabled={disabledButton}
      loading={loading}
    >
      Mostrar plano indicado
    </InterUIButton>
  );

  return (
    <InterUIContainer stickyFooter={stickyFooter}>
      <H1 marginBottom="8px">Seu jeito de investir</H1>
      <PSmallGray marginBottom="24px">
        Escolha como você quer investir no seu plano de aposentadoria na previdência privada.
      </PSmallGray>
      <ContributionStrategyOptions />
      <SeparatorDashed marginTop="22px" marginBottom="24px" color={theme.colors.grayscale.A200} />
      <PBlack disabled={choosedContributionStrategy === ''}>Defina os valores</PBlack>
      <PSmallGray marginBottom="16px" disabled={choosedContributionStrategy === ''}>
        Sabendo o quanto você quer investir, indicaremos o plano que mais faz sentido.
      </PSmallGray>
      <RetirementForm setDisabled={setDisabledButton} />
      <InfoInitialMonthlyContribution
        strategy={choosedContributionStrategy}
        initialContribution={initialContribution}
        monthlyContribution={monthlyContribution}
      />
    </InterUIContainer>
  );
};

export default RetirementPage;
