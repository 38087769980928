import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { InvestmentFundCard, RiskScale } from '..';
import { ProposalDetailActions } from '../../store/proposal/actions';
import { ProposalTypes } from '../../store/proposal/types';
import SelectableFundsActions from '../../store/selectableFunds/actions';
import { ThemeInter } from '../../styles';
import { Div, SeparatorDashed } from '../../styles/commons';
import { defaultSorting, sortByProfitability, sortByRiskLevel } from '../../utils/fundsSortsUtils';
import { formatToBRLCurrency } from '../../utils/numberFormatUtils';
import CardFooter from '../InvestmentFundCard/CardFooter/CardFooter';
import { InvestmentFund } from '../InvestmentFundCard/InvestmentFundCard';
import SelectableFundsManager from '../SelectableFundsManager/SelectableFundsManager';
import VirtualizedList from '../VirtualizedList/VirtualizedList';

interface ISelectableFundsList {
  onSelect: (selectedFund: InvestmentFund) => void;
  selected: InvestmentFund;
  funds: Array<InvestmentFund>;
}

const SelectableFundsList: React.FC<ISelectableFundsList> = ({
  onSelect,
  selected,
  funds,
  children,
}) => {
  const sortter = useSelector(SelectableFundsActions.getSortter);
  const theme = useTheme() as ThemeInter;
  const type = useSelector(ProposalDetailActions.getType);
  const [searchingTerm, setSearchingTerm] = useState('');

  const filtered = useMemo(
    () =>
      funds.filter((fund: InvestmentFund) =>
        fund.name.toLowerCase().includes(searchingTerm.toLowerCase()),
      ),
    [funds, searchingTerm],
  );

  const sorted = useMemo(() => {
    switch (sortter) {
      case 'Padrão':
        return filtered.sort((fund1, fund2) => defaultSorting(fund1, fund2));
      case 'Grau de risco':
        return filtered.sort((fund1, fund2) => sortByRiskLevel(fund1, fund2));
      default:
        return filtered.sort((fund1, fund2) => sortByProfitability(fund1, fund2));
    }
  }, [filtered, sortter]);

  return (
    <Div>
      <VirtualizedList
        list={sorted}
        element={(fund: InvestmentFund) => (
          <InvestmentFundCard
            key={`fund-${fund.code}`}
            dataTestId={`fund-${fund.code}`}
            investmentFund={fund}
            marginBottom="16px"
            selected={fund.code === selected.code}
            footer={
              type === ProposalTypes.BY_FUND && (
                <CardFooter
                  label="Aplicação Mínima:"
                  value={formatToBRLCurrency(fund.minimumMonthlyContribution)}
                />
              )
            }
            onSelect={onSelect}
          />
        )}
      >
        {children}
        <SelectableFundsManager onSearching={(term) => setSearchingTerm(term)} />
        <SeparatorDashed marginTop="24px" marginBottom="24px" color={theme.colors.grayscale.A200} />
        <RiskScale />
      </VirtualizedList>
    </Div>
  );
};

export default SelectableFundsList;
