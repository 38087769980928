import { AnyAction } from 'redux';
import { action } from 'typesafe-actions';
import { RootState } from '..';
import { IncomeTaxPageTypes } from './types';

const getContributionStrategy = (state: RootState): string => state.incomeTax.strategy;

const setContributionStrategy = (strategy: string): AnyAction =>
  action(IncomeTaxPageTypes.SET_CONTRIBUTION_STRATEGY, strategy);

const getInitialContribution = (state: RootState): number => state.incomeTax.initialContribution;

const setInitialContribution = (initialContribution: number): AnyAction =>
  action(IncomeTaxPageTypes.SET_INITIAL_CONTRIBUTION, initialContribution);

const getMonthlyContribution = (state: RootState): number => state.incomeTax.monthlyContribution;

const setMonthlyContribution = (monthlyContribution: number): AnyAction =>
  action(IncomeTaxPageTypes.SET_MONTHLY_CONTRIBUTION, monthlyContribution);

const getInitialMinimum = (state: RootState): number => state.incomeTax.initialMinimum;

const setInitialMinimum = (initialMinimum: number): AnyAction =>
  action(IncomeTaxPageTypes.SET_INITIAL_MINIMUM, initialMinimum);

const getMonthlyMinimum = (state: RootState): number => state.incomeTax.monthlyMinimum;

const setMonthlyMinimum = (monthlyMinimum: number): AnyAction =>
  action(IncomeTaxPageTypes.SET_MONTHLY_MINIMUM, monthlyMinimum);

const getAnnualContribution = (state: RootState): number => state.incomeTax.annualContribution;

const setAnnualContribution = (annualContribution: number): AnyAction =>
  action(IncomeTaxPageTypes.SET_ANNUAL_CONTRIBUTION, annualContribution);

const resetState = (): AnyAction => action(IncomeTaxPageTypes.RESET);

const IncomeTaxPageActions = {
  getContributionStrategy,
  setContributionStrategy,
  getInitialContribution,
  setInitialContribution,
  getInitialMinimum,
  setInitialMinimum,
  getMonthlyMinimum,
  setMonthlyMinimum,
  getMonthlyContribution,
  setMonthlyContribution,
  getAnnualContribution,
  setAnnualContribution,
  resetState,
};

export default IncomeTaxPageActions;
