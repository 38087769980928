import { InterUIRadio } from '@interco/inter-ui-react-lib';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FontSizeREM from '../../../../enums/fontSizesRem';
import { ProposalDetailActions } from '../../../../store/proposal/actions';
import Colors from '../../../../styles/colors';
import { P, PSmallBold, PSmallGray } from '../../../../styles/commons';
import { formatToBRLCurrency } from '../../../../utils/numberFormatUtils';

const Insurance: React.FC = () => {
  const dispatch = useDispatch();
  const defaultInsurance = useSelector(ProposalDetailActions.getInsuranceCoverage);
  const currentHasInsuranceCoverage = useSelector(ProposalDetailActions.getHasInsuranceCoverage);

  return (
    <>
      <P lineHeight={FontSizeREM.PX20} marginBottom="9px" fontFamily="Sora">
        Sua previdência privada protegida
      </P>
      <P fontSize={FontSizeREM.PX14} color={Colors.shuttleGray} fontWeight={400} marginBottom="9px">
        Com a proteção, você pode garantir que quem depende de você fique amparado no caso da sua
        falta.
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        color={Colors.shuttleGray}
        fontWeight={400}
        marginBottom="22px"
      >
        O valor recebido é de {formatToBRLCurrency(defaultInsurance.coverageValue)} e a carência é
        de <strong>{defaultInsurance.gracePeriod} meses</strong>.
      </P>
      <InterUIRadio
        variant="choose-item"
        name="insurance"
        onChange={() => dispatch(ProposalDetailActions.setHasInsuranceCoverage(true))}
        checked={currentHasInsuranceCoverage === true}
        data-testid="choose-insurance-coverage-btn"
      >
        <PSmallBold marginBottom="4px">Com proteção</PSmallBold>
        <PSmallGray
          maxWidth="183px"
          fontSize={FontSizeREM.PX12}
          lineHeight={FontSizeREM.PX15}
          marginBottom="0px"
        >
          {formatToBRLCurrency(defaultInsurance.monthlyContributionValue)} por mês
        </PSmallGray>
      </InterUIRadio>
      <InterUIRadio
        variant="choose-item"
        name="insurance"
        onChange={() => dispatch(ProposalDetailActions.setHasInsuranceCoverage(false))}
        checked={currentHasInsuranceCoverage === false}
        data-testid="choose-no-insurance-coverage-btn"
      >
        <PSmallBold marginBottom="4px">Sem proteção</PSmallBold>
        <PSmallGray
          maxWidth="183px"
          fontSize={FontSizeREM.PX12}
          lineHeight={FontSizeREM.PX15}
          marginBottom="0px"
        >
          R$ 0,00
        </PSmallGray>
      </InterUIRadio>
    </>
  );
};

export default Insurance;
