import { AnyAction } from 'redux';
import { action } from 'typesafe-actions';
import { RootState } from '..';
import { IncomeExpensePageState, IncomeExpensePageTypes } from './types';

const get = (state: RootState): IncomeExpensePageState => state.incomeExpense;

const set = (incomeExpense: IncomeExpensePageState): AnyAction =>
  action(IncomeExpensePageTypes.SET_INCOME_EXPENSES_STATE, incomeExpense);

const resetState = (): AnyAction => action(IncomeExpensePageTypes.RESET);

export const IncomeExpenseActions = {
  get,
  set,
  resetState,
};
