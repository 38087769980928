import { LocalStorageKeys } from '../enums/localStorageKeys';
import LocalStorageService from '../services/localStorageService';
import { PrivatePensionPlan, PrivatePensionPlanStatus } from '../store/privatePensionPlan/types';

export const getFailedCertificateFromLocalStorage = (account: string): Array<string> => {
  const data = LocalStorageService.getItem<PrivatePensionPlan[]>(
    LocalStorageKeys.PRIVATE_PENSION_PLANS,
    account,
  )?.data;

  return data
    ? data
        ?.filter((plan: PrivatePensionPlan) => plan.status === PrivatePensionPlanStatus.ERROR)
        ?.map((plan: PrivatePensionPlan) => plan.certificateNumber)
    : [];
};
