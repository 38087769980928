import { InterUIBottomSheet, InterUIButton, InterUIContainer } from '@interco/inter-ui-react-lib';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import PortabilityAnalyticsService from '../../analytics/portabilityAnalyticsService';
import { EnvVariableKeys } from '../../enums/evironmentVariableKeys';
import FontSizeREM from '../../enums/fontSizesRem';
import { PageRoutes } from '../../enums/pageRoutes';
import PageTitles from '../../enums/pageTitles';
import { EnvVariableService } from '../../services/environmentVariableService';
import GlobalActions from '../../store/global/actions';
import NavbarActions from '../../store/navbar/actions';
import PortabilityActions from '../../store/portability/actions';
import { ThemeInter } from '../../styles';
import { Div, Flex, P } from '../../styles/commons';
import { convertSecondsToMillis } from '../../utils/dateTimeUtils';
import InstitutionIcons from '../PortabilityOnboardingBelvo/components/InstitutionsIcons/InstitutionIcons';
import { useDisplayedMessage } from './hooks/useDisplayedMessage';

interface IPortabilityQueryState {
  linkId: string;
}

const PortabilityQueryPensionPlanPage: React.FC = () => {
  const PORTABILITY_REQUEST_TIMEOUT = EnvVariableService.getVariable(
    EnvVariableKeys.PORTABILITY_REQUEST_TIMEOUT,
    '10',
  );
  const ACCEPTABLE_WAITING_TIME_TO_SHOW_ALERT = EnvVariableService.getVariable(
    EnvVariableKeys.ACCEPTABLE_WAITING_TIME_TO_SHOW_ALERT,
    '60',
  );
  const MAX_ACCEPTABLE_WAITING_TIME_TO_SHOW_ALERT = EnvVariableService.getVariable(
    EnvVariableKeys.MAX_ACCEPTABLE_WAITING_TIME_TO_SHOW_ALERT,
    '300',
  );

  const [hasMaximumWaitingTimeReached, setHasMaximumWaitingTimeReached] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme() as ThemeInter;
  const { state: portabilityQueryState } = useLocation<IPortabilityQueryState>();
  const displayedMessage = useDisplayedMessage();
  const selectedInstitution = useSelector(PortabilityActions.getFinancialInstitution);
  const requestCount = useSelector(PortabilityActions.getRequestCount);
  const [bottomSheet, setBottomSheet] = useState<boolean>(false);
  const { portabilidadeId } = useSelector(GlobalActions.getQueryParams);
  const portabilityId = useSelector(PortabilityActions.getPortabilityId);

  const text = {
    title: 'Acessando suas informações',
    bottomSheetTitle: 'Está demorando mais que o esperado',
    bottomSheetMessage:
      'Não se preocupe, seus dados ainda estão sendo coletados. Quando o processo finalizar, você será notificado para concluir sua solicitação de portabilidade.',
    bottomSheetPrimeryLbl: 'Continuar aqui',
    bottomSheetSecondaryLbl: 'Ir para a Home',
    footer:
      'Esse processo dura, em média, 45 segundos. Fique tranquilo, caso dure mais que isso, notificaremos quando a coleta estiver pronta.',
  };

  const requestExternalPensionPlanTimeout = useCallback(
    (time: number) =>
      setTimeout(() => {
        dispatch(
          PortabilityActions.requestPensionPlans({
            history,
            pathname: PageRoutes.PORTABILITY_EXTERNAL_PENSION_PLAN,
          }),
        );
      }, time),
    [dispatch, history],
  );

  const setShowBottomSheet = (time: number, callback?: () => void) =>
    setTimeout(() => {
      if (callback) {
        callback();
      }
      setBottomSheet(true);
    }, time);

  const goToHome = () => {
    setBottomSheet(false);
    history.push(PageRoutes.HOME);
  };

  const onHide = () => {
    if (hasMaximumWaitingTimeReached) {
      PortabilityAnalyticsService.delayBottomSheetAction('BottomSheet hide: go to home');
      goToHome();
    } else {
      PortabilityAnalyticsService.delayBottomSheetAction('BottomSheet hide');
      setBottomSheet(false);
    }
  };

  const bottomSheetContinueClick = () => {
    PortabilityAnalyticsService.delayBottomSheetAction(text.bottomSheetPrimeryLbl);
    setBottomSheet(false);
  };

  const bottomSheetGoToHomeClick = () => {
    PortabilityAnalyticsService.delayBottomSheetAction(text.bottomSheetSecondaryLbl);
    goToHome();
  };

  // Call to portabilityId
  useEffect(
    () => {
      if (portabilityQueryState && portabilityQueryState.linkId) {
        dispatch(PortabilityActions.setLinkId(portabilityQueryState.linkId));
        dispatch(
          PortabilityActions.requestDataCollection({
            history,
            pathname: '',
          }),
        );
      }
    }, // Stryker disable next-line all
    [dispatch, history, portabilityQueryState],
  );

  // First call to external pension plan
  useEffect(
    () => {
      if (portabilityId) {
        dispatch(NavbarActions.setGoIndex(-3));
        dispatch(
          PortabilityActions.requestPensionPlans({
            history,
            pathname: PageRoutes.PORTABILITY_EXTERNAL_PENSION_PLAN,
          }),
        );
      }
    }, // Stryker disable next-line all
    [dispatch, history, portabilityId],
  );

  const clearTimeoutOnUnmount = (timeout: NodeJS.Timeout | null) => {
    if (timeout) {
      clearTimeout(timeout);
    }
  };

  // Set timeout to call external pension plans.
  useEffect(
    () => {
      let requestTimeout: NodeJS.Timeout | null = null;
      if (
        portabilityId &&
        !requestTimeout &&
        PORTABILITY_REQUEST_TIMEOUT &&
        requestCount !== 0 &&
        !bottomSheet
      ) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        requestTimeout = requestExternalPensionPlanTimeout(
          convertSecondsToMillis(PORTABILITY_REQUEST_TIMEOUT),
        );
      }
      return () => clearTimeoutOnUnmount(requestTimeout);
    }, // Stryker disable next-line all
    [
      PORTABILITY_REQUEST_TIMEOUT,
      requestCount,
      requestExternalPensionPlanTimeout,
      bottomSheet,
      portabilityId,
    ],
  );

  // Set timeout to show botton sheet with ACCEPTABLE_WAITING_TIME_TO_SHOW_ALERT.
  useEffect(
    () => {
      let showBottomSheetTimeout: NodeJS.Timeout | null = null;
      if (ACCEPTABLE_WAITING_TIME_TO_SHOW_ALERT) {
        showBottomSheetTimeout = setShowBottomSheet(
          convertSecondsToMillis(ACCEPTABLE_WAITING_TIME_TO_SHOW_ALERT),
        );
      }
      return () => clearTimeoutOnUnmount(showBottomSheetTimeout);
    }, // Stryker disable next-line all
    [ACCEPTABLE_WAITING_TIME_TO_SHOW_ALERT],
  );

  // Set timeout to show botton sheet with MAX_ACCEPTABLE_WAITING_TIME_TO_SHOW_ALERT.
  useEffect(
    () => {
      let showBottomSheetTimeout: NodeJS.Timeout | null = null;
      if (MAX_ACCEPTABLE_WAITING_TIME_TO_SHOW_ALERT) {
        showBottomSheetTimeout = setShowBottomSheet(
          convertSecondsToMillis(MAX_ACCEPTABLE_WAITING_TIME_TO_SHOW_ALERT),
          () => setHasMaximumWaitingTimeReached(true),
        );
      }
      return () => clearTimeoutOnUnmount(showBottomSheetTimeout);
    }, // Stryker disable next-line all
    [MAX_ACCEPTABLE_WAITING_TIME_TO_SHOW_ALERT],
  );

  useEffect(
    () => {
      if (portabilidadeId) {
        dispatch(PortabilityActions.setPortabilityId(portabilidadeId));
      }
    }, // Stryker disable next-line all
    [dispatch, portabilidadeId],
  );

  useEffect(
    () => {
      dispatch(NavbarActions.setTitle(PageTitles.PORTABILITY_QUERY_PENSION_PLAN));
      dispatch(NavbarActions.setGoIndex(-3));
    }, // Stryker disable next-line all
    [dispatch],
  );

  return (
    <InterUIContainer>
      <Flex
        height="calc(100vh - 152px)"
        flexDirection="column"
        justifyContent="center"
        alignItems="cente"
      >
        <InstitutionIcons isLoading margin="0 12px 20px 12px" institution={selectedInstitution} />
        <P textAlign="center" fontFamily="Sora" lineHeight={FontSizeREM.PX20}>
          {text.title}
        </P>

        <Div height="37px">
          <P
            textAlign="center"
            fontWeight={400}
            fontSize={FontSizeREM.PX14}
            lineHeight={FontSizeREM.PX17}
          >
            {displayedMessage}
          </P>
        </Div>
      </Flex>

      <Div position="fixed" left="0px" margin="0 24px" bottom="45px" textAlign="center">
        <P fontWeight={400} fontSize={FontSizeREM.PX12} lineHeight={FontSizeREM.PX14}>
          {text.footer}
        </P>
      </Div>

      <InterUIBottomSheet toggle={bottomSheet} onHide={() => onHide()}>
        <P
          fontFamily="Sora"
          fontWeight={600}
          fontSize={FontSizeREM.PX14}
          lineHeight={FontSizeREM.PX20}
        >
          {text.bottomSheetTitle}
        </P>
        <P
          fontWeight={400}
          fontSize={FontSizeREM.PX14}
          lineHeight={FontSizeREM.PX14}
          color={theme.colors.grayscale.A400}
        >
          {text.bottomSheetMessage}
        </P>
        {!hasMaximumWaitingTimeReached && (
          <InterUIButton type="button" onClick={bottomSheetContinueClick} margin="32px 0px 0px 0px">
            {text.bottomSheetPrimeryLbl}
          </InterUIButton>
        )}
        <InterUIButton
          type="button"
          onClick={bottomSheetGoToHomeClick}
          margin="16px 0px 0px 0px"
          variant={hasMaximumWaitingTimeReached ? 'primary' : 'secondary'}
        >
          {text.bottomSheetSecondaryLbl}
        </InterUIButton>
      </InterUIBottomSheet>
    </InterUIContainer>
  );
};

export default PortabilityQueryPensionPlanPage;
