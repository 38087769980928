import AnalyticsBaseEventName from '../enums/analyticsBaseEventNames';
import BridgeService from '../services/bridgeService';
import { ProposalTypes } from '../store/proposal/types';
import { handlerProposalType } from '../utils/analyticsUtils';
import { IProposalDetailsState } from './types';

class RevisionAnalyticsService {
  private static className = 'RevisionAnalyticsService';

  static contractPreviewButton(
    {
      hasInsurance,
      content_data,
      content_fundo,
      content_mod,
      content_tempo,
      content_trib,
      price_inicial,
      price_mensal,
    }: IProposalDetailsState,
    proposalType: ProposalTypes,
  ): void {
    BridgeService.requestAnalytics(
      `${this.className}.contractPreviewButton`,
      `${AnalyticsBaseEventName.REVISION}T_PreviaDoContra`,
      {
        content_data: price_mensal === '0,00' ? '0' : content_data,
        content_fundo,
        content_mod,
        content_tempo,
        content_trib,
        price_inicial,
        price_mensal,
        currency: 'BRL',
        content_protecao: hasInsurance ? 'Com proteção' : 'Sem proteção',
        content_id: handlerProposalType(proposalType),
      },
    );
  }

  static hireProposalButton(
    {
      hasInsurance,
      content_data,
      content_fundo,
      content_mod,
      content_tempo,
      content_trib,
      price_inicial,
      price_mensal,
    }: IProposalDetailsState,
    proposalType: ProposalTypes,
  ): void {
    BridgeService.requestAnalytics(
      `${this.className}.hireProposalButton`,
      `${AnalyticsBaseEventName.REVISION}T_Contratar`,
      {
        content_data: price_mensal === '0,00' ? '0' : content_data,
        content_fundo,
        content_mod,
        content_tempo,
        content_trib,
        price_inicial,
        price_mensal,
        currency: 'BRL',
        content_protecao: hasInsurance ? 'Com proteção' : 'Sem proteção',
        content_id: handlerProposalType(proposalType),
      },
    );
  }

  static downloadButtonClick(proposalType: ProposalTypes): void {
    BridgeService.requestAnalytics(
      `${this.className}.downloadButtonClick`,
      `${AnalyticsBaseEventName.REVISION}ContrPrev_T_Download`,
      {
        content_id: handlerProposalType(proposalType),
      },
    );
  }
}

export default RevisionAnalyticsService;
