import React from 'react';
import { ExternalPensionPlan } from '../../../../store/portability/types';
import ExternalPensionPlanCard from '../ExternalPensionPlanCard/ExternalPensionPlanCard';

interface IExternalPensionPlanList {
  list: ExternalPensionPlan[];
  selected?: ExternalPensionPlan | null;
  onSelect: (selectedExternalPensionPlan: ExternalPensionPlan) => void;
}

const ExternalPensionPlanList: React.FC<IExternalPensionPlanList> = ({
  list,
  selected,
  onSelect,
}) => (
  <>
    {list.map((externalPensionPlan: ExternalPensionPlan) => {
      const key = `key-${externalPensionPlan.id}`;
      return (
        <ExternalPensionPlanCard
          key={key}
          externalPensionPlan={externalPensionPlan}
          selected={externalPensionPlan.id === selected?.id}
          onSelect={onSelect}
        />
      );
    })}
  </>
);
export default ExternalPensionPlanList;
