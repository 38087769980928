import { AnyAction, Reducer } from 'redux';
import { NavbarState, NavbarTypes } from './types';

const INITIAL_STATE: NavbarState = {
  title: 'Aposentadoria',
  direction: 'forward',
  canGoBack: true,
  goIndex: 0,
};

const reducer: Reducer<NavbarState> = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case NavbarTypes.SET_TITLE: {
      return { ...state, title: action.payload };
    }
    case NavbarTypes.SET_DIRECTION: {
      return { ...state, direction: action.payload };
    }
    case NavbarTypes.SET_CAN_GO_BACK: {
      return { ...state, canGoBack: action.payload };
    }
    case NavbarTypes.SET_GO_INDEX: {
      return { ...state, goIndex: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
