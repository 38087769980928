export enum ContributionValuesTypes {
  SET_CONTRIBUTION_VALUES = `@contribution/SET_CONTRIBUTION_VALUES`,
  RESET = `@contribution/RESET`,
}

export interface ContributionValuesState {
  initialContribution: number;
  monthlyContribution: number;
  annualContribution: number;
  isChangeRequired: boolean;
}
