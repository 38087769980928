import { AnyAction, Reducer } from 'redux';
import { InvestmentFund } from '../../components/InvestmentFundCard/InvestmentFundCard';
import { HireProposalStatus, PrivatePensions, TaxTypes } from '../../enums/privatePensions';
import { HireProposalState, HireProposalTypes } from './types';

const INSURANCE_INITIAL_STATE = {
  coverageValue: 0,
  monthlyContributionValue: 0,
  gracePeriod: 0,
};

const INVESTMENT_FUND_INTIIAL_STATE: InvestmentFund = {
  minimumInitialContribution: 0,
  minimumMonthlyContribution: 0,
  minimumAdditionalContribution: 0,
  monthlyProfitability: 0,
  yearlyProfitability: 0,
  lastTwelveMonthsProfitability: 0,
  maximumAdministrationFee: 0,
  minimumAdministrationFee: 0,
  interestRate: 0,
  name: '',
  code: '',
  riskLevel: 0,
  interFund: false,
  qualified: false,
};
const PENDING_HIRE_PROPOSAL_INITIAL_STATE = {
  suggestedProposalId: 0,
  privatePensionType: PrivatePensions.EMPTY,
  taxType: TaxTypes.EMPTY,
  initialContribution: 0,
  monthlyContribution: 0,
  numberMonthsInvesting: 0,
  paymentDay: 0,
  chosenProposalId: 0,
  status: HireProposalStatus.EMPTY,
  firstDebitDate: '',
  investmentFund: INVESTMENT_FUND_INTIIAL_STATE,
  insuranceCoverage: INSURANCE_INITIAL_STATE,
};

const INITIAL_STATE: HireProposalState = {
  suggestedProposalId: 0,
  privatePensionType: PrivatePensions.EMPTY,
  taxType: TaxTypes.EMPTY,
  initialContribution: 0,
  monthlyContribution: 0,
  numberMonthsInvesting: 0,
  paymentDay: 0,
  investmentFundCode: '',
  hasInsuranceCoverage: false,
  chosenProposalId: 0,
  pendingHireProposal: PENDING_HIRE_PROPOSAL_INITIAL_STATE,
};

const reducer: Reducer<HireProposalState> = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case HireProposalTypes.SET_SUGGESTED_PROPOSAL_ID: {
      return { ...state, suggestedProposalId: action.payload };
    }
    case HireProposalTypes.SET_PRIVATE_PENSION_TYPE: {
      return { ...state, privatePensionType: action.payload };
    }
    case HireProposalTypes.SET_TAX_TYPE: {
      return { ...state, taxType: action.payload };
    }
    case HireProposalTypes.SET_INITIAL_CONTRIBUTION: {
      return { ...state, initialContribution: action.payload };
    }
    case HireProposalTypes.SET_MONTHLY_CONTRIBUTION: {
      return { ...state, monthlyContribution: action.payload };
    }
    case HireProposalTypes.SET_NUMBER_MONTHS_INVESTING: {
      return { ...state, numberMonthsInvesting: action.payload };
    }
    case HireProposalTypes.SET_PAYMENT_DAY: {
      return { ...state, paymentDay: action.payload };
    }
    case HireProposalTypes.SET_INVESTMENT_FUND_CODE: {
      return { ...state, investmentFundCode: action.payload };
    }
    case HireProposalTypes.SET_CHOOSEN_PROPOSAL_ID: {
      return { ...state, chosenProposalId: action.payload };
    }
    case HireProposalTypes.SET_HAS_INSURANCE_COVERAGE: {
      return { ...state, hasInsuranceCoverage: action.payload };
    }
    case HireProposalTypes.SET_PENDING_HIRE_PROPOSAL: {
      return { ...state, pendingHireProposal: action.payload };
    }
    case HireProposalTypes.RESET: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
