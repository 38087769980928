/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { InterUIIcon } from '@interco/inter-ui-react-lib';
import React from 'react';
import {
  FieldError,
  RegisterOptions,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetFocus,
  UseFormSetValue,
} from 'react-hook-form';
import Colors from '../../styles/colors';
import { Div, ErrorMessage } from '../../styles/commons';
import { SelectContainer } from './SelectWithHook.styles';

type SelectType = 'number' | 'text';

export interface ISelectWithHookProps {
  name: string;
  nextField?: string;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  readOnly?: boolean;
  dataTestId?: string;
  type: SelectType;
  width?: string;
  margin?: string;
}

type SelectProps = React.SelectHTMLAttributes<HTMLSelectElement> &
  ISelectWithHookProps & {
    register?: UseFormRegister<any>;
    setValue?: UseFormSetValue<any>;
    getValues?: UseFormGetValues<any>;
    setFocus?: UseFormSetFocus<any>;
    errors?: FieldError;
    validation?: RegisterOptions;
    onBlur?: React.FocusEventHandler<any>;
  };

const SelectWithHook: React.FC<SelectProps> = ({
  name,
  nextField,
  placeholder,
  label,
  disabled = false,
  dataTestId,
  errors,
  prefix,
  type = 'text',
  register = (data: any) => {},
  setValue = (data: any) => {},
  getValues = (data: any) => {},
  setFocus,
  validation,
  children,
  width,
  margin,
  onBlur = (data: any) => {},
  ...props
}) => {
  const iconStyle: React.CSSProperties = {
    position: 'absolute',
    top: label ? '29px' : '12px',
    right: '24px',
    zIndex: 1,
    display: 'inline-block',
    pointerEvents: 'none',
  };

  return (
    <SelectContainer width={width} margin={margin}>
      <Div position="relative">
        <label htmlFor={name}>{label}</label>
        <select
          {...register(name, { ...validation, valueAsNumber: type === 'number' })}
          {...props}
          data-testid={dataTestId || name}
          disabled={disabled}
          defaultValue=""
          onChange={(e) => {
            setValue(name, e.target.value, {
              shouldValidate: true,
            });
          }}
          onSelect={(event) => {
            if (event.isTrusted) {
              if (nextField && setFocus) {
                setFocus(nextField);
              }
            }
          }}
          onBlur={onBlur}
        >
          <>
            {placeholder ? (
              <option value="" disabled>
                {placeholder}
              </option>
            ) : (
              ''
            )}
            {children}
          </>
        </select>
        <InterUIIcon
          style={iconStyle}
          name="arrow-chevron-down"
          size="20px"
          color={Colors.interOrange}
        />
      </Div>
      <ErrorMessage>{errors && errors.message ? <span>{errors.message}</span> : ''}</ErrorMessage>
    </SelectContainer>
  );
};

export default SelectWithHook;
