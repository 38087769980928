import { InterUIButton, InterUIContainer } from '@interco/inter-ui-react-lib';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import contractCovered from '../../assets/previewContract/covered/termo_previdencia_privada_inter.pdf';
import contractUncovered from '../../assets/previewContract/uncovered/termo_previdencia_privada_inter.pdf';
import { InterUIPdfReader } from '../../components/InterUIPdfReader/InterUIPdfReader';
import PageTitles from '../../enums/pageTitles';
import ErrorActions from '../../store/error/actions';
import { LoadingActions } from '../../store/loading/actions';
import NavbarActions from '../../store/navbar/actions';
import { PreviewContractActions } from '../../store/previewContract/actions';
import { ProposalDetailActions } from '../../store/proposal/actions';
import TermsAndConditionsActions from '../../store/termAndCondition/actions';
import { Div } from '../../styles/commons';

const PreviewContractPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(LoadingActions.get);
  const contract = useSelector(PreviewContractActions.getContract);

  const currentHasInsuranceCoverage = useSelector(ProposalDetailActions.getHasInsuranceCoverage);
  const currentInsurance = useSelector(ProposalDetailActions.getInsuranceCoverage);
  const currentMonthlyContribution = useSelector(ProposalDetailActions.getMonthlyContribution);

  const isCovered = useMemo(() => {
    if (
      currentMonthlyContribution > 0 &&
      currentInsurance !== undefined &&
      currentHasInsuranceCoverage
    ) {
      return true;
    }
    return false;
  }, [currentMonthlyContribution, currentInsurance, currentHasInsuranceCoverage]);

  const goBack = () => {
    if (loading) {
      return;
    }
    dispatch(TermsAndConditionsActions.setIsTermsAndConditionsAccepted(true));
    history.goBack();
  };

  useEffect(() => {
    dispatch(NavbarActions.setTitle(PageTitles.PREVIEW_CONTRACT));
    dispatch(ErrorActions.resetState());
    dispatch(PreviewContractActions.requestContract());
  }, [dispatch]);

  useEffect(() => {
    if (isCovered) {
      dispatch(PreviewContractActions.setContract(contractCovered));
    } else {
      dispatch(PreviewContractActions.setContract(contractUncovered));
    }
  }, [dispatch, isCovered]);

  const stickyFooter = (
    <InterUIButton
      type="button"
      margin="24px"
      disabled={loading === undefined || loading}
      onClick={() => goBack()}
    >
      Entendi
    </InterUIButton>
  );

  return (
    <InterUIContainer stickyFooter={stickyFooter} margin="0px">
      <Div height="calc(100vh - 145px)">
        <InterUIPdfReader file={contract} />
      </Div>
    </InterUIContainer>
  );
};

export default PreviewContractPage;
