/* eslint-disable react-hooks/exhaustive-deps */
import { GlobalUIInterStyle, InterTheme, InterUIFonts } from '@interco/inter-ui-react-lib';
import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import {
  ErrorFallback,
  ErrorObserver,
  Navbar,
  OnWebviewInit,
  Overlay,
  Snackbar,
} from './components';
import { LocalStorageKeys } from './enums/localStorageKeys';
import Routes from './routes';
import LocalStorageService from './services/localStorageService';
import { MonitoringService } from './services/monitoringService';
import ErrorActions from './store/error/actions';
import UserInfoActions from './store/user/actions';

const { REACT_APP_IS_ADDITIONAL_CONTRIBUTION_AVAILABLE } = process.env;

const IS_ADDITIONAL_CONTRIBUTION_AVAILABLE = REACT_APP_IS_ADDITIONAL_CONTRIBUTION_AVAILABLE
  ? REACT_APP_IS_ADDITIONAL_CONTRIBUTION_AVAILABLE.toLowerCase() === 'true'
  : false;

// Stryker disable all
function App(): JSX.Element {
  InterUIFonts();
  const dispatch = useDispatch();
  const hasError = useSelector(ErrorActions.get);
  const { account } = useSelector(UserInfoActions.get);

  const getLocalStorageItem = () =>
    LocalStorageService.getItem<boolean>(LocalStorageKeys.USER_HAS_PRIVATE_PENSION, account);

  const hasValidLocalStorageItem = () =>
    !LocalStorageService.hasExpired(LocalStorageKeys.USER_HAS_PRIVATE_PENSION, account);

  useEffect(() => {
    if (account) {
      LocalStorageService.clearExpiredItems(account);
    }

    if (IS_ADDITIONAL_CONTRIBUTION_AVAILABLE) {
      const dataFromLocalStorage = getLocalStorageItem();
      if (account) {
        if (!dataFromLocalStorage || !hasValidLocalStorageItem()) {
          dispatch(UserInfoActions.requestHasPrivatePension());
        }
      }
    }
  }, [account]);

  const resetError = () => {
    dispatch(ErrorActions.resetState());
  };

  return (
    <ThemeProvider theme={InterTheme}>
      <GlobalUIInterStyle />
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={resetError}
        resetKeys={[hasError]}
        onError={(error, info) =>
          MonitoringService.noticeError(error, { componentStackTrace: info.componentStack })
        }
      >
        <Overlay />
        <BrowserRouter>
          <Navbar />
          <Routes />
        </BrowserRouter>
        {/**
         * The OnWebviewInit Component must stay just after the
         * Routes component, because there are some dependencies between
         */}
        <OnWebviewInit />
        <Snackbar />
        <ErrorObserver />
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
