import { AnyAction, Reducer } from 'redux';
import { INITIAL_CONTRIBUTION_MINIMUM, MONTHLY_CONTRIBUTION_MINIMUM } from '../../utils/minValues';
import { RetirementPageState, RetirementPageTypes } from './types';

const INITIAL_STATE: RetirementPageState = {
  strategy: '',
  initialContribution: 0,
  initialMinimum: INITIAL_CONTRIBUTION_MINIMUM,
  monthlyContribution: 0,
  monthlyMinimum: MONTHLY_CONTRIBUTION_MINIMUM,
};

const reducer: Reducer<RetirementPageState> = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case RetirementPageTypes.SET_CONTRIBUTION_STRATEGY: {
      return { ...state, strategy: action.payload };
    }
    case RetirementPageTypes.SET_INITIAL_CONTRIBUTION: {
      return { ...state, initialContribution: action.payload };
    }
    case RetirementPageTypes.SET_MONTHLY_CONTRIBUTION: {
      return { ...state, monthlyContribution: action.payload };
    }
    case RetirementPageTypes.SET_INITIAL_MINIMUM: {
      return { ...state, initialMinimum: action.payload };
    }
    case RetirementPageTypes.SET_MONTHLY_MINIMUM: {
      return { ...state, monthlyMinimum: action.payload };
    }
    case RetirementPageTypes.RESET: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
