export enum PortabilityStatusTypes {
  SET_PORTABILITIES = `@portabilityStatus/SET_PORTABILITIES`,
  RESET = `@portabilityStatus/RESET`,
}

export enum Status {
  UNDER_ANALYSIS = 'Em análise',
  FAILED = 'Reprovada',
  APPROVED = 'Aprovada',
  DATA_COLLECTION_COMPLETED = 'Coleta de Dados Concluída',
}

export interface PortabilityStatus {
  readonly id: number;
  readonly name?: string;
  readonly certificateNumber?: string;
  readonly startedAt?: string;
  readonly status: Status;
  readonly finishedAt?: string;
  readonly protocoledAt?: string;
  readonly accumulatedValues?: number;
}

export interface PortabilitiesStatusState {
  readonly portabilitiesStatus: PortabilityStatus[];
}
