/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { InterUIAlert, InterUIButton, InterUIContainer } from '@interco/inter-ui-react-lib';
import React, { useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReceiptAnalyticsService from '../../analytics/receiptAnalyticsService';
import SuccessImg from '../../assets/success/Success.png';
import { InfoInitialMonthlyContribution } from '../../components';
import FontSizeREM from '../../enums/fontSizesRem';
import { LocalStorageKeys } from '../../enums/localStorageKeys';
import { PageRoutes } from '../../enums/pageRoutes';
import PageTitles from '../../enums/pageTitles';
import { TaxTypes } from '../../enums/privatePensions';
import BridgeService from '../../services/bridgeService';
import LocalStorageService from '../../services/localStorageService';
import ErrorActions from '../../store/error/actions';
import { HireProposalActions } from '../../store/hire/actions';
import NavbarActions from '../../store/navbar/actions';
import { ProposalDetailActions } from '../../store/proposal/actions';
import UserInfoActions from '../../store/user/actions';
import Colors from '../../styles/colors';
import { Flex, H1, P, Separator } from '../../styles/commons';
import {
  formatMonthsToYearsAndMonths,
  getMonthsInvestingText,
  getSecondDebitDate,
} from '../../utils/dateTimeUtils';
import { _try } from '../../utils/errorUtils';
import { formatToBRLCurrency, formatToPercent } from '../../utils/numberFormatUtils';

const ContractionReceiptPage: React.FC = () => {
  const dispatch = useDispatch();
  const handleError = useErrorHandler();
  const history = useHistory();
  const currentFund = useSelector(ProposalDetailActions.getInvestmentFund);
  const currentInitialContribution = useSelector(ProposalDetailActions.getInitialContribution);
  const currentMonthlyContribution = useSelector(ProposalDetailActions.getMonthlyContribution);
  const currentMonthsInvesting = useSelector(ProposalDetailActions.getNumberMonthsInvesting);
  const currentPrivatePensionType = useSelector(ProposalDetailActions.getPrivatePensionType);
  const currentTaxType = useSelector(ProposalDetailActions.getTaxType);
  const currentFirstDebitDate = useSelector(ProposalDetailActions.getFirstDebitDate);
  const currentPaymentDay = useSelector(ProposalDetailActions.getPaymentDay);
  const currentHasInsuranceCoverage = useSelector(ProposalDetailActions.getHasInsuranceCoverage);
  const currentInsurance = useSelector(ProposalDetailActions.getInsuranceCoverage);
  const proposalType = useSelector(ProposalDetailActions.getType);

  const chosenProposalId = useSelector(HireProposalActions.getChosenProposalId);

  const userInfo = useSelector(UserInfoActions.get);

  const hasLocalStorageItem = (): boolean =>
    LocalStorageService.hasItem(LocalStorageKeys.INCOME_EXPENSE_FIELDS, userInfo.account);

  const removeLocalStorageItem = () => {
    LocalStorageService.removeItem(LocalStorageKeys.INCOME_EXPENSE_FIELDS, userInfo.account);
  };

  /**
   * The T00:00:00 is used to make new Date return the date stored on currentFirstDebitDate.
   * Without T00:00:00, new Date will return a date with the day before the wanted.
   */
  const firstDebitDayAsDate = new Date(`${currentFirstDebitDate}T00:00:00`);

  useEffect(() => {
    ReceiptAnalyticsService.contractionReceipt({
      content_data: String(currentPaymentDay),
      content_fundo: currentFund.name,
      hasInsurance: currentHasInsuranceCoverage,
      price_inicial: formatToBRLCurrency(currentInitialContribution),
      price_mensal: formatToBRLCurrency(currentMonthlyContribution),
      content_mod: currentPrivatePensionType,
      content_trib: currentTaxType,
      content_tempo: String(currentMonthsInvesting),
    });

    const hasIncomeExpenseFileds = hasLocalStorageItem();

    if (hasIncomeExpenseFileds) {
      removeLocalStorageItem();
    }
  }, [
    currentPaymentDay,
    currentFund,
    currentHasInsuranceCoverage,
    currentInitialContribution,
    currentMonthlyContribution,
    currentPrivatePensionType,
    currentTaxType,
    currentMonthsInvesting,
  ]);

  useEffect(() => {
    dispatch(NavbarActions.setTitle(PageTitles.CONTRACTION_RECEIPT));
    dispatch(ErrorActions.resetState());
  }, [dispatch]);

  const goToAppHome = () =>
    _try(
      async () => {
        ReceiptAnalyticsService.goToHomeButtonClick(proposalType);
        await BridgeService.goToAppHomeOrBackToNative(history, PageRoutes.HOME);
      },
      handleError,
      'ContractionReceiptPage.goToAppHome',
    );

  const stickyFooter = (
    <>
      <InterUIButton margin="0 0 16px 0" onClick={() => goToAppHome()}>
        Ir para a home
      </InterUIButton>
    </>
  );

  return (
    <InterUIContainer stickyFooter={stickyFooter}>
      <Flex alignItems="center" marginBottom="32px">
        <img style={{ width: '64px', height: '64px' }} src={SuccessImg} alt="Símbolo de sucesso" />
      </Flex>
      <H1 marginBottom="10px">Estamos processando seu investimento</H1>
      <P
        fontWeight={400}
        fontSize={FontSizeREM.PX14}
        lineHeight={FontSizeREM.PX17}
        color={Colors.shuttleGray}
        marginBottom="16px"
      >
        Seu investimento está em processamento e será concluído em breve. Sua previdência privada
        estará ativa a partir de{' '}
        {firstDebitDayAsDate.toLocaleString('pt-BR', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })}
        , quando ocorrerá a primeira aplicação. Você pode acompanhar a evolução da sua previdência{' '}
        <strong>em até 7 dias úteis</strong>.
        <br />
        <br />
        <InfoInitialMonthlyContribution
          strategy={
            currentInitialContribution && currentMonthlyContribution
              ? 'INITIAL_MONTHLY_CONTRIBUTION'
              : ''
          }
          initialContribution={currentInitialContribution}
          monthlyContribution={currentMonthlyContribution}
        />
      </P>
      <P
        fontWeight={400}
        fontSize={FontSizeREM.PX14}
        lineHeight={FontSizeREM.PX17}
        color={Colors.shuttleGray}
        marginBottom="16px"
      >
        Número da sua solicitação: <strong>{chosenProposalId}</strong>
      </P>

      <Separator
        style={{ margin: '16px -24px 32px -24px' }}
        color={Colors.ghostWhite}
        height="8px"
      />
      <P fontFamily="Sora" lineHeight={FontSizeREM.PX20} marginBottom="16px">
        Informações do plano Inter {currentPrivatePensionType}
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={400}
        lineHeight={FontSizeREM.PX17}
        marginBottom="4px"
        color={Colors.shuttleGray}
      >
        Nome do fundo
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={700}
        lineHeight={FontSizeREM.PX17}
        marginBottom="16px"
      >
        {currentFund.name}
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={400}
        lineHeight={FontSizeREM.PX17}
        marginBottom="4px"
        color={Colors.shuttleGray}
      >
        Valor da contribuição inicial
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={700}
        lineHeight={FontSizeREM.PX17}
        marginBottom="16px"
      >
        {formatToBRLCurrency(currentInitialContribution)}
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={400}
        lineHeight={FontSizeREM.PX17}
        marginBottom="4px"
        color={Colors.shuttleGray}
      >
        Valor das contribuições mensais
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={700}
        lineHeight={FontSizeREM.PX17}
        marginBottom="16px"
      >
        {formatToBRLCurrency(currentMonthlyContribution)}
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={400}
        lineHeight={FontSizeREM.PX17}
        marginBottom="4px"
        color={Colors.shuttleGray}
      >
        Prazo de aposentadoria
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={700}
        lineHeight={FontSizeREM.PX17}
        marginBottom="16px"
      >
        {getMonthsInvestingText(formatMonthsToYearsAndMonths(currentMonthsInvesting))}
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={400}
        lineHeight={FontSizeREM.PX17}
        marginBottom="4px"
        color={Colors.shuttleGray}
      >
        Modalidade de plano
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={700}
        lineHeight={FontSizeREM.PX17}
        marginBottom="16px"
      >
        {currentPrivatePensionType}
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={400}
        lineHeight={FontSizeREM.PX17}
        marginBottom="4px"
        color={Colors.shuttleGray}
      >
        Tabela de tributação
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={700}
        lineHeight={FontSizeREM.PX17}
        marginBottom="16px"
      >
        Tabela {currentTaxType === TaxTypes.PROGRESSIVE ? 'Progressiva' : 'Regressiva'}
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={400}
        lineHeight={FontSizeREM.PX17}
        marginBottom="4px"
        color={Colors.shuttleGray}
      >
        Primeiro débito
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={700}
        lineHeight={FontSizeREM.PX17}
        marginBottom="16px"
      >
        {firstDebitDayAsDate.toLocaleString('pt-BR', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })}
      </P>
      {currentMonthlyContribution > 0 && (
        <>
          <P
            fontSize={FontSizeREM.PX14}
            fontWeight={400}
            lineHeight={FontSizeREM.PX17}
            marginBottom="4px"
            color={Colors.shuttleGray}
          >
            Data do débito mensal
          </P>
          <P
            fontSize={FontSizeREM.PX14}
            fontWeight={700}
            lineHeight={FontSizeREM.PX17}
            marginBottom="16px"
          >
            {getSecondDebitDate(currentFirstDebitDate, currentPaymentDay).toLocaleString('pt-BR', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })}
          </P>
        </>
      )}
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={400}
        lineHeight={FontSizeREM.PX17}
        marginBottom="4px"
        color={Colors.shuttleGray}
      >
        Taxa de administração mín./máx.
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={700}
        lineHeight={FontSizeREM.PX17}
        marginBottom="16px"
      >
        {formatToPercent(currentFund.minimumAdministrationFee)} /{' '}
        {formatToPercent(currentFund.maximumAdministrationFee)} a.a.
      </P>
      <Separator
        style={{ margin: '32px -24px 32px -24px' }}
        color={Colors.ghostWhite}
        height="8px"
      />
      <InterUIAlert>
        <P
          fontWeight={700}
          fontSize={FontSizeREM.PX12}
          lineHeight={FontSizeREM.PX15}
          marginBottom="4px"
        >
          Beneficiários
        </P>
        <P fontWeight={400} fontSize={FontSizeREM.PX12} lineHeight={FontSizeREM.PX15}>
          Os beneficiários do seu plano de previdência são seus herdeiros legais.
        </P>
      </InterUIAlert>
      {currentMonthlyContribution > 0 && currentInsurance && currentHasInsuranceCoverage && (
        <>
          <Separator
            style={{ margin: '32px -24px 32px -24px' }}
            color={Colors.ghostWhite}
            height="8px"
          />
          <P fontFamily="Sora" lineHeight={FontSizeREM.PX20} marginBottom="16px">
            Seguro
          </P>
          <P
            fontSize={FontSizeREM.PX14}
            fontWeight={400}
            lineHeight={FontSizeREM.PX17}
            marginBottom="4px"
            color={Colors.shuttleGray}
          >
            Nome do seguro
          </P>
          <P
            fontSize={FontSizeREM.PX14}
            fontWeight={700}
            lineHeight={FontSizeREM.PX17}
            marginBottom="16px"
          >
            Pecúlio
          </P>
          <P
            fontSize={FontSizeREM.PX14}
            fontWeight={400}
            lineHeight={FontSizeREM.PX17}
            marginBottom="4px"
            color={Colors.shuttleGray}
          >
            Valor segurado
          </P>
          <P
            fontSize={FontSizeREM.PX14}
            fontWeight={700}
            lineHeight={FontSizeREM.PX17}
            marginBottom="16px"
          >
            {formatToBRLCurrency(currentInsurance.coverageValue)}
          </P>
          <P
            fontSize={FontSizeREM.PX14}
            fontWeight={400}
            lineHeight={FontSizeREM.PX17}
            marginBottom="4px"
            color={Colors.shuttleGray}
          >
            Valor pago mensalmente
          </P>
          <P
            fontSize={FontSizeREM.PX14}
            fontWeight={700}
            lineHeight={FontSizeREM.PX17}
            marginBottom="16px"
          >
            {formatToBRLCurrency(currentInsurance.monthlyContributionValue)}
          </P>
          <P
            fontSize={FontSizeREM.PX14}
            fontWeight={400}
            lineHeight={FontSizeREM.PX17}
            marginBottom="4px"
            color={Colors.shuttleGray}
          >
            Carência
          </P>
          <P
            fontSize={FontSizeREM.PX14}
            fontWeight={700}
            lineHeight={FontSizeREM.PX17}
            marginBottom="16px"
          >
            {currentInsurance.gracePeriod} meses
          </P>
          <P
            fontSize={FontSizeREM.PX14}
            fontWeight={400}
            lineHeight={FontSizeREM.PX17}
            marginBottom="4px"
            color={Colors.shuttleGray}
          >
            Coberturas
          </P>
          <P
            fontSize={FontSizeREM.PX14}
            fontWeight={700}
            lineHeight={FontSizeREM.PX17}
            marginBottom="16px"
          >
            Indeniza os beneficiários em caso de morte do(a) segurado(a).
          </P>
        </>
      )}
    </InterUIContainer>
  );
};

export default ContractionReceiptPage;
