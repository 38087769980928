/* eslint-disable react-hooks/exhaustive-deps */
import { InterUIButton } from '@interco/inter-ui-react-lib';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PortabilityAnalyticsService from '../../analytics/portabilityAnalyticsService';
import { FormWithHook } from '../../components/FormWithHook/FormWithHook';
import InputPricingWithHook from '../../components/InputPricingWithHook/InputPricingWithHook';
import FontSizeREM from '../../enums/fontSizesRem';
import { getMessages, MessagesValidation } from '../../enums/messages';
import { PageRoutes } from '../../enums/pageRoutes';
import { LoadingActions } from '../../store/loading/actions';
import PortabilityActions from '../../store/portability/actions';
import Colors from '../../styles/colors';
import { H1, P } from '../../styles/commons';
import { ACCUMULATED_MINIMUM_VALUE } from '../../utils/minValues';
import { formatToBRLCurrency } from '../../utils/numberFormatUtils';

interface IPortabilityAccumulatedValueForm {
  accumulatedValue: number | string;
}

const PortabilityAccumulatedValuePage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedInstitution = useSelector(PortabilityActions.getFinancialInstitution);
  const accumulatedValue = useSelector(PortabilityActions.getAccumulatedValue);
  const loading = useSelector(LoadingActions.get);

  // Stryker disable all
  const methods = useForm<IPortabilityAccumulatedValueForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      accumulatedValue: accumulatedValue || 0,
    },
  });
  // Stryker restore all

  const {
    getValues,
    formState: { isValid },
  } = methods;

  const isButtonDisabled = useMemo(() => !isValid || getValues('accumulatedValue') === 0, [
    isValid,
    // Stryker disable next-line all
    getValues('accumulatedValue'),
  ]);

  const stikyFooter: React.ReactElement = (
    <InterUIButton type="submit" disabled={isButtonDisabled} loading={loading} margin="24px">
      Solicitar portabilidade
    </InterUIButton>
  );

  const onSubmit = (data: IPortabilityAccumulatedValueForm) => {
    dispatch(PortabilityActions.setAccumulatedValue(+data.accumulatedValue));
    PortabilityAnalyticsService.accumulatedValueButton(String(data.accumulatedValue));
    PortabilityAnalyticsService.portabilityConfirmation({
      price: `${data.accumulatedValue}`,
      content_id: selectedInstitution?.name as string,
    });
    dispatch(
      PortabilityActions.request({ history, pathname: PageRoutes.PORTABILITY_CONFIRMATION }),
    );
  };

  return (
    <FormWithHook stickyFooter={stikyFooter} methods={methods} onSubmit={onSubmit}>
      <H1 marginBottom="8px">Valor acumulado</H1>

      <P
        fontSize={FontSizeREM.PX14}
        lineHeight={FontSizeREM.PX17}
        fontWeight={400}
        color={Colors.shuttleGray}
        marginBottom="0px"
      >
        Valor acumulado é a soma das contribuições feitas na sua previdência até agora. Quanto você
        já acumulou aproximadamente?
      </P>

      <InputPricingWithHook
        dataTestId="accumulated-value"
        name="accumulatedValue"
        placeholder="0,00"
        prefix="R$"
        maxLength={14}
        margin="0 0 6px"
        validation={{
          min: {
            value: ACCUMULATED_MINIMUM_VALUE,
            message: getMessages(MessagesValidation.MINIMUM_VALUE_VALIDATION, [
              formatToBRLCurrency(ACCUMULATED_MINIMUM_VALUE),
            ]),
          },
        }}
      />
    </FormWithHook>
  );
};

export default PortabilityAccumulatedValuePage;
