import { AnyAction, Reducer } from 'redux';
import { ContributionValuesState, ContributionValuesTypes } from './types';

const INITIAL_STATE: ContributionValuesState = {
  initialContribution: 0,
  monthlyContribution: 0,
  annualContribution: 0,
  isChangeRequired: false,
};

const reducer: Reducer<ContributionValuesState> = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case ContributionValuesTypes.SET_CONTRIBUTION_VALUES: {
      return action.payload;
    }
    case ContributionValuesTypes.RESET: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
