import AnalyticsBaseEventName from '../enums/analyticsBaseEventNames';
import BridgeService from '../services/bridgeService';
import { ProposalTypes } from '../store/proposal/types';
import { handlerProposalType } from '../utils/analyticsUtils';
import { IProposalDetailsState } from './types';

class ReceiptAnalyticsService {
  private static className = 'ReceiptAnalyticsService';

  static contractionReceipt({
    hasInsurance,
    content_data,
    content_fundo,
    content_mod,
    content_tempo,
    content_trib,
    price_inicial,
    price_mensal,
  }: IProposalDetailsState): void {
    BridgeService.requestAnalytics(
      `${this.className}.contractionReceipt`,
      `${AnalyticsBaseEventName.RECEIPT}DC`,
      {
        content_data: price_mensal === '0,00' ? '0' : content_data,
        content_fundo,
        content_mod,
        content_tempo,
        content_trib,
        price_inicial,
        price_mensal,
        currency: 'BRL',
        content_protecao: hasInsurance ? 'Com proteção' : 'Sem proteção',
        content_id: 'Aposentadoria',
      },
    );
  }

  static goToHomeButtonClick(proposalType: ProposalTypes): void {
    BridgeService.requestAnalytics(
      `${this.className}.goToHomeButtonClick`,
      `${AnalyticsBaseEventName.RECEIPT}T_Ir_home`,
      {
        content_id: handlerProposalType(proposalType),
      },
    );
  }
}

export default ReceiptAnalyticsService;
