import { IWbUserInfo } from 'inter-webview-bridge';
import TermsTypes from '../../enums/signedTerms';

export enum UserInfoTypes {
  SET_USER_INFO = `@user/SET_USER_INFO`,
  SET_USER_BALANCE_INFO = '@userBalance/SET_USER_BALANCE_INFO',
  SET_USER_HAS_PRIVATE_PENSION = '@user/SET_USER_HAS_PRIVATE_PENSION',
  REQUEST_USER_HAS_PRIVATE_PENSION = '@user/REQUEST_USER_HAS_PRIVATE_PENSION',
  REQUEST_USER_BALANCE_INFO = '@userBalance/REQUEST_USER_BALANCE_INFO',
  SET_USER_SIGNED_TERMS = '@userSignedTerms/SET_USER_SIGNED_TERMS',
  REQUEST_USER_SIGNED_TERMS = '@userSignedTerms/REQUEST_USER_SIGNED_TERMS',
  RESET = `@user/RESET`,
}

export interface IUserBalanceInfo {
  blockedByCheck?: number;
  judiciallyBlocked?: number;
  avaliable?: number;
}

export interface UserInfoState {
  info: IWbUserInfo;
  balanceInfo?: IUserBalanceInfo;
  signedTerms: IUserSignedTermsInfo;
  hasPrivatePension: boolean;
}

export interface IUserSignedTermsInfo {
  investorProfile: string | null;
  terms: Array<ITerms>;
  isAcceptable?: boolean;
  whichIsMissing: IMissingTerms;
  isQualified?: boolean;
}

export interface IMissingTerms {
  isSuitabilityMissing: boolean;
  isInvestorProfileMissing: boolean;
}

export interface ITerms {
  assinado: boolean;
  dataAssinatura: string | null;
  numeroTermo: number;
  tipo: TermsTypes;
}
