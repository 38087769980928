/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/unbound-method */
import { AnyAction } from 'redux';
import { call, put, SagaGenerator, select, takeLeading } from 'typed-redux-saga';
import BuyGoodProposalService from '../../services/buyGoodProposalService';
import DeepLinkProposalService from '../../services/deepLinkProposalService';
import IncomeTaxProposalDetailsService from '../../services/incomeTaxProposalDetailsService';
import { MonitoringService } from '../../services/monitoringService';
import PortabilityService from '../../services/portabilityService';
import ProposalDetailsService from '../../services/proposalDetailsService';
import { errorHandlingSaga, redirectThroughSaga } from '../../utils/providerSaga';
import { BuyGoodActions } from '../buyGood/actions';
import DeepLinkProposalActions from '../deeplinkProposal/actions';
import { IncomeExpenseActions } from '../incomeExpenses/actions';
import IncomeTaxPageActions from '../incomeTax/actions';
import { LoadingActions } from '../loading/actions';
import PortabilityActions from '../portability/actions';
import { ProposalByFundActions } from '../proposalByFund/actions';
import { ProposalByFundContributionValuesState } from '../proposalByFund/types';
import RetirementPageActions from '../retirement/actions';
import UserInfoActions from '../user/actions';
import { ProposalDetailActions } from './actions';
import { ProposalDetailsTypes, ProposalTypes } from './types';

const { REACT_APP_HAS_INSURANCE_COVERAGE } = process.env;

function* requestProposalDetails({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show());

    const initialContribution = yield* select(RetirementPageActions.getInitialContribution);
    const monthlyContribution = yield* select(RetirementPageActions.getMonthlyContribution);

    const { cpf } = yield* select(UserInfoActions.get);

    const response = yield* call(
      ProposalDetailsService.getProposalDetails,
      initialContribution || 0,
      monthlyContribution || 0,
      cpf,
    );

    const proposal = response.data;

    if (REACT_APP_HAS_INSURANCE_COVERAGE === 'WITH_INSURANCE') {
      proposal.hasInsuranceCoverage = true;
    }

    proposal.type = ProposalTypes.RETIREMENT;

    yield* put(ProposalDetailActions.setProposalDetails(proposal));

    yield* put(LoadingActions.hide());

    redirectThroughSaga(payload);
  } catch (error: any) {
    yield* errorHandlingSaga(error, 'getProposalDetailsSaga');
  }
}

function* requestBuyGoodProposalDetails({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show());

    const { cpf } = yield* select(UserInfoActions.get);

    const numberOfMonthInYear = 12;
    const { goalName, goalValue, years, months } = yield* select(BuyGoodActions.get);
    const numberOfmnth = (years || 0) * numberOfMonthInYear;
    const monthlyContribution = numberOfmnth + (months || 0);

    const response = yield* call(
      BuyGoodProposalService.requestBuyGoodProposal,
      goalName,
      goalValue,
      monthlyContribution,
      cpf,
    );

    const proposal = response.data;

    proposal.type = ProposalTypes.BUY_GOOD;

    yield* put(LoadingActions.hide());

    yield* put(ProposalDetailActions.setProposalDetails(proposal));
    redirectThroughSaga(payload);
  } catch (error: any) {
    yield* errorHandlingSaga(error, 'requestBuyGoodProposalSaga');
  }
}

function* requestIncomeTaxProposalDetails({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show());

    const initialContribution = yield* select(IncomeTaxPageActions.getInitialContribution);
    const monthlyContribution = yield* select(IncomeTaxPageActions.getMonthlyContribution);
    const annualContribution = yield* select(IncomeTaxPageActions.getAnnualContribution);

    const {
      annualIncome,
      monthlyEducationExpenditure,
      monthlyEducationExpenditureDependents,
      monthlyHealthExpenditure,
      numberOfDependents,
      socialSecurityContribution,
    } = yield* select(IncomeExpenseActions.get);

    const { cpf } = yield* select(UserInfoActions.get);

    const response = yield* call(
      IncomeTaxProposalDetailsService.getIncomeTaxProposalDetails,
      initialContribution || 0,
      monthlyContribution || 0,
      annualContribution,
      annualIncome,
      monthlyEducationExpenditure,
      monthlyEducationExpenditureDependents || 0,
      monthlyHealthExpenditure,
      cpf,
      numberOfDependents,
      socialSecurityContribution,
    );

    const proposal = response.data;
    proposal.annualContribution = annualContribution;

    if (REACT_APP_HAS_INSURANCE_COVERAGE === 'WITH_INSURANCE') {
      proposal.hasInsuranceCoverage = true;
    }

    if (proposal.incomeTax) {
      proposal.incomeTax.forEach((incomeTax) => {
        if (incomeTax.incomeTaxType === 'COMPLETO') {
          proposal.incomeTaxComplete = incomeTax;
        } else if (incomeTax.incomeTaxType === 'SIMPLIFICADO') {
          proposal.incomeTaxSimplified = incomeTax;
        }
      });
      proposal.incomeTax = [];
    }
    proposal.type = ProposalTypes.INCOME_TAX;

    yield* put(ProposalDetailActions.setProposalDetails(proposal));

    yield* put(LoadingActions.hide());

    redirectThroughSaga(payload);
  } catch (error: any) {
    yield* errorHandlingSaga(error, 'getIncomeExpensesProposalDetails');
  }
}

function* requestDeepLinkProposal({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show());
    const deepLinkBody = yield* select(DeepLinkProposalActions.getDeepLinkProposal);

    const { cpf } = yield* select(UserInfoActions.get);

    const response = yield* call(
      DeepLinkProposalService.requestDeepLinkProposal,
      deepLinkBody,
      cpf,
    );

    const proposal = response.data;

    if (REACT_APP_HAS_INSURANCE_COVERAGE === 'WITH_INSURANCE') {
      proposal.hasInsuranceCoverage = true;
    }

    yield* put(ProposalDetailActions.setProposalDetails(proposal));

    yield* put(LoadingActions.hide());

    redirectThroughSaga(payload);
  } catch (error: any) {
    // in case of an error, we are going to redirect the user back to the home page

    MonitoringService.noticeError(error, {
      errorCodeRef: 'requestDeepLinkProposalSaga',
    });
    yield* put(DeepLinkProposalActions.setDeepLinkProposalFailure(true));
    yield* put(LoadingActions.hide());
  }
}

function* requestProposalByFund({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show());

    const state: ProposalByFundContributionValuesState = yield* select(ProposalByFundActions.get);
    const currentFund = yield* select(ProposalDetailActions.getInvestmentFund);

    const { cpf } = yield* select(UserInfoActions.get);

    const response = yield* call(
      ProposalDetailsService.getProposalDetails,
      state.initialValue || 0,
      state.monthlyValue || 0,
      cpf,
    );

    const proposal = response.data;
    proposal.investmentFund = currentFund;

    if (REACT_APP_HAS_INSURANCE_COVERAGE === 'WITH_INSURANCE') {
      proposal.hasInsuranceCoverage = true;
    }

    proposal.type = ProposalTypes.BY_FUND;

    yield* put(ProposalDetailActions.setProposalDetails(proposal));
    yield* put(LoadingActions.hide());
    redirectThroughSaga(payload);
  } catch (error: any) {
    yield* errorHandlingSaga(error, 'requestRetirementByFundProposal');
  }
}

function* requestPortabilityProposal({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show());

    const externalPensionPlan = yield* select(PortabilityActions.getExternalPensionPlan);
    const { cpf } = yield* select(UserInfoActions.get);

    if (externalPensionPlan) {
      const response = yield* call(
        PortabilityService.requestPortabilityProposal,
        externalPensionPlan.id,
        cpf,
      );

      const proposal = response.data;
      proposal.type = ProposalTypes.PORTABILITY;

      yield* put(ProposalDetailActions.setProposalDetails(proposal));

      yield* put(LoadingActions.hide());
      redirectThroughSaga(payload);
    } else {
      yield* put(LoadingActions.hide());
      MonitoringService.noticeError(new Error('ExternalPensionPlan was not selected/defined!'), {
        errorCodeRef: 'requestPortabilityProposal',
      });
    }
  } catch (error: any) {
    yield* errorHandlingSaga(error, 'requestPortabilityProposal');
  }
}

export default function* watchProposalDetails(): SagaGenerator<void> {
  yield* takeLeading(
    ProposalDetailsTypes.REQUEST_BUY_GOOD_PROPOSAL_DETAILS,
    requestBuyGoodProposalDetails,
  );
  yield* takeLeading(ProposalDetailsTypes.REQUEST_PROPOSAL_DETAILS, requestProposalDetails);
  yield* takeLeading(
    ProposalDetailsTypes.REQUEST_INCOME_TAX_PROPOSAL_DETAILS,
    requestIncomeTaxProposalDetails,
  );
  yield* takeLeading(ProposalDetailsTypes.REQUEST_DEEP_LINK_PROPOSAL, requestDeepLinkProposal);
  yield* takeLeading(ProposalDetailsTypes.REQUEST_PROPOSAL_BY_FUND, requestProposalByFund);
  yield* takeLeading(ProposalDetailsTypes.REQUEST_PORTABILITY_PROPOSAL, requestPortabilityProposal);
}
