export enum SnackbarTypes {
  SHOW = `@snackbar/SHOW`,
  HIDE = `@snackbar/HIDE`,
  SET_MESSAGE = `@snackbar/SET_MESSAGE`,
  SET_DURATION = `@snackbar/SET_DURATION`,
}

export interface SnackbarState {
  toggle: boolean;
  message: string;
  duration: number;
}
