import styled from 'styled-components';

interface PropsStyle {
  toggle?: boolean;
}

export const ExpandCollpaseBtn = styled.button<PropsStyle>`
  margin: 0 1px 0 auto;
  transition: all 0.35s;

  ${(props) => props.toggle && `transform: rotate(180deg);`}
`;
