import { AnyAction } from 'redux';
import { action } from 'typesafe-actions';
import { RootState } from '..';
import { HireProposalStatus, PrivatePensions, TaxTypes } from '../../enums/privatePensions';
import { IReceiptPageProps } from '../../pages/CancelReceipt/CancelReceiptPage';
import { SagaWithHistory } from '../../utils/providerSaga';
import { HireProposalState, HireProposalTypes } from './types';

const get = (state: RootState): HireProposalState => state.hireProposal;

const hasPendingHireProposal = (state: RootState): boolean =>
  state.hireProposal.pendingHireProposal?.status === HireProposalStatus.ACEITE_PENDENTE;

const setPendingHireProposal = (hireProposal: HireProposalState): AnyAction =>
  action(HireProposalTypes.SET_PENDING_HIRE_PROPOSAL, hireProposal);

const getSuggestedProposalId = (state: RootState): number => state.hireProposal.suggestedProposalId;

const setSuggestedProposalId = (suggestedProposalId: number): AnyAction =>
  action(HireProposalTypes.SET_SUGGESTED_PROPOSAL_ID, suggestedProposalId);

const getPrivatePensionType = (state: RootState): PrivatePensions =>
  state.hireProposal.privatePensionType;

const setPrivatePensionType = (privatePensionType: PrivatePensions): AnyAction =>
  action(HireProposalTypes.SET_PRIVATE_PENSION_TYPE, privatePensionType);

const getTaxType = (state: RootState): TaxTypes => state.hireProposal.taxType;

const setTaxType = (taxType: TaxTypes): AnyAction =>
  action(HireProposalTypes.SET_TAX_TYPE, taxType);

const getInitialContribution = (state: RootState): number => state.hireProposal.initialContribution;

const setInitialContribution = (initialContribution: number): AnyAction =>
  action(HireProposalTypes.SET_INITIAL_CONTRIBUTION, initialContribution);

const getMonthlyContribution = (state: RootState): number => state.hireProposal.monthlyContribution;

const setMonthlyContribution = (monthlyContribution: number): AnyAction =>
  action(HireProposalTypes.SET_MONTHLY_CONTRIBUTION, monthlyContribution);

const getNumberMonthsInvesting = (state: RootState): number =>
  state.hireProposal.numberMonthsInvesting;

const setNumberMonthsInvesting = (numberMonthsInvesting: number): AnyAction =>
  action(HireProposalTypes.SET_NUMBER_MONTHS_INVESTING, numberMonthsInvesting);

const getPaymentDay = (state: RootState): number => state.hireProposal.paymentDay;

const setPaymentDay = (paymentDay: number): AnyAction =>
  action(HireProposalTypes.SET_PAYMENT_DAY, paymentDay);

const getInvestmentFundCode = (state: RootState): string => state.hireProposal.investmentFundCode;

const setInvestimentFundCode = (investmentFundCode: string): AnyAction =>
  action(HireProposalTypes.SET_INVESTMENT_FUND_CODE, investmentFundCode);

const getHasInsuranceCoverage = (state: RootState): boolean | undefined =>
  state.hireProposal.hasInsuranceCoverage;

const setHasInsuranceCoverage = (hasInsuranceCoverage: boolean | undefined): AnyAction =>
  action(HireProposalTypes.SET_HAS_INSURANCE_COVERAGE, hasInsuranceCoverage);

const requestHireProposal = (data?: SagaWithHistory): AnyAction =>
  action(HireProposalTypes.REQUEST_HIRE_PROPOSAL, data);

const requestHirePortabilityProposal = (data?: SagaWithHistory): AnyAction =>
  action(HireProposalTypes.REQUEST_HIRE_PORTABILITY_PROPOSAL, data);

const getChosenProposalId = (state: RootState): number => state.hireProposal.chosenProposalId;

const setChosenProposalId = (chosenProposalId: number): AnyAction =>
  action(HireProposalTypes.SET_CHOOSEN_PROPOSAL_ID, chosenProposalId);

const resetState = (): AnyAction => action(HireProposalTypes.RESET);

const getCustomerPendingHireProposal = (callback?: () => void): AnyAction =>
  action(HireProposalTypes.GET_HIRE_PROPOSAL, { callback });

const cancelPendingHireProposal = (
  id: number,
  receiptPageProps: IReceiptPageProps,
  data?: SagaWithHistory,
): AnyAction =>
  action(HireProposalTypes.CANCEL_PENDING_HIRE_PROPOSAL, { id, receiptPageProps, data });

const signPendingHireProposal = (id: number, data?: SagaWithHistory): AnyAction =>
  action(HireProposalTypes.SIGN_PENDING_HIRE_PROPOSAL, { id, data });

export const HireProposalActions = {
  get,
  cancelPendingHireProposal,
  hasPendingHireProposal,
  setPendingHireProposal,
  getSuggestedProposalId,
  setSuggestedProposalId,
  getPrivatePensionType,
  setPrivatePensionType,
  getTaxType,
  setTaxType,
  getInitialContribution,
  setInitialContribution,
  getMonthlyContribution,
  setMonthlyContribution,
  getNumberMonthsInvesting,
  setNumberMonthsInvesting,
  getPaymentDay,
  setPaymentDay,
  getInvestmentFundCode,
  setInvestimentFundCode,
  getHasInsuranceCoverage,
  setHasInsuranceCoverage,
  requestHireProposal,
  requestHirePortabilityProposal,
  getChosenProposalId,
  setChosenProposalId,
  getCustomerPendingHireProposal,
  resetState,
  signPendingHireProposal,
};
