/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from 'axios';
import { IWbAppInfo } from './bridgeService';
import { MockService } from './mockService';

class AppInfoMockService {
  static async requestAppInfo(): Promise<IWbAppInfo> {
    const path = `app-bridge/app-info`;
    let response = {} as AxiosResponse<IWbAppInfo>;

    try {
      response = await axios({
        method: 'GET',
        url: `${MockService.baseUrl}/${path}`,
        headers: { 'x-api-key': MockService.apiKey },
      });
    } catch (error: any) {
      MockService.handleMockServiceErrors(error, 'AppInfoMockService.handleMockServiceErrors');
    }

    return response.data;
  }
}

export default AppInfoMockService;
