import { AnyAction, Reducer } from 'redux';
import { PrivatePensions, TaxTypes } from '../../enums/privatePensions';
import { ProposalDetailsState, ProposalDetailsTypes, ProposalTypes } from './types';

const INITIAL_STATE: ProposalDetailsState = {
  suggestedProposalId: 0,
  qualifiedCustomer: false,
  type: ProposalTypes.RETIREMENT,
  privatePensionType: PrivatePensions.EMPTY,
  taxType: TaxTypes.EMPTY,
  initialContribution: 0,
  monthlyContribution: 0,
  annualContribution: 0,
  portabilityValue: 0,
  numberMonthsInvesting: 0,
  firstDebitDate: '',
  paymentDay: 0,
  insuranceCoverage: {
    coverageValue: 0,
    monthlyContributionValue: 0,
    gracePeriod: 0,
  },
  investmentFund: {
    minimumInitialContribution: 0,
    minimumMonthlyContribution: 0,
    minimumAdditionalContribution: 0,
    monthlyProfitability: 0,
    yearlyProfitability: 0,
    lastTwelveMonthsProfitability: 0,
    maximumAdministrationFee: 0,
    minimumAdministrationFee: 0,
    interestRate: 0,
    name: '',
    code: '',
    riskLevel: 0,
    interFund: false,
    qualified: false,
  },
  hasInsuranceCoverage: undefined,
};

const reducer: Reducer<ProposalDetailsState> = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case ProposalDetailsTypes.SET_PRIVATE_PENSION_TYPE: {
      return { ...state, privatePensionType: action.payload };
    }
    case ProposalDetailsTypes.SET_TAX_TYPE: {
      return { ...state, taxType: action.payload };
    }
    case ProposalDetailsTypes.SET_INITIAL_CONTRIBUTION: {
      return { ...state, initialContribution: action.payload };
    }
    case ProposalDetailsTypes.SET_MONTHLY_CONTRIBUTION: {
      return { ...state, monthlyContribution: action.payload };
    }
    case ProposalDetailsTypes.SET_ANNUAL_CONTRIBUTION: {
      return { ...state, annualContribution: action.payload };
    }
    case ProposalDetailsTypes.SET_PORTABILITY_VALUE: {
      return { ...state, portabilityValue: action.payload };
    }
    case ProposalDetailsTypes.SET_NUMBER_MONTHS_INVESTING: {
      return { ...state, numberMonthsInvesting: action.payload };
    }
    case ProposalDetailsTypes.SET_PAYMENT_DAY: {
      return { ...state, paymentDay: action.payload };
    }
    case ProposalDetailsTypes.SET_FIRST_DEBIT_DATE: {
      return { ...state, firstDebitDate: action.payload };
    }
    case ProposalDetailsTypes.SET_INSURANCE_COVERAGE: {
      return { ...state, insuranceCoverage: action.payload };
    }
    case ProposalDetailsTypes.SET_INVESTMENT_FUND: {
      return { ...state, investmentFund: action.payload };
    }
    case ProposalDetailsTypes.SET_INCOME_TAX_COMPLETE: {
      return { ...state, incomeTaxComplete: action.payload };
    }
    case ProposalDetailsTypes.SET_INCOME_TAX_SIMPLIFIED: {
      return { ...state, incomeTaxSimplified: action.payload };
    }
    case ProposalDetailsTypes.SET_HAS_INSURANCE_COVERAGE: {
      return { ...state, hasInsuranceCoverage: action.payload };
    }
    case ProposalDetailsTypes.SET_IRRF_TABLE: {
      return { ...state, incomeTaxIRRFTableResponse: action.payload };
    }
    case ProposalDetailsTypes.SET_TYPE: {
      return { ...state, type: action.payload };
    }
    case ProposalDetailsTypes.SET_PROPOSAL_DETAILS: {
      return action.payload;
    }
    case ProposalDetailsTypes.RESET: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
