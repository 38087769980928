/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  ExternalPortabilityRequest,
  ExternalPortabilityStatusEnum,
} from '../store/portability/types';
import { AppInfoService } from './appInfoService';
import { BaseService, ServiceResponse } from './baseService';
import { MockService } from './mockService';

const {
  REACT_APP_API_PENSION_PORTABILITY_V1,
  REACT_APP_API_BRIDGE_PENSION_PORTABILITY_V1,
} = process.env;

interface ISafeHeader {
  authenticationToken: string;
  authenticationType: string;
  authenticationValue: string;
  code: string;
  transactionType: string;
}

interface ICancelPendingPortability {
  status: ExternalPortabilityStatusEnum;
}

class ExternalPortabilityService {
  static async getCustomerLastPendingPortability(): Promise<
    ServiceResponse<ExternalPortabilityRequest[]>
  > {
    const allowedStatus = [ExternalPortabilityStatusEnum.ACEITE_PENDENTE];
    const maxResults = 1;
    const order = 'DESC';

    const queryParams = `?allowedStatus=${allowedStatus}&maxResults=${maxResults}&order=${order}`;
    return BaseService.doExecute({
      endpoint: {
        browser: `${REACT_APP_API_PENSION_PORTABILITY_V1}/portabilidades-externas/interno${queryParams}`,
        bridge: `${REACT_APP_API_BRIDGE_PENSION_PORTABILITY_V1}/portabilidades-externas${queryParams}`,
      },
      method: 'GET',
      headers: {
        browser: {
          'x-inter-conta-corrente': MockService.account,
        },
      },
    });
  }

  static async getExternalPortabilityRequest(
    id: number | null,
  ): Promise<ServiceResponse<ExternalPortabilityRequest>> {
    return BaseService.doExecute({
      endpoint: {
        browser: `${REACT_APP_API_PENSION_PORTABILITY_V1}/portabilidades-externas/${id}/interno`,
        bridge: `${REACT_APP_API_BRIDGE_PENSION_PORTABILITY_V1}/portabilidades-externas/${id}`,
      },
      method: 'GET',
      headers: {
        browser: {
          'x-inter-conta-corrente': MockService.account,
        },
      },
    });
  }

  static async acceptExternalPortabilityRequest(
    header: ISafeHeader,
    id: number | null,
  ): Promise<ServiceResponse<ExternalPortabilityRequest>> {
    return BaseService.doExecute({
      endpoint: {
        browser: `${REACT_APP_API_PENSION_PORTABILITY_V1}/portabilidades-externas/${id}/aceite/interno`,
        bridge: `${REACT_APP_API_BRIDGE_PENSION_PORTABILITY_V1}/portabilidades-externas/${id}/aceite`,
      },
      method: 'POST',
      headers: {
        browser: {
          'x-inter-conta-corrente': MockService.account,
        },
        bridge: {
          'x-inter-isafe-token': header.authenticationToken,
          'x-inter-isafe-value': header.authenticationValue,
          'x-inter-isafe-authetication-type': `${header.authenticationType}`, // Force string to prevent error
          'x-inter-isafe-transaction-type': header.transactionType,
        },
      },
    });
  }

  static async cancelPendingHireProposal(
    id: number,
    header: ISafeHeader,
    body: ICancelPendingPortability,
  ): Promise<ServiceResponse<void>> {
    return BaseService.doExecute({
      endpoint: {
        browser: `${REACT_APP_API_PENSION_PORTABILITY_V1}/portabilidades-externas/${id}/interno`,
        bridge: `${REACT_APP_API_BRIDGE_PENSION_PORTABILITY_V1}/portabilidades-externas/${id}`,
      },
      method: 'PUT',
      headers: {
        browser: {
          'x-inter-ip': '127.1.1.1',
          'x-inter-isafe-token': header.authenticationToken,
          'x-inter-isafe-value': header.authenticationValue,
          'x-inter-isafe-authetication-type': `${header.authenticationType}`, // Force string to prevent error
          'x-inter-isafe-transaction-type': header.transactionType,
          'mag-identifier': 'magidentifier',
          'x-inter-mac-address': `00:00:00:00:00:00`,
          'x-inter-app-origem': 'Webview Previdência',
          'x-inter-app-version': AppInfoService.superAppVersion,
          'x-inter-so': AppInfoService.deviceName,
          'x-inter-conta-corrente': MockService.account,
          'x-inter-user': MockService.account,
          ...MockService.matchHeadersByUserAccount,
        },
        bridge: {
          'x-inter-isafe-token': header.authenticationToken,
          'x-inter-isafe-value': header.authenticationValue,
          'x-inter-isafe-authetication-type': `${header.authenticationType}`, // Force string to prevent error
          'x-inter-isafe-transaction-type': header.transactionType,
        },
      },
      data: body,
    });
  }
}

export default ExternalPortabilityService;
