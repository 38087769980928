import React from 'react';
import { useTheme } from 'styled-components';
import FontSizeREM from '../../../enums/fontSizesRem';
import { ThemeInter } from '../../../styles';
import { Flex, P } from '../../../styles/commons';

interface IExpiredInfoProps {
  label: string;
  value: string;
}

const ExpiredInfo: React.FC<IExpiredInfoProps> = ({ label, value }) => {
  const theme = useTheme() as ThemeInter;

  return (
    <>
      <Flex flexDirection="row">
        <P
          fontSize={FontSizeREM.PX14}
          fontWeight={400}
          lineHeight={FontSizeREM.PX17}
          marginBottom="8px"
          color={theme.colors.grayscale.A400}
        >
          {label}
        </P>
      </Flex>
      <Flex flexDirection="row">
        <P fontSize={FontSizeREM.PX14} fontWeight={700} lineHeight={FontSizeREM.PX17}>
          {value}
        </P>
      </Flex>
    </>
  );
};

export default ExpiredInfo;
