import styled, { css } from 'styled-components';
import Colors from '../../styles/colors';

interface PropsStyle {
  toggle?: boolean;
  transitionIn?: string;
  transitionOut?: string;
}

const Shadow = css`
  box-shadow: 0px 2px 6px rgb(0 0 0 / 20%);
`;

export const Card = styled.div<{
  selectable?: boolean;
  selected?: boolean;
  marginBottom?: string;
  hideShadow?: boolean;
}>`
  width: 100%;
  min-height: 113px;
  border: 1px solid ${(props) => (props.selected ? Colors.orange300 : Colors.ghostWhite)};
  border-radius: 8px;
  box-sizing: border-box;
  position: relative;
  ${(props) => props.selectable && 'cursor: pointer;'}
  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom};`};
  ${(props) => props.selected && !props.hideShadow && Shadow}
`;

export const CardContainer = styled.div<{
  margin?: string;
  backgroundColor: string;
  borderRadius: string;
}>`
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.backgroundColor && `background-color: ${props.backgroundColor};`}
  ${(props) => props.borderRadius && `border-radius: ${props.borderRadius};`};
`;

/**
 * Conteúdo do collapse.
 */
export const collapsedStyle = css<PropsStyle>`
  height: auto;
  max-height: 100vh;
  margin-bottom: 2px;
  ${(props) => props.transitionOut && `transition: ${props.transitionOut}`}
`;

export const Collapse = styled.div<PropsStyle>`
  max-height: 0;
  overflow: hidden;
  transition: ${(props) => (props.transitionIn ? props.transitionIn : '0.35s ease-out')};
  ${(props) => props.toggle && collapsedStyle}
`;
