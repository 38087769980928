import { AnyAction } from 'redux';
import { action } from 'typesafe-actions';
import { RootState } from '..';
import { RetirementPageTypes } from './types';

const getContributionStrategy = (state: RootState): string => state.retirementPage.strategy;

const setContributionStrategy = (strategy: string): AnyAction =>
  action(RetirementPageTypes.SET_CONTRIBUTION_STRATEGY, strategy);

const getInitialContribution = (state: RootState): number =>
  state.retirementPage.initialContribution;

const setInitialContribution = (initialContribution: number): AnyAction =>
  action(RetirementPageTypes.SET_INITIAL_CONTRIBUTION, initialContribution);

const getMonthlyContribution = (state: RootState): number =>
  state.retirementPage.monthlyContribution;

const setMonthlyContribution = (monthlyContribution: number): AnyAction =>
  action(RetirementPageTypes.SET_MONTHLY_CONTRIBUTION, monthlyContribution);

const getInitialMinimum = (state: RootState): number => state.retirementPage.initialMinimum;

const setInitialMinimum = (initialMinimum: number): AnyAction =>
  action(RetirementPageTypes.SET_INITIAL_MINIMUM, initialMinimum);

const getMonthlyMinimum = (state: RootState): number => state.retirementPage.monthlyMinimum;

const setMonthlyMinimum = (monthlyMinimum: number): AnyAction =>
  action(RetirementPageTypes.SET_MONTHLY_MINIMUM, monthlyMinimum);

const resetState = (): AnyAction => action(RetirementPageTypes.RESET);

const RetirementPageActions = {
  getContributionStrategy,
  setContributionStrategy,
  getInitialContribution,
  setInitialContribution,
  getInitialMinimum,
  setInitialMinimum,
  getMonthlyMinimum,
  setMonthlyMinimum,
  getMonthlyContribution,
  setMonthlyContribution,
  resetState,
};

export default RetirementPageActions;
