import styled from 'styled-components';
import FontSizeREM from '../enums/fontSizesRem';
import Colors from './colors';

export interface IIconProps {
  size: string;
  color?: string;
}

export interface FlexStyledProps {
  name?: string;
  flexDirection?: string;
  justifyContent?: string;
  alignItems?: string;
  height?: string;
  width?: string;
  overflowX?: string;
  overflowY?: string;
  margin?: string;
  marginBottom?: string;
  gap?: string;
  backgroundColor?: string;
  padding?: string;
  borderRadius?: string;
  flexFlow?: string;
}

export const P = styled.p<{
  color?: string;
  marginBottom?: string;
  lineHeight?: string;
  fontSize?: string;
  fontWeight?: number;
  maxWidth?: string;
  minWidth?: string;
  disabled?: boolean;
  fontFamily?: string;
  textAlign?: string;
  margin?: string;
  textTransform?: string;
}>`
  margin-bottom: ${(props) => props.marginBottom || `8px`};

  font-size: ${(props) => props.fontSize || FontSizeREM.PX16};
  line-height: ${(props) => props.lineHeight || FontSizeREM.PX18};
  font-weight: ${(props) => props.fontWeight || `600`};
  color: ${(props) =>
    props.disabled ? Colors.interBlackDisabled : props.color || Colors.interBlack};
  ${(props) => props.margin && `margin: ${props.margin} !important;`}
  ${(props) => props.fontFamily && `font-family: '${props.fontFamily}';`}
  ${(props) => props.textAlign && `text-align: ${props.textAlign};`}
  ${(props) => props.minWidth && `min-width: ${props.minWidth};`}
  ${(props) => props.textTransform && `text-transform: ${props.textTransform};`}
`;

export const PSmall = styled(P)`
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth};`}
  margin-bottom: ${(props) => props.marginBottom || `8px`};
  font-size: ${(props) => props.fontSize || FontSizeREM.PX14};
  line-height: ${(props) => props.lineHeight || FontSizeREM.PX18};
  font-weight: ${(props) => props.fontWeight || `400`};
`;

export const PSmallBold = styled(PSmall)`
  font-weight: 700;
`;

export const PBlack = styled(P)`
  color: ${(props) => (props.disabled ? Colors.interBlackDisabled : Colors.interBlack)};
`;

export const PSmallGray = styled(PSmall)`
  color: ${(props) => (props.disabled ? Colors.shuttleGrayDisabled : Colors.shuttleGray)};
`;

export const Small = styled.small<{
  bold?: boolean;
  marginBottom?: string;
}>`
  display: block;

  ${(props) => props.bold && `font-weight: bold;`}
  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom};`}
`;

export const Span = styled.span<{
  color?: string;
  lineHeight?: string;
  fontSize?: string;
  fontWeight?: number;
  margin?: string;
  textTransform?: string;
}>`
  ${(props) => props.color && `color: ${props.color};`}
  font-size: ${(props) => props.fontSize || FontSizeREM.PX16};
  line-height: ${(props) => props.lineHeight || FontSizeREM.PX16};
  font-weight: ${(props) => props.fontWeight || `400`};
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.textTransform && `text-transform: ${props.textTransform};`}
`;

export const H1 = styled.h1<{ marginBottom?: string }>`
  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom};`}
`;

export const H2 = styled.h2<{ marginBottom?: string }>`
  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom};`}
`;

export const H3 = styled.h3<{
  marginBottom?: string;
  marginTop?: string;
}>`
  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom};`}
  ${(props) => props.marginTop && `margin-top: ${props.marginTop};`}
`;

export const SeparatorDashed = styled.div<{
  marginBottom?: string;
  marginTop?: string;
  color?: string;
  margin?: string;
}>`
  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom};`}
  ${(props) => props.marginTop && `margin-top: ${props.marginTop};`}
  ${(props) => props.margin && `margin: ${props.margin} !important;`}
  border-bottom: 1px dashed ${(props) => props.color || Colors.shuttleGray};
`;

export const Separator = styled.div<{
  margin?: string;
  marginBottom?: string;
  marginTop?: string;
  color?: string;
  height?: string;
  width?: string;
}>`
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom};`}
  ${(props) => props.marginTop && `margin-top: ${props.marginTop};`}
  ${(props) => props.width && `width: ${props.width};`}
  border-top: ${(props) => props.height || '1px'} solid ${(props) =>
    props.color || Colors.shuttleGray};
`;

export const Div = styled.div<{
  margin?: string;
  marginTop?: string;
  marginBottom?: string;
  position?: string;
  top?: string;
  left?: string;
  bottom?: string;
  right?: string;
  height?: string;
  width?: string;
  backgroundColor?: string;
  transform?: string;
  overflowY?: string;
  overflowX?: string;
  zIndex?: number;
  borderRadius?: string;
  textAlign?: string;
}>`
  ${(props) => props.position && `position: ${props.position};`}
  ${(props) => props.top && `top: ${props.top};`}
  ${(props) => props.left && `left: ${props.left};`}
  ${(props) => props.bottom && `bottom: ${props.bottom};`}
  ${(props) => props.right && `right: ${props.right};`}
  ${(props) => props.height && `height: ${props.height};`};
  ${(props) => props.width && `width: ${props.width};`};
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.marginTop && `margin-top: ${props.marginTop};`}
  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom};`}
  ${(props) => props.backgroundColor && `background-color: ${props.backgroundColor};`};
  ${(props) => props.transform && `transform: ${props.transform};`};
  ${(props) => props.overflowY && `overflow-y: ${props.overflowY};`};
  ${(props) => props.overflowX && `overflow-x: ${props.overflowX};`};
  ${(props) => props.zIndex && `z-index: ${props.zIndex};`};
  ${(props) => props.borderRadius && `border-radius: ${props.borderRadius};`};
  ${(props) => props.textAlign && `text-align: ${props.textAlign};`}
`;

export const EditButton = styled.span<{
  position?: string;
  top?: string;
  left?: string;
  bottom?: string;
  right?: string;
  color?: string;
  fontSize?: string;
  fontWeight?: number;
  lineHeight?: string;
}>`
  ${(props) => props.position && `position: ${props.position};`}
  ${(props) => props.top && `top: ${props.top};`}
  ${(props) => props.left && `left: ${props.left};`}
  ${(props) => props.bottom && `bottom: ${props.bottom};`}
  ${(props) => props.right && `right: ${props.right};`}
  color: ${(props) => props.color || Colors.interOrange};
  font-size: ${(props) => props.fontSize || FontSizeREM.PX16};
  line-height: ${(props) => props.lineHeight || FontSizeREM.PX18};
  font-weight: ${(props) => props.fontWeight || `700`};
`;

export const Flex = styled.div<FlexStyledProps>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection || 'column'};
  ${(props) => props.justifyContent && `justify-content: ${props.justifyContent};`}
  ${(props) => props.alignItems && `align-items: ${props.alignItems};`}
  ${(props) => props.height && `height: ${props.height};`}
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.overflowY && `overflow-y: ${props.overflowY};`};
  ${(props) => props.overflowX && `overflow-x: ${props.overflowX};`};
  ${(props) => props.margin && `margin: ${props.margin};`};
  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom};`}
  ${(props) => props.backgroundColor && `background-color: ${props.backgroundColor};`};
  ${(props) => props.padding && `padding: ${props.padding};`};
  ${(props) => props.borderRadius && `border-radius: ${props.borderRadius};`};
  ${(props) => props.gap && `gap: ${props.gap};`};
  ${(props) => props.flexFlow && `flex-flow: ${props.flexFlow};`};
`;

export const ErrorMessage = styled.div`
  height: 1rem;
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${(props) => props.theme.colors.error.A400};
`;

export const InfoMessage = styled.div<{ color?: string }>`
  height: 1rem;
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${(props) => (props.color ? props.color : props.theme.colors.grayscale.A400)};
`;
