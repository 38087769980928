import { InvestmentFund } from '../../components/InvestmentFundCard/InvestmentFundCard';
import { PrivatePensions, TaxTypes } from '../../enums/privatePensions';

export enum PortabilityTypes {
  GET_CUSTOMER_LAST_PENDING_PORTABILITY = `@portability/GET_CUSTOMER_LAST_PENDING_PORTABILITY`,
  FETCH_EXTERNAL_PORTABILITY_REQUEST = `@portability/FETCH_EXTERNAL_PORTABILITY_REQUEST`,
  ACCEPT_EXTERNAL_PORTABILITY_REQUEST = `@portability/ACCEPT_EXTERNAL_PORTABILITY_REQUEST`,
  SET_EXTERNAL_PORTABILITY_REQUEST = `@portability/SET_EXTERNAL_PORTABILITY_REQUEST`,
  SET_FINANCIAL_INSTITUTION = `@portability/SET_FINANCIAL_INSTITUTION`,
  SET_ACCUMULATED_VALUE = `@portability/SET_ACCUMULATED_VALUE`,
  REQUEST = `@portability/REQUEST`,
  REQUEST_FINANCIAL_INSTITUTIONS = `@portability/REQUEST_FINANCIAL_INSTITUTIONS`,
  REQUEST_ACCESS_TOKEN = `@portability/REQUEST_ACCESS_TOKEN`,
  REQUEST_PENSION_PLANS = `@portability/REQUEST_PENSION_PLANS`,
  REQUEST_DATA_COLLECTION = `@portability/REQUEST_DATA_COLLECTION`,
  SET_PORTABILITY_ID = `@portability/SET_PORTABILITY_ID`,
  SET_EXTERNAL_PENSION_PLAN = `@portability/SET_EXTERNAL_PENSION_PLAN`,
  SET_REQUEST_COUNT = `@portability/SET_REQUEST_COUNT`,
  SET_LINK_ID = `@portability/SET_LINK_ID`,
  SET_PRIVACY_POLICY_ACCEPTED = `@portability/SET_PRIVACY_POLICY_ACCEPTED`,
  REQUEST_PORTABILITIES = `@portability/REQUEST_PORTABILITIES`,
  CANCEL_PENDING_REQUEST = `@portability/CANCEL_PENDING_REQUEST`,
  RESET = `@portability/RESET`,
}

export enum ExternalPensionPlanStatus {
  OK = 'OK',
  INCOMPLETED_DATA = 'INCOMPLETED_DATA',
  PORTABILITY_IN_PROGRESS = 'PORTABILITY_IN_PROGRESS',
}
export interface FinancialInstitution {
  readonly id: number;
  readonly name: string;
  readonly order: number;
  readonly isSupportedByBelvo: boolean;
}

export interface ExternalPensionPlan {
  readonly id: number;
  readonly name: string;
  readonly susep: string;
  readonly planType: PrivatePensions;
  readonly taxType: TaxTypes;
  readonly accumulatedValues: number;
  readonly certificateNumber: string;
  readonly completedData: boolean;
  readonly status: ExternalPensionPlanStatus;
}

export interface PortabilityState {
  readonly financialInstitution: FinancialInstitution | null;
  readonly accumulatedValue: number | null;
  readonly portabilityId: number | null;
  readonly linkId: string | null;
  readonly externalPensionPlan: ExternalPensionPlan | null;
  readonly requestCount: number;
  readonly isPrivacyPolicyAccepted: boolean;
  readonly externalPortabilityRequest: ExternalPortabilityRequest | null;
}

export enum SupportedInstitutesId {
  // TODO: create column in database to map with BelvoSupportedInstitution and drop this enum (SupportedInstitutesId)
  BANCO_DO_BRASIL = 12,
  ITAU = 4,
}

export interface ExternalPortabilityRequest {
  readonly id: number;
  readonly interBankAccount: string;
  readonly customerCpf: string;
  readonly value: number;
  readonly type: string;
  readonly originSusep: string;
  readonly originCertificateNumber: string;
  readonly originFundCnpj: string;
  readonly originPlanType: string;
  readonly originTaxType: string;
  readonly status: ExternalPortabilityStatusEnum;
  readonly originApp: string;
  readonly interUser: string;
  readonly sentRequestDate: string;
  readonly signatureDate: string;
  readonly creationDate: string;
  readonly updateDate: string;
  readonly lastErrorDescription: string;
  readonly targetPlans: PortabilityTargetPlan[];
}

export enum ExternalPortabilityStatusEnum {
  ACEITE_PENDENTE = 'ACEITE_PENDENTE',
  ACEITE_EFEUTADO = 'ACEITE_EFEUTADO',
  PROTOCOLOADA = 'PROTOCOLOADA',
  ASSINADA = 'ASSINADA',
  CANCELADA = 'CANCELADA',
}

export interface PortabilityTargetPlan {
  readonly certificateNumber: string;
  readonly proposalNumber: string;
  readonly mainPlan: boolean;
  readonly distributionPercentage: number;
  readonly requireHire: boolean;
  readonly taxType: TaxRegimeTypeEnum;
  readonly planType: PlanTypeEnum;
  readonly fund: InvestmentFund;
}

export enum TaxRegimeTypeEnum {
  REGRESSIVO,
  PROGRESSIVO,
}

export enum PlanTypeEnum {
  PGBL,
  VGBL,
}
