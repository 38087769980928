import { History } from 'history';
import { put, SagaGenerator } from 'typed-redux-saga';
import { ServiceError } from '../exceptions/index';
import ErrorActions from '../store/error/actions';
import { ErrorResponse } from '../store/error/types';
import { LoadingActions } from '../store/loading/actions';
import { withErrorCodeRef } from './errorUtils';

export interface SagaWithHistory {
  history: History;
  pathname: string;
}

const isDetailedError = (errorResponse: ServiceError<ErrorResponse>): boolean => {
  if (
    errorResponse.data &&
    errorResponse.data.errors &&
    errorResponse.isPresentable &&
    errorResponse.data.errors[0].code &&
    errorResponse.data.errors[0].message
  ) {
    return true;
  }
  return false;
};

/**
 * Realiza o redirecionamento utilizando o useHistory recebido por parâmetro.
 * @param payload Payload recebido através da action.
 */
export function redirectThroughSaga(payload: SagaWithHistory, state?: unknown): void {
  if (payload && payload.history && payload.pathname) {
    const { history } = payload;
    const { pathname } = payload;

    if (state) {
      history.push(pathname, state);
    } else {
      history.push(pathname);
    }
  }
}

/**
 * Handle the error
 */
export function* errorHandlingSaga(
  error: ServiceError<ErrorResponse> | Error,
  errorCodeRef: string,
): SagaGenerator<void> {
  try {
    yield* put(LoadingActions.hide());
    yield* put(ErrorActions.resetState());

    if (error instanceof ServiceError) {
      const isDetailed = isDetailedError(error);
      yield* put(ErrorActions.setIsDetailedError(isDetailed));
      if (isDetailed) {
        yield* put(ErrorActions.setErrorResponse(error.data));
      }
    }

    yield* put(ErrorActions.setErrorInstance(withErrorCodeRef(error, errorCodeRef)));
    yield* put(ErrorActions.show());
  } catch (err) {
    yield* put(
      ErrorActions.setErrorInstance(withErrorCodeRef(err, `errorHandlingSaga|${errorCodeRef}`)),
    );

    yield* put(ErrorActions.show());
  }
}
