import AnalyticsBaseEventName from '../enums/analyticsBaseEventNames';
import BridgeService from '../services/bridgeService';

class AdditionalContributionAnalyticsService {
  private static className = 'AdditionalContributionAnalyticsService';

  static choosePrivatePensionPlan(fundName: string, fundType: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.choosePrivatePensionPlan`,
      `${AnalyticsBaseEventName.ADDITIONAL_CONTRIBUTION}FundosC_T_EscolherFundo`,
      {
        fundo: fundName,
        tipo_fundo: fundType,
      },
    );
  }

  static investmentPage(fundName: string, fundType: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.investmentPage`,
      `${AnalyticsBaseEventName.ADDITIONAL_CONTRIBUTION}_Unico_DC_Acesso`,
      {
        fundo: fundName,
        tipo_fundo: fundType,
      },
    );
  }

  static goToProposalPage(fundName: string, fundType: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.goToProposalPage`,
      `${AnalyticsBaseEventName.ADDITIONAL_CONTRIBUTION}_Unico_T_Investir`,
      {
        fundo: fundName,
        tipo_fundo: fundType,
      },
    );
  }

  static pgblFlowAccess(fundName: string, fundType: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.pgblFlowAccess`,
      `${AnalyticsBaseEventName.ADDITIONAL_CONTRIBUTION}_SimuIR_DC_Acesso`,
      {
        fundo: fundName,
        tipo_fundo: fundType,
      },
    );
  }

  static goToRevisionPagePGBL(fundName: string, fundType: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.goToRevisionPage`,
      `${AnalyticsBaseEventName.ADDITIONAL_CONTRIBUTION}_Unico_T_Investir`,
      {
        fundo: fundName,
        tipo_fundo: fundType,
      },
    );
  }

  static pgblHelperSimulateSubmitButton(fundName: string, fundType: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.pgblHelperSimulateSubmitButton`,
      `${AnalyticsBaseEventName.ADDITIONAL_CONTRIBUTION}_Renda_T_InfoRenda`,
      {
        fundo: fundName,
        tipo_fundo: fundType,
      },
    );
  }

  static IRInvestmentPage(fundName: string, fundType: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.IRInvestmentPage`,
      `${AnalyticsBaseEventName.ADDITIONAL_CONTRIBUTION}_AporteIR_DC_Acesso`,
      {
        fundo: fundName,
        tipo_fundo: fundType,
      },
    );
  }

  static goToProposalPageSimulatingAnnualIncome(fundName: string, fundType: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.goToProposalPageSimulatingAnnualIncome`,
      `${AnalyticsBaseEventName.ADDITIONAL_CONTRIBUTION}_AporteIR_T_InvestirComIR`,
      {
        fundo: fundName,
        tipo_fundo: fundType,
      },
    );
  }

  static revisionPage(fundName: string, fundType: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.revisionPage`,
      `${AnalyticsBaseEventName.ADDITIONAL_CONTRIBUTION}_Resumo_DC_AcessoResumo`,
      {
        fundo: fundName,
        tipo_fundo: fundType,
      },
    );
  }

  static editInvestmentValueButton(fundName: string, fundType: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.editInvestmentValueButton`,
      `${AnalyticsBaseEventName.ADDITIONAL_CONTRIBUTION}_Resumo_T_EditarFundo`,
      {
        fundo: fundName,
        tipo_fundo: fundType,
      },
    );
  }

  static investButton(fundName: string, fundType: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.investButton`,
      `${AnalyticsBaseEventName.ADDITIONAL_CONTRIBUTION}_Resumo_T_ConfirmarInvest`,
      {
        fundo: fundName,
        tipo_fundo: fundType,
      },
    );
  }

  static receiptPage(fundName: string, fundType: string, contribution: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.receiptPage`,
      `${AnalyticsBaseEventName.ADDITIONAL_CONTRIBUTION}_Comprov_DC_AcessoComprov`,
      {
        fundo: fundName,
        tipo_fundo: fundType,
        valor: contribution,
      },
    );
  }

  static expiredPage(): void {
    BridgeService.requestAnalytics(
      `${this.className}.expiredPage`,
      `${AnalyticsBaseEventName.ADDITIONAL_CONTRIBUTION}_ContriExpirada_DC_Acesso`,
    );
  }

  static hireNewPrevButton(pageTitle: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.hireNewPrevButton`,
      `${AnalyticsBaseEventName.ADDITIONAL_CONTRIBUTION}_ContriExpirada_T_NovaPrev`,
      { tela: pageTitle },
    );
  }
}

export default AdditionalContributionAnalyticsService;
