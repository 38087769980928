import { InterUIBottomSheet, InterUIButton, InterUIIcon } from '@interco/inter-ui-react-lib';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import ProposalAnalyticsService from '../../../../analytics/proposalAnalyticsService';
import FontSizeREM from '../../../../enums/fontSizesRem';
import { PageRoutes } from '../../../../enums/pageRoutes';
import { ProposalDetailActions } from '../../../../store/proposal/actions';
import { ThemeInter } from '../../../../styles';
import Colors from '../../../../styles/colors';
import {
  Div,
  EditButton,
  P,
  PSmallBold,
  PSmallGray,
  SeparatorDashed,
} from '../../../../styles/commons';
import {
  formatMonthsToYearsAndMonths,
  getMonthsInvestingText,
} from '../../../../utils/dateTimeUtils';
import { formatToBRLCurrency } from '../../../../utils/numberFormatUtils';

interface IBottomSheetState {
  show: boolean;
  title?: string;
  items: { title?: string; description: string }[];
}

const DebitInfo: React.FC = () => {
  const history = useHistory();
  const type = useSelector(ProposalDetailActions.getType);
  const theme = useTheme() as ThemeInter;
  const investmentFund = useSelector(ProposalDetailActions.getInvestmentFund);
  const initialContribution = useSelector(ProposalDetailActions.getInitialContribution);
  const monthlyContribution = useSelector(ProposalDetailActions.getMonthlyContribution);
  const paymentDay = useSelector(ProposalDetailActions.getPaymentDay);
  const monthsInvesting = useSelector(ProposalDetailActions.getNumberMonthsInvesting);
  const gracePeriod = investmentFund.qualified ? '180 dias para fundos qualificados' : '60 dias';

  const CONTRIBUTION_TIME_INFO = {
    items: [
      {
        title: 'Entenda o prazo de aposentadoria',
        description:
          'O prazo de aposentadoria é o período estimado em que você deseja receber o valor acumulado em sua Previdência.',
      },
      {
        description: `Você pode resgatar seu dinheiro a qualquer momento, respeitando a carência mínima de ${gracePeriod}.`,
      },
      {
        description:
          ' Após atingir o período definido, seu plano não poderá receber novas contribuições.',
      },
    ],
  };

  const [bottomSheet, setBottomSheet] = useState<IBottomSheetState>({
    show: false,
    title: '',
    items: [{ title: '', description: '' }],
  });

  const callBottomSheetInfo = () => {
    setBottomSheet({ show: true, ...CONTRIBUTION_TIME_INFO });
  };

  const goToContributionTimePage = () => {
    ProposalAnalyticsService.editInfoButton('prazo de aposentadoria', type);
    history.push(PageRoutes.CONTRIBUTION_TIME);
  };
  const goToPaymentDayPage = () => {
    ProposalAnalyticsService.editInfoButton('dia do débito a cada mês', type);
    history.push(PageRoutes.PAYMENT_DAY);
  };
  const goToContributionValuesPage = () => {
    ProposalAnalyticsService.editInfoButton('valores de contribuição', type);
    history.push(PageRoutes.CONTRIBUTION_VALUES);
  };

  return (
    <>
      <Div position="relative">
        <P
          fontSize={FontSizeREM.PX14}
          fontWeight={400}
          lineHeight={FontSizeREM.PX17}
          marginBottom="4px"
          color={Colors.shuttleGray}
        >
          Valor inicial
        </P>
        <P
          fontSize={FontSizeREM.PX14}
          fontWeight={700}
          lineHeight={FontSizeREM.PX17}
          marginBottom="12px"
        >
          {formatToBRLCurrency(initialContribution)}
        </P>
        <P
          fontSize={FontSizeREM.PX14}
          fontWeight={400}
          lineHeight={FontSizeREM.PX17}
          marginBottom="4px"
          color={Colors.shuttleGray}
        >
          Valor a cada mês
        </P>
        <P
          fontSize={FontSizeREM.PX14}
          fontWeight={700}
          lineHeight={FontSizeREM.PX17}
          marginBottom="0px"
        >
          {formatToBRLCurrency(monthlyContribution)}
        </P>
        <EditButton
          position="absolute"
          top="0"
          right="0"
          fontSize={FontSizeREM.PX14}
          lineHeight={FontSizeREM.PX17}
          onClick={goToContributionValuesPage}
          data-testid="edit-contribution-values-btn"
        >
          Editar
        </EditButton>
      </Div>
      {monthlyContribution > 0 && (
        <>
          <SeparatorDashed
            marginTop="24px"
            marginBottom="24px"
            color={Colors.shuttleGrayDisabled}
          />
          <Div position="relative">
            <P
              fontSize={FontSizeREM.PX14}
              fontWeight={400}
              lineHeight={FontSizeREM.PX17}
              marginBottom="4px"
              color={Colors.shuttleGray}
            >
              Dia do débito a cada mês
            </P>
            <P
              fontSize={FontSizeREM.PX14}
              fontWeight={700}
              lineHeight={FontSizeREM.PX17}
              marginBottom="0px"
            >
              Dia {paymentDay}
            </P>
            <EditButton
              position="absolute"
              top="0"
              right="0"
              fontSize={FontSizeREM.PX14}
              lineHeight={FontSizeREM.PX17}
              onClick={goToPaymentDayPage}
              data-testid="edit-payment-date-btn"
            >
              Editar
            </EditButton>
          </Div>
        </>
      )}
      <SeparatorDashed marginTop="24px" marginBottom="24px" color={Colors.shuttleGrayDisabled} />
      <Div position="relative">
        <P
          fontSize={FontSizeREM.PX14}
          fontWeight={400}
          lineHeight={FontSizeREM.PX17}
          marginBottom="4px"
          color={Colors.shuttleGray}
        >
          Prazo de aposentadoria
          <button
            type="button"
            style={{ verticalAlign: 'middle', marginLeft: '6px' }}
            onClick={() => callBottomSheetInfo()}
            aria-label="O que é o Prazo de aposentadoria?"
          >
            <InterUIIcon name="help" color={theme.colors.primary.A500} size="16px" />
          </button>
        </P>
        <P
          fontSize={FontSizeREM.PX14}
          fontWeight={700}
          lineHeight={FontSizeREM.PX17}
          marginBottom="0px"
        >
          {getMonthsInvestingText(formatMonthsToYearsAndMonths(monthsInvesting))}
        </P>
        <EditButton
          position="absolute"
          top="0"
          right="0"
          fontSize={FontSizeREM.PX14}
          lineHeight={FontSizeREM.PX17}
          onClick={goToContributionTimePage}
          data-testid="edit-contribution-time-btn"
        >
          Editar
        </EditButton>
      </Div>
      <InterUIBottomSheet
        title={bottomSheet.title}
        toggle={bottomSheet.show}
        onHide={() => setBottomSheet({ ...bottomSheet, show: false })}
      >
        {bottomSheet.items.map((item, index) => {
          const key = `paragraph-${index}`;
          return (
            <Div key={key}>
              <PSmallBold>{item.title}</PSmallBold>
              <PSmallGray>{item.description}</PSmallGray>
            </Div>
          );
        })}
        <InterUIButton
          type="button"
          onClick={() => {
            ProposalAnalyticsService.contributionTimeBottomSheetClick(type);
            setBottomSheet({ ...bottomSheet, show: false });
          }}
          margin="32px 0px 0px 0px"
        >
          Entendi
        </InterUIButton>
      </InterUIBottomSheet>
    </>
  );
};

export default DebitInfo;
