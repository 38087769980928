import { InterUIRadio } from '@interco/inter-ui-react-lib';
import React from 'react';
import { useTheme } from 'styled-components';
import FontSizeREM from '../../../../enums/fontSizesRem';
import { ExternalPensionPlan } from '../../../../store/portability/types';
import { ThemeInter } from '../../../../styles';
import { Flex, P } from '../../../../styles/commons';
import { formatToBRLCurrency } from '../../../../utils/numberFormatUtils';
import { Content, Header, OutlineCard } from './ExternalPensionPlanCard.styles';

interface IIExternalPensionPlanCardProps {
  externalPensionPlan: ExternalPensionPlan;
  selected: boolean;
  onSelect: (selectedExternalPensionPlan: ExternalPensionPlan) => void;
}
const ExternalPensionPlanCard: React.FC<IIExternalPensionPlanCardProps> = ({
  externalPensionPlan,
  selected,
  onSelect,
}) => {
  const theme = useTheme() as ThemeInter;

  return (
    <OutlineCard selected={selected} onClick={() => onSelect(externalPensionPlan)}>
      <Header>
        <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
          <P
            color={theme.colors.grayscale.A500}
            fontSize={FontSizeREM.PX18}
            fontWeight={700}
            lineHeight={FontSizeREM.PX21}
            margin="0"
          >
            {externalPensionPlan.name}
          </P>
          <InterUIRadio readOnly border="none" variant="default" checked={selected} padding="0" />
        </Flex>
      </Header>
      <Content backgroundColor={theme.colors.grayscale.A100}>
        <P
          color={theme.colors.grayscale.A500}
          fontSize={FontSizeREM.PX14}
          fontWeight={700}
          lineHeight={FontSizeREM.PX17}
          textTransform="capitalize"
        >
          {externalPensionPlan?.taxType?.toLocaleLowerCase()}
        </P>
        <Flex flexDirection="row" justifyContent="space-between">
          <P
            color={theme.colors.grayscale.A400}
            fontSize={FontSizeREM.PX12}
            fontWeight={400}
            lineHeight={FontSizeREM.PX14}
          >
            Valor acumulado
          </P>
          <P
            color={theme.colors.grayscale.A500}
            fontSize={FontSizeREM.PX14}
            fontWeight={700}
            lineHeight={FontSizeREM.PX17}
          >
            {externalPensionPlan?.accumulatedValues
              ? formatToBRLCurrency(externalPensionPlan.accumulatedValues)
              : ' - '}
          </P>
        </Flex>

        <Flex flexDirection="row" justifyContent="space-between">
          <P
            color={theme.colors.grayscale.A400}
            fontSize={FontSizeREM.PX12}
            fontWeight={400}
            lineHeight={FontSizeREM.PX14}
          >
            Número do certificado
          </P>
          <P
            color={theme.colors.grayscale.A400}
            fontSize={FontSizeREM.PX12}
            fontWeight={400}
            lineHeight={FontSizeREM.PX14}
          >
            {externalPensionPlan.certificateNumber}
          </P>
        </Flex>
      </Content>
    </OutlineCard>
  );
};

export default ExternalPensionPlanCard;
