/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  InterUIButton,
  InterUICheckBox,
  InterUIContainer,
  InterUIIcon,
  InterUILoading,
} from '@interco/inter-ui-react-lib';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import HireProposalAcceptanceAnalyticsService from '../../analytics/hireProposalAcceptanceAnalyticsService';
import { InvestmentFundCard } from '../../components';
import FontSizeREM from '../../enums/fontSizesRem';
import { PageRoutes } from '../../enums/pageRoutes';
import PageTitles from '../../enums/pageTitles';
import { TaxTypes } from '../../enums/privatePensions';
import ErrorActions from '../../store/error/actions';
import { HireProposalActions } from '../../store/hire/actions';
import { LoadingActions } from '../../store/loading/actions';
import NavbarActions from '../../store/navbar/actions';
import { PreviewContractActions } from '../../store/previewContract/actions';
import { ProposalDetailActions } from '../../store/proposal/actions';
import { ProposalTypes } from '../../store/proposal/types';
import TermsAndConditionsActions from '../../store/termAndCondition/actions';
import Colors from '../../styles/colors';
import { Flex, P, Separator, SeparatorDashed, Span } from '../../styles/commons';
import {
  formatMonthsToYearsAndMonths,
  getFirstDebitDate,
  getMonthsInvestingText,
  getSecondDebitDate,
  toIsoDateFormat,
} from '../../utils/dateTimeUtils';
import { formatToBRLCurrency } from '../../utils/numberFormatUtils';
import { IRouterStateProps } from '../../utils/routesUtils';
import { IReceiptPageProps } from '../CancelReceipt/CancelReceiptPage';

const cancelReceiptPageProps = {
  pageTitle: PageTitles.HIRE_PROPOSAL_CANCEL_RECEIPT,
  title: 'Cancelamento de contratação efetuado!',
  redirectTo: PageRoutes.HOME,
  text: `Feito! A solicitação de contratação de previdência privada foi cancelada. Caso queira
  realizar outra entre em contato com seu acessor de investimentos, ou com um de nossos canais
  de atendimento.`,
} as IReceiptPageProps;

const HireProposalAcceptancePage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pendingHireProposal } = useSelector(HireProposalActions.get);
  const loading = useSelector(LoadingActions.get);
  const isTermsAndConditionsAccepted = useSelector(
    TermsAndConditionsActions.getIsTermsAndConditionsAccepted,
  );

  const retrieveCustomerPendingHireProposal = useCallback(() => {
    dispatch(
      HireProposalActions.getCustomerPendingHireProposal(() =>
        history.push(PageRoutes.HOME, { data: { ignoreFetch: true } }),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const routerState = history.location.state as IRouterStateProps;
    if (routerState?.data?.deeplink) {
      retrieveCustomerPendingHireProposal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const firstDebitDayAsDate = getFirstDebitDate();

  const goToPreviewContractPage = () => {
    HireProposalAcceptanceAnalyticsService.genericButtonClick(
      'contractPreviewButton',
      'T_PreviaDoContra',
      {
        content_data: String(pendingHireProposal.paymentDay),
        content_fundo: pendingHireProposal.investmentFund.name,
        hasInsurance: !!(
          pendingHireProposal.monthlyContribution > 0 &&
          pendingHireProposal.insuranceCoverage !== undefined
        ),
        price_inicial: formatToBRLCurrency(pendingHireProposal.initialContribution),
        price_mensal: formatToBRLCurrency(pendingHireProposal.monthlyContribution),
        content_mod: pendingHireProposal.privatePensionType,
        content_trib: pendingHireProposal.taxType,
        content_tempo: String(pendingHireProposal.numberMonthsInvesting),
      },
      ProposalTypes.ADVISOR,
    );
    history.push(PageRoutes.PREVIEW_CONTRACT);
  };

  useEffect(() => {
    dispatch(NavbarActions.setTitle(PageTitles.HIRE_PROPOSAL_ACCEPTANCE));
    dispatch(PreviewContractActions.resetState());
    dispatch(ErrorActions.resetState());
  }, [dispatch]);

  const signAndGoToHireReceiptPage = () => {
    HireProposalAcceptanceAnalyticsService.genericButtonClick(
      'hireProposalButton',
      'T_Contratar',
      {
        content_data: String(pendingHireProposal.paymentDay),
        content_fundo: pendingHireProposal.investmentFund.name,
        hasInsurance: !!(
          pendingHireProposal.monthlyContribution > 0 &&
          pendingHireProposal.insuranceCoverage !== undefined
        ),
        price_inicial: formatToBRLCurrency(pendingHireProposal.initialContribution),
        price_mensal: formatToBRLCurrency(pendingHireProposal.monthlyContribution),
        content_mod: pendingHireProposal.privatePensionType,
        content_trib: pendingHireProposal.taxType,
        content_tempo: String(pendingHireProposal.numberMonthsInvesting),
      },
      ProposalTypes.ADVISOR,
    );
    if (pendingHireProposal) {
      const {
        chosenProposalId,
        privatePensionType,
        taxType,
        initialContribution,
        monthlyContribution,
        insuranceCoverage,
        paymentDay,
        investmentFund,
        numberMonthsInvesting,
      } = pendingHireProposal;
      dispatch(HireProposalActions.setChosenProposalId(chosenProposalId));
      dispatch(ProposalDetailActions.setPrivatePensionType(privatePensionType));
      dispatch(ProposalDetailActions.setTaxType(taxType));
      dispatch(ProposalDetailActions.setInitialContribution(initialContribution));
      dispatch(ProposalDetailActions.setMonthlyContribution(monthlyContribution));
      dispatch(ProposalDetailActions.setNumberMonthsInvesting(numberMonthsInvesting));
      dispatch(ProposalDetailActions.setFirstDebitDate(toIsoDateFormat(firstDebitDayAsDate)));
      dispatch(ProposalDetailActions.setPaymentDay(paymentDay));
      dispatch(ProposalDetailActions.setInvestimentFund(investmentFund));
      dispatch(
        ProposalDetailActions.setHasInsuranceCoverage(
          !!(monthlyContribution > 0 && insuranceCoverage),
        ),
      );
      if (insuranceCoverage) {
        dispatch(ProposalDetailActions.setInsuranceCoverage(insuranceCoverage));
      }
      dispatch(
        HireProposalActions.signPendingHireProposal(chosenProposalId, {
          history,
          pathname: PageRoutes.CONTRACTION_RECEIPT,
        }),
      );
    }
  };

  const cancelPendingHireProposal = () => {
    HireProposalAcceptanceAnalyticsService.genericButtonClick(
      'cancelHireProposalButton',
      'T_CancelaContratacaoPendente',
      {
        content_data: String(pendingHireProposal.paymentDay),
        content_fundo: pendingHireProposal.investmentFund.name,
        hasInsurance: !!(
          pendingHireProposal.monthlyContribution > 0 &&
          pendingHireProposal.insuranceCoverage !== undefined
        ),
        price_inicial: formatToBRLCurrency(pendingHireProposal.initialContribution),
        price_mensal: formatToBRLCurrency(pendingHireProposal.monthlyContribution),
        content_mod: pendingHireProposal.privatePensionType,
        content_trib: pendingHireProposal.taxType,
        content_tempo: String(pendingHireProposal.numberMonthsInvesting),
      },
      ProposalTypes.ADVISOR,
    );
    dispatch(
      HireProposalActions.cancelPendingHireProposal(
        pendingHireProposal.chosenProposalId,
        cancelReceiptPageProps,
        {
          history,
          pathname: PageRoutes.CANCELLATION_RECEIPT,
        },
      ),
    );
  };

  const stickyFooter: React.ReactElement = (
    <>
      <InterUIButton
        disabled={!isTermsAndConditionsAccepted}
        onClick={signAndGoToHireReceiptPage}
        margin="0 0 24px 0"
        loading={loading}
        data-testid="go-to-contraction-receipt-button"
      >
        Contratar previdência privada
      </InterUIButton>
      <InterUIButton
        onClick={cancelPendingHireProposal}
        loading={loading}
        data-testid="cancel-pending-hire-proposal-button"
      >
        Cancelar contratação
      </InterUIButton>
    </>
  );

  return !loading ? (
    <InterUIContainer stickyFooter={stickyFooter}>
      <InvestmentFundCard investmentFund={pendingHireProposal.investmentFund} />
      <SeparatorDashed marginTop="24px" marginBottom="24px" color={Colors.shuttleGrayDisabled} />
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={400}
        lineHeight={FontSizeREM.PX17}
        marginBottom="4px"
        color={Colors.shuttleGray}
      >
        Valor inicial
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={700}
        lineHeight={FontSizeREM.PX17}
        marginBottom="16px"
      >
        {formatToBRLCurrency(pendingHireProposal.initialContribution)}
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={400}
        lineHeight={FontSizeREM.PX17}
        marginBottom="4px"
        color={Colors.shuttleGray}
      >
        Valor a cada mês
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={700}
        lineHeight={FontSizeREM.PX17}
        marginBottom="16px"
      >
        {formatToBRLCurrency(pendingHireProposal.monthlyContribution)}
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={400}
        lineHeight={FontSizeREM.PX17}
        marginBottom="4px"
        color={Colors.shuttleGray}
      >
        Prazo de aposentadoria
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={700}
        lineHeight={FontSizeREM.PX17}
        marginBottom="16px"
      >
        {getMonthsInvestingText(
          formatMonthsToYearsAndMonths(pendingHireProposal.numberMonthsInvesting),
        )}
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={400}
        lineHeight={FontSizeREM.PX17}
        marginBottom="4px"
        color={Colors.shuttleGray}
      >
        Modalidade do plano
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={700}
        lineHeight={FontSizeREM.PX17}
        marginBottom="16px"
      >
        {pendingHireProposal.privatePensionType}
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={400}
        lineHeight={FontSizeREM.PX17}
        marginBottom="4px"
        color={Colors.shuttleGray}
      >
        Tabela de tributação
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={700}
        lineHeight={FontSizeREM.PX17}
        marginBottom="16px"
      >
        Tabela {pendingHireProposal.taxType === TaxTypes.PROGRESSIVE ? 'Progressiva' : 'Regressiva'}
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={400}
        lineHeight={FontSizeREM.PX17}
        marginBottom="4px"
        color={Colors.shuttleGray}
      >
        Primeiro débito
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={700}
        lineHeight={FontSizeREM.PX17}
        marginBottom="16px"
      >
        {firstDebitDayAsDate.toLocaleString('pt-BR', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })}
      </P>
      {pendingHireProposal.monthlyContribution > 0 && (
        <>
          <P
            fontSize={FontSizeREM.PX14}
            fontWeight={400}
            lineHeight={FontSizeREM.PX17}
            marginBottom="4px"
            color={Colors.shuttleGray}
          >
            Data do débito mensal
          </P>
          <P
            fontSize={FontSizeREM.PX14}
            fontWeight={700}
            lineHeight={FontSizeREM.PX17}
            marginBottom="16px"
          >
            {getSecondDebitDate(
              pendingHireProposal.firstDebitDate,
              pendingHireProposal.paymentDay,
            ).toLocaleString('pt-BR', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })}
          </P>
        </>
      )}
      {pendingHireProposal.monthlyContribution > 0 && pendingHireProposal.insuranceCoverage && (
        <>
          <P
            fontSize={FontSizeREM.PX14}
            fontWeight={400}
            lineHeight={FontSizeREM.PX17}
            marginBottom="4px"
            color={Colors.shuttleGray}
          >
            Seguro
          </P>
          <P
            fontSize={FontSizeREM.PX14}
            fontWeight={700}
            lineHeight={FontSizeREM.PX17}
            marginBottom="16px"
          >
            {formatToBRLCurrency(pendingHireProposal.insuranceCoverage.monthlyContributionValue)}{' '}
            por mês
          </P>
        </>
      )}
      <SeparatorDashed marginTop="24px" marginBottom="24px" color={Colors.shuttleGrayDisabled} />
      <Flex
        flexDirection="row"
        height="8.25vh"
        alignItems="center"
        marginBottom="24px"
        onClick={() => goToPreviewContractPage()}
        data-testid="preview-contract-button"
      >
        <InterUIIcon
          name="pdf-file"
          size="22px"
          color={Colors.interBlack}
          style={{ marginRight: '18px' }}
        />
        <Flex flexDirection="column" style={{ margin: '0 auto 0 0' }}>
          <P fontSize={FontSizeREM.PX12} lineHeight={FontSizeREM.PX15} marginBottom="2px">
            Prévia do contrato
          </P>
          <P
            fontSize={FontSizeREM.PX12}
            lineHeight={FontSizeREM.PX15}
            fontWeight={400}
            marginBottom="0px"
            color={Colors.shuttleGray}
          >
            Contrato do Fundo - Via Inter Seguros
          </P>
        </Flex>
        <InterUIIcon name="arrow-chevron-right" size="14px" color={Colors.interOrange} />
      </Flex>
      <Separator color={Colors.ghostWhite} height="1px" style={{ margin: '0 -24px' }} />
      <Flex flexDirection="row" alignItems="center" justifyContent="space-between">
        <P
          fontSize={FontSizeREM.PX12}
          lineHeight={FontSizeREM.PX18}
          marginBottom="0px"
          fontWeight={400}
        >
          Li e concordo com os{' '}
          <button type="button" onClick={goToPreviewContractPage}>
            <Span
              fontSize={FontSizeREM.PX12}
              lineHeight={FontSizeREM.PX15}
              fontWeight={700}
              color={Colors.interOrange}
            >
              Termos e Condições.
            </Span>
          </button>
        </P>
        <InterUICheckBox
          variant="default"
          defaultChecked={isTermsAndConditionsAccepted}
          height="22px"
          width="22px"
          onClick={() =>
            dispatch(
              TermsAndConditionsActions.setIsTermsAndConditionsAccepted(
                !isTermsAndConditionsAccepted,
              ),
            )
          }
        />
      </Flex>
    </InterUIContainer>
  ) : (
    <Flex height="100vh" justifyContent="center" alignItems="center">
      <InterUILoading size="ld" data-testid="loading" />
    </Flex>
  );
};

export default HireProposalAcceptancePage;
