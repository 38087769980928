/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
import { InterUILoading } from '@interco/inter-ui-react-lib';
import debounce from 'lodash.debounce';
import pdfjsWorker from 'pdfjs-dist/es5/build/pdf.worker.entry';
import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useTheme } from 'styled-components';
import { ThemeInter } from '../../styles';
import { Div, Flex } from '../../styles/commons';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

/**
 * Componente Inter UI PdfReader.
 * @param props Propriedades disponíveis para definição do layout.
 */

/**
 * Props disponíveis para o IInterUIPdfReaderProps.
 */ interface IInterUIPdfReaderProps {
  /**
   * Arquivo PDF.
   */
  file: any;
  /**
   * Largura da página do PDF.
   */
  width?: string;
  height?: string;
}

export const InterUIPdfReader: React.FC<
  IInterUIPdfReaderProps & React.HtmlHTMLAttributes<HTMLElement>
> = ({ file, width = '100%', height = '100%' }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageWidth, setPageWidth] = useState<number>();
  const [disablePdfPanning, setDisablePdfPanning] = useState(true);
  const pdfWrapperRef = useRef<HTMLDivElement>(null);
  const theme = useTheme() as ThemeInter;
  const onDocumentLoadSuccess = ({ numPages }: any) => setNumPages(numPages);

  const setDivSize = () => setPageWidth(pdfWrapperRef.current?.getBoundingClientRect().width);

  const loading = () => (
    <Flex alignItems="center" justifyContent="center" height="calc(100vh - 145px)" width="100vw">
      <InterUILoading halfColor={theme.colors.white} fullColor={theme.colors.primary.A400} />
    </Flex>
  );

  useEffect(() => {
    setDivSize();
    const setDivDebounce = debounce(setDivSize, 100);
    window.addEventListener('resize', setDivDebounce);
    return () => window.removeEventListener('resize', setDivDebounce);
  }, []);

  const handlePdfPinching = (ref: ReactZoomPanPinchRef) => {
    if (ref.state.scale > 1) {
      setDisablePdfPanning(false);
      return;
    }
    setDisablePdfPanning(true);
  };

  return (
    <Div
      width={width}
      height={height}
      overflowX="scroll"
      overflowY="scroll"
      ref={pdfWrapperRef}
      data-testid="pdf-container"
    >
      <TransformWrapper
        maxScale={3}
        onPinchingStop={handlePdfPinching}
        panning={{ disabled: disablePdfPanning }}
      >
        <TransformComponent>
          <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={loading}
            noData={loading}
            renderMode="svg"
          >
            {Array.from(new Array(numPages), (_, index) => (
              <Page
                width={pageWidth}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderMode="svg"
              />
            ))}
          </Document>
        </TransformComponent>
      </TransformWrapper>
    </Div>
  );
};
