import styled from 'styled-components';
import Colors from '../../styles/colors';
import { Div } from '../../styles/commons';

export const ScaleIndicator = styled(Div)<{
  leftRadius?: boolean;
  rightRadius?: boolean;
  marginLeft?: string;
  marginRight?: string;
}>`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : Colors.interBlack};
  margin: 0px 1px;
  height: 8px;
  width: 100%;

  ${(props) => props.leftRadius && `border-radius: 8px 0px 0px 8px;`}
  ${(props) => props.rightRadius && `border-radius: 0px 8px 8px 0px;`}
  ${(props) => props.marginLeft && `margin-left: ${props.marginLeft};`}
  ${(props) => props.marginRight && `margin-right: ${props.marginRight};`}
`;
