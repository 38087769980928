import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ErrorActions from '../../../store/error/actions';
import { Div } from '../../../styles/commons';

const ErrorObserver: React.FC = () => {
  const { hasError, errorInstance } = useSelector(ErrorActions.get);

  useEffect(() => {
    if (hasError) {
      throw errorInstance;
    }
  }, [errorInstance, hasError]);

  return <Div />;
};

export default ErrorObserver;
