/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/unbound-method */
import { AnyAction } from 'redux';
import { call, put, SagaGenerator, select, takeLeading } from 'typed-redux-saga';
import { EnvVariableKeys } from '../../enums/evironmentVariableKeys';
import { LocalStorageKeys } from '../../enums/localStorageKeys';
import { PageRoutes } from '../../enums/pageRoutes';
import TermsTypes from '../../enums/signedTerms';
import { EnvVariableService } from '../../services/environmentVariableService';
import LocalStorageService, { LocalStorageRequest } from '../../services/localStorageService';
import { MonitoringService } from '../../services/monitoringService';
import UserBalanceInfoService from '../../services/userBalanceInfoService';
import UserHasPrivatePensionService from '../../services/userHasPrivatePensionService';
import UserSignedTermsService from '../../services/userSignedTermsService';
import { errorHandlingSaga, redirectThroughSaga } from '../../utils/providerSaga';
import { LoadingActions } from '../loading/actions';
import UserInfoActions from './actions';
import { IMissingTerms, ITerms, IUserSignedTermsInfo, UserInfoTypes } from './types';

const IS_NOT_RUNNING_HML = EnvVariableService.getVariableAsBoolean(EnvVariableKeys.INTER_ENV);

export interface IUserSignedTerms {
  missingTerms: IMissingTerms;
  isAcceptable?: boolean;
  isQualifiedInvestor: boolean;
}

/**
 *
 * @param terms Array of signed terms
 * @param termsTypes type of signed term
 * @returns Return true if the terms type are signed, otherwise false
 */
const hasTermsAssignedByType = (terms: Array<ITerms>, termsTypes: TermsTypes): boolean => {
  if (terms) {
    return !!terms.find(({ assinado, tipo }) => assinado && tipo === termsTypes);
  }
  return false;
};

export const handleUserSignedTerms = (
  investorProfile: string | null,
  terms: Array<ITerms>,
): IUserSignedTerms => {
  const missingTerms: IMissingTerms = {
    isSuitabilityMissing: false,
    isInvestorProfileMissing: false,
  };

  let isAcceptable = false;
  const isQualifiedInvestor = hasTermsAssignedByType(terms, TermsTypes.QUALIFIED);
  const suitabilityTerm = hasTermsAssignedByType(terms, TermsTypes.SUITABILITY);

  if (!suitabilityTerm) {
    missingTerms.isSuitabilityMissing = true;
  }

  if (investorProfile === null) {
    missingTerms.isInvestorProfileMissing = true;
  }

  if (
    missingTerms.isSuitabilityMissing === false &&
    missingTerms.isInvestorProfileMissing === false
  ) {
    isAcceptable = true;
  }

  return {
    missingTerms,
    isAcceptable,
    isQualifiedInvestor,
  };
};

function* requestUserBalanceInfo({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show());

    const { account, cpf } = yield* select(UserInfoActions.get);

    const response = yield* call(UserBalanceInfoService.requestUserBalanceInfo, account, cpf);

    const { bloqueadoCheque, bloqueadoJudicialmente, disponivel } = response.data as any;
    yield* put(
      UserInfoActions.setUserBalanceInfo({
        blockedByCheck: bloqueadoCheque,
        judiciallyBlocked: bloqueadoJudicialmente,
        avaliable: disponivel,
      }),
    );

    yield put(LoadingActions.hide());
    redirectThroughSaga(payload);
  } catch (error) {
    const { history } = payload;
    yield* put(
      UserInfoActions.setUserBalanceInfo({
        blockedByCheck: undefined,
        judiciallyBlocked: undefined,
        avaliable: undefined,
      }),
    );
    history.push(PageRoutes.ADDITIONAL_CONTRIBUTION_PROPOSAL);
    yield put(LoadingActions.hide());
  }
}

function* requestHasPrivatePension({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show());
    const { account } = yield* select(UserInfoActions.get);

    const response = yield* call(UserHasPrivatePensionService.requestHasPrivatePension);
    const { hasPrivatePension } = response.data;

    const expiresAt = new Date();
    expiresAt.setDate(expiresAt.getDate() + 1);
    expiresAt.setHours(0, 0, 0, 0);

    const userHasPrivatePension: LocalStorageRequest<boolean> = {
      data: hasPrivatePension,
      requestDate: new Date(),
      expiresAt,
    };

    LocalStorageService.setItem(
      LocalStorageKeys.USER_HAS_PRIVATE_PENSION,
      userHasPrivatePension,
      account,
    );

    yield* put(LoadingActions.hide());

    redirectThroughSaga(payload);
  } catch (error: any) {
    yield* errorHandlingSaga(error, 'requestHasPrivatePension');
  }
}

function* requestUserSignedTerms({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.setToggleOn());

    const { account } = yield* select(UserInfoActions.get);

    const response = yield* call(UserSignedTermsService.requestSignedTerms, account);

    if (response.data && Object.keys(response.data).length > 0) {
      const { perfilInvestidor, termos } = response.data;

      const { isAcceptable, missingTerms, isQualifiedInvestor } = handleUserSignedTerms(
        perfilInvestidor,
        termos,
      );

      yield* put(
        UserInfoActions.setUserSignedTerms({
          investorProfile: perfilInvestidor,
          terms: termos,
          isAcceptable,
          whichIsMissing: missingTerms,
          isQualified: isQualifiedInvestor,
        }),
      );

      // If the user has the properly signed terms, and if the environment is different from staging.
      // we are going to save this data in our local storage.

      if (isAcceptable) {
        if (IS_NOT_RUNNING_HML) {
          const expiresAt = new Date();
          expiresAt.setDate(expiresAt.getDate() + 7);
          expiresAt.setHours(0, 0, 0, 0);

          const userSignedTermsInfo: LocalStorageRequest<IUserSignedTermsInfo> = {
            data: response.data,
            requestDate: new Date(),
            expiresAt,
          };

          LocalStorageService.setItem(
            LocalStorageKeys.USER_SIGNED_TERMS,
            userSignedTermsInfo,
            account,
          );
        }

        redirectThroughSaga(payload);
      } else {
        payload.history.push(PageRoutes.HOME);
      }

      yield put(LoadingActions.hide());
    } else {
      throw new Error(
        `UserSignedTermsService returned a invalid response: ${JSON.stringify(response)}`,
      );
    }
  } catch (error: any) {
    // In case of an error:
    // We are pushing to the pathname, from payload, because this saga is used in different places

    MonitoringService.noticeError(error, {
      errorCodeRef: 'requestUserSignedTermsSaga',
    });

    const { history, pathname } = payload;
    history.push(pathname);
    yield put(LoadingActions.hide());
  }
}

export default function* watchUserInfo(): SagaGenerator<void> {
  yield* takeLeading(UserInfoTypes.REQUEST_USER_BALANCE_INFO, requestUserBalanceInfo);
  yield* takeLeading(UserInfoTypes.REQUEST_USER_HAS_PRIVATE_PENSION, requestHasPrivatePension);
  yield* takeLeading(UserInfoTypes.REQUEST_USER_SIGNED_TERMS, requestUserSignedTerms);
}
