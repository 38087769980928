/* eslint-disable react/no-array-index-key */
import { InterUIBottomSheet, InterUIButton, InterUIIcon } from '@interco/inter-ui-react-lib';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import PortabilityAnalyticsService from '../../analytics/portabilityAnalyticsService';
import { LocalStorageKeys } from '../../enums/localStorageKeys';
import { PageRoutes } from '../../enums/pageRoutes';
import PageTitles from '../../enums/pageTitles';
import { PrivatePensions } from '../../enums/privatePensions';
import LocalStorageService from '../../services/localStorageService';
import { LoadingActions } from '../../store/loading/actions';
import NavbarActions from '../../store/navbar/actions';
import PortabilityActions from '../../store/portability/actions';
import { ExternalPensionPlan, ExternalPensionPlanStatus } from '../../store/portability/types';
import { ProposalDetailActions } from '../../store/proposal/actions';
import UserInfoActions from '../../store/user/actions';
import { ThemeInter } from '../../styles';
import { Div, H1, P, PSmall, PSmallGray } from '../../styles/commons';
import { InterContainerNoFooter } from '../../styles/inter-ui-customizations';
import ExternalPensionPlanList from './components/ExternalPensionPlanList/ExternalPensionPlanList';

interface IBottomSheetState {
  show: boolean;
  title: string;
  description: string[];
}

type BottomSheetInfoType = 'VGBL' | 'PGBL';

const PortabilityExternalPensionPlansPage: React.FC = () => {
  const theme = useTheme() as ThemeInter;
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(LoadingActions.get);
  const [statusBottomSheet, setStatusBottomSheet] = useState<boolean>(false);
  const [emptyBottomSheet, setEmptyBottomSheet] = useState<boolean>(false);

  const [bottomSheet, setBottomSheet] = useState<IBottomSheetState>({
    show: false,
    title: '',
    description: [''],
  });

  const [errorStatus, setErrorStatus] = useState<ExternalPensionPlanStatus>();
  const { account } = useSelector(UserInfoActions.get);
  const selectedExternalPensionPlan = useSelector(PortabilityActions.getExternalPensionPlan);
  const [selectedPlan, setSelectedPlan] = useState<ExternalPensionPlan | null>(
    selectedExternalPensionPlan,
  );

  const VGBL_INFO = {
    title: 'VGBL',
    description: [
      'Ideal para quem faz a declaração simplificada do Imposto de Renda.',
      'O IR incide apenas sobre seus rendimentos, e só quando você resgata, lá na frente.',
    ],
  };

  const PGBL_INFO = {
    title: 'PGBL',
    description: [
      'Ideal para quem faz declaração completa do Imposto de Renda e quer investir 12% do total da renda tributável na previdência.',
      'Com o desconto no IR, o que você investiu na previdência (até 12%) é abatido da base de cálculo.',
    ],
  };

  const text = {
    title: 'Qual Previdência você deseja portar?',
    message: 'Selecione uma das opções abaixo',
    emptyMessage: 'Não identificamos previdências para essa modalidade.',
  };

  const getLocalStorageItem = () =>
    LocalStorageService.getItem<ExternalPensionPlan[]>(
      LocalStorageKeys.EXTERNAL_PENSION_PLANS,
      account,
    );

  const filterByPlanType = (
    planType: PrivatePensions,
    list: ExternalPensionPlan[] = [],
  ): ExternalPensionPlan[] =>
    list.filter((item: ExternalPensionPlan) => item.planType === planType);

  const dataFromLocalStorage = getLocalStorageItem();

  const PGBLExternalPensionPlans = useMemo(
    () => filterByPlanType(PrivatePensions.PGBL, dataFromLocalStorage?.data),
    // Stryker disable next-line all
    [dataFromLocalStorage],
  );

  const VGBLExternalPensionPlans = useMemo(
    () => filterByPlanType(PrivatePensions.VGBL, dataFromLocalStorage?.data),
    // Stryker disable next-line all
    [dataFromLocalStorage],
  );

  const onSelect = (externalensionPlan: ExternalPensionPlan) => {
    if (externalensionPlan.status === ExternalPensionPlanStatus.OK) {
      setSelectedPlan(externalensionPlan);
    } else {
      setErrorStatus(externalensionPlan.status);
      setStatusBottomSheet(true);
    }
  };

  const VGBLExternalPensionPlansList: React.FC = () => {
    if (VGBLExternalPensionPlans.length > 0) {
      return (
        <ExternalPensionPlanList
          list={VGBLExternalPensionPlans}
          selected={selectedPlan}
          onSelect={(externalensionPlan) => onSelect(externalensionPlan)}
        />
      );
    }
    return <PSmallGray textAlign="center">{text.emptyMessage}</PSmallGray>;
  };

  const PGBLExternalPensionPlansList: React.FC = () => {
    if (PGBLExternalPensionPlans.length > 0) {
      return (
        <ExternalPensionPlanList
          list={PGBLExternalPensionPlans}
          selected={selectedPlan}
          onSelect={(externalensionPlan) => onSelect(externalensionPlan)}
        />
      );
    }
    return <PSmallGray textAlign="center">{text.emptyMessage}</PSmallGray>;
  };

  const getBottomSheetMessageByPlanStatus = (status: ExternalPensionPlanStatus) => {
    switch (status) {
      case ExternalPensionPlanStatus.INCOMPLETED_DATA:
        return <>Os dados para essa previdência estão incompletos</>;
      default:
        return <>A portabilidade para essa previdência está em processamento</>;
    }
  };

  const goToPortabilityAccumulatedValue = () => {
    setStatusBottomSheet(false);
    history.push(PageRoutes.PORTABILITY_ACCUMULATED_VALUE);
  };

  const getBottomSheetBtnsByPlanStatus = (status: ExternalPensionPlanStatus) => {
    switch (status) {
      case ExternalPensionPlanStatus.INCOMPLETED_DATA:
        return (
          <>
            <InterUIButton
              type="button"
              onClick={() => goToPortabilityAccumulatedValue()}
              margin="32px 0px 0px 0px"
            >
              Continuar de forma manual
            </InterUIButton>
            <InterUIButton
              type="button"
              onClick={() => setStatusBottomSheet(false)}
              margin="16px 0px 0px 0px"
              variant="secondary"
            >
              Entendi
            </InterUIButton>
          </>
        );
      default:
        return (
          <InterUIButton
            type="button"
            onClick={() => setStatusBottomSheet(false)}
            margin="32px 0px 0px 0px"
          >
            Entendi
          </InterUIButton>
        );
    }
  };

  const handlerBottomSheetCloseButton = (
    bottomSheetParams: IBottomSheetState,
    show: boolean,
  ): void => {
    setBottomSheet({ ...bottomSheetParams, show });
  };

  const callBottomSheetInfo = (type: BottomSheetInfoType) => {
    switch (type) {
      case 'PGBL': {
        setBottomSheet({ show: true, ...PGBL_INFO });
        break;
      }
      default: {
        setBottomSheet({ show: true, ...VGBL_INFO });
        break;
      }
    }
  };

  const onSubmit = () => {
    dispatch(PortabilityActions.setExternalPensionPlan(selectedPlan));
    if (selectedPlan) {
      PortabilityAnalyticsService.externalPensionPlanContinueButton(
        selectedPlan.planType,
        selectedPlan.taxType,
      );
    }
    dispatch(
      ProposalDetailActions.requestPortabilityProposal({
        history,
        pathname: PageRoutes.PROPOSAL_BY_PORTABILITY,
      }),
    );
  };

  useEffect(
    () => {
      setEmptyBottomSheet(
        PGBLExternalPensionPlans.length === 0 && VGBLExternalPensionPlans.length === 0,
      );
    }, // Stryker disable next-line all
    [PGBLExternalPensionPlans.length, VGBLExternalPensionPlans.length],
  );

  useEffect(
    () => {
      dispatch(NavbarActions.setTitle(PageTitles.PORTABILITY_EXTERNAL_PENSION_PLAN));
    }, // Stryker disable next-line all
    [dispatch],
  );

  return (
    <InterContainerNoFooter margin="24px">
      <H1 color={theme.colors.grayscale.A500}>{text.title}</H1>
      <PSmall color={theme.colors.grayscale.A400} margin="8px 0 32px">
        {text.message}
      </PSmall>

      <P color={theme.colors.grayscale.A400} margin="16px 0">
        VGBL
        <button
          type="button"
          style={{ verticalAlign: 'middle', marginLeft: '6px' }}
          onClick={() => callBottomSheetInfo('VGBL')}
          aria-label="O que é a Previdência VGBL?"
        >
          <InterUIIcon name="help" color={theme.colors.primary.A500} size="16px" />
        </button>
      </P>
      <VGBLExternalPensionPlansList />

      <P color={theme.colors.grayscale.A400} margin="16px 0">
        PGBL
        <button
          type="button"
          style={{ verticalAlign: 'middle', marginLeft: '6px' }}
          onClick={() => callBottomSheetInfo('PGBL')}
          aria-label="O que é a Previdência PGBL?"
        >
          <InterUIIcon name="help" color={theme.colors.primary.A500} size="16px" />
        </button>
      </P>
      <PGBLExternalPensionPlansList />

      <Div
        position="fixed"
        left="0px"
        bottom="0px"
        width="100%"
        backgroundColor={theme.colors.white}
      >
        <InterUIButton
          type="button"
          loading={loading}
          disabled={!selectedPlan}
          onClick={onSubmit}
          margin="24px"
        >
          Continuar
        </InterUIButton>
      </Div>
      <Div height="72px" />

      <InterUIBottomSheet
        onHide={() => {
          setStatusBottomSheet(false);
        }}
        title="Algo inesperado ocorreu"
        toggle={statusBottomSheet}
      >
        <PSmallGray>{errorStatus && getBottomSheetMessageByPlanStatus(errorStatus)}</PSmallGray>

        {errorStatus && getBottomSheetBtnsByPlanStatus(errorStatus)}
      </InterUIBottomSheet>

      <InterUIBottomSheet
        title={bottomSheet.title}
        toggle={bottomSheet.show}
        onHide={() => handlerBottomSheetCloseButton({ ...bottomSheet }, false)}
      >
        {bottomSheet.description.map((paragraph, index) => (
          <PSmallGray key={`paragraph-${index}`}>{paragraph}</PSmallGray>
        ))}
        <InterUIButton
          type="button"
          onClick={() => handlerBottomSheetCloseButton({ ...bottomSheet }, false)}
          margin="32px 0px 0px 0px"
        >
          Entendi
        </InterUIButton>
      </InterUIBottomSheet>

      <InterUIBottomSheet
        onHide={() => {
          setEmptyBottomSheet(true);
        }}
        title="Não encontramos Previdências"
        toggle={emptyBottomSheet}
      >
        <PSmallGray>
          No momento não conseguimos coletar informações sobre a sua previdência na sua instituição
          financeira. Mas você pode continuar com a portabilidade de forma manual.
        </PSmallGray>

        <InterUIButton
          type="button"
          onClick={() => {
            setEmptyBottomSheet(false);
            history.push(PageRoutes.PORTABILITY_ACCUMULATED_VALUE);
          }}
          margin="32px 0px 0px 0px"
        >
          Continuar
        </InterUIButton>
        <InterUIButton
          type="button"
          onClick={() => {
            setEmptyBottomSheet(false);
            history.push(PageRoutes.HOME);
          }}
          margin="16px 0px 0px 0px"
          variant="secondary"
        >
          Voltar para home
        </InterUIButton>
      </InterUIBottomSheet>
    </InterContainerNoFooter>
  );
};

export default PortabilityExternalPensionPlansPage;
