import { InterUIIcon } from '@interco/inter-ui-react-lib';
import React from 'react';
import FontSizeREM from '../../../enums/fontSizesRem';
import Colors from '../../../styles/colors';
import { Flex, P, Span } from '../../../styles/commons';
import { formatToPercent } from '../../../utils/numberFormatUtils';
import { AdministrationFee } from './CardProfitability.styles';

export interface ICardProfitabilityProps {
  monthlyProfitability: number | null;
  yearlyProfitability: number | null;
  lastTwelveMonthsProfitability: number | null;
  minimumAdministrationFee: number;
  maximumAdministrationFee: number;
}

interface IColumnCardProps {
  profitability: number | null;
  label: string;
}

const ColumnCard: React.FC<IColumnCardProps> = ({ profitability, label }) => {
  const getProfitabilityStatus = () => {
    if (profitability === 0) {
      return (
        <Span fontSize={FontSizeREM.PX12} fontWeight={600}>
          {formatToPercent(profitability)}
        </Span>
      );
    }

    if (profitability && profitability > 0) {
      return (
        <Span color={Colors.pigmentGreen} fontSize={FontSizeREM.PX12} fontWeight={600}>
          {formatToPercent(profitability)}
        </Span>
      );
    }

    if (profitability && profitability < 0) {
      return (
        <Span color={Colors.bitterSweet} fontSize={FontSizeREM.PX12} fontWeight={600}>
          {formatToPercent(profitability)}
        </Span>
      );
    }

    return <Span>-</Span>;
  };
  return (
    <>
      <P
        fontSize={FontSizeREM.PX12}
        lineHeight={FontSizeREM.PX14}
        fontWeight={400}
        color={Colors.shuttleGray}
      >
        {label}
      </P>
      <P fontSize={FontSizeREM.PX14} lineHeight={FontSizeREM.PX17} fontWeight={700}>
        {profitability ? (
          <InterUIIcon
            name={`arrow-${profitability > 0 ? 'up' : 'down'}`}
            size={FontSizeREM.PX12}
            color={profitability > 0 ? Colors.pigmentGreen : Colors.bitterSweet}
          />
        ) : (
          ''
        )}{' '}
        {getProfitabilityStatus()}
      </P>
    </>
  );
};

const CardProfitability: React.FC<ICardProfitabilityProps> = ({
  monthlyProfitability,
  yearlyProfitability,
  lastTwelveMonthsProfitability,
  minimumAdministrationFee,
  maximumAdministrationFee,
}) => (
  <Flex flexDirection="row" justifyContent="space-between" padding="16px 18px">
    <Flex flexDirection="column">
      <ColumnCard label="No mês" profitability={monthlyProfitability} />
    </Flex>
    <Flex flexDirection="column">
      <ColumnCard label="No ano" profitability={yearlyProfitability} />
    </Flex>
    <Flex flexDirection="column">
      <ColumnCard label="12 meses" profitability={lastTwelveMonthsProfitability} />
    </Flex>
    <Flex flexDirection="column">
      <P
        fontSize={FontSizeREM.PX12}
        lineHeight={FontSizeREM.PX14}
        fontWeight={400}
        color={Colors.shuttleGray}
      >
        Taxa adm mín/máx
      </P>
      <AdministrationFee fontSize={FontSizeREM.PX12}>
        {formatToPercent(minimumAdministrationFee)}/{formatToPercent(maximumAdministrationFee)}
      </AdministrationFee>
    </Flex>
  </Flex>
);

export default CardProfitability;
