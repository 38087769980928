import { useEffect, useState } from 'react';

export function useNewTaxableBase(
  initialTaxableBase: number,
  annualIncome: number,
  totalDeduction: number,
): number {
  const [newTaxableBase, setNewTaxableBase] = useState(initialTaxableBase);

  useEffect(() => {
    setNewTaxableBase(annualIncome - totalDeduction);
  }, [annualIncome, totalDeduction]);

  return newTaxableBase;
}
