import { ContributionStrategyType } from '../pages/IncomeTax/components/ContributionStrategy/ContributionStrategy';
import { ProposalTypes } from '../store/proposal/types';

export function handlerProposalType(proposalType: ProposalTypes): string {
  switch (proposalType) {
    case ProposalTypes.BUY_GOOD:
      return 'Compra de um bem';
    case ProposalTypes.DEEPLINK:
      return 'Deeplink';
    case ProposalTypes.INCOME_TAX:
      return 'IR';
    default:
      return 'Aposentadoria';
  }
}

export function handlerContributionStrategy(strategy: string): string {
  switch (strategy) {
    case ContributionStrategyType.INITIAL_CONTRIBUTION:
      return 'Valor único';
    case ContributionStrategyType.MONTHLY_CONTRIBUTION:
      return 'Valor mensal';
    default:
      return 'Valor inicial + mensal';
  }
}
