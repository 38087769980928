import styled from 'styled-components';

export const OutlineCard = styled.div<{
  selected?: boolean;
}>`
  border: 1px solid
    ${(props) =>
      props.selected ? props.theme.colors.primary.A300 : props.theme.colors.grayscale.A200};
  box-sizing: border-box;
  border-radius: 8px;
  margin: 8px 0 8px 0;
`;
