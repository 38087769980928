/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { InterUIRadio } from '@interco/inter-ui-react-lib';
import { IInterUIRadioProps } from '@interco/inter-ui-react-lib/dist/interfaces/inter-ui-radio-props';
import React from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import FontSizeREM from '../../enums/fontSizesRem';
import { PSmallBold, PSmallGray } from '../../styles/commons';

type RadioType = 'default' | 'radio-top' | 'choose-item';

interface ILabelOptions {
  margin?: string;
  maxWidth?: string;
}

interface IRadioLabel {
  value: string;
  options?: ILabelOptions;
}

interface IRadioWithHookProps extends IInterUIRadioProps {
  name: string;
  subtitle?: string;
  border?: 'none';
  variant?: RadioType;
  label?: string | IRadioLabel;
  register?: UseFormRegister<any>;
  setValue?: UseFormSetValue<any>;
}

type RadioProps = React.InputHTMLAttributes<HTMLInputElement> &
  React.TextareaHTMLAttributes<HTMLTextAreaElement> &
  IRadioWithHookProps;

export const RadioWithHook: React.FC<RadioProps> = ({
  variant = 'default',
  margin,
  label,
  name,
  subtitle,
  register = (data: any) => {},
  setValue = (data: any) => {},
  ...props
}) => {
  const labelOptions = typeof label !== 'string' ? label?.options : undefined;

  return (
    <InterUIRadio
      {...register(name)}
      {...props}
      data-testid={name}
      variant={variant}
      margin={margin}
      onChange={(e) => {
        setValue(name, e.target.value, {
          shouldValidate: true,
        });
      }}
    >
      <PSmallBold margin={labelOptions?.margin || '0'} maxWidth={labelOptions?.maxWidth}>
        {typeof label === 'string' ? label : label?.value}
      </PSmallBold>
      {subtitle && (
        <PSmallGray
          maxWidth="183px"
          fontSize={FontSizeREM.PX12}
          lineHeight={FontSizeREM.PX15}
          marginBottom="0px"
        >
          {subtitle}
        </PSmallGray>
      )}
    </InterUIRadio>
  );
};
