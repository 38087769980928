/**
 * Aplica a máscara monetária no elemento input informado.
 *
 * @param value Valor a ser aplicado a formatação.
 * @param locale Padrão para formatação ('pt-BR' para formatação padrão Brasil).
 *
 * @returns void
 *
 * @example
 * currencyMask(e.target.value, 2, 'pt-BR');
 */
export const currencyMask = (value: string, fractionDigits = 2, locale = 'pt-BR'): string => {
  let formattedValue = Intl.NumberFormat(locale, {
    minimumFractionDigits: fractionDigits,
  }).format(parseInt(value.replace(/[\D]+/g, '')) / 100);

  if (formattedValue === 'NaN') {
    formattedValue = '';
  }

  return formattedValue;
};
