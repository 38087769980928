import styled, { keyframes } from 'styled-components';

const iconAnimation = (position?: number) => keyframes`
0% {top: 0px;}
5% {top: ${(position === 1 && `-4px`) || `0px`};}
10% {top: 0px;}
15% {top: 0px;}
20% {top: ${(position === 2 && `-2px`) || `0px`};}
25% {top: 0px;}
30% {top: ${(position === 3 && `-2px`) || `0px`};}
35% {top: 0px;}
40% {top: ${(position === 4 && `-2px`) || `0px`};}
45% {top: 0px;}
50% {top: ${(position === 5 && `-4px`) || `0px`};}
55% {top: 0px;}
60% {top: ${(position === 6 && `-2px`) || `0px`};}
65% {top: 0px;}
70% {top: ${(position === 7 && `-2px`) || `0px`};}
75% {top: 0px;}
80% {top: ${(position === 8 && `-2px`) || `0px`};}
85% {top: 0px;}
90% {top: 0px;}
95% {top: ${(position === 9 && `-4px`) || `0px`};}
100% {top: 0px;}
`;

export const Loading = styled.div<{ position?: number; loading?: string }>`
  position: relative;
  animation-name: ${(props) => props.position && iconAnimation(props.position)};
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-play-state: ${(props) => (props.loading === 'true' ? `running` : `paused`)};
`;

export const Dot = styled.div<{ color?: string }>`
  height: 4px;
  width: 4px;
  border-radius: 50%;
  ${(props) => props.color && `background-color: ${props.color};`}
`;
