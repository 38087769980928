import { InterUIButton, InterUIContainer } from '@interco/inter-ui-react-lib';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import belvoPrivacyPolicy from '../../assets/belvoPreviewPrivacyPolicy/belvo_privacy_policy.pdf';
import { InterUIPdfReader } from '../../components/InterUIPdfReader/InterUIPdfReader';
import PageTitles from '../../enums/pageTitles';
import { LoadingActions } from '../../store/loading/actions';
import NavbarActions from '../../store/navbar/actions';
import PortabilityActions from '../../store/portability/actions';
import { Div } from '../../styles/commons';

const PortabilityPreviewPrivacyPolicyPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const loading = useSelector(LoadingActions.get);

  const goBack = () => {
    dispatch(PortabilityActions.setIsPrivacyPolicyAccepted(true));
    history.goBack();
  };

  const stickyFooter = (
    <InterUIButton type="button" margin="24px" disabled={loading} onClick={() => goBack()}>
      Entendi
    </InterUIButton>
  );

  useEffect(
    () => {
      dispatch(NavbarActions.setTitle(PageTitles.PREVIEW_PRIVACY_POLICY));
    },
    // Stryker disable next-line all
    [dispatch],
  );

  return (
    <InterUIContainer stickyFooter={stickyFooter} margin="0px">
      <Div height="calc(100vh - 152px)">
        <InterUIPdfReader file={belvoPrivacyPolicy} />
      </Div>
    </InterUIContainer>
  );
};

export default PortabilityPreviewPrivacyPolicyPage;
