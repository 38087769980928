/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { InterUIButton, InterUIContainer } from '@interco/inter-ui-react-lib';
import React, { useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import AdditionalContributionAnalyticsService from '../../analytics/additionalContributionAnalyticsService';
import SuccessImg from '../../assets/success/Success.png';
import FontSizeREM from '../../enums/fontSizesRem';
import { PageRoutes } from '../../enums/pageRoutes';
import PageTitles from '../../enums/pageTitles';
import BridgeService from '../../services/bridgeService';
import ErrorActions from '../../store/error/actions';
import NavbarActions from '../../store/navbar/actions';
import PrivatePensionPlanActions from '../../store/privatePensionPlan/actions';
import { ThemeInter } from '../../styles';
import { Div, Flex, H1, H3, P, Separator, SeparatorDashed } from '../../styles/commons';
import { convertDateToStringPtBR, getFirstDebitDate } from '../../utils/dateTimeUtils';
import { _try } from '../../utils/errorUtils';
import { formatToBRLCurrency, formatToPercent } from '../../utils/numberFormatUtils';

const AdditionalContributionReceiptPage: React.FC = () => {
  const dispatch = useDispatch();
  const handleError = useErrorHandler();
  const history = useHistory();
  const theme = useTheme() as ThemeInter;
  const { privatePensionPlan, additionalContribution } = useSelector(PrivatePensionPlanActions.get);

  const additionalContributionId = useSelector(
    PrivatePensionPlanActions.getAdditionalContributionId,
  );

  useEffect(() => {
    dispatch(NavbarActions.setTitle(PageTitles.CONTRACTION_RECEIPT));
    dispatch(ErrorActions.resetState());
  }, [dispatch]);

  useEffect(() => {
    AdditionalContributionAnalyticsService.receiptPage(
      privatePensionPlan.investmentFund?.name || '',
      privatePensionPlan.privatePensionType || '',
      String(additionalContribution) || '',
    );
  }, []);

  const goToAppHome = () =>
    _try(
      async () => {
        await BridgeService.goToAppHomeOrBackToNative(history, PageRoutes.HOME);
      },
      handleError,
      'AdditionalContributionReceiptPage.goToAppHome',
    );

  const stickyFooter = (
    <>
      <InterUIButton margin="0 0 16px 0" onClick={() => goToAppHome()}>
        Voltar para home
      </InterUIButton>
    </>
  );

  return (
    <InterUIContainer stickyFooter={stickyFooter}>
      <Flex alignItems="center" marginBottom="32px">
        <img style={{ width: '64px', height: '64px' }} src={SuccessImg} alt="Símbolo de sucesso" />
      </Flex>
      <Div>
        <H1 marginBottom="8px">Estamos processando seu investimento!</H1>
        <P
          fontSize={FontSizeREM.PX14}
          lineHeight={FontSizeREM.PX17}
          fontWeight={400}
          color={theme.colors.grayscale.A400}
          marginBottom="24px"
        >
          Seu investimento está em processamento e será concluído em breve. O valor será debitado da
          sua conta Inter no dia {convertDateToStringPtBR(getFirstDebitDate())}, ou seja, no
          primeiro dia útil após sua solicitação.
        </P>
        <P
          fontWeight={400}
          fontSize={FontSizeREM.PX14}
          lineHeight={FontSizeREM.PX17}
          color={theme.colors.grayscale.A400}
          marginBottom="16px"
        >
          Número da sua solicitação: <strong>{additionalContributionId}</strong>
        </P>
      </Div>
      <Div width="100vw" margin="24px 0px 24px -24px">
        <Separator
          marginTop="32px"
          marginBottom="32px"
          color={theme.colors.grayscale.A100}
          height="8px"
        />
      </Div>
      <Flex justifyContent="center" alignItems="center" flexDirection="column">
        <H3 marginBottom="4px">Valor do investimento</H3>
        <P fontSize={FontSizeREM.PX24} lineHeight={FontSizeREM.PX30}>
          {formatToBRLCurrency(+additionalContribution!)}
        </P>
      </Flex>

      <SeparatorDashed marginBottom="24px" marginTop="24px" color={theme.colors.grayscale.A200} />

      <Div>
        <P
          fontSize={FontSizeREM.PX16}
          lineHeight={FontSizeREM.PX20}
          fontWeight={600}
          marginBottom="24px"
        >
          informações do plano inter {privatePensionPlan.privatePensionType}
        </P>
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="17px"
        >
          <P
            fontSize={FontSizeREM.PX12}
            lineHeight={FontSizeREM.PX15}
            fontWeight={400}
            color={theme.colors.grayscale.A400}
          >
            Nome do fundo
          </P>
          <P
            fontSize={FontSizeREM.PX12}
            lineHeight={FontSizeREM.PX16}
            fontWeight={600}
            color={theme.colors.grayscale.A500}
            textAlign="right"
          >
            {privatePensionPlan.investmentFund?.name}
          </P>
        </Flex>
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="17px"
        >
          <P
            fontSize={FontSizeREM.PX12}
            lineHeight={FontSizeREM.PX15}
            fontWeight={400}
            color={theme.colors.grayscale.A400}
          >
            Modalidade do Plano
          </P>
          <P
            fontSize={FontSizeREM.PX12}
            lineHeight={FontSizeREM.PX16}
            fontWeight={600}
            color={theme.colors.grayscale.A500}
          >
            {privatePensionPlan.privatePensionType}
          </P>
        </Flex>
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="17px"
        >
          <P
            fontSize={FontSizeREM.PX12}
            lineHeight={FontSizeREM.PX15}
            fontWeight={400}
            color={theme.colors.grayscale.A400}
          >
            Data do débito (Próximo dia útil)
          </P>
          <P
            fontSize={FontSizeREM.PX12}
            lineHeight={FontSizeREM.PX16}
            fontWeight={600}
            color={theme.colors.grayscale.A500}
          >
            Dia {String(getFirstDebitDate().getDate())}
          </P>
        </Flex>
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="17px"
        >
          <P
            fontSize={FontSizeREM.PX12}
            lineHeight={FontSizeREM.PX15}
            fontWeight={400}
            color={theme.colors.grayscale.A400}
          >
            Tabela tributação
          </P>
          <P
            fontSize={FontSizeREM.PX12}
            lineHeight={FontSizeREM.PX16}
            fontWeight={600}
            color={theme.colors.grayscale.A500}
          >
            {privatePensionPlan.taxType}
          </P>
        </Flex>
        <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
          <P
            fontSize={FontSizeREM.PX12}
            lineHeight={FontSizeREM.PX15}
            fontWeight={400}
            color={theme.colors.grayscale.A400}
          >
            Taxa de administração (Min/Max)
          </P>
          <P
            fontSize={FontSizeREM.PX12}
            lineHeight={FontSizeREM.PX16}
            fontWeight={600}
            color={theme.colors.grayscale.A500}
          >
            {privatePensionPlan &&
              privatePensionPlan.investmentFund &&
              `${formatToPercent(
                privatePensionPlan.investmentFund.minimumAdministrationFee,
              )} / ${formatToPercent(privatePensionPlan.investmentFund.maximumAdministrationFee)}`}
          </P>
        </Flex>
        <SeparatorDashed marginBottom="24px" marginTop="24px" color={theme.colors.grayscale.A200} />
      </Div>
    </InterUIContainer>
  );
};

export default AdditionalContributionReceiptPage;
