/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { DeepLinkProposalState } from '../store/deeplinkProposal/types';
import { ProposalDetailsState } from '../store/proposal/types';
import { AppInfoService } from './appInfoService';
import { BaseService, ServiceResponse } from './baseService';
import { MockService } from './mockService';

const {
  REACT_APP_API_PENSION_PROPOSAL_V1,
  REACT_APP_API_BRIDGE_PROPOSAL_V1,
  REACT_APP_INTER_ENV,
} = process.env;

class DeepLinkProposalService {
  static async requestDeepLinkProposal(
    proposalBody: DeepLinkProposalState,
    userCpf: string,
  ): Promise<ServiceResponse<ProposalDetailsState>> {
    const getUserCpfAndRequestProposal = BaseService.doExecute<ProposalDetailsState>({
      endpoint: {
        browser: `${REACT_APP_API_PENSION_PROPOSAL_V1}/deeplink/interno`,
        bridge: `${REACT_APP_API_BRIDGE_PROPOSAL_V1}/deeplink`,
      },
      method: 'POST',
      headers: {
        browser: {
          'x-inter-cpf': userCpf,
          'x-inter-app-origem': 'Webview Previdência',
          'x-inter-app-version': AppInfoService.superAppVersion,
          'x-inter-conta-corrente': MockService.account,
          ...MockService.matchHeadersByUserAccount,
        },
        /**
         * This conditions is being fulfilled because:
         * At this date (18/05/2021) the Bridge is returning a CPF that is not the same of the CPF registered on the UAT backend (inter-consulta-cliente-service).
         * To avoid any problems, we are not sending the CPF received from the Bridge to the development and staging environments.
         * From the previdência backend view, there will be no error. But, don´t send the CPF creates one more processing for the backend to request the CPF.
         */
        bridge: REACT_APP_INTER_ENV === 'production' ? { 'x-inter-cpf': userCpf } : undefined,
      },
      data: proposalBody,
    });

    return getUserCpfAndRequestProposal;
  }
}
export default DeepLinkProposalService;
