import { AnyAction, Reducer } from 'redux';
import { BuyGoodPageState, BuyGoodPageType } from './types';

const INITIAL_STATE: BuyGoodPageState = {
  goalName: '',
  goalValue: 0,
};

const reducer: Reducer<BuyGoodPageState> = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case BuyGoodPageType.SET_BUY_GOOD_STATE: {
      return action.payload;
    }
    case BuyGoodPageType.RESET: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
