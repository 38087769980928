import React from 'react';
import FontSizeREM from '../../enums/fontSizesRem';
import Colors from '../../styles/colors';
import { Div, Flex, PSmallGray, Span } from '../../styles/commons';
import { ScaleIndicator } from './RiskScale.styles';

const RiskScale: React.FC = () => (
  <>
    <Div marginBottom="20px">
      <Flex alignItems="center" flexDirection="row">
        <Span color={Colors.vividBlue} fontSize={FontSizeREM.PX12} fontWeight={600}>
          -
        </Span>
        <ScaleIndicator backgroundColor={Colors.vividBlue} marginLeft="9px" leftRadius />
        <ScaleIndicator backgroundColor={Colors.pigmentGreen} />
        <ScaleIndicator backgroundColor={Colors.tangerineYellow} />
        <ScaleIndicator backgroundColor={Colors.bitterSweet} />
        <ScaleIndicator backgroundColor={Colors.fireEngineRed} marginRight="9px" rightRadius />
        <Span color={Colors.fireEngineRed} fontSize={FontSizeREM.PX12} fontWeight={600}>
          +
        </Span>
      </Flex>
    </Div>
    <Flex flexDirection="row" justifyContent="center" width="100%">
      <PSmallGray marginBottom="24px" fontSize={FontSizeREM.PX12}>
        Nível de risco
      </PSmallGray>
    </Flex>
  </>
);

export default RiskScale;
