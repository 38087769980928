import { AnyAction } from 'redux';
import { action } from 'typesafe-actions';
import { RootState } from '..';
import { LoadingTypes } from './types';

const get = (state: RootState): boolean => state.loading.toggle;

const getByElement = (elementId = '') => (state: RootState): boolean =>
  state.loading.toggle && state.loading.elementId === elementId;

const setToggleOn = (): AnyAction => action(LoadingTypes.SET_TOGGLE_ON);

const show = (elementId = ''): AnyAction => action(LoadingTypes.SHOW, elementId);

const hide = (): AnyAction => action(LoadingTypes.HIDE);

const useIsElementLoading = (state: RootState) => (elementId: string): boolean =>
  state.loading.toggle && state.loading.elementId === elementId;

export const LoadingActions = {
  get,
  show,
  hide,
  setToggleOn,
  useIsElementLoading,
  getByElement,
};
