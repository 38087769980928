import { AnyAction, Reducer } from 'redux';
import { IncomeExpensePageState, IncomeExpensePageTypes } from './types';

const INITIAL_STATE: IncomeExpensePageState = {
  annualIncome: 0,
  monthlyEducationExpenditure: 0,
  monthlyEducationExpenditureDependents: 0,
  monthlyHealthExpenditure: 0,
};

const reducer: Reducer<IncomeExpensePageState> = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case IncomeExpensePageTypes.SET_INCOME_EXPENSES_STATE: {
      return action.payload;
    }
    case IncomeExpensePageTypes.RESET: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
