/* eslint-disable react-hooks/exhaustive-deps */

import { yupResolver } from '@hookform/resolvers/yup';
import { InterUIButton } from '@interco/inter-ui-react-lib';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import * as yup from 'yup';
import { InputFormFieldWithHook } from '../../components';
import FlexWithHook from '../../components/FlexWithHook/FlexWithHook';
import { FormWithHook } from '../../components/FormWithHook/FormWithHook';
import InputPricingWithHook from '../../components/InputPricingWithHook/InputPricingWithHook';
import SelectWithHook from '../../components/SelectWithHook/SelectWithHook';
import { getMessages, MessagesValidation } from '../../enums/messages';
import { PageRoutes } from '../../enums/pageRoutes';
import PageTitles from '../../enums/pageTitles';
import { BuyGoodActions } from '../../store/buyGood/actions';
import { LoadingActions } from '../../store/loading/actions';
import NavbarActions from '../../store/navbar/actions';
import { ProposalDetailActions } from '../../store/proposal/actions';
import { ThemeInter } from '../../styles';
import { H1, P, PSmallGray, SeparatorDashed } from '../../styles/commons';
import { INITIAL_CONTRIBUTION_MINIMUM } from '../../utils/minValues';
import { formatToBRLCurrency } from '../../utils/numberFormatUtils';

interface IBuyGoodForm {
  goalName: string;
  goalValue: number;
  years: number;
  months: number;
}

const GOOD_INFO = {
  title: 'O que vai te guiar',
  description: 'Diga para a gente o que você quer conquistar',
  inputLabel: 'Nome da meta',
  inputPlaceholder: 'Ex: conquistar meu primeiro milhão',
};

const TOTAL_VALUE_INFO = {
  title: 'Defina os valores',
  description:
    'Sabendo o valor dessa meta, junto com tempo até alcançá-la, indicaremos o plano que mais fazsentido para você.',
  inputLabel: 'Valor total',
};

const NUMBER_MONTHS_INVESTING_INFO = {
  title: 'Escolha o tempo',
  description:
    'Se você não sabe exatamento quando fará o resgate, dê uma estimativa. E você poderá resgatar antes, com descontos, se precisar.',
  inputYearLabel: 'Anos',
  inputMonthsLabel: 'Meses',
  inputPlaceholder: 'Selecione',
};

const buttonLabel = 'Mostrar plano indicado';

function getSchemaByProps(totallMinimum: number) {
  const goalValue = yup
    .string()
    .test(
      'min',
      getMessages(MessagesValidation.MINIMUM_VALUE_VALIDATION, [
        formatToBRLCurrency(totallMinimum),
      ]),
      (value) => {
        let parsedValue = null;
        if (value) {
          parsedValue = +value;
        }
        return !!parsedValue && parsedValue >= totallMinimum;
      },
    )
    .required();

  const years = yup.number().required();
  const months = yup.number().required();
  const goalName = yup.string().required();

  return yup.object().shape({
    goalValue,
    years,
    months,
    goalName,
  });
}

const BuyGoodPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme() as ThemeInter;
  const loading = useSelector(LoadingActions.get);
  const buyGoodDefaultValues = useSelector(BuyGoodActions.get);

  const methods = useForm<IBuyGoodForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      ...buyGoodDefaultValues,
    },
    resolver: yupResolver(getSchemaByProps(INITIAL_CONTRIBUTION_MINIMUM)),
  });

  const {
    getValues,
    formState: { isValid },
  } = methods;

  const disableButton = useMemo(() => {
    const years = getValues('years');
    const months = getValues('months');
    return !isValid || years * 12 + months === 0;
  }, [isValid, getValues(['years', 'months'])]);

  const isDisableTotalValue: boolean = useMemo(() => getValues('goalName') === '', [
    getValues('goalName'),
  ]);

  const isDisableTime: boolean = useMemo(
    () => getValues('goalValue') < INITIAL_CONTRIBUTION_MINIMUM,
    [getValues('goalValue')],
  );

  const stickyFooter: React.ReactElement = (
    <InterUIButton margin="0 24px 24px" disabled={disableButton} loading={loading}>
      {buttonLabel}
    </InterUIButton>
  );

  const onSubmit = (data: IBuyGoodForm) => {
    dispatch(BuyGoodActions.set({ ...data, goalValue: +data.goalValue }));
    dispatch(
      ProposalDetailActions.requestBuyGoodProposal({ history, pathname: PageRoutes.PROPOSAL }),
    );
  };

  useEffect(() => {
    dispatch(NavbarActions.setTitle(PageTitles.BUY_GOOD));
  }, [dispatch]);

  return (
    <FormWithHook stickyFooter={stickyFooter} onSubmit={onSubmit} methods={methods}>
      <H1 marginBottom="8px">{GOOD_INFO.title}</H1>
      <PSmallGray marginBottom="24px">{GOOD_INFO.description}</PSmallGray>
      <InputFormFieldWithHook
        name="goalName"
        nextField="goalValue"
        placeholder={GOOD_INFO.inputPlaceholder}
        label={GOOD_INFO.inputLabel}
        type="text"
        inputMode="text"
        shouldDirty
      />
      <SeparatorDashed color={theme.colors.grayscale.A200} marginTop="24px" marginBottom="24px" />
      <P disabled={isDisableTotalValue}>{TOTAL_VALUE_INFO.title}</P>
      <PSmallGray disabled={isDisableTotalValue}>{TOTAL_VALUE_INFO.description}</PSmallGray>
      <InputPricingWithHook
        name="goalValue"
        nextField="years"
        label={TOTAL_VALUE_INFO.inputLabel}
        placeholder="0,00"
        prefix="R$"
        maxLength={14}
        disabled={isDisableTotalValue}
      />
      <SeparatorDashed color={theme.colors.grayscale.A200} marginTop="8px" marginBottom="24px" />
      <P disabled={isDisableTime}>{NUMBER_MONTHS_INVESTING_INFO.title}</P>
      <PSmallGray marginBottom="24px" disabled={isDisableTime}>
        {NUMBER_MONTHS_INVESTING_INFO.description}
      </PSmallGray>

      <FlexWithHook flexDirection="row" justifyContent="space-between">
        <SelectWithHook
          name="years"
          nextField="months"
          type="number"
          label={NUMBER_MONTHS_INVESTING_INFO.inputYearLabel}
          placeholder={NUMBER_MONTHS_INVESTING_INFO.inputPlaceholder}
          disabled={isDisableTotalValue || isDisableTime}
          width="100%"
          margin="0 8px 0 0"
        >
          {Array.from(Array(51).keys()).map((nYears) => {
            const key = `year-${nYears}`;
            return (
              <option key={key} value={nYears}>
                {nYears}
              </option>
            );
          })}
        </SelectWithHook>

        <SelectWithHook
          name="months"
          type="number"
          label={NUMBER_MONTHS_INVESTING_INFO.inputMonthsLabel}
          placeholder={NUMBER_MONTHS_INVESTING_INFO.inputPlaceholder}
          disabled={isDisableTotalValue || isDisableTime}
          width="100%"
          margin="0 0 0 8px"
        >
          {Array.from(Array(12).keys()).map((nMonths) => {
            const key = `year-${nMonths}`;
            return (
              <option key={key} value={nMonths}>
                {nMonths}
              </option>
            );
          })}
        </SelectWithHook>
      </FlexWithHook>
    </FormWithHook>
  );
};

export default BuyGoodPage;
