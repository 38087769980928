import { combineReducers } from 'redux';
import buyGood from './buyGood';
import contributionValues from './contributionValues';
import deepLinkProposal from './deeplinkProposal';
import error from './error';
import global from './global';
import hireProposal from './hire';
import incomeExpense from './incomeExpenses';
import incomeTax from './incomeTax';
import loading from './loading';
import navbar from './navbar';
import portability from './portability';
import portabilitiesStatus from './portabilityStatus';
import previewContract from './previewContract';
import privatePensionPlan from './privatePensionPlan';
import proposalDetails from './proposal';
import proposalByFund from './proposalByFund';
import retirementPage from './retirement';
import selectableFunds from './selectableFunds';
import snackbar from './snackbar';
import termAndCondition from './termAndCondition';
import user from './user';

const rootReducer = combineReducers({
  navbar,
  snackbar,
  loading,
  error,
  user,
  retirementPage,
  proposalDetails,
  selectableFunds,
  contributionValues,
  previewContract,
  termAndCondition,
  hireProposal,
  portability,
  incomeExpense,
  buyGood,
  incomeTax,
  global,
  deepLinkProposal,
  privatePensionPlan,
  proposalByFund,
  portabilitiesStatus,
});

export default rootReducer;
