import React from 'react';
import { PortabilityStatus } from '../../../../store/portabilityStatus/types';
import PortabilityStatusCard from '../PortabilityStatusCard/PortabilitStatusCard';

interface IPortabilitStatusList {
  list: PortabilityStatus[];
}

const PortabilityStatusList: React.FC<IPortabilitStatusList> = ({ list }) => (
  <>
    {list.map((portabilityStatus: PortabilityStatus) => {
      // Stryker disable next-line all
      const key = `key-${portabilityStatus.id}`;
      return <PortabilityStatusCard key={key} portabilityStatus={portabilityStatus} />;
    })}
  </>
);

export default PortabilityStatusList;
