/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-return */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { InterUIBottomSheet, InterUIButton, InterUILoading } from '@interco/inter-ui-react-lib';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import HomeAnalyticsService from '../../analytics/homeAnalyticsService';
import PortabilityAnalyticsService from '../../analytics/portabilityAnalyticsService';
import HomeImg from '../../assets/home/Home.png';
import Babi from '../../components/Babi/Babi';
import { EnvVariableKeys } from '../../enums/evironmentVariableKeys';
import FontSizeREM from '../../enums/fontSizesRem';
import { LocalStorageKeys } from '../../enums/localStorageKeys';
import { PageRoutes } from '../../enums/pageRoutes';
import PageTitles from '../../enums/pageTitles';
import { PrivatePensions } from '../../enums/privatePensions';
import TermsTypes from '../../enums/signedTerms';
import { AppInfoService } from '../../services/appInfoService';
import BridgeService from '../../services/bridgeService';
import { EnvVariableService } from '../../services/environmentVariableService';
import LocalStorageService from '../../services/localStorageService';
import { MonitoringService } from '../../services/monitoringService';
import { BuyGoodActions } from '../../store/buyGood/actions';
import ErrorActions from '../../store/error/actions';
import { HireProposalActions } from '../../store/hire/actions';
import IncomeTaxPageActions from '../../store/incomeTax/actions';
import { LoadingActions } from '../../store/loading/actions';
import NavbarActions from '../../store/navbar/actions';
import PortabilityActions from '../../store/portability/actions';
import { PreviewContractActions } from '../../store/previewContract/actions';
import PrivatePensionPlanActions from '../../store/privatePensionPlan/actions';
import { PrivatePensionPlan } from '../../store/privatePensionPlan/types';
import { ProposalDetailActions } from '../../store/proposal/actions';
import { ProposalByFundActions } from '../../store/proposalByFund/actions';
import RetirementPageActions from '../../store/retirement/actions';
import SelectableFundsActions from '../../store/selectableFunds/actions';
import TermsAndConditionsActions from '../../store/termAndCondition/actions';
import UserInfoActions from '../../store/user/actions';
import { ThemeInter } from '../../styles';
import Colors from '../../styles/colors';
import { Div, Flex, H1, P, PSmall, Separator } from '../../styles/commons';
import { InterContainerNoFooter } from '../../styles/inter-ui-customizations';
import { calcAge, newDateHandler } from '../../utils/dateTimeUtils';
import { termsDeeplinkById } from '../../utils/deeplinkUtils';
import { _try } from '../../utils/errorUtils';
import { getFailedCertificateFromLocalStorage } from '../../utils/partialSuccessPrevsList';
import { IRouterStateProps } from '../../utils/routesUtils';
import Faq from './components/Faq/Faq';

const MAX_ALLOWED_AGE = 75;

const CPF_MAX_SIZE = 11;

const INVESTOR_PROFILE_DEEPLINK = 'bancointer://investimentos/configuracoes/perfil';

export const HomeButtonIds = {
  simulatePrev: '@home/simulate-prev',
  investMore: '@home/invest-more',
  portability: '@home/portability',
  acceptance: '@home/proposal-acceptance',
};

export interface IBottomSheetState {
  description: Array<string>;
  title: string;
  buttonTitle: string;
  onClick?: () => void;
  toggle: boolean;
}

const Home: React.FC = () => {
  const dispatch = useDispatch();
  const handleError = useErrorHandler();
  const history = useHistory();
  const isElementLoading = useSelector(LoadingActions.useIsElementLoading);
  const { account } = useSelector(UserInfoActions.get);
  const userInfo = useSelector(UserInfoActions.get);
  const customerPendingHireProposal = useSelector(HireProposalActions.hasPendingHireProposal);
  const loadingAcceptanceHireProposal = useSelector(LoadingActions.get);

  const userSignedTerms = useSelector(UserInfoActions.getUserSignedTerms);
  const theme = useTheme() as ThemeInter;

  const PREV_RETURN_DATE = EnvVariableService.getVariable(EnvVariableKeys.PREV_RETURN_DATE);
  const BottomSheetMessages = {
    TITLE_UNAVAILABLE: 'Oops, estamos em manutenção!',
    DESCRIPTION_UNAVAILABLE: [
      `Devido a uma manutenção programada dos nossos serviços, estamos com o sistema indisponível${
        PREV_RETURN_DATE ? ` até ${PREV_RETURN_DATE}. ` : '.'
      }`,
      'Estamos trabalhando para melhorar sua experiência cada vez mais!',
    ],

    TITLE_AGE: 'Temos outros serviços pra você',
    DESCRIPTION_AGE_MORE_THAN_MAX: [
      `Devido às nossas políticas de investimentos, no momento não é possível oferecer um plano de previdência a maiores de ${MAX_ALLOWED_AGE} anos.`,
      'Enquanto isso, te convidamos a ver outros serviços do Inter.',
    ],
    TITLE_CNPJ: 'Exclusivo para Pessoa Física',
    DESCRIPTION_CPNJ: [
      'Devido às nossas políticas de investimentos, no momento não é possível oferecer um plano de previdência para correntistas MEI.',
      'Enquanto isso, você pode acessar sua conta Pessoa Física e contratar sua previdência.',
    ],
    TITLE_DTVM_NOT_SIGNED: 'Aceite o termo DTVM para continuar',

    DESCRIPTION_DTVM_NOT_SIGNED: [
      `Você deve aceitar o termo da DTVM para utilizar a área de investimentos.`,
    ],

    TITLE_INVESTOR_PROFILE_NOT_FOUND: 'Defina seu perfil investidor',
    DESCRIPTION_INVESTOR_PROFILE_NOT_FOUND: [
      `Para aproveitar a área de investimentos da melhor maneira e receber indicações personalizadas, é necessário preencher seu perfil investidor.`,
    ],
    TITLE_INVESTOR_PROFILE_AND_DTVM_NOT_FOUND:
      'Defina seu perfil de investidor e aceite o termo DTVM para prosseguir',
    DESCRIPTION_INVESTOR_PROFILE_AND_DTVM_NOT_FOUND: [
      `É necessario preencher o teste de perfil e aceitar o termo para utilizar a área de investimentos.`,
    ],
  };

  const callSuitabilityDeepLink = async () => {
    const suitabilityTerm = userSignedTerms.terms.find(
      ({ tipo }) => tipo === TermsTypes.SUITABILITY,
    );

    dispatch(UserInfoActions.setUserSignedTerms({ ...userSignedTerms, isAcceptable: undefined }));
    try {
      HomeAnalyticsService.suitabilityDeeplinkButton();
      await BridgeService.openDeepLink(termsDeeplinkById(suitabilityTerm!.numeroTermo));
    } catch (e: any) {
      MonitoringService.noticeError(e, {
        errorCodeRef: 'Home.callSuitabilityDeepLink',
      });
    }
  };

  const callInvestorProfileDeepLink = async () => {
    dispatch(UserInfoActions.setUserSignedTerms({ ...userSignedTerms, isAcceptable: undefined }));
    try {
      HomeAnalyticsService.investorProfileDeeplinkButton();
      await BridgeService.openDeepLink(INVESTOR_PROFILE_DEEPLINK);
    } catch (e: any) {
      MonitoringService.noticeError(e, {
        errorCodeRef: 'Home.callInvestorProfileDeepLink',
      });
    }
  };

  const goToAppHome = () =>
    _try(
      async () => {
        await BridgeService.goToAppHomeOrBackToNative(history, PageRoutes.HOME);
      },
      handleError,
      'Home.goToAppHome',
    );

  const [bottomSheetProperties, setBottomSheetProperties] = useState<IBottomSheetState>({
    description: [],
    title: '',
    buttonTitle: 'Ir para home',
    toggle: false,
    onClick: goToAppHome,
  });

  const hasValidSignedTermsLocalStorage = () =>
    !LocalStorageService.hasExpired(LocalStorageKeys.USER_SIGNED_TERMS, account);

  const isPrevUnavailable = EnvVariableService.getVariableAsBoolean(
    EnvVariableKeys.IS_PREV_UNAVAILABLE,
  );

  const isAdditionalContributionAvailable = EnvVariableService.getVariableAsBoolean(
    EnvVariableKeys.IS_ADDITIONAL_CONTRIBUTION_AVAILABLE,
  );

  const retrieveCustomerPendingHireProposal = useCallback(() => {
    const state = history?.location?.state as IRouterStateProps;

    if (!state?.data?.ignoreFetch) {
      dispatch(HireProposalActions.getCustomerPendingHireProposal());
    } else {
      const unused = ''; // Following doc https://developer.mozilla.org/en-US/docs/Web/API/History/replaceState#syntax
      window.history.replaceState(PageRoutes.HOME, unused);
    }
  }, [dispatch]);

  useEffect(() => {
    retrieveCustomerPendingHireProposal();
  }, []);

  useEffect(() => {
    dispatch(NavbarActions.setTitle(PageTitles.HOME));
    dispatch(RetirementPageActions.resetState());
    dispatch(IncomeTaxPageActions.resetState());
    dispatch(BuyGoodActions.resetState());
    dispatch(ProposalDetailActions.resetState());
    dispatch(SelectableFundsActions.resetState());
    dispatch(PreviewContractActions.resetState());
    dispatch(TermsAndConditionsActions.resetState());
    dispatch(PortabilityActions.resetState());
    dispatch(ErrorActions.resetState());
    dispatch(HireProposalActions.resetState());
    dispatch(ProposalByFundActions.resetState());
    dispatch(PrivatePensionPlanActions.resetState());
  }, [dispatch]);

  const isValidUserAge = useMemo((): boolean => {
    const { birthDate } = userInfo;

    const birthDateAsDate = newDateHandler(birthDate);
    MonitoringService.log('Home.isValidUserAge.birthDate', birthDate);
    MonitoringService.log('Home.isValidUserAge.birthDateAsDate', birthDateAsDate);

    if (birthDateAsDate) {
      const userAge = calcAge(birthDateAsDate);
      MonitoringService.log('Home.isValidUserAge.userAge', userAge);

      if (userAge > MAX_ALLOWED_AGE) {
        setBottomSheetProperties({
          ...bottomSheetProperties,
          title: BottomSheetMessages.TITLE_AGE,
          description: BottomSheetMessages.DESCRIPTION_AGE_MORE_THAN_MAX,
          toggle: true,
        });

        HomeAnalyticsService.restrictionWarning('Idade máxima');
        return false;
      }
    }
    return true;
  }, [userInfo]);

  const isValidUserCPF = useMemo((): boolean => {
    const { cpf } = userInfo;

    if (cpf.length > CPF_MAX_SIZE) {
      setBottomSheetProperties({
        ...bottomSheetProperties,
        title: BottomSheetMessages.TITLE_CNPJ,
        description: BottomSheetMessages.DESCRIPTION_CPNJ,
        toggle: true,
      });

      HomeAnalyticsService.restrictionWarning('MEI');
      return false;
    }
    return true;
  }, [userInfo]);

  const checkIfTheUserTermsAreValid = (paramsRoute: string) => {
    const signedTermsFromLocalStorageIsValid = hasValidSignedTermsLocalStorage();

    // The minimal app version to use the qualified investor term and suitability deeplinks.
    const startingVersionToValidadeCustomerSuitability = AppInfoService.convertToNumber(
      EnvVariableService.getVariable(
        EnvVariableKeys.STARTING_VERSION_TO_VALIDATE_CUSTOMER_SUITABILITY,
      )!,
    );

    const appVersionInfo = AppInfoService.convertToNumber(AppInfoService.superAppVersion);

    // We compare versions because only in the v11.2 of the app, we have the Suitability and Investor Profile deeplink
    // Under the v11.2, the user will follow the old flow.
    const isAppVersionAllowed = appVersionInfo >= startingVersionToValidadeCustomerSuitability;

    if (
      !signedTermsFromLocalStorageIsValid &&
      userSignedTerms.isAcceptable !== true &&
      isAppVersionAllowed
    ) {
      dispatch(UserInfoActions.requestUserSignedTerms({ pathname: paramsRoute, history }));
    } else {
      dispatch(LoadingActions.hide());
      history.push(paramsRoute);
    }
  };

  const handleRejectedUserTerms = () => {
    const { isSuitabilityMissing, isInvestorProfileMissing } = userSignedTerms.whichIsMissing;

    let bottomSheetTitle = '';
    let bottomSheetDescription = [];
    let onClickFunction = null;
    let buttonTitle = 'Ir para teste';

    // If both investor profile and suitability are missing
    if (isInvestorProfileMissing && isSuitabilityMissing) {
      bottomSheetTitle = BottomSheetMessages.TITLE_INVESTOR_PROFILE_AND_DTVM_NOT_FOUND;
      bottomSheetDescription = BottomSheetMessages.DESCRIPTION_INVESTOR_PROFILE_AND_DTVM_NOT_FOUND;
      onClickFunction = callInvestorProfileDeepLink;
    }
    // If investor profile is missing
    else if (isInvestorProfileMissing) {
      bottomSheetTitle = BottomSheetMessages.TITLE_INVESTOR_PROFILE_NOT_FOUND;
      bottomSheetDescription = BottomSheetMessages.DESCRIPTION_INVESTOR_PROFILE_NOT_FOUND;
      onClickFunction = callInvestorProfileDeepLink;
    }
    // If suitability is missing
    else {
      bottomSheetTitle = BottomSheetMessages.TITLE_DTVM_NOT_SIGNED;
      bottomSheetDescription = BottomSheetMessages.DESCRIPTION_DTVM_NOT_SIGNED;
      onClickFunction = callSuitabilityDeepLink;
      buttonTitle = 'Aceitar termo';
    }

    setBottomSheetProperties({
      ...bottomSheetProperties,
      title: bottomSheetTitle,
      description: bottomSheetDescription,
      buttonTitle,
      toggle: true,
      onClick: onClickFunction,
    });
  };

  useEffect(() => {
    if (userSignedTerms.isAcceptable === false) {
      handleRejectedUserTerms();
    }
  }, [userSignedTerms.isAcceptable]);

  const validAccess = (): boolean => {
    if (!isValidUserAge || !isValidUserCPF) {
      return false;
    }

    if (userSignedTerms.isAcceptable === false) {
      handleRejectedUserTerms();
      return false;
    }

    return true;
  };

  const goToObjectivesHomePage = () => {
    if (isPrevUnavailable) {
      setBottomSheetProperties({
        ...bottomSheetProperties,
        title: BottomSheetMessages.TITLE_UNAVAILABLE,
        description: BottomSheetMessages.DESCRIPTION_UNAVAILABLE,
        toggle: true,
      });

      HomeAnalyticsService.restrictionWarning('Previdencia indisponível');
      return;
    }

    HomeAnalyticsService.clickedButton('Contratar novo plano');

    if (validAccess()) {
      dispatch(LoadingActions.show(HomeButtonIds.simulatePrev));
      checkIfTheUserTermsAreValid(PageRoutes.OBJECTIVES_HOME);
    }
  };

  const goToPortabilityHomePage = () => {
    HomeAnalyticsService.clickedButton('Trazer minha previdência para o Inter');

    if (validAccess()) {
      checkIfTheUserTermsAreValid(PageRoutes.PORTABILITY_HOME);
    }
  };

  const goToHireProposalAcceptancePage = () => {
    HomeAnalyticsService.clickedButton('Autorizar contratação');
    if (!customerPendingHireProposal) return;

    if (validAccess()) {
      checkIfTheUserTermsAreValid(PageRoutes.HIRE_PROPOSAL_ACCEPTANCE);
    }
  };

  const getLocalStorageItem = () =>
    LocalStorageService.getItem<PrivatePensionPlan[]>(
      LocalStorageKeys.PRIVATE_PENSION_PLANS,
      account,
    );

  const handleRedirect = () => {
    const dataFromLocalStorage = getLocalStorageItem();
    if (dataFromLocalStorage?.data.length === 1) {
      const privatePensionPlan = dataFromLocalStorage?.data[0];
      dispatch(PrivatePensionPlanActions.setPrivatePensionPlan(privatePensionPlan));

      if (!privatePensionPlan.isValidToContribute) {
        history.push(PageRoutes.ADDITIONAL_CONTRIBUTION_EXPIRED);
      } else if (privatePensionPlan.privatePensionType === PrivatePensions.PGBL) {
        history.push(PageRoutes.ADDITIONAL_CONTRIBUTION_PGBL_HELPER);
      } else if (privatePensionPlan.privatePensionType === PrivatePensions.VGBL) {
        history.push(PageRoutes.ADDITIONAL_CONTRIBUTION_INVESTMENT_FORM);
      }
    } else if (dataFromLocalStorage && dataFromLocalStorage.data.length > 1) {
      history.push(PageRoutes.PRIVATE_PENSION_PLANS);
    }
    dispatch(LoadingActions.hide());
  };

  const hasValidLocalStorageItem = () =>
    !LocalStorageService.hasExpired(LocalStorageKeys.PRIVATE_PENSION_PLANS, account);

  const handleClickInvestMore = () => {
    dispatch(LoadingActions.show(HomeButtonIds.investMore));

    HomeAnalyticsService.investMoreButton();
    const dataFromLocalStorage = getLocalStorageItem();
    const failedPrivatePensionPlans = getFailedCertificateFromLocalStorage(account);

    if (dataFromLocalStorage && hasValidLocalStorageItem()) {
      if (failedPrivatePensionPlans && failedPrivatePensionPlans.length > 0) {
        dispatch(PrivatePensionPlanActions.setFailedPrivatePensionPlans(failedPrivatePensionPlans));
        dispatch(
          PrivatePensionPlanActions.requestPrivatePensionPlans({
            history,
            pathname: PageRoutes.PRIVATE_PENSION_PLANS,
          }),
        );
      } else {
        handleRedirect();
      }
    } else {
      dispatch(
        PrivatePensionPlanActions.requestPrivatePensionPlans({
          history,
          pathname: PageRoutes.PRIVATE_PENSION_PLANS,
        }),
      );
    }
  };

  const userHasPrivatePension = () =>
    LocalStorageService.getItem<boolean>(LocalStorageKeys.USER_HAS_PRIVATE_PENSION, account);

  return !loadingAcceptanceHireProposal ? (
    <>
      <InterContainerNoFooter margin="0 24px 24px">
        <Div width="100vw" margin="0 0px 24px -24px">
          <img src={HomeImg} alt="Home" style={{ width: '100%', marginBottom: '24px' }} />
        </Div>
        <H1 marginBottom="8px">Previdência pra tudo, até pra aposentadoria</H1>
        <P
          fontSize={FontSizeREM.PX14}
          lineHeight={FontSizeREM.PX17}
          fontWeight={400}
          color={Colors.shuttleGray}
          marginBottom="24px"
        >
          Comprar um bem, viajar, economizar no imposto de renda: dá pra realizar tudo isso com a
          previdência privada! Em <strong>menos de 5 minutos</strong> você já sai com sua
          previdência!
        </P>
        <InterUIButton
          onClick={goToObjectivesHomePage}
          margin="0 0 24px 0"
          loading={isElementLoading(HomeButtonIds.simulatePrev)}
        >
          Contratar novo plano
        </InterUIButton>
        {customerPendingHireProposal && (
          <InterUIButton
            onClick={goToHireProposalAcceptancePage}
            margin="0 0 24px 0"
            loading={isElementLoading(HomeButtonIds.acceptance)}
          >
            Autorizar contratação
          </InterUIButton>
        )}
        {isAdditionalContributionAvailable &&
          userHasPrivatePension() &&
          userHasPrivatePension()?.data && (
            <InterUIButton
              variant="secondary"
              onClick={handleClickInvestMore}
              loading={isElementLoading(HomeButtonIds.investMore)}
            >
              Investir mais no meu plano
            </InterUIButton>
          )}
        <Div width="100vw" margin="24px 0px 24px -24px">
          <Separator
            marginTop="8px"
            marginBottom="8px"
            color={theme.colors.grayscale.A100}
            height="8px"
          />
        </Div>
        <InterUIButton
          type="button"
          variant="secondary"
          onClick={goToPortabilityHomePage}
          loading={isElementLoading(HomeButtonIds.portability)}
        >
          Portabilidade de Previdência
        </InterUIButton>
        <Div width="100vw" margin="24px 0px 24px -24px">
          <Separator
            marginTop="8px"
            marginBottom="8px"
            color={theme.colors.grayscale.A100}
            height="8px"
          />
        </Div>
        <Faq />
        <Div width="100vw" margin="32px 0px 32px -24px">
          <Separator
            marginTop="8px"
            marginBottom="8px"
            color={theme.colors.grayscale.A100}
            height="8px"
          />
        </Div>
        <Div margin="0 0 47px">
          <Babi
            title="Oi, posso ajudar?"
            subTitle="A Babi vai te ajudar com todo o conteúdo da nossa central."
            errorCodeRef="Home"
            analyticCallBack={() => PortabilityAnalyticsService.babi()}
          />
        </Div>
      </InterContainerNoFooter>
      <InterUIBottomSheet
        toggle={bottomSheetProperties.toggle}
        onHide={() => setBottomSheetProperties({ ...bottomSheetProperties, toggle: false })}
        title={bottomSheetProperties.title}
      >
        {bottomSheetProperties.description.map((message, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <PSmall key={index} color={Colors.shuttleGray} marginBottom="16px">
            {message}
          </PSmall>
        ))}
        <InterUIButton margin="32px 0 0 0" onClick={bottomSheetProperties.onClick}>
          {bottomSheetProperties.buttonTitle}
        </InterUIButton>
      </InterUIBottomSheet>
    </>
  ) : (
    <Flex height="100vh" justifyContent="center" alignItems="center">
      <InterUILoading size="ld" data-testid="loading" />
    </Flex>
  );
};

export default Home;
