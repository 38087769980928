import { InterUIBottomSheet, InterUIRadio } from '@interco/inter-ui-react-lib';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Action from '../../enums/actions';
import SelectableFundsActions from '../../store/selectableFunds/actions';
import { FundSortterType } from '../../store/selectableFunds/types';
import { Div, PSmallBold } from '../../styles/commons';

interface ISelectableFundsSortterProps {
  toggle: boolean;

  marginBottom?: string;

  toggleOff: () => void;
}

interface IFundsSortOption {
  id: string;
  title: FundSortterType;
}

const sortOptions: Array<IFundsSortOption> = [
  {
    id: 'by_profitability',
    title: 'Rentabilidade',
  },
  {
    id: 'by_risk',
    title: 'Grau de risco',
  },
  {
    id: 'by_priority',
    title: 'Padrão',
  },
];

const SelectableFundsSortter: React.FC<ISelectableFundsSortterProps> = ({
  marginBottom,
  toggle,
  toggleOff,
}) => {
  const dispatch = useDispatch();
  const sortter = useSelector(SelectableFundsActions.getSortter);
  const [choosedOption, setChoosedOption] = useState<FundSortterType>(sortter);

  const onChange = (value: FundSortterType) => {
    setChoosedOption(value);
  };

  useEffect(() => {
    dispatch(SelectableFundsActions.setSortter(choosedOption));
  }, [choosedOption, dispatch]);

  const renderOptions = () =>
    sortOptions.map((option) => {
      const key = `sort_${option.id}`;
      return (
        <InterUIRadio
          data-testid={key}
          key={key}
          id={key}
          name="sortter"
          value={option.title}
          onChange={(e) => onChange(e.target.value as FundSortterType)}
          checked={choosedOption === `${option.title}`}
        >
          <PSmallBold marginBottom="4px" maxWidth="115px">
            {option.title}
          </PSmallBold>
        </InterUIRadio>
      );
    });

  return (
    <Div marginBottom={marginBottom}>
      <InterUIBottomSheet
        title={Action.SORT}
        toggle={toggle}
        onHide={toggleOff}
        link={Action.CLOSE}
        onClickedLink={toggleOff}
      >
        {renderOptions()}
      </InterUIBottomSheet>
    </Div>
  );
};

export default SelectableFundsSortter;
