import {
  InterUIButton,
  InterUICheckBox,
  InterUIContainer,
  InterUILoading,
} from '@interco/inter-ui-react-lib';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import SuccessImg from '../../assets/success/Success.png';
import { InvestmentFundCard } from '../../components';
import FontSizeREM from '../../enums/fontSizesRem';
import { PageRoutes } from '../../enums/pageRoutes';
import PageTitles from '../../enums/pageTitles';
import BridgeService from '../../services/bridgeService';
import { LoadingActions } from '../../store/loading/actions';
import NavbarActions from '../../store/navbar/actions';
import PortabilityActions from '../../store/portability/actions';
import {
  ExternalPortabilityStatusEnum,
  PortabilityTargetPlan,
} from '../../store/portability/types';
import { ThemeInter } from '../../styles';
import Colors from '../../styles/colors';
import { Div, Flex, H1, H2, H3, P, Separator } from '../../styles/commons';
import { _try } from '../../utils/errorUtils';
import { formatToBRLCurrency, formatToPercent } from '../../utils/numberFormatUtils';
import { IReceiptPageProps } from '../CancelReceipt/CancelReceiptPage';
import {
  IField,
  ISectionSettings,
  ITargetPlanSetting,
  LoadingIds,
  PathParam,
  Titles,
} from './ExternalPortabilityAcceptance.type';

const cancelReceiptPageProps = {
  pageTitle: PageTitles.PORTABILITY_CANCEL_RECEIPT,
  title: 'Cancelamento de portabilidade efetuado!',
  redirectTo: PageRoutes.HOME,
  text: `Feito! A solicitação de portabilidade de previdência privada foi cancelada. Caso queira
  realizar outra entre em contato com seu acessor de investimentos, ou com um de nossos canais
  de atendimento.`,
} as IReceiptPageProps;

const ExternalPortabilityAcceptance: React.FC = () => {
  const { id } = useParams<PathParam>();
  const dispatch = useDispatch();
  const portabilityRequest = useSelector(PortabilityActions.getExternalPortabilityRequest);
  const [originPlanSettings, setOriginPlanSettings] = useState<ISectionSettings>();
  const [targetPlanSettings, setTargetPlanSettings] = useState<ISectionSettings>();
  const [selectedTargetPlans, setSelectedTargetPlans] = useState<boolean[]>([]);
  const loading = useSelector(LoadingActions.getByElement(LoadingIds.page));
  const loadingConfirmBtn = useSelector(LoadingActions.getByElement(LoadingIds.confirmButton));
  const [termsAgreementAccepted, setTermsAgreementAccepted] = useState<boolean>(false);
  const theme = useTheme() as ThemeInter;
  const history = useHistory();
  const handleError = useErrorHandler();

  const defaultFlexItemStyle = { flex: '1 0 10rem' };

  const fetchExternalPortabilityRequest = useCallback(() => {
    dispatch(PortabilityActions.fetchExternalPortabilityRequest((id as unknown) as number));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(NavbarActions.setTitle(PageTitles.EXTERNAL_PORTABILITY));
    fetchExternalPortabilityRequest();
  }, [fetchExternalPortabilityRequest, dispatch]);

  const acceptExternalPortability = () => {
    dispatch(PortabilityActions.acceptExternalPortabilityRequest((id as unknown) as number));
  };

  const acceptancePending = useMemo(
    () => portabilityRequest?.status === ExternalPortabilityStatusEnum.ACEITE_PENDENTE,
    [portabilityRequest],
  );

  const cancelExternalPortabilityAndBackToHome = () => {
    if (portabilityRequest) {
      dispatch(
        PortabilityActions.cancelExternalPortabilityRequest(
          portabilityRequest.id,
          cancelReceiptPageProps,
          {
            history,
            pathname: PageRoutes.CANCELLATION_RECEIPT,
          },
        ),
      );
    }
  };

  const goToAppHome = () =>
    _try(
      async () => {
        await BridgeService.goToAppHomeOrBackToNative(history, PageRoutes.HOME);
      },
      handleError,
      'ExternalPortabilityAcceptance.goToAppHome',
    );

  const TermsAgreement: React.ReactElement = (
    <Flex flexDirection="row" alignItems="center" justifyContent="space-between" margin="17px 0">
      <P
        fontSize={FontSizeREM.PX12}
        lineHeight={FontSizeREM.PX14}
        marginBottom="0px"
        fontWeight={400}
      >
        Eu autorizo a portabilidade da minha previdência privada para o Inter. Estou ciente de que,
        devido à oscilação do mercado, de acordo com a rentabilidade do fundo atrelado ao meu
        certificado, o meu saldo total poderá ser maior ou menor na data em que a portabilidade for
        efetivamente processada.
      </P>
      <InterUICheckBox
        variant="default"
        defaultChecked={termsAgreementAccepted}
        height="22px"
        width="22px"
        disabled={loadingConfirmBtn}
        onClick={() => setTermsAgreementAccepted(!termsAgreementAccepted)}
      />
    </Flex>
  );

  const stickyFooter: React.ReactElement = (
    <>
      <Separator
        marginTop="8px"
        marginBottom="16px"
        color={theme.colors.grayscale.A100}
        height="4px"
      />
      {acceptancePending ? (
        <>
          {TermsAgreement}
          <InterUIButton
            margin="0 0 18px 0"
            onClick={acceptExternalPortability}
            loading={loadingConfirmBtn}
            disabled={!acceptancePending || loadingConfirmBtn || !termsAgreementAccepted}
          >
            Autorizar portabilidade
          </InterUIButton>
          <InterUIButton
            onClick={cancelExternalPortabilityAndBackToHome}
            loading={loading}
            data-testid="cancel-pending-hire-proposal-button"
          >
            Cancelar portabilidade
          </InterUIButton>
        </>
      ) : (
        <InterUIButton margin="0 0 18px 0" onClick={goToAppHome}>
          Ir para a Home
        </InterUIButton>
      )}
    </>
  );

  const toggleExpandedContent = (index: number) => {
    selectedTargetPlans[index] = !selectedTargetPlans[index];
    setSelectedTargetPlans([...selectedTargetPlans]);
  };

  const defineOriginPlanSettings = useCallback((): ISectionSettings | undefined => {
    if (portabilityRequest === null) return undefined;

    const planSettings: ISectionSettings = {
      sectionTitle: Titles.OriginPlan.TITLE,
      fields: [
        {
          key: 1,
          name: Titles.Plan.SUSEP_NUMBER,
          value: portabilityRequest?.originSusep,
          style: defaultFlexItemStyle,
        },
        {
          key: 2,
          name: Titles.Plan.CERTIFICATE_NUMBER,
          value: portabilityRequest?.originCertificateNumber,
          style: defaultFlexItemStyle,
        },
        {
          key: 3,
          name: Titles.Plan.FUND_CNPJ,
          value: portabilityRequest?.originFundCnpj,
          style: defaultFlexItemStyle,
        },
        {
          key: 4,
          name: Titles.Plan.PLAN_TYPE,
          value: portabilityRequest?.originPlanType,
          style: defaultFlexItemStyle,
        },
        {
          key: 5,
          name: Titles.Plan.TAX_TYPE,
          value: portabilityRequest?.originTaxType,
          style: defaultFlexItemStyle,
        },
        {
          key: 6,
          name: Titles.Plan.PORTABILITY_TYPE,
          value: portabilityRequest?.type,
          style: defaultFlexItemStyle,
        },
        {
          key: 7,
          name: Titles.Plan.PORTABILITY_VALUE,
          value: (formatToBRLCurrency(portabilityRequest?.value) as unknown) as string,
          style: { flex: '10 0 10.5rem' },
        },
      ],
    };
    return planSettings;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portabilityRequest]);

  const definedTargetPlansSettings = useCallback((): ISectionSettings => {
    const buildTargetPlan = (fields: Array<IField>, p: PortabilityTargetPlan, i: number) => ({
      key: i,
      fields,
      fund: p.fund,
      selected: false,
      expandableContent: (
        <InterUIContainer margin="10px 10px 0px 10px">
          <Flex flexFlow="row wrap">
            {fields.map(({ key, name, value, style }) => (
              <Div key={key} style={style}>
                <P
                  fontSize={FontSizeREM.PX14}
                  fontWeight={400}
                  lineHeight={FontSizeREM.PX17}
                  marginBottom="4px"
                  color={Colors.shuttleGray}
                >
                  {name}
                </P>
                <P
                  fontSize={FontSizeREM.PX14}
                  fontWeight={700}
                  lineHeight={FontSizeREM.PX17}
                  marginBottom="14px"
                >
                  {value}
                </P>
              </Div>
            ))}
          </Flex>
        </InterUIContainer>
      ),
    });

    const plansSettings: ISectionSettings = {
      sectionTitle: Titles.TargetPlans.TITLE,
      existingPlans: [],
      newPlans: [],
    };

    portabilityRequest?.targetPlans.forEach((p, i) => {
      let fields: Array<IField>;
      let plan: ITargetPlanSetting;
      if (!p.requireHire) {
        fields = [
          {
            key: 1,
            name: Titles.Plan.SUSEP_NUMBER,
            value: p?.fund?.susep,
            style: defaultFlexItemStyle,
          },
          {
            key: 2,
            name: p?.certificateNumber
              ? Titles.Plan.CERTIFICATE_NUMBER
              : Titles.Plan.PROPOSAL_NUMBER,
            value: p?.certificateNumber || p?.proposalNumber,
            style: defaultFlexItemStyle,
          },
          {
            key: 3,
            name: Titles.Plan.FUND_CNPJ,
            value: p?.fund?.cnpj,
            style: defaultFlexItemStyle,
          },
          {
            key: 4,
            name: Titles.Plan.PLAN_TYPE,
            value: p?.planType.toString(),
            style: defaultFlexItemStyle,
          },
          {
            key: 5,
            name: Titles.Plan.TAX_TYPE,
            value: p?.taxType.toString(),
            style: defaultFlexItemStyle,
          },
          {
            key: 6,
            name: Titles.TargetPlans.DISTRIBUTION_PERCENTAGE,
            value: (formatToPercent(p?.distributionPercentage) as unknown) as string,
            style: defaultFlexItemStyle,
          },
        ];
        plan = buildTargetPlan(fields, p, i);
        plansSettings?.existingPlans?.push(plan);
      } else {
        fields = [
          {
            key: 1,
            name: Titles.Plan.SUSEP_NUMBER,
            value: p?.fund?.susep,
            style: defaultFlexItemStyle,
          },
          {
            key: 2,
            name: Titles.Plan.FUND_CNPJ,
            value: p?.fund?.cnpj,
            style: defaultFlexItemStyle,
          },
          {
            key: 3,
            name: Titles.Plan.PLAN_TYPE,
            value: p?.planType.toString(),
            style: defaultFlexItemStyle,
          },
          {
            key: 4,
            name: Titles.Plan.TAX_TYPE,
            value: p?.taxType.toString(),
            style: defaultFlexItemStyle,
          },
          {
            key: 5,
            name: Titles.TargetPlans.DISTRIBUTION_PERCENTAGE,
            value: (formatToPercent(p?.distributionPercentage) as unknown) as string,
            style: { flex: '6 0 10.5rem' },
          },
        ];
        plan = buildTargetPlan(fields, p, i);
        plansSettings?.newPlans?.push(plan);
      }

      selectedTargetPlans.push(false);
      setSelectedTargetPlans([...selectedTargetPlans]);
    });

    return plansSettings;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portabilityRequest]);

  useEffect(() => {
    setTargetPlanSettings(definedTargetPlansSettings());
    setOriginPlanSettings(defineOriginPlanSettings());
  }, [definedTargetPlansSettings, defineOriginPlanSettings]);

  return !loading && portabilityRequest ? (
    <InterUIContainer margin="24px" stickyFooter={stickyFooter}>
      <Div height="calc(100vh - 320px)" overflowY="scroll">
        {acceptancePending ? (
          <>
            <H2 marginBottom="20px"> {originPlanSettings?.sectionTitle}</H2>
            <Flex flexFlow="row wrap">
              {originPlanSettings?.fields
                ?.filter((op) => op.value)
                .map(({ key, name, value, style }) => (
                  <Div key={key} style={style}>
                    <P
                      fontSize={FontSizeREM.PX14}
                      fontWeight={400}
                      lineHeight={FontSizeREM.PX17}
                      marginBottom="4px"
                      color={Colors.shuttleGray}
                    >
                      {name}
                    </P>
                    <P
                      fontSize={FontSizeREM.PX14}
                      fontWeight={700}
                      lineHeight={FontSizeREM.PX17}
                      marginBottom="16px"
                    >
                      {value}
                    </P>
                  </Div>
                ))}
            </Flex>
            <Separator
              marginTop="4px"
              marginBottom="4px"
              color={theme.colors.grayscale.A100}
              height="4px"
            />

            <br />
            <H2 marginBottom="16px"> {targetPlanSettings?.sectionTitle}</H2>
            {targetPlanSettings?.existingPlans?.length !== 0 && (
              <H3 marginBottom="20px">Planos existentes</H3>
            )}
            {targetPlanSettings?.existingPlans?.map((plan, j) => (
              <Div key={`targetplan-${plan.key}`}>
                <InvestmentFundCard
                  investmentFund={plan.fund}
                  onSelect={() => toggleExpandedContent(plan.key)}
                  expandableContent={plan.expandableContent}
                  selected={selectedTargetPlans[plan.key]}
                />
                {targetPlanSettings.existingPlans &&
                  j !== targetPlanSettings.existingPlans?.length - 1 && <Div marginBottom="16px" />}
              </Div>
            ))}
            {targetPlanSettings?.newPlans?.length !== 0 && (
              <H3 marginTop="20px" marginBottom="20px">
                Novas contratações
              </H3>
            )}

            {targetPlanSettings?.newPlans?.map((plan, j) => (
              <Div key={`targetplan-${plan.key}`}>
                <InvestmentFundCard
                  investmentFund={plan.fund}
                  onSelect={() => toggleExpandedContent(plan.key)}
                  expandableContent={plan.expandableContent}
                  selected={selectedTargetPlans[plan.key]}
                />
                {targetPlanSettings.newPlans && j !== targetPlanSettings.newPlans?.length - 1 && (
                  <Div marginBottom="16px" />
                )}
              </Div>
            ))}
          </>
        ) : (
          <>
            <Flex alignItems="center" marginBottom="32px">
              <img
                style={{ width: '64px', height: '64px', margin: '25px 0px 15px 0px' }}
                src={SuccessImg}
                alt="Símbolo de sucesso"
              />
            </Flex>
            <Div>
              <H1 marginBottom="12px">Estamos processando sua solicitação de portabilidade!</H1>
              <P
                fontSize={FontSizeREM.PX14}
                lineHeight={FontSizeREM.PX17}
                fontWeight={400}
                color={theme.colors.grayscale.A400}
                marginBottom="24px"
              >
                Sua portabilidade está em processamento e será concluída em breve. Você pode entrar
                em contato através de um de nossos canais de atendimento para acompanhar o
                andamento.
              </P>
            </Div>
          </>
        )}
      </Div>
    </InterUIContainer>
  ) : (
    <Flex height="100vh" justifyContent="center" alignItems="center">
      <InterUILoading size="ld" data-testid="loading" />
    </Flex>
  );
};

export default ExternalPortabilityAcceptance;
