/* eslint-disable no-lonely-if */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/unbound-method */
import { yupResolver } from '@hookform/resolvers/yup';
import { InterUIBox, InterUIIcon } from '@interco/inter-ui-react-lib';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import * as yup from 'yup';
import IncomeTaxAnalyticsService from '../../../../analytics/incomeTaxAnalyticsService';
import { FormWithHook } from '../../../../components/FormWithHook/FormWithHook';
import InputPricingWithHook from '../../../../components/InputPricingWithHook/InputPricingWithHook';
import { getMessages, MessagesValidation } from '../../../../enums/messages';
import { PageRoutes } from '../../../../enums/pageRoutes';
import IncomeTaxPageActions from '../../../../store/incomeTax/actions';
import { ProposalDetailActions } from '../../../../store/proposal/actions';
import { ThemeInter } from '../../../../styles';
import { Div, PSmallGray } from '../../../../styles/commons';
import { InterUIAlertCustomized } from '../../../../styles/inter-ui-customizations';
import { handlerContributionStrategy } from '../../../../utils/analyticsUtils';
import { getMonthsToCompleteAnYear } from '../../../../utils/dateTimeUtils';
import { formatToBRLCurrency } from '../../../../utils/numberFormatUtils';
import { ContributionStrategyType } from '../ContributionStrategy/ContributionStrategy';

/**
 * Interface that represents the return of the form
 * each interface field must be exactly the name of each input
 * and each field represents only one input, so the names must be unique
 */
export interface IIncomeTaxForm {
  initialContribution: number | string;
  monthlyContribution: number | string;
}

interface IIncomeTaxFormProps {
  setDisabled: (disabled: boolean) => void;
  maximumDeductibleAmount: number;
}

function getSchemaByProps(initialMinimum: number, monthlyMinimum: number) {
  let initialField;
  let monthlyField;
  if (initialMinimum === 0) {
    initialField = yup.string().nullable();
  } else {
    initialField = yup
      .string()
      .test(
        'min',
        getMessages(MessagesValidation.MINIMUM_VALUE_VALIDATION, [
          formatToBRLCurrency(initialMinimum),
        ]),
        (value) => {
          let parsedValue = null;
          if (value) {
            parsedValue = +value;
          }
          return !!parsedValue && parsedValue >= initialMinimum;
        },
      )
      .required(MessagesValidation.INITIAL_CONTRIBUTION_REQUIRED);
    monthlyField = yup.string().nullable();
  }

  if (monthlyMinimum === 0) {
    monthlyField = yup.string().nullable();
  } else {
    monthlyField = yup
      .string()
      .test(
        'min',
        getMessages(MessagesValidation.MINIMUM_VALUE_VALIDATION, [
          formatToBRLCurrency(monthlyMinimum),
        ]),
        (value) => {
          let parsedValue = null;
          if (value) {
            parsedValue = +value;
          }
          return !!parsedValue && parsedValue >= monthlyMinimum;
        },
      )
      .required(MessagesValidation.MONTHLY_CONTRIBUTION_REQUIRED);
  }

  return yup.object().shape({
    initialContribution: initialField,
    monthlyContribution: monthlyField,
  });
}

export const IncomeTaxForm: React.FC<IIncomeTaxFormProps> = ({
  setDisabled,
  maximumDeductibleAmount,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme() as ThemeInter;
  const history = useHistory();
  const [annualContribution, setAnnualContribution] = useState(0);

  const contributionStrategy: string = useSelector(IncomeTaxPageActions.getContributionStrategy);
  const initialContribution = useSelector(IncomeTaxPageActions.getInitialContribution);
  const initialMinimum = useSelector(IncomeTaxPageActions.getInitialMinimum);
  const monthlyContribution = useSelector(IncomeTaxPageActions.getMonthlyContribution);
  const monthlyMinimum = useSelector(IncomeTaxPageActions.getMonthlyMinimum);
  const proposalId = useSelector(ProposalDetailActions.getSuggestedProposalId);
  const yearOfExercise = String(new Date().getFullYear() + 1);
  const isDisabledInitial =
    contributionStrategy === '' ||
    contributionStrategy === ContributionStrategyType.MONTHLY_CONTRIBUTION;

  const isDisabledMonthly =
    contributionStrategy === '' ||
    contributionStrategy === ContributionStrategyType.INITIAL_CONTRIBUTION;

  const methods = useForm<IIncomeTaxForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      initialContribution: initialContribution || undefined,
      monthlyContribution: monthlyContribution || undefined,
    },
    criteriaMode: 'all',
    resolver: yupResolver(getSchemaByProps(initialMinimum, monthlyMinimum)),
  });

  const {
    trigger,
    setValue,
    formState: { dirtyFields, isValid },
    getValues,
    watch,
  } = methods;

  const onSubmit = (data: IIncomeTaxForm) => {
    if (contributionStrategy === ContributionStrategyType.INITIAL_CONTRIBUTION) {
      dispatch(IncomeTaxPageActions.setInitialContribution(+data.initialContribution));
      dispatch(IncomeTaxPageActions.setMonthlyContribution(0.0));
    } else if (contributionStrategy === ContributionStrategyType.MONTHLY_CONTRIBUTION) {
      dispatch(IncomeTaxPageActions.setInitialContribution(0.0));
      dispatch(IncomeTaxPageActions.setMonthlyContribution(+data.monthlyContribution));
    } else {
      dispatch(IncomeTaxPageActions.setInitialContribution(+data.initialContribution));
      dispatch(IncomeTaxPageActions.setMonthlyContribution(+data.monthlyContribution));
    }
    dispatch(IncomeTaxPageActions.setAnnualContribution(annualContribution));

    IncomeTaxAnalyticsService.requestProposal({
      contribution_strategy: handlerContributionStrategy(contributionStrategy),
      initial_contribution: formatToBRLCurrency(initialContribution),
      monthly_contribution: formatToBRLCurrency(monthlyContribution),
      annual_contribution: formatToBRLCurrency(annualContribution),
    });

    dispatch(
      ProposalDetailActions.requestIncomeTaxProposalDetails({
        history,
        pathname: PageRoutes.PROPOSAL,
      }),
    );
  };

  const initialContributionDeductibleIncomeExpense = useMemo(() => {
    if (contributionStrategy !== ContributionStrategyType.INITIAL_CONTRIBUTION) {
      return undefined;
    }

    const initialContributionForm = +getValues('initialContribution') || 0;
    const deductibleIncomeExpense = +(maximumDeductibleAmount - initialContributionForm).toFixed(2);
    setAnnualContribution(initialContributionForm);

    if (deductibleIncomeExpense > 0) {
      return {
        message: getMessages(
          MessagesValidation.ANNUAL_CONTRIBUTION_UNDER_MAXIMUM_DEDUCTIBLE_AMOUNT_FOR_INITIAL,
          [formatToBRLCurrency(maximumDeductibleAmount), yearOfExercise],
        ),
      };
    }

    if (deductibleIncomeExpense < 0) {
      return {
        message: getMessages(MessagesValidation.MAXIMUM_DEDUCTIBLE_AMOUNT_EXCEEDS, [
          formatToBRLCurrency(maximumDeductibleAmount),
          yearOfExercise,
          formatToBRLCurrency(deductibleIncomeExpense * -1),
        ]),
      };
    }

    return undefined;
  }, [contributionStrategy, getValues('initialContribution')]);

  const monthlyContributionDeductibleInfo = useMemo(() => {
    if (
      !contributionStrategy ||
      contributionStrategy === ContributionStrategyType.INITIAL_CONTRIBUTION
    ) {
      return undefined;
    }

    const initialContributionForm = +getValues('initialContribution') || 0;
    const monthlyContributionForm = +getValues('monthlyContribution') || 0;
    const defaultPaymentDate = 10;
    const monthsToCompleteAnYear = getMonthsToCompleteAnYear(defaultPaymentDate);
    const diffBetweenMaximumDeductibleAndIniticalContribution =
      maximumDeductibleAmount - initialContributionForm;

    const idealMonthlyContribution = +(
      diffBetweenMaximumDeductibleAndIniticalContribution / monthsToCompleteAnYear
    ).toFixed(2);

    const idealAmountMonthlyContribution = +(
      idealMonthlyContribution * monthsToCompleteAnYear
    ).toFixed(2);

    /**
     * newMaximumDeductible is the maximumDeductibleAmount that is considering the rounded idealMonthlyContribution
     * In cases when the idealMonthlyContribution is exact ex: 500, 250, 100...
     * The newMaximumDeductible will be equal to the maximumDeductibleAmount;
     * In cases when the idealMonthlyContribution is a periodic tithe ex 133.33, 666.67...
     * The newMaximumDeductible will be considering the rounded monthly values
     * So we need to use as reference the newMaximumDeductible to calcular the deductibleIncomeExpense,
     * This is needed to hidden the card for when is suggested a rounded monthly contribution and the user input the suggestion;
     */
    const newMaximumDeductible = idealAmountMonthlyContribution + initialContributionForm;
    let deductibleIncomeExpense;
    const ammountMonthlyContribution = +(monthlyContributionForm * monthsToCompleteAnYear).toFixed(
      2,
    );

    if (monthsToCompleteAnYear > 0) {
      deductibleIncomeExpense =
        newMaximumDeductible - (ammountMonthlyContribution + initialContributionForm);

      setAnnualContribution(ammountMonthlyContribution + initialContributionForm);
    } else {
      deductibleIncomeExpense =
        maximumDeductibleAmount - (monthlyContributionForm + initialContributionForm);
      setAnnualContribution(monthlyContributionForm + initialContributionForm);
    }

    if (deductibleIncomeExpense > 0) {
      return {
        message: getMessages(
          MessagesValidation.ANNUAL_CONTRIBUTION_UNDER_MAXIMUM_DEDUCTIBLE_AMOUNT_FOR_MONTHLY,
          [formatToBRLCurrency(idealMonthlyContribution), yearOfExercise],
        ),
      };
    }

    if (deductibleIncomeExpense < 0) {
      /*
       * In this scenarious we need show the diff between the correct maximumDeductibleAmount and the annualContribution inputed
       */
      const rightDeductibleIncomeExpense =
        maximumDeductibleAmount - (ammountMonthlyContribution + initialContributionForm);
      return {
        message: getMessages(MessagesValidation.MAXIMUM_DEDUCTIBLE_AMOUNT_EXCEEDS, [
          formatToBRLCurrency(maximumDeductibleAmount),
          yearOfExercise,
          formatToBRLCurrency(rightDeductibleIncomeExpense * -1),
        ]),
      };
    }

    return undefined;
  }, [
    getValues('initialContribution'),
    getValues('monthlyContribution'),
    maximumDeductibleAmount,
    contributionStrategy,
  ]);

  const isDisableButton = useMemo(() => !isValid, [isValid]);

  useEffect(() => {
    setDisabled(isDisableButton);
  }, [isDisableButton, setDisabled]);

  /**
   * When @param initialMinimum state change check if is
   * neccessary to trigger the validations for this field
   */
  useEffect(() => {
    if (dirtyFields.initialContribution) {
      trigger('initialContribution');
    }
  }, [initialMinimum, dirtyFields.initialContribution, trigger]);

  /**
   * When @param monthlyMinimum state change check if is
   * neccessary to trigger the validations for this field
   */
  useEffect(() => {
    if (dirtyFields.monthlyContribution) {
      trigger('monthlyContribution');
    }
  }, [monthlyMinimum, dirtyFields.monthlyContribution, trigger]);

  useEffect(() => {
    if (contributionStrategy && proposalId) {
      trigger();
    }
  }, [trigger]);

  /**
   * Based on the contributionStrategy scroll to view
   * and set focus into the correct input reference;
   */
  useEffect(() => {
    switch (contributionStrategy) {
      case ContributionStrategyType.INITIAL_CONTRIBUTION: {
        const initialInput = document.getElementById('initialContribution') as HTMLInputElement;
        initialInput.scrollIntoView({ block: 'start', behavior: 'smooth' });
        initialInput.focus({ preventScroll: true });

        setValue('monthlyContribution', '0,00');
        break;
      }
      case ContributionStrategyType.MONTHLY_CONTRIBUTION: {
        const monthlyInput = document.getElementById('monthlyContribution') as HTMLInputElement;
        monthlyInput.scrollIntoView({ block: 'start', behavior: 'smooth' });
        monthlyInput.focus({ preventScroll: true });

        setValue('initialContribution', '0,00', { shouldValidate: true });
        break;
      }
      case ContributionStrategyType.INITIAL_MONTHLY_CONTRIBUTION: {
        const initialInput = document.getElementById('initialContribution') as HTMLInputElement;
        initialInput.scrollIntoView({ block: 'start', behavior: 'smooth' });
        initialInput.focus({ preventScroll: true });
        break;
      }
      default: {
        break;
      }
    }
  }, [contributionStrategy, setValue]);

  const watchCurrencyFields = watch(['initialContribution', 'monthlyContribution']);

  useEffect(() => {
    const subscription = watch((_, { name }) => {
      if (name === 'initialContribution')
        dispatch(
          IncomeTaxPageActions.setInitialContribution(Number(getValues('initialContribution'))),
        );
      else if (name === 'monthlyContribution')
        dispatch(
          IncomeTaxPageActions.setMonthlyContribution(Number(getValues('monthlyContribution'))),
        );
    });
    return () => subscription.unsubscribe();
  }, [watchCurrencyFields]);

  return (
    <FormWithHook onSubmit={onSubmit} methods={methods} formId="retirement-form" margin="0px">
      <InputPricingWithHook
        dataTestId="initial-contribution"
        name="initialContribution"
        label="Valor inicial"
        placeholder="0,00"
        prefix="R$"
        maxLength={14}
        margin="0"
        disabled={isDisabledInitial}
        shouldDirty
      />
      <Div marginBottom="12px">
        {initialContributionDeductibleIncomeExpense && (
          <InterUIBox padding="0px" margin="0 0 16px">
            <InterUIAlertCustomized
              color={theme.colors.white}
              icon={<InterUIIcon name="contextual-info" color={theme.colors.alert.A400} />}
              alignItems="center"
            >
              <PSmallGray marginBottom="0">
                {initialContributionDeductibleIncomeExpense.message}
              </PSmallGray>
            </InterUIAlertCustomized>
          </InterUIBox>
        )}
      </Div>
      <InputPricingWithHook
        dataTestId="monthly-contribution"
        name="monthlyContribution"
        label="Valor a cada mês"
        placeholder="0,00"
        prefix="R$"
        maxLength={14}
        margin="0"
        disabled={isDisabledMonthly}
        shouldDirty
      />
      <Div marginBottom="12px">
        {monthlyContributionDeductibleInfo && (
          <InterUIBox padding="0px" margin="0 0 16px">
            <InterUIAlertCustomized
              color={theme.colors.white}
              icon={<InterUIIcon name="contextual-info" color={theme.colors.alert.A400} />}
              alignItems="center"
            >
              <PSmallGray marginBottom="0">{monthlyContributionDeductibleInfo.message}</PSmallGray>
            </InterUIAlertCustomized>
          </InterUIBox>
        )}
      </Div>
    </FormWithHook>
  );
};
