import {
  InterUIAlert,
  InterUIButton,
  InterUIContainer,
  InterUIRadio,
} from '@interco/inter-ui-react-lib';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import ProposalAnalyticsService from '../../analytics/proposalAnalyticsService';
import FontSizeREM from '../../enums/fontSizesRem';
import PageTitles from '../../enums/pageTitles';
import { TaxTypes } from '../../enums/privatePensions';
import ErrorActions from '../../store/error/actions';
import NavbarActions from '../../store/navbar/actions';
import { ProposalDetailActions } from '../../store/proposal/actions';
import Colors from '../../styles/colors';
import { H1, P, PSmallBold, PSmallGray } from '../../styles/commons';

interface ITaxTypes {
  name: string;
  description: string;
  type: TaxTypes;
}

type ContributionTimeState = {
  annualContribution: number;
  months: number;
  suggestedTaxType: string;
};

const taxTypes: Array<ITaxTypes> = [
  {
    name: 'Tabela Regressiva',
    description: `Indicada pra quem precisa do dinheiro só lá no futuro, no longo prazo.
			O desconto sobre os rendimentos começa em 35% e vai diminuindo ao longo do tempo, até chegar em 10%.`,
    type: TaxTypes.REGRESSIVE,
  },
  {
    name: 'Tabela Progressiva',
    description: `Indicada pra quem talvez precise resgatar o valor em pouco tempo.
			O desconto é baseado na tabela progressiva do Imposto de Renda. Vai desde a isenção até 27,5%, dependendo do valor do resgate.`,
    type: TaxTypes.PROGRESSIVE,
  },
];

const TaxTypePage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { state: contributionTimeState } = useLocation<ContributionTimeState>();
  const [selectedTaxType, setSelectedTaxType] = useState<TaxTypes>(TaxTypes.EMPTY);
  const currentTaxType = useSelector(ProposalDetailActions.getTaxType);
  const proposalType = useSelector(ProposalDetailActions.getType);

  useEffect(() => {
    dispatch(NavbarActions.setTitle(PageTitles.TAX_TYPE));
    dispatch(ErrorActions.resetState());
  }, [dispatch]);

  useLayoutEffect(() => {
    if (contributionTimeState === undefined) setSelectedTaxType(currentTaxType);
  }, [contributionTimeState, currentTaxType]);

  const onSelectTaxType = (inputValue: string) => {
    const taxType = inputValue as TaxTypes;
    setSelectedTaxType(taxType);
  };

  const onSubmit = () => {
    const oldTaxType = currentTaxType;
    dispatch(ProposalDetailActions.setTaxType(selectedTaxType));

    ProposalAnalyticsService.editInfoConfirmation(
      {
        content_area: 'tabela',
        content_new_info: selectedTaxType,
        content_info: oldTaxType,
      },
      proposalType,
    );

    if (contributionTimeState === undefined) {
      history.goBack();
    } else {
      const goToProposalPage = -2;
      dispatch(ProposalDetailActions.setNumberMonthsInvesting(contributionTimeState.months));
      dispatch(
        ProposalDetailActions.setAnnualContribution(contributionTimeState.annualContribution),
      );
      history.go(goToProposalPage);
    }
  };

  const disableButton = () => {
    if (contributionTimeState === undefined)
      return selectedTaxType === currentTaxType || selectedTaxType === TaxTypes.EMPTY;
    return selectedTaxType === TaxTypes.EMPTY;
  };

  const renderTaxTypes: React.ReactElement[] = taxTypes.map((tax, index) => {
    const key = `tax-type-${index}`;
    return (
      <InterUIRadio
        variant="choose-item"
        data-testid={key}
        key={key}
        id={key}
        name="tax-type"
        value={tax.type}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onSelectTaxType(e.currentTarget.value)
        }
        checked={selectedTaxType === tax.type}
      >
        <PSmallBold marginBottom="4px">{tax.name}</PSmallBold>
        <PSmallGray fontSize={FontSizeREM.PX12} lineHeight={FontSizeREM.PX15} marginBottom="0px">
          {tax.description}
        </PSmallGray>
      </InterUIRadio>
    );
  });

  const stickyFooter: React.ReactElement = (
    <InterUIButton disabled={disableButton()} onClick={onSubmit}>
      Redefinir
    </InterUIButton>
  );

  return (
    <InterUIContainer stickyFooter={stickyFooter}>
      <H1 marginBottom="8px">Escolha sua tabela</H1>
      <P
        fontWeight={400}
        fontSize={FontSizeREM.PX14}
        lineHeight={FontSizeREM.PX18}
        color={Colors.shuttleGray}
        marginBottom="16px"
      >
        {contributionTimeState === undefined ? (
          <>Daqui quanto tempo você resgatará o valor investido? Isso pode te ajudar a escolher.</>
        ) : (
          <>
            Analisamos seu novo prazo de aposentadoria e percebemos que a{' '}
            <strong>tabela {contributionTimeState.suggestedTaxType} </strong>
            talvez faça mais sentido pra você agora:
          </>
        )}
      </P>
      {renderTaxTypes}
      <InterUIAlert margin="16px 0 24px 0">
        <P
          fontWeight={700}
          fontSize={FontSizeREM.PX12}
          lineHeight={FontSizeREM.PX15}
          marginBottom="4px"
        >
          Mudança de tabela
        </P>
        <P fontWeight={400} fontSize={FontSizeREM.PX12} lineHeight={FontSizeREM.PX15}>
          É possível mudar da tabela Progressiva para a Regressiva, mas não o contrário.
        </P>
      </InterUIAlert>
    </InterUIContainer>
  );
};

export default TaxTypePage;
