import { AnyAction } from 'redux';
import { action } from 'typesafe-actions';
import { RootState } from '..';
import { ErrorResponse, ErrorState, ErrorTypes } from './types';

const get = (state: RootState): ErrorState => state.error;
const show = (): AnyAction => action(ErrorTypes.SHOW);
const hide = (): AnyAction => action(ErrorTypes.HIDE);

const setErrorResponse = (errorResponse: ErrorResponse): AnyAction =>
  action(ErrorTypes.SET_ERROR_RESPONSE, errorResponse);

const getErrorResponse = (state: RootState): ErrorResponse => state.error.errorResponse;

const setErrorInstance = (errorInstance: Error): AnyAction =>
  action(ErrorTypes.SET_ERROR_INSTANCE, errorInstance);

const getErrorInstance = (state: RootState): Error => state.error.errorInstance;

const setIsDetailedError = (isDetailedError: boolean): AnyAction =>
  action(ErrorTypes.SET_IS_DETAILED_ERROR, isDetailedError);

const getIsDetailedError = (state: RootState): boolean => state.error.isDetailedError;

const resetState = (): AnyAction => action(ErrorTypes.RESET);

const ErrorActions = {
  setErrorResponse,
  getErrorResponse,
  setErrorInstance,
  getErrorInstance,
  setIsDetailedError,
  getIsDetailedError,
  resetState,
  get,
  show,
  hide,
};

export default ErrorActions;
