export enum ProposalByFundContributionValuesType {
  SET_CONTRIBUTION_VALUES = `@proposalByFundContributionValuesTypes/SET_CONTRIBUTION_VALUES`,
  SET_INITIAL_VALUE = `@proposalByFundContributionValuesTypes/SET_INITIAL_VALUE`,
  SET_MONTHLY_VALUE = `@proposalByFundContributionValuesTypes/SET_MONTHLY_VALUE`,
  SET_STRATEGY_CONTRIBUTION = `@proposalByFundContributionValuesTypes/SET_STRATEGY_CONTRIBUTION`,
  RESET = `@proposalByFundContributionValuesTypes/RESET`,
}

export enum ContributionStrategyType {
  INITIAL_CONTRIBUTION = 'INITIAL_CONTRIBUTION',
  MONTHLY_CONTRIBUTION = 'MONTHLY_CONTRIBUTION',
  INITIAL_MONTHLY_CONTRIBUTION = 'INITIAL_MONTHLY_CONTRIBUTION',
  EMPTY = '',
}

export interface ProposalByFundContributionValuesState {
  strategyContribution: ContributionStrategyType;
  initialValue: number;
  monthlyValue: number;
}
