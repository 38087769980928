import { AnyAction } from 'redux';
import { action } from 'typesafe-actions';
import { RootState } from '..';
import { InvestmentFund } from '../../components/InvestmentFundCard/InvestmentFundCard';
import { PrivatePensions, TaxTypes } from '../../enums/privatePensions';
import { SagaWithHistory } from '../../utils/providerSaga';
import {
  IncomeTax,
  InsuranceCoverage,
  IRRFTable,
  ProposalDetailsState,
  ProposalDetailsTypes,
  ProposalTypes,
} from './types';

const get = (state: RootState): ProposalDetailsState => state.proposalDetails;

const getSuggestedProposalId = (state: RootState): number =>
  state.proposalDetails.suggestedProposalId;

const getQualifiedCustomer = (state: RootState): boolean => state.proposalDetails.qualifiedCustomer;

const getPrivatePensionType = (state: RootState): PrivatePensions =>
  state.proposalDetails.privatePensionType;

const setPrivatePensionType = (privatePensionType: PrivatePensions): AnyAction =>
  action(ProposalDetailsTypes.SET_PRIVATE_PENSION_TYPE, privatePensionType);

const getTaxType = (state: RootState): TaxTypes => state.proposalDetails.taxType;

const setTaxType = (taxType: TaxTypes): AnyAction =>
  action(ProposalDetailsTypes.SET_TAX_TYPE, taxType);

const getType = (state: RootState): ProposalTypes => state.proposalDetails.type;

const setType = (type: ProposalTypes): AnyAction => action(ProposalDetailsTypes.SET_TYPE, type);

const getInitialContribution = (state: RootState): number =>
  state.proposalDetails.initialContribution;

const setInitialContribution = (initialContribution: number): AnyAction =>
  action(ProposalDetailsTypes.SET_INITIAL_CONTRIBUTION, initialContribution);

const getPortabilityValue = (state: RootState): number => state.proposalDetails.portabilityValue;

const setPortabilityValue = (portabilityValue: number): AnyAction =>
  action(ProposalDetailsTypes.SET_PORTABILITY_VALUE, portabilityValue);

const getMonthlyContribution = (state: RootState): number =>
  state.proposalDetails.monthlyContribution;

const setMonthlyContribution = (monthlyContribution: number): AnyAction =>
  action(ProposalDetailsTypes.SET_MONTHLY_CONTRIBUTION, monthlyContribution);

const getAnnualContribution = (state: RootState): number =>
  state.proposalDetails.annualContribution;

const setAnnualContribution = (annualContribution: number): AnyAction =>
  action(ProposalDetailsTypes.SET_ANNUAL_CONTRIBUTION, annualContribution);

const getNumberMonthsInvesting = (state: RootState): number =>
  state.proposalDetails.numberMonthsInvesting;

const setNumberMonthsInvesting = (numberMonthsInvesting: number): AnyAction =>
  action(ProposalDetailsTypes.SET_NUMBER_MONTHS_INVESTING, numberMonthsInvesting);

const getFirstDebitDate = (state: RootState): string => state.proposalDetails.firstDebitDate;

const setFirstDebitDate = (firstDebitDate: string): AnyAction =>
  action(ProposalDetailsTypes.SET_FIRST_DEBIT_DATE, firstDebitDate);

const getPaymentDay = (state: RootState): number => state.proposalDetails.paymentDay;

const setPaymentDay = (paymentDay: number): AnyAction =>
  action(ProposalDetailsTypes.SET_PAYMENT_DAY, paymentDay);

const getInsuranceCoverage = (state: RootState): InsuranceCoverage =>
  state.proposalDetails.insuranceCoverage;

const setInsuranceCoverage = (insuranceCoverage: InsuranceCoverage): AnyAction =>
  action(ProposalDetailsTypes.SET_INSURANCE_COVERAGE, insuranceCoverage);

const getInvestmentFund = (state: RootState): InvestmentFund =>
  state.proposalDetails.investmentFund;

const getIncomeTaxComplete = (state: RootState): IncomeTax | undefined =>
  state.proposalDetails.incomeTaxComplete;
const setIncomeTaxComplete = (incomeTaxComplete: IncomeTax | undefined): AnyAction =>
  action(ProposalDetailsTypes.SET_INCOME_TAX_COMPLETE, incomeTaxComplete);

const getIncomeTaxSimplified = (state: RootState): IncomeTax | undefined =>
  state.proposalDetails.incomeTaxSimplified;
const setIncomeTaxSimplified = (incomeTaxSimplified: IncomeTax | undefined): AnyAction =>
  action(ProposalDetailsTypes.SET_INCOME_TAX_SIMPLIFIED, incomeTaxSimplified);

const setInvestimentFund = (investmentFund: InvestmentFund): AnyAction =>
  action(ProposalDetailsTypes.SET_INVESTMENT_FUND, investmentFund);

const getHasInsuranceCoverage = (state: RootState): boolean | undefined =>
  state.proposalDetails.hasInsuranceCoverage;

const setHasInsuranceCoverage = (hasInsuranceCoverage: boolean | undefined): AnyAction =>
  action(ProposalDetailsTypes.SET_HAS_INSURANCE_COVERAGE, hasInsuranceCoverage);

const getIncomeTaxIRRFTable = (state: RootState): Array<IRRFTable> | undefined =>
  state.proposalDetails.incomeTaxIRRFTableResponse;

const setIncomeTaxIRRFTable = (incomeTaxIRRFTable: Array<IRRFTable> | undefined): AnyAction =>
  action(ProposalDetailsTypes.SET_IRRF_TABLE, incomeTaxIRRFTable);

const requestProposalDetails = (data?: SagaWithHistory): AnyAction =>
  action(ProposalDetailsTypes.REQUEST_PROPOSAL_DETAILS, data);

const setProposalDetails = (proposalDetails: ProposalDetailsState): AnyAction =>
  action(ProposalDetailsTypes.SET_PROPOSAL_DETAILS, proposalDetails);

const requestBuyGoodProposal = (data?: SagaWithHistory): AnyAction =>
  action(ProposalDetailsTypes.REQUEST_BUY_GOOD_PROPOSAL_DETAILS, data);

const requestIncomeTaxProposalDetails = (data?: SagaWithHistory): AnyAction =>
  action(ProposalDetailsTypes.REQUEST_INCOME_TAX_PROPOSAL_DETAILS, data);

const requestDeepLinkProposal = (data?: SagaWithHistory): AnyAction =>
  action(ProposalDetailsTypes.REQUEST_DEEP_LINK_PROPOSAL, data);

const requestProposalByFund = (data?: SagaWithHistory): AnyAction =>
  action(ProposalDetailsTypes.REQUEST_PROPOSAL_BY_FUND, data);

const requestPortabilityProposal = (data?: SagaWithHistory): AnyAction =>
  action(ProposalDetailsTypes.REQUEST_PORTABILITY_PROPOSAL, data);

const resetState = (): AnyAction => action(ProposalDetailsTypes.RESET);

export const ProposalDetailActions = {
  get,
  getSuggestedProposalId,
  getQualifiedCustomer,
  getPrivatePensionType,
  setPrivatePensionType,
  getTaxType,
  setTaxType,
  getType,
  setType,
  getInitialContribution,
  setInitialContribution,
  getMonthlyContribution,
  setMonthlyContribution,
  getAnnualContribution,
  setAnnualContribution,
  getPortabilityValue,
  setPortabilityValue,
  getNumberMonthsInvesting,
  setNumberMonthsInvesting,
  getFirstDebitDate,
  setFirstDebitDate,
  getPaymentDay,
  setPaymentDay,
  getInsuranceCoverage,
  setInsuranceCoverage,
  getInvestmentFund,
  setInvestimentFund,
  getHasInsuranceCoverage,
  setHasInsuranceCoverage,
  setProposalDetails,
  requestBuyGoodProposal,
  getIncomeTaxComplete,
  setIncomeTaxComplete,
  getIncomeTaxSimplified,
  setIncomeTaxSimplified,
  getIncomeTaxIRRFTable,
  setIncomeTaxIRRFTable,
  requestProposalDetails,
  requestIncomeTaxProposalDetails,
  requestDeepLinkProposal,
  requestProposalByFund,
  requestPortabilityProposal,
  resetState,
};
