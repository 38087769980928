/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import { InterUILoading } from '@interco/inter-ui-react-lib';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LocalStorageKeys } from '../../enums/localStorageKeys';
import { PageRoutes } from '../../enums/pageRoutes';
import { PrivatePensions } from '../../enums/privatePensions';
import LocalStorageService from '../../services/localStorageService';
import GlobalActions from '../../store/global/actions';
import { LoadingActions } from '../../store/loading/actions';
import NavbarActions from '../../store/navbar/actions';
import PrivatePensionPlanActions from '../../store/privatePensionPlan/actions';
import { PrivatePensionPlan, PrivatePensionPlanStatus } from '../../store/privatePensionPlan/types';
import UserInfoActions from '../../store/user/actions';
import { Flex } from '../../styles/commons';
import { formatCertificateNumber } from '../../utils/numberFormatUtils';
import { getFailedCertificateFromLocalStorage } from '../../utils/partialSuccessPrevsList';

const AdditionalContributionDeepLinkSplashPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { account } = useSelector(UserInfoActions.get);
  const { numeroCertificado } = useSelector(GlobalActions.getQueryParams);
  const loading = useSelector(LoadingActions.get);

  /**
   *
   * handleRedirect();
   * We have two ways to redirect, after a successful deeplink call:
   *
   * Example 1: ----------------------------------------------------------------
   * @params
   * URL: ?params=page:AporteEsporadico
   *
   * @redirect to the private pension plans list page
   *
   * Example 2: ----------------------------------------------------------------
   * @params
   * URL: ?params=page:AporteEsporadico;numeroCertificado: {certificateNumber}
   *
   * @redirect to specific private pension based in the fund certificate number
   *
   *
   */

  const handleRedirect = (arrayPrivatePensionPlans?: any) => {
    if (arrayPrivatePensionPlans) {
      if (arrayPrivatePensionPlans.length === 1) {
        const privatePensionPlan = arrayPrivatePensionPlans[0];
        const { status, isValidToContribute } = privatePensionPlan;

        dispatch(PrivatePensionPlanActions.setPrivatePensionPlan(privatePensionPlan));
        dispatch(NavbarActions.setCanGoBack(false));

        if (!isValidToContribute) {
          history.push(PageRoutes.ADDITIONAL_CONTRIBUTION_EXPIRED);
        } else if (status === PrivatePensionPlanStatus.ERROR) {
          history.push(PageRoutes.HOME);
        } else {
          if (privatePensionPlan.privatePensionType === PrivatePensions.PGBL) {
            history.push(PageRoutes.ADDITIONAL_CONTRIBUTION_PGBL_HELPER);
          }
          if (privatePensionPlan.privatePensionType === PrivatePensions.VGBL) {
            history.push(PageRoutes.ADDITIONAL_CONTRIBUTION_INVESTMENT_FORM);
          }
        }
      } else if (arrayPrivatePensionPlans.length > 1) {
        history.push(PageRoutes.PRIVATE_PENSION_PLANS);
      }
    } else {
      history.push(PageRoutes.OBJECTIVES_HOME);
    }

    dispatch(LoadingActions.hide());
  };

  /**
   *
   * handleDeeplinkParams()
   * Function that is called when the data from local storage is valid.
   * Basically this function, treats the deep link params and after that calls the redirect.
   *
   */
  const handleDeeplinkParams = (dataFromLocalStorage: Array<PrivatePensionPlan>) => {
    let privatePensionArray;

    if (numeroCertificado) {
      /**
       *
       * If we have the certificateNumber present in the deep link params:
       * We are going the make a filter based in the data from local storage,
       * to see if that certificate number exist.
       *
       * If it does exist, we are going to have a array with length 1.
       *
       * If it does not, we are going to set privatePensionArray as undefined.
       *
       * And then, ater we call handleRedirect(), we have 2 cases:
       * 1 : (IN CASE TO FAILURE TO FIND CERTIFICATE NUMBER): The user will be redirected to home page, or private pension plans page.
       * 2 : (IN CASE TO SUCCESSFULLY FIND CERTIFICATE NUMBER): The user will be redirected to specific private pension plan page.
       *
       */
      const privatePensionPlans = dataFromLocalStorage?.filter(
        (element: any) =>
          formatCertificateNumber(element?.certificateNumber) ===
          formatCertificateNumber(numeroCertificado),
      );
      if (privatePensionPlans?.length > 0) {
        privatePensionArray = privatePensionPlans;
        dispatch(NavbarActions.setCanGoBack(false));
      }
    } else {
      privatePensionArray = dataFromLocalStorage;
    }

    handleRedirect(
      privatePensionArray || (dataFromLocalStorage?.length > 1 && dataFromLocalStorage),
    );
  };

  const getLocalStorageItem = () =>
    LocalStorageService.getItem<PrivatePensionPlan[]>(
      LocalStorageKeys.PRIVATE_PENSION_PLANS,
      account,
    );

  const hasValidLocalStorageItem = () =>
    !LocalStorageService.hasExpired(LocalStorageKeys.PRIVATE_PENSION_PLANS, account);

  useEffect(() => {
    dispatch(LoadingActions.show());
    if (account) {
      const dataFromLocalStorage = getLocalStorageItem();
      const failedPrivatePensionPlans = getFailedCertificateFromLocalStorage(account);

      if (dataFromLocalStorage && hasValidLocalStorageItem()) {
        if (failedPrivatePensionPlans && failedPrivatePensionPlans.length > 0) {
          dispatch(
            PrivatePensionPlanActions.setFailedPrivatePensionPlans(failedPrivatePensionPlans),
          );
          dispatch(
            PrivatePensionPlanActions.requestPrivatePensionPlanDeeplink({
              history,
              pathname: PageRoutes.PRIVATE_PENSION_PLANS,
            }),
          );
        }

        handleDeeplinkParams(dataFromLocalStorage.data);
      } else {
        dispatch(
          PrivatePensionPlanActions.requestPrivatePensionPlanDeeplink({
            history,
            pathname: PageRoutes.PRIVATE_PENSION_PLANS,
          }),
        );
      }
    }
  }, [account]);

  return (
    <Flex height="100vh" justifyContent="center" alignItems="center">
      {loading && <InterUILoading size="ld" data-testid="loading" />}
    </Flex>
  );
};

export default AdditionalContributionDeepLinkSplashPage;
