function convertValueMaskedToNumber(maskedValue: string): number {
  return parseInt(`${maskedValue}`.replace(/[\D]+/g, '')) / 100;
}

function getNumericTypedValue(value: number): number {
  const typedValue = Math.round((value + Number.EPSILON) * 100);
  return parseInt(`${typedValue}`);
}

function getInputSizeByText(text: string): string {
  let size = 0;
  const value = text.split('');

  value.forEach((char) => {
    if (char.match(/[.,]/g)) {
      size += 9;
    } else if (char.match(/[1]/g)) {
      size += 11;
    } else if (char.match(/[7]/g)) {
      size += 15;
    } else if (char.match(/[235]/g)) {
      size += 16;
    } else if (char.match(/[4689]/g)) {
      size += 17;
    } else if (char.match(/[0]/g)) {
      size += 18;
    }
  });

  return `${size}px`;
}

export { convertValueMaskedToNumber, getNumericTypedValue, getInputSizeByText };
