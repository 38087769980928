import { useEffect, useState } from 'react';

export function useNewPGBL(
  initialPGBL: number,
  annualContribution: number,
  annualIncome: number,
): number {
  const percentageOfMaxDeduction = 0.12;
  const maxDeduction = annualIncome * percentageOfMaxDeduction;

  const [newPGBL, setNewPGBL] = useState(initialPGBL);

  useEffect(() => {
    setNewPGBL(annualContribution >= maxDeduction ? maxDeduction : annualContribution);
  }, [annualContribution, maxDeduction]);

  return newPGBL;
}
