import { AnyAction } from 'redux';
import { action } from 'typesafe-actions';
import { RootState } from '..';
import { PortabilityStatus, PortabilityStatusTypes } from './types';

const getPortabilitiesStatus = (state: RootState): PortabilityStatus[] | [] =>
  state.portabilitiesStatus.portabilitiesStatus;

const setPortabilitiesStatus = (portabilitiesStatus: PortabilityStatus[] | null): AnyAction =>
  action(PortabilityStatusTypes.SET_PORTABILITIES, portabilitiesStatus);

const PostabilitiesStatusActions = {
  getPortabilitiesStatus,
  setPortabilitiesStatus,
};

export default PostabilitiesStatusActions;
