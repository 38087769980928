import { useEffect, useState } from 'react';

export function useNewRestitutionValue(
  initialRestitutionValue: number,
  taxAlreadyPaid: number,
  totalTaxPayable: number,
): number {
  const [newRestitutionValue, setNewRestitutionValue] = useState(initialRestitutionValue);

  useEffect(() => {
    setNewRestitutionValue(taxAlreadyPaid - totalTaxPayable);
  }, [taxAlreadyPaid, totalTaxPayable]);

  return newRestitutionValue;
}
