import React from 'react';
import { useTheme } from 'styled-components';
import FontSizeREM from '../../../enums/fontSizesRem';
import { ThemeInter } from '../../../styles';
import { Flex, P } from '../../../styles/commons';

export interface ICardFooterProps {
  label: string;
  value: string;
}

const CardFooter: React.FC<ICardFooterProps> = ({ label, value }) => {
  const theme = useTheme() as ThemeInter;

  return (
    <Flex flexDirection="row" justifyContent="space-between" padding="0 12px 8px">
      <P
        fontSize={FontSizeREM.PX12}
        lineHeight={FontSizeREM.PX14}
        fontWeight={400}
        color={theme.colors.grayscale.A400}
      >
        {label}
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        lineHeight={FontSizeREM.PX16}
        fontWeight={700}
        color={theme.colors.grayscale.A500}
      >
        {value}
      </P>
    </Flex>
  );
};

export default CardFooter;
