import { InterUIButton, InterUIContainer } from '@interco/inter-ui-react-lib';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { InfoInitialMonthlyContribution } from '../../components';
import PageTitles from '../../enums/pageTitles';
import ErrorActions from '../../store/error/actions';
import { IncomeExpenseActions } from '../../store/incomeExpenses/actions';
import IncomeTaxPageActions from '../../store/incomeTax/actions';
import { LoadingActions } from '../../store/loading/actions';
import NavbarActions from '../../store/navbar/actions';
import { ThemeInter } from '../../styles';
import { H1, PBlack, PSmallGray, SeparatorDashed } from '../../styles/commons';
import { ANNUAL_INCOME_PERCENTAGE } from '../../utils/constantsValues';
import { formatToBRLCurrency } from '../../utils/numberFormatUtils';
import { ContributionStrategyOptions, IncomeTaxForm } from './components';

const IncomeTaxPage: React.FC = () => {
  const theme = useTheme() as ThemeInter;
  const choosedContributionStrategy = useSelector(IncomeTaxPageActions.getContributionStrategy);
  const initialContribution = useSelector(IncomeTaxPageActions.getInitialContribution);
  const monthlyContribution = useSelector(IncomeTaxPageActions.getMonthlyContribution);
  const loading = useSelector(LoadingActions.get);
  const dispatch = useDispatch();
  const [disabledButton, setDisabledButton] = useState<boolean>(true);
  const formId = 'retirement-form';

  const incomeExpense = useSelector(IncomeExpenseActions.get);

  const maximumDeductibleAmount = +(incomeExpense.annualIncome * ANNUAL_INCOME_PERCENTAGE).toFixed(
    2,
  );

  useEffect(() => {
    dispatch(NavbarActions.setTitle(PageTitles.INCOME_TAX));
    dispatch(ErrorActions.resetState());
  }, [dispatch]);

  const stickyFooter: React.ReactElement = (
    <InterUIButton
      type="submit"
      data-testid="retirement-form-btn"
      form={formId}
      disabled={disabledButton}
      loading={loading}
    >
      Mostrar plano indicado
    </InterUIButton>
  );

  return (
    <InterUIContainer stickyFooter={stickyFooter}>
      <H1 marginBottom="8px">Seu jeito de investir</H1>
      <PSmallGray marginBottom="24px">
        Escolha como você quer investir para alcançar o seu objetivo de economizar no seu IR.
      </PSmallGray>
      <ContributionStrategyOptions />
      <SeparatorDashed marginTop="22px" marginBottom="24px" color={theme.colors.grayscale.A200} />
      <PBlack disabled={choosedContributionStrategy === ''}>Defina os valores</PBlack>
      <PSmallGray marginBottom="16px" disabled={choosedContributionStrategy === ''}>
        <strong>Até 12% da sua renda anual tributável pode ser deduzida</strong> da base de cálculo
        IR, se investida na previdência privada. Pela renda que você nos informou,{' '}
        <strong>isso equivale investir {formatToBRLCurrency(maximumDeductibleAmount)}</strong> ao
        longo do ano fiscal.
      </PSmallGray>
      <IncomeTaxForm
        setDisabled={setDisabledButton}
        maximumDeductibleAmount={maximumDeductibleAmount}
      />
      <InfoInitialMonthlyContribution
        strategy={choosedContributionStrategy}
        initialContribution={initialContribution}
        monthlyContribution={monthlyContribution}
      />
    </InterUIContainer>
  );
};

export default IncomeTaxPage;
