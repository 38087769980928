import { InterUIIcon, InterUIRadio, InterUITag } from '@interco/inter-ui-react-lib';
import React from 'react';
import { useTheme } from 'styled-components';
import FontSizeREM from '../../../enums/fontSizesRem';
import { ThemeInter } from '../../../styles';
import { Div, Flex, P } from '../../../styles/commons';
import CardStatus from '../CardStatus/CardStatus';
import { ExpandCollpaseBtn } from './CardHeader.styles';

export interface ICardHeaderProps {
  name: string;
  interFund: boolean;
  riskLevel: number;
  selected: boolean;
  qualified: boolean;
  selectable?: boolean;
  expandable?: boolean;
}

const CardHeader: React.FC<ICardHeaderProps> = ({
  name,
  riskLevel,
  selected,
  qualified,
  selectable,
  expandable,
}) => {
  const theme = useTheme() as ThemeInter;

  const HeaderBtn = () => {
    if (expandable) {
      return (
        <ExpandCollpaseBtn toggle={selected}>
          <InterUIIcon name="arrow-chevron-down" size="md" color={theme.colors.primary.A500} />
        </ExpandCollpaseBtn>
      );
    }

    return <InterUIRadio readOnly border="none" variant="default" checked={selected} padding="0" />;
  };

  return (
    <Div borderRadius="8px 8px 0px 0px;" backgroundColor={theme.colors.white}>
      <Flex flexDirection="row" justifyContent="flex-start" gap="8px" padding="0 12px">
        {qualified && (
          <InterUITag
            color={theme.colors.grayscale.A500}
            backgroundColor={theme.colors.grayscale.A100}
            margin="16px 0 0 0"
          >
            Investidor qualificado
          </InterUITag>
        )}
      </Flex>
      <Flex
        flexDirection="row"
        padding="16px 12px"
        backgroundColor={theme.colors.white}
        borderRadius="8px 8px 0px 0px;"
      >
        <Flex flexDirection="column">
          <CardStatus riskLevel={riskLevel} />
        </Flex>
        <Flex flexDirection="column" margin="0 20px 0 16px">
          <P
            marginBottom="0"
            lineHeight={FontSizeREM.PX22}
            fontSize={FontSizeREM.PX18}
            fontWeight={700}
          >
            {name}
          </P>
        </Flex>
        <Flex flexDirection="column" width="32px">
          <Div position="absolute" top="14px" right="14px">
            {(selectable || expandable) && HeaderBtn()}
          </Div>
        </Flex>
      </Flex>
    </Div>
  );
};
export default CardHeader;
