import styled from 'styled-components';
import FontSizeREM from '../../../../enums/fontSizesRem';

export const Point = styled.div<{ color: string }>`
  height: 12px;
  width: 12px;
  border-radius: 8px;
  background-color: ${(props) => props.color};
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

export const LegendChart = styled.div`
  display: flex;
  font-family: 'Inter';
  font-weight: 700;
  font-size: ${FontSizeREM.PX10};
  line-height: ${FontSizeREM.PX12};
  margin-bottom: 8px;
`;
