import { PrivatePensions, TaxTypes } from '../../enums/privatePensions';

export enum QueryParamsTypes {
  SET_QUERY_PARAMS = `@queryParams/SET_QUERY_PARAMS`,
  RESET = `@queryParams/RESET`,
}

export interface QueryParamsState {
  codigoFundo?: string;
  diaDebito?: string;
  modalidade?: PrivatePensions;
  tabela?: TaxTypes;
  tempoContribuicaoMeses?: string;
  valorInicial?: string;
  valorMensal?: string;
  numeroCertificado?: string;
  prefix?: string;
  page?: string;
  portabilidadeId?: number;
  /**
   * This param determine the @param MockService.shouldMock
   */
  mock?: boolean;
  /**
   * This param determine the @param MockService.account to be used in the requests
   */
  mockContaCorrente?: string;
  /**
   * This param determine the @param MockService.apiKey
   * to be used to communicate with the mock server
   */
  mockApiKey?: string;
  useBelvoApiMock?: boolean;
}
