export enum EnvVariableKeys {
  INTER_ENV = 'REACT_APP_INTER_ENV',
  API_HOST = 'REACT_APP_API_HOST',
  API_PENSION_PROPOSAL_V1 = 'REACT_APP_API_PENSION_PROPOSAL_V1',
  API_HIRE_PENSION_PROPOSAL_V1 = 'REACT_APP_API_HIRE_PENSION_PROPOSAL_V1',
  API_PENSION_PORTABILITY_V1 = 'REACT_APP_API_PENSION_PORTABILITY_V1',
  API_PRIVATE_PENSION_PLANS_V1 = 'REACT_APP_API_PRIVATE_PENSION_PLANS_V1',
  API_USER_INFO_V3 = 'REACT_APP_API_USER_INFO_V3',
  API_BRIDGE_PROPOSAL_V1 = 'REACT_APP_API_BRIDGE_PROPOSAL_V1',
  API_BRIDGE_HIRE_PENSION_PROPOSAL_V1 = 'REACT_APP_API_BRIDGE_HIRE_PENSION_PROPOSAL_V1',
  API_BRIDGE_PENSION_PORTABILITY_V1 = 'REACT_APP_API_BRIDGE_PENSION_PORTABILITY_V1',
  API_BRIDGE_PRIVATE_PENSION_PLANS_V1 = 'REACT_APP_API_BRIDGE_PRIVATE_PENSION_PLANS_V1',
  API_BRIDGE_BALANCE_V3 = 'REACT_APP_API_BRIDGE_BALANCE_V3',
  MOCK_SERVER_BASE_URL = 'REACT_APP_MOCK_SERVER_BASE_URL',
  NEW_RELIC_AGENT = 'REACT_APP_NEW_RELIC_AGENT',
  LOCAL_LOG_ENABLED = 'REACT_APP_LOCAL_LOG_ENABLED',
  REMOTE_LOG_ENABLED = 'REACT_APP_REMOTE_LOG_ENABLED',
  USE_REDIRECT_PREFIX = 'REACT_APP_USE_REDIRECT_PREFIX',
  IS_PREV_UNAVAILABLE = 'REACT_APP_IS_PREV_UNAVAILABLE',
  PREV_RETURN_DATE = 'REACT_APP_PREV_RETURN_DATE',
  IS_ADDITIONAL_CONTRIBUTION_AVAILABLE = 'REACT_APP_IS_ADDITIONAL_CONTRIBUTION_AVAILABLE',
  REMOTE_BRIDGE_DEBUG_LOG_ENABLED = 'REACT_APP_REMOTE_BRIDGE_DEBUG_LOG_ENABLED',
  BELVO_SCRIPT = 'REACT_APP_BELVO_SCRIPT',
  PORTABILITY_REQUEST_TIMEOUT = 'REACT_APP_PORTABILITY_REQUEST_TIMEOUT',
  ACCEPTABLE_WAITING_TIME_TO_SHOW_ALERT = 'REACT_APP_ACCEPTABLE_WAITING_TIME_TO_SHOW_ALERT',
  MAX_ACCEPTABLE_WAITING_TIME_TO_SHOW_ALERT = 'REACT_APP_MAX_ACCEPTABLE_WAITING_TIME_TO_SHOW_ALERT',
  BIGGER_ACCEPTABLE_CONTRIBUTION_TIME_TO_SHOW_ALERT = 'REACT_APP_BIGGER_ACCEPTABLE_CONTRIBUTION_TIME_TO_SHOW_ALERT',
  STARTING_VERSION_TO_VALIDATE_CUSTOMER_SUITABILITY = 'REACT_APP_STARTING_VERSION_TO_VALIDATE_CUSTOMER_SUITABILITY',
}
