/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/unbound-method */
import { AnyAction } from 'redux';
import { call, put, SagaGenerator, select, takeLeading } from 'typed-redux-saga';
import { HireProposalStatus } from '../../enums/privatePensions';
import BridgeService from '../../services/bridgeService';
import { HttpStatus } from '../../services/constants';
import HirePortabilityProposalService, {
  HirePortabilityProposalBody,
} from '../../services/hirePortabilityProposalService';
import HireProposalService from '../../services/hireProposalService';
import { errorHandlingSaga, redirectThroughSaga } from '../../utils/providerSaga';
import { LoadingActions } from '../loading/actions';
import PortabilityActions from '../portability/actions';
import { ProposalDetailActions } from '../proposal/actions';
import { iSafeRequest } from '../storeConstants';
import { HireProposalActions } from './actions';
import { HireProposalTypes } from './types';

function* requestHireProposal({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show());

    const bodyRequest = yield* select(HireProposalActions.get);

    const iSafeResponse = yield* call(BridgeService.getISafeToken, iSafeRequest);

    const { data } = yield* call(HireProposalService.getHireReceipt, iSafeResponse, bodyRequest);

    yield* put(HireProposalActions.setChosenProposalId(data.chosenProposalId));

    yield* put(LoadingActions.hide());

    redirectThroughSaga(payload);
  } catch (error: any) {
    yield* errorHandlingSaga(error, 'requestHireProposalSaga');
  }
}

function* requestCustomerPendingHireProposal({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show());

    const allowedStatus = HireProposalStatus.ACEITE_PENDENTE;
    const size = 1;
    const order = 'DESC';

    const response = yield* call(
      HireProposalService.getCustomerHireProposals,
      allowedStatus,
      size,
      order,
    );

    if (response.status === HttpStatus.OK && response.data.content[0]) {
      yield* put(HireProposalActions.setPendingHireProposal(response.data.content[0]));
    } else if (payload.callback) {
      payload.callback();
    }
  } catch (error: any) {
    console.warn('Erro ao consultar possíveis contratações pendentes', error);
  } finally {
    yield* put(LoadingActions.hide());
  }
}

function* cancelPendingHireProposal({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show());

    const iSafeResponse = yield* call(BridgeService.getISafeToken, iSafeRequest);

    const bodyRequest = { status: HireProposalStatus.CANCELADA };

    yield* call(
      HireProposalService.cancelPendingHireProposal,
      payload.id,
      iSafeResponse,
      bodyRequest,
    );

    yield* put(HireProposalActions.resetState());

    yield* put(LoadingActions.hide());

    redirectThroughSaga(payload.data, payload.receiptPageProps);
  } catch (error: any) {
    yield* errorHandlingSaga(error, 'cancelHireProposalSaga');
  }
}

function* signPendingHireProposal({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show());

    const iSafeResponse = yield* call(BridgeService.getISafeToken, iSafeRequest);

    const bodyRequest = { chosenProposalIds: [payload.id] };

    yield* call(HireProposalService.signPendingHireProposal, iSafeResponse, bodyRequest);

    yield* put(LoadingActions.hide());

    redirectThroughSaga(payload.data);
  } catch (error: any) {
    yield* errorHandlingSaga(error, 'signHireProposalSaga');
  }
}

function* requestHirePortabilityProposal({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show());

    const iSafeResponse = yield* call(BridgeService.getISafeToken, iSafeRequest);

    const { numberMonthsInvesting, investmentFund } = yield* select(ProposalDetailActions.get);
    const { externalPensionPlan, portabilityId } = yield* select(PortabilityActions.get);

    if (externalPensionPlan?.id && portabilityId) {
      const bodyRequest: HirePortabilityProposalBody = {
        investmentFundCode: investmentFund.code,
        originPrivatePensionId: externalPensionPlan?.id,
        portabilityId: Number(portabilityId),
        contributionMonthly: numberMonthsInvesting,
      };

      const { data } = yield* call(
        HirePortabilityProposalService.getHireProposalReceipt,
        iSafeResponse,
        bodyRequest,
      );

      yield* put(HireProposalActions.setChosenProposalId(data.chosenProposalId));
    }

    yield* put(LoadingActions.hide());

    redirectThroughSaga(payload);
  } catch (error: any) {
    yield* errorHandlingSaga(error, 'requestHirePortabilityProposal');
  }
}

export default function* watchHireProposal(): SagaGenerator<void> {
  yield* takeLeading(HireProposalTypes.REQUEST_HIRE_PROPOSAL, requestHireProposal);
  yield* takeLeading(
    HireProposalTypes.REQUEST_HIRE_PORTABILITY_PROPOSAL,
    requestHirePortabilityProposal,
  );
  yield* takeLeading(HireProposalTypes.GET_HIRE_PROPOSAL, requestCustomerPendingHireProposal);
  yield* takeLeading(HireProposalTypes.CANCEL_PENDING_HIRE_PROPOSAL, cancelPendingHireProposal);
  yield* takeLeading(HireProposalTypes.SIGN_PENDING_HIRE_PROPOSAL, signPendingHireProposal);
}
