import AnalyticsBaseEventName from '../enums/analyticsBaseEventNames';
import BridgeService from '../services/bridgeService';

class HomeAnalyticsService {
  private static className = 'HomeAnalyticsService';

  static restrictionWarning(content_name: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.restrictionWarning`,
      `${AnalyticsBaseEventName.PRIVATE_PENSION}Restricao_DC`,
      { content_name },
    );
  }

  static clickedButton(content_name: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.clickedButton`,
      `${AnalyticsBaseEventName.PRIVATE_PENSION}Home_T_Botao`,
      { content_name },
    );
  }

  static backButton(tela: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.backButton`,
      `${AnalyticsBaseEventName.PRIVATE_PENSION}T_Voltar`,
      {
        tela,
      },
    );
  }

  static retirementButton(): void {
    BridgeService.requestAnalytics(
      `${this.className}.retirementButton`,
      `${AnalyticsBaseEventName.PRIVATE_PENSION}Home_T_GarantirAposent`,
    );
  }

  static incomeExpenseButton(): void {
    BridgeService.requestAnalytics(
      `${this.className}.incomeExpenseButton`,
      `${AnalyticsBaseEventName.PRIVATE_PENSION}Home_T_EconomizarImpostoRenda`,
    );
  }

  static buyGoodButton(goal: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.buyGoodButton`,
      `${AnalyticsBaseEventName.PRIVATE_PENSION}Home_T_${goal}`,
    );
  }

  static investMoreButton(): void {
    BridgeService.requestAnalytics(
      `${this.className}.investMoreButton`,
      `${AnalyticsBaseEventName.ADDITIONAL_CONTRIBUTION}Home_T_InvestirNoMeuPlano`,
    );
  }

  static suitabilityDeeplinkButton(): void {
    BridgeService.requestAnalytics(
      `${this.className}.suitabilityDeeplinkButton`,
      `${AnalyticsBaseEventName.INVESTMENTS}Home_T_DeepLinkSuitability`,
    );
  }

  static investorProfileDeeplinkButton(): void {
    BridgeService.requestAnalytics(
      `${this.className}.investorProfileDeeplinkButton`,
      `${AnalyticsBaseEventName.INVESTMENTS}Home_T_DeepLinkInvestorProfile`,
    );
  }

  static privatePensionFAQ(content_name: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.privatePensionFAQ`,
      `${AnalyticsBaseEventName.PRIVATE_PENSION}T_PerguntasFrequentes`,
      { content_name },
    );
  }
}

export default HomeAnalyticsService;
