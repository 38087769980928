import { InterUIButton } from '@interco/inter-ui-react-lib';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ProposalAnalyticsService from '../../analytics/proposalAnalyticsService';
import { InvestmentFund } from '../../components/InvestmentFundCard/InvestmentFundCard';
import SelectableFundsList from '../../components/SelectableFundsList/SelectableFundsList';
import { LocalStorageKeys } from '../../enums/localStorageKeys';
import PageTitles from '../../enums/pageTitles';
import LocalStorageService from '../../services/localStorageService';
import ContributionValuesAction from '../../store/contributionValues/actions';
import ErrorActions from '../../store/error/actions';
import IncomeTaxPageActions from '../../store/incomeTax/actions';
import NavbarActions from '../../store/navbar/actions';
import { ProposalDetailActions } from '../../store/proposal/actions';
import { ProposalTypes } from '../../store/proposal/types';
import { ProposalByFundActions } from '../../store/proposalByFund/actions';
import RetirementPageActions from '../../store/retirement/actions';
import Colors from '../../styles/colors';
import { Div, H3, PSmallGray } from '../../styles/commons';
import { InterContainerNoFooter } from '../../styles/inter-ui-customizations';
import {
  defineMinimumMonthlyContribution,
  INITIAL_CONTRIBUTION_MINIMUM,
  MONTHLY_CONTRIBUTION_MINIMUM,
} from '../../utils/minValues';
import { formatToBRLCurrency } from '../../utils/numberFormatUtils';
import { ContributionStrategyType } from '../IncomeTax/components/ContributionStrategy/ContributionStrategy';

const SelectableFundsPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const type = useSelector(ProposalDetailActions.getType);
  const initialContribution = useSelector(ProposalDetailActions.getInitialContribution);
  const monthlyContribution = useSelector(ProposalDetailActions.getMonthlyContribution);
  const portabilityValue = useSelector(ProposalDetailActions.getPortabilityValue);

  const currentFund = useSelector(ProposalDetailActions.getInvestmentFund);
  const {
    initialContribution: newInitialContribution,
    monthlyContribution: newMonthlyContribution,
    annualContribution,
    isChangeRequired,
  } = useSelector(ContributionValuesAction.getContributionValues);
  const [selectedFund, setSelectedFund] = useState(currentFund);

  const newOnlyInitialContribution = newInitialContribution > 0 && newMonthlyContribution === 0;
  const newOnlyMonthlyContribution = newMonthlyContribution > 0 && newInitialContribution === 0;
  const onlyInitialContribution = initialContribution > 0 && monthlyContribution === 0;
  const onlyMonthlyContribution = monthlyContribution > 0 && initialContribution === 0;

  const noChangeFundRequiredSubtitle = () => {
    let subtitle = `Listamos aqui os fundos que permitem `;
    const fundInitialMin = formatToBRLCurrency(initialContribution);
    const fundMonthlyMin = formatToBRLCurrency(monthlyContribution);

    if (onlyInitialContribution) {
      subtitle = `${subtitle} valor mínimo inicial de ${fundInitialMin}`;
    } else if (onlyMonthlyContribution) {
      subtitle = `${subtitle} valor mínimo mensal de ${fundMonthlyMin}`;
    } else {
      subtitle = `${subtitle} valores mínimos inicial e mensal de, respectivamente, ${fundInitialMin} e ${fundMonthlyMin}`;
    }

    return `${subtitle}. Se você quiser ver outras opções de fundo, volte à
    tela anterior e edite os valores que deseja investir.`;
  };

  const changeFundRequiredSubtitle = (): string => {
    const fundInitialMin = formatToBRLCurrency(currentFund.minimumInitialContribution);
    const fundMonthlyMin = formatToBRLCurrency(currentFund.minimumMonthlyContribution);

    let subtitle;
    if (onlyInitialContribution) {
      subtitle = `O valor mínimo inicial no fundo selecionado era de ${fundInitialMin}`;
    } else if (onlyMonthlyContribution) {
      subtitle = `O valor mínimo mensal no fundo selecionado era de ${fundMonthlyMin}`;
    } else {
      subtitle = `Os valores mínimos inicial e mensal no fundo selecionado eram de, respectivamente, ${fundInitialMin} e ${fundMonthlyMin}`;
    }
    return `${subtitle} . Considerando o novo valor informado, escolha entre um
      desses novos fundos que selecionamos pra você:`;
  };

  const defineContributionState = (): void => {
    let initialContributionMinimum = INITIAL_CONTRIBUTION_MINIMUM;
    let monthlyContributionMinimum = MONTHLY_CONTRIBUTION_MINIMUM;
    let contributionStrategy = ContributionStrategyType.INITIAL_MONTHLY_CONTRIBUTION;

    switch (type) {
      case ProposalTypes.INCOME_TAX: {
        dispatch(IncomeTaxPageActions.setInitialContribution(newInitialContribution));
        dispatch(IncomeTaxPageActions.setMonthlyContribution(newMonthlyContribution));
        if (newOnlyInitialContribution) {
          contributionStrategy = ContributionStrategyType.INITIAL_CONTRIBUTION;
          monthlyContributionMinimum = 0;
        } else if (newOnlyMonthlyContribution) {
          contributionStrategy = ContributionStrategyType.MONTHLY_CONTRIBUTION;
          initialContributionMinimum = 0;
        }
        dispatch(IncomeTaxPageActions.setContributionStrategy(contributionStrategy));
        dispatch(IncomeTaxPageActions.setInitialMinimum(initialContributionMinimum));
        dispatch(IncomeTaxPageActions.setMonthlyMinimum(monthlyContributionMinimum));
        break;
      }
      case ProposalTypes.BY_FUND: {
        dispatch(ProposalByFundActions.setInitialValue(newInitialContribution));
        dispatch(ProposalByFundActions.setMonthlyValue(newMonthlyContribution));
        if (newOnlyInitialContribution) {
          contributionStrategy = ContributionStrategyType.INITIAL_CONTRIBUTION;
        } else if (newOnlyMonthlyContribution) {
          contributionStrategy = ContributionStrategyType.MONTHLY_CONTRIBUTION;
        }
        dispatch(ProposalByFundActions.setStrategyContribution(contributionStrategy));
        break;
      }
      default: {
        dispatch(RetirementPageActions.setInitialContribution(newInitialContribution));
        dispatch(RetirementPageActions.setMonthlyContribution(newMonthlyContribution));
        if (newOnlyInitialContribution) {
          contributionStrategy = ContributionStrategyType.INITIAL_CONTRIBUTION;
          monthlyContributionMinimum = 0;
        } else if (newOnlyMonthlyContribution) {
          contributionStrategy = ContributionStrategyType.MONTHLY_CONTRIBUTION;
          initialContributionMinimum = 0;
        }
        dispatch(RetirementPageActions.setContributionStrategy(contributionStrategy));
        dispatch(RetirementPageActions.setInitialMinimum(initialContributionMinimum));
        dispatch(RetirementPageActions.setMonthlyMinimum(monthlyContributionMinimum));
        break;
      }
    }
    dispatch(ProposalDetailActions.setInitialContribution(newInitialContribution));
    dispatch(ProposalDetailActions.setMonthlyContribution(newMonthlyContribution));
    dispatch(ProposalDetailActions.setAnnualContribution(annualContribution));
  };

  const onSubmit = () => {
    if (isChangeRequired) {
      const goToProposalPage = -2;

      defineContributionState();

      ProposalAnalyticsService.selectNewFund(selectedFund.name, type);

      dispatch(ProposalDetailActions.setInvestimentFund(selectedFund));

      history.go(goToProposalPage);
      return;
    }

    ProposalAnalyticsService.selectNewFund(selectedFund.name, type);
    dispatch(ProposalDetailActions.setInvestimentFund(selectedFund));
    history.goBack();
  };

  const filteredFunds = useMemo(() => {
    const fundsRequest = LocalStorageService.getItem<InvestmentFund[]>(
      LocalStorageKeys.AVAILABLE_FUNDS,
    );

    const dynamicFilterFunction = (
      fund: InvestmentFund,
      minimumMonthly: number,
      initialValue: number,
      monthlyValue: number,
    ) => fund.minimumInitialContribution <= initialValue && minimumMonthly <= monthlyValue;

    let filterFunction;
    if (fundsRequest) {
      if (isChangeRequired) {
        if (newOnlyInitialContribution) {
          filterFunction = (fund: InvestmentFund) =>
            fund.minimumInitialContribution <= newInitialContribution;
        } else if (newOnlyMonthlyContribution) {
          filterFunction = (fund: InvestmentFund) =>
            fund.minimumMonthlyContribution <= newMonthlyContribution;
        } else {
          return fundsRequest.data.filter((fund: InvestmentFund) => {
            const minimumMonthly = defineMinimumMonthlyContribution(
              fund,
              newInitialContribution,
              newMonthlyContribution,
            );
            return dynamicFilterFunction(
              fund,
              minimumMonthly,
              newInitialContribution,
              newMonthlyContribution,
            );
          });
        }

        return fundsRequest.data.filter(filterFunction);
      }
      if (monthlyContribution > 0 && initialContribution === 0) {
        filterFunction = (fund: InvestmentFund) =>
          fund.minimumMonthlyContribution <= monthlyContribution;
      } else if (portabilityValue > 0) {
        filterFunction = (fund: InvestmentFund) =>
          fund.minimumInitialContribution <= portabilityValue;
      } else if (monthlyContribution === 0 && initialContribution > 0) {
        filterFunction = (fund: InvestmentFund) =>
          fund.minimumInitialContribution <= initialContribution;
      } else {
        return fundsRequest.data.filter((fund: InvestmentFund) => {
          const minimumMonthly = defineMinimumMonthlyContribution(
            fund,
            initialContribution,
            monthlyContribution,
          );
          return dynamicFilterFunction(
            fund,
            minimumMonthly,
            initialContribution,
            monthlyContribution,
          );
        });
      }
      return fundsRequest.data.filter(filterFunction);
    }
    return [];
  }, [
    initialContribution,
    isChangeRequired,
    monthlyContribution,
    newInitialContribution,
    newMonthlyContribution,
    newOnlyInitialContribution,
    newOnlyMonthlyContribution,
    portabilityValue,
  ]);

  useEffect(() => {
    dispatch(NavbarActions.setTitle(PageTitles.SELECTABLE_FUNDS));
    dispatch(ErrorActions.resetState());
  }, [dispatch]);

  return (
    <InterContainerNoFooter margin="0 24px 24px">
      <SelectableFundsList
        onSelect={(fund) => setSelectedFund(fund)}
        selected={selectedFund}
        funds={filteredFunds}
      >
        <H3 marginBottom="8px">
          {isChangeRequired ? (
            <>Escolha um dos novos fundos disponíveis pra você</>
          ) : (
            <>Escolha entre os outros fundos disponíveis pra você</>
          )}
        </H3>
        <PSmallGray marginBottom="24px" data-testid="selectable-funds-subtitle-id">
          {isChangeRequired ? (
            <>{changeFundRequiredSubtitle()}</>
          ) : (
            <>{noChangeFundRequiredSubtitle()}</>
          )}
        </PSmallGray>
      </SelectableFundsList>
      <Div position="fixed" left="0px" bottom="0px" width="100%" backgroundColor={Colors.white}>
        <Div position="relative" margin="24px">
          <InterUIButton
            type="button"
            data-testid="choose-fund-btn"
            disabled={selectedFund.code === currentFund.code}
            onClick={onSubmit}
          >
            Escolher fundo
          </InterUIButton>
        </Div>
      </Div>
      <Div height="72px" />
    </InterContainerNoFooter>
  );
};

export default SelectableFundsPage;
