/* eslint-disable no-lonely-if */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { InterUIIcon, InterUINavbar } from '@interco/inter-ui-react-lib';
import { WbEnvironments } from 'inter-webview-bridge';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import HomeAnalyticsService from '../../analytics/homeAnalyticsService';
import RevisionAnalyticsService from '../../analytics/revisionAnalyticsService';
import { EnvVariableKeys } from '../../enums/evironmentVariableKeys';
import { PageDirections, PageRoutes } from '../../enums/pageRoutes';
import BridgeService from '../../services/bridgeService';
import { EnvVariableService } from '../../services/environmentVariableService';
import NavbarActions from '../../store/navbar/actions';
import { PreviewContractActions } from '../../store/previewContract/actions';
import PrivatePensionPlanActions from '../../store/privatePensionPlan/actions';
import { ProposalDetailActions } from '../../store/proposal/actions';
import { SnackbarActions } from '../../store/snackbar/actions';
import { ThemeInter } from '../../styles';
import { Div } from '../../styles/commons';
import { NavContainer } from '../../styles/inter-ui-customizations';
import { _try } from '../../utils/errorUtils';
import { IRouterStateProps } from '../../utils/routesUtils';
import { BtnDownload } from './Navbar.styles';

// Stryker disable all
const Navbar: React.FC = () => {
  const theme = useTheme() as ThemeInter;
  const dispatch = useDispatch();
  const handleError = useErrorHandler();
  const history = useHistory();
  const proposalType = useSelector(ProposalDetailActions.getType);
  const goIndex = useSelector(NavbarActions.getGoIndex);
  const { privatePensionPlan } = useSelector(PrivatePensionPlanActions.get);

  const title = useSelector(NavbarActions.getTitle);
  const contract = useSelector(PreviewContractActions.getContract);
  const canGoBack = useSelector(NavbarActions.getCanGoBack);
  const [locationUrlCounter, setLocationUrlCounter] = useState(0);

  const useRedirectPrefix = EnvVariableService.getVariableAsBoolean(
    EnvVariableKeys.USE_REDIRECT_PREFIX,
  );

  const onHomeButtonClick = useCallback(
    () =>
      _try(
        async () => {
          await BridgeService.goToAppHomeOrBackToNative(history, PageRoutes.HOME);
        },
        handleError,
        'NavBar.onHomeButtonClick',
      ),
    [handleError, history],
  );

  const onClickedBack = useCallback(
    () =>
      _try(
        async () => {
          switch (history.location.pathname) {
            case PageRoutes.HOME: {
              // We are turning this off temporarily, because of native bug: RAP-119991
              // await BridgeService.backToNative();
              await onHomeButtonClick();
              break;
            }
            case PageRoutes.RETIREMENT: {
              if (canGoBack === false) {
                history.push(PageRoutes.HOME);
                dispatch(NavbarActions.setCanGoBack(true));
              } else {
                history.goBack();
              }
              break;
            }
            case PageRoutes.PORTABILITY_HOME: {
              if (canGoBack === false) {
                history.push(PageRoutes.HOME);
                dispatch(NavbarActions.setCanGoBack(true));
              } else {
                history.goBack();
              }
              break;
            }
            case PageRoutes.PORTABILITY_QUERY_PENSION_PLAN: {
              history.push(PageRoutes.PORTABILITY_HOME);
              break;
            }
            case PageRoutes.PORTABILITY_CONTRACTION_RECEIPT: {
              history.push(PageRoutes.PORTABILITY_HOME);
              break;
            }
            case PageRoutes.PORTABILITY_SELECT_FINANCIAL_INSTITUTION: {
              if (canGoBack === false) {
                history.push(PageRoutes.PORTABILITY_HOME);
                dispatch(NavbarActions.setCanGoBack(true));
              } else {
                history.goBack();
              }
              break;
            }
            case PageRoutes.PORTABILITY_EXTERNAL_PENSION_PLAN: {
              if (canGoBack === false) {
                history.push(PageRoutes.PORTABILITY_HOME);
              } else {
                history.go(goIndex);
              }
              break;
            }
            case PageRoutes.DEEP_LINK_PROPOSAL: {
              if (canGoBack === false) {
                history.push(PageRoutes.HOME);
                dispatch(NavbarActions.setCanGoBack(true));
              } else {
                history.goBack();
              }
              break;
            }
            case PageRoutes.PROPOSAL: {
              if (canGoBack === false) {
                history.push(PageRoutes.HOME);
                dispatch(NavbarActions.setCanGoBack(true));
              } else {
                history.goBack();
              }
              break;
            }
            case PageRoutes.INCOME_EXPENSE: {
              if (canGoBack === false) {
                history.push(PageRoutes.HOME);
                dispatch(NavbarActions.setCanGoBack(true));
              } else {
                history.goBack();
              }
              break;
            }
            case PageRoutes.PRIVATE_PENSION_PLANS: {
              history.push(PageRoutes.HOME);
              dispatch(NavbarActions.setCanGoBack(true));

              break;
            }
            case PageRoutes.ADDITIONAL_CONTRIBUTION_PGBL_HELPER: {
              if (canGoBack === false) {
                history.push(PageRoutes.HOME);
                dispatch(NavbarActions.setCanGoBack(true));
              } else {
                history.goBack();
              }
              break;
            }
            case PageRoutes.ADDITIONAL_CONTRIBUTION_INVESTMENT_FORM: {
              if (privatePensionPlan.privatePensionType === 'VGBL' && canGoBack === false) {
                history.push(PageRoutes.HOME);
                dispatch(NavbarActions.setCanGoBack(true));
              } else {
                history.goBack();
              }
              break;
            }
            case PageRoutes.ADDITIONAL_CONTRIBUTION_EXPIRED: {
              if (canGoBack === false) {
                history.push(PageRoutes.HOME);
                dispatch(NavbarActions.setCanGoBack(true));
              } else {
                history.goBack();
              }
              break;
            }

            default: {
              dispatch(NavbarActions.setDirection(PageDirections.BACKWARD));

              HomeAnalyticsService.backButton(history.location.pathname);

              history.goBack();

              setTimeout(() => {
                dispatch(NavbarActions.setDirection(PageDirections.FORWARD));
              }, 550);
              break;
            }
          }
        },
        handleError,
        'Navbar.onClickedBack',
      ),
    [
      handleError,
      history,
      onHomeButtonClick,
      canGoBack,
      dispatch,
      goIndex,
      privatePensionPlan.privatePensionType,
    ],
  );

  /**
   * ## CAUTION ##
   * This slice of code is needed to add the back button listener when the webview starts
   * With the @function _try implementation is necessary create a function that call the onClickedBack() Promise
   *
   * Before the call was BridgeService.addBackButtonEvent(() => onClickedBack);
   * Now the call is BridgeService.addBackButtonEvent(() => { onClickedBack(); });
   * >>> Don`t change this code without atention; <<<
   */
  useEffect(() => {
    BridgeService.addBackButtonEvent(() => {
      onClickedBack();
    });
  }, [onClickedBack]);

  useEffect(() => {
    if (locationUrlCounter === 1) {
      setTimeout(() => {
        setLocationUrlCounter(0);
      }, 7000);
    }

    if (locationUrlCounter === 7) {
      dispatch(SnackbarActions.setMessage(`Essa é a url atual: ${window.location.href}`));
      dispatch(SnackbarActions.setDuration(5000));
      dispatch(SnackbarActions.show());
    }
  }, [dispatch, locationUrlCounter]);

  const iconRight = useMemo(() => {
    switch (history.location.pathname) {
      case PageRoutes.PREVIEW_CONTRACT: {
        if (
          BridgeService.getEnvironment() === WbEnvironments.IOS ||
          BridgeService.getEnvironment() === WbEnvironments.ANDROID ||
          !contract
        ) {
          return <Div />;
        }
        return (
          <BtnDownload
            download
            href={contract}
            onClick={() => {
              RevisionAnalyticsService.downloadButtonClick(proposalType);
            }}
          >
            <InterUIIcon name="download" size="24px" color={theme.colors.primary.A500} />
          </BtnDownload>
        );
      }
      case PageRoutes.PORTABILITY_CONFIRMATION: {
        return <Div />;
      }
      case PageRoutes.ERROR: {
        return <Div />;
      }
      case PageRoutes.CONTRACTION_RECEIPT: {
        return <Div />;
      }
      case PageRoutes.PORTABILITY_CONTRACTION_RECEIPT: {
        return <Div />;
      }
      case PageRoutes.ADDITIONAL_CONTRIBUTION_RECEIPT: {
        return <Div />;
      }
      default:
        return (
          <button type="button" onClick={() => onHomeButtonClick()}>
            <InterUIIcon name="home" size="24px" color={theme.colors.primary.A500} />
          </button>
        );
    }
  }, [
    history.location.pathname,
    contract,
    theme.colors.primary.A500,
    proposalType,
    onHomeButtonClick,
  ]);

  const hideBackButton = useMemo(() => {
    const { pathname } = history.location;
    const portabilityRouteRegex = new RegExp(
      PageRoutes.EXTERNAL_PORTABILITY_ACCEPTANCE.replace(':id', '(.*)'),
    );
    const routerState = history?.location?.state as IRouterStateProps;

    switch (pathname) {
      case PageRoutes.CONTRACTION_RECEIPT: {
        return true;
      }
      case PageRoutes.PORTABILITY_CONTRACTION_RECEIPT: {
        return true;
      }
      case PageRoutes.ADDITIONAL_CONTRIBUTION_RECEIPT: {
        return true;
      }
      case PageRoutes.CANCELLATION_RECEIPT: {
        return true;
      }
      case PageRoutes.HIRE_PROPOSAL_ACCEPTANCE: {
        return routerState?.data?.deeplink;
      }
      default:
        return portabilityRouteRegex.test(pathname) && routerState?.data?.deeplink;
    }
  }, [history.location]);

  return (
    <NavContainer>
      <InterUINavbar
        onClick={() => {
          if (useRedirectPrefix) {
            setLocationUrlCounter((prev) => prev + 1);
          }
        }}
        title={title}
        onClickedBack={onClickedBack}
        iconRight={iconRight}
        data-testid="navbar-container"
        hideBack={hideBackButton}
      />
    </NavContainer>
  );
};

export default Navbar;
