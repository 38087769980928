/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useTheme } from 'styled-components';
import { ThemeInter } from '../../styles';
import { Separator } from '../../styles/commons';
import CardHeader from './CardHeader/CardHeader';
import CardProfitability from './CardProfitability/CardProfitability';
import { Card, CardContainer, Collapse } from './InvestmentFundCard.styles';

export interface InvestmentFund {
  readonly minimumInitialContribution: number;
  readonly minimumMonthlyContribution: number;
  readonly minimumAdditionalContribution: number;
  readonly monthlyProfitability: number | null;
  readonly yearlyProfitability: number | null;
  readonly lastTwelveMonthsProfitability: number | null;
  readonly maximumAdministrationFee: number;
  readonly minimumAdministrationFee: number;
  readonly interestRate: number;
  readonly name: string;
  readonly code: string;
  readonly riskLevel: number;
  readonly interFund: boolean;
  readonly qualified: boolean;
  readonly susep?: string;
  readonly cnpj?: string;
  readonly minimumDiscountedMonthlyContribution?: number;
  readonly priority?: number;
}
interface IInvestmentFundCardProps {
  investmentFund: InvestmentFund;
  marginBottom?: string;
  dataTestId?: string;
  selected?: boolean;
  onSelect?: (selectedFund: InvestmentFund) => void;
  footer?: React.ReactNode;
  hideShadow?: boolean;
  expandableContent?: React.ReactElement;
}

const InvestmentFundCard: React.FC<IInvestmentFundCardProps> = ({
  investmentFund,
  marginBottom,
  dataTestId,
  selected = false,
  onSelect,
  footer,
  hideShadow = false,
  expandableContent,
}) => {
  const {
    name,
    monthlyProfitability,
    yearlyProfitability,
    lastTwelveMonthsProfitability,
    maximumAdministrationFee,
    minimumAdministrationFee,
    interFund,
    riskLevel,
    qualified,
  } = investmentFund;
  const expandable = !!expandableContent;
  const selectable = !!onSelect;

  const cardContent = () => {
    const theme = useTheme() as ThemeInter;

    return (
      <CardContainer backgroundColor={theme.colors.grayscale.A100} borderRadius="8px">
        <CardHeader
          name={name}
          interFund={interFund}
          riskLevel={riskLevel}
          selected={selected}
          qualified={qualified}
          selectable={selectable}
          expandable={expandable}
        />
        {expandable && <Collapse toggle={selected}>{expandableContent}</Collapse>}
        <CardProfitability
          monthlyProfitability={monthlyProfitability}
          yearlyProfitability={yearlyProfitability}
          lastTwelveMonthsProfitability={lastTwelveMonthsProfitability}
          minimumAdministrationFee={minimumAdministrationFee}
          maximumAdministrationFee={maximumAdministrationFee}
        />
        {footer && (
          <>
            <Separator margin="0 16px 16px" color={theme.colors.grayscale.A200} /> {footer}
          </>
        )}
      </CardContainer>
    );
  };

  return (
    <>
      {onSelect ? (
        <Card
          data-testid={dataTestId}
          marginBottom={marginBottom}
          selectable
          selected={selected}
          onClick={() => onSelect(investmentFund)}
          hideShadow={hideShadow}
        >
          {cardContent()}
        </Card>
      ) : (
        <Card marginBottom={marginBottom} hideShadow={hideShadow}>
          {cardContent()}
        </Card>
      )}
    </>
  );
};

export default InvestmentFundCard;
