import styled from 'styled-components';
import { Div } from '../../styles/commons';

export const VirtuaLizedContainer = styled(Div)`
  height: calc(100vh - 152px);
  width: 100%;
  overflow: auto;
`;

export const VirtuaLizedList = styled(Div)`
  height: ${(props) => props.height};
  width: auto;
  position: relative;
`;

// The 2 pixels subtracted in the width is because of both side of the card border width.
export const VirtuaLizedItem = styled(Div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  transform: translateY(${(props) => props.transform});
`;
