import { AnyAction } from 'redux';
import { action } from 'typesafe-actions';
import { RootState } from '..';
import { NavbarTypes } from './types';

const getTitle = (state: RootState): string => state.navbar.title;

const setTitle = (data: string): AnyAction => action(NavbarTypes.SET_TITLE, data);

const getDirection = (state: RootState): string => state.navbar.direction;

const setDirection = (direction: string): AnyAction => action(NavbarTypes.SET_DIRECTION, direction);

const setCanGoBack = (canGoBack: boolean): AnyAction =>
  action(NavbarTypes.SET_CAN_GO_BACK, canGoBack);

const getCanGoBack = (state: RootState): boolean => state.navbar.canGoBack;

const setGoIndex = (goIndex: number): AnyAction => action(NavbarTypes.SET_GO_INDEX, goIndex);

const getGoIndex = (state: RootState): number => state.navbar.goIndex;

const NavbarActions = {
  getTitle,
  setTitle,
  setDirection,
  getDirection,
  setCanGoBack,
  getCanGoBack,
  setGoIndex,
  getGoIndex,
};

export default NavbarActions;
