import { AnyAction } from 'redux';
import { action } from 'typesafe-actions';
import { RootState } from '..';
import { TermsAndConditionsTypes } from './types';

const getIsTermsAndConditionsAccepted = (state: RootState): boolean =>
  state.termAndCondition.isTermsAndConditionsAccepted;

const setIsTermsAndConditionsAccepted = (isTermsAndConditionsAccepted: boolean): AnyAction =>
  action(TermsAndConditionsTypes.SET_TERMS_CONDITIONS_ACCEPTED, isTermsAndConditionsAccepted);

const resetState = (): AnyAction => action(TermsAndConditionsTypes.RESET);

const TermsAndConditionsActions = {
  getIsTermsAndConditionsAccepted,
  setIsTermsAndConditionsAccepted,
  resetState,
};

export default TermsAndConditionsActions;
