/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  IInterUIDropdownOption,
  InterUIAlert,
  InterUIBottomSheet,
  InterUIBox,
  InterUIButton,
  InterUIContainer,
  InterUIIcon,
} from '@interco/inter-ui-react-lib';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import ProposalAnalyticsService from '../../analytics/proposalAnalyticsService';
import Select from '../../components/Select/Select';
import FontSizeREM from '../../enums/fontSizesRem';
import { getMessages, MessagesValidation } from '../../enums/messages';
import PageTitles from '../../enums/pageTitles';
import ErrorActions from '../../store/error/actions';
import { IncomeExpenseActions } from '../../store/incomeExpenses/actions';
import NavbarActions from '../../store/navbar/actions';
import { ProposalDetailActions } from '../../store/proposal/actions';
import { ProposalTypes } from '../../store/proposal/types';
import { ThemeInter } from '../../styles';
import { Div, H1, P, PSmallGray } from '../../styles/commons';
import { InterUIAlertCustomized } from '../../styles/inter-ui-customizations';
import { ANNUAL_INCOME_PERCENTAGE } from '../../utils/constantsValues';
import {
  getFirstDebitDate,
  getMonthsToCompleteAnYear,
  getSecondDebitDate,
} from '../../utils/dateTimeUtils';
import { formatToBRLCurrency } from '../../utils/numberFormatUtils';

// Stryker disable all
const daysOptions: IInterUIDropdownOption[] = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 },
  { label: '13', value: 13 },
  { label: '14', value: 14 },
  { label: '15', value: 15 },
  { label: '16', value: 16 },
  { label: '17', value: 17 },
  { label: '18', value: 18 },
  { label: '19', value: 19 },
  { label: '20', value: 20 },
  { label: '21', value: 21 },
  { label: '22', value: 22 },
  { label: '23', value: 23 },
  { label: '24', value: 24 },
  { label: '25', value: 25 },
  { label: '26', value: 26 },
  { label: '27', value: 27 },
  { label: '28', value: 28 },
  { label: '29', value: 29 },
  { label: '30', value: 30 },
  { label: '31', value: 31 },
];
// Stryker restore all

type BottomSheetInfoType = 'FIRST_DEBIT_DAY' | 'SECOND_DEBIT_DAY';

const FIRST_DEBIT_DAY_INFO = {
  title: 'Primeiro dia do débito',
  description: [
    'A data do primeiro débito acontece no próximo dia útil a partir da data de contratação.',
  ],
};

const SECOND_DEBIT_DAY_INFO = {
  title: 'Segundo dia do débito',
  description: [
    'A data do segundo débito obedece o dia do débito escolhido, respeitando-se o intervalo mínimo de 1 mês entre o primeiro e o segundo débito',
  ],
};
interface IBottomSheetState {
  show: boolean;
  title: string;
  description: string[];
}

const PaymentDay: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme() as ThemeInter;
  const paymentDay = useSelector(ProposalDetailActions.getPaymentDay);
  const type = useSelector(ProposalDetailActions.getType);
  const currentAnnualContribution = useSelector(ProposalDetailActions.getAnnualContribution);
  const currentInitialContribution = useSelector(ProposalDetailActions.getInitialContribution);
  const currentMonthlyContribution = useSelector(ProposalDetailActions.getMonthlyContribution);
  const timeContribution = useSelector(ProposalDetailActions.getNumberMonthsInvesting);
  const [annualContribution, setAnnualContribution] = useState(currentAnnualContribution);
  /**
   * newMaximumDeductible is the maximumDeductibleAmount that is considering the rounded idealMonthlyContribution
   * In cases when the idealMonthlyContribution is exact ex: 500, 250, 100...
   * The newMaximumDeductible will be equal to the maximumDeductibleAmount;
   * In cases when the idealMonthlyContribution is a periodic tithe ex 133.33, 666.67...
   * The newMaximumDeductible will be considering the rounded monthly values
   * So we need to use as reference the newMaximumDeductible to calcular the deductibleIncomeExpense,
   * This is needed to hidden the card for when is suggested a rounded monthly contribution and the user input the suggestion;
   */
  const [newMaximumDeductible, setNewMaximumDeductible] = useState(0);
  const incomeExpense = useSelector(IncomeExpenseActions.get);
  const [selectedPaymentDay, setSelectedPaymentDay] = useState<number | string>(paymentDay);
  const paymentDayAsNumber = parseInt(selectedPaymentDay as string, 10);
  const firstDebitDate = getFirstDebitDate();
  const yearOfExercise = String(new Date().getFullYear() + 1);

  const maximumDeductibleAmount = incomeExpense.annualIncome * ANNUAL_INCOME_PERCENTAGE;

  // Stryker disable all
  const [bottomSheet, setBottomSheet] = useState<IBottomSheetState>({
    show: false,
    title: '',
    description: [''],
  });
  // Stryker restore all

  useEffect(() => {
    let numberOfContributions;
    const monthsToCompleteAnYear = getMonthsToCompleteAnYear(paymentDayAsNumber);

    const diffBetweenMaximumDeductibleAndIniticalContribution =
      maximumDeductibleAmount - currentInitialContribution;

    if (timeContribution < monthsToCompleteAnYear) {
      numberOfContributions = timeContribution;
    } else {
      numberOfContributions = monthsToCompleteAnYear;
    }

    const idealMonthlyContribution = +(
      diffBetweenMaximumDeductibleAndIniticalContribution / numberOfContributions
    ).toFixed(2);

    const idealAmountMonthlyContribution = +(
      idealMonthlyContribution * numberOfContributions
    ).toFixed(2);

    const currenctAmountMonthlyContribution = +(
      currentMonthlyContribution * numberOfContributions
    ).toFixed(2);

    setNewMaximumDeductible(idealAmountMonthlyContribution + currentInitialContribution);

    setAnnualContribution(currenctAmountMonthlyContribution + currentInitialContribution);
  }, [selectedPaymentDay]);

  useEffect(() => {
    dispatch(NavbarActions.setTitle(PageTitles.PAYMENT_DAY));
    dispatch(ErrorActions.resetState());
    // Stryker disable next-line all
  }, [dispatch]);

  const recalcDeclarationTableInfo = useMemo(() => {
    if (type !== ProposalTypes.INCOME_TAX || currentAnnualContribution === annualContribution) {
      return undefined;
    }

    const deductibleIncomeExpense = newMaximumDeductible - annualContribution;

    if (deductibleIncomeExpense > 0) {
      return {
        message: getMessages(
          MessagesValidation.ANNUAL_CONTRIBUTION_UNDER_MAXIMUM_DEDUCTIBLE_AMOUNT_DIFF,
          [formatToBRLCurrency(deductibleIncomeExpense), yearOfExercise],
        ),
      };
    }

    if (deductibleIncomeExpense < 0) {
      /*
       * In this scenarious we need show the diff between the correct maximumDeductibleAmount and the annualContribution inputed
       */
      const rightDeductibleIncomeDiff = maximumDeductibleAmount - annualContribution;
      return {
        message: getMessages(MessagesValidation.MAXIMUM_DEDUCTIBLE_AMOUNT_EXCEEDS_FOR_PAYMENT_DAY, [
          formatToBRLCurrency(maximumDeductibleAmount),
          yearOfExercise,
          formatToBRLCurrency(rightDeductibleIncomeDiff * -1),
        ]),
      };
    }
    return undefined;
  }, [annualContribution]);

  const onSubmit = () => {
    const oldPaymentDate = paymentDay;
    dispatch(ProposalDetailActions.setPaymentDay(paymentDayAsNumber));
    dispatch(ProposalDetailActions.setAnnualContribution(annualContribution));

    ProposalAnalyticsService.editInfoConfirmation(
      {
        content_area: 'dia do débito por mês',
        content_new_info: String(paymentDayAsNumber),
        content_info: String(oldPaymentDate),
      },
      type,
    );
    history.goBack();
  };

  const disableButton = () => {
    if (paymentDayAsNumber === paymentDay) return true;
    return false;
  };

  const stickyFooter: React.ReactElement = (
    <>
      {type === ProposalTypes.INCOME_TAX && (
        <InterUIAlert margin="0 0 16px 0">
          <P
            fontWeight={700}
            fontSize={FontSizeREM.PX12}
            lineHeight={FontSizeREM.PX15}
            marginBottom="4px"
          >
            Alteração do dia do débito.
          </P>
          <P fontWeight={400} fontSize={FontSizeREM.PX12} lineHeight={FontSizeREM.PX15}>
            A alteração do Dia do Débito a Cada Mês, e consequentemente a alteração das datas do
            Primeiro e Segundo Débito, podem implicar no recalculo dos valores ideais para
            investimento na Previdência para Economizar no Imposto de Renda.
          </P>
        </InterUIAlert>
      )}
      <InterUIButton
        data-testid="edit-payment-date-btn"
        disabled={disableButton()}
        onClick={onSubmit}
      >
        Redefinir
      </InterUIButton>
    </>
  );

  const callBottomSheetInfo = (bottomType: BottomSheetInfoType) => {
    switch (bottomType) {
      case 'FIRST_DEBIT_DAY': {
        setBottomSheet({ show: true, ...FIRST_DEBIT_DAY_INFO });
        break;
      }
      case 'SECOND_DEBIT_DAY': {
        setBottomSheet({ show: true, ...SECOND_DEBIT_DAY_INFO });
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <InterUIContainer stickyFooter={stickyFooter}>
      <H1 marginBottom="8px">Escolha o dia do débito</H1>
      <P
        fontWeight={400}
        fontSize={FontSizeREM.PX14}
        lineHeight={FontSizeREM.PX17}
        color={theme.colors.grayscale.A400}
        marginBottom="16px"
      >
        Esse é o dia em que o valor será debitado a cada mês.
      </P>
      <Select
        label="Dia do mês"
        onChange={setSelectedPaymentDay}
        defaultValue={selectedPaymentDay}
        selectValueColor={
          disableButton() ? theme.colors.grayscale.A400 : theme.colors.grayscale.A500
        }
        marginBottom="40px"
        dataTestId="edit-payment-date-select"
      >
        {daysOptions.map((d: IInterUIDropdownOption) => {
          const key = `day-${d.value}`;
          return (
            <option key={key} value={d.value}>
              {d.label}
            </option>
          );
        })}
      </Select>

      <Div position="relative">
        <P
          fontSize={FontSizeREM.PX14}
          fontWeight={400}
          lineHeight={FontSizeREM.PX17}
          marginBottom="4px"
          color={theme.colors.grayscale.A400}
        >
          Primeiro débito
        </P>
        <Div position="absolute" top="0" right="0">
          <button
            type="button"
            onClick={() => callBottomSheetInfo('FIRST_DEBIT_DAY')}
            aria-label="O que é primeiro débito?"
          >
            <InterUIIcon name="help" color={theme.colors.primary.A500} size="16px" />
          </button>
        </Div>
      </Div>
      <P
        fontSize={FontSizeREM.PX14}
        fontWeight={700}
        lineHeight={FontSizeREM.PX17}
        marginBottom="16px"
      >
        {firstDebitDate.toLocaleString('pt-BR', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })}
      </P>
      {currentMonthlyContribution > 0 && (
        <Div marginTop="35px" position="relative">
          <P
            fontSize={FontSizeREM.PX14}
            fontWeight={400}
            lineHeight={FontSizeREM.PX17}
            marginBottom="4px"
            color={theme.colors.grayscale.A400}
          >
            Data do débito mensal
          </P>
          <Div position="absolute" top="0" right="0">
            <button
              type="button"
              onClick={() => callBottomSheetInfo('SECOND_DEBIT_DAY')}
              aria-label="O que é data do débito mensal?"
            >
              <InterUIIcon name="help" color={theme.colors.primary.A500} size="16px" />
            </button>
          </Div>
          <P
            fontSize={FontSizeREM.PX14}
            fontWeight={700}
            lineHeight={FontSizeREM.PX17}
            marginBottom="16px"
          >
            {getSecondDebitDate(String(firstDebitDate), +selectedPaymentDay).toLocaleString(
              'pt-BR',
              {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              },
            )}
          </P>
        </Div>
      )}
      {recalcDeclarationTableInfo && (
        <InterUIBox padding="0px" margin="0 0 16px">
          <InterUIAlertCustomized
            color={theme.colors.white}
            icon={<InterUIIcon name="contextual-info" color={theme.colors.alert.A400} />}
            alignItems="center"
          >
            <PSmallGray marginBottom="0">{recalcDeclarationTableInfo.message}</PSmallGray>
          </InterUIAlertCustomized>
        </InterUIBox>
      )}

      <InterUIBottomSheet
        title={bottomSheet.title}
        toggle={bottomSheet.show}
        onHide={() => setBottomSheet({ ...bottomSheet, show: false })}
      >
        {bottomSheet.description.map((paragraph, index) => (
          <PSmallGray key={`paragraph-${index}`}>{paragraph}</PSmallGray>
        ))}
        <InterUIButton
          type="button"
          onClick={() => {
            ProposalAnalyticsService.debitBottomSheetClick(bottomSheet.title, type);
            setBottomSheet({ ...bottomSheet, show: false });
          }}
          margin="32px 0px 0px 0px"
        >
          Entendi
        </InterUIButton>
      </InterUIBottomSheet>
    </InterUIContainer>
  );
};

export default PaymentDay;
