import { applyMiddleware, compose, createStore, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import BelvoSDK from '../belvo';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

/**
 * Configuration to enable status monitoring on the Redux tab in Devtools
 * It is necessary to download the Chrome extension <https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd>
 */
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    belvoSDK?: BelvoSDK;
  }
}

const devTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const configureStore = (): Store<RootState> => {
  const sagaMiddleware = createSagaMiddleware();

  const store: Store<RootState> = createStore(
    rootReducer,
    devTools(applyMiddleware(sagaMiddleware)),
  );

  sagaMiddleware.run(rootSaga);
  return store;
};

export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore();
export default store;
