import AnalyticsBaseEventName from '../enums/analyticsBaseEventNames';
import BridgeService from '../services/bridgeService';
import { IDeepLinkProposal } from './types';

class DeepLinkProposalAnalytics {
  private static className = 'DeepLinkProposalAnalytics';

  static success({
    fund_code,
    debt_day,
    modality,
    table,
    monthly_contribution_time,
    initial_value,
    monthly_value,
  }: IDeepLinkProposal): void {
    BridgeService.requestAnalytics(
      `${this.className}.success`,
      `${AnalyticsBaseEventName.DEEPLINK}_Sucesso`,
      {
        codigoFundo: fund_code || '',
        debitoDia: debt_day || '',
        modalidade: modality || '',
        tabela: table || '',
        tempoContribuicaoMeses: monthly_contribution_time || '',
        valorInicial: initial_value || '',
        valorMensal: monthly_value || '',
        currency: 'BRL',
        content_id: 'Proposta via DeepLink',
      },
    );
  }

  static error({
    fund_code,
    debt_day,
    modality,
    table,
    monthly_contribution_time,
    initial_value,
    monthly_value,
  }: IDeepLinkProposal): void {
    BridgeService.requestAnalytics(
      `${this.className}.erro`,
      `${AnalyticsBaseEventName.DEEPLINK}_Erro`,
      {
        codigoFundo: fund_code || '',
        diaDebito: debt_day || '',
        modalidade: modality || '',
        tabela: table || '',
        tempoContribuicaoMeses: monthly_contribution_time || '',
        valorInicial: initial_value || '',
        valorMensal: monthly_value || '',
        currency: 'BRL',
        content_id: 'Proposta via DeepLink',
      },
    );
  }
}

export default DeepLinkProposalAnalytics;
