/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { HireProposalStatus } from '../enums/privatePensions';
import { HireProposalState, HireProposalStatePage } from '../store/hire/types';
import { AppInfoService } from './appInfoService';
import { BaseService, ServiceResponse } from './baseService';
import { MockService } from './mockService';

interface HireProposalHeader {
  authenticationToken: string;
  authenticationType: string;
  authenticationValue: string;
  code: string;
  transactionType: string;
}

interface HireProposalResponseBody {
  chosenProposalId: number;
}

const {
  REACT_APP_API_HIRE_PENSION_PROPOSAL_V1,
  REACT_APP_API_BRIDGE_HIRE_PENSION_PROPOSAL_V1,
} = process.env;

interface ICancelPendingHireProposal {
  status: HireProposalStatus;
}

interface ISignPendingHireProposal {
  chosenProposalIds: Array<number>;
}

class HireProposalService {
  static async cancelPendingHireProposal(
    id: number,
    header: HireProposalHeader,
    body: ICancelPendingHireProposal,
  ): Promise<ServiceResponse<void>> {
    return BaseService.doExecute({
      endpoint: {
        browser: `${REACT_APP_API_HIRE_PENSION_PROPOSAL_V1}/propostas-contratacao/${id}/interno`,
        bridge: `${REACT_APP_API_BRIDGE_HIRE_PENSION_PROPOSAL_V1}/propostas-contratacao/${id}`,
      },
      method: 'PUT',
      headers: {
        browser: {
          'x-inter-ip': '127.1.1.1',
          'x-inter-isafe-token': header.authenticationToken,
          'x-inter-isafe-value': header.authenticationValue,
          'x-inter-isafe-authetication-type': `${header.authenticationType}`, // Force string to prevent error
          'x-inter-isafe-transaction-type': header.transactionType,
          'mag-identifier': 'magidentifier',
          'x-inter-mac-address': `00:00:00:00:00:00`,
          'x-inter-app-origem': 'Webview Previdência',
          'x-inter-app-version': AppInfoService.superAppVersion,
          'x-inter-so': AppInfoService.deviceName,
          'x-inter-conta-corrente': MockService.account,
          'x-inter-user': MockService.account,
          ...MockService.matchHeadersByUserAccount,
        },
        bridge: {
          'x-inter-isafe-token': header.authenticationToken,
          'x-inter-isafe-value': header.authenticationValue,
          'x-inter-isafe-authetication-type': `${header.authenticationType}`, // Force string to prevent error
          'x-inter-isafe-transaction-type': header.transactionType,
        },
      },
      data: body,
    });
  }

  static async signPendingHireProposal(
    header: HireProposalHeader,
    body: ISignPendingHireProposal,
  ): Promise<ServiceResponse<void>> {
    return BaseService.doExecute({
      endpoint: {
        browser: `${REACT_APP_API_HIRE_PENSION_PROPOSAL_V1}/assinaturas/interno`,
        bridge: `${REACT_APP_API_BRIDGE_HIRE_PENSION_PROPOSAL_V1}/assinaturas`,
      },
      method: 'POST',
      headers: {
        browser: {
          'x-real-ip': '127.1.1.1',
          'x-inter-conta-corrente': MockService.account,
          ...MockService.matchHeadersByUserAccount,
        },
        bridge: {
          'x-inter-isafe-token': header.authenticationToken,
          'x-inter-isafe-value': header.authenticationValue,
          'x-inter-isafe-authetication-type': `${header?.authenticationType ?? ''}`, // Force string to prevent error
          'x-inter-isafe-transaction-type': header.transactionType,
        },
      },
      data: body,
    });
  }

  static async getHireReceipt(
    header: HireProposalHeader,
    body: HireProposalState,
  ): Promise<ServiceResponse<HireProposalResponseBody>> {
    return BaseService.doExecute<HireProposalResponseBody>({
      endpoint: {
        browser: `${REACT_APP_API_HIRE_PENSION_PROPOSAL_V1}/contratar/interno`,
        bridge: `${REACT_APP_API_BRIDGE_HIRE_PENSION_PROPOSAL_V1}/contratar`,
      },
      method: 'POST',
      headers: {
        browser: {
          'x-inter-ip': '127.1.1.1',
          'x-inter-isafe-token': header.authenticationToken,
          'x-inter-isafe-value': header.authenticationValue,
          'x-inter-isafe-authetication-type': `${header.authenticationType}`, // Force string to prevent error
          'x-inter-isafe-transaction-type': header.transactionType,
          'mag-identifier': 'magidentifier',
          'x-inter-mac-address': `00:00:00:00:00:00`,
          'x-inter-app-origem': 'Webview Previdência',
          'x-inter-app-version': AppInfoService.superAppVersion,
          'x-inter-so': AppInfoService.deviceName,
          'x-inter-conta-corrente': MockService.account,
          'sign-immediately': 'true',
          ...MockService.matchHeadersByUserAccount,
        },
        bridge: {
          'x-inter-isafe-token': header.authenticationToken,
          'x-inter-isafe-value': header.authenticationValue,
          'x-inter-isafe-authetication-type': `${header.authenticationType}`, // Force string to prevent error
          'x-inter-isafe-transaction-type': header.transactionType,
          'sign-immediately': 'true',
        },
      },
      data: body,
    });
  }

  static async getCustomerHireProposals(
    allowedStatus: HireProposalStatus,
    size: number,
    order: string,
  ): Promise<ServiceResponse<HireProposalStatePage>> {
    const queryParams = `?status=${[allowedStatus]}&size=${size}&order=${order}`;
    return BaseService.doExecute<HireProposalStatePage>({
      endpoint: {
        browser: `${REACT_APP_API_HIRE_PENSION_PROPOSAL_V1}/propostas-contratacao/interno${queryParams}`,
        bridge: `${REACT_APP_API_BRIDGE_HIRE_PENSION_PROPOSAL_V1}/propostas-contratacao${queryParams}`,
      },
      method: 'GET',
      headers: {
        browser: {
          'x-inter-app-origem': 'Webview Previdência',
          'x-inter-app-version': AppInfoService.superAppVersion,
          'x-inter-conta-corrente': MockService.account,
          ...MockService.matchHeadersByUserAccount,
        },
      },
    });
  }
}

export default HireProposalService;
