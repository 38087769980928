import { useEffect, useState } from 'react';
import { useIRRFTable } from './useIRRFTable';

export function useNewTotalTaxPayable(initialTotalTaxPayable: number, taxableBase: number): number {
  const [newTotalTaxPayable, setNewTotalTaxPayable] = useState(initialTotalTaxPayable);
  const [aliquot, deduction] = useIRRFTable(taxableBase);

  useEffect(() => {
    setNewTotalTaxPayable(taxableBase * aliquot - deduction);
  }, [aliquot, deduction, taxableBase]);

  return newTotalTaxPayable;
}
