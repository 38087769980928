import styled from 'styled-components';
import Colors from '../../../styles/colors';

export const CardStatusColor = styled.div<{
  color?: string;
  marginBottom?: string;
}>`
  width: 4px;
  height: 100%;
  background-color: ${(props) => props.color || Colors.pigmentGreen};
  border-radius: 24px;
  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom};`};
`;
