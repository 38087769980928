import axios, { AxiosError, AxiosResponse } from 'axios';
import { ServiceError } from '../exceptions/index';
import store from '../store';
import ErrorActions from '../store/error/actions';
import { ErrorResponse } from '../store/error/types';
import { withErrorCodeRef } from '../utils/errorUtils';
import BridgeService from './bridgeService';

const {
  REACT_APP_INTER_ENV,
  REACT_APP_MOCK_SERVER_BASE_URL,
  REACT_APP_MOCK_SERVER_API_KEY,
} = process.env;

const IS_NOT_RUNNING_PROD =
  REACT_APP_INTER_ENV && REACT_APP_INTER_ENV.toLowerCase() !== 'production';

const STATUS_CODE_ACCEPTED = [401];
export class MockService {
  private static _baseUrl = REACT_APP_MOCK_SERVER_BASE_URL || '';

  /**
   * The private key to set in the header 'x-api-key'
   * see @method prepareAxios and @method prepareMock
   */
  private static _apiKey = REACT_APP_MOCK_SERVER_API_KEY || '';

  /**
   * Represent the account of the client
   * and is used to match the correct return of mocked service
   * using the 'x-mock-match-request-headers' and 'x-mock-conta-corrente'
   * see @method prepareAxios and @method prepareMock
   */
  private static _account: string;

  /**
   * The flag which determine if the app should consider the mock services
   * see @method prepareAxios and @method prepareMock
   */
  private static _shouldMock: boolean;

  /**
   * The headers that determine the correct mock to return via postman mock server
   * using the 'x-mock-match-request-headers' and 'x-mock-conta-corrente'
   * see @method prepareMatchHeadersByUserAccount
   */
  private static _matchHeadersByUserAccount: Record<string, string>;

  static get baseUrl(): string {
    return MockService._baseUrl;
  }

  static get apiKey(): string {
    return MockService._apiKey;
  }

  static get account(): string {
    return MockService._account;
  }

  static set account(account: string) {
    MockService._account = account;
  }

  static get shouldMock(): boolean {
    return MockService._shouldMock;
  }

  static get matchHeadersByUserAccount(): Record<string, string> {
    return MockService._matchHeadersByUserAccount;
  }

  static prepareMatchHeadersByUserAccount(): void {
    if (MockService.shouldMock && IS_NOT_RUNNING_PROD && BridgeService.isBrowser()) {
      MockService._matchHeadersByUserAccount = {
        'x-mock-conta-corrente': MockService.account || '',
        'x-mock-match-request-headers': 'x-mock-conta-corrente',
      };
    }
  }

  static validate(): void {
    if (!MockService.apiKey && BridgeService.isBrowser() && IS_NOT_RUNNING_PROD) {
      const errorResponse: ErrorResponse = {
        totalErrors: 1,
        errors: [
          {
            code: 'api_key_missing',
            message:
              'O parametro mockApiKey não foi informado, por favor verifique e tente novamente.',
          },
        ],
      };

      store.dispatch(ErrorActions.resetState());
      store.dispatch(
        ErrorActions.setErrorInstance(
          withErrorCodeRef(
            new ServiceError(true, errorResponse, JSON.stringify(errorResponse), 400),
            'MockService.validate',
          ),
        ),
      );
      store.dispatch(ErrorActions.setIsDetailedError(true));
      store.dispatch(ErrorActions.setErrorResponse(errorResponse));
      store.dispatch(ErrorActions.show());
    }
  }

  /**
   * The method witch configure the mock service to communicate with the mock server.
   * It should be call when was neccessary to setting the application mock strategy,
   * this is the only method that turn shouldMock to true and only have effect if:
   * - @var props.shouldMock is true
   * - @const IS_NOT_RUNNING_PROD is true
   */
  static prepareMock(props: { shouldMock: boolean; apiKey?: string; account?: string }): void {
    if (props.shouldMock && IS_NOT_RUNNING_PROD && BridgeService.isBrowser()) {
      MockService._shouldMock = props.shouldMock;
    }

    /**
     * Even when should not mock (props.mock is not true)
     * the apiKey can be setted to mock the client
     * in staging environment and only for browser
     */
    if (props.apiKey) {
      MockService._apiKey = props.apiKey;
    }

    /**
     * Even when should not mock (props.mock is not true)
     * the account can be setted to differ the multiple scenarios
     * in development environment and only for browser
     */
    if (props.account) {
      MockService._account = props.account;
    }

    MockService.prepareMatchHeadersByUserAccount();

    MockService.validate();
  }

  /**
   * The method witch configure the axios to communicate with the mock server.
   * It should be call before any http call that should be mocked by axios
   * and only have effect if:
   * - @var shouldMock is true
   * - @const IS_NOT_RUNNING_PROD is true
   */
  static prepareAxios(): void {
    if (MockService.shouldMock && IS_NOT_RUNNING_PROD) {
      axios.defaults.headers['x-api-key'] = MockService.apiKey || '';
      axios.defaults.baseURL = MockService.baseUrl;
    }
  }

  static handleMockServiceErrors(errorObject: AxiosError, errorCodeRef: string): void {
    let errorHandled = withErrorCodeRef(new Error(JSON.stringify(errorObject)), errorCodeRef);

    if (errorObject.response && STATUS_CODE_ACCEPTED.includes(errorObject.response?.status)) {
      const errorResponse: AxiosResponse = errorObject.response;
      errorHandled = withErrorCodeRef(
        new ServiceError(
          STATUS_CODE_ACCEPTED.includes(errorResponse?.status),
          errorResponse,
          JSON.stringify(errorResponse),
          errorResponse?.status,
        ),
        errorCodeRef,
      );

      const errorToShow: ErrorResponse = {
        totalErrors: 1,
        errors: [
          {
            code: 'api_key_unauthorized',
            message:
              'Não foi possível autenticar. Por favor verifique a mockApiKey provisionada e tente novamente.',
          },
        ],
      };

      store.dispatch(ErrorActions.resetState());
      store.dispatch(ErrorActions.setErrorInstance(errorHandled));
      store.dispatch(ErrorActions.setIsDetailedError(true));
      store.dispatch(ErrorActions.setErrorResponse(errorToShow));
    }

    throw errorHandled;
  }
}
