export enum LoadingTypes {
  SHOW = `@loading/SHOW`,
  HIDE = `@loading/HIDE`,
  SET_TOGGLE_ON = `@loading/SET_TOGGLE_ON`,
}

export interface LoadingState {
  readonly toggle: boolean;
  elementId: string;
}
