/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-prototype-builtins */
import { QueryParamsState } from '../store/global/types';

interface ParamsProposalValidate {
  isParamsValid: boolean;
  paramsError?: string;
}

export const ParamsProposalKeys: Array<string> = [
  'codigoFundo',
  'diaDebito',
  'modalidade',
  'tabela',
  'tempoContribuicaoMeses',
  'valorInicial',
  'valorMensal',
];

export const possibleModelTypes = ['VGBL', 'PGBL'];

export const possibleTableTypes = ['REGRESSIVO', 'PROGRESSIVO'];

export const isHasAllTheKeys = (proposalParams: QueryParamsState): boolean => {
  const hasAllKeys = ParamsProposalKeys.every((prop: string) =>
    proposalParams.hasOwnProperty(prop),
  );

  return hasAllKeys;
};

export function isDeepLinkParamsValid(proposalParams: QueryParamsState): ParamsProposalValidate {
  const hasAllKeys = isHasAllTheKeys(proposalParams);
  const validateResult: ParamsProposalValidate = { isParamsValid: false };

  if (hasAllKeys) {
    // Check if it has all the params
    if (Number.isNaN(Number(proposalParams.valorInicial))) {
      // verifying if valorInicial ins't  a number
      validateResult.isParamsValid = false;
      validateResult.paramsError = "valorInicial ins't  a number";
      return validateResult;
    }
    if (Number.isNaN(Number(proposalParams.valorMensal))) {
      // verifying if valorMensal ins't a number
      validateResult.isParamsValid = false;
      validateResult.paramsError = "valorMensal ins't a number";
      return validateResult;
    }
    if (+proposalParams.valorInicial! === 0 && +proposalParams.valorMensal! === 0) {
      // initialValue (REQUIRED), monthlyValue (REQUIRED) : Both can't be zero at the same time
      validateResult.isParamsValid = false;
      validateResult.paramsError =
        "valorInicial (REQUIRED), valorMensal (REQUIRED) : Both can't be zero at the same time";
      return validateResult;
    }
    if (+proposalParams.valorInicial! < 0 || +proposalParams.valorMensal! < 0) {
      // initialValue and monthlyValue can't be negative values
      validateResult.isParamsValid = false;
      validateResult.paramsError = "valorInicial and valorMensal can't be negative values";
      return validateResult;
    }
    if (!possibleModelTypes.includes(proposalParams.modalidade!)) {
      // modality (REQUIRED) : the only valid values are, PGBL and VGBL
      validateResult.isParamsValid = false;
      validateResult.paramsError =
        'modalidade (REQUIRED) : the only valid values are, PGBL and VGBL';
      return validateResult;
    }
    if (!possibleTableTypes.includes(proposalParams.tabela!)) {
      // tax type (REQUIRED) : the only valid values, PROGRESSIVO and REGRESSIVO
      validateResult.isParamsValid = false;
      validateResult.paramsError =
        'tabela (REQUIRED) : the only valid values, PROGRESSIVO and REGRESSIVO';
      return validateResult;
    }
    if (Number.isNaN(Number(proposalParams.tempoContribuicaoMeses))) {
      // verifying if tempoContribuicaoMeses ins't a number
      validateResult.isParamsValid = false;
      validateResult.paramsError = "tempoContribuicaoMeses ins't a number";
      return validateResult;
    }
    if (parseInt(proposalParams.tempoContribuicaoMeses!) < 1) {
      // contribuition time (REQUIRED), : min 1
      validateResult.isParamsValid = false;
      validateResult.paramsError = 'tempoContribuicaoMeses (REQUIRED), : min less then 1';
      return validateResult;
    }
    if (Number.isNaN(Number(proposalParams.diaDebito))) {
      // verifying if diaDebito ins't a number
      validateResult.isParamsValid = false;
      validateResult.paramsError = "diaDebito ins't a number";
      return validateResult;
    }
    if (parseInt(proposalParams.diaDebito!) < 1 || parseInt(proposalParams.diaDebito!) > 31) {
      // debit day (REQUIRED) : values are, min 1 and max 31
      validateResult.isParamsValid = false;
      validateResult.paramsError =
        'diaDebito (REQUIRED) : values are out of range, min 1 and max 31';
      return validateResult;
    }
    validateResult.isParamsValid = true;
    return validateResult;
  }
  validateResult.isParamsValid = false;
  validateResult.paramsError = 'Missing param error';
  return validateResult;
}
