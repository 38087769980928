/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-return */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react/no-array-index-key */
import {
  InterUIBottomSheet,
  InterUIButton,
  InterUIContainer,
  InterUIIcon,
} from '@interco/inter-ui-react-lib';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import AdditionalContributionAnalyticsService from '../../analytics/additionalContributionAnalyticsService';
import { InvestmentFundCard } from '../../components';
import CardFooter from '../../components/InvestmentFundCard/CardFooter/CardFooter';
import { LocalStorageKeys } from '../../enums/localStorageKeys';
import { PageRoutes } from '../../enums/pageRoutes';
import PageTitles from '../../enums/pageTitles';
import { PrivatePensions } from '../../enums/privatePensions';
import LocalStorageService from '../../services/localStorageService';
import ErrorActions from '../../store/error/actions';
import { LoadingActions } from '../../store/loading/actions';
import NavbarActions from '../../store/navbar/actions';
import PrivatePensionPlanActions from '../../store/privatePensionPlan/actions';
import { PrivatePensionPlan, PrivatePensionPlanStatus } from '../../store/privatePensionPlan/types';
import UserInfoActions from '../../store/user/actions';
import { ThemeInter } from '../../styles';
import { Div, H1, P, PSmallGray } from '../../styles/commons';
import { formatToBRLCurrency } from '../../utils/numberFormatUtils';
import ErrorBottomSheet from './components/ErrorBottomSheet/ErrorBottomSheet';

interface IBottomSheetState {
  show: boolean;
  title: string;
  description: string[];
}

type BottomSheetInfoType = 'VGBL' | 'PGBL';

const VGBL_INFO = {
  title: 'VGBL',
  description: [
    'Ideal para quem faz a declaração simplificada do Imposto de Renda.',
    'O IR incide apenas sobre seus rendimentos, e só quando você resgata, lá na frente.',
  ],
};

const PGBL_INFO = {
  title: 'PGBL',
  description: [
    'Ideal para quem faz declaração completa do Imposto de Renda e quer investir 12% do total da renda tributável na previdência.',
    'Com o desconto no IR, o que você investiu na previdência (até 12%) é abatido da base de cálculo.',
  ],
};

const PrivatePensionPlansPage: React.FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(LoadingActions.get);
  const { account } = useSelector(UserInfoActions.get);
  const theme = useTheme() as ThemeInter;
  const history = useHistory();
  const [openBottomSheet, setOpenBottomSheet] = useState(false);
  const failedPlans = useSelector(PrivatePensionPlanActions.getFailedPrivatePensionPlans);
  const [failedPrivatePensionPlans, setFailedPrivatePensionPlans] = useState<Array<string>>([]);
  const selectedPrivatePensionPlan = useSelector(PrivatePensionPlanActions.getPrivatePensionPlan);
  const [disabledButton, setDisabledButton] = useState<boolean>(
    !selectedPrivatePensionPlan.certificateNumber,
  );
  const [selectedPrivatePension, setSelectedPrivatePension] = useState<PrivatePensionPlan>(
    selectedPrivatePensionPlan,
  );

  const getLocalStorageItem = () =>
    LocalStorageService.getItem<PrivatePensionPlan[]>(
      LocalStorageKeys.PRIVATE_PENSION_PLANS,
      account,
    );

  const dataFromLocalStorage = getLocalStorageItem();

  const [bottomSheet, setBottomSheet] = useState<IBottomSheetState>({
    show: false,
    title: '',
    description: [''],
  });

  const handlerBottomSheetCloseButton = (
    bottomSheetParams: IBottomSheetState,
    show: boolean,
  ): void => {
    setBottomSheet({ ...bottomSheetParams, show });
  };

  const callBottomSheetInfo = (type: BottomSheetInfoType) => {
    switch (type) {
      case 'PGBL': {
        setBottomSheet({ show: true, ...PGBL_INFO });
        break;
      }
      default: {
        setBottomSheet({ show: true, ...VGBL_INFO });
        break;
      }
    }
  };

  useEffect(() => {
    if (dataFromLocalStorage) {
      setFailedPrivatePensionPlans(
        dataFromLocalStorage.data
          .filter((plan: PrivatePensionPlan) => plan.status === PrivatePensionPlanStatus.ERROR)
          .map((plan: PrivatePensionPlan) => plan.certificateNumber),
      );
    }
  }, [failedPlans]);

  useEffect(() => {
    setOpenBottomSheet(failedPrivatePensionPlans && failedPrivatePensionPlans.length > 0);
  }, [failedPrivatePensionPlans]);

  useEffect(() => {
    dispatch(NavbarActions.setTitle(PageTitles.ADDITIONAL_CONTRIBUTION));
    dispatch(ErrorActions.resetState());
  }, [dispatch]);

  const PGBLInvestmentsFunds = useMemo(() => {
    const PGBL = dataFromLocalStorage?.data?.filter(
      (privatePensionPlan: PrivatePensionPlan) =>
        privatePensionPlan.privatePensionType === PrivatePensions.PGBL,
    );

    return PGBL || [];
  }, [dataFromLocalStorage]);

  const VGBLInvestmentsFunds = useMemo(() => {
    const VGBL = dataFromLocalStorage?.data?.filter(
      (privatePensionPlan: PrivatePensionPlan) =>
        privatePensionPlan.privatePensionType === PrivatePensions.VGBL,
    );

    return VGBL || [];
  }, [dataFromLocalStorage]);

  const selectFundAndEnableButton = (privatePension: PrivatePensionPlan) => {
    setSelectedPrivatePension(privatePension);
    setDisabledButton(false);
  };

  const renderPrivatePensionPlan = (privatePensionsHired: Array<PrivatePensionPlan>) => {
    if (privatePensionsHired.length > 0) {
      return privatePensionsHired?.map((privatePensionPlan: PrivatePensionPlan) => {
        if (privatePensionPlan && privatePensionPlan.investmentFund) {
          const key = `fund-${privatePensionPlan.certificateNumber}`;

          if (privatePensionPlan.status === PrivatePensionPlanStatus.ERROR) {
            return null;
          }

          return (
            <InvestmentFundCard
              key={key}
              dataTestId={key}
              investmentFund={privatePensionPlan.investmentFund}
              marginBottom="16px"
              selected={
                privatePensionPlan.certificateNumber === selectedPrivatePension.certificateNumber
              }
              onSelect={() => selectFundAndEnableButton(privatePensionPlan)}
              footer={
                !!privatePensionPlan.accumulatedValue && (
                  <CardFooter
                    label="Valor acumulado"
                    value={formatToBRLCurrency(privatePensionPlan.accumulatedValue)}
                  />
                )
              }
            />
          );
        }
        return null;
      });
    }
    return (
      <PSmallGray textAlign="center">Não existem previdências para essa modalidade.</PSmallGray>
    );
  };

  const handleContinue = () => {
    const { privatePensionType, investmentFund, isValidToContribute } = selectedPrivatePension;

    AdditionalContributionAnalyticsService.choosePrivatePensionPlan(
      investmentFund?.name || '',
      privatePensionType || '',
    );

    dispatch(PrivatePensionPlanActions.setPrivatePensionPlan(selectedPrivatePension));

    if (!isValidToContribute) {
      history.push(PageRoutes.ADDITIONAL_CONTRIBUTION_EXPIRED);
    } else {
      if (privatePensionType === 'PGBL') {
        history.push(PageRoutes.ADDITIONAL_CONTRIBUTION_PGBL_HELPER);
      }
      if (privatePensionType === 'VGBL') {
        history.push(PageRoutes.ADDITIONAL_CONTRIBUTION_INVESTMENT_FORM);
      }
    }
  };

  const handleClickTryAgain = () => {
    dispatch(PrivatePensionPlanActions.setFailedPrivatePensionPlans(failedPrivatePensionPlans));
    dispatch(
      PrivatePensionPlanActions.requestPrivatePensionPlans({
        history,
        pathname: PageRoutes.PRIVATE_PENSION_PLANS,
      }),
    );
    setOpenBottomSheet(false);
  };

  const stickyFooter: React.ReactElement = (
    <InterUIButton
      type="submit"
      data-testid="private-pension-plans-form-btn"
      form="private-pension-plans"
      disabled={disabledButton}
      loading={loading}
      onClick={() => handleContinue()}
    >
      Continuar
    </InterUIButton>
  );

  return (
    <InterUIContainer stickyFooter={stickyFooter}>
      <H1 marginBottom="8px">Fundos contratados</H1>
      <PSmallGray marginBottom="24px">
        Escolha qual fundo de previdência gostaria de investir.
      </PSmallGray>
      <Div position="relative" marginBottom="24px">
        <P color={theme.colors.grayscale.A400}>
          VGBL
          <button
            type="button"
            style={{ verticalAlign: 'middle', marginLeft: '6px' }}
            onClick={() => callBottomSheetInfo('VGBL')}
            aria-label="O que é a Previdência VGBL?"
          >
            <InterUIIcon name="help" color={theme.colors.primary.A500} size="16px" />
          </button>
        </P>
      </Div>
      {renderPrivatePensionPlan(VGBLInvestmentsFunds)}
      <Div position="relative" marginBottom="24px" marginTop="24px">
        <P color={theme.colors.grayscale.A400}>
          PGBL
          <button
            type="button"
            style={{ verticalAlign: 'middle', marginLeft: '6px' }}
            onClick={() => callBottomSheetInfo('PGBL')}
            aria-label="O que é a Previdência PGBL?"
          >
            <InterUIIcon name="help" color={theme.colors.primary.A500} size="16px" />
          </button>
        </P>
      </Div>
      {renderPrivatePensionPlan(PGBLInvestmentsFunds)}
      <InterUIBottomSheet
        title={bottomSheet.title}
        toggle={bottomSheet.show}
        onHide={() => handlerBottomSheetCloseButton({ ...bottomSheet }, false)}
      >
        {bottomSheet.description.map((paragraph, index) => (
          <PSmallGray key={`paragraph-${index}`}>{paragraph}</PSmallGray>
        ))}
        <InterUIButton
          type="button"
          onClick={() => handlerBottomSheetCloseButton({ ...bottomSheet }, false)}
          margin="32px 0px 0px 0px"
        >
          Entendi
        </InterUIButton>
      </InterUIBottomSheet>
      <ErrorBottomSheet
        setOpenBottomSheet={setOpenBottomSheet}
        openBottomSheet={openBottomSheet}
        handleClickTryAgain={handleClickTryAgain}
      />
    </InterUIContainer>
  );
};

export default PrivatePensionPlansPage;
