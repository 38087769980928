/**
 * The commons actions of the application
 */
enum Action {
  SORT = 'Ordenar',
  CLOSE = 'Fechar',
  EMPTY = '',
}

export default Action;
