import { AnyAction, Reducer } from 'redux';
import { INITIAL_CONTRIBUTION_MINIMUM, MONTHLY_CONTRIBUTION_MINIMUM } from '../../utils/minValues';
import { IncomeTaxPageState, IncomeTaxPageTypes } from './types';

const INITIAL_STATE: IncomeTaxPageState = {
  strategy: '',
  initialContribution: 0,
  initialMinimum: INITIAL_CONTRIBUTION_MINIMUM,
  monthlyContribution: 0,
  monthlyMinimum: MONTHLY_CONTRIBUTION_MINIMUM,
  annualContribution: 0,
};

const reducer: Reducer<IncomeTaxPageState> = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case IncomeTaxPageTypes.SET_CONTRIBUTION_STRATEGY: {
      return { ...state, strategy: action.payload };
    }
    case IncomeTaxPageTypes.SET_INITIAL_CONTRIBUTION: {
      return { ...state, initialContribution: action.payload };
    }
    case IncomeTaxPageTypes.SET_MONTHLY_CONTRIBUTION: {
      return { ...state, monthlyContribution: action.payload };
    }
    case IncomeTaxPageTypes.SET_INITIAL_MINIMUM: {
      return { ...state, initialMinimum: action.payload };
    }
    case IncomeTaxPageTypes.SET_MONTHLY_MINIMUM: {
      return { ...state, monthlyMinimum: action.payload };
    }
    case IncomeTaxPageTypes.SET_ANNUAL_CONTRIBUTION: {
      return { ...state, annualContribution: action.payload };
    }
    case IncomeTaxPageTypes.RESET: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
