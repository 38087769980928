/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ExternalPensionPlan, FinancialInstitution } from '../store/portability/types';
import { PortabilityStatus } from '../store/portabilityStatus/types';
import { ProposalDetailsState } from '../store/proposal/types';
import { AppInfoService } from './appInfoService';
import { BaseService, ServiceResponse } from './baseService';
import { MockService } from './mockService';

const {
  REACT_APP_API_PENSION_PORTABILITY_V1,
  REACT_APP_API_BRIDGE_PENSION_PORTABILITY_V1,
  REACT_APP_INTER_ENV,
} = process.env;

interface DataCollectionResponse {
  portabilityId: number;
}

interface AccessTokenResponse {
  access: string;
  refresh: string;
}

class PortabilityService {
  static async requestPortability(
    accumulatedValue: number | null,
    financialInstitutionId: number | null,
  ): Promise<ServiceResponse<any>> {
    return BaseService.doExecute({
      endpoint: {
        browser: `${REACT_APP_API_PENSION_PORTABILITY_V1}/portabilidade`,
        bridge: `${REACT_APP_API_BRIDGE_PENSION_PORTABILITY_V1}/portabilidade`,
      },
      method: 'POST',
      data: { accumulatedValue, financialInstitutionId },
    });
  }

  static async getFinancialInstitutions(): Promise<ServiceResponse<Array<FinancialInstitution>>> {
    return BaseService.doExecute({
      endpoint: {
        browser: `${REACT_APP_API_PENSION_PORTABILITY_V1}/instituicoes-financeiras`,
        bridge: `${REACT_APP_API_BRIDGE_PENSION_PORTABILITY_V1}/instituicoes-financeiras`,
      },
      method: 'GET',
    });
  }

  static async requestAccessToken(): Promise<ServiceResponse<AccessTokenResponse>> {
    return BaseService.doExecute({
      endpoint: {
        browser: `${REACT_APP_API_PENSION_PORTABILITY_V1}/portabilidade-automatica/token-acesso-widget`,
        bridge: `${REACT_APP_API_BRIDGE_PENSION_PORTABILITY_V1}/portabilidade-automatica/token-acesso-widget`,
      },
      method: 'GET',
    });
  }

  static async requestPortabilityProposal(
    privatePensionId: number,
    userCpf: string,
  ): Promise<ServiceResponse<ProposalDetailsState>> {
    return BaseService.doExecute({
      endpoint: {
        browser: `${REACT_APP_API_PENSION_PORTABILITY_V1}/portabilidade-automatica/proposta/interno`,
        bridge: `${REACT_APP_API_BRIDGE_PENSION_PORTABILITY_V1}/portabilidade-automatica/proposta`,
      },
      method: 'POST',
      headers: {
        browser: {
          'x-inter-cpf': userCpf,
          'x-inter-app-origem': 'Webview Previdência',
          'x-inter-app-version': AppInfoService.superAppVersion,
          'x-inter-conta-corrente': MockService.account,
          ...MockService.matchHeadersByUserAccount,
        },
        /**
         * This conditions is being fulfilled because:
         * At this date (18/05/2021) the Bridge is returning a CPF that is not the same of the CPF registered on the UAT backend (inter-consulta-cliente-service).
         * To avoid any problems, we are not sending the CPF received from the Bridge to the development and staging environments.
         * From the previdência backend view, there will be no error. But, don´t send the CPF creates one more processing for the backend to request the CPF.
         */
        bridge: REACT_APP_INTER_ENV === 'production' ? { 'x-inter-cpf': userCpf } : undefined,
      },
      data: { privatePensionId },
    });
  }

  static async requestDataCollection(
    link: string | null,
    institutionId: number | null,
  ): Promise<ServiceResponse<DataCollectionResponse>> {
    return BaseService.doExecute({
      endpoint: {
        browser: `${REACT_APP_API_PENSION_PORTABILITY_V1}/portabilidade-automatica/coleta-dados/interno`,
        bridge: `${REACT_APP_API_BRIDGE_PENSION_PORTABILITY_V1}/portabilidade-automatica/coleta-dados`,
      },
      method: 'POST',
      headers: {
        browser: {
          'x-inter-app-origem': 'Webview Previdência',
          'x-inter-app-version': AppInfoService.superAppVersion,
          'x-inter-conta-corrente': MockService.account,
          ...MockService.matchHeadersByUserAccount,
        },
      },
      data: { link, institutionId },
    });
  }

  static async requestPensionPlans(
    portabilityId: number | null,
    userCpf: string,
  ): Promise<ServiceResponse<Array<ExternalPensionPlan>>> {
    return BaseService.doExecute({
      endpoint: {
        browser: `${REACT_APP_API_PENSION_PORTABILITY_V1}/portabilidade-automatica/previdencias-privada-origem/interno`,
        bridge: `${REACT_APP_API_BRIDGE_PENSION_PORTABILITY_V1}/portabilidade-automatica/previdencias-privada-origem`,
      },
      method: 'POST',
      headers: {
        browser: {
          'x-inter-cpf': userCpf,
          'x-inter-app-origem': 'Webview Previdência',
          'x-inter-app-version': AppInfoService.superAppVersion,
          'x-inter-conta-corrente': MockService.account,
          ...MockService.matchHeadersByUserAccount,
        },
        /**
         * This conditions is being fulfilled because:
         * At this date (18/05/2021) the Bridge is returning a CPF that is not the same of the CPF registered on the UAT backend (inter-consulta-cliente-service).
         * To avoid any problems, we are not sending the CPF received from the Bridge to the development and staging environments.
         * From the previdência backend view, there will be no error. But, don´t send the CPF creates one more processing for the backend to request the CPF.
         */
        bridge: REACT_APP_INTER_ENV === 'production' ? { 'x-inter-cpf': userCpf } : undefined,
      },
      data: { portabilityId },
    });
  }

  static async requestPortabilities(): Promise<ServiceResponse<Array<PortabilityStatus>>> {
    return BaseService.doExecute({
      endpoint: {
        browser: `${REACT_APP_API_PENSION_PORTABILITY_V1}/portabilidade-automatica/portabilidades/interno`,
        bridge: `${REACT_APP_API_BRIDGE_PENSION_PORTABILITY_V1}/portabilidade-automatica/portabilidades`,
      },
      method: 'GET',
      headers: {
        browser: {
          'x-inter-app-origem': 'Webview Previdência',
          'x-inter-app-version': AppInfoService.superAppVersion,
          'x-inter-conta-corrente': MockService.account,
          ...MockService.matchHeadersByUserAccount,
        },
      },
    });
  }
}
export default PortabilityService;
