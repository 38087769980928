/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react-hooks/exhaustive-deps */
import { InterUILoading } from '@interco/inter-ui-react-lib';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { EnvVariableKeys } from '../../enums/evironmentVariableKeys';
import { LocalStorageKeys } from '../../enums/localStorageKeys';
import { AppInfoService } from '../../services/appInfoService';
import { EnvVariableService } from '../../services/environmentVariableService';
import LocalStorageService from '../../services/localStorageService';
import { LoadingActions } from '../../store/loading/actions';
import UserInfoActions from '../../store/user/actions';
import { Flex } from '../../styles/commons';
import { handleParamsPage } from '../../utils/routesUtils';

// The minimal app version to use the qualified investor term deeplink and suitability deeplink.
const STARTING_VERSION_TO_VALIDATE_CUSTOMER_SUITABILITY = AppInfoService.convertToNumber(
  EnvVariableService.getVariable(
    EnvVariableKeys.STARTING_VERSION_TO_VALIDATE_CUSTOMER_SUITABILITY,
  )!,
);

const CheckSignedTermsDeeplinkSplashPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector(LoadingActions.get);
  const { account } = useSelector(UserInfoActions.get);
  const { isAcceptable } = useSelector(UserInfoActions.getUserSignedTerms);

  const hasValidSignedTermsLocalStorage = () =>
    !LocalStorageService.hasExpired(LocalStorageKeys.USER_SIGNED_TERMS, account);

  useEffect(() => {
    dispatch(LoadingActions.show());

    if (account && AppInfoService.superAppVersion) {
      const signedTermsFromLocalStorageIsValid = hasValidSignedTermsLocalStorage();
      const APP_VERSION_INFO = AppInfoService.convertToNumber(AppInfoService.superAppVersion);
      // We compare versions because only in the v11.2 of the app, we have the Suitability and Investor Profile deeplink
      // Under the v11.2, the user will follow the old flow.
      const isAppVersionAllowed =
        APP_VERSION_INFO >= STARTING_VERSION_TO_VALIDATE_CUSTOMER_SUITABILITY;

      if (!signedTermsFromLocalStorageIsValid && isAcceptable !== true && isAppVersionAllowed) {
        dispatch(
          UserInfoActions.requestUserSignedTerms({
            pathname: handleParamsPage(String(history.location.state)),
            history,
          }),
        );
      } else {
        history.push(handleParamsPage(String(history.location.state)));
        dispatch(LoadingActions.hide());
      }
    }
  }, [dispatch, history, isAcceptable, account, AppInfoService.superAppVersion]);

  return (
    <Flex height="100vh" justifyContent="center" alignItems="center">
      {loading && <InterUILoading size="ld" data-testid="loading" />}
    </Flex>
  );
};
export default CheckSignedTermsDeeplinkSplashPage;
