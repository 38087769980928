import { CSSProperties, ReactElement } from 'react';
import { InvestmentFund } from '../../components/InvestmentFundCard/InvestmentFundCard';

type IFields = Array<IField>;

export interface IField {
  key: number;
  name: string;
  value?: string;
  style?: CSSProperties;
}

export interface ITargetPlanSetting {
  key: number;
  fields: IFields;
  expandableContent: ReactElement;
  selected: boolean;
  fund: InvestmentFund;
}

export interface ISectionSettings {
  sectionTitle: string;
  existingPlans?: ITargetPlanSetting[];
  newPlans?: ITargetPlanSetting[];
  fields?: IFields;
}

export interface PathParam {
  id: string;
}

export const LoadingIds = {
  confirmButton: '@external-portability/confirm-btn',
  page: '@external-portability/page',
  backToHomeButton: '@external-portability/back-to-home-btn',
};

export const Titles = {
  Plan: {
    SUSEP_NUMBER: 'Número Susep',
    CERTIFICATE_NUMBER: 'Número Certificado',
    PROPOSAL_NUMBER: 'Número Proposta',
    FUND_CNPJ: 'CNPJ do fundo',
    PLAN_TYPE: 'Tipo do plano',
    TAX_TYPE: 'Regime Tributação',
    PORTABILITY_TYPE: 'Tipo Portabilidade',
    PORTABILITY_VALUE: 'Valor',
  },
  OriginPlan: {
    TITLE: 'Plano de Origem',
  },
  TargetPlans: {
    TITLE: 'Planos de Destino',
    DISTRIBUTION_PERCENTAGE: 'Percentual de distribuição',
  },
};
