import { AnyAction } from 'redux';
import { action } from 'typesafe-actions';
import { SagaWithHistory } from '../../utils/providerSaga';
import { RootState } from '../index';
import { FundSortterType, SelectableFundsTypes } from './types';

const getSortter = (state: RootState): FundSortterType => state.selectableFunds.sortter;

const setSortter = (sotter: FundSortterType): AnyAction =>
  action(SelectableFundsTypes.SET_SORTTER, sotter);

const requestAvailableFunds = (data?: SagaWithHistory): AnyAction =>
  action(SelectableFundsTypes.REQUEST_AVAILABLE_FUNDS, data);

const requestAllAvailableFunds = (data?: SagaWithHistory): AnyAction =>
  action(SelectableFundsTypes.REQUEST_ALL_AVAILABLE_FUNDS, data);

const resetState = (): AnyAction => action(SelectableFundsTypes.RESET);

const SelectableFundsActions = {
  getSortter,
  setSortter,
  requestAvailableFunds,
  requestAllAvailableFunds,
  resetState,
};

export default SelectableFundsActions;
