/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { BaseService, ServiceResponse } from './baseService';
import { MockService } from './mockService';

const {
  REACT_APP_API_INVESTMENT_CONFIGURATION_V1,
  REACT_APP_API_BRIDGE_INVESTMENT_CONFIGURATION_V1,
} = process.env;

// const getYesterdayDate = () => {
//   const yesterdayDate = new Date();
//   yesterdayDate.setDate(yesterdayDate.getDate() - 1);
//   return yesterdayDate.toISOString();
// };

class UserSignedTermsService {
  static async requestSignedTerms(userAccount: string | number): Promise<ServiceResponse<any>> {
    return BaseService.doExecute({
      endpoint: {
        browser: `${REACT_APP_API_INVESTMENT_CONFIGURATION_V1}/termos-declaracao/interno`,
        bridge: `${REACT_APP_API_BRIDGE_INVESTMENT_CONFIGURATION_V1}/clientes/configuracoes`,
      },

      method: 'GET',
      headers: {
        browser: {
          'x-inter-numero-conta-cliente': userAccount as string,
          ...MockService.matchHeadersByUserAccount,
        },

        // About X-Inter-UltimaAtualizacao:
        //  This header is used internally by the backend to do a cache control.
        //  Depending on how the information is passed, the service may return an EMPTY response body with status 304 (not modified).
        //  This behavior does not suit our scenario because we always need the appropriate return from the service, that is, a response with
        //  body and all the respective fields.
        //  So, to avoid this behavior of returning an empty body, just pass the header empty.
        //  For further clarification, please check the code at:
        //    https://gitlab.sharedservices.local/investimentos/inter-configuracoes-investimentos-service/-/blob/master/src/main/java/br/com/bancointer/controllers/TermoDeclaracaoController.java
        //    Method: consultTermosDeclaração
        //  ATTENTION: even if it is sent empty, the header needs to be set to avoid sending a null value, otherwise we will receive a bad request.

        bridge: { 'X-Inter-UltimaAtualizacao': '' },
      },
    });
  }
}

export default UserSignedTermsService;
