/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from 'axios';
import { IWbUserInfo } from 'inter-webview-bridge';
import { MockService } from './mockService';
/**
 * This service should only be called on browser
 */
class UserInfoMockService {
  static async requestUserInfo(account?: string): Promise<IWbUserInfo> {
    const path = account ? `app-bridge/user/${account}` : 'app-bridge/user';

    let response = {} as AxiosResponse<IWbUserInfo>;

    try {
      response = await axios({
        method: 'GET',
        url: `${MockService.baseUrl}/${path}`,
        headers: { 'x-api-key': MockService.apiKey },
      });
    } catch (error: any) {
      MockService.handleMockServiceErrors(error, 'UserInfoMockService.handleMockServiceErrors');
    }

    return response.data;
  }
}
export default UserInfoMockService;
