import { TrackableError } from '../exceptions';

function withErrorCodeRef(originalError: unknown, errorCodeRef: string): TrackableError {
  if (originalError instanceof Error) {
    const trackableError: TrackableError = originalError;
    trackableError.errorCodeRef = errorCodeRef;
    return trackableError;
  }

  if (typeof originalError === 'string') {
    const trackableError: TrackableError = new Error(originalError);
    trackableError.errorCodeRef = errorCodeRef;
    return trackableError;
  }

  const trackableError: TrackableError = new Error(JSON.stringify(originalError));
  trackableError.errorCodeRef = errorCodeRef;
  return trackableError;
}

async function errorHandlerDecorator(
  callback: () => Promise<unknown>,
  handleError: (error: unknown) => void,
  errorCodeRef: string,
): Promise<void> {
  try {
    await callback();
  } catch (e) {
    handleError(withErrorCodeRef(e, errorCodeRef));
  }
}

export { errorHandlerDecorator as _try, withErrorCodeRef };
