import { PrivatePensions, TaxTypes } from '../../enums/privatePensions';

export enum DeepLinkProposalTypes {
  SET_DEEP_LINK_PROPOSAL = `@deepLinkProposal/SET_DEEP_LINK_PROPOSAL`,
  SET_DEEP_LINK_PROPOSAL_FAILURE = `@deepLinkProposal/SET_DEEP_LINK_PROPOSAL_FAILURE`,
  RESET = `@deepLinkProposal/RESET`,
}

export interface DeepLinkProposalState {
  initialContribution: number;
  monthlyContribution: number;
  privatePensionType: PrivatePensions;
  taxType: TaxTypes;
  investmentFundcode: string;
  numberMonthsInvesting: number;
  paymentDay: number;
  error?: boolean;
}
