/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
import { InterUIBox, InterUIIcon } from '@interco/inter-ui-react-lib';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import AdditionalContributionAnalyticsService from '../../../analytics/additionalContributionAnalyticsService';
import { FormWithHook } from '../../../components/FormWithHook/FormWithHook';
import InputPricingWithHook from '../../../components/InputPricingWithHook/InputPricingWithHook';
import { getMessages, MessagesValidation } from '../../../enums/messages';
import { PageRoutes } from '../../../enums/pageRoutes';
import { PrivatePensions } from '../../../enums/privatePensions';
import PrivatePensionPlanActions from '../../../store/privatePensionPlan/actions';
import UserInfoActions from '../../../store/user/actions';
import { ThemeInter } from '../../../styles';
import { Div, H3, PSmallGray } from '../../../styles/commons';
import { InterUIAlertCustomized } from '../../../styles/inter-ui-customizations';
import { formatToBRLCurrency } from '../../../utils/numberFormatUtils';

export interface IAdditionalInvestmentForm {
  additionalContribution: number;
}
interface IAdditionalInvestmentProps {
  setDisabled: (disabled: boolean) => void;
  maximumDeductibleAmount: number;
  minimumAdditionalContribution: number;
  defaultAdditionalContribution?: number;
  privatePensionType?: string;
  investmentFundName?: string;
  additionalContributionPGBLMethod?: string;
}
const InvestmentForm: React.FC<IAdditionalInvestmentProps> = ({
  setDisabled,
  maximumDeductibleAmount,
  minimumAdditionalContribution,
  defaultAdditionalContribution,
  privatePensionType,
  investmentFundName,
  additionalContributionPGBLMethod,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme() as ThemeInter;
  const methods = useForm<IAdditionalInvestmentForm>({
    mode: 'onChange',
    defaultValues: {
      additionalContribution: defaultAdditionalContribution || 0,
    },
    reValidateMode: 'onChange',
  });

  const {
    getValues,
    formState: { isValid },
  } = methods;

  const isDisableButton = useMemo(() => !isValid, [isValid]);

  const additionalInvestmentValidation = useMemo(() => {
    if (maximumDeductibleAmount === 0) {
      return undefined;
    }
    const initialContributionForm = +getValues('additionalContribution')! || 0;
    const deductibleIncomeExpense = +(maximumDeductibleAmount - initialContributionForm).toFixed(2);
    const contributionUnderMaxiumDeductible = +(
      maximumDeductibleAmount - initialContributionForm
    ).toFixed(2);

    if (deductibleIncomeExpense > 0) {
      return {
        message: getMessages(
          MessagesValidation.ANNUAL_CONTRIBUTION_UNDER_MAXIMUM_DEDUCTIBLE_AMOUNT_ADDITIONAL_CONTRIBUTION,
          [formatToBRLCurrency(contributionUnderMaxiumDeductible)],
        ),
      };
    }

    if (deductibleIncomeExpense < 0) {
      return {
        message: getMessages(
          MessagesValidation.MAXIMUM_DEDUCTIBLE_AMOUNT_EXCEEDS_ADDITIONAL_CONTRIBUTION,
          [
            formatToBRLCurrency(maximumDeductibleAmount),
            formatToBRLCurrency(Math.abs(deductibleIncomeExpense)),
          ],
        ),
      };
    }

    return undefined;
  }, [getValues, maximumDeductibleAmount, getValues('additionalContribution')]);

  useEffect(() => {
    setDisabled(isDisableButton);
  }, [isDisableButton, setDisabled]);

  const onSubmit = (data: IAdditionalInvestmentForm) => {
    AdditionalContributionAnalyticsService.goToProposalPage(
      investmentFundName || '',
      privatePensionType || '',
    );

    if (
      privatePensionType === PrivatePensions.PGBL &&
      additionalContributionPGBLMethod! === 'EXACT_VALUE'
    ) {
      AdditionalContributionAnalyticsService.goToRevisionPagePGBL(
        investmentFundName || '',
        privatePensionType || '',
      );
    } else if (
      privatePensionType === PrivatePensions.PGBL &&
      additionalContributionPGBLMethod! === 'SIMULATE_VALUE'
    ) {
      AdditionalContributionAnalyticsService.goToProposalPageSimulatingAnnualIncome(
        investmentFundName || '',
        privatePensionType || '',
      );
    }

    dispatch(PrivatePensionPlanActions.setAdditionalContribution(data.additionalContribution));

    dispatch(
      UserInfoActions.requestUserBalanceInfo({
        history,
        pathname: PageRoutes.ADDITIONAL_CONTRIBUTION_PROPOSAL,
      }),
    );
  };

  return (
    <FormWithHook
      methods={methods}
      onSubmit={onSubmit}
      formId="additional-contribution-investment-form"
      margin="0px"
    >
      <H3 marginBottom="8px">Valor do investimento</H3>

      <InputPricingWithHook
        name="additionalContribution"
        placeholder="0,00"
        prefix="R$"
        maxLength={14}
        validation={{
          min: {
            value: minimumAdditionalContribution,
            message: getMessages(MessagesValidation.MINIMUM_VALUE_VALIDATION, [
              formatToBRLCurrency(minimumAdditionalContribution),
            ]),
          },
        }}
        margin="0 0 16px 0"
        shouldDirty
      />
      <Div marginBottom="12px">
        {additionalInvestmentValidation && (
          <InterUIBox padding="0px" margin="0 0 16px">
            <InterUIAlertCustomized
              color={theme.colors.white}
              icon={<InterUIIcon name="contextual-info" color={theme.colors.alert.A400} />}
              alignItems="center"
            >
              <PSmallGray marginBottom="0">{additionalInvestmentValidation.message}</PSmallGray>
            </InterUIAlertCustomized>
          </InterUIBox>
        )}
      </Div>
    </FormWithHook>
  );
};

export default InvestmentForm;
