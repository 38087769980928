export enum BuyGoodPageType {
  SET_BUY_GOOD_STATE = `@buyGood/SET_BUY_GOOD_STATE`,
  RESET = `@buyGood/RESET`,
}

export interface BuyGoodPageState {
  goalName: string;
  goalValue: number;
  years?: number;
  months?: number;
}
