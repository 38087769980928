import { AnyAction } from 'redux';
import { action } from 'typesafe-actions';
import { RootState } from '..';
import {
  ProposalByFundContributionValuesState,
  ProposalByFundContributionValuesType,
} from './types';

const get = (state: RootState): ProposalByFundContributionValuesState => state.proposalByFund;
const set = (proposalByFund: ProposalByFundContributionValuesState): AnyAction =>
  action(ProposalByFundContributionValuesType.SET_CONTRIBUTION_VALUES, proposalByFund);

const setInitialValue = (initialValue: number): AnyAction =>
  action(ProposalByFundContributionValuesType.SET_INITIAL_VALUE, initialValue);

const setMonthlyValue = (monthlyValue: number): AnyAction =>
  action(ProposalByFundContributionValuesType.SET_MONTHLY_VALUE, monthlyValue);

const setStrategyContribution = (strategyContribution: string): AnyAction =>
  action(ProposalByFundContributionValuesType.SET_STRATEGY_CONTRIBUTION, strategyContribution);

const resetState = (): AnyAction => action(ProposalByFundContributionValuesType.RESET);

export const ProposalByFundActions = {
  get,
  set,
  setInitialValue,
  setMonthlyValue,
  setStrategyContribution,
  resetState,
};
