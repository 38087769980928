/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from 'axios';
import { IWbISafeResponse } from 'inter-webview-bridge';
import { MockService } from './mockService';

/**
 * This service should only be called on browser
 */
class ISafeTokenMockService {
  static async requestISafe(
    category: string,
    checkType: string,
    feature: string,
    value: number,
  ): Promise<IWbISafeResponse> {
    const path = 'app-bridge/i-safe';

    let response = {} as AxiosResponse<IWbISafeResponse>;

    try {
      response = await axios({
        method: 'POST',
        url: `${MockService.baseUrl}/${path}`,
        headers: { 'x-api-key': MockService.apiKey },
        data: { category, checkType, feature, value },
      });
    } catch (error: any) {
      MockService.handleMockServiceErrors(error, 'ISafeTokenMockService.handleMockServiceErrors');
    }

    return response.data;
  }
}
export default ISafeTokenMockService;
