export enum IncomeTaxPageTypes {
  SET_CONTRIBUTION_STRATEGY = `@incomeExpenseContribution/SET_CONTRIBUTION_STRATEGY`,
  SET_INITIAL_CONTRIBUTION = `@incomeExpenseContribution/SET_INITIAL_CONTRIBUTION`,
  SET_MONTHLY_CONTRIBUTION = `@incomeExpenseContribution/SET_MONTHLY_CONTRIBUTION`,
  SET_INITIAL_MINIMUM = `@incomeExpenseContribution/SET_INITIAL_MINIMUM`,
  SET_MONTHLY_MINIMUM = `@incomeExpenseContribution/SET_MONTHLY_MINIMUM`,
  SET_ANNUAL_CONTRIBUTION = `@incomeExpenseContribution/SET_ANNUAL_CONTRIBUTION`,
  RESET = `@incomeExpenseContribution/RESET`,
}

export interface IncomeTaxPageState {
  readonly strategy: string;
  readonly initialContribution: number;
  readonly initialMinimum: number;
  readonly monthlyContribution: number;
  readonly monthlyMinimum: number;
  readonly annualContribution: number;
}
