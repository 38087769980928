import { InterUIIcon } from '@interco/inter-ui-react-lib';
import React from 'react';
import FontSizeREM from '../../enums/fontSizesRem';
import Colors from '../../styles/colors';
import { Div, P } from '../../styles/commons';
import SelectStyle from './Select.styles';

interface SelectProps {
  children: JSX.Element | JSX.Element[];
  width?: string;
  padding?: string;
  backgroundColor?: string;
  borderRadius?: string;
  label?: string;
  placeholder?: string;
  marginBottom?: string;
  onChange: (e: string | number) => void;
  defaultValue?: string | number;
  selectValueColor?: string;
  dataTestId?: string;
}

const Select: React.FC<SelectProps> = ({
  children,
  width,
  padding,
  backgroundColor,
  borderRadius,
  label,
  placeholder,
  marginBottom,
  onChange,
  defaultValue,
  selectValueColor,
  dataTestId,
}) => {
  const iconStyle: React.CSSProperties = {
    position: 'absolute',
    top: '17px',
    right: '28px',
    zIndex: 1,
    display: 'inline-block',
    pointerEvents: 'none',
  };

  return (
    <Div marginBottom={marginBottom}>
      {label !== undefined ? (
        <P
          marginBottom="4px"
          fontSize={FontSizeREM.PX14}
          lineHeight={FontSizeREM.PX17}
          fontWeight={400}
          color={Colors.shuttleGray}
        >
          {label}
        </P>
      ) : null}
      <Div position="relative">
        <SelectStyle
          width={width}
          padding={padding}
          backgroundColor={backgroundColor}
          borderRadius={borderRadius}
          onChange={(e) => onChange(e.target.value)}
          value={defaultValue}
          color={selectValueColor}
          placeholder={placeholder}
          data-testid={dataTestId}
        >
          {children}
        </SelectStyle>
        <InterUIIcon
          style={iconStyle}
          name="arrow-chevron-down"
          size="12px"
          color={Colors.interOrange}
        />
      </Div>
    </Div>
  );
};

export default Select;
