/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { InterUIInputGroup } from '@interco/inter-ui-react-lib';
import React, { useEffect, useState } from 'react';
import {
  Control,
  FieldError,
  RegisterOptions,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetFocus,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { useTheme } from 'styled-components';
import { ThemeInter } from '../../styles';
import { Div, InfoMessage } from '../../styles/commons';
import {
  convertValueMaskedToNumber,
  getInputSizeByText,
  getNumericTypedValue,
} from '../../utils/inputPricingUtils';
import { currencyMask } from '../../utils/masksUtils';
import { InputPricingContainer, PrefixPricing } from './InputPricingWithHook.styles';

interface IInfoMessage {
  message: string;
  color?: string;
}

export interface IInputPricingWithHookProps {
  name: string;
  nextField?: string;
  placeholder?: string;
  type?: string;
  label?: string;
  disabled?: boolean;
  readOnly?: boolean;
  dataTestId?: string;
  prefix?: string;
  maxLength?: number;
  info?: IInfoMessage;
}

type InputProps = React.InputHTMLAttributes<HTMLInputElement> &
  IInputPricingWithHookProps & {
    register?: UseFormRegister<any>;
    getValues?: UseFormGetValues<any>;
    setValue?: UseFormSetValue<any>;
    setFocus?: UseFormSetFocus<any>;
    validation?: RegisterOptions;
    errors?: FieldError;
    margin?: string;
    control?: Control;
    shouldDirty?: boolean;
    onBlur?: React.FocusEventHandler<any>;
  };

const InputPricingWithHook: React.FC<InputProps> = ({
  name,
  nextField,
  placeholder,
  label,
  disabled = false,
  readOnly = false,
  dataTestId,
  errors,
  info,
  maxLength,
  prefix,
  margin = '0px',
  register = (data: any) => {},
  getValues = (data: any) => null,
  setValue = (data: any) => {},
  setFocus,
  validation,
  shouldDirty = false,
  control,
  onBlur = (data: any) => {},
  ...props
}) => {
  const theme = useTheme() as ThemeInter;
  const [width, setWidth] = useState<string>(
    `${placeholder ? getInputSizeByText(placeholder) : '100%'}`,
  );

  const curVal = useWatch({ control, name });

  const doUpdate = (value: number) => {
    if (placeholder) {
      const maskedValue = currencyMask(`${getNumericTypedValue(value)}`, 2, 'pt-BR');
      const placeholderSize = placeholder.split('');
      const inputValueSize = maskedValue.split('');
      if (inputValueSize.length < placeholderSize.length) {
        setValue(name, maskedValue);
        setWidth(getInputSizeByText(placeholder));
      } else {
        setValue(name, maskedValue);
        setWidth(getInputSizeByText(maskedValue));
      }
    }
  };

  useEffect(() => {
    doUpdate(curVal);
  }, [curVal]);

  return (
    <InputPricingContainer color={info?.color}>
      <InterUIInputGroup
        invalid={!!errors}
        disabled={disabled}
        readOnly={readOnly}
        variant="pricing"
        width={width}
        margin={margin}
      >
        <Div marginBottom="6px">
          <label htmlFor={name}>{label}</label>
          {prefix && (
            <PrefixPricing invalid={!!errors} disabled={disabled}>
              {prefix}{' '}
            </PrefixPricing>
          )}

          <input
            {...register(name, {
              ...validation,
              setValueAs: (value) => (value ? convertValueMaskedToNumber(value) : value),
            })}
            {...props}
            data-testid={dataTestId || name}
            readOnly={disabled}
            maxLength={maxLength}
            name={name}
            id={name}
            placeholder={placeholder}
            autoComplete="off"
            type="text"
            inputMode="numeric"
            onChange={(e) => {
              setValue(name, currencyMask(`${e.target.value}`, 2, 'pt-BR'), {
                shouldValidate: true,
                shouldDirty,
              });
            }}
            onKeyDown={(event) => {
              if ((event.isTrusted && event.key === 'Enter') || event.code === '13') {
                if (nextField && setFocus) {
                  setFocus(nextField);
                }
              }
            }}
            onBlur={onBlur}
          />
        </Div>
        <InfoMessage color={errors && errors.message ? theme.colors.error.A400 : info?.color}>
          {errors && errors.message ? (
            <span>{errors.message}</span>
          ) : info && info.message ? (
            <span>{info.message}</span>
          ) : (
            ''
          )}
        </InfoMessage>
      </InterUIInputGroup>
    </InputPricingContainer>
  );
};

export default InputPricingWithHook;
