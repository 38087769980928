export enum RetirementPageTypes {
  SET_CONTRIBUTION_STRATEGY = `@retirement/SET_CONTRIBUTION_STRATEGY`,
  SET_INITIAL_CONTRIBUTION = `@retirement/SET_INITIAL_CONTRIBUTION`,
  SET_MONTHLY_CONTRIBUTION = `@retirement/SET_MONTHLY_CONTRIBUTION`,
  SET_INITIAL_MINIMUM = `@retirement/SET_INITIAL_MINIMUM`,
  SET_MONTHLY_MINIMUM = `@retirement/SET_MONTHLY_MINIMUM`,
  RESET = `@retirement/RESET`,
}

export interface RetirementPageState {
  readonly strategy: string;
  readonly initialContribution: number;
  readonly initialMinimum: number;
  readonly monthlyContribution: number;
  readonly monthlyMinimum: number;
}
