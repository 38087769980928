import { AnyAction, Reducer } from 'redux';
import { PreviewContractState, PreviewContractTypes } from './types';

const INITIAL_STATE: PreviewContractState = {
  contract: null,
};

const reducer: Reducer<PreviewContractState> = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case PreviewContractTypes.SET_CONTRACT: {
      return { ...state, contract: action.payload };
    }
    case PreviewContractTypes.RESET: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
