import { useEffect, useState } from 'react';

export function useNewTotalDeductions(
  initialTotalDeductions: number,
  pgblExpenditure: number,
  socialSecurity: number,
  educationExpenditure: number,
  dependentsDeduction: number,
  healthyExpenditure: number,
): number {
  const [totalDeductions, setTotalDeductions] = useState(initialTotalDeductions);

  useEffect(() => {
    const totalCalc =
      socialSecurity +
      educationExpenditure +
      dependentsDeduction +
      healthyExpenditure +
      pgblExpenditure;

    setTotalDeductions(totalCalc);
  }, [
    pgblExpenditure,
    dependentsDeduction,
    educationExpenditure,
    healthyExpenditure,
    socialSecurity,
  ]);

  return totalDeductions;
}
