import { InvestmentFund } from '../../components/InvestmentFundCard/InvestmentFundCard';
import { PrivatePensions, TaxTypes } from '../../enums/privatePensions';

export enum PrivatePensionPlanTypes {
  SET_PRIVATE_PENSION_PLAN = '@privatePensionPlan/SET_PRIVATE_PENSION_PLAN',
  RESET = '@privatePensionPlan/RESET',
  REQUEST_PRIVATE_PENSION_PLANS = '@privatePensionPlan/REQUEST_PRIVATE_PENSION_PLANS',
  REQUEST_PRIVATE_PENSION_PLANS_DEEP_LINK = '@privatePensionPlan/REQUEST_PRIVATE_PENSION_PLANS_DEEP_LINK',
  SET_ADDITIONAL_CONTRIBUTION = '@privatePensionPlan/SET_ADDITIONAL_CONTRIBUTION',
  SET_ADDITIONAL_CONTRIBUTION_PGBL_METHOD = '@privatePensionPlan/SET_ADDITIONAL_CONTRIBUTION_PGBL_METHOD',
  SET_ANNUAL_INCOME_SIMULATION_VALUE = '@privatePensionPlan/SET_ANNUAL_INCOME_SIMULATION_VALUE',
  SET_FAILED_PRIVATE_PENSION_PLANS = '@privatePensionPlan/SET_FAILED_PRIVATE_PENSION_PLANS',
  SET_ADDITIONAL_CONTRIBUTION_ID = '@privatePensionPlan/SET_ADDITIONAL_CONTRIBUTION_ID',
  REQUEST_ADDITIONAL_CONTRIBUTION = '@privatePensionPlan/REQUEST_ADDITIONAL_CONTRIBUTION',
}

export type AdditionalContributionPGBLMethodType = 'EXACT_VALUE' | 'SIMULATE_VALUE';

export enum PrivatePensionPlanStatus {
  EMPTY = '',
  ACTIVE = 'ATIVO',
  BLOCKED = 'BLOQUEADO',
  ERROR = 'ERRO',
  CANCELED = 'CANCELADO',
  IN_BENEFIT_GRANT = 'EM_CONCESSAO_DE_BENEFÍCIO',
}

export interface PrivatePensionPlan {
  certificateNumber: string;
  status: PrivatePensionPlanStatus;
  accumulatedValue?: number | null;
  monthlyContribution?: number | null;
  initialContributionDate?: string | null;
  endContributionDate?: string | null;
  numberMonthsInvesting?: number | null;
  privatePensionType?: PrivatePensions;
  taxType?: TaxTypes;
  investmentFund?: InvestmentFund;
  isValidToContribute: boolean;
  privatePensionStatusTitle: string;
  privatePensionStatusMessage: string;
}

export interface PrivatePensionPlanState {
  privatePensionPlan: PrivatePensionPlan;
  failedPrivatePensionPlans?: Array<string>;
  additionalContributionPGBLMethod?: AdditionalContributionPGBLMethodType;
  annualIncomeSimulationValue?: number;
  additionalContribution?: number;
  additionalContributionId: number;
}
