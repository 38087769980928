import { InvestmentFund } from '../../components/InvestmentFundCard/InvestmentFundCard';
import { PrivatePensions, TaxTypes } from '../../enums/privatePensions';

export enum ProposalDetailsTypes {
  SET_PROPOSAL_DETAILS = '@proposal/SET_PROPOSAL_DETAILS',
  GET_SUGGESTED_PROPOSAL_ID = '@proposal/GET_SUGGESTED_PROPOSAL_ID',
  SET_PRIVATE_PENSION_TYPE = '@proposal/SET_PRIVATE_PENSION_TYPE',
  SET_TAX_TYPE = '@proposal/SET_TAX_TYPE',
  SET_INITIAL_CONTRIBUTION = '@proposal/SET_INITIAL_CONTRIBUTION',
  SET_MONTHLY_CONTRIBUTION = '@proposal/SET_MONTHLY_CONTRIBUTION',
  SET_PORTABILITY_VALUE = `@proposal/SET_PORTABILITY_VALUE`,
  SET_NUMBER_MONTHS_INVESTING = '@proposal/SET_NUMBER_MONTHS_INVESTING',
  SET_FIRST_DEBIT_DATE = '@proposal/SET_FIRST_DEBIT_DATE',
  SET_PAYMENT_DAY = '@proposal/SET_PAYMENT_DAY',
  SET_INSURANCE_COVERAGE = '@proposal/SET_INSURANCE_COVERAGE',
  SET_INVESTMENT_FUND = '@proposal/SET_INVESTIMENT_FUND',
  SET_HAS_INSURANCE_COVERAGE = '@proposal/SET_HAS_INSURANCE_COVERAGE',
  REQUEST_BUY_GOOD_PROPOSAL_DETAILS = '@proposal/REQUEST_BUY_GOOD_PROPOSAL_DETAILS',
  SET_INCOME_TAX_COMPLETE = `@proposal/SET_INCOME_TAX_COMPLETE`,
  SET_PGBL_EXPENDITURE = `@proposal/SET_PGBL_EXPENDITURE`,
  SET_INCOME_TAX_SIMPLIFIED = `@proposal/SET_INCOME_TAX_SIMPLIFIED`,
  SET_ANNUAL_CONTRIBUTION = `@proposal/SET_ANNUAL_CONTRIBUTION`,
  SET_IRRF_TABLE = `@proposal/SET_IRRF_TABLE`,
  SET_TYPE = `@proposal/SET_TYPE`,
  REQUEST_PROPOSAL_DETAILS = '@proposal/REQUEST_PROPOSAL_DETAILS',
  REQUEST_INCOME_TAX_PROPOSAL_DETAILS = '@proposal/REQUEST_INCOME_TAX_PROPOSAL_DETAILS',
  REQUEST_DEEP_LINK_PROPOSAL = '@proposal/REQUEST_DEEP_LINK_PROPOSAL',
  REQUEST_PROPOSAL_BY_FUND = '@proposal/REQUEST_PROPOSAL_BY_FUND',
  REQUEST_PORTABILITY_PROPOSAL = '@proposal/REQUEST_PORTABILITY_PROPOSAL',
  RESET = `@proposal/RESET`,
}

export interface ProposalDetailsState {
  readonly suggestedProposalId: number;
  readonly qualifiedCustomer: boolean;
  type: ProposalTypes;
  annualContribution: number;
  privatePensionType: PrivatePensions;
  taxType: TaxTypes;
  initialContribution: number;
  monthlyContribution: number;
  portabilityValue: number;
  numberMonthsInvesting: number;
  firstDebitDate: string;
  paymentDay: number;
  insuranceCoverage: InsuranceCoverage;
  investmentFund: InvestmentFund;
  hasInsuranceCoverage: boolean | undefined;
  incomeTaxComplete?: IncomeTax;
  incomeTaxSimplified?: IncomeTax;
  incomeTax?: IncomeTax[];
  incomeTaxIRRFTableResponse?: IRRFTable[];
}

export enum ProposalTypes {
  RETIREMENT = 'RETIREMENT',
  INCOME_TAX = 'INCOME_TAX',
  DEEPLINK = 'DEEPLINK',
  BUY_GOOD = 'BUY_GOOD',
  BY_FUND = 'BY_FUND',
  PORTABILITY = 'PORTABILITY',
  ADVISOR = 'ADVISOR',
}

export interface InsuranceCoverage {
  readonly coverageValue: number;
  readonly monthlyContributionValue: number;
  readonly gracePeriod: number;
}

export interface IncomeTax {
  readonly incomeTaxType: string;
  readonly taxAlreadyPaid: number;
  readonly simplifiedDiscount?: number;
  readonly socialSecurity?: number;
  readonly dependentsDeduction?: number;
  readonly educationExpenditure?: number;
  readonly healthyExpenditure?: number;
  pgblExpenditure?: number;
  totalDeductions: number;
  taxableBase: number;
  totalTaxPayable: number;
  restitutionValue: number;
}

export interface IRRFTable {
  readonly rangeNumber: number;
  readonly value: number;
  readonly aliquot: number;
  readonly deduction: number;
}
