/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  InterUIBottomSheet,
  InterUIBox,
  InterUIButton,
  InterUIIcon,
} from '@interco/inter-ui-react-lib';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import AdditionalContributionAnalyticsService from '../../analytics/additionalContributionAnalyticsService';
import FlexWithHook from '../../components/FlexWithHook/FlexWithHook';
import { FormWithHook } from '../../components/FormWithHook/FormWithHook';
import InputPricingWithHook from '../../components/InputPricingWithHook/InputPricingWithHook';
import { RadioWithHook } from '../../components/RadioWithHook/RadioWithHook';
import { getMessages, MessagesValidation } from '../../enums/messages';
import { PageRoutes } from '../../enums/pageRoutes';
import PageTitles from '../../enums/pageTitles';
import ErrorActions from '../../store/error/actions';
import { LoadingActions } from '../../store/loading/actions';
import NavbarActions from '../../store/navbar/actions';
import PrivatePensionPlanActions from '../../store/privatePensionPlan/actions';
import { AdditionalContributionPGBLMethodType } from '../../store/privatePensionPlan/types';
import { ThemeInter } from '../../styles';
import { Div, H1, P, PSmallGray, SeparatorDashed } from '../../styles/commons';
import { InterUIAlertCustomized } from '../../styles/inter-ui-customizations';
import { MINIMUM_INCOME_ACCEPTED, MINIMUM_INCOME_TO_DECLARE } from '../../utils/minValues';
import { formatToBRLCurrency } from '../../utils/numberFormatUtils';

interface AdditionalContributionPGBLHelperForm {
  additionalContributionPBGLMethod: AdditionalContributionPGBLMethodType;
  annualIncomeSimulationValue?: number;
}

const ANNUAL_INCOME_INFO = {
  title: 'Por que informar sua renda anual?',
  description: [
    'Para saber sua renda anual tributável, basta somar seus recebimentos de salário no ano, suas férias e 1/3 das férias.',
    'Podem entrar no cálculo também, os aluguéis e pensões recebidas, se houverem.',
    'Se quiser conferir a lista completa de rendimentos tributáveis, acesse o manual da Receita Federal.',
  ],
};

const AdditionalContributionPGBLHelperPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme() as ThemeInter;
  const loading = useSelector(LoadingActions.get);
  const { investmentFund, privatePensionType } = useSelector(
    PrivatePensionPlanActions.getPrivatePensionPlan,
  );
  const [bottomSheet, setBottomSheet] = useState<boolean>(false);
  const defaultIncomeSimulation = useSelector(
    PrivatePensionPlanActions.getAnnualIncomeSimulationValue,
  );
  const defaultAdditionalContributionMethod = useSelector(
    PrivatePensionPlanActions.getAdditionalContributionPGBLMethod,
  );

  const methods = useForm<AdditionalContributionPGBLHelperForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      additionalContributionPBGLMethod: defaultAdditionalContributionMethod || undefined,
      annualIncomeSimulationValue: defaultIncomeSimulation || undefined,
    },
  });

  const {
    getValues,
    setValue,
    formState: { isValid, isDirty },
  } = methods;

  useEffect(() => {
    dispatch(NavbarActions.setTitle(PageTitles.ADDITIONAL_CONTRIBUTION));
    dispatch(ErrorActions.resetState());
  }, [dispatch]);

  useEffect(() => {
    AdditionalContributionAnalyticsService.pgblFlowAccess(
      investmentFund?.name || '',
      privatePensionType || '',
    );
  }, []);

  useEffect(() => {
    const additionalContributionPBGLMethod = getValues('additionalContributionPBGLMethod');

    if (additionalContributionPBGLMethod === 'EXACT_VALUE') {
      setValue('annualIncomeSimulationValue', undefined);
    }
  }, [getValues('additionalContributionPBGLMethod'), isValid]);

  const showAnnualIncomeForm = useMemo<boolean>(
    () => getValues('additionalContributionPBGLMethod') === 'SIMULATE_VALUE',
    [getValues('additionalContributionPBGLMethod')],
  );

  const isTheInformationalNecessary = useMemo<boolean>(() => {
    const annualIncome = getValues('annualIncomeSimulationValue');
    const additionalContributionPBGLMethod = getValues('additionalContributionPBGLMethod');

    if (
      additionalContributionPBGLMethod === 'SIMULATE_VALUE' &&
      annualIncome! >= MINIMUM_INCOME_ACCEPTED &&
      annualIncome! <= MINIMUM_INCOME_TO_DECLARE
    ) {
      return true;
    }
    return false;
  }, [getValues('annualIncomeSimulationValue'), getValues('additionalContributionPBGLMethod')]);

  const isDisableButton = useMemo(() => {
    const wayOfInvest = getValues('additionalContributionPBGLMethod');
    const annualIncomeValue = getValues('annualIncomeSimulationValue');

    if (wayOfInvest && isValid) {
      if (wayOfInvest === 'SIMULATE_VALUE' && annualIncomeValue === undefined) {
        return true;
      }
      return false;
    }
    return true;
  }, [
    getValues('additionalContributionPBGLMethod'),
    getValues('annualIncomeSimulationValue'),
    isValid,
    isDirty,
  ]);

  const stickyFooter: React.ReactElement = (
    <InterUIButton margin="0 24px 24px" type="submit" disabled={isDisableButton} loading={loading}>
      Continuar
    </InterUIButton>
  );

  const onSubmit = (data: AdditionalContributionPGBLHelperForm) => {
    const { additionalContributionPBGLMethod, annualIncomeSimulationValue } = data;

    if (additionalContributionPBGLMethod === 'SIMULATE_VALUE') {
      AdditionalContributionAnalyticsService.pgblHelperSimulateSubmitButton(
        investmentFund?.name || '',
        privatePensionType || '',
      );
    }

    dispatch(
      PrivatePensionPlanActions.setAdditionalContributionPGBLMethod(
        additionalContributionPBGLMethod,
      ),
    );
    dispatch(PrivatePensionPlanActions.setAnnualIncomeSimulationValue(annualIncomeSimulationValue));

    history.push(PageRoutes.ADDITIONAL_CONTRIBUTION_INVESTMENT_FORM);
  };

  return (
    <FormWithHook stickyFooter={stickyFooter} onSubmit={onSubmit} methods={methods}>
      <H1 marginBottom="8px">Seu jeito de investir</H1>
      <PSmallGray marginBottom="24px">
        Escolha como você quer investir no seu fundo de previdência privada.
      </PSmallGray>
      <RadioWithHook
        variant="choose-item"
        type="text"
        label="Já sei quanto investir"
        id="EXACT_VALUE"
        name="additionalContributionPBGLMethod"
        value="EXACT_VALUE"
      />
      <RadioWithHook
        variant="choose-item"
        type="text"
        label="Quero simular os melhores valores para meu IR"
        id="SIMULATE_VALUE"
        name="additionalContributionPBGLMethod"
        value="SIMULATE_VALUE"
      />
      <FlexWithHook>
        {showAnnualIncomeForm && (
          <>
            <SeparatorDashed
              marginBottom="24px"
              marginTop="12px"
              color={theme.colors.grayscale.A200}
            />
            <Div position="relative">
              <Div position="absolute" top="0" right="0" zIndex={3}>
                <button
                  type="button"
                  onClick={() => setBottomSheet(true)}
                  aria-label="Sua renda bruta anual tributável"
                >
                  <InterUIIcon name="help" color={theme.colors.primary.A500} size="16px" />
                </button>
              </Div>
            </Div>
            <P fontFamily="Sora">Sua renda anual tributável</P>
            <PSmallGray>informe sua renda anual tributável no Imposto de Renda (IR).</PSmallGray>
          </>
        )}
        {showAnnualIncomeForm && (
          <InputPricingWithHook
            name="annualIncomeSimulationValue"
            placeholder="0,00"
            prefix="R$"
            maxLength={14}
            margin="0"
            validation={{
              min: {
                value: MINIMUM_INCOME_ACCEPTED,
                message: getMessages(MessagesValidation.MINIMUM_VALUE_VALIDATION, [
                  formatToBRLCurrency(MINIMUM_INCOME_ACCEPTED),
                ]),
              },
            }}
            shouldDirty
          />
        )}
      </FlexWithHook>
      <Div>
        {isTheInformationalNecessary && (
          <InterUIBox padding="0px" margin="0 0 16px">
            <InterUIAlertCustomized
              color={theme.colors.white}
              icon={<InterUIIcon name="contextual-info" color={theme.colors.alert.A400} />}
              alignItems="center"
            >
              <PSmallGray marginBottom="0">
                Lembre-se que para economizar no IR com a previdência privada, você precisa fazer a
                sua declaração do tipo completa.
              </PSmallGray>
            </InterUIAlertCustomized>
          </InterUIBox>
        )}
      </Div>
      <InterUIBottomSheet
        title={ANNUAL_INCOME_INFO.title}
        toggle={bottomSheet}
        onHide={() => setBottomSheet(false)}
      >
        {ANNUAL_INCOME_INFO.description.map((paragraph, index) => (
          <PSmallGray key={`paragraph-${index}`}>{paragraph}</PSmallGray>
        ))}
        <InterUIButton
          type="button"
          onClick={() => setBottomSheet(false)}
          margin="32px 0px 0px 0px"
        >
          Entendi
        </InterUIButton>
      </InterUIBottomSheet>
    </FormWithHook>
  );
};

export default AdditionalContributionPGBLHelperPage;
