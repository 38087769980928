/**
 * Cores disponíveis na aplicação.
 */
const Colors = {
  white: '#FFFFFF',
  interBlack: '#161616',
  interBlackDisabled: '#B6B7BB',
  interOrange: '#FF7A00',
  orange300: '#FFB46E',
  paleOrange: '#FFF2E7',
  shuttleGray: '#6A6C72',
  shuttleGrayDisabled: '#DEDFE4',
  cornflowerBlue: '#6195ED',
  ghostWhite: '#F5F6FA',
  vividBlue: '#43B0F0',
  infoBlue: '#40B9EA',
  pigmentGreen: '#00AA4F',
  tangerineYellow: '#FFC700',
  bitterSweet: '#F56A50',
  fireEngineRed: '#C5232A',
  Gray600: '#8C91A9',
  Gray700: '#6A6E81',
  Gray800: '#4B4E5C',
};

export default Colors;
