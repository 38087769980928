import { InterUIButton, InterUICheckBox, InterUIContainer } from '@interco/inter-ui-react-lib';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import PortabilityAnalyticsService from '../../analytics/portabilityAnalyticsService';
import FontSizeREM from '../../enums/fontSizesRem';
import { PageRoutes } from '../../enums/pageRoutes';
import PageTitles from '../../enums/pageTitles';
import { HireProposalActions } from '../../store/hire/actions';
import { LoadingActions } from '../../store/loading/actions';
import NavbarActions from '../../store/navbar/actions';
import PortabilityActions from '../../store/portability/actions';
import { ProposalDetailActions } from '../../store/proposal/actions';
import TermsAndConditionsActions from '../../store/termAndCondition/actions';
import { ThemeInter } from '../../styles';
import Colors from '../../styles/colors';
import { Flex, P, PSmall, Separator, SeparatorDashed, Span } from '../../styles/commons';
import {
  convertDateToStringPtBR,
  formatMonthsToYearsAndMonths,
  getDateFromNumberOfMonths,
  getMonthsInvestingText,
} from '../../utils/dateTimeUtils';
import { formatToBRLCurrency } from '../../utils/numberFormatUtils';

interface IRevisionItem {
  label: string;
  value?: string;
  textTransform?: string;
}

const PortabilityRevisionPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(LoadingActions.get);
  const theme = useTheme() as ThemeInter;
  const isTermsAndConditionsAccepted = useSelector(
    TermsAndConditionsActions.getIsTermsAndConditionsAccepted,
  );
  const { numberMonthsInvesting, investmentFund } = useSelector(ProposalDetailActions.get);
  const { financialInstitution, externalPensionPlan } = useSelector(PortabilityActions.get);

  const text = {
    message: 'Revise os dados sobre a portabilidade para confirmar.',
    externalPensionLabel: 'Plano de origem',
    pensionPlanLabel: 'Plano de destino',
  };

  const goToPreviewContractPage = () => {
    history.push(PageRoutes.PREVIEW_CONTRACT);
  };

  const goToContractionReceiptPage = () => {
    if (externalPensionPlan && investmentFund) {
      PortabilityAnalyticsService.revisionConfirmButton(
        externalPensionPlan.planType,
        investmentFund.name,
      );
    }
    dispatch(
      HireProposalActions.requestHirePortabilityProposal({
        history,
        pathname: PageRoutes.PORTABILITY_CONTRACTION_RECEIPT,
      }),
    );
  };

  const RevisionItem: React.FC<IRevisionItem> = ({ label, value, textTransform }) => (
    <Flex flexDirection="row" justifyContent="space-between" width="100%" alignItems="center">
      <Flex margin="0 0 17px">
        <Span
          fontSize={FontSizeREM.PX12}
          lineHeight={FontSizeREM.PX15}
          fontWeight={400}
          color={theme.colors.grayscale.A400}
        >
          {label}
        </Span>
      </Flex>

      <Flex margin="0 0 17px">
        <Span
          fontSize={FontSizeREM.PX12}
          lineHeight={FontSizeREM.PX15}
          fontWeight={700}
          color={theme.colors.grayscale.A500}
          textTransform={textTransform}
        >
          {value}
        </Span>
      </Flex>
    </Flex>
  );

  const RevisionFooter: React.FC = () => (
    <Flex flexDirection="row" alignItems="center" justifyContent="space-between" margin="17px 0">
      <P
        fontSize={FontSizeREM.PX12}
        lineHeight={FontSizeREM.PX14}
        marginBottom="0px"
        fontWeight={400}
      >
        Li e concordo com as informações descritas nos{' '}
        <button type="button" onClick={goToPreviewContractPage}>
          <Span
            fontSize={FontSizeREM.PX12}
            lineHeight={FontSizeREM.PX15}
            fontWeight={700}
            color={Colors.interOrange}
          >
            Termos e Condições de Portabilidade.
          </Span>
        </button>
      </P>
      <InterUICheckBox
        variant="default"
        defaultChecked={isTermsAndConditionsAccepted}
        height="22px"
        width="22px"
        onClick={() =>
          dispatch(
            TermsAndConditionsActions.setIsTermsAndConditionsAccepted(
              !isTermsAndConditionsAccepted,
            ),
          )
        }
      />
    </Flex>
  );

  const stickyFooter: React.ReactElement = (
    <>
      <Separator color={theme.colors.grayscale.A100} height="1px" style={{ margin: '0 -24px' }} />
      <RevisionFooter />
      <InterUIButton
        disabled={!isTermsAndConditionsAccepted}
        onClick={goToContractionReceiptPage}
        loading={loading}
        data-testid="go-to-contraction-receipt-button"
      >
        Confirmar
      </InterUIButton>
    </>
  );
  useEffect(
    () => {
      dispatch(NavbarActions.setTitle(PageTitles.PORTABILITY_REVISION));
    }, // Stryker disable next-line all
    [dispatch],
  );
  return (
    <InterUIContainer stickyFooter={stickyFooter} margin="18px 24px 24px">
      <PSmall color={theme.colors.grayscale.A400} margin="0 0 16px">
        {text.message}
      </PSmall>

      <P
        fontSize={FontSizeREM.PX12}
        lineHeight={FontSizeREM.PX15}
        fontWeight={700}
        color={theme.colors.grayscale.A500}
        margin="0 0 17px"
      >
        {text.externalPensionLabel}
      </P>
      <RevisionItem label="Instituição de origem" value={financialInstitution?.name} />
      <RevisionItem
        label="Valor acumulado"
        value={
          externalPensionPlan?.accumulatedValues
            ? formatToBRLCurrency(externalPensionPlan.accumulatedValues)
            : formatToBRLCurrency(0)
        }
      />
      <RevisionItem label="Certificado" value={externalPensionPlan?.certificateNumber} />
      <RevisionItem label="Processo Susep" value={externalPensionPlan?.susep} />
      <RevisionItem
        label="Regime tributário"
        textTransform="capitalize"
        value={externalPensionPlan?.taxType.toLocaleLowerCase()}
      />
      <RevisionItem label="Modalidade" value={externalPensionPlan?.planType} />

      <SeparatorDashed marginTop="8px" marginBottom="24px" color={theme.colors.grayscale.A200} />

      <P
        fontSize={FontSizeREM.PX12}
        lineHeight={FontSizeREM.PX15}
        fontWeight={700}
        color={theme.colors.grayscale.A500}
        margin="0 0 17px"
      >
        {text.pensionPlanLabel}
      </P>
      <RevisionItem label="Fundo escolhido" value={investmentFund.name} />
      <RevisionItem label="CNPJ do fundo" value={investmentFund.cnpj} />
      <RevisionItem
        label="Prazo de aposentadoria"
        value={getMonthsInvestingText(formatMonthsToYearsAndMonths(numberMonthsInvesting))}
      />

      <RevisionItem
        label="Data de aposentadoria"
        value={convertDateToStringPtBR(getDateFromNumberOfMonths(numberMonthsInvesting))}
      />
    </InterUIContainer>
  );
};

export default PortabilityRevisionPage;
