import { AnyAction } from 'redux';
import { action } from 'typesafe-actions';
import { RootState } from '..';
import { SnackbarState, SnackbarTypes } from './types';

const get = (state: RootState): SnackbarState => state.snackbar;

const show = (): AnyAction => action(SnackbarTypes.SHOW);

const hide = (): AnyAction => action(SnackbarTypes.HIDE);

const setMessage = (message: string): AnyAction => action(SnackbarTypes.SET_MESSAGE, message);

const setDuration = (duration: number): AnyAction => action(SnackbarTypes.SET_DURATION, duration);

export const SnackbarActions = {
  get,
  show,
  hide,
  setMessage,
  setDuration,
};
