export enum PrivatePensions {
  VGBL = 'VGBL',
  PGBL = 'PGBL',
  EMPTY = '',
}

export enum TaxTypes {
  REGRESSIVE = 'REGRESSIVO',
  PROGRESSIVE = 'PROGRESSIVO',
  EMPTY = '',
}

export enum HireProposalStatus {
  ACEITE_PENDENTE = 'ACEITE_PENDENTE',
  CANCELADA = 'CANCELADA',
  EMPTY = '',
}
