/* eslint-disable react-hooks/exhaustive-deps */
import {
  InterUIAlert,
  InterUIBottomSheet,
  InterUIBox,
  InterUIButton,
  InterUIIcon,
  InterUISegmentControl,
} from '@interco/inter-ui-react-lib';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import ProposalAnalyticsService from '../../../../analytics/proposalAnalyticsService';
import FontSizeREM from '../../../../enums/fontSizesRem';
import {
  useNewPGBL,
  useNewRestitutionValue,
  useNewTaxableBase,
  useNewTotalDeductions,
} from '../../../../hooks';
import { useNewTotalTaxPayable } from '../../../../hooks/useNewTotalTaxPayable';
import { IncomeExpenseActions } from '../../../../store/incomeExpenses/actions';
import { ProposalDetailActions } from '../../../../store/proposal/actions';
import { ThemeInter } from '../../../../styles';
import Colors from '../../../../styles/colors';
import { Div, Flex, P, PSmallGray, SeparatorDashed } from '../../../../styles/commons';
import { InterUIAlertCustomized } from '../../../../styles/inter-ui-customizations';
import { formatToBRLCurrency, formatToPercent } from '../../../../utils/numberFormatUtils';
import { IBestOption } from '../../Proposal';

export type TableLabel = 'Completa' | 'Simplificada';

enum IncomeTaxType {
  COMPLETO = 'COMPLETO',
  SIMPLIFICADO = 'SIMPLIFICADO',
}

type BottomSheetInfoType = 'SOCIAL_SECURITY_INFO' | 'IRRF_INFO';
interface IBottomSheetState {
  show: boolean;
  title: string;
  description: string[];
}

interface IDeclarationTableProps {
  bestOption: IBestOption;
}

const SOCIAL_SECURITY_INFO = (currentYear: string) => ({
  title: 'INSS',
  description: [
    `O cálculo do INSS nesta simulação segue a alíquota progressiva vigente no ano de ${currentYear}, que é descontada diretamente da folha de pagamento.`,
    'Caso você não seja um funcionário CLT, os valores podem ser diferentes.',
  ],
});

const IRRF_INFO = (currentYear: string) => ({
  title: 'Imposto Retido na Fonte',
  description: [
    `O cálculo do imposto de renda retido na fonte (IRRF) nesta simulação segue a alíquota progressiva vigente no ano de ${currentYear}, que é descontada diretamente da folha de pagamento.`,
    'Caso você não seja um funcionário CLT, os valores podem ser diferentes.',
  ],
});

const DeclarationTable: React.FC<IDeclarationTableProps> = ({ bestOption }) => {
  const theme = useTheme() as ThemeInter;
  const dispatch = useDispatch();
  const annualContribution = useSelector(ProposalDetailActions.getAnnualContribution);
  const incomeTaxComplete = useSelector(ProposalDetailActions.getIncomeTaxComplete);
  const incomeTaxSimplified = useSelector(ProposalDetailActions.getIncomeTaxSimplified);
  const { annualIncome } = useSelector(IncomeExpenseActions.get);
  const currentYear = String(new Date().getFullYear());
  const yearOfExercise = String(new Date().getFullYear() + 1);
  const newPGBL = useNewPGBL(
    incomeTaxComplete?.pgblExpenditure || 0,
    annualContribution,
    annualIncome,
  );

  const newTotalDeductions = useNewTotalDeductions(
    incomeTaxComplete?.totalDeductions || 0,
    incomeTaxComplete?.pgblExpenditure || 0,
    incomeTaxComplete?.socialSecurity || 0,
    incomeTaxComplete?.educationExpenditure || 0,
    incomeTaxComplete?.dependentsDeduction || 0,
    incomeTaxComplete?.healthyExpenditure || 0,
  );

  const newTaxableBase = useNewTaxableBase(
    incomeTaxComplete?.taxableBase || 0,
    annualIncome,
    incomeTaxComplete?.totalDeductions || 0,
  );

  const newTotalTaxPayable = useNewTotalTaxPayable(
    incomeTaxComplete?.totalTaxPayable || 0,
    incomeTaxComplete?.taxableBase || 0,
  );

  const newRestitutionValue = useNewRestitutionValue(
    incomeTaxComplete?.restitutionValue || 0,
    incomeTaxComplete?.taxAlreadyPaid || 0,
    incomeTaxComplete?.totalTaxPayable || 0,
  );

  const [bottomSheet, setBottomSheet] = useState<IBottomSheetState>({
    show: false,
    title: '',
    description: [''],
  });

  const calcPercentOfAnnualIncome = (value: number) =>
    formatToPercent(parseInt(`${(value * 100) / annualIncome}`), 0, 0);

  const callBottomSheetInfo = (type: BottomSheetInfoType) => {
    if (type === 'SOCIAL_SECURITY_INFO') {
      setBottomSheet({ show: true, ...SOCIAL_SECURITY_INFO(currentYear) });
    } else {
      setBottomSheet({ show: true, ...IRRF_INFO(currentYear) });
    }
  };

  const isBestOption = (incomeTaxType?: string) => {
    switch (incomeTaxType) {
      case IncomeTaxType.COMPLETO:
        return bestOption?.tableLabel === 'Completa';
      case IncomeTaxType.SIMPLIFICADO:
        return bestOption?.tableLabel === 'Simplificada';
      default:
        return false;
    }
  };

  useEffect(() => {
    if (incomeTaxComplete) {
      dispatch(
        ProposalDetailActions.setIncomeTaxComplete({
          ...incomeTaxComplete,
          pgblExpenditure: newPGBL,
          totalDeductions: newTotalDeductions,
          taxableBase: newTaxableBase,
          totalTaxPayable: newTotalTaxPayable,
          restitutionValue: newRestitutionValue,
        }),
      );
    }
  }, [newPGBL, newTotalDeductions, newTaxableBase, newTotalTaxPayable, newRestitutionValue]);

  return (
    <>
      <P lineHeight={FontSizeREM.PX20} marginBottom="8px" fontFamily="Sora">
        Seu tipo de declaração
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        color={theme.colors.grayscale.A400}
        fontWeight={400}
        marginBottom="20px"
      >
        Para economizar na declaração do seu IR em {yearOfExercise}, o ideal é que você faça a
        declaração <strong>{bestOption?.tableLabel}</strong> com a modalidade{' '}
        <strong>{bestOption?.privatePension}</strong>. Aqui, te mostramos um comparativo da sua
        economia.
      </P>
      <InterUISegmentControl selectedLabel={bestOption?.tableLabel}>
        {Array.of(incomeTaxComplete, incomeTaxSimplified).map((incomeTax, index) => (
          <Div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            data-label={incomeTax?.incomeTaxType === 'COMPLETO' ? 'Completa' : 'Simplificada'}
            data-testid={incomeTax?.incomeTaxType === 'COMPLETO' ? 'Completa' : 'Simplificada'}
            margin="16px 0 0 0"
          >
            {bestOption && isBestOption(incomeTax?.incomeTaxType) && (
              <InterUIBox padding="0px" margin="0 0 16px" justify="flex-start">
                <InterUIAlertCustomized
                  style={{ padding: '0' }}
                  color={theme.colors.white}
                  icon={
                    <InterUIIcon
                      name="contextual-info"
                      color={Colors.infoBlue}
                      size="20px"
                      style={{ margin: '0 5px 0 18px' }}
                    />
                  }
                  alignItems="center"
                >
                  <P
                    fontWeight={600}
                    fontSize={FontSizeREM.PX14}
                    lineHeight={FontSizeREM.PX20}
                    marginBottom="0"
                    color={Colors.Gray700}
                  >
                    Melhor opção para você
                  </P>
                  <PSmallGray fontSize={FontSizeREM.PX12} marginBottom="0">
                    Modalidade {bestOption.privatePension} + Declaração de IR{' '}
                    {bestOption.tableLabel}
                  </PSmallGray>
                </InterUIAlertCustomized>
              </InterUIBox>
            )}
            <Flex flexDirection="row" justifyContent="space-between" marginBottom="8px">
              <P
                fontSize={FontSizeREM.PX14}
                fontWeight={400}
                lineHeight={FontSizeREM.PX14}
                marginBottom="4px"
                color={theme.colors.grayscale.A400}
              >
                Desconto simplificado
              </P>
              <P
                fontSize={FontSizeREM.PX14}
                fontWeight={700}
                lineHeight={FontSizeREM.PX14}
                marginBottom="0px"
              >
                {incomeTax?.simplifiedDiscount
                  ? formatToBRLCurrency(incomeTax.simplifiedDiscount)
                  : 'Não se aplica'}
              </P>
            </Flex>
            <Flex flexDirection="row" justifyContent="space-between" marginBottom="8px">
              <P
                fontSize={FontSizeREM.PX14}
                fontWeight={400}
                lineHeight={FontSizeREM.PX14}
                marginBottom="4px"
                color={theme.colors.grayscale.A400}
              >
                INSS
                <button
                  type="button"
                  style={{ verticalAlign: 'middle', marginLeft: '6px' }}
                  onClick={() => callBottomSheetInfo('SOCIAL_SECURITY_INFO')}
                  aria-label="O que é deduzido do INSS?"
                >
                  <InterUIIcon name="help" color={theme.colors.primary.A500} size="16px" />
                </button>
              </P>
              <P
                fontSize={FontSizeREM.PX14}
                fontWeight={700}
                lineHeight={FontSizeREM.PX14}
                marginBottom="0px"
              >
                {incomeTax &&
                incomeTax.socialSecurity !== null &&
                incomeTax.socialSecurity !== undefined
                  ? formatToBRLCurrency(incomeTax.socialSecurity)
                  : 'Não se aplica'}
              </P>
            </Flex>
            <Flex flexDirection="row" justifyContent="space-between" marginBottom="8px">
              <P
                fontSize={FontSizeREM.PX14}
                fontWeight={400}
                lineHeight={FontSizeREM.PX14}
                marginBottom="4px"
                color={theme.colors.grayscale.A400}
              >
                Dependentes
              </P>
              <P
                fontSize={FontSizeREM.PX14}
                fontWeight={700}
                lineHeight={FontSizeREM.PX14}
                marginBottom="0px"
              >
                {incomeTax &&
                incomeTax.dependentsDeduction !== null &&
                incomeTax.dependentsDeduction !== undefined
                  ? formatToBRLCurrency(incomeTax.dependentsDeduction)
                  : 'Não se aplica'}
              </P>
            </Flex>
            <Flex flexDirection="row" justifyContent="space-between" marginBottom="8px">
              <P
                fontSize={FontSizeREM.PX14}
                fontWeight={400}
                lineHeight={FontSizeREM.PX14}
                marginBottom="4px"
                color={theme.colors.grayscale.A400}
              >
                Educação
              </P>
              <P
                fontSize={FontSizeREM.PX14}
                fontWeight={700}
                lineHeight={FontSizeREM.PX14}
                marginBottom="0px"
              >
                {incomeTax &&
                incomeTax.educationExpenditure !== null &&
                incomeTax.educationExpenditure !== undefined
                  ? formatToBRLCurrency(incomeTax.educationExpenditure)
                  : 'Não se aplica'}
              </P>
            </Flex>
            <Flex flexDirection="row" justifyContent="space-between" marginBottom="8px">
              <P
                fontSize={FontSizeREM.PX14}
                fontWeight={400}
                lineHeight={FontSizeREM.PX14}
                marginBottom="4px"
                color={theme.colors.grayscale.A400}
              >
                Saúde
              </P>
              <P
                fontSize={FontSizeREM.PX14}
                fontWeight={700}
                lineHeight={FontSizeREM.PX14}
                marginBottom="0px"
              >
                {incomeTax &&
                incomeTax.healthyExpenditure !== null &&
                incomeTax.healthyExpenditure !== undefined
                  ? formatToBRLCurrency(incomeTax.healthyExpenditure)
                  : 'Não se aplica'}
              </P>
            </Flex>
            <Flex flexDirection="row" justifyContent="space-between" marginBottom="8px">
              <P
                fontSize={FontSizeREM.PX14}
                fontWeight={400}
                lineHeight={FontSizeREM.PX14}
                marginBottom="4px"
                color={theme.colors.grayscale.A400}
              >
                PGBL
              </P>
              <P
                fontSize={FontSizeREM.PX14}
                fontWeight={700}
                lineHeight={FontSizeREM.PX14}
                marginBottom="0px"
              >
                {incomeTax?.pgblExpenditure
                  ? formatToBRLCurrency(incomeTax.pgblExpenditure)
                  : 'Não se aplica'}
              </P>
            </Flex>
            <SeparatorDashed
              marginTop="24px"
              marginBottom="24px"
              color={theme.colors.grayscale.A200}
            />
            <Flex flexDirection="row" justifyContent="space-between" marginBottom="8px">
              <P
                fontSize={FontSizeREM.PX14}
                fontWeight={700}
                lineHeight={FontSizeREM.PX14}
                marginBottom="4px"
              >
                Dedução total (desconto)
              </P>
              <P
                fontSize={FontSizeREM.PX14}
                fontWeight={700}
                lineHeight={FontSizeREM.PX14}
                marginBottom="0px"
              >
                {incomeTax && formatToBRLCurrency(incomeTax.totalDeductions)} (
                {incomeTax && calcPercentOfAnnualIncome(incomeTax.totalDeductions)})
              </P>
            </Flex>
            <Flex flexDirection="row" justifyContent="space-between" marginBottom="8px">
              <P
                fontSize={FontSizeREM.PX14}
                fontWeight={400}
                lineHeight={FontSizeREM.PX14}
                marginBottom="4px"
                color={theme.colors.grayscale.A400}
              >
                Base Tributável
              </P>
              <P
                fontSize={FontSizeREM.PX14}
                fontWeight={700}
                lineHeight={FontSizeREM.PX14}
                marginBottom="0px"
              >
                {incomeTax && formatToBRLCurrency(incomeTax.taxableBase)}
              </P>
            </Flex>
            <Flex flexDirection="row" justifyContent="space-between" marginBottom="8px">
              <P
                fontSize={FontSizeREM.PX14}
                fontWeight={400}
                lineHeight={FontSizeREM.PX14}
                marginBottom="4px"
                color={theme.colors.grayscale.A400}
              >
                Imposto Retido na Fonte
                <button
                  type="button"
                  style={{ verticalAlign: 'middle', marginLeft: '6px' }}
                  onClick={() => callBottomSheetInfo('IRRF_INFO')}
                  aria-label="O que é o Imposto Retido na Fonte?"
                >
                  <InterUIIcon name="help" color={theme.colors.primary.A500} size="16px" />
                </button>
              </P>
              <P
                fontSize={FontSizeREM.PX14}
                fontWeight={700}
                lineHeight={FontSizeREM.PX14}
                marginBottom="0px"
              >
                {incomeTax && formatToBRLCurrency(incomeTax.taxAlreadyPaid)}
              </P>
            </Flex>
            <Flex flexDirection="row" justifyContent="space-between" marginBottom="8px">
              <P
                fontSize={FontSizeREM.PX14}
                fontWeight={400}
                lineHeight={FontSizeREM.PX14}
                marginBottom="4px"
                color={theme.colors.grayscale.A400}
              >
                Imposto a pagar
              </P>
              <P
                fontSize={FontSizeREM.PX14}
                fontWeight={700}
                lineHeight={FontSizeREM.PX14}
                marginBottom="0px"
              >
                {incomeTax && formatToBRLCurrency(incomeTax.totalTaxPayable)}
              </P>
            </Flex>
            <SeparatorDashed
              marginTop="24px"
              marginBottom="24px"
              color={theme.colors.grayscale.A200}
            />
            <Flex flexDirection="row" justifyContent="space-between" marginBottom="8px">
              <P
                fontSize={FontSizeREM.PX14}
                fontWeight={700}
                lineHeight={FontSizeREM.PX14}
                marginBottom="4px"
                color={
                  incomeTax && incomeTax.restitutionValue >= 0
                    ? theme.colors.success.A500
                    : theme.colors.error.A500
                }
              >
                {incomeTax && incomeTax.restitutionValue >= 0 ? 'Restituição' : 'Valor devido'}
              </P>
              <P
                fontSize={FontSizeREM.PX14}
                fontWeight={700}
                lineHeight={FontSizeREM.PX14}
                marginBottom="0px"
                color={
                  incomeTax && incomeTax.restitutionValue >= 0
                    ? theme.colors.success.A500
                    : theme.colors.error.A500
                }
              >
                {incomeTax && formatToBRLCurrency(incomeTax?.restitutionValue)}
              </P>
            </Flex>
            <InterUIAlert>
              <P
                fontWeight={700}
                fontSize={FontSizeREM.PX12}
                lineHeight={FontSizeREM.PX15}
                marginBottom="4px"
              >
                Essa é uma simulação
              </P>
              <P fontWeight={400} fontSize={FontSizeREM.PX12} lineHeight={FontSizeREM.PX15}>
                Os valores reais podem variar de acordo com seu regime empregatício, ganhos não
                previstos e alterações nas tabelas do IR.
              </P>
            </InterUIAlert>
          </Div>
        ))}
      </InterUISegmentControl>
      <InterUIBottomSheet
        title={bottomSheet.title}
        toggle={bottomSheet.show}
        onHide={() => setBottomSheet({ ...bottomSheet, show: false })}
      >
        {bottomSheet.description.map((paragraph, index) => {
          const key = `paragraph-${index}`;
          return <PSmallGray key={key}>{paragraph}</PSmallGray>;
        })}
        <InterUIButton
          type="button"
          onClick={() => {
            if (bottomSheet.title === 'INSS') {
              ProposalAnalyticsService.inssBottomSheetClick();
            } else {
              ProposalAnalyticsService.irrfBottomSheetClick();
            }
            setBottomSheet({ ...bottomSheet, show: false });
          }}
          margin="32px 0px 0px 0px"
        >
          Entendi
        </InterUIButton>
      </InterUIBottomSheet>
    </>
  );
};

export default DeclarationTable;
