import styled, { css } from 'styled-components';
import Colors from '../../../../styles/colors';

export const Header = styled.div`
  padding: 16px;
`;

const Shadow = css`
  box-shadow: 0px 2px 6px rgb(0 0 0 / 20%);
`;

export const Content = styled.div<{ backgroundColor: string }>`
  ${(porps) => porps.backgroundColor && `background: ${porps.backgroundColor}`};
  padding: 16px;
  border-radius: 0px 0px 8px 8px;
`;

export const OutlineCard = styled.div<{
  selected?: boolean;
}>`
  border: 1px solid ${(props) => (props.selected ? Colors.orange300 : Colors.ghostWhite)};
  box-sizing: border-box;
  border-radius: 8px;
  margin: 8px 0 8px 0;
  ${(props) => props.selected && Shadow}
`;
