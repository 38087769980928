/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { AppInfoService } from './appInfoService';
import { BaseService, ServiceResponse } from './baseService';
import { MockService } from './mockService';

export interface HirePortabilityProposalBody {
  investmentFundCode: string;
  originPrivatePensionId: number;
  portabilityId: number;
  contributionMonthly: number;
}

interface HirePortabilityProposalHeader {
  authenticationToken: string;
  authenticationType: string;
  authenticationValue: string;
  code: string;
  transactionType: string;
}

interface HirePortabilityProposalResponseBody {
  chosenProposalId: number;
}

const {
  REACT_APP_API_PENSION_PORTABILITY_V1,
  REACT_APP_API_BRIDGE_PENSION_PORTABILITY_V1,
} = process.env;

class HirePortabilityProposalService {
  static async getHireProposalReceipt(
    header: HirePortabilityProposalHeader,
    body: HirePortabilityProposalBody,
  ): Promise<ServiceResponse<HirePortabilityProposalResponseBody>> {
    return BaseService.doExecute({
      endpoint: {
        browser: `${REACT_APP_API_PENSION_PORTABILITY_V1}/portabilidade-automatica/aplicar/interno`,
        bridge: `${REACT_APP_API_BRIDGE_PENSION_PORTABILITY_V1}/portabilidade-automatica/aplicar`,
      },
      method: 'POST',
      headers: {
        browser: {
          'x-inter-ip': '127.1.1.1',
          'x-inter-isafe-token': header.authenticationToken,
          'x-inter-isafe-value': header.authenticationValue,
          'x-inter-isafe-authetication-type': `${header.authenticationType}`, // Force string to prevent error
          'x-inter-isafe-transaction-type': header.transactionType,
          'mag-identifier': 'magidentifier',
          'x-inter-mac-address': `00:00:00:00:00:00`,
          'x-inter-app-origem': 'Webview Previdência',
          'x-inter-app-version': AppInfoService.superAppVersion,
          'x-inter-so': AppInfoService.deviceName,
          'x-inter-conta-corrente': MockService.account,
          ...MockService.matchHeadersByUserAccount,
        },
        bridge: {
          'x-inter-isafe-token': header.authenticationToken,
          'x-inter-isafe-value': header.authenticationValue,
          'x-inter-isafe-authetication-type': `${header.authenticationType}`, // Force string to prevent error
          'x-inter-isafe-transaction-type': header.transactionType,
        },
      },
      data: body,
    });
  }
}

export default HirePortabilityProposalService;
