import React from 'react';
import { PSmallGray } from '../../styles/commons';
import { formatToBRLCurrency } from '../../utils/numberFormatUtils';

interface InfoInitialMonthlyContributionProps {
  strategy: string;
  initialContribution: number;
  monthlyContribution: number;
}

const InfoInitialMonthlyContribution: React.FC<InfoInitialMonthlyContributionProps> = ({
  strategy,
  initialContribution,
  monthlyContribution,
}: InfoInitialMonthlyContributionProps) => {
  if (strategy === 'INITIAL_MONTHLY_CONTRIBUTION') {
    return (
      <PSmallGray data-testid="info-initial-monthly-contribution-text" marginBottom="16px">
        Para garantir a contratação da sua previdência, mantenha em conta o valor total do aporte
        inicial + aporte mensal ({formatToBRLCurrency(monthlyContribution + initialContribution)})
        na data do primeiro débito.
      </PSmallGray>
    );
  }

  return <> </>;
};

export default InfoInitialMonthlyContribution;
