/* eslint-disable no-useless-return */
import { InterUIIcon } from '@interco/inter-ui-react-lib';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import HomeAnalyticsService from '../../analytics/homeAnalyticsService';
import ObjectivesHomeImg from '../../assets/objectivesHome/ObjectivesHome.png';
import FontSizeREM from '../../enums/fontSizesRem';
import { PageRoutes } from '../../enums/pageRoutes';
import PageTitles from '../../enums/pageTitles';
import ErrorActions from '../../store/error/actions';
import NavbarActions from '../../store/navbar/actions';
import { ThemeInter } from '../../styles';
import { Div, Flex, P, PSmallGray, Separator, SeparatorDashed } from '../../styles/commons';
import { InterContainerNoFooter } from '../../styles/inter-ui-customizations';
import { getFirstDebitDate } from '../../utils/dateTimeUtils';

const ObjectivesHome: React.FC = () => {
  const TODAY_DATE = new Date();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isIncomeExpenseBlocked, setIsIncomeExpenseBlocked] = useState(false);
  const theme = useTheme() as ThemeInter;

  useEffect(() => {
    dispatch(NavbarActions.setTitle(PageTitles.OBJECTIVES_HOME));
    dispatch(ErrorActions.resetState());
  }, [dispatch]);

  const goToRetirementPage = () => {
    HomeAnalyticsService.retirementButton();
    history.push(PageRoutes.RETIREMENT);
  };

  const goToIncomeExpensePage = () => {
    if (isIncomeExpenseBlocked) {
      return;
    }
    HomeAnalyticsService.incomeExpenseButton();
    history.push(PageRoutes.INCOME_EXPENSE);
  };

  useEffect(() => {
    const firstDebitDate = getFirstDebitDate();

    if (firstDebitDate.getFullYear() === TODAY_DATE.getFullYear() + 1) {
      setIsIncomeExpenseBlocked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const goToBuyGoodPage = (goal: string) => {
  //   HomeAnalyticsService.buyGoodButton(goal);
  //   history.push(PageRoutes.BUY_GOOD);
  // };

  return (
    <InterContainerNoFooter margin="0 24px 24px">
      <Div width="100vw" margin="0 0px 24px -24px">
        <img src={ObjectivesHomeImg} alt="Objectives Home" style={{ width: '100%' }} />
      </Div>
      <P fontSize={FontSizeREM.PX24} fontFamily="Sora" lineHeight={FontSizeREM.PX30}>
        Tudo começa com um objetivo
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        lineHeight={FontSizeREM.PX17}
        fontWeight={400}
        color={theme.colors.grayscale.A400}
        marginBottom="0px"
      >
        Escolha o melhor pra você, seja <strong>se aposentar</strong> com tranquilidade,{' '}
        <strong>conquistar uma meta</strong> ou <strong>economizar no imposto de renda</strong>.
      </P>
      <Div width="100vw" margin="24px 0px 24px -24px">
        <Separator marginTop="32px" color={theme.colors.grayscale.A100} height="8px" />
      </Div>
      <Div marginTop="-24px">
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          height="91px"
          onClick={goToRetirementPage}
        >
          <P marginBottom="0px">Garantir minha aposentadoria</P>
          <InterUIIcon
            name="arrow-chevron-right"
            size={FontSizeREM.PX16}
            color={theme.colors.primary.A500}
          />
        </Flex>
      </Div>
      <SeparatorDashed color={theme.colors.grayscale.A200} />
      {/* <Flex
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
        height="55px"
        margin="24px 0px 16px 0px"
      >
        <P marginBottom="3px" fontSize={FontSizeREM.PX16}>
          Conquistar uma meta
        </P>
        <P
          fontSize={FontSizeREM.PX14}
          lineHeight={FontSizeREM.PX17}
          fontWeight={400}
          color={theme.colors.grayscale.A400}
          marginBottom="0px"
        >
          Escolha uma dessas opções, ou personalize com qualquer meta e o valor que quiser
        </P>
      </Flex>
      <Div>
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          height="64px"
          marginBottom="8px"
          onClick={() => goToBuyGoodPage('MetaPersonalizada')}
        >
          <Flex flexDirection="row" justifyContent="space-between" alignItems="center" gap="14px">
            <InterUIBoxCustomized
              actived
              padding="0px 24px"
              minHeight="64px"
              color={theme.colors.primary.A100}
            >
              <InterUIIcon name="home" size={FontSizeREM.PX20} color={theme.colors.primary.A500} />
            </InterUIBoxCustomized>
            <Div>
              <P marginBottom="0px" fontSize={FontSizeREM.PX12} lineHeight={FontSizeREM.PX15}>
                Criar minha meta personalizada
              </P>
              <P
                marginBottom="0px"
                fontSize={FontSizeREM.PX12}
                lineHeight={FontSizeREM.PX15}
                fontWeight={400}
              >
                Defina qualquer meta <br />e o valor dela
              </P>
            </Div>
          </Flex>
          <InterUIIcon
            name="arrow-chevron-right"
            size={FontSizeREM.PX16}
            color={theme.colors.primary.A500}
          />
        </Flex>
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          height="64px"
          marginBottom="8px"
          onClick={() => goToBuyGoodPage('ComprarCarroNovo')}
        >
          <Flex flexDirection="row" justifyContent="space-between" alignItems="center" gap="14px">
            <InterUIBoxCustomized
              actived
              padding="0px 24px"
              minHeight="64px"
              color={theme.colors.primary.A100}
            >
              <InterUIIcon name="home" size={FontSizeREM.PX20} color={theme.colors.primary.A500} />
            </InterUIBoxCustomized>

            <P marginBottom="0px" fontSize={FontSizeREM.PX12} lineHeight={FontSizeREM.PX15}>
              Comprar um carro novo
            </P>
          </Flex>

          <InterUIIcon
            name="arrow-chevron-right"
            size={FontSizeREM.PX16}
            color={theme.colors.primary.A500}
          />
        </Flex>
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          height="64px"
          marginBottom="8px"
          onClick={() => goToBuyGoodPage('MorarCasaPropria')}
        >
          <Flex flexDirection="row" justifyContent="space-between" alignItems="center" gap="14px">
            <InterUIBoxCustomized
              actived
              padding="0px 24px"
              minHeight="64px"
              color={theme.colors.primary.A100}
            >
              <InterUIIcon name="home" size={FontSizeREM.PX20} color={theme.colors.primary.A500} />
            </InterUIBoxCustomized>
            <P marginBottom="0px" fontSize={FontSizeREM.PX12} lineHeight={FontSizeREM.PX15}>
              Morar na casa própria
            </P>
          </Flex>
          <InterUIIcon
            name="arrow-chevron-right"
            size={FontSizeREM.PX16}
            color={theme.colors.primary.A500}
          />
        </Flex>
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          height="64px"
          marginBottom="24px"
          onClick={() => goToBuyGoodPage('PagarEducacaoFamilia')}
        >
          <Flex flexDirection="row" justifyContent="space-between" alignItems="center" gap="14px">
            <InterUIBoxCustomized
              actived
              padding="0px 24px"
              minHeight="64px"
              color={theme.colors.primary.A100}
            >
              <InterUIIcon name="home" size={FontSizeREM.PX20} color={theme.colors.primary.A500} />
            </InterUIBoxCustomized>
            <P marginBottom="0px" fontSize={FontSizeREM.PX12} lineHeight={FontSizeREM.PX15}>
              Pagar pela educação da familia
            </P>
          </Flex>
          <InterUIIcon
            name="arrow-chevron-right"
            size={FontSizeREM.PX16}
            color={theme.colors.primary.A500}
          />
        </Flex>
      </Div>
      <SeparatorDashed color={theme.colors.grayscale.A200} /> */}

      <Flex
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        height="80px"
        onClick={goToIncomeExpensePage}
      >
        <P marginBottom="0px" disabled={isIncomeExpenseBlocked}>
          Economizar no imposto de renda
        </P>
        <InterUIIcon
          name="arrow-chevron-right"
          size={FontSizeREM.PX16}
          color={isIncomeExpenseBlocked ? theme.colors.grayscale.A300 : theme.colors.primary.A500}
        />
      </Flex>
      {isIncomeExpenseBlocked && (
        <PSmallGray>Estaremos de volta a partir de 01/01/{TODAY_DATE.getFullYear() + 1}</PSmallGray>
      )}
    </InterContainerNoFooter>
  );
};

export default ObjectivesHome;
