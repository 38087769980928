import React, { useCallback, useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useDispatch } from 'react-redux';
import { EnvVariableKeys } from '../../enums/evironmentVariableKeys';
import BridgeService from '../../services/bridgeService';
import { EnvVariableService } from '../../services/environmentVariableService';
import { MockService } from '../../services/mockService';
import { MonitoringService } from '../../services/monitoringService';
import UserInfoActions from '../../store/user/actions';
import { Div } from '../../styles/commons';
import { _try } from '../../utils/errorUtils';

const OnWebviewInit: React.FC = () => {
  const dispatch = useDispatch();
  const handleError = useErrorHandler();
  const isRemoteBridgeDebugLogEnable = EnvVariableService.getVariableAsBoolean(
    EnvVariableKeys.REMOTE_BRIDGE_DEBUG_LOG_ENABLED,
  );

  const getUserInfo = useCallback(
    () =>
      _try(
        async () => {
          const response = await BridgeService.getUserInfo();
          dispatch(UserInfoActions.set(response));
          await MonitoringService.setDefaultAttributes(response.account);

          if (isRemoteBridgeDebugLogEnable) {
            BridgeService.enableRemoteBridgeDebugLog();
          }

          if (!MockService.account) {
            MockService.account = response.account;
            MockService.prepareMatchHeadersByUserAccount();
          }
        },
        handleError,
        'OnWebviewInit.getUserInfo',
      ),
    [dispatch, handleError, isRemoteBridgeDebugLogEnable],
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Div />;
};

export default OnWebviewInit;
