/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { all } from 'typed-redux-saga';
import watchHireProposal from './hire/saga';
import watchProposalDetails from './portability/saga';
import watchPrivatePensionPlans from './privatePensionPlan/saga';
import watchPortabilityRequest from './proposal/saga';
import watchSelectableFunds from './selectableFunds/saga';
import watchUserInfo from './user/saga';

export default function* rootSaga() {
  return yield* all([
    watchProposalDetails(),
    watchPortabilityRequest(),
    watchSelectableFunds(),
    watchHireProposal(),
    watchPrivatePensionPlans(),
    watchUserInfo(),
  ]);
}
