import AnalyticsBaseEventName from '../enums/analyticsBaseEventNames';
import BridgeService from '../services/bridgeService';
import { IIncomeTaxRequestProposal } from './types';

class IncomeTaxAnalyticsService {
  private static className = 'IncomeTaxAnalyticsService';

  static contributionStrategy(contribution_strategy: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.contributionStrategy`,
      `${AnalyticsBaseEventName.IR_INCOME_TAX}S_Valores`,
      {
        contribution_strategy,
      },
    );
  }

  static requestProposal(requestBody: IIncomeTaxRequestProposal): void {
    BridgeService.requestAnalytics(
      `${this.className}.showPlans`,
      `${AnalyticsBaseEventName.IR_INCOME_TAX}T_MostrPlan`,
      {
        ...requestBody,
      },
    );
  }
}

export default IncomeTaxAnalyticsService;
