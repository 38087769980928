import AnalyticsBaseEventName from '../enums/analyticsBaseEventNames';
import BridgeService from '../services/bridgeService';
import { IRequestProposal } from './types';

class RetirementAnalyticsService {
  private static className = 'RetirementAnalyticsService';

  static requestProposal({ content_name, price_inicial, price_mensal }: IRequestProposal): void {
    BridgeService.requestAnalytics(
      `${this.className}.requestProposal`,
      `${AnalyticsBaseEventName.RETIREMENT}T_PlanoIndicad`,
      {
        content_name,
        price_inicial,
        price_mensal,
        content_id: 'Aposentadoria',
      },
    );
  }
}

export default RetirementAnalyticsService;
