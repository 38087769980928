import { AnyAction } from 'redux';
import { action } from 'typesafe-actions';
import { RootState } from '../index';
import { ContributionValuesState, ContributionValuesTypes } from './types';

const getContributionValues = (state: RootState): ContributionValuesState =>
  state.contributionValues;

const setContributionValues = (contributionValues: ContributionValuesState): AnyAction =>
  action(ContributionValuesTypes.SET_CONTRIBUTION_VALUES, contributionValues);

const resetState = (): AnyAction => action(ContributionValuesTypes.RESET);

const ContributionValuesAction = {
  getContributionValues,
  setContributionValues,
  resetState,
};

export default ContributionValuesAction;
