/* eslint-disable react/no-array-index-key */
import {
  InterTheme,
  InterUIBottomSheet,
  InterUIButton,
  InterUIContainer,
  InterUIIcon,
  InterUILoading,
} from '@interco/inter-ui-react-lib';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import PortabilityAnalyticsService from '../../analytics/portabilityAnalyticsService';
import Hero from '../../assets/portabilityHome/Hero.png';
import Babi from '../../components/Babi/Babi';
import FontSizeREM from '../../enums/fontSizesRem';
import { LocalStorageKeys } from '../../enums/localStorageKeys';
import { PageRoutes } from '../../enums/pageRoutes';
import PageTitles from '../../enums/pageTitles';
import LocalStorageService from '../../services/localStorageService';
import ErrorActions from '../../store/error/actions';
import { LoadingActions } from '../../store/loading/actions';
import NavbarActions from '../../store/navbar/actions';
import PortabilityActions from '../../store/portability/actions';
import { ThemeInter } from '../../styles';
import { Div, Flex, H1, P, PSmallGray, SeparatorDashed } from '../../styles/commons';

interface IBottomSheetTitle {
  text: string;
  icon: ReactElement | null;
}
interface IBottomSheetState {
  show: boolean;
  title: IBottomSheetTitle;
  description: string[];
}

type BottomSheetInfoType = 'LESS_TAXES' | 'PREMIUM_CLIENT' | 'MORE_PROFITABILITY' | 'ZERO_COST';

const LESS_TAXES_INFO = {
  title: {
    text: 'Pague menos taxas administrativas',
    icon: <InterUIIcon name="wallet" size="24px" color={InterTheme.colors.primary.A500} />,
  },
  description: [
    'Economize no imposto de renda.',
    'Até 12% da sua renda anual tributável pode ser deduzida do IR, se investida na Previdência Privada PGBL',
  ],
};

const PREMIUM_CLIENT_INFO = {
  title: {
    text: 'Torne-se um cliente ONE ou Black',
    icon: <InterUIIcon name="card" size="24px" color={InterTheme.colors.primary.A500} />,
  },
  description: [
    'Ganhe benefícios exclusivos como atendentes exclusivos 24h, acesso a salas vips em aeroportos, cashback e muito mais.',
    'Para se tornar cliente One, você deve ter mais de R$ 50 mil em sua Previdência.',
    'Para se tornar cliente Black, você precisa ter mais de R$ 250 mil em sua Previdência.',
  ],
};

const MORE_PROFITABILITY_INFO = {
  title: {
    text: 'Chances de mais rentabilidade',
    icon: <InterUIIcon name="investment" size="24px" color={InterTheme.colors.primary.A500} />,
  },
  description: [
    'Aumente a rentabilidade do seu plano.',
    'Você terá o apoio de gestores qualificados para buscar melhores rentabilidades futuras para sua carteira.',
  ],
};

const ZERO_COST_INFO = {
  title: {
    text: 'Custo Zero',
    icon: <InterUIIcon name="no-taxes" size="24px" color={InterTheme.colors.primary.A500} />,
  },
  description: [
    'Você não paga nada para trazer sua previdência, e tem acesso a fundos com taxas de administração competitivas.',
  ],
};

interface IPortabilityInformativeCardProps {
  theme: ThemeInter;
  title: string;
  children: ReactElement;
  onClick: () => void;
  dataTestId: string;
  marginBottom: string;
}

const PortabilityInformativeCard: React.FC<IPortabilityInformativeCardProps> = ({
  theme,
  title,
  children,
  onClick,
  dataTestId,
  marginBottom,
}) => (
  <Flex
    flexDirection="row"
    alignItems="center"
    justifyContent="space-between"
    onClick={onClick}
    data-testid={dataTestId}
    marginBottom={marginBottom}
  >
    <Flex flexDirection="row" alignItems="center">
      <Flex
        margin="0px 8px 0px 0px"
        borderRadius="32px"
        width="40px"
        height="40px"
        backgroundColor={theme.colors.primary.A100}
        alignItems="center"
        justifyContent="center"
      >
        {children}
      </Flex>
      <P
        fontSize={FontSizeREM.PX14}
        lineHeight={FontSizeREM.PX17}
        marginBottom="0px"
        fontWeight={700}
      >
        {title}
      </P>
    </Flex>
    <InterUIIcon
      name="arrow-chevron-right"
      size={FontSizeREM.PX32}
      color={theme.colors.primary.A500}
    />
  </Flex>
);

export const LoadingIds = {
  requestBtn: '@portability-home/request-portability-btn',
  acceptanceBtn: '@portability-home/acceptcance-portability-btn',
  page: '@portability-home/page',
};

const PortabilityHomePage: React.FC = () => {
  const history = useHistory();
  const theme = useTheme() as ThemeInter;
  const loadingRequestPortbilityBtn = useSelector(
    LoadingActions.getByElement(LoadingIds.requestBtn),
  );
  const loadingAcceptancePortabilityBtn = useSelector(
    LoadingActions.getByElement(LoadingIds.acceptanceBtn),
  );
  const pageLoading = useSelector(LoadingActions.getByElement(LoadingIds.page));
  const dispatch = useDispatch();
  const [bottomSheet, setBottomSheet] = useState<IBottomSheetState>({
    show: false,
    title: {
      text: '',
      icon: null,
    },
    description: [''],
  });
  const customerPendingPortability = useSelector(PortabilityActions.getExternalPortabilityRequest);

  const callBottomSheetInfo = (type: BottomSheetInfoType) => {
    switch (type) {
      case 'PREMIUM_CLIENT': {
        PortabilityAnalyticsService.benefitItem(PREMIUM_CLIENT_INFO.title.text);
        setBottomSheet({ show: true, ...PREMIUM_CLIENT_INFO });
        break;
      }
      case 'MORE_PROFITABILITY': {
        PortabilityAnalyticsService.benefitItem(MORE_PROFITABILITY_INFO.title.text);
        setBottomSheet({ show: true, ...MORE_PROFITABILITY_INFO });
        break;
      }
      case 'ZERO_COST': {
        PortabilityAnalyticsService.benefitItem(ZERO_COST_INFO.title.text);
        setBottomSheet({ show: true, ...ZERO_COST_INFO });
        break;
      }
      default: {
        PortabilityAnalyticsService.benefitItem(LESS_TAXES_INFO.title.text);
        setBottomSheet({ show: true, ...LESS_TAXES_INFO });
        break;
      }
    }
  };

  const handlerBottomSheetCloseButton = (
    bottomSheetParams: IBottomSheetState,
    show: boolean,
  ): void => {
    PortabilityAnalyticsService.benefitsBottomSheetHireButton(bottomSheetParams.title.text);
    setBottomSheet({ ...bottomSheetParams, show });
  };

  const goToPortabilityDataPage = () => {
    if (!LocalStorageService.hasExpired(LocalStorageKeys.FINANCIAL_INSTITUTIONS)) {
      history.push(PageRoutes.PORTABILITY_SELECT_FINANCIAL_INSTITUTION);
      return;
    }

    if (loadingRequestPortbilityBtn) return;

    PortabilityAnalyticsService.bringPortabilityButton();

    dispatch(
      PortabilityActions.requestFinancialInstitutions({
        history,
        pathname: PageRoutes.PORTABILITY_SELECT_FINANCIAL_INSTITUTION,
      }),
    );
  };

  const goToPortabilityAcceptancePage = () => {
    if (!customerPendingPortability) return;
    history.push(
      PageRoutes.EXTERNAL_PORTABILITY_ACCEPTANCE.replace(
        ':id',
        (customerPendingPortability.id as unknown) as string,
      ),
    );
  };

  const stikyFooter: React.ReactElement = (
    <>
      <InterUIButton
        type="button"
        onClick={goToPortabilityDataPage}
        loading={loadingRequestPortbilityBtn}
        disabled={loadingAcceptancePortabilityBtn || loadingRequestPortbilityBtn}
        margin="24px"
      >
        Pedir minha portabilidade
      </InterUIButton>
      {customerPendingPortability && (
        <InterUIButton
          type="button"
          onClick={goToPortabilityAcceptancePage}
          loading={loadingAcceptancePortabilityBtn}
          disabled={loadingAcceptancePortabilityBtn || loadingRequestPortbilityBtn}
          margin="24px"
        >
          Autorizar minha portabilidade
        </InterUIButton>
      )}
    </>
  );

  const retrieveLastCustomerPendingPortability = useCallback(() => {
    dispatch(PortabilityActions.getCustomerLastPendingPortability());
  }, [dispatch]);

  useEffect(
    () => {
      retrieveLastCustomerPendingPortability();
      dispatch(NavbarActions.setTitle(PageTitles.PORTABILITY_HOME));
      dispatch(PortabilityActions.resetState());
      dispatch(ErrorActions.resetState());
    }, // Stryker disable next-line all
    [dispatch, retrieveLastCustomerPendingPortability],
  );

  return !pageLoading ? (
    <InterUIContainer stickyFooter={stikyFooter} margin="0">
      <Div
        height={`calc(100vh - ${customerPendingPortability ? '224px' : '152px'})`}
        overflowY="scroll"
        width="100vw"
      >
        <img
          src={Hero}
          alt="Portability Hero Home"
          style={{ width: '100%', margin: '24px 0 24px' }}
        />
        <Div margin="0 24px">
          <Div width="288px">
            <H1 marginBottom="16px">Comece a realizar seus sonhos do futuro agora</H1>
            <P
              fontSize={FontSizeREM.PX14}
              lineHeight={FontSizeREM.PX17}
              fontWeight={400}
              color={theme.colors.grayscale.A400}
              marginBottom="20px"
            >
              Traga sua Previdência para o Inter, sem pagar nada a mais, economize no Imposto de
              Renda, reduza as taxas do plano e garanta outras vantagens exclusivas.
            </P>
          </Div>
          <SeparatorDashed color={theme.colors.grayscale.A300} marginBottom="40px" />
          <P
            fontSize={FontSizeREM.PX16}
            fontFamily="Sora"
            lineHeight={FontSizeREM.PX20}
            fontWeight={600}
            color={theme.colors.grayscale.A500}
            marginBottom="24px"
          >
            Benefícios
          </P>
          <PortabilityInformativeCard
            theme={theme}
            title={LESS_TAXES_INFO.title.text}
            dataTestId="less-taxes"
            onClick={() => callBottomSheetInfo('LESS_TAXES')}
            marginBottom="24px"
          >
            {LESS_TAXES_INFO.title.icon}
          </PortabilityInformativeCard>

          <PortabilityInformativeCard
            theme={theme}
            title={PREMIUM_CLIENT_INFO.title.text}
            onClick={() => callBottomSheetInfo('PREMIUM_CLIENT')}
            dataTestId="premium-client"
            marginBottom="24px"
          >
            {PREMIUM_CLIENT_INFO.title.icon}
          </PortabilityInformativeCard>
          <PortabilityInformativeCard
            theme={theme}
            title={MORE_PROFITABILITY_INFO.title.text}
            onClick={() => callBottomSheetInfo('MORE_PROFITABILITY')}
            dataTestId="more-profitability"
            marginBottom="24px"
          >
            {MORE_PROFITABILITY_INFO.title.icon}
          </PortabilityInformativeCard>
          <PortabilityInformativeCard
            theme={theme}
            title={ZERO_COST_INFO.title.text}
            onClick={() => callBottomSheetInfo('ZERO_COST')}
            dataTestId="zero-cost"
            marginBottom="0px"
          >
            {ZERO_COST_INFO.title.icon}
          </PortabilityInformativeCard>
          <SeparatorDashed
            color={theme.colors.grayscale.A300}
            marginBottom="24px"
            marginTop="50px"
          />
          <Div margin="48px 0px">
            <Babi
              title="Precisa de ajuda?"
              subTitle="Converse com a gente pelo chat. A Babi vai te ajudar."
              errorCodeRef="PortabilityHome"
              analyticCallBack={() => PortabilityAnalyticsService.babi()}
            />
          </Div>
          <InterUIBottomSheet
            toggle={bottomSheet.show}
            onHide={() => handlerBottomSheetCloseButton({ ...bottomSheet }, false)}
          >
            <Flex flexDirection="row" alignItems="center" marginBottom="27px">
              <Flex
                margin="0px 8px 0px 0px"
                borderRadius="32px"
                width="40px"
                height="40px"
                backgroundColor={theme.colors.primary.A100}
                alignItems="center"
                justifyContent="center"
              >
                {bottomSheet.title.icon}
              </Flex>
              <P
                fontSize={FontSizeREM.PX14}
                lineHeight={FontSizeREM.PX17}
                marginBottom="0px"
                fontWeight={700}
                fontFamily="Inter"
              >
                {bottomSheet.title.text}
              </P>
            </Flex>

            {bottomSheet.description.map((paragraph, index) => (
              <PSmallGray key={`paragraph-${index}`}>{paragraph}</PSmallGray>
            ))}
            <InterUIButton
              type="button"
              onClick={() => handlerBottomSheetCloseButton({ ...bottomSheet }, false)}
              margin="32px 0px 0px 0px"
            >
              Entendi
            </InterUIButton>
          </InterUIBottomSheet>
        </Div>
      </Div>
    </InterUIContainer>
  ) : (
    <Flex height="100vh" justifyContent="center" alignItems="center">
      <InterUILoading size="ld" data-testid="loading" />
    </Flex>
  );
};

export default PortabilityHomePage;
