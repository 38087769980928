/* eslint-disable react/destructuring-assignment */
import { InterUIAlert } from '@interco/inter-ui-react-lib';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import ProposalAnalyticsService from '../../../../analytics/proposalAnalyticsService';
import FontSizeREM from '../../../../enums/fontSizesRem';
import { PageRoutes } from '../../../../enums/pageRoutes';
import { PrivatePensions, TaxTypes } from '../../../../enums/privatePensions';
import { ProposalDetailActions } from '../../../../store/proposal/actions';
import { ThemeInter } from '../../../../styles';
import Colors from '../../../../styles/colors';
import { P, SeparatorDashed } from '../../../../styles/commons';
import { IBestOption } from '../../Proposal';

interface IPlanTypesProps {
  bestOption?: IBestOption;
}

const PlanTypes: React.FC<IPlanTypesProps> = ({ bestOption }) => {
  const privatePensionType = useSelector(ProposalDetailActions.getPrivatePensionType);
  const taxType = useSelector(ProposalDetailActions.getTaxType);
  const proposalType = useSelector(ProposalDetailActions.getType);
  const history = useHistory();
  const theme = useTheme() as ThemeInter;

  const getTypeText = (type: string) => {
    switch (type) {
      case PrivatePensions.PGBL:
        return `Ideal para quem faz a declaração completa do Imposto de Renda (IR). Com desconto no IR, o que
        você investiu na previdência (até 12% do total declarado) é abatido da base de cálculo.`;
      case PrivatePensions.VGBL:
        return `Ideal para quem faz a declaração simplificada do Imposto de Renda (IR) e quer investir na previdência
         mais do que 12% do total declarado. O IR incide apenas sobre seus rendimentos, e só no resgate.`;
      case TaxTypes.REGRESSIVE:
        return `Indicada pra quem precisa do dinheiro só lá no futuro, no longo prazo. O desconto sobre os
        rendimentos começa em 35% e vai diminuindo ao longo do tempo, até chegar em 10%.`;
      case TaxTypes.PROGRESSIVE:
        return `Indicada pra quem talvez precise resgatar o valor em pouco tempo. O desconto é baseado na tabela
        progressiva do Imposto de Renda. Vai desde a isenção até 27,5%, dependendo do valor do resgate.`;
      default:
        return ``;
    }
  };

  const goToTaxTypePage = () => {
    ProposalAnalyticsService.editInfoButton('tabela', proposalType);
    history.push(PageRoutes.TAX_TYPE);
  };
  const goToPrivatePensionTypePage = () => {
    ProposalAnalyticsService.editInfoButton('modalidade', proposalType);
    history.push(PageRoutes.PRIVATE_PENSION_TYPE);
  };

  return (
    <>
      <P fontSize={FontSizeREM.PX14} lineHeight={FontSizeREM.PX17} fontWeight={700}>
        Modalidade {privatePensionType}
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        lineHeight={FontSizeREM.PX17}
        fontWeight={400}
        color={Colors.shuttleGray}
      >
        {getTypeText(privatePensionType)}
      </P>
      {bestOption && bestOption.privatePension !== privatePensionType && (
        <InterUIAlert type="attention" color={theme.colors.alert.A100} margin="16px 0 16px 0">
          <P
            fontWeight={700}
            fontSize={FontSizeREM.PX12}
            lineHeight={FontSizeREM.PX15}
            marginBottom="4px"
          >
            {`Valores ideais para ${bestOption.privatePension}`}
          </P>
          <P fontWeight={400} fontSize={FontSizeREM.PX12} lineHeight={FontSizeREM.PX15}>
            {`De acordo com os valores informados, seu plano ideal seria o ${bestOption.privatePension}.`}
          </P>
        </InterUIAlert>
      )}
      <P
        fontSize={FontSizeREM.PX14}
        lineHeight={FontSizeREM.PX17}
        color={Colors.interOrange}
        marginBottom="0"
        onClick={goToPrivatePensionTypePage}
        data-testid="edit-private-pension-btn"
      >
        Ver outra opção de modalidade
      </P>
      <SeparatorDashed marginTop="41px" marginBottom="24px" color={Colors.shuttleGrayDisabled} />
      <P fontSize={FontSizeREM.PX14} lineHeight={FontSizeREM.PX17} fontWeight={700}>
        Tabela {taxType === TaxTypes.PROGRESSIVE ? 'Progressiva' : 'Regressiva'}
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        lineHeight={FontSizeREM.PX17}
        fontWeight={400}
        color={Colors.shuttleGray}
      >
        {getTypeText(taxType)}
      </P>
      <P
        fontSize={FontSizeREM.PX14}
        lineHeight={FontSizeREM.PX17}
        color={Colors.interOrange}
        marginBottom="0"
        onClick={goToTaxTypePage}
        data-testid="edit-tax-type-btn"
      >
        Ver outra opção de tabela de tributação
      </P>
    </>
  );
};

export default PlanTypes;
