/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Control,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetFocus,
  UseFormSetValue,
  UseFormStateReturn,
} from 'react-hook-form';
import { Flex, FlexStyledProps } from '../../styles/commons';

type FlexWithHookProps = React.InputHTMLAttributes<HTMLInputElement> &
  FlexStyledProps & {
    register?: UseFormRegister<any>;
    getValues?: UseFormGetValues<any>;
    setValue?: UseFormSetValue<any>;
    setFocus?: UseFormSetFocus<any>;
    control?: Control;
    formState?: UseFormStateReturn<any>;
  };

const FlexWithHook: React.FC<FlexWithHookProps> = ({
  flexDirection,
  justifyContent,
  alignItems,
  height,
  width,
  overflowX,
  overflowY,
  margin,
  marginBottom,
  children,
  register,
  setValue,
  setFocus,
  getValues,
  control,
  formState,
}) => (
  <Flex
    flexDirection={flexDirection}
    justifyContent={justifyContent}
    alignItems={alignItems}
    height={height}
    width={width}
    overflowX={overflowX}
    overflowY={overflowY}
    margin={margin}
    marginBottom={marginBottom}
  >
    {React.Children.map(children, (child: any) =>
      child?.props.name
        ? React.createElement(child.type, {
            ...{
              ...child.props,
              register,
              setValue,
              getValues,
              setFocus,
              control,
              errors: formState?.errors[child.props.name],
              key: child.props.name,
            },
          })
        : child,
    )}
  </Flex>
);

export default FlexWithHook;
