/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { InterUIButton } from '@interco/inter-ui-react-lib';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import PortabilityAnalyticsService from '../../analytics/portabilityAnalyticsService';
import { EnvVariableKeys } from '../../enums/evironmentVariableKeys';
import { LocalStorageKeys } from '../../enums/localStorageKeys';
import { PageRoutes } from '../../enums/pageRoutes';
import PageTitles from '../../enums/pageTitles';
import { AppInfoService } from '../../services/appInfoService';
import { EnvVariableService } from '../../services/environmentVariableService';
import LocalStorageService from '../../services/localStorageService';
import { LoadingActions } from '../../store/loading/actions';
import NavbarActions from '../../store/navbar/actions';
import PostabilitiesStatusActions from '../../store/portabilityStatus/action';
import { PortabilityStatus } from '../../store/portabilityStatus/types';
import UserInfoActions from '../../store/user/actions';
import { ThemeInter } from '../../styles';
import { Div } from '../../styles/commons';
import { InterContainerNoFooter } from '../../styles/inter-ui-customizations';
import { convertStringPtBRToDate } from '../../utils/dateTimeUtils';
import PortabilityStatusList from './components/PortabilityStatusList/PortabilityStatusList';

const PortabilityStatusPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(LoadingActions.get);
  const { account } = useSelector(UserInfoActions.get);
  const userSignedTerms = useSelector(UserInfoActions.getUserSignedTerms);
  const theme = useTheme() as ThemeInter;
  const portabilitiesStatus = useSelector(PostabilitiesStatusActions.getPortabilitiesStatus);

  const portabilitiesSorttedByDate: PortabilityStatus[] = useMemo(
    () => {
      const getComparabilityDate = (portabilityStatus: PortabilityStatus): string =>
        portabilityStatus.protocoledAt || portabilityStatus.startedAt || '';

      const comparePortability = (item: PortabilityStatus, comparedItem: PortabilityStatus) => {
        const comparabledItemDate = getComparabilityDate(comparedItem);
        const ItemDate = getComparabilityDate(item);
        return (
          convertStringPtBRToDate(comparabledItemDate).getTime() -
          convertStringPtBRToDate(ItemDate).getTime()
        );
      };

      return portabilitiesStatus.sort((item, comparedItem) =>
        comparePortability(item, comparedItem),
      );
    },
    // Stryker disable next-line all
    [portabilitiesStatus],
  );

  const checkIfTheUserTermsAreValid = (paramsRoute: string) => {
    const signedTermsFromLocalStorageIsValid = !LocalStorageService.hasExpired(
      LocalStorageKeys.USER_SIGNED_TERMS,
      account,
    );

    // The minimal app version to use the qualified investor term and suitability deeplinks.
    const startingVersionToValidadeCustomerSuitability = AppInfoService.convertToNumber(
      EnvVariableService.getVariable(
        EnvVariableKeys.STARTING_VERSION_TO_VALIDATE_CUSTOMER_SUITABILITY,
      )!,
    );

    const appVersionInfo = AppInfoService.convertToNumber(AppInfoService.superAppVersion);

    // We compare versions because only in the v11.2 of the app, we have the Suitability and Investor Profile deeplink
    // Under the v11.2, the user will follow the old flow.
    const isAppVersionAllowed = appVersionInfo >= startingVersionToValidadeCustomerSuitability;

    if (
      !signedTermsFromLocalStorageIsValid &&
      userSignedTerms.isAcceptable !== true &&
      isAppVersionAllowed
    ) {
      dispatch(UserInfoActions.requestUserSignedTerms({ pathname: paramsRoute, history }));
    } else {
      history.push(paramsRoute);
    }
  };

  const newRequestClick = () => {
    checkIfTheUserTermsAreValid(PageRoutes.PORTABILITY_HOME);
    PortabilityAnalyticsService.newPortabilityButton();
  };

  useEffect(
    () => {
      dispatch(NavbarActions.setTitle(PageTitles.PORTABILITY_STATUS));
    },
    // Stryker disable next-line all
    [dispatch],
  );

  return (
    <InterContainerNoFooter margin="16px 24px 24px">
      <PortabilityStatusList list={portabilitiesSorttedByDate} />
      <Div
        position="fixed"
        left="0px"
        bottom="0px"
        width="100%"
        backgroundColor={theme.colors.white}
      >
        <InterUIButton
          type="button"
          disabled={false}
          onClick={newRequestClick}
          loading={loading}
          margin="24px"
        >
          Nova solicitação
        </InterUIButton>
      </Div>
      <Div height="72px" />
    </InterContainerNoFooter>
  );
};

export default PortabilityStatusPage;
