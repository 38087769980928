import { AnyAction } from 'redux';
import { action } from 'typesafe-actions';
import { RootState } from '..';
import { PreviewContractTypes } from './types';

const getContract = (state: RootState): string | null => state.previewContract.contract;

const setContract = (contract: string | null): AnyAction =>
  action(PreviewContractTypes.SET_CONTRACT, contract);

const requestContract = (): AnyAction => action(PreviewContractTypes.REQUEST_CONTRACT);

const resetState = (): AnyAction => action(PreviewContractTypes.RESET);

export const PreviewContractActions = {
  getContract,
  setContract,
  requestContract,
  resetState,
};
