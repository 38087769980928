import React from 'react';
import { useTheme } from 'styled-components';
import { BBIcon, BelvoIcon, InterIcon, ItauIcon } from '../../../../assets/icons/svg';
import { FinancialInstitution, SupportedInstitutesId } from '../../../../store/portability/types';
import { ThemeInter } from '../../../../styles';
import { Flex } from '../../../../styles/commons';
import { Dot, Loading } from './InstitutionIcons.styled';

interface IInstitutionProps {
  institution?: FinancialInstitution | null;
  margin?: string;
  isLoading?: boolean;
}

const InstitutionIcons: React.FC<IInstitutionProps> = ({ institution, margin, isLoading }) => {
  const theme = useTheme() as ThemeInter;
  const iconSize = '64';
  const loading = isLoading ? isLoading.toString() : 'false';

  const getInstitutionIcon = () => {
    switch (institution?.id) {
      case SupportedInstitutesId.ITAU:
        return <ItauIcon size={iconSize} />;
      default:
        return <BBIcon size={iconSize} />;
    }
  };

  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      margin={margin}
      gap="7px"
    >
      <Loading position={1} loading={loading}>
        <InterIcon size={iconSize} color={theme.colors.primary.A500} />
      </Loading>

      <Flex
        id="leftDots"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        gap="4px"
      >
        <Loading position={2} loading={loading}>
          <Dot color={theme.colors.primary.A500} />
        </Loading>
        <Loading position={3} loading={loading}>
          <Dot color={theme.colors.primary.A500} />
        </Loading>
        <Loading position={4} loading={loading}>
          <Dot color={theme.colors.primary.A500} />
        </Loading>
      </Flex>

      <Loading position={5} loading={loading}>
        <BelvoIcon size={iconSize} />
      </Loading>

      <Flex
        id="rightDots"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        gap="4px"
      >
        <Loading position={6} loading={loading}>
          <Dot color={theme.colors.primary.A500} />
        </Loading>
        <Loading position={7} loading={loading}>
          <Dot color={theme.colors.primary.A500} />
        </Loading>
        <Loading position={8} loading={loading}>
          <Dot color={theme.colors.primary.A500} />
        </Loading>
      </Flex>

      <Loading position={9} loading={loading}>
        {getInstitutionIcon()}
      </Loading>
    </Flex>
  );
};

export default InstitutionIcons;
