/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  InterUIAccordion,
  InterUIAlert,
  InterUIBox,
  InterUIContainer,
  InterUITimeline,
  InterUIWizard,
} from '@interco/inter-ui-react-lib';
import styled from 'styled-components';
import { Div } from './commons';

/**
 * Only Overlays with fade style should have z-index greater than 90,
 * otherwise the z-index must be always smaller.
 */
export const NavContainer = styled(Div)`
  nav {
    z-index: 90;
    h3 {
      width: 64.445vw; //232px on a 360px width viewport
      margin: auto;
    }
  }
`;

/**
 * Customize the tag color and backdroung-color
 */
export const InterTagContainer = styled(Div)<{ color?: string }>`
  background-color: unset;
  button {
    ${(props) => props.backgroundColor && `background-color: ${props.backgroundColor};`}
    ${(props) => props.color && `color: ${props.color};`}
    .inter-ic-arrow-chevron-down {
      margin: 0 5px 0 8px;
      ${(props) => props.color && `color: ${props.color};`}
    }
  }
`;

/**
 * Customize the paragraph on dropdown to be bolder
 */
export const InterDropdownContainer = styled(Div)`
  p {
    font-weight: bold;
  }
`;

/**
 * Customize the container to keep the navbar fixed in the top if no footer
 */
export const InterContainerNoFooter = styled(InterUIContainer)`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 56px);
  overflow: auto;
`;

export const InterAccordionCustomized = styled(InterUIAccordion)`
  div {
    overflow: hidden;
    margin-bottom: 0;
  }
`;

export const InterUIBoxCustomized = styled(InterUIBox)<{ color?: string }>`
  ${(props) => props.color && `border: 1px solid ${props.color};`}
`;

export const InterUIAlertCustomized = styled(InterUIAlert)<{
  alignItems?: string;
  justifyContent?: string;
}>`
  ${(props) => props.justifyContent && `justify-content: ${props.justifyContent};`}
  ${(props) => props.alignItems && `align-items: ${props.alignItems};`}
`;

/**
 * Customize the InterUIWizard to adjustment of component position and the behavior of buttons e controls
 */
export const InterUIWizardCustomized = styled(InterUIWizard)<{ showControl?: boolean }>`
  padding-top: 0;
  div {
    left: 0;
    padding-top: 0;
    margin: 0;
  }
  h1 {
    padding-top: 0;
  }
  button {
    display: ${(props) => (props.showControl ? `block;` : `none;`)};
  }
  span {
    display: ${(props) => (props.showControl ? `inline-block;` : `none;`)};
  }
`;

export const InterUITimelineItemCustomized = styled(InterUITimeline.Item)<{
  color?: string;
  fontColor?: string;
}>`
  //  step circle
  span.sc-bUAGoA {
    ${(props) =>
      props.type === 'Disabled' &&
      `left: 6px;
       top: 6px; height: 0px;
       width: 0px;
       border: 4px solid ${props.color};
    `}
    ${(props) => props.type === 'Start' && props.color && `border: 4px solid ${props.color};`}
  }

  // step start line
  div.sc-tIASn {
    ${(props) => props.type === 'Start' && props.color && `background-color: ${props.color};`}
  }

  // steps titles
  span.sc-dSDSiC {
    ${(props) => props.type === 'Success' && props.fontColor && `color: ${props.fontColor};`}
    ${(props) => props.type === 'Error' && props.fontColor && `color: ${props.fontColor};`}
    ${(props) => props.type === 'Disabled' && props.fontColor && `color: ${props.fontColor};`}
  }

  // steps icons
  div.sc-jLaYxh {
    width: auto;
    height: auto;
    border: none;
  }
`;
