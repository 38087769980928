/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { PageRoutes } from '../enums/pageRoutes';
import { useQueryParams } from '../hooks';
import AdditionalContributionDeepLinkSplashPage from '../pages/AdditionalContributionDeepLinkSplash/AdditionalContributionDeepLinkSplash';
import AdditionalContributionExpiredPage from '../pages/AdditionalContributionExpired/AdditionalContributionExpired';
import AdditionalContributionInvestmentFormPage from '../pages/AdditionalContributionInvestmentForm/AdditionalContributionInvestmentForm';
import AdditionalContributionPGBLHelperPage from '../pages/AdditionalContributionPGBLHelper/AdditionalContributionPGBLHelper';
import AdditionalContributionProposalPage from '../pages/AdditionalContributionProposal/AdditionalContributionProposal';
import AdditionalContributionReceiptPage from '../pages/AdditionalContributionReceipt/AdditionalContributionReceipt';
import BuyGoodPage from '../pages/BuyGood/BuyGood';
import CancelReceiptPage from '../pages/CancelReceipt/CancelReceiptPage';
import CheckSignedTermsDeeplinkSplashPage from '../pages/CheckSignedTermsDeeplinkSplash/CheckSignedTermsDeeplinkSplash';
import ContractionReceipt from '../pages/ContractionReceipt/ContractionReceipt';
import ContributionTime from '../pages/ContributionTime/ContributionTime';
import ContributionValues from '../pages/ContributionValues/ContributionValues';
import DeepLinkSplash from '../pages/DeepLinkSplash/DeepLinkSplash';
import ExternalPortabilityAcceptance from '../pages/ExternalPortabilityAcceptance/ExternalPortabilityAcceptance';
import HireProposalAcceptancePage from '../pages/HireProposalAcceptance/HireProposalAcceptance';
import Home from '../pages/Home/Home';
import IncomeExpensePage from '../pages/IncomeExpense/IncomeExpense';
import IncomeTaxPage from '../pages/IncomeTax/IncomeTax';
import ObjectivesHome from '../pages/ObjectivesHome/ObjectivesHome';
import PaymentDay from '../pages/PaymentDay/PaymentDay';
import PortabilityAccumulatedValuePage from '../pages/PortabilityAccumulatedValue/PortabilityAccumulatedValue';
import PortabilityConfirmationPage from '../pages/PortabilityConfirmation/PortabilityConfirmation';
import PortabilityContractionReceipt from '../pages/PortabilityContractionReceipt/PortabilityContractionReceipt';
import PortabilityExternalPensionPlansPage from '../pages/PortabilityExternalPensionPlans/PortabilityExternalPensionPlans';
import PortabilityHomePage from '../pages/PortabilityHome/PortabilityHome';
import PortabilityOnboardingBelvoPage from '../pages/PortabilityOnboardingBelvo/PortabilityOnboardingBelvo';
import PortabilityPreviewPrivacyPolicyPage from '../pages/PortabilityPreviewPrivacyPolicy/PortabilityPreviewPrivacyPolicy';
import PortabilityQueryPensionPlanPage from '../pages/PortabilityQueryPensionPlan/PortabilityQueryPensionPlan';
import PortabilityRevision from '../pages/PortabilityRevision/PortabillityRevision';
import PortabilitySelectFinancialInstitutionPage from '../pages/PortabilitySelectFinancialInstitution/PortabilitySelectFinancialInstitution';
import PortabilityStatusPage from '../pages/PortabilityStatus/PortabilityStatus';
import PreviewContractPage from '../pages/PreviewContract/PreviewContract';
import PrivatePensionPlansPage from '../pages/PrivatePensionPlans/PrivatePensionPlans';
import PrivatePensionTypePage from '../pages/PrivatePensionType/PrivatePensionType';
import ProposalPage from '../pages/Proposal/Proposal';
import ProposalByFundChooseFundPage from '../pages/ProposalByFundChooseFund/ProposalByFundChooseFund';
import ProposalByFundContributionValuesPage from '../pages/ProposalByFundContributionValues/ProposalByFundContributionValues';
import ProposalByPortability from '../pages/ProposalByPortability/ProposalByPortability';
import RetirementPage from '../pages/Retirement/Retirement';
import RevisionPage from '../pages/Revision/Revision';
import SelectableFundsPage from '../pages/SelectableFunds/SelectableFunds';
import TaxTypePage from '../pages/TaxType/TaxType';

// Stryker disable all
const Routes: React.FC = () => {
  const { handleQueryParams } = useQueryParams();

  useEffect(() => {
    handleQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      <Route path={PageRoutes.OBJECTIVES_HOME} exact component={ObjectivesHome} />
      <Route path={PageRoutes.HOME} exact component={Home} />
      <Route path={PageRoutes.RETIREMENT} exact component={RetirementPage} />
      <Route path={PageRoutes.PROPOSAL} exact component={ProposalPage} />
      <Route path={PageRoutes.SELECTABLE_FUNDS} exact component={SelectableFundsPage} />
      <Route path={PageRoutes.TAX_TYPE} exact component={TaxTypePage} />
      <Route path={PageRoutes.PRIVATE_PENSION_TYPE} exact component={PrivatePensionTypePage} />
      <Route path={PageRoutes.CONTRIBUTION_TIME} exact component={ContributionTime} />
      <Route path={PageRoutes.PAYMENT_DAY} exact component={PaymentDay} />
      <Route path={PageRoutes.CONTRIBUTION_VALUES} exact component={ContributionValues} />
      <Route path={PageRoutes.PREVIEW_CONTRACT} exact component={PreviewContractPage} />
      <Route path={PageRoutes.PORTABILITY_HOME} exact component={PortabilityHomePage} />
      <Route
        path={PageRoutes.PORTABILITY_SELECT_FINANCIAL_INSTITUTION}
        exact
        component={PortabilitySelectFinancialInstitutionPage}
      />
      <Route
        path={PageRoutes.PORTABILITY_ACCUMULATED_VALUE}
        exact
        component={PortabilityAccumulatedValuePage}
      />
      <Route
        path={PageRoutes.PORTABILITY_CONFIRMATION}
        exact
        component={PortabilityConfirmationPage}
      />
      <Route
        path={PageRoutes.PORTABILITY_ONBOARDING_BELVO}
        exact
        component={PortabilityOnboardingBelvoPage}
      />
      <Route
        path={PageRoutes.PORTABILITY_PREVIEW_PRIVACY_POLICY}
        exact
        component={PortabilityPreviewPrivacyPolicyPage}
      />
      <Route
        path={PageRoutes.PORTABILITY_QUERY_PENSION_PLAN}
        exact
        component={PortabilityQueryPensionPlanPage}
      />
      <Route
        path={PageRoutes.PORTABILITY_EXTERNAL_PENSION_PLAN}
        exact
        component={PortabilityExternalPensionPlansPage}
      />
      <Route path={PageRoutes.PORTABILITY_STATUS} exact component={PortabilityStatusPage} />
      <Route path={PageRoutes.PROPOSAL_BY_PORTABILITY} exact component={ProposalByPortability} />
      <Route path={PageRoutes.PORTABILITY_REVISION} exact component={PortabilityRevision} />
      <Route
        path={PageRoutes.PORTABILITY_CONTRACTION_RECEIPT}
        exact
        component={PortabilityContractionReceipt}
      />
      <Route path={PageRoutes.BUY_GOOD} exact component={BuyGoodPage} />
      <Route path={PageRoutes.REVISION} exact component={RevisionPage} />
      <Route path={PageRoutes.CONTRACTION_RECEIPT} exact component={ContractionReceipt} />
      <Route path={PageRoutes.INCOME_EXPENSE} exact component={IncomeExpensePage} />
      <Route path={PageRoutes.INCOME_TAX} exact component={IncomeTaxPage} />
      <Route path={PageRoutes.DEEP_LINK_PROPOSAL} exact component={DeepLinkSplash} />
      <Route
        path={PageRoutes.PROPOSAL_BY_FUND_CHOOSE_FUND}
        exact
        component={ProposalByFundChooseFundPage}
      />
      <Route
        path={PageRoutes.PROPOSAL_BY_FUND_CONTRIBUTION_VALUES}
        exact
        component={ProposalByFundContributionValuesPage}
      />
      <Route path={PageRoutes.PRIVATE_PENSION_PLANS} exact component={PrivatePensionPlansPage} />
      <Route
        path={PageRoutes.ADDITIONAL_CONTRIBUTION_INVESTMENT_FORM}
        exact
        component={AdditionalContributionInvestmentFormPage}
      />
      <Route
        path={PageRoutes.ADDITIONAL_CONTRIBUTION_PROPOSAL}
        exact
        component={AdditionalContributionProposalPage}
      />
      <Route
        path={PageRoutes.ADDITIONAL_CONTRIBUTION_PGBL_HELPER}
        exact
        component={AdditionalContributionPGBLHelperPage}
      />
      <Route
        path={PageRoutes.ADDITIONAL_CONTRIBUTION_RECEIPT}
        exact
        component={AdditionalContributionReceiptPage}
      />
      <Route
        path={PageRoutes.ADDITIONAL_CONTRIBUTION_DEEP_LINK}
        exact
        component={AdditionalContributionDeepLinkSplashPage}
      />
      <Route
        path={PageRoutes.ADDITIONAL_CONTRIBUTION_EXPIRED}
        exact
        component={AdditionalContributionExpiredPage}
      />
      <Route
        path={PageRoutes.CHECK_SIGNED_TERMS_DEEPLINK_SPLASH}
        exact
        component={CheckSignedTermsDeeplinkSplashPage}
      />
      <Route
        path={PageRoutes.EXTERNAL_PORTABILITY_ACCEPTANCE}
        exact
        component={ExternalPortabilityAcceptance}
      />
      <Route
        path={PageRoutes.HIRE_PROPOSAL_ACCEPTANCE}
        exact
        component={HireProposalAcceptancePage}
      />
      <Route path={PageRoutes.CANCELLATION_RECEIPT} exact component={CancelReceiptPage} />
    </Switch>
  );
};

export default withRouter(Routes);
