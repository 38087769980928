/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { PrivatePensions, TaxTypes } from '../enums/privatePensions';
import { AppInfoService } from './appInfoService';
import { BaseService, ServiceResponse } from './baseService';
import { MockService } from './mockService';

interface AdditionalContributionHeader {
  authenticationToken: string;
  authenticationType: string;
  authenticationValue: string;
  code: string;
  transactionType: string;
}

interface AdditionalContributionBody {
  additionalContribution: number;
  investmentFundCode: string;
  privatePensionType: PrivatePensions;
  taxType: TaxTypes;
}

interface AdditionalContributionResponse {
  additionalContributionId: number;
}

const {
  REACT_APP_API_PRIVATE_PENSION_PLANS_V1,
  REACT_APP_API_BRIDGE_PRIVATE_PENSION_PLANS_V1,
} = process.env;

class AdditionalContributionService {
  static async getContributionReceipt(
    certificateNumber: string,
    header: AdditionalContributionHeader,
    body: AdditionalContributionBody,
  ): Promise<ServiceResponse<AdditionalContributionResponse>> {
    return BaseService.doExecute<AdditionalContributionResponse>({
      endpoint: {
        browser: `${REACT_APP_API_PRIVATE_PENSION_PLANS_V1}/planos-previdencia/${certificateNumber}/aporte-adicional`,
        bridge: `${REACT_APP_API_BRIDGE_PRIVATE_PENSION_PLANS_V1}/planos-previdencia/${certificateNumber}/aporte-adicional`,
      },
      method: 'POST',
      headers: {
        browser: {
          'x-inter-ip': '127.1.1.1',
          'x-inter-isafe-token': header.authenticationToken,
          'x-inter-isafe-value': header.authenticationValue,
          'x-inter-isafe-authetication-type': `${header.authenticationType}`, // Force string to prevent error
          'x-inter-isafe-transaction-type': header.transactionType,
          'mag-identifier': 'magidentifier',
          'x-inter-mac-address': `00:00:00:00:00:00`,
          'x-inter-app-version': AppInfoService.superAppVersion,
          'x-inter-so': AppInfoService.deviceName,
          ...MockService.matchHeadersByUserAccount,
        },
        bridge: {
          'x-inter-isafe-token': header.authenticationToken,
          'x-inter-isafe-value': header.authenticationValue,
          'x-inter-isafe-authetication-type': `${header.authenticationType}`, // Force string to prevent error
          'x-inter-isafe-transaction-type': header.transactionType,
        },
      },
      data: body,
    });
  }
}

export default AdditionalContributionService;
