import { AnyAction, Reducer } from 'redux';
import {
  ContributionStrategyType,
  ProposalByFundContributionValuesState,
  ProposalByFundContributionValuesType,
} from './types';

const INITIAL_STATE: ProposalByFundContributionValuesState = {
  strategyContribution: ContributionStrategyType.EMPTY,
  initialValue: 0,
  monthlyValue: 0,
};

const reducer: Reducer<ProposalByFundContributionValuesState> = (
  state = INITIAL_STATE,
  action: AnyAction,
) => {
  switch (action.type) {
    case ProposalByFundContributionValuesType.SET_CONTRIBUTION_VALUES: {
      return action.payload;
    }
    case ProposalByFundContributionValuesType.SET_INITIAL_VALUE: {
      return { ...state, initialValue: action.payload };
    }
    case ProposalByFundContributionValuesType.SET_MONTHLY_VALUE: {
      return { ...state, monthlyValue: action.payload };
    }
    case ProposalByFundContributionValuesType.SET_STRATEGY_CONTRIBUTION: {
      return { ...state, strategyContribution: action.payload };
    }
    case ProposalByFundContributionValuesType.RESET: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
