import { AnyAction } from 'redux';
import { action } from 'typesafe-actions';
import { RootState } from '..';
import { QueryParamsState, QueryParamsTypes } from './types';

const getQueryParams = (state: RootState): QueryParamsState => state.global;

const setQueryParams = (proposalParams: QueryParamsState): AnyAction =>
  action(QueryParamsTypes.SET_QUERY_PARAMS, proposalParams);

const resetState = (): AnyAction => action(QueryParamsTypes.RESET);

const GlobalActions = {
  getQueryParams,
  setQueryParams,
  resetState,
};

export default GlobalActions;
