import {
  InterUIAccordion,
  InterUIButton,
  InterUIIcon,
  InterUITimeline,
} from '@interco/inter-ui-react-lib';
import { TimelineType } from '@interco/inter-ui-react-lib/dist/interfaces/inter-ui-timeline-props';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import PortabilityAnalyticsService from '../../../../analytics/portabilityAnalyticsService';
import FontSizeREM from '../../../../enums/fontSizesRem';
import { PageRoutes } from '../../../../enums/pageRoutes';
import NavbarActions from '../../../../store/navbar/actions';
import PortabilityActions from '../../../../store/portability/actions';
import { PortabilityStatus, Status } from '../../../../store/portabilityStatus/types';
import { ThemeInter } from '../../../../styles';
import Colors from '../../../../styles/colors';
import { Flex, P, SeparatorDashed, Span } from '../../../../styles/commons';
import {
  InterUIAlertCustomized,
  InterUITimelineItemCustomized,
} from '../../../../styles/inter-ui-customizations';
import {
  convertDateToStringPtBR,
  convertStringPtBRToDate,
  getBusinessDaysDateElapsed,
  getWeekDayFromDate,
} from '../../../../utils/dateTimeUtils';
import { formatToBRLCurrency } from '../../../../utils/numberFormatUtils';
import { OutlineCard } from './PortabilityStatusCard.styles';

export interface IPortabilityStatus {
  portabilityStatus: PortabilityStatus;
}

interface PortabilityTimelineSteps {
  title: string;
  type: TimelineType;
  color: string;
  fontColor?: string;
  description?: React.ReactElement;
  icon?: React.ReactElement;
}

const PortabilityStatusCard: React.FC<IPortabilityStatus> = ({ portabilityStatus }) => {
  const theme = useTheme() as ThemeInter;
  const history = useHistory();
  const dispatch = useDispatch();

  const getStatusTitle = (status: Status): string => {
    if (Status.DATA_COLLECTION_COMPLETED === status) {
      return 'Continue de onde parou';
    }
    return status;
  };

  const CardIcon: React.FC<{ status: Status }> = ({ status }) => {
    if (status === Status.APPROVED) {
      return <InterUIIcon size="ld" name="check-circle" color={Colors.pigmentGreen} />;
    }
    if (status === Status.FAILED) {
      return <InterUIIcon size="ld" name="canceled" color={theme.colors.error.A400} />;
    }
    if (status === Status.DATA_COLLECTION_COMPLETED) {
      return <InterUIIcon size="ld" name="play-outline" color={theme.colors.raspberry.A400} />;
    }

    return <InterUIIcon size="ld" name="attention" color={theme.colors.alert.A500} />;
  };

  const CardTitle: React.FC<{ title: Status }> = ({ title }) => (
    <P
      fontSize={FontSizeREM.PX14}
      lineHeight={FontSizeREM.PX17}
      fontWeight={700}
      color={theme.colors.grayscale.A500}
    >
      {getStatusTitle(title)}
    </P>
  );

  const CardSubTitle: React.FC<{ subTitle?: string }> = ({ subTitle }) => (
    <Flex>
      <P
        fontSize={FontSizeREM.PX12}
        lineHeight={FontSizeREM.PX15}
        fontWeight={400}
        color={theme.colors.grayscale.A400}
        margin="0 0 2px"
      >
        {subTitle}
      </P>
    </Flex>
  );

  const goToPortabilityExternalPensionPlans = () => {
    dispatch(PortabilityActions.setPortabilityId(portabilityStatus.id));
    dispatch(NavbarActions.setGoIndex(-1));
    dispatch(
      PortabilityActions.requestPensionPlans({
        history,
        pathname: PageRoutes.PORTABILITY_EXTERNAL_PENSION_PLAN,
      }),
    );
  };

  const cardFooterForDataCollectionComplete: React.ReactElement = (
    <InterUIButton
      type="button"
      variant="secondary"
      onClick={goToPortabilityExternalPensionPlans}
      margin="0"
    >
      Continuar com portabilidade
    </InterUIButton>
  );

  const cardFooterUnderAnalysis: React.ReactElement = (
    <InterUIAlertCustomized
      type="attention"
      alignItems="center"
      style={{ padding: '14.5px 16px 14.5px' }}
    >
      <P
        fontSize={FontSizeREM.PX12}
        lineHeight={FontSizeREM.PX14}
        fontWeight={400}
        color={theme.colors.grayscale.A500}
      >
        Talvez você receba uma ligação do seu Banco de origem para confirmar a sua portabilidade.
      </P>
    </InterUIAlertCustomized>
  );

  const cardFooterApproved: React.ReactElement = (
    <InterUIAlertCustomized
      type="attention"
      alignItems="center"
      style={{ padding: '14.5px 16px 14.5px' }}
    >
      <P
        fontSize={FontSizeREM.PX12}
        lineHeight={FontSizeREM.PX14}
        fontWeight={400}
        color={theme.colors.grayscale.A500}
      >
        Depois de aprovada, a portabilidade da sua previdência pode levar alguns dias para ser
        concluída e estar disponível no app.
      </P>
    </InterUIAlertCustomized>
  );

  const cardHeader: React.ReactElement = (
    <Flex flexDirection="row">
      <Flex flexDirection="column">
        <CardIcon status={portabilityStatus.status} />
      </Flex>

      <Flex flexDirection="column" margin="0 0 0 16px" style={{ alignSelf: 'flex-end' }}>
        <CardTitle title={portabilityStatus.status} />
        <CardSubTitle subTitle={portabilityStatus.name} />
        {portabilityStatus.certificateNumber && (
          <CardSubTitle subTitle={`Certificado: ${portabilityStatus.certificateNumber}`} />
        )}
        {portabilityStatus.accumulatedValues && (
          <CardSubTitle
            subTitle={`Valor: ${formatToBRLCurrency(portabilityStatus.accumulatedValues)}`}
          />
        )}
      </Flex>
    </Flex>
  );

  const elapsedDateByBusinessDays = getBusinessDaysDateElapsed(
    15,
    convertStringPtBRToDate(portabilityStatus.protocoledAt || ''),
  );

  const StepDescriptionStart: React.FC<{ date: string }> = ({ date }) => (
    <>
      <Span
        fontSize={FontSizeREM.PX12}
        lineHeight={FontSizeREM.PX15}
        fontWeight={400}
        color={theme.colors.grayscale.A400}
        textTransform="capitalize"
      >
        {`${getWeekDayFromDate(convertStringPtBRToDate(date || ''))}, `}
      </Span>
      <Span
        fontSize={FontSizeREM.PX12}
        lineHeight={FontSizeREM.PX15}
        fontWeight={400}
        color={theme.colors.grayscale.A400}
      >
        {date}
      </Span>
    </>
  );

  const stepDescriptionUnderAnalysis: React.ReactElement = (
    <Span
      fontSize={FontSizeREM.PX12}
      lineHeight={FontSizeREM.PX15}
      fontWeight={400}
      color={theme.colors.grayscale.A400}
    >
      Você receberá um retorno em até 15 dias úteis.{' '}
      <Span
        fontSize={FontSizeREM.PX12}
        lineHeight={FontSizeREM.PX15}
        fontWeight={400}
        color={theme.colors.grayscale.A400}
        textTransform="capitalize"
      >
        {getWeekDayFromDate(elapsedDateByBusinessDays)},{' '}
      </Span>
      {convertDateToStringPtBR(elapsedDateByBusinessDays)}
    </Span>
  );

  const stepDescriptionResult: React.ReactElement = (
    <Span
      fontSize={FontSizeREM.PX12}
      lineHeight={FontSizeREM.PX15}
      fontWeight={400}
      color={theme.colors.grayscale.A400}
    >
      <Span
        fontSize={FontSizeREM.PX12}
        lineHeight={FontSizeREM.PX15}
        fontWeight={400}
        color={theme.colors.grayscale.A400}
        textTransform="capitalize"
      >
        {portabilityStatus.finishedAt &&
          `${getWeekDayFromDate(convertStringPtBRToDate(portabilityStatus.finishedAt))}, `}
      </Span>
      {portabilityStatus.finishedAt}
    </Span>
  );

  const UNDER_ANALYSIS_STEPS: PortabilityTimelineSteps[] = [
    {
      title: 'Solicitação feita',
      type: 'Start',
      color: Colors.pigmentGreen,
      fontColor: theme.colors.grayscale.A500,
      description: <StepDescriptionStart date={portabilityStatus.protocoledAt || ''} />,
    },
    {
      title: 'Em análise',
      type: 'Warning',
      color: theme.colors.alert.A500,
      fontColor: theme.colors.grayscale.A500,
      icon: <InterUIIcon size="20px" name="attention" color={theme.colors.alert.A500} />,
      description: stepDescriptionUnderAnalysis,
    },
    {
      title: 'Resultado da solicitação',
      type: 'Disabled',
      color: theme.colors.grayscale.A300,
      fontColor: theme.colors.grayscale.A300,
    },
  ];

  const APPROVED_STEPS: PortabilityTimelineSteps[] = [
    {
      title: 'Solicitação feita',
      type: 'Start',
      color: Colors.pigmentGreen,
      fontColor: theme.colors.grayscale.A500,
      description: <StepDescriptionStart date={portabilityStatus.protocoledAt || ''} />,
    },
    {
      title: 'Em análise',
      color: Colors.pigmentGreen,
      fontColor: theme.colors.grayscale.A500,
      type: 'Start',
    },
    {
      title: 'Resultado da solicitação',
      type: 'Success',
      color: Colors.pigmentGreen,
      fontColor: Colors.pigmentGreen,
      icon: <InterUIIcon size="20px" name="check-circle" color={Colors.pigmentGreen} />,
      description: stepDescriptionResult,
    },
  ];

  const FAILED_STEPS: PortabilityTimelineSteps[] = [
    {
      title: 'Solicitação feita',
      type: 'Disabled',
      color: theme.colors.grayscale.A300,
      fontColor: theme.colors.grayscale.A500,
      description: <StepDescriptionStart date={portabilityStatus.protocoledAt || ''} />,
    },
    {
      title: 'Em análise',
      type: 'Disabled',
      color: theme.colors.grayscale.A300,
      fontColor: theme.colors.grayscale.A500,
    },
    {
      title: 'Resultado da solicitação',
      type: 'Error',
      color: theme.colors.error.A400,
      fontColor: theme.colors.error.A400,
      icon: <InterUIIcon size="20px" name="canceled" color={theme.colors.error.A400} />,
      description: stepDescriptionResult,
    },
  ];

  const DATA_COLLECTION_COMPLETED: PortabilityTimelineSteps[] = [
    {
      title: 'Coleta de dados iniciada',
      type: 'Disabled',
      color: theme.colors.raspberry.A400,
      fontColor: theme.colors.grayscale.A500,
      description: <StepDescriptionStart date={portabilityStatus.startedAt || ''} />,
    },
  ];

  const getPortabilityStepsByStatus = (): PortabilityTimelineSteps[] => {
    if (portabilityStatus.status === Status.UNDER_ANALYSIS) {
      return UNDER_ANALYSIS_STEPS;
    }
    if (portabilityStatus.status === Status.APPROVED) {
      return APPROVED_STEPS;
    }
    if (portabilityStatus.status === Status.DATA_COLLECTION_COMPLETED) {
      return DATA_COLLECTION_COMPLETED;
    }
    return FAILED_STEPS;
  };

  const cardStatusClick = () => {
    PortabilityAnalyticsService.cardStatusClick(portabilityStatus.status);
  };

  return (
    <OutlineCard onClick={cardStatusClick}>
      <InterUIAccordion header={cardHeader} margin="0 16px" id="" border="none">
        <SeparatorDashed color={theme.colors.grayscale.A200} margin="0 -24px 16px" />
        <InterUITimeline>
          {getPortabilityStepsByStatus().map((step: PortabilityTimelineSteps, index) => {
            const key = `step-${index}`;
            return (
              <InterUITimelineItemCustomized
                key={key}
                title={step.title}
                type={step.type}
                color={step.color}
                icon={step.icon}
                fontColor={step.fontColor}
              >
                <Span
                  fontSize={FontSizeREM.PX12}
                  lineHeight={FontSizeREM.PX15}
                  fontWeight={400}
                  color={theme.colors.grayscale.A400}
                >
                  {step.description}
                </Span>
              </InterUITimelineItemCustomized>
            );
          })}
        </InterUITimeline>

        {portabilityStatus.status === Status.DATA_COLLECTION_COMPLETED &&
          cardFooterForDataCollectionComplete}
        {portabilityStatus.status === Status.UNDER_ANALYSIS && cardFooterUnderAnalysis}
        {portabilityStatus.status === Status.APPROVED && cardFooterApproved}
      </InterUIAccordion>
    </OutlineCard>
  );
};

export default PortabilityStatusCard;
