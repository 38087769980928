import { Reducer } from 'redux';
import { ErrorState, ErrorTypes, ERROR_MESSAGE_DEFAULT } from './types';

const INITIAL_STATE: ErrorState = {
  hasError: false,
  errorResponse: {
    totalErrors: 1,
    errors: [
      {
        code: 'default_error',
        message: ERROR_MESSAGE_DEFAULT,
      },
    ],
  },
  errorInstance: new Error(),
  isDetailedError: false,
};

export const reducer: Reducer<ErrorState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ErrorTypes.SET_ERROR_RESPONSE: {
      return { ...state, errorResponse: action.payload };
    }
    case ErrorTypes.SET_ERROR_INSTANCE: {
      return { ...state, errorInstance: action.payload };
    }
    case ErrorTypes.SET_IS_DETAILED_ERROR: {
      return { ...state, isDetailedError: action.payload };
    }
    case ErrorTypes.HIDE: {
      return { ...state, hasError: false };
    }
    case ErrorTypes.SHOW: {
      return { ...state, hasError: true };
    }
    case ErrorTypes.RESET: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
