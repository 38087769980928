import { IWbISafeResponse, IWbUserInfo } from 'inter-webview-bridge';
import { IRequestISafe } from '../services/bridgeService';
import { MonitoringService } from '../services/monitoringService';

const { REACT_APP_INTER_ENV } = process.env;

export function logISafeRequest(request: IRequestISafe): void {
  MonitoringService.log('BridgeService.getISafeToken.request', request);
}

export function logISafeResponse(response: IWbISafeResponse): void {
  if (REACT_APP_INTER_ENV === 'production') {
    MonitoringService.log('BridgeService.getISafeToken.response', { result: 'SUCCESS' });
  } else {
    MonitoringService.log('BridgeService.getISafeToken.response', response);
  }
}

export function logUserInfoResponse(response: IWbUserInfo): void {
  if (REACT_APP_INTER_ENV === 'production') {
    MonitoringService.log('BridgeService.getUserInfo.response', { result: 'SUCCESS' });
  } else {
    MonitoringService.log('BridgeService.getUserInfo.response', response);
  }
}
