import { IWbUserInfo } from 'inter-webview-bridge';
import { AnyAction } from 'redux';
import { action } from 'typesafe-actions';
import { SagaWithHistory } from '../../utils/providerSaga';
import { RootState } from '../index';
import { IUserBalanceInfo, IUserSignedTermsInfo, UserInfoTypes } from './types';

const get = (state: RootState): IWbUserInfo => state.user.info;

const set = (info: IWbUserInfo): AnyAction => action(UserInfoTypes.SET_USER_INFO, info);

const getUserBalanceInfo = (state: RootState): IUserBalanceInfo | undefined =>
  state.user.balanceInfo;

const setUserBalanceInfo = (userBalanceInfo: IUserBalanceInfo): AnyAction =>
  action(UserInfoTypes.SET_USER_BALANCE_INFO, userBalanceInfo);

const requestUserBalanceInfo = (data?: SagaWithHistory): AnyAction =>
  action(UserInfoTypes.REQUEST_USER_BALANCE_INFO, data);

const requestHasPrivatePension = (data?: SagaWithHistory): AnyAction =>
  action(UserInfoTypes.REQUEST_USER_HAS_PRIVATE_PENSION, data);

const getUserSignedTerms = (state: RootState): IUserSignedTermsInfo => state.user.signedTerms;

const setUserSignedTerms = (signedTerms: IUserSignedTermsInfo): AnyAction =>
  action(UserInfoTypes.SET_USER_SIGNED_TERMS, signedTerms);

const requestUserSignedTerms = (data?: SagaWithHistory): AnyAction =>
  action(UserInfoTypes.REQUEST_USER_SIGNED_TERMS, data);

const resetState = (): AnyAction => action(UserInfoTypes.RESET);

const UserInfoActions = {
  get,
  set,
  getUserBalanceInfo,
  setUserBalanceInfo,
  requestUserBalanceInfo,
  requestHasPrivatePension,
  getUserSignedTerms,
  setUserSignedTerms,
  requestUserSignedTerms,
  resetState,
};

export default UserInfoActions;
