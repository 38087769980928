import { InvestmentFund } from '../components/InvestmentFundCard/InvestmentFundCard';

export const INITIAL_CONTRIBUTION_MINIMUM = 100;
export const MONTHLY_CONTRIBUTION_MINIMUM = 100;
export const ACCUMULATED_MINIMUM_VALUE = 1000;

// IR FLOW
export const MINIMUM_INCOME_ACCEPTED = 10000;
export const MINIMUM_INCOME_TO_DECLARE = 28559.7;

export const defineMinimumMonthlyContribution = (
  fund: InvestmentFund,
  initial: number,
  monthly: number,
): number => {
  if (
    initial > 0 &&
    monthly > 0 &&
    initial >= fund.minimumInitialContribution &&
    !!fund.minimumDiscountedMonthlyContribution &&
    fund.minimumDiscountedMonthlyContribution > 0
  ) {
    return fund.minimumDiscountedMonthlyContribution;
  }
  return fund.minimumMonthlyContribution;
};
