import React from 'react';
import Colors from '../../../styles/colors';
import { CardStatusColor } from './CardStatus.styles';

export interface ICardStatusProps {
  riskLevel: number;
}

const getRiskColor = (riskLevel: number): string => {
  switch (riskLevel) {
    case 1:
      return Colors.vividBlue;
    case 2:
      return Colors.pigmentGreen;
    case 3:
      return Colors.tangerineYellow;
    case 4:
      return Colors.bitterSweet;
    default:
      return Colors.fireEngineRed;
  }
};

const CardStatus: React.FC<ICardStatusProps> = ({ riskLevel }) => (
  <CardStatusColor color={getRiskColor(riskLevel)} />
);

export default CardStatus;
