import { AnyAction } from 'redux';
import { action } from 'typesafe-actions';
import { RootState } from '..';
import { SagaWithHistory } from '../../utils/providerSaga';
import {
  AdditionalContributionPGBLMethodType,
  PrivatePensionPlan,
  PrivatePensionPlanState,
  PrivatePensionPlanTypes,
} from './types';

const get = (state: RootState): PrivatePensionPlanState => state.privatePensionPlan;

const getPrivatePensionPlan = (state: RootState): PrivatePensionPlan =>
  state.privatePensionPlan.privatePensionPlan;

const setPrivatePensionPlan = (privatePensionPlan: PrivatePensionPlan): AnyAction =>
  action(PrivatePensionPlanTypes.SET_PRIVATE_PENSION_PLAN, privatePensionPlan);

const setAdditionalContributionPGBLMethod = (
  additionalContributionPBGLMethod: AdditionalContributionPGBLMethodType,
): AnyAction =>
  action(
    PrivatePensionPlanTypes.SET_ADDITIONAL_CONTRIBUTION_PGBL_METHOD,
    additionalContributionPBGLMethod,
  );

const getAdditionalContributionPGBLMethod = (
  state: RootState,
): AdditionalContributionPGBLMethodType | undefined =>
  state.privatePensionPlan.additionalContributionPGBLMethod;

const setAnnualIncomeSimulationValue = (
  annualIncomeSimulationValue: number | undefined,
): AnyAction =>
  action(PrivatePensionPlanTypes.SET_ANNUAL_INCOME_SIMULATION_VALUE, annualIncomeSimulationValue);

const getAnnualIncomeSimulationValue = (state: RootState): number | undefined =>
  state.privatePensionPlan.annualIncomeSimulationValue;

const resetState = (): AnyAction => action(PrivatePensionPlanTypes.RESET);

const requestPrivatePensionPlans = (data?: SagaWithHistory): AnyAction =>
  action(PrivatePensionPlanTypes.REQUEST_PRIVATE_PENSION_PLANS, data);

const requestPrivatePensionPlanDeeplink = (data?: SagaWithHistory): AnyAction =>
  action(PrivatePensionPlanTypes.REQUEST_PRIVATE_PENSION_PLANS_DEEP_LINK, data);

const setAdditionalContribution = (additionalContribution: number): AnyAction =>
  action(PrivatePensionPlanTypes.SET_ADDITIONAL_CONTRIBUTION, additionalContribution);

const getAdditionalContribution = (state: RootState): number | undefined =>
  state.privatePensionPlan.additionalContribution;

const setFailedPrivatePensionPlans = (failedPrivatePensionPlans: Array<string>): AnyAction =>
  action(PrivatePensionPlanTypes.SET_FAILED_PRIVATE_PENSION_PLANS, failedPrivatePensionPlans);

const getFailedPrivatePensionPlans = (state: RootState): Array<string> | undefined =>
  state.privatePensionPlan.failedPrivatePensionPlans;

const getAdditionalContributionId = (state: RootState): number =>
  state.privatePensionPlan.additionalContributionId;

const setAdditionalContributionId = (additionalContributionId: number): AnyAction =>
  action(PrivatePensionPlanTypes.SET_ADDITIONAL_CONTRIBUTION_ID, additionalContributionId);

const requestAdditionalContribution = (data?: SagaWithHistory): AnyAction =>
  action(PrivatePensionPlanTypes.REQUEST_ADDITIONAL_CONTRIBUTION, data);

const PrivatePensionPlanActions = {
  get,
  getPrivatePensionPlan,
  setPrivatePensionPlan,
  resetState,
  requestPrivatePensionPlans,
  requestPrivatePensionPlanDeeplink,
  setAdditionalContribution,
  getAdditionalContribution,
  setAdditionalContributionPGBLMethod,
  getAdditionalContributionPGBLMethod,
  setAnnualIncomeSimulationValue,
  getAnnualIncomeSimulationValue,
  setFailedPrivatePensionPlans,
  getFailedPrivatePensionPlans,
  getAdditionalContributionId,
  setAdditionalContributionId,
  requestAdditionalContribution,
};

export default PrivatePensionPlanActions;
