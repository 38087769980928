import React, { ReactElement } from 'react';
import HomeAnalyticsService from '../../../../analytics/homeAnalyticsService';
import FontSizeREM from '../../../../enums/fontSizesRem';
import Colors from '../../../../styles/colors';
import { P } from '../../../../styles/commons';
import { InterAccordionCustomized } from '../../../../styles/inter-ui-customizations';

interface IFaqItem {
  header: string;
  content: ReactElement;
}

const faqItems: Array<IFaqItem> = [
  {
    header: 'O que são os valores iniciais ou mensais no fundo de previdência?',
    content: (
      <P
        fontSize={FontSizeREM.PX12}
        lineHeight={FontSizeREM.PX14}
        color={Colors.shuttleGray}
        fontWeight={400}
      >
        Esses são os valores depositados no plano, seja uma única vez, no caso do inicial, ou a cada
        mês, no caso do mensal. Eles também podem ser chamados de &quot;contribuições&quot; ou
        &quot;aportes&quot;, e seu intuito é aumentar a reserva e, consequentemente, o valor de
        renda a ser recebido quando você decidir resgatar.
      </P>
    ),
  },
  {
    header: 'Qual modalidade escolher, PGBL ou VGBL?',
    content: (
      <>
        <P
          fontSize={FontSizeREM.PX12}
          lineHeight={FontSizeREM.PX14}
          color={Colors.shuttleGray}
          fontWeight={400}
        >
          Essa escolha depende de quanto você quer investir na previdência privada e qual é o seu
          tipo de declaração de imposto de renda.
        </P>
        <P
          fontSize={FontSizeREM.PX12}
          lineHeight={FontSizeREM.PX14}
          color={Colors.shuttleGray}
          fontWeight={400}
        >
          PGBL: Ideal pra quem faz a declaração completa do IR e quer investir até 12% do total da
          sua renda tributável na previdência. Com desconto no Imposto de Renda (IR), o que você
          investiu na previdência (até 12%), é abatido da base de cálculo.
        </P>
        <P
          fontSize={FontSizeREM.PX12}
          lineHeight={FontSizeREM.PX14}
          color={Colors.shuttleGray}
          fontWeight={400}
        >
          VGBL: Ideal pra quem faz a declaração simplificada do IR. O IR incide apenas sobre seus
          rendimentos, e só quando você resgata, lá na frente.
        </P>
      </>
    ),
  },
  {
    header: 'Qual tabela escolher, regressiva ou progressiva?',
    content: (
      <>
        <P
          fontSize={FontSizeREM.PX12}
          lineHeight={FontSizeREM.PX14}
          color={Colors.shuttleGray}
          fontWeight={400}
        >
          Isso depende de qual é seu objetivo com a previdência privada.
        </P>
        <P
          fontSize={FontSizeREM.PX12}
          lineHeight={FontSizeREM.PX14}
          color={Colors.shuttleGray}
          fontWeight={400}
        >
          Regressiva: É a melhor opção pra quem precisa do dinheiro só lá no futuro, no longo prazo.
          O desconto sobre os rendimentos começa em 35% e vai diminuindo ao longo do tempo, até
          chegar em 10%.
        </P>
        <P
          fontSize={FontSizeREM.PX12}
          lineHeight={FontSizeREM.PX14}
          color={Colors.shuttleGray}
          fontWeight={400}
        >
          Progressiva: Indicada pra quem precisa resgatar o valor em pouco tempo. O desconto é
          baseado na tabela progressiva do IR. Vai desde a isenção até 27,5%.
        </P>
      </>
    ),
  },
  {
    header: 'O que é a taxa de administração de um fundo de previdência?',
    content: (
      <P
        fontSize={FontSizeREM.PX12}
        lineHeight={FontSizeREM.PX14}
        color={Colors.shuttleGray}
        fontWeight={400}
      >
        É o percentual cobrado sobre o patrimônio líquido (PL) do fundo de investimento, que
        recepciona os recursos do PGBL ou VGBL. Ele é previamente definido no regulamento do fundo e
        destinado à remuneração do administrador responsável pelos serviços de gestão, consultoria e
        administração dos fundos.
      </P>
    ),
  },
  {
    header: 'Existe carência para solicitação de resgate da minha previdência?',
    content: (
      <>
        <P
          fontSize={FontSizeREM.PX12}
          lineHeight={FontSizeREM.PX14}
          color={Colors.shuttleGray}
          fontWeight={400}
        >
          Após o cumprimento do período de carência da sua previdência, você poderá solicitar o
          resgate dos recursos acumulados, seja ele parcial ou total. Este período de carência é de
          60 dias, podendo variar para fundos qualificados, contados a partir da data de início da
          vigência.
        </P>
        <P
          fontSize={FontSizeREM.PX12}
          lineHeight={FontSizeREM.PX14}
          color={Colors.shuttleGray}
          fontWeight={400}
        >
          Além disso, os resgates devem ser espaçados dentro do intervalo estabelecido no plano, que
          deverá ser entre 60 dias e 6 meses para fundos qualificados. No regulamento de cada plano,
          que você recebe após a contratação, é possível encontrar as informações sobre os prazos de
          carência.
        </P>
      </>
    ),
  },
  {
    header: 'Por que investir em fundos de previdência e não só em fundos de investimentos?',
    content: (
      <P
        fontSize={FontSizeREM.PX12}
        lineHeight={FontSizeREM.PX14}
        color={Colors.shuttleGray}
        fontWeight={400}
      >
        Diferente dos fundos de investimento comuns, o &quot;come-cotas&quot;, que é o imposto
        semestral de 15% ou 20% sobre a rentabilidade, não incide nos fundos de previdência PGBL e
        VGBL. Nos fundos comuns, além do que deve ser pago no momento do resgate, esse imposto come
        uma parte do rendimento obtido, a cada seis meses. Na previdência o imposto só é pago no
        momento do resgate ou recebimento de renda, sem a perda de parte da sua rentabilidade para o
        come-cotas.
      </P>
    ),
  },
];

const renderFAQ = faqItems.map((faqItem, index) => {
  const id = `question-${index}`;
  const border = index < faqItems.length - 1 ? 'top' : 'both';
  return (
    <InterAccordionCustomized
      id={id}
      key={id}
      header={faqItem.header}
      border={border}
      transitionIn="max-height 0.5s cubic-bezier(0, 1, 0, 1)"
      transitionOut="max-height 1s ease-in-out"
      onClick={() => HomeAnalyticsService.privatePensionFAQ(faqItem.header)}
    >
      {faqItem.content}
    </InterAccordionCustomized>
  );
});

const Faq: React.FC = () => (
  <>
    <P
      fontFamily="Sora"
      fontSize={FontSizeREM.PX20}
      lineHeight={FontSizeREM.PX25}
      marginBottom="24px"
    >
      Perguntas frequentes
    </P>
    {renderFAQ}
  </>
);

export default Faq;
