import { InterUISnackbar } from '@interco/inter-ui-react-lib';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SnackbarActions } from '../../store/snackbar/actions';

const Snackbar: React.FC = () => {
  const dispatch = useDispatch();
  const { toggle, message, duration } = useSelector(SnackbarActions.get);
  return (
    <InterUISnackbar
      toggle={toggle}
      duration={duration}
      message={message}
      onHide={() => dispatch(SnackbarActions.hide())}
    />
  );
};

export default Snackbar;
