import { zeroPad } from './numberFormatUtils';

interface YearsAndMonths {
  years: number;
  months: number;
}

export function currentZeroedDate(): Date {
  const zeroedDate = new Date();
  zeroedDate.setHours(0, 0, 0, 0);
  return zeroedDate;
}

export function convertStringPtBRToDate(date: string): Date {
  const dateInf = date.split('/');
  return new Date(parseInt(dateInf[2]), parseInt(dateInf[1]) - 1, parseInt(dateInf[0]));
}

export function convertDateToStringPtBR(date: Date): string {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

export function differenceBetweenDatesInMiliseconds(initialDate: Date, finalDate: Date): number {
  return finalDate.getTime() - initialDate.getTime();
}

export function differenceBetweenDatesInSeconds(initialDate: Date, finalDate: Date): number {
  return differenceBetweenDatesInMiliseconds(initialDate, finalDate) / 1000;
}

export function differenceBetweenDatesInMinutes(initialDate: Date, finalDate: Date): number {
  return differenceBetweenDatesInSeconds(initialDate, finalDate) / 60;
}

export function differenceBetweenDatesInHours(initialDate: Date, finalDate: Date): number {
  return differenceBetweenDatesInMinutes(initialDate, finalDate) / 60;
}

export function differenceBetweenDatesInDays(initialDate: Date, finalDate: Date): number {
  return differenceBetweenDatesInHours(initialDate, finalDate) / 24;
}

export function differenceBetweenDatesInMonths(initialDate: Date, finalDate: Date): number {
  return differenceBetweenDatesInDays(initialDate, finalDate) / 30;
}

export function differenceBetweenDatesInYears(initialDate: Date, finalDate: Date): number {
  return differenceBetweenDatesInMonths(initialDate, finalDate) / 12;
}

export function getMonthsInvestingText(yearsAndMonths: YearsAndMonths): string {
  if (yearsAndMonths.years > 0 && yearsAndMonths.months === 0)
    return `${yearsAndMonths.years} ${yearsAndMonths.years > 1 ? 'anos' : 'ano'}`;
  if (yearsAndMonths.months > 0 && yearsAndMonths.years === 0)
    return `${yearsAndMonths.months} ${yearsAndMonths.months > 1 ? 'meses' : 'mês'}`;

  return `${yearsAndMonths.years} ${yearsAndMonths.years > 1 ? 'anos' : 'ano'} e ${
    yearsAndMonths.months
  } ${yearsAndMonths.months > 1 ? 'meses' : 'mês'}`;
}

export const formatMonthsToYearsAndMonths = (value: number): YearsAndMonths => ({
  years: Math.floor(value / 12),
  months: value % 12,
});

export const calcAge = (birthDate: Date): number => {
  const today = new Date();
  return Math.floor(
    Math.ceil(Math.abs(birthDate.getTime() - today.getTime()) / (1000 * 3600 * 24)) / 365.25,
  );
};

export function getFirstDebitDate(): Date {
  const debitDate = new Date();
  debitDate.setDate(debitDate.getDate() + 1);

  /**
   * The condition check if the next day is sunday.
   * If the condition is true, we sum 1 day to get us to monday
   */
  if (debitDate.getDay() === 0) {
    debitDate.setDate(debitDate.getDate() + 1);
  }

  /**
   * The condition check if the next day is saturday.
   * If the condition is true, we sum 2 days to get us to monday
   */
  if (debitDate.getDay() === 6) {
    debitDate.setDate(debitDate.getDate() + 2);
  }

  return debitDate;
}

export function getSecondDebitDate(firstDebitDate: string, chosenPaymentDay: number): Date {
  /* 
    firstDebitDate is a string in format YYYY-MM-DD
    */
  const startDate = new Date(firstDebitDate);
  const nextDate = new Date(startDate);
  nextDate.setMonth(nextDate.getMonth() + 1);

  if (nextDate.getDate() > chosenPaymentDay) {
    nextDate.setDate(chosenPaymentDay);
    nextDate.setMonth(nextDate.getMonth() + 1);
    if (nextDate.getDate() !== chosenPaymentDay) {
      nextDate.setDate(0);
    }
  }

  if (nextDate.getDate() < chosenPaymentDay) {
    nextDate.setDate(chosenPaymentDay);
    if (nextDate.getDate() !== chosenPaymentDay) {
      nextDate.setDate(0);
    }
  }

  if (startDate.getMonth() === nextDate.getMonth()) {
    nextDate.setMonth(nextDate.getMonth() + 1);
    if (nextDate.getDate() !== chosenPaymentDay) {
      nextDate.setDate(0);
    }
  }

  return nextDate;
}

export const getMonthsToCompleteAnYear = (defaultPaymentDate: number): number => {
  const currentDate = new Date();
  const firstDebitDate = getFirstDebitDate();
  const secondDebitDate = getSecondDebitDate(
    firstDebitDate.toLocaleDateString('en-US'),
    defaultPaymentDate,
  );

  if (currentDate.getFullYear() < firstDebitDate.getFullYear()) {
    return 0;
  }

  if (currentDate.getFullYear() < secondDebitDate.getFullYear()) {
    return 1;
  }

  return 12 - (secondDebitDate.getMonth() + 1) + 2;
};

export const newDateHandler = (dateStr: string): Date | null => {
  const dateHandled = new Date(dateStr);

  if (Number.isNaN(dateHandled.getTime())) {
    return null;
  }

  return dateHandled;
};

/**
 * This function convert seconds to milliseconds
 * @param seconds time value in seconds
 * @returns a number in milliseconds
 */
export const convertSecondsToMillis = (seconds: string): number => {
  const MILLISECONDS = 1000;
  return parseInt(seconds) * MILLISECONDS;
};

/**
 * This function returns the date of the elapsed number of months
 * @param numberOfMonths number of months
 * @returns the date of the elapsed number of months
 */
export const getDateFromNumberOfMonths = (numberOfMonths: number): Date => {
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() + numberOfMonths);
  return currentDate;
};

/**
 * This function return the date elapsed
 * @param numberOfDays number to add the date
 * @param date the start date
 * @returns the elapsed date
 */
export const getDateFromNumberOfDays = (numberOfDays: number, date: Date): Date => {
  date.setDate(date.getDate() + numberOfDays);
  return date;
};

/**
 * This function return the business days date elapsed
 * @param numberOfDays number to add the date
 * @param date the start date
 * @returns the elapsed business days date
 */
export const getBusinessDaysDateElapsed = (numberOfDays: number, date: Date): Date => {
  const saturdayIndex = 6;
  const sundayIndex = 0;
  const dateElapsed = new Date(date);
  let numberOfDaysElapsed = 0;

  while (numberOfDaysElapsed < numberOfDays) {
    dateElapsed.setDate(dateElapsed.getDate() + 1);
    if (dateElapsed.getDay() !== sundayIndex && dateElapsed.getDay() !== saturdayIndex) {
      numberOfDaysElapsed += 1;
    }
  }
  return dateElapsed;
};

/**
 * This function return week day name
 * @param date The Date
 * @returns The week day name
 */
export const getWeekDayFromDate = (date: Date): string =>
  date.toLocaleDateString('pt-BR', { weekday: 'long' }).replace('-feira', '').toLowerCase();

export const getRetirementAge = (birthDate: Date, retirementDate: Date): number =>
  Math.floor(
    Math.ceil(Math.abs(birthDate.getTime() - retirementDate.getTime()) / (1000 * 3600 * 24)) /
      365.25,
  );

export function toIsoDateFormat(date: Date): string {
  const year = date.getFullYear();
  const month = zeroPad(date.getMonth() + 1, 2); // months go from 0 to 11 that's why it's necessary to add 1
  const day = zeroPad(date.getDate(), 2);
  return `${year}-${month}-${day}`;
}
