import { InterUIRadio } from '@interco/inter-ui-react-lib';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FontSizeREM from '../../../../enums/fontSizesRem';
import RetirementPageActions from '../../../../store/retirement/actions';
import { Div, PSmallBold, PSmallGray } from '../../../../styles/commons';
import {
  INITIAL_CONTRIBUTION_MINIMUM,
  MONTHLY_CONTRIBUTION_MINIMUM,
} from '../../../../utils/minValues';

interface IContributionStrategy {
  id: number;
  type: string;
  title: string;
  subtitle: string;
}

export const ContributionStrategyType = {
  INITIAL_CONTRIBUTION: 'INITIAL_CONTRIBUTION',
  MONTHLY_CONTRIBUTION: 'MONTHLY_CONTRIBUTION',
  INITIAL_MONTHLY_CONTRIBUTION: 'INITIAL_MONTHLY_CONTRIBUTION',
};

const strategies: Array<IContributionStrategy> = [
  {
    id: 1,
    type: ContributionStrategyType.INITIAL_CONTRIBUTION,
    title: 'Um valor único',
    subtitle: 'Você define um valor que será investido uma única vez, logo no início',
  },
  {
    id: 2,
    type: ContributionStrategyType.MONTHLY_CONTRIBUTION,
    title: 'A cada mês',
    subtitle: 'Você define o valor que será investido mensalmente',
  },
  {
    id: 3,
    type: ContributionStrategyType.INITIAL_MONTHLY_CONTRIBUTION,
    title: 'Um valor inicial + a cada mês',
    subtitle: 'Um valor único no início + valores mensais',
  },
];

export const ContributionStrategyOptions: React.FC = () => {
  const dispatch = useDispatch();
  const contributionStrategy = useSelector(RetirementPageActions.getContributionStrategy);
  const [choosedRadio, setChoosedRadio] = useState<string>(contributionStrategy);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChoosedRadio(e.target.value);
  };

  useEffect(() => {
    dispatch(RetirementPageActions.setContributionStrategy(choosedRadio));
    switch (choosedRadio) {
      case ContributionStrategyType.INITIAL_CONTRIBUTION: {
        dispatch(RetirementPageActions.setInitialMinimum(INITIAL_CONTRIBUTION_MINIMUM));
        dispatch(RetirementPageActions.setMonthlyMinimum(0));
        dispatch(RetirementPageActions.setMonthlyContribution(0));
        break;
      }
      case ContributionStrategyType.MONTHLY_CONTRIBUTION: {
        dispatch(RetirementPageActions.setInitialMinimum(0));
        dispatch(RetirementPageActions.setMonthlyMinimum(MONTHLY_CONTRIBUTION_MINIMUM));
        dispatch(RetirementPageActions.setInitialContribution(0));
        break;
      }
      case ContributionStrategyType.INITIAL_MONTHLY_CONTRIBUTION: {
        dispatch(RetirementPageActions.setInitialMinimum(INITIAL_CONTRIBUTION_MINIMUM));
        dispatch(RetirementPageActions.setMonthlyMinimum(MONTHLY_CONTRIBUTION_MINIMUM));
        break;
      }
      default:
        break;
    }
  }, [choosedRadio, dispatch]);

  return (
    <Div data-testid="radio-container">
      {strategies.map((strategy) => {
        const key = `strategy-${strategy.id}`;
        return (
          <InterUIRadio
            variant="choose-item"
            data-testid={key}
            key={key}
            id={key}
            name="strategy"
            value={strategy.type}
            onChange={(e) => onChange(e)}
            checked={choosedRadio === `${strategy.type}`}
          >
            <PSmallBold marginBottom="4px" maxWidth="115px">
              {strategy.title}
            </PSmallBold>
            <PSmallGray
              maxWidth="183px"
              fontSize={FontSizeREM.PX12}
              lineHeight={FontSizeREM.PX15}
              marginBottom="0px"
            >
              {strategy.subtitle}
            </PSmallGray>
          </InterUIRadio>
        );
      })}
    </Div>
  );
};
