import { InterUIButton, InterUIContainer } from '@interco/inter-ui-react-lib';
import React, { useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import PortabilityAnalyticsService from '../../analytics/portabilityAnalyticsService';
import SuccessImg from '../../assets/success/Success.png';
import { EnvVariableKeys } from '../../enums/evironmentVariableKeys';
import FontSizeREM from '../../enums/fontSizesRem';
import { LocalStorageKeys } from '../../enums/localStorageKeys';
import { PageRoutes } from '../../enums/pageRoutes';
import PageTitles from '../../enums/pageTitles';
import { useRemoveScript } from '../../hooks/useRemoveScript';
import BridgeService from '../../services/bridgeService';
import { EnvVariableService } from '../../services/environmentVariableService';
import LocalStorageService from '../../services/localStorageService';
import ErrorActions from '../../store/error/actions';
import { HireProposalActions } from '../../store/hire/actions';
import { LoadingActions } from '../../store/loading/actions';
import NavbarActions from '../../store/navbar/actions';
import PortabilityActions from '../../store/portability/actions';
import { ProposalDetailActions } from '../../store/proposal/actions';
import { ThemeInter } from '../../styles';
import { Flex, H1, P, SeparatorDashed, Span } from '../../styles/commons';
import { InterUIAlertCustomized } from '../../styles/inter-ui-customizations';
import {
  convertDateToStringPtBR,
  formatMonthsToYearsAndMonths,
  getDateFromNumberOfMonths,
  getMonthsInvestingText,
} from '../../utils/dateTimeUtils';
import { _try } from '../../utils/errorUtils';

interface IContractionItem {
  label: string;
  value?: string;
}

const PortabilityContractionReceiptPage: React.FC = () => {
  const dispatch = useDispatch();
  const removeScript = useRemoveScript();
  const history = useHistory();
  const theme = useTheme() as ThemeInter;
  const handleError = useErrorHandler();
  const loading = useSelector(LoadingActions.get);
  const chosenProposalId = useSelector(HireProposalActions.getChosenProposalId);
  const currentFund = useSelector(ProposalDetailActions.getInvestmentFund);
  const currentMonthsInvesting = useSelector(ProposalDetailActions.getNumberMonthsInvesting);
  const selectedInstitution = useSelector(PortabilityActions.getFinancialInstitution);
  const BELVO_SCRIPT = EnvVariableService.getVariable(EnvVariableKeys.BELVO_SCRIPT);

  const text = {
    title: 'Solicitação recebida!',
    messageContent:
      'Tudo certo até aqui! Você vai receber um e-mail com os próximos passos. Pode contar com nossa ajuda sempre que precisar!',
    messageFooter:
      'Em breve, você vai aproveitar todos os benefícios de ter sua Previdência com a gente!',
  };

  const goToAppHome = () =>
    _try(
      async () => {
        await BridgeService.goToAppHomeOrBackToNative(history, PageRoutes.HOME);
      },
      handleError,
      'PortabilityContractionReceiptPage.goToAppHome',
    );

  const goToSelectFinancialInstitution = () => {
    dispatch(NavbarActions.setCanGoBack(false));
    if (!LocalStorageService.hasExpired(LocalStorageKeys.FINANCIAL_INSTITUTIONS)) {
      history.push(PageRoutes.PORTABILITY_SELECT_FINANCIAL_INSTITUTION);
      return;
    }

    if (loading) return;
    dispatch(PortabilityActions.resetState());
    dispatch(
      PortabilityActions.requestFinancialInstitutions({
        history,
        pathname: PageRoutes.PORTABILITY_SELECT_FINANCIAL_INSTITUTION,
      }),
    );
  };

  useEffect(() => {
    PortabilityAnalyticsService.contractionReceipt(selectedInstitution?.name as string);
    if (BELVO_SCRIPT) {
      removeScript(BELVO_SCRIPT);
    }
  });

  const ContractionItem: React.FC<IContractionItem> = ({ label, value }) => (
    <Flex flexDirection="row" justifyContent="space-between" width="100%" alignItems="center">
      <Flex marginBottom="16px">
        <Span
          fontWeight={400}
          fontSize={FontSizeREM.PX12}
          lineHeight={FontSizeREM.PX14}
          color={theme.colors.grayscale.A400}
        >
          {label}
        </Span>
      </Flex>
      <Flex marginBottom="16px">
        <Span
          fontWeight={700}
          fontSize={FontSizeREM.PX12}
          lineHeight={FontSizeREM.PX14}
          color={theme.colors.grayscale.A500}
        >
          {value}
        </Span>
      </Flex>
    </Flex>
  );
  useEffect(
    () => {
      dispatch(NavbarActions.setTitle(PageTitles.PORTABILITY_CONTRACTION_RECEIPT));
      dispatch(ErrorActions.resetState());
    }, // Stryker disable next-line all

    [dispatch],
  );

  const stickyFooter = (
    <>
      <InterUIButton margin="0 0 16px 0" onClick={goToAppHome}>
        Voltar para home
      </InterUIButton>
      <InterUIButton
        margin="0 0 16px 0"
        variant="secondary"
        onClick={goToSelectFinancialInstitution}
        loading={loading}
      >
        Solicitar nova Portabilidade
      </InterUIButton>
    </>
  );

  return (
    <InterUIContainer stickyFooter={stickyFooter}>
      <Flex alignItems="center">
        <img
          style={{ width: '64px', height: '64px', margin: '0 16px 24px' }}
          src={SuccessImg}
          alt="Símbolo de sucesso"
        />
      </Flex>

      <H1 marginBottom="8px" color={theme.colors.grayscale.A500}>
        {text.title}
      </H1>

      <P
        fontWeight={400}
        fontSize={FontSizeREM.PX14}
        lineHeight={FontSizeREM.PX17}
        color={theme.colors.grayscale.A400}
        margin="0 0 16px"
      >
        {text.messageContent}
      </P>

      <P
        fontWeight={400}
        fontSize={FontSizeREM.PX14}
        lineHeight={FontSizeREM.PX17}
        color={theme.colors.grayscale.A400}
        margin="0 0 16px"
      >
        {text.messageFooter}
      </P>

      <P
        fontWeight={400}
        fontSize={FontSizeREM.PX14}
        lineHeight={FontSizeREM.PX17}
        color={theme.colors.grayscale.A400}
        marginBottom="16px"
      >
        Número da sua solicitação: <strong>{chosenProposalId}</strong>
      </P>

      <SeparatorDashed margin="24px 0" color={theme.colors.grayscale.A200} />

      <InterUIAlertCustomized
        type="attention"
        margin="0 0 16px"
        style={{ padding: '14.5px 16px 14.5px' }}
      >
        <P
          fontSize={FontSizeREM.PX12}
          lineHeight={FontSizeREM.PX14}
          fontWeight={700}
          color={theme.colors.grayscale.A500}
          margin="0 0 4px"
        >
          Próximos passos...
        </P>
        <P
          fontSize={FontSizeREM.PX12}
          lineHeight={FontSizeREM.PX14}
          fontWeight={400}
          color={theme.colors.grayscale.A500}
        >
          Talvez você receba uma ligação do seu Banco de origem para confirmar a sua portabilidade.
        </P>
      </InterUIAlertCustomized>

      <P
        fontWeight={700}
        fontSize={FontSizeREM.PX14}
        lineHeight={FontSizeREM.PX17}
        color={theme.colors.grayscale.A500}
        marginBottom="16px"
      >
        Plano de destino
      </P>

      <ContractionItem label="Fundo escolhido" value={currentFund.name} />

      <ContractionItem label="CNPJ do fundo" value={currentFund.cnpj} />

      <ContractionItem
        label="Prazo de aposentadoria"
        value={getMonthsInvestingText(formatMonthsToYearsAndMonths(currentMonthsInvesting))}
      />

      <ContractionItem
        label="Data da aposentadoria"
        value={convertDateToStringPtBR(getDateFromNumberOfMonths(currentMonthsInvesting))}
      />
    </InterUIContainer>
  );
};

export default PortabilityContractionReceiptPage;
