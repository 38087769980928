export type BelvoAccessModeType = 'single' | 'recurrent';
export type BelvoInstitutionType = 'business' | 'retail' | 'fiscacl' | 'gig';
export type BelvoLocaleType = 'es' | 'en' | 'pt';
export type BelvoCountryType = 'MX' | 'CO' | 'BR';

export enum BelvoSupportedInstitution {
  BANCO_DO_BRASIL = 'bancodobrasil_br_retail',
  ITAU = 'itau_br_retail',
  FICTIONAL_INSTITUTION = 'erebor_br_retail',
  EMPTY = '',
}

export enum BelvoEventName {
  APP_CREATED = 'APP_CREATED',
  PAGE_LOAD = 'PAGE_LOAD',
  CLOSE_ATTEMPT = 'CLOSE_ATTEMPT',
  ERROR = 'ERROR',
}

export interface BelvoEventMetaData {
  from?: string;
  page?: string;
  error_code?: string;
  error_message?: string;
  institution_name?: string;
  timestamp?: Date;
}

export interface BelvoLastEncounteredError {
  code: string;
  message: string;
}

export interface BelvoExitMetaData {
  institution_name: string;
  step: string;
}

export interface BelvoEventResponse {
  /**
   * A string representing the name of event that has just occurred in the widget.
   */
  eventName: BelvoEventName;

  /**
   * An object containing more information about the event that has just occurred in the widget.
   */
  meta_data: BelvoEventMetaData;

  /**
   * A string used to uniquely identify the error.
   */
  request_id?: string;
}

export interface BelvoExitResponse {
  /**
   * The object is only sent if an error occurred.
   */
  last_encountered_error: BelvoLastEncounteredError | null;

  /**
   * An object containing more information about user exit.
   */
  meta_data: BelvoExitMetaData;
}

export interface BelvoWidgetConfig {
  /**
   * Define if are show Belvo intro screen(Belvo onboarding screen)
   * @default true
   */
  show_intro?: boolean;

  /**
   * Define the type of link the widget will create
   * For more detail about the types of links
   * @link <https://developers.belvo.com/docs/links-and-institutions#links>
   * @default recurrent
   */
  access_mode?: BelvoAccessModeType;

  /**
   * Additional identifier to be associated with the link in the Belvo database.
   *
   * Originally this field was not required, but as a good practice we should provide it.
   * @required
   */
  exteral_id: string;

  /**
   * Define which countries the user can select.
   * By default the widget displays all supported countries.
   * @default ['MX','CO','BR']
   * @see BelvoCountryType
   */
  country_codes?: BelvoCountryType[];

  /**
   * Define which institutions the user can connect to.
   * By default the widget displays all the supported institutions
   * @link <https://developers.belvo.com/docs/institution>
   */
  institutions?: string[];

  /**
   * Define the institutions supported resources
   */
  resources?: string[];

  /**
   * Define what institution types to show in the widget.
   * By default the widget displays all the supported institution types
   * @default ['business','retail','fiscacl','gig']
   * @see BelvoInstitutionType
   */
  institution_types?: BelvoInstitutionType[];

  /**
   * Define what language the widget should display.
   * @default 'es'
   */
  locale?: BelvoLocaleType;

  /**
   * Once a user successfully connects their account, Belvo sends a data object with the institution
   * and the link ID (which you'll need in your further requests for data).
   * @param link the link_id created in the widget
   * @param institution the name of the institution for this creeated link
   * @required
   */
  callback(link: string, institution: string): void;

  /**
   * When users encounter certain events in the widget, Belvo'll send some data to explain what's going on.
   * @param data the response data of the event triggered
   * @required
   */
  onEvent(data: BelvoEventResponse): void;

  /**
   * When users decide to close the widget, Belvo'll send some data to explain what's going on.
   * @param data the response data for when the user close the widget
   * @required
   */
  onExit(data: BelvoExitResponse): void;
}

export interface BelvoWidget {
  /** Build an widget */
  build(): void;
}

interface BelvoSDK {
  /**
   * Create and configure the widget
   * @param access the access_token used to connect with the widget
   * @param config the configurations of the widget
   */
  createWidget(access: string, config: BelvoWidgetConfig): BelvoWidget;
}

export default BelvoSDK;
