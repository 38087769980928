import React from 'react';
import { useSelector } from 'react-redux';
import { LoadingActions } from '../../store/loading/actions';
import { OverlayBlock } from './Overlay.styles';

const Overlay: React.FC = () => {
  const loading = useSelector(LoadingActions.get);

  return <OverlayBlock show={loading} />;
};

export default Overlay;
