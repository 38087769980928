export const formatToBRLCurrency = (value: number): string =>
  new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);

export const formatToPercent = (
  value: number,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
): string =>
  new Intl.NumberFormat('pt-BR', {
    style: 'percent',
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value / 100);

export function formatCertificateNumber(certificateNumber: string | number): string {
  const LENGTH_CERTIFICATE = 12;
  return String(certificateNumber).padStart(LENGTH_CERTIFICATE, '0');
}

export function zeroPad(num: number, places: number): string {
  return String(num).padStart(places, '0');
}
