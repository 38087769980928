import AnalyticsBaseEventName from '../enums/analyticsBaseEventNames';
import BridgeService from '../services/bridgeService';
import { IPortabilityInformation } from './types';

class PortabilityAnalyticsService {
  private static className = 'PortabilityAnalyticsService';

  static requestPortabilityButton(): void {
    BridgeService.requestAnalytics(
      `${this.className}.requestPortabilityButton`,
      `${AnalyticsBaseEventName.PORTABILITY}Home_T_PedirPortabilidade`,
    );
  }

  static portabilityConfirmation({ price, content_id }: IPortabilityInformation): void {
    BridgeService.requestAnalytics(
      `${this.className}.portabilityConfirmation`,
      `${AnalyticsBaseEventName.PORTABILITY}PrevidAtual_T_Solicitar`,
      {
        price,
        currency: 'BRL',
        content_id,
      },
    );
  }

  static requestPortabilitySuccess({ price, content_id }: IPortabilityInformation): void {
    BridgeService.requestAnalytics(
      `${this.className}.requestPortabilitySuccess`,
      `${AnalyticsBaseEventName.PORTABILITY}Solicitacao_DC_Sucesso`,
      {
        price,
        currency: 'BRL',
        content_id,
      },
    );
  }

  static portabilityStart(content_id: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.portabilityStart`,
      `${AnalyticsBaseEventName.AUTO_PORTABILITY}Home_DC_Inicio`,
      {
        content_id,
      },
    );
  }

  static benefitItem(content_name?: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.benefitItem`,
      `${AnalyticsBaseEventName.AUTO_PORTABILITY}Home_T_Beneficios`,
      content_name ? { content_name } : undefined,
    );
  }

  static bringPortabilityButton(): void {
    BridgeService.requestAnalytics(
      `${this.className}.bringPortabilityButton`,
      `${AnalyticsBaseEventName.AUTO_PORTABILITY}Home_T_PedirPortab`,
    );
  }

  static financialInstitutionContinueButton(content_name: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.financialInstitutionContinueButton`,
      `${AnalyticsBaseEventName.AUTO_PORTABILITY}Banco_T_Continuar`,
      { content_name },
    );
  }

  static accumulatedValueButton(valor: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.accumulatedValueButton`,
      `${AnalyticsBaseEventName.AUTO_PORTABILITY}Valor_T_Solicitar`,
      { valor },
    );
  }

  static contractionReceipt(content_name: string, valor?: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.contractionReceipt`,
      `${AnalyticsBaseEventName.AUTO_PORTABILITY}Comprovante_DC_Contratado`,
      valor ? { content_name, valor } : { content_name },
    );
  }

  static onboardingNextButton(): void {
    BridgeService.requestAnalytics(
      `${this.className}.onboardingNextButton`,
      `${AnalyticsBaseEventName.AUTO_PORTABILITY}Onboarding_T_Proximo`,
    );
  }

  static onboardingOptionButton(content_name: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.onboardingOptionButton`,
      `${AnalyticsBaseEventName.AUTO_PORTABILITY}Onboarding_T_Opcao`,
      { content_name },
    );
  }

  static queryPensionPlansLoading(content_id: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.queryPensionPlansLoading`,
      `${AnalyticsBaseEventName.AUTO_PORTABILITY}DC_Loading`,
      { content_id },
    );
  }

  static externalPensionPlanContinueButton(content_id: string, content_name: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.externalPensionPlanContinueButton`,
      `${AnalyticsBaseEventName.AUTO_PORTABILITY}Selectprevi_T_Continuar`,
      { content_id, content_name },
    );
  }

  static proposalContinueButton(content_name: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.proposalContinueButton`,
      `${AnalyticsBaseEventName.AUTO_PORTABILITY}SelectFundo_T_Continuar`,
      { content_name },
    );
  }

  static contributionTimeDefineButton(content_id: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.contributionTimeDefineButton`,
      `${AnalyticsBaseEventName.AUTO_PORTABILITY}DataSaída_T_Definir`,
      { content_id },
    );
  }

  static revisionConfirmButton(content_id: string, content_name: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.revisionConfirmButton`,
      `${AnalyticsBaseEventName.AUTO_PORTABILITY}Revisao_T_Continuar`,
      { content_id, content_name },
    );
  }

  static delayBottomSheetAction(content_name: string, content_id?: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.delayBottomSheetAction`,
      `${AnalyticsBaseEventName.AUTO_PORTABILITY}Demorando_T_Opcao`,
      content_id ? { content_id, content_name } : { content_name },
    );
  }

  static homePortabilityButton(content_id: string, content_name: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.homePortabilityButton`,
      `${AnalyticsBaseEventName.AUTO_PORTABILITY}Home_T_Opcao`,
      { content_id, content_name },
    );
  }

  static cardStatusClick(content_id: string, content_name?: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.cardStatusClick`,
      `${AnalyticsBaseEventName.AUTO_PORTABILITY}CardStatus_T_Clique`,
      content_name ? { content_id, content_name } : { content_id },
    );
  }

  static newPortabilityButton(): void {
    BridgeService.requestAnalytics(
      `${this.className}.newPortabilityButton`,
      `${AnalyticsBaseEventName.AUTO_PORTABILITY}Status_T_NovaPortab`,
    );
  }

  static babi(): void {
    BridgeService.requestAnalytics(
      `${this.className}.babi`,
      `${AnalyticsBaseEventName.AUTO_PORTABILITY}Home_T_Babi`,
    );
  }

  static benefitsBottomSheetHireButton(content_id: string): void {
    BridgeService.requestAnalytics(
      `${this.className}.benefitsBottomSheetHireButton`,
      `${AnalyticsBaseEventName.AUTO_PORTABILITY}Detalhes_T_Contratar`,
      { content_id },
    );
  }
}
export default PortabilityAnalyticsService;
