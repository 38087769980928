import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import NavbarActions from '../store/navbar/actions';
import scrollToTop from '../utils/navigationUtils';
import SimulationRoutes from './simulation.routes';
import './transition-style.css';

const Routes: React.FC = () => {
  const [direction, setDirection] = React.useState('pageSliderLeft');

  const goTo = useSelector(NavbarActions.getDirection);

  useEffect(() => {
    setDirection(goTo === 'backward' ? 'pageSliderRight' : 'pageSliderLeft');
  }, [direction, goTo]);

  const scrollTop = () => {
    scrollToTop();
  };

  return (
    <Route
      render={({ location }) => (
        <TransitionGroup>
          <CSSTransition
            onEnter={scrollTop}
            timeout={250}
            key={location.key}
            classNames={direction}
          >
            <SimulationRoutes />
          </CSSTransition>
        </TransitionGroup>
      )}
    />
  );
};

export default withRouter(Routes);
