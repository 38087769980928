import { EnvVariableKeys } from '../enums/evironmentVariableKeys';

export class EnvVariableService {
  static getVariable(key: EnvVariableKeys, fallback?: string): string | undefined {
    return process.env[key] || fallback;
  }

  /**
   * A function to convert any env variable to boolean
   * @param key the key of the variable in the @enum EnvVariableKeys
   * @param assertExpression an value to expect in the variable
   * @returns @val true only when the env value is equal to
   * the @param assertExpression or the string 'true'
   */
  static getVariableAsBoolean(key: EnvVariableKeys, assertExpression?: string): boolean {
    const env = EnvVariableService.getVariable(key);
    return env ? env.toLowerCase() === (assertExpression || 'true') : false;
  }
}
