export enum SelectableFundsTypes {
  REQUEST_AVAILABLE_FUNDS = `@funds/REQUEST_AVAILABLES_FUNDS`,
  REQUEST_ALL_AVAILABLE_FUNDS = `@funds/REQUEST_ALL_AVAILABLE_FUNDS`,
  SET_SORTTER = `@funds/SET_SORTTER`,
  RESET = `@funds/RESET`,
}

export interface SelectableFundsState {
  readonly sortter: FundSortterType;
}
export type FundSortterType = 'Grau de risco' | 'Rentabilidade' | 'Padrão';
