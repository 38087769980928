enum AnalyticsBaseEventName {
  ADDITIONAL_CONTRIBUTION = 'C_AporteESP_',
  DEEPLINK = 'C_PrevidPrivada_Prop_Deeplink',
  PRIVATE_PENSION = 'C_PrevidPrivada_',
  IR_INCOME_EXPENSE = 'C_IR_Seu Cálculo_',
  IR_INCOME_TAX = 'C_IR_Seu Jeito_',
  PORTABILITY = 'C_PortabPrevid_',
  AUTO_PORTABILITY = 'C_PortaPrevi_',
  PROPOSAL = 'C_PrevidPrivada_DetaPlano_',
  RECEIPT = 'C_PrevidPrivada_Comprovante_',
  RETIREMENT = 'C_PrevidPrivada_Aposetand_',
  REVISION = 'C_PrevidPrivada_Revisao_',
  INVESTMENTS = 'C_Invest_Prop_Deeplink',
  HIRE_PROPOSAL_ACCEPTANCE = 'C_Aceite_Proposta',
}

export default AnalyticsBaseEventName;
