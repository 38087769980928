import { Reducer } from 'redux';
import { LoadingState, LoadingTypes } from './types';

const INITIAL_STATE: LoadingState = {
  toggle: false,
  elementId: '',
};

export const reducer: Reducer<LoadingState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LoadingTypes.SET_TOGGLE_ON:
      return { ...state, toggle: true };
    case LoadingTypes.SHOW:
      return { toggle: true, elementId: action.payload };
    case LoadingTypes.HIDE:
      return { toggle: false, elementId: '' };
    default:
      return state;
  }
};

export default reducer;
