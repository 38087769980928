/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { LocalStorageKeys } from '../enums/localStorageKeys';

export interface LocalStorageRequest<T> {
  data: T;
  requestDate: Date;
  expiresAt: Date;
}

class LocalStorageService {
  static setItem<T>(key: LocalStorageKeys, item: LocalStorageRequest<T>, sufix?: string): void {
    localStorage.setItem(sufix ? `${key}-${sufix}` : key, btoa(JSON.stringify(item)));
  }

  static getItem<T>(key: LocalStorageKeys, sufix?: string): LocalStorageRequest<T> | undefined {
    const item = localStorage.getItem(sufix ? `${key}-${sufix}` : key);
    if (item !== null && item !== undefined) {
      return JSON.parse(atob(item));
    }
    return undefined;
  }

  static removeItem(key: LocalStorageKeys, sufix?: string): void {
    localStorage.removeItem(sufix ? `${key}-${sufix}` : key);
  }

  static hasItem(key: LocalStorageKeys, sufix?: string): boolean {
    const item = localStorage.getItem(sufix ? `${key}-${sufix}` : key);
    return item !== null && item !== undefined;
  }

  static hasExpired(key: LocalStorageKeys, sufix?: string): boolean {
    const now = new Date();
    const lastRequest = LocalStorageService.getItem<unknown>(key, sufix);

    if (!lastRequest) {
      return true;
    }

    if (!lastRequest.expiresAt) {
      return true;
    }

    const expiresAt = new Date(lastRequest.expiresAt);

    if (now > expiresAt) {
      return true;
    }

    return false;
  }

  static clearExpiredItems(sufix: string): void {
    Object.values(LocalStorageKeys).forEach((value) => {
      if (LocalStorageService.hasItem(value) && LocalStorageService.hasExpired(value)) {
        LocalStorageService.removeItem(value);
      }
      if (
        LocalStorageService.hasItem(value, sufix) &&
        LocalStorageService.hasExpired(value, sufix)
      ) {
        LocalStorageService.removeItem(value, sufix);
      }
    });
  }
}

export default LocalStorageService;
