import { InterUIButton, InterUIContainer } from '@interco/inter-ui-react-lib';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import SuccessImg from '../../assets/success/Success.png';
import FontSizeREM from '../../enums/fontSizesRem';
import PageTitles from '../../enums/pageTitles';
import NavbarActions from '../../store/navbar/actions';
import { ThemeInter } from '../../styles';
import Colors from '../../styles/colors';
import { Div, Flex, H1, P } from '../../styles/commons';

export interface IReceiptPageProps {
  pageTitle: PageTitles;
  title: string;
  text: string;
  redirectTo: string;
}

const CancelReceiptPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme() as ThemeInter;
  const { pageTitle, title, text, redirectTo } = history.location.state as IReceiptPageProps;

  useEffect(() => {
    dispatch(NavbarActions.setTitle(pageTitle));
  }, [dispatch, pageTitle]);

  const stickyFooter: React.ReactElement = (
    <InterUIButton
      type="button"
      onClick={() => {
        history.push(redirectTo);
      }}
      margin="16px 0px 0px 0px"
      variant="primary"
    >
      Voltar para home
    </InterUIButton>
  );

  return (
    <InterUIContainer stickyFooter={stickyFooter}>
      <Flex alignItems="center" marginBottom="32px">
        <img
          style={{ width: '64px', height: '64px', margin: '25px 0px 15px 0px' }}
          src={SuccessImg}
          alt="Símbolo de sucesso"
        />
      </Flex>
      <Div>
        <H1 marginBottom="12px">{title}</H1>
        <P
          fontSize={FontSizeREM.PX14}
          lineHeight={FontSizeREM.PX17}
          fontWeight={400}
          color={theme.colors.grayscale.A400}
          marginBottom="45px"
        />
        <P
          fontSize={FontSizeREM.PX14}
          lineHeight={FontSizeREM.PX17}
          fontWeight={400}
          color={Colors.shuttleGray}
          marginBottom="24px"
        >
          {text}
        </P>
      </Div>
    </InterUIContainer>
  );
};

export default CancelReceiptPage;
