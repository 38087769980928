/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { PrivatePensionPlan } from '../store/privatePensionPlan/types';
import { BaseService, ServiceResponse } from './baseService';
import { MockService } from './mockService';

const {
  REACT_APP_API_PRIVATE_PENSION_PLANS_V1,
  REACT_APP_API_BRIDGE_PRIVATE_PENSION_PLANS_V1,
} = process.env;

class PrivatePensionPlanService {
  static async getPrivatePensionPlans(): Promise<ServiceResponse<PrivatePensionPlan[]>> {
    return BaseService.doExecute({
      endpoint: {
        browser: `${REACT_APP_API_PRIVATE_PENSION_PLANS_V1}/planos-previdencia`,
        bridge: `${REACT_APP_API_BRIDGE_PRIVATE_PENSION_PLANS_V1}/planos-previdencia`,
      },
      headers: {
        browser: MockService.matchHeadersByUserAccount,
      },
      method: 'GET',
    });
  }
}
export default PrivatePensionPlanService;
