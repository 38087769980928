/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/unbound-method */
import { AnyAction } from 'redux';
import { call, put, SagaGenerator, select, takeLeading } from 'typed-redux-saga';
import { InvestmentFund } from '../../components/InvestmentFundCard/InvestmentFundCard';
import { LocalStorageKeys } from '../../enums/localStorageKeys';
import AvailableFundsService from '../../services/availableFundsService';
import LocalStorageService, { LocalStorageRequest } from '../../services/localStorageService';
import { errorHandlingSaga, redirectThroughSaga } from '../../utils/providerSaga';
import { LoadingActions } from '../loading/actions';
import { ProposalDetailActions } from '../proposal/actions';
import { SelectableFundsTypes } from './types';

function* requestAvailablesFunds({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show());

    const qualifiedCustomer = yield* select(ProposalDetailActions.getQualifiedCustomer);

    const response = yield* call(AvailableFundsService.getAvailableFunds, qualifiedCustomer);

    const expiresAt = new Date();
    expiresAt.setDate(expiresAt.getDate() + 1);
    expiresAt.setHours(0, 0, 0, 0);

    const storeFund: LocalStorageRequest<InvestmentFund[]> = {
      data: response.data,
      requestDate: new Date(),
      expiresAt,
    };

    LocalStorageService.setItem(LocalStorageKeys.AVAILABLE_FUNDS, storeFund);

    yield* put(LoadingActions.hide());

    redirectThroughSaga(payload);
  } catch (error: any) {
    yield* errorHandlingSaga(error, 'requestAvailablesFundsSaga');
  }
}

function* requestAllAvailablesFunds({ payload }: AnyAction) {
  try {
    yield* put(LoadingActions.show());

    const response = yield* call(AvailableFundsService.getAvailableFunds, true);

    const expiresAt = new Date();
    expiresAt.setDate(expiresAt.getDate() + 1);
    expiresAt.setHours(0, 0, 0, 0);

    const storeFund: LocalStorageRequest<InvestmentFund[]> = {
      data: response.data,
      requestDate: new Date(),
      expiresAt,
    };

    LocalStorageService.setItem(LocalStorageKeys.AVAILABLE_FUNDS, storeFund);

    yield* put(LoadingActions.hide());

    redirectThroughSaga(payload);
  } catch (error: any) {
    yield* errorHandlingSaga(error, 'requestAllAvailablesFunds');
  }
}

export default function* watchSelectableFunds(): SagaGenerator<void> {
  yield* takeLeading(SelectableFundsTypes.REQUEST_AVAILABLE_FUNDS, requestAvailablesFunds);
  yield* takeLeading(SelectableFundsTypes.REQUEST_ALL_AVAILABLE_FUNDS, requestAllAvailablesFunds);
}
