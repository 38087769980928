const ELEMENT_TAG_NAME = 'script';

const removeChild = (element: HTMLScriptElement, src: string): void => {
  if (element.src === src) {
    document.body.removeChild(element);
  }
};

const removeScript = (src: string): void => {
  const elements: HTMLCollectionOf<HTMLScriptElement> = document.body.getElementsByTagName(
    ELEMENT_TAG_NAME,
  );
  Array.from(elements).forEach((element: HTMLScriptElement) => removeChild(element, src));
};

/**
 * A hook that return a function to remove a third party script.
 *
 * @tutorial
 * const removeScript = useRemoveScript();
 * removeScript('https:...');
 *
 * @returns a function to remove the script
 */
export const useRemoveScript = (): ((src: string) => void) => removeScript;
