import { InterUIButton, InterUIContainer } from '@interco/inter-ui-react-lib';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import AdditionalContributionAnalyticsService from '../../analytics/additionalContributionAnalyticsService';
import { InvestmentFundCard } from '../../components';
import FontSizeREM from '../../enums/fontSizesRem';
import { PageRoutes } from '../../enums/pageRoutes';
import PageTitles from '../../enums/pageTitles';
import NavbarActions from '../../store/navbar/actions';
import PrivatePensionPlanActions from '../../store/privatePensionPlan/actions';
import { ThemeInter } from '../../styles';
import { Div, P, Separator, SeparatorDashed, Span } from '../../styles/commons';
import {
  formatMonthsToYearsAndMonths,
  getMonthsInvestingText,
  newDateHandler,
} from '../../utils/dateTimeUtils';
import { formatToBRLCurrency } from '../../utils/numberFormatUtils';
import ExpiredInfo from './Components/ExpiredInfo';

const AdditionalContributionExpiredPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme() as ThemeInter;
  const {
    investmentFund,
    accumulatedValue,
    numberMonthsInvesting,
    endContributionDate,
    privatePensionStatusTitle,
    privatePensionStatusMessage,
  } = useSelector(PrivatePensionPlanActions.getPrivatePensionPlan);

  let endContributionString = null;
  if (endContributionDate) {
    endContributionString = newDateHandler(`${endContributionDate}T00:00:00`)?.toLocaleDateString(
      'pt-BR',
    );
  }

  useEffect(() => {
    dispatch(NavbarActions.setTitle(PageTitles.ADDITIONAL_CONTRIBUTION));
  }, [dispatch]);

  useEffect(() => {
    AdditionalContributionAnalyticsService.expiredPage();
  }, []);

  const goHome = () => {
    history.push(PageRoutes.HOME);
  };

  const goToObjectives = () => {
    AdditionalContributionAnalyticsService.hireNewPrevButton(privatePensionStatusTitle);
    history.push(PageRoutes.OBJECTIVES_HOME);
  };

  const stickyFooter: React.ReactElement = (
    <Div>
      <InterUIButton
        margin="0 0 16px 0"
        type="submit"
        data-testid="expired-go-to-objectives-btn"
        onClick={() => goToObjectives()}
      >
        Contratar nova previdência
      </InterUIButton>

      <InterUIButton
        type="submit"
        data-testid="expired-go-to-home-btn"
        variant="secondary"
        onClick={() => goHome()}
      >
        Voltar para a Home
      </InterUIButton>
    </Div>
  );

  return (
    <InterUIContainer stickyFooter={stickyFooter}>
      {investmentFund && <InvestmentFundCard investmentFund={investmentFund} marginBottom="24px" />}
      {accumulatedValue != null && (
        <ExpiredInfo label="Valor acumulado" value={formatToBRLCurrency(accumulatedValue)} />
      )}
      <Separator
        height="8px"
        width="100vw"
        margin="32px 0px 32px -24px"
        color={theme.colors.grayscale.A100}
      />
      <P fontSize={FontSizeREM.PX16} lineHeight={FontSizeREM.PX20}>
        {privatePensionStatusTitle}
      </P>
      <Span
        color={theme.colors.grayscale.A400}
        fontWeight={400}
        lineHeight={FontSizeREM.PX18}
        fontSize={FontSizeREM.PX14}
      >
        {privatePensionStatusMessage}
      </Span>
      <SeparatorDashed margin="18px 0px 26px 0" color={theme.colors.grayscale.A200} />
      {numberMonthsInvesting != null && (
        <ExpiredInfo
          label="Seu prazo de aposentadoria"
          value={getMonthsInvestingText(formatMonthsToYearsAndMonths(numberMonthsInvesting))}
        />
      )}
      {endContributionDate != null && endContributionString != null && (
        <>
          <ExpiredInfo label="Data de contribuição limite" value={endContributionString} />
        </>
      )}
    </InterUIContainer>
  );
};

export default AdditionalContributionExpiredPage;
