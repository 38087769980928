/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { BaseService, ServiceResponse } from './baseService';
import { MockService } from './mockService';

const {
  REACT_APP_API_PRIVATE_PENSION_PLANS_V1,
  REACT_APP_API_BRIDGE_PRIVATE_PENSION_PLANS_V1,
} = process.env;

class UserHasPrivatePensionService {
  static async requestHasPrivatePension(): Promise<ServiceResponse<any>> {
    return BaseService.doExecute({
      endpoint: {
        browser: `${REACT_APP_API_PRIVATE_PENSION_PLANS_V1}/planos-previdencia/tem-certificado`,
        bridge: `${REACT_APP_API_BRIDGE_PRIVATE_PENSION_PLANS_V1}/planos-previdencia/tem-certificado`,
      },
      headers: {
        browser: MockService.matchHeadersByUserAccount,
      },
      method: 'GET',
    });
  }
}
export default UserHasPrivatePensionService;
