import { AnyAction } from 'redux';
import { action } from 'typesafe-actions';
import { RootState } from '..';
import { DeepLinkProposalState, DeepLinkProposalTypes } from './types';

const getDeepLinkProposal = (state: RootState): DeepLinkProposalState => state.deepLinkProposal;

const setDeepLinkProposal = (deepLinkProposalDetails: DeepLinkProposalState): AnyAction =>
  action(DeepLinkProposalTypes.SET_DEEP_LINK_PROPOSAL, deepLinkProposalDetails);

const setDeepLinkProposalFailure = (error: boolean): AnyAction =>
  action(DeepLinkProposalTypes.SET_DEEP_LINK_PROPOSAL_FAILURE, error);

const resetState = (): AnyAction => action(DeepLinkProposalTypes.RESET);

const DeepLinkProposalActions = {
  getDeepLinkProposal,
  setDeepLinkProposal,
  setDeepLinkProposalFailure,
  resetState,
};

export default DeepLinkProposalActions;
