import { Reducer } from 'redux';
import { PrivatePensions, TaxTypes } from '../../enums/privatePensions';
import { QueryParamsState, QueryParamsTypes } from './types';

const INITIAL_STATE: QueryParamsState = {
  codigoFundo: '',
  diaDebito: '',
  modalidade: PrivatePensions.EMPTY,
  tabela: TaxTypes.EMPTY,
  tempoContribuicaoMeses: '',
  valorInicial: '',
  valorMensal: '',
  numeroCertificado: '',
  mock: false,
  mockContaCorrente: '',
  mockApiKey: '',
  useBelvoApiMock: true,
};

export const reducer: Reducer<QueryParamsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case QueryParamsTypes.SET_QUERY_PARAMS: {
      return action.payload;
    }
    case QueryParamsTypes.RESET: {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
};

export default reducer;
