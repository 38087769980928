import { PageRoutes } from '../enums/pageRoutes';
import store from '../store';
import { QueryParamsState } from '../store/global/types';
import NavbarActions from '../store/navbar/actions';
import { getFirstDebitDate } from './dateTimeUtils';

export interface IRouterStateProps {
  data: {
    deeplink: boolean;
    ignoreFetch: boolean;
  };
}

export const RoutesByQueryParams: {
  [params: string]: PageRoutes;
} = {
  Aposentadoria: PageRoutes.RETIREMENT,
  PortabilidadeSimplificada: PageRoutes.PORTABILITY_HOME,
  PortabilidadeAuto: PageRoutes.PORTABILITY_QUERY_PENSION_PLAN,
  PropostaDeepLink: PageRoutes.DEEP_LINK_PROPOSAL,
  ImpostoRenda: PageRoutes.INCOME_EXPENSE,
  AporteEsporadico: PageRoutes.ADDITIONAL_CONTRIBUTION_DEEP_LINK,
  AceitePortabilidadeExterna: PageRoutes.EXTERNAL_PORTABILITY_ACCEPTANCE,
  AceitePropostaContratacao: PageRoutes.HIRE_PROPOSAL_ACCEPTANCE,
};

export function handleParamsPage(page: string, params?: QueryParamsState): string {
  if (page === 'ImpostoRenda') {
    const firstDebitDate = getFirstDebitDate();
    const TODAY_DATE = new Date();

    if (firstDebitDate.getFullYear() !== TODAY_DATE.getFullYear()) {
      return PageRoutes.HOME;
    }
  }

  if (page !== 'AporteEsporadico') {
    store.dispatch(NavbarActions.setCanGoBack(false));
  }

  if (page === 'AceitePortabilidadeExterna' && params) {
    if (params.portabilidadeId) {
      return RoutesByQueryParams[page].replace(
        ':id',
        (params.portabilidadeId as unknown) as string,
      );
    }
    return PageRoutes.HOME;
  }

  return RoutesByQueryParams[page];
}

export function buildParams(splitedQuery: string[]): string {
  let params = '';

  splitedQuery.forEach((item: string) => {
    const dict = item.split(':');

    const key = dict[0];
    const value = dict[1];

    if (key !== 'prefix' && key && value) {
      params = params.concat(key, ':', value, ';');
    }
  });

  return params ? `?params=${params}` : '';
}
