/**
 * Rotas disponíveis na aplicação.
 */
export enum PageRoutes {
  HOME = '/',
  ERROR = '/error',
  OBJECTIVES_HOME = '/objectives-home',
  RETIREMENT = '/retirement',
  PROPOSAL = '/proposal',
  SELECTABLE_FUNDS = '/proposal/select-fund',
  TAX_TYPE = '/proposal/tax-type',
  PRIVATE_PENSION_TYPE = '/proposal/private-pension-type',
  CONTRIBUTION_TIME = '/proposal/contribution-time',
  PAYMENT_DAY = '/proposal/payment-day',
  CONTRIBUTION_VALUES = '/proposal/contribution-values',
  PREVIEW_CONTRACT = '/preview-contract',
  REVISION = '/revision',
  PORTABILITY_HOME = '/portability/home',
  PORTABILITY_SELECT_FINANCIAL_INSTITUTION = '/portability/select-financial-institution',
  PORTABILITY_CONFIRMATION = '/portability/confirmation',
  PORTABILITY_ONBOARDING_BELVO = '/portability/onboarding-belvo',
  PORTABILITY_PREVIEW_PRIVACY_POLICY = '/portability/preview-privacy-policy',
  PORTABILITY_QUERY_PENSION_PLAN = '/portability/query-pension-plan',
  PROPOSAL_BY_PORTABILITY = '/portability/proposal',
  PORTABILITY_EXTERNAL_PENSION_PLAN = '/portability/external-pension-plan',
  PORTABILITY_PROPOSAL = '/portability/proposal',
  PORTABILITY_ACCUMULATED_VALUE = '/portability/accumulated-value',
  PORTABILITY_REVISION = '/portability/revision',
  PORTABILITY_CONTRACTION_RECEIPT = '/portability/contraction-receipt',
  PORTABILITY_STATUS = '/portability/status',
  CONTRACTION_RECEIPT = '/contraction-receipt',
  INCOME_EXPENSE = '/income-expense',
  BUY_GOOD = '/buy-good',
  INCOME_TAX = '/income-tax',
  DEEP_LINK_PROPOSAL = '/proposal-by-deeplink',
  PROPOSAL_BY_FUND_CHOOSE_FUND = '/proposal-by-fund/choose-fund',
  PROPOSAL_BY_FUND_CONTRIBUTION_VALUES = '/proposal-by-fund/contribution-values',
  PRIVATE_PENSION_PLANS = '/additional-contribution/private-pension-plans',
  ADDITIONAL_CONTRIBUTION_INVESTMENT_FORM = '/additional-contribution/investment-form',
  ADDITIONAL_CONTRIBUTION_EXPIRED = '/additional-contribution/expired',
  ADDITIONAL_CONTRIBUTION_PROPOSAL = '/additional-contribution/proposal',
  ADDITIONAL_CONTRIBUTION_PGBL_HELPER = '/additional-contribution/pgbl-helper',
  ADDITIONAL_CONTRIBUTION_PREVIEW_CONTRACT = '/additional-contribution/preview-contract',
  ADDITIONAL_CONTRIBUTION_RECEIPT = '/additional-contribution/receipt',
  ADDITIONAL_CONTRIBUTION_DEEP_LINK = '/additional-contribution/deep-link',
  CHECK_SIGNED_TERMS_DEEPLINK_SPLASH = '/check-signed-terms',
  EXTERNAL_PORTABILITY_ACCEPTANCE = '/external-portability/:id/acceptance',
  HIRE_PROPOSAL_ACCEPTANCE = '/hire-proposal/acceptance',
  CANCELLATION_RECEIPT = '/cancellation-receipt',
}

export enum PageDirections {
  FORWARD = 'forward',
  BACKWARD = 'backward',
}
