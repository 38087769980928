/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/unbound-method */
import { formatCurrency, InterUIBox, InterUIIcon } from '@interco/inter-ui-react-lib';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import ProposalAnalyticsService from '../../../analytics/proposalAnalyticsService';
import { FormWithHook } from '../../../components/FormWithHook/FormWithHook';
import InputPricingWithHook from '../../../components/InputPricingWithHook/InputPricingWithHook';
import { LocalStorageKeys } from '../../../enums/localStorageKeys';
import { getMessages, MessagesValidation } from '../../../enums/messages';
import { PageRoutes } from '../../../enums/pageRoutes';
import LocalStorageService from '../../../services/localStorageService';
import ContributionValuesAction from '../../../store/contributionValues/actions';
import { IncomeExpenseActions } from '../../../store/incomeExpenses/actions';
import IncomeTaxPageActions from '../../../store/incomeTax/actions';
import { ProposalDetailActions } from '../../../store/proposal/actions';
import { ProposalTypes } from '../../../store/proposal/types';
import { ProposalByFundActions } from '../../../store/proposalByFund/actions';
import RetirementPageActions from '../../../store/retirement/actions';
import SelectableFundsActions from '../../../store/selectableFunds/actions';
import { ThemeInter } from '../../../styles';
import { Div, PSmallGray } from '../../../styles/commons';
import { InterUIAlertCustomized } from '../../../styles/inter-ui-customizations';
import { ANNUAL_INCOME_PERCENTAGE } from '../../../utils/constantsValues';
import { getMonthsToCompleteAnYear } from '../../../utils/dateTimeUtils';
import {
  defineMinimumMonthlyContribution,
  INITIAL_CONTRIBUTION_MINIMUM,
  MONTHLY_CONTRIBUTION_MINIMUM,
} from '../../../utils/minValues';
import { formatToBRLCurrency } from '../../../utils/numberFormatUtils';
import { ContributionStrategyType } from '../../Retirement/components/ContributionStrategy/ContributionStrategy';

interface IContributionValuesForm {
  initialContribution: number | string;
  monthlyContribution: number | string;
}

interface IContributionValuesFormProps {
  setDisabledButton: (isFormValid: boolean) => void;
  setDisableButtonIfEqual: (areValuesEqual: boolean) => void;
}

const ContributionValuesForm: React.FC<IContributionValuesFormProps> = ({
  setDisabledButton,
  setDisableButtonIfEqual,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme() as ThemeInter;

  const type = useSelector(ProposalDetailActions.getType);
  const currentInitialContribution = useSelector(ProposalDetailActions.getInitialContribution);
  const currentMonthlyContribution = useSelector(ProposalDetailActions.getMonthlyContribution);
  const currentInvestmentFund = useSelector(ProposalDetailActions.getInvestmentFund);
  const newContributionValues = useSelector(ContributionValuesAction.getContributionValues);
  const currentAnnualContribution = useSelector(ProposalDetailActions.getAnnualContribution);
  const numberMonthsInvesting = useSelector(ProposalDetailActions.getNumberMonthsInvesting);
  const paymentDay = useSelector(ProposalDetailActions.getPaymentDay);
  const { annualIncome } = useSelector(IncomeExpenseActions.get);
  const maximumDeductibleAmount = annualIncome * ANNUAL_INCOME_PERCENTAGE;
  const [annualContribution, setAnnualContribution] = useState(currentAnnualContribution);
  const initialContribution = newContributionValues.isChangeRequired
    ? newContributionValues.initialContribution
    : currentInitialContribution;
  const monthlyContribution = newContributionValues.isChangeRequired
    ? newContributionValues.monthlyContribution
    : currentMonthlyContribution;

  const [initialMinContribution, setInitialMinContribution] = useState(
    currentInitialContribution > 0 ? INITIAL_CONTRIBUTION_MINIMUM : 0,
  );
  const [monthlyMinContribution, setMonthlyMinContribution] = useState(
    currentMonthlyContribution > 0 ? MONTHLY_CONTRIBUTION_MINIMUM : 0,
  );
  const [triggerValidation, setTriggerValidation] = useState(false);

  const methods = useForm<IContributionValuesForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      initialContribution: initialContribution || undefined,
      monthlyContribution: monthlyContribution || undefined,
    },
    criteriaMode: 'all',
  });

  const {
    watch,
    trigger,
    formState: { isValid },
  } = methods;

  const formInitialContribution = watch('initialContribution');
  const formMonthlyContribution = watch('monthlyContribution');

  const yearOfExercise = String(new Date().getFullYear() + 1);

  const defineContributionState = (initial: number, monthly: number) => {
    let initialContributionMinimum = INITIAL_CONTRIBUTION_MINIMUM;
    let monthlyContributionMinimum = MONTHLY_CONTRIBUTION_MINIMUM;
    let contributionStrategy = ContributionStrategyType.INITIAL_MONTHLY_CONTRIBUTION;

    const onlyInitialContribution = initial > 0 && monthly === 0;
    const onlyMonthlyContribution = monthly > 0 && initial === 0;

    switch (type) {
      case ProposalTypes.INCOME_TAX: {
        dispatch(IncomeTaxPageActions.setInitialContribution(initial));
        dispatch(IncomeTaxPageActions.setMonthlyContribution(monthly));
        if (onlyInitialContribution) {
          contributionStrategy = ContributionStrategyType.INITIAL_CONTRIBUTION;
          monthlyContributionMinimum = 0;
        } else if (onlyMonthlyContribution) {
          contributionStrategy = ContributionStrategyType.MONTHLY_CONTRIBUTION;
          initialContributionMinimum = 0;
        }
        dispatch(IncomeTaxPageActions.setContributionStrategy(contributionStrategy));
        dispatch(IncomeTaxPageActions.setInitialMinimum(initialContributionMinimum));
        dispatch(IncomeTaxPageActions.setMonthlyMinimum(monthlyContributionMinimum));
        break;
      }
      case ProposalTypes.BY_FUND: {
        dispatch(ProposalByFundActions.setInitialValue(initial));
        dispatch(ProposalByFundActions.setMonthlyValue(monthly));
        if (onlyInitialContribution) {
          contributionStrategy = ContributionStrategyType.INITIAL_CONTRIBUTION;
        } else if (onlyMonthlyContribution) {
          contributionStrategy = ContributionStrategyType.MONTHLY_CONTRIBUTION;
        }
        dispatch(ProposalByFundActions.setStrategyContribution(contributionStrategy));
        break;
      }
      default: {
        dispatch(RetirementPageActions.setInitialContribution(initial));
        dispatch(RetirementPageActions.setMonthlyContribution(monthly));
        if (onlyInitialContribution) {
          contributionStrategy = ContributionStrategyType.INITIAL_CONTRIBUTION;
          monthlyContributionMinimum = 0;
        } else if (onlyMonthlyContribution) {
          contributionStrategy = ContributionStrategyType.MONTHLY_CONTRIBUTION;
          initialContributionMinimum = 0;
        }
        dispatch(RetirementPageActions.setContributionStrategy(contributionStrategy));
        dispatch(RetirementPageActions.setInitialMinimum(initialContributionMinimum));
        dispatch(RetirementPageActions.setMonthlyMinimum(monthlyContributionMinimum));
        break;
      }
    }
    dispatch(ProposalDetailActions.setInitialContribution(initial));
    dispatch(ProposalDetailActions.setMonthlyContribution(monthly));
    dispatch(ProposalDetailActions.setAnnualContribution(annualContribution));
  };

  useEffect(() => {
    const disabled = !isValid || (formInitialContribution === 0 && formMonthlyContribution === 0);
    setDisabledButton(disabled);
  }, [formInitialContribution, formMonthlyContribution, isValid, setDisabledButton]);

  /**
   * Disable button if the newer values are equal to the older values
   */
  useEffect(() => {
    setDisableButtonIfEqual(
      formInitialContribution === currentInitialContribution &&
        formMonthlyContribution === currentMonthlyContribution,
    );
  }, [
    formInitialContribution,
    formMonthlyContribution,
    currentInitialContribution,
    currentMonthlyContribution,
    setDisableButtonIfEqual,
  ]);

  const buildInitialMinimumValidation = useCallback(() => {
    setTriggerValidation(true);
    return {
      value: initialMinContribution,
      message: getMessages(MessagesValidation.MINIMUM_VALUE_VALIDATION, [
        formatToBRLCurrency(initialMinContribution),
      ]),
    };
  }, [initialMinContribution]);

  const initialMinimumValidation = useMemo(() => buildInitialMinimumValidation(), [
    buildInitialMinimumValidation,
  ]);

  const buildMonthlyMinimumValidation = useCallback(() => {
    setTriggerValidation(true);
    return {
      value: monthlyMinContribution,
      message: getMessages(MessagesValidation.MINIMUM_VALUE_VALIDATION, [
        formatToBRLCurrency(monthlyMinContribution),
      ]),
    };
  }, [monthlyMinContribution]);

  const monthlyMinimumValidation = useMemo(() => buildMonthlyMinimumValidation(), [
    buildMonthlyMinimumValidation,
  ]);

  useEffect(() => {
    trigger();
    setTriggerValidation(false);
  }, [trigger, triggerValidation]);

  const initialContributionDeductibleInfo = useMemo(() => {
    if (type !== ProposalTypes.INCOME_TAX) {
      return undefined;
    }

    const initialContributionForm = +formInitialContribution || 0;
    const monthlyContributionForm = +formMonthlyContribution || 0;

    if (!initialContributionForm || monthlyContributionForm) {
      return undefined;
    }

    const deductibleIncomeExpense = maximumDeductibleAmount - initialContributionForm;
    setAnnualContribution(initialContributionForm);

    if (deductibleIncomeExpense > 0) {
      return {
        message: getMessages(
          MessagesValidation.ANNUAL_CONTRIBUTION_UNDER_MAXIMUM_DEDUCTIBLE_AMOUNT_FOR_INITIAL,
          [formatToBRLCurrency(maximumDeductibleAmount), yearOfExercise],
        ),
      };
    }

    if (deductibleIncomeExpense < 0) {
      return {
        message: getMessages(MessagesValidation.MAXIMUM_DEDUCTIBLE_AMOUNT_EXCEEDS, [
          formatToBRLCurrency(maximumDeductibleAmount),
          yearOfExercise,
          formatToBRLCurrency(deductibleIncomeExpense * -1),
        ]),
      };
    }

    return undefined;
  }, [
    formInitialContribution,
    formMonthlyContribution,
    maximumDeductibleAmount,
    type,
    yearOfExercise,
  ]);

  const monthlyContributionDeductibleInfo = useMemo(() => {
    if (type !== ProposalTypes.INCOME_TAX) {
      return undefined;
    }

    const initialContributionForm = +formInitialContribution || 0;
    const monthlyContributionForm = +formMonthlyContribution || 0;

    if (initialContributionForm && !monthlyContributionForm) {
      return undefined;
    }

    const monthsToCompleteAnYear = getMonthsToCompleteAnYear(paymentDay);
    let numberOfContributions;

    let deductibleIncomeExpense;

    if (numberMonthsInvesting < monthsToCompleteAnYear) {
      numberOfContributions = numberMonthsInvesting;
    } else {
      numberOfContributions = monthsToCompleteAnYear;
    }

    const diffBetweenMaximumDeductibleAndIniticalContribution =
      maximumDeductibleAmount - initialContributionForm;

    const idealMonthlyContribution = +(
      diffBetweenMaximumDeductibleAndIniticalContribution / numberOfContributions
    ).toFixed(2);

    const idealAmountMonthlyContribution = +(
      idealMonthlyContribution * monthsToCompleteAnYear
    ).toFixed(2);

    /**
     * newMaximumDeductible is the maximumDeductibleAmount that is considering the rounded idealMonthlyContribution
     * In cases when the idealMonthlyContribution is exact ex: 500, 250, 100...
     * The newMaximumDeductible will be equal to the maximumDeductibleAmount;
     * In cases when the idealMonthlyContribution is a periodic tithe ex 133.33, 666.67...
     * The newMaximumDeductible will be considering the rounded monthly values
     * So we need to use as reference the newMaximumDeductible to calcular the deductibleIncomeExpense,
     * This is needed to hidden the card for when is suggested a rounded monthly contribution and the user input the suggestion;
     */
    const newMaximumDeductible = idealAmountMonthlyContribution + initialContributionForm;

    const ammountMonthlyContribution = +(monthlyContributionForm * numberOfContributions).toFixed(
      2,
    );

    if (monthsToCompleteAnYear > 0) {
      deductibleIncomeExpense =
        newMaximumDeductible - (ammountMonthlyContribution + initialContributionForm);

      setAnnualContribution(ammountMonthlyContribution + initialContributionForm);
    } else {
      deductibleIncomeExpense =
        maximumDeductibleAmount - (monthlyContributionForm + initialContributionForm);
      setAnnualContribution(monthlyContributionForm + initialContributionForm);
    }

    if (deductibleIncomeExpense > 0) {
      return {
        message: getMessages(
          MessagesValidation.ANNUAL_CONTRIBUTION_UNDER_MAXIMUM_DEDUCTIBLE_AMOUNT_FOR_MONTHLY,
          [formatToBRLCurrency(idealMonthlyContribution), yearOfExercise],
        ),
      };
    }

    if (deductibleIncomeExpense < 0) {
      /*
       * In this scenarious we need show the diff between the correct maximumDeductibleAmount and the annualContribution inputed
       */
      const rightDeductibleIncomeExpense =
        maximumDeductibleAmount - (ammountMonthlyContribution + initialContributionForm);
      return {
        message: getMessages(MessagesValidation.MAXIMUM_DEDUCTIBLE_AMOUNT_EXCEEDS, [
          formatToBRLCurrency(maximumDeductibleAmount),
          yearOfExercise,
          formatToBRLCurrency(rightDeductibleIncomeExpense * -1),
        ]),
      };
    }

    return undefined;
  }, [
    formInitialContribution,
    formMonthlyContribution,
    maximumDeductibleAmount,
    numberMonthsInvesting,
    paymentDay,
    type,
    yearOfExercise,
  ]);

  /**
   * Change the minimum of each input by the values
   */
  useEffect(() => {
    const initialValue = (formInitialContribution as unknown) as number;
    const monthlyValue = (formMonthlyContribution as unknown) as number;

    const monthlyMinimum = defineMinimumMonthlyContribution(
      currentInvestmentFund,
      initialValue,
      monthlyValue,
    );

    const initialMinimum = initialValue > 0 ? INITIAL_CONTRIBUTION_MINIMUM : 0;
    const finalMonthlyMinimum =
      monthlyValue > 0 ? Math.min(monthlyMinimum, MONTHLY_CONTRIBUTION_MINIMUM) : 0;

    setInitialMinContribution(initialMinimum);
    setMonthlyMinContribution(finalMonthlyMinimum);
  }, [formMonthlyContribution, formInitialContribution, currentInvestmentFund]);

  const onSubmit = (data: IContributionValuesForm) => {
    const conditionalMonthlyMinimum = defineMinimumMonthlyContribution(
      currentInvestmentFund,
      data.initialContribution as number,
      data.monthlyContribution as number,
    );
    if (
      (data.initialContribution > 0 &&
        data.initialContribution < currentInvestmentFund.minimumInitialContribution) ||
      (data.monthlyContribution > 0 && data.monthlyContribution < conditionalMonthlyMinimum)
    ) {
      dispatch(
        ContributionValuesAction.setContributionValues({
          initialContribution: +data.initialContribution,
          monthlyContribution: +data.monthlyContribution,
          annualContribution,
          isChangeRequired: true,
        }),
      );

      if (!LocalStorageService.hasExpired(LocalStorageKeys.AVAILABLE_FUNDS)) {
        history.push(PageRoutes.SELECTABLE_FUNDS);
        return;
      }

      ProposalAnalyticsService.editContributionValues({
        content_inicial_new: formatCurrency(+data.initialContribution, 2, 'pt-BR'),
        content_inicial_old: formatCurrency(currentInitialContribution, 2, 'pt-BR'),
        content_mensal_new: formatCurrency(+data.monthlyContribution, 2, 'pt-BR'),
        content_mensal_old: formatCurrency(currentMonthlyContribution, 2, 'pt-BR'),
      });

      dispatch(
        SelectableFundsActions.requestAvailableFunds({
          history,
          pathname: PageRoutes.SELECTABLE_FUNDS,
        }),
      );
    } else {
      ProposalAnalyticsService.editContributionValues({
        content_inicial_new: formatCurrency(+data.initialContribution, 2, 'pt-BR'),
        content_inicial_old: formatCurrency(currentInitialContribution, 2, 'pt-BR'),
        content_mensal_new: formatCurrency(+data.monthlyContribution, 2, 'pt-BR'),
        content_mensal_old: formatCurrency(currentMonthlyContribution, 2, 'pt-BR'),
      });

      defineContributionState(+data.initialContribution, +data.monthlyContribution);

      history.goBack();
    }
  };

  return (
    <FormWithHook
      onSubmit={onSubmit}
      methods={methods}
      formId="contribution-values-form"
      margin="0px"
    >
      <InputPricingWithHook
        label="Valor inicial"
        placeholder="0,00"
        prefix="R$"
        name="initialContribution"
        maxLength={14}
        margin="0"
        validation={{
          min: initialMinimumValidation,
        }}
        shouldDirty
      />
      <Div marginBottom="12px">
        {initialContributionDeductibleInfo && (
          <InterUIBox padding="0px" margin="0 0 16px">
            <InterUIAlertCustomized
              color={theme.colors.white}
              icon={<InterUIIcon name="contextual-info" color={theme.colors.alert.A400} />}
              alignItems="center"
            >
              <PSmallGray marginBottom="0">{initialContributionDeductibleInfo.message}</PSmallGray>
            </InterUIAlertCustomized>
          </InterUIBox>
        )}
      </Div>
      <InputPricingWithHook
        label="Valor a cada mês"
        placeholder="0,00"
        prefix="R$"
        name="monthlyContribution"
        maxLength={14}
        margin="0"
        validation={{
          min: monthlyMinimumValidation,
        }}
        shouldDirty
      />
      <Div marginBottom="12px">
        {monthlyContributionDeductibleInfo && (
          <InterUIBox padding="0px" margin="0 0 16px">
            <InterUIAlertCustomized
              color={theme.colors.white}
              icon={<InterUIIcon name="contextual-info" color={theme.colors.alert.A400} />}
              alignItems="center"
            >
              <PSmallGray marginBottom="0">{monthlyContributionDeductibleInfo.message}</PSmallGray>
            </InterUIAlertCustomized>
          </InterUIBox>
        )}
      </Div>
    </FormWithHook>
  );
};

export default ContributionValuesForm;
