/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/unbound-method */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import RetirementAnalyticsService from '../../../../analytics/retirementAnalyticsService';
import { FormWithHook } from '../../../../components/FormWithHook/FormWithHook';
import InputPricingWithHook from '../../../../components/InputPricingWithHook/InputPricingWithHook';
import { getMessages, MessagesValidation } from '../../../../enums/messages';
import { PageRoutes } from '../../../../enums/pageRoutes';
import { ProposalDetailActions } from '../../../../store/proposal/actions';
import RetirementPageActions from '../../../../store/retirement/actions';
import { formatToBRLCurrency } from '../../../../utils/numberFormatUtils';
import { ContributionStrategyType } from '../ContributionStrategy/ContributionStrategy';

/**
 * Interface that represents the return of the form
 * each interface field must be exactly the name of each input
 * and each field represents only one input, so the names must be unique
 */
export interface IRetirementForm {
  initialContribution: number | string;
  monthlyContribution: number | string;
}

interface IRetirementFormProps {
  setDisabled: (disabled: boolean) => void;
}
function getSchemaByProps(initialMinimum: number, monthlyMinimum: number) {
  let initialField;
  let monthlyField;
  if (initialMinimum === 0) {
    initialField = yup.string().nullable();
  } else {
    initialField = yup
      .string()
      .test(
        'min',
        getMessages(MessagesValidation.MINIMUM_VALUE_VALIDATION, [
          formatToBRLCurrency(initialMinimum),
        ]),
        (value) => {
          let parsedValue = null;
          if (value) {
            parsedValue = +value;
          }
          return !!parsedValue && parsedValue >= initialMinimum;
        },
      )
      .required(MessagesValidation.INITIAL_CONTRIBUTION_REQUIRED);
    monthlyField = yup.string().nullable();
  }

  if (monthlyMinimum === 0) {
    monthlyField = yup.string().nullable();
  } else {
    monthlyField = yup
      .string()
      .test(
        'min',
        getMessages(MessagesValidation.MINIMUM_VALUE_VALIDATION, [
          formatToBRLCurrency(monthlyMinimum),
        ]),
        (value) => {
          let parsedValue = null;
          if (value) {
            parsedValue = +value;
          }
          return !!parsedValue && parsedValue >= monthlyMinimum;
        },
      )
      .required(MessagesValidation.MONTHLY_CONTRIBUTION_REQUIRED);
  }

  return yup.object().shape({
    initialContribution: initialField,
    monthlyContribution: monthlyField,
  });
}

export const RetirementForm: React.FC<IRetirementFormProps> = ({ setDisabled }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const contributionStrategy: string = useSelector(RetirementPageActions.getContributionStrategy);
  const initialContribution = useSelector(RetirementPageActions.getInitialContribution);
  const initialMinimum = useSelector(RetirementPageActions.getInitialMinimum);
  const monthlyContribution = useSelector(RetirementPageActions.getMonthlyContribution);
  const monthlyMinimum = useSelector(RetirementPageActions.getMonthlyMinimum);
  const proposalId = useSelector(ProposalDetailActions.getSuggestedProposalId);
  const isDisabledInitial =
    contributionStrategy === '' ||
    contributionStrategy === ContributionStrategyType.MONTHLY_CONTRIBUTION;

  const isDisabledMonthly =
    contributionStrategy === '' ||
    contributionStrategy === ContributionStrategyType.INITIAL_CONTRIBUTION;

  const methods = useForm<IRetirementForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      initialContribution: initialContribution || undefined,
      monthlyContribution: monthlyContribution || undefined,
    },
    criteriaMode: 'all',
    resolver: yupResolver(getSchemaByProps(initialMinimum, monthlyMinimum)),
  });

  const {
    trigger,
    setValue,
    getValues,
    formState: { dirtyFields, isValid },
    watch,
  } = methods;

  const onSubmit = (data: IRetirementForm) => {
    if (contributionStrategy === ContributionStrategyType.INITIAL_CONTRIBUTION) {
      dispatch(RetirementPageActions.setInitialContribution(+data.initialContribution));
      dispatch(RetirementPageActions.setMonthlyContribution(0.0));
    } else if (contributionStrategy === ContributionStrategyType.MONTHLY_CONTRIBUTION) {
      dispatch(RetirementPageActions.setInitialContribution(0.0));
      dispatch(RetirementPageActions.setMonthlyContribution(+data.monthlyContribution));
    } else {
      dispatch(RetirementPageActions.setInitialContribution(+data.initialContribution));
      dispatch(RetirementPageActions.setMonthlyContribution(+data.monthlyContribution));
    }
    RetirementAnalyticsService.requestProposal({
      content_name: contributionStrategy,
      price_inicial: formatToBRLCurrency(initialContribution),
      price_mensal: formatToBRLCurrency(monthlyContribution),
    });
    dispatch(
      ProposalDetailActions.requestProposalDetails({ history, pathname: PageRoutes.PROPOSAL }),
    );
  };

  const isDisableButton = useMemo(() => !isValid, [isValid]);

  useEffect(() => {
    setDisabled(isDisableButton);
  }, [isDisableButton, setDisabled]);

  /**
   * When @param initialMinimum state change check if is
   * neccessary to trigger the validations for this field
   */
  useEffect(() => {
    if (dirtyFields.initialContribution) {
      trigger('initialContribution');
    }
  }, [initialMinimum, dirtyFields.initialContribution, trigger]);

  /**
   * When @param monthlyMinimum state change check if is
   * neccessary to trigger the validations for this field
   */
  useEffect(() => {
    if (dirtyFields.monthlyContribution) {
      trigger('monthlyContribution');
    }
  }, [monthlyMinimum, dirtyFields.monthlyContribution, trigger]);

  useEffect(() => {
    if (contributionStrategy && proposalId) {
      trigger();
    }
  }, [trigger]);

  /**
   * Based on the contributionStrategy scroll to view
   * and set focus into the correct input reference;
   */
  useEffect(() => {
    switch (contributionStrategy) {
      case ContributionStrategyType.INITIAL_CONTRIBUTION: {
        const initialInput = document.getElementById('initialContribution') as HTMLInputElement;
        initialInput.scrollIntoView({ block: 'start', behavior: 'smooth' });
        initialInput.focus({ preventScroll: true });

        setValue('monthlyContribution', '0,00');
        break;
      }
      case ContributionStrategyType.MONTHLY_CONTRIBUTION: {
        const monthlyInput = document.getElementById('monthlyContribution') as HTMLInputElement;
        monthlyInput.scrollIntoView({ block: 'start', behavior: 'smooth' });
        monthlyInput.focus({ preventScroll: true });

        setValue('initialContribution', '0,00', { shouldValidate: true });
        break;
      }
      case ContributionStrategyType.INITIAL_MONTHLY_CONTRIBUTION: {
        const initialInput = document.getElementById('initialContribution') as HTMLInputElement;
        initialInput.scrollIntoView({ block: 'start', behavior: 'smooth' });
        initialInput.focus({ preventScroll: true });
        break;
      }
      default: {
        break;
      }
    }
  }, [contributionStrategy, setValue]);

  const watchCurrencyFields = watch(['initialContribution', 'monthlyContribution']);

  useEffect(() => {
    const subscription = watch((_, { name }) => {
      if (name === 'initialContribution')
        dispatch(
          RetirementPageActions.setInitialContribution(Number(getValues('initialContribution'))),
        );
      else if (name === 'monthlyContribution')
        dispatch(
          RetirementPageActions.setMonthlyContribution(Number(getValues('monthlyContribution'))),
        );
    });
    return () => subscription.unsubscribe();
  }, [watchCurrencyFields]);

  return (
    <FormWithHook onSubmit={onSubmit} methods={methods} formId="retirement-form" margin="0px">
      <InputPricingWithHook
        dataTestId="initial-contribution"
        name="initialContribution"
        label="Valor inicial"
        placeholder="0,00"
        prefix="R$"
        maxLength={14}
        margin="0 0 12px"
        disabled={isDisabledInitial}
        shouldDirty
      />
      <InputPricingWithHook
        dataTestId="monthly-contribution"
        name="monthlyContribution"
        label="Valor a cada mês"
        placeholder="0,00"
        prefix="R$"
        maxLength={14}
        margin="0 0 12px"
        disabled={isDisabledMonthly}
        shouldDirty
      />
    </FormWithHook>
  );
};
