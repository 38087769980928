import { IWbHttpData } from 'inter-webview-bridge';
import { BaseRequestConfig } from '../services/baseService';
import { MonitoringService } from '../services/monitoringService';

export function logCallByBridgeRequest(request: BaseRequestConfig): void {
  MonitoringService.log('BaseService.callByBridge.request', request);
}

export function logCallByBridgeResponse(response: IWbHttpData): void {
  MonitoringService.log('BaseService.callByBridge.response', response);
}
