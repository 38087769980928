import {
  convertDateToStringPtBR,
  convertStringPtBRToDate,
  differenceBetweenDatesInMonths,
  differenceBetweenDatesInYears,
} from './dateTimeUtils';

const CDI_MONTHLY_PROFITABILITY = 0.009225;
const MAX_CONTRIBUTION_TIME_FOR_TIMELINE_IN_MONTHS = 48;

export interface ILineChartDataset {
  label: string;
  data: number;
}

/*
Final value:
    f.v = itialContributionIncrease(i.c.i) + monthlyContributionIncrease(m.c.i)
where:
    i.c.i = itialContribution(i.c) * i.r.p.m
    m.c.i = monthlyContribution(m.c) * i.p.m.c
    i.r.p.m = (i.p)^n - calculate the increase of interestRate over months
    i.p = 1+i
    i.p.m.c = (i.r.p.m - 1)/i
*/
const finalValue = (
  interestRate: number,
  contributionTime: number,
  initialContribution: number,
  monthlyContribution: number,
) => {
  const interestRateIncreased = (1 + interestRate) ** contributionTime;
  const initialContributionIncreased = initialContribution * interestRateIncreased;
  const monthlyContributionIncreased =
    monthlyContribution * (+Number(interestRateIncreased - 1).toPrecision(4) / interestRate);

  return initialContributionIncreased + monthlyContributionIncreased;
};

const monthlyReturns = (
  interestRate: number,
  contributionTime: number,
  initialContribution: number,
  monthlyContribution: number,
) => {
  const profitabilities: number[] = [];

  profitabilities.push(finalValue(interestRate, 1, initialContribution, monthlyContribution));
  for (let i = 0; i < contributionTime - 1; i += 1) {
    profitabilities.push(finalValue(interestRate, 1, profitabilities[i], monthlyContribution));
  }
  return profitabilities;
};

const getLabelsByContributionTime = (contributionTime: number): Array<string> => {
  const labels: string[] = [];
  const currentDate = new Date();
  let nextDate = new Date(currentDate.getTime());

  for (let i = 0; i < contributionTime; i += 1) {
    const previousDate = nextDate;
    nextDate = new Date(currentDate.getTime());
    nextDate.setMonth(nextDate.getMonth() + 1 + i);

    if (nextDate.getMonth() > previousDate.getMonth() + 1) {
      nextDate.setDate(0);
    }

    labels.push(convertDateToStringPtBR(nextDate));
  }

  return labels;
};

const getCDIDatasets = (
  contributionTime: number,
  initialContribution: number,
  monthlyContribution: number,
): Array<ILineChartDataset> => {
  const labels = getLabelsByContributionTime(contributionTime);

  return monthlyReturns(
    CDI_MONTHLY_PROFITABILITY,
    contributionTime,
    initialContribution,
    monthlyContribution,
  ).map((data, i) => ({ label: labels[i], data } as ILineChartDataset));
};

const getFundDatasets = (
  interestRate: number,
  contributionTime: number,
  initialContribution: number,
  monthlyContribution: number,
): Array<ILineChartDataset> => {
  const labels = getLabelsByContributionTime(contributionTime);

  return monthlyReturns(
    interestRate,
    contributionTime,
    initialContribution,
    monthlyContribution,
  ).map((data, i) => ({ label: labels[i], data } as ILineChartDataset));
};

/**
 * Will return timeline points (X axis) in Months when contributionTime is less or equal than 4 years (<= 48 months).
 * Will return 5 or 6 points, ideally 5. The complete rule is explained on @function choosePoints.
 *
 * @param datasets all possible points between the birthDate and the rescueDate
 * @param contributionTime quantity of months contributing
 * @returns 5 or 6 points to plot on the graph
 */
const chooseByMonth = (
  datasets: Array<ILineChartDataset>,
  contributionTime: number,
): Array<ILineChartDataset> => {
  const newDataset: Array<ILineChartDataset> = [];

  const initialPoint = datasets[0];
  const finalPoint = datasets[datasets.length - 1];

  const finalPointDate = convertStringPtBRToDate(finalPoint.label);

  const spaceBetweenPoints = parseInt(`${contributionTime / 4}`);

  newDataset.push(initialPoint);

  let count = 1;

  while (newDataset.length < 4) {
    const nextPointIndex = spaceBetweenPoints * count;
    const nextPoint = datasets[nextPointIndex];
    const nextPointDate = convertStringPtBRToDate(nextPoint.label);

    newDataset.push(nextPoint);

    if (newDataset.length === 4) {
      const difference = parseInt(
        `${differenceBetweenDatesInMonths(nextPointDate, finalPointDate)}`,
      );

      if (difference / 2 >= spaceBetweenPoints) {
        const extraMonths = parseInt(`${difference / 2}`);
        const nextExtraPoint = datasets[nextPointIndex + extraMonths];
        newDataset.push(nextExtraPoint);
      }
    }

    count += 1;
  }

  newDataset.push(finalPoint);

  return newDataset;
};

/**
 * Will return timeline points (X axis) in Years when contributionTime is bigger than 4 years (> 48 months).
 * Will return 5 or 6 points, ideally 5. The complete rule is explained on @function choosePoints.
 *
 * @param datasets all possible points between the birthDate and the rescueDate
 * @param contributionTime quantity of months contributing
 * @returns 5 or 6 points to plot on the graph
 */
const chooseByYear = (
  datasets: Array<ILineChartDataset>,
  contributionTime: number,
): Array<ILineChartDataset> => {
  const newDataset: Array<ILineChartDataset> = [];

  const initialPoint = datasets[0];
  const finalPoint = datasets[contributionTime];

  const finalPointDate = convertStringPtBRToDate(finalPoint.label);

  const spaceBetweenPoints = parseInt(`${contributionTime / 12 / 4}`);

  newDataset.push(initialPoint);

  let count = 1;

  while (newDataset.length < 4) {
    const nextPointIndex = spaceBetweenPoints * 12 * count;
    const nextPoint = datasets[nextPointIndex];
    const nextPointDate = convertStringPtBRToDate(nextPoint.label);

    newDataset.push(nextPoint);

    if (newDataset.length === 4) {
      const difference = parseInt(
        `${differenceBetweenDatesInYears(nextPointDate, finalPointDate)}`,
      );

      if (difference / 2 >= spaceBetweenPoints) {
        const extraMonths = parseInt(`${difference / 2}`) * 12;
        const nextExtraPoint = datasets[nextPointIndex + extraMonths];
        newDataset.push(nextExtraPoint);
      }
    }

    count += 1;
  }

  newDataset.push(finalPoint);

  return newDataset;
};
/**
 * Will return timeline points (X axis) in Years (@function chooseByYear) or Months (@function chooseByMonth) based on contributionTime.
 *
 * Will return 5 or 6 points, ideally 5,  obtained from the following logic:
 *  - The first point always will be the birthDate;
 *  - The last point always will be the rescueDate, the last month of contribution;
 *  - To determine the points between the first and the last, so divide the contributionTime by 4 to get the spaceBetweenPoints interval
 *    used to define wich point will be choosed.
 *        - In case of Years (@function chooseByYear), divide the contributionTime by 12 to obtain the contribution in years,
 *          rounded down. After that, divide the years by 4.
 *  - So for every point we increase the spaceBetweenPoint in month or years and get the corresponding dataset in datasets for this date.
 *  - To define whether a 6 point is needed we divide the difference in months or years, between the last point and the penultimate point, by 2.
 *  - If this difference is greater than spaceBetweenPoint, so must be inserted a new point increasing this difference like interval.
 *
 * @param datasets all possible points between the birthDate and the rescueDate
 * @param contributionTime quantity of months contributing
 * @returns 5 or 6 points to plot on the graph from @function chooseByYear or @function chooseByMonth
 */
export const choosePoints = (
  datasets: Array<ILineChartDataset>,
  contributionTime: number,
): Array<ILineChartDataset> => {
  if (contributionTime >= MAX_CONTRIBUTION_TIME_FOR_TIMELINE_IN_MONTHS) {
    return chooseByYear(datasets, contributionTime);
  }

  return chooseByMonth(datasets, contributionTime);
};

export { CDI_MONTHLY_PROFITABILITY, getLabelsByContributionTime, getCDIDatasets, getFundDatasets };
