import { useEffect, useState } from 'react';

export const useDisplayedMessage = (): string => {
  const messages: string[] = [
    'Aguarde enquanto coletamos seus dados.',
    'Continue nesta tela, estamos coletando seus dados.',
    'Ainda estamos coletando seus dados, continue aguardando.',
  ];
  const [displayedMessage, setDisplayedMessage] = useState<string>(messages[0]);
  const setMessageTimeout = (message: string, time: number): NodeJS.Timeout =>
    setTimeout(() => {
      setDisplayedMessage(message);
    }, time);

  useEffect(() => {
    const firstMessageTimeout = setMessageTimeout(messages[1], 15000);
    const secondMessageTimeout = setMessageTimeout(messages[2], 35000);

    return () => {
      clearTimeout(firstMessageTimeout);
      clearTimeout(secondMessageTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });
  return displayedMessage;
};
