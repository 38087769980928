import { InterUIButton, InterUIContainer, InterUIRadio } from '@interco/inter-ui-react-lib';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ProposalAnalyticsService from '../../analytics/proposalAnalyticsService';
import FontSizeREM from '../../enums/fontSizesRem';
import PageTitles from '../../enums/pageTitles';
import { PrivatePensions } from '../../enums/privatePensions';
import ErrorActions from '../../store/error/actions';
import NavbarActions from '../../store/navbar/actions';
import { ProposalDetailActions } from '../../store/proposal/actions';
import Colors from '../../styles/colors';
import { H1, P, PSmallBold, PSmallGray } from '../../styles/commons';

interface IPrivatePensions {
  type: PrivatePensions;
  description: string;
}

const privatePensionTypes: Array<IPrivatePensions> = [
  {
    type: PrivatePensions.PGBL,
    description: `Ideal para quem faz a declaração completa do Imposto de Renda (IR).
			Com desconto no IR, o que você investiu na previdência (até 12% do total declarado) é abatido da base de cálculo.`,
  },
  {
    type: PrivatePensions.VGBL,
    description: `Ideal para quem faz a declaração simplificada do Imposto de Renda (IR) e quer investir na previdência mais do que 12% do total declarado.
			O IR incide apenas sobre seus rendimentos, e só no resgate.`,
  },
];

const PrivatePensionTypePage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedPrivatePension, setSelectedPrivatePension] = useState<PrivatePensions>(
    PrivatePensions.EMPTY,
  );
  const currentPrivatePension = useSelector(ProposalDetailActions.getPrivatePensionType);
  const proposalType = useSelector(ProposalDetailActions.getType);

  useEffect(() => {
    dispatch(NavbarActions.setTitle(PageTitles.PRIVATE_PENSION_TYPE));
    dispatch(ErrorActions.resetState());
  }, [dispatch]);

  useEffect(() => {
    setSelectedPrivatePension(currentPrivatePension);
  }, [currentPrivatePension]);

  const onSelectPrivatePension = (inputValue: string) => {
    const privatePension = inputValue as PrivatePensions;
    setSelectedPrivatePension(privatePension);
  };

  const onSubmit = () => {
    const oldPrivatePension = currentPrivatePension;
    dispatch(ProposalDetailActions.setPrivatePensionType(selectedPrivatePension));
    ProposalAnalyticsService.editInfoConfirmation(
      {
        content_area: 'tabela',
        content_new_info: selectedPrivatePension,
        content_info: oldPrivatePension,
      },
      proposalType,
    );
    history.goBack();
  };

  const renderPrivatePensionTypes: React.ReactElement[] = privatePensionTypes.map(
    (privatePension, index) => {
      const key = `private-pension-${index}`;
      return (
        <InterUIRadio
          variant="choose-item"
          data-testid={key}
          key={key}
          id={key}
          name="private-pension"
          value={privatePension.type}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onSelectPrivatePension(e.currentTarget.value);
          }}
          checked={selectedPrivatePension === privatePension.type}
        >
          <PSmallBold marginBottom="4px">{privatePension.type}</PSmallBold>
          <PSmallGray fontSize={FontSizeREM.PX12} lineHeight={FontSizeREM.PX15} marginBottom="0px">
            {privatePension.description}
          </PSmallGray>
        </InterUIRadio>
      );
    },
  );

  const stickyFooter: React.ReactElement = (
    <InterUIButton disabled={selectedPrivatePension === currentPrivatePension} onClick={onSubmit}>
      Redefinir
    </InterUIButton>
  );

  return (
    <InterUIContainer stickyFooter={stickyFooter}>
      <H1 marginBottom="8px">Escolha sua modalidade</H1>
      <P
        fontWeight={400}
        fontSize={FontSizeREM.PX14}
        lineHeight={FontSizeREM.PX18}
        color={Colors.shuttleGray}
        marginBottom="16px"
      >
        Depende do seu tipo de declaração de imposto de renda e do quanto quer investir.
      </P>
      {renderPrivatePensionTypes}
    </InterUIContainer>
  );
};

export default PrivatePensionTypePage;
