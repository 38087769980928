import styled from 'styled-components';
import Colors from '../../styles/colors';

const SelectStyle = styled.select<{
  width?: string;
  padding?: string;
  backgroundColor?: string;
  borderRadius?: string;
  color?: string;
}>`
  appearance: none;
  z-index: 2;
  width: ${(props) => props.width || '100%'};
  padding: ${(props) => props.padding || '12px 24px'};
  background-color: ${(props) => props.backgroundColor || Colors.ghostWhite};
  border-radius: ${(props) => props.borderRadius || '8px'};
  color: ${(props) => props.color || Colors.interBlack};
`;

export default SelectStyle;
