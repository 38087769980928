import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { EnvVariableKeys } from '../enums/evironmentVariableKeys';
import { PageRoutes } from '../enums/pageRoutes';
import { EnvVariableService } from '../services/environmentVariableService';
import { MockService } from '../services/mockService';
import { MonitoringService } from '../services/monitoringService';
import GlobalActions from '../store/global/actions';
import { QueryParamsState } from '../store/global/types';
import { buildParams, handleParamsPage } from '../utils/routesUtils';
import { useQuery } from './useQuery';

interface IUseQueryParamsType {
  handleQueryParams: () => void;
}

/**
 * useQueryParams
 *
 * A Hook used to handle the deep link query params.
 * @returns handleQueryParams, a function to process every param passed
 *
 * @usage
 * const { handleQueryParams } = useQueryParams();
 *
 * First of all, we use the query that comes from another hook, that return
 * a URLSearchParams;
 * @func useQuery
 *
 * After that we split this query, by the ";" , into an array of strings:
 * @func getQueryParams
 * @returns: [0: "page:SomePage", 1: "someparam:SomeRandomParam"]
 *
 * Next, we pick up this array with the params and we turn it into a object;
 * @func queryParamsAsObj
 * @returns: {page: "SomePage", someparam: "SomeRandomParam"}
 *
 * In case of prefix inside the query params:
 * @func handlePrefixQuery
 *
 * In case of mock inside the query params, as the API_KEY:
 * @func handleMock
 *
 * After the all treat above the query params, we redirect to the the respective page
 * @func handleRedirect
 *
 */

export function useQueryParams(): IUseQueryParamsType {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const defaultParams = useSelector(GlobalActions.getQueryParams);
  const useRedirectPrefix = EnvVariableService.getVariableAsBoolean(
    EnvVariableKeys.USE_REDIRECT_PREFIX,
  );

  const queryParamsArray = useMemo(() => {
    const queryParams: { [key: string]: string } = {};
    query.forEach((value, key) => {
      queryParams[key] = value;
    });
    return queryParams;
  }, [query]);

  const splitedQuery = useMemo(() => {
    if (queryParamsArray !== undefined && queryParamsArray.params !== undefined) {
      return queryParamsArray.params.split(';');
    }

    return [];
  }, [queryParamsArray]);

  const queryParamsAsObj = useMemo(() => {
    const queryParams: { [key: string]: string } = {};
    splitedQuery?.forEach((item: string) => {
      const dict = item.split(':');
      const key = dict[0];
      const value = dict[1];
      queryParams[key] = value;
    });
    return queryParams as QueryParamsState;
  }, [splitedQuery]);

  const handlePrefixQuery = () => {
    if (queryParamsAsObj.prefix && useRedirectPrefix) {
      const url = `https://${String(
        queryParamsAsObj.prefix,
      )}.previdenciawebview.uatbi.com.br${buildParams(splitedQuery)}`;
      window.open(url, '_self');
    }
  };

  const handleMock = () => {
    const shouldMock = String(queryParamsAsObj.mock).toLowerCase() === 'true';
    MockService.prepareMock({
      shouldMock,
      account: queryParamsAsObj.mockContaCorrente,
      apiKey: queryParamsAsObj.mockApiKey,
    });
  };

  const handleRedirect = () => {
    // Routes that does not have the necessity to redirect through the splash signedterms screen
    const whiteListRoutes = [
      'AporteEsporadico',
      'AceitePortabilidadeExterna',
      'PortabilidadeSimplificada',
      'AceitePropostaContratacao',
    ];
    const hasPageParamInWhiteList = !!whiteListRoutes.find(
      (elem) => elem === queryParamsAsObj.page,
    );
    if (queryParamsAsObj.page) {
      if (hasPageParamInWhiteList) {
        history.push({
          pathname: handleParamsPage(queryParamsAsObj.page, queryParamsAsObj),
          state: { data: { deeplink: true } },
        });
      } else {
        history.push(PageRoutes.CHECK_SIGNED_TERMS_DEEPLINK_SPLASH, queryParamsAsObj.page);
      }
    }
  };

  const handleBelvoApiMock = (): void => {
    if (queryParamsAsObj.useBelvoApiMock) {
      queryParamsAsObj.useBelvoApiMock =
        String(queryParamsAsObj.useBelvoApiMock).toLowerCase() === 'true';
    } else {
      queryParamsAsObj.useBelvoApiMock = defaultParams.useBelvoApiMock;
    }
  };

  const handleQueryParams = (): void => {
    if (queryParamsArray !== undefined && queryParamsArray.params !== undefined) {
      handlePrefixQuery();
      handleMock();
      handleBelvoApiMock();
      MonitoringService.log('Simulation.routes.queryParamsOBJ', JSON.stringify(queryParamsAsObj));
      dispatch(GlobalActions.setQueryParams(queryParamsAsObj));

      handleRedirect();
    }
    MockService.validate();
  };

  return { handleQueryParams };
}
