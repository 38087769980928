/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios, { AxiosResponse, Method } from 'axios';
import { interWbHttp, IWbHttpData } from 'inter-webview-bridge';
import { ServiceError } from '../exceptions/index';
import { logCallByBridgeRequest, logCallByBridgeResponse } from '../loggers/baseServiceLogger';
import { ErrorResponse } from '../store/error/types';
import BridgeService from './bridgeService';
import { MockService } from './mockService';
import { MonitoringService } from './monitoringService';

interface EndpointRequestConfig {
  browser: string;
  bridge: string;
}
interface IHeaders {
  browser?: Record<string, string>;
  bridge?: Record<string, string>;
}

export interface BaseRequestConfig {
  method: Method;
  endpoint: EndpointRequestConfig;
  urlPrefix?: string;
  headers?: IHeaders;
  data?: any;
}

export type ServiceResponse<T> = {
  status: number;
  data: T;
  headers: any;
};

const { REACT_APP_API_HOST, REACT_APP_INTER_ENV } = process.env;
const STATUS_CODE_ACCEPTED = [400];
const BRIDGE_MESSAGES_ACCEPTED = [
  'Parece que você está sem internet ou sua conexão encontra-se instável.',
];

export class BaseService {
  static async prepareAxios(urlPrefix?: string): Promise<void> {
    axios.defaults.baseURL = urlPrefix || process.env.REACT_APP_API_HOST;
    axios.defaults.headers['x-inter-frontend-session'] = MonitoringService.frontendSession;
    axios.defaults.headers['x-inter-app-origem'] = 'Webview Previdência';
    axios.defaults.headers['x-inter-app-version'] = '1.0';

    if (!axios.defaults.headers['x-api-token'] && !MockService.shouldMock) {
      await BaseService.authDev();
    }
  }

  static async authDev(): Promise<void> {
    const getCredential: AxiosResponse<{ authorization: string }> = await axios({
      url: `${MockService.baseUrl}/backend-oauth/credentials`,
      method: 'GET',
      headers: {
        'x-api-key': MockService.apiKey,
      },
    });

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${getCredential.data.authorization}`,
    };

    let response = {} as AxiosResponse;

    try {
      response = await axios({
        url: `${REACT_APP_API_HOST}/oauth/token?grant_type=client_credentials`,
        method: 'POST',
        headers,
      });
    } catch (error: any) {
      throw new ServiceError(
        error.response?.status,
        error.response?.data,
        error.response?.status,
        error.response?.headers,
        error.message,
      );
    }

    axios.defaults.headers['x-api-token'] = response.data.access_token;
  }

  static async callByAxios<T>(config: BaseRequestConfig): Promise<ServiceResponse<T>> {
    const { method, endpoint, headers, data, urlPrefix } = config;

    await BaseService.prepareAxios(urlPrefix);

    /**
     * This method must be called after the BaseService.prepareAxios
     * because it has some override of axios defaults attributes, like @var baseURL
     */
    MockService.prepareAxios();

    let response = {} as AxiosResponse;

    try {
      response = await axios({
        method,
        url: endpoint.browser,
        data,
        headers: headers?.browser ? headers.browser : headers,
      });
    } catch (error: any) {
      throw new ServiceError(
        STATUS_CODE_ACCEPTED.includes(error.response?.status),
        error.response?.data,
        error.message,
        error.response?.status,
        error.response?.headers,
      );
    }

    return {
      data: response.data,
      status: response.status,
      headers: response.headers,
    };
  }

  static async callByBridge<T>(requestConfig: BaseRequestConfig): Promise<ServiceResponse<T>> {
    logCallByBridgeRequest(requestConfig);

    const { endpoint, headers, data } = requestConfig;

    const method = requestConfig.method.toLowerCase();

    let bridgeResponse = {} as IWbHttpData;

    if (headers && headers.bridge) {
      headers.bridge['x-inter-frontend-session'] = String(MonitoringService.frontendSession);
    }

    const headersWithSession = headers && headers.bridge ? headers.bridge : undefined;

    try {
      switch (method) {
        case 'get': {
          bridgeResponse = await interWbHttp.get(endpoint.bridge, headersWithSession);
          break;
        }
        case 'post': {
          bridgeResponse = await interWbHttp.post(endpoint.bridge, data, headersWithSession);
          break;
        }
        case 'put': {
          bridgeResponse = await interWbHttp.put(endpoint.bridge, data, headersWithSession);
          break;
        }
        case 'delete': {
          bridgeResponse = await interWbHttp.delete(endpoint.bridge, headersWithSession);
          break;
        }
        default: {
          break;
        }
      }
    } catch (error: any) {
      let errorObject: any = error;
      if (typeof errorObject === 'string') {
        try {
          errorObject = JSON.parse(error);
        } catch {}
      }

      BaseService.handleBackendErrors(errorObject);
      BaseService.handleAcceptableErrorMessages(errorObject);

      throw error;
    }

    logCallByBridgeResponse(bridgeResponse);

    let parsedData = bridgeResponse.response as any;

    if (typeof parsedData === 'string' && parsedData !== '') {
      try {
        parsedData = JSON.parse(parsedData);
      } catch (e: any) {
        MonitoringService.noticeError(e, {
          errorCodeRef: 'BaseService.callByBridge.parseResponseToJSON',
          responseToParse: btoa(bridgeResponse.response),
          responseHttpStatus: bridgeResponse.httpStatus,
          requestEndpoint: endpoint.bridge,
        });
      }
    }

    return {
      data: parsedData,
      status: bridgeResponse.httpStatus,
      headers: bridgeResponse.headers,
    };
  }

  static handleBackendErrors(errorObject: any): void {
    if (errorObject.httpStatus && errorObject.response) {
      let errorResponse: any = errorObject.response;

      if (typeof errorObject.response === 'string') {
        try {
          errorResponse = JSON.parse(errorObject.response);
        } catch {}
      }

      throw new ServiceError(
        STATUS_CODE_ACCEPTED.includes(errorObject.httpStatus),
        errorResponse,
        JSON.stringify(errorResponse),
        errorObject.httpStatus,
      );
    }
  }

  static handleAcceptableErrorMessages(errorObject: any) {
    if (
      errorObject.message &&
      BRIDGE_MESSAGES_ACCEPTED.find((message) => errorObject.message.includes(message))
    ) {
      const errorResponse: ErrorResponse = {
        errors: [{ code: errorObject.action, message: errorObject.message }],
        totalErrors: 1,
      };

      throw new ServiceError(
        true,
        errorResponse,
        JSON.stringify(errorResponse),
        errorObject.httpStatus,
      );
    }
  }

  static async doExecute<T>(config: BaseRequestConfig): Promise<ServiceResponse<T>> {
    if (BridgeService.isBrowser()) {
      if (REACT_APP_INTER_ENV === 'production') {
        return Promise.reject(
          new Error('Não é possível executar ambiente de produção via browser.'),
        );
      }

      return BaseService.callByAxios(config);
    }

    return BaseService.callByBridge(config);
  }
}
